import React from "react";
import styled from "styled-components";
import useAutoFocusRefCallback from "../../hooks/useAutoFocusRefCallback";
import { Divider } from "../../libs/semantic-ui";
import { Box, Text } from "../../UI";
import ContentTextarea from "../PostMenu/ContentTextarea";

export interface CommunityDescriptionFormProps {
  description?: string;
  onChange: (description: string | undefined) => void;
}

const maxLength = 500;

const CommunityDescriptionForm: React.FC<CommunityDescriptionFormProps> = ({ description, onChange }) => {
  const [communityDescriptionRef] = useAutoFocusRefCallback();

  return (
    <Box px={2}>
      <Box width="100%" mt={2} mb={3}>
        <Text textAlign="left" fontSize={3} fontWeight={600}>
          Let people know what your community is about
        </Text>
        <Box color="darkGrey" my={1}>
          No pressure, you can always change this later
        </Box>
        <Box mt={4} display="flex" alignItems="center">
          <ContentTextarea
            ref={communityDescriptionRef}
            placeholder="Enter description here"
            async
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
            value={description || undefined}
            maxLength={maxLength}
          />
        </Box>
      </Box>
      <InputDivider />
      <Text color="mediumGrey" textAlign={"right"}>{`${description?.length || 0}/${maxLength}`}</Text>
    </Box>
  );
};

const InputDivider = styled(Divider)`
  &&&& {
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

export default CommunityDescriptionForm;
