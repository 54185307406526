import React from "react";
import { useMutation } from "redux-query-react";
import { muteUser, unmuteUser } from "../../../queries/admin/adminQuery";
import { UsersAPI } from "../../../typings/API";
import { Admin } from "../../Permission";
import ActionsMenuItem from "../ActionsMenuItem";

export interface MuteUserActionsMenuItemProps {
  user: UsersAPI.PublicProfile;
}

const MuteUserActionsMenuItem: React.FC<MuteUserActionsMenuItemProps> = ({ user }) => {
  const [, mute] = useMutation(muteUser);
  const [, unmute] = useMutation(unmuteUser);

  return (
    <Admin>
      {user.is_muted ? (
        <ActionsMenuItem label="Unmute (Un-Shadowban)" iconName="unmute" onClick={() => unmute(user.id)} />
      ) : (
        <ActionsMenuItem label="Mute (Shadowban)" iconName="mute" onClick={() => mute(user.id)} />
      )}
    </Admin>
  );
};

export default MuteUserActionsMenuItem;
