import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useUser } from "reactfire";
import { useMutation } from "redux-query-react";
import styled from "styled-components";
import analyticsUtil, { trackEvent } from "../../helpers/analyticsUtil";
import useAuthenticatedCallback from "../../hooks/useAuthenticatedCallback";
import { addCommunitySubscription } from "../../queries/api/communityQuery";
import communitySelector from "../../selectors/communitySelector";
import { PostsAPI } from "../../typings/API-V2";
import { Box, BoxProps, Button, Icon, Link, Spacer, Text, UserAvatar, UserAvatarProps } from "../../UI";
import PostActionsMenu from "../ActionsMenu/Post/PostActionsMenu";
import PostTitleMeta from "./PostTitleMeta";

export interface PostMetaProps {
  hideActions?: boolean;
  hideCommunity: boolean;
  post: PostsAPI.Post;
  showPinned: boolean;
}

const PostMeta: React.FC<PostMetaProps> = ({ post, hideActions, hideCommunity, showPinned }) => {
  const history = useHistory();
  const { data: currentUser } = useUser();
  const { addToast } = useToasts();
  const [, addCommunity] = useMutation(addCommunitySubscription);
  const isSubscribed = useSelector(communitySelector.userIsSubscribed(currentUser?.uid, post.community.id));

  const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false);
  const openActionMenu = useCallback<Required<BoxProps>["onClick"]>((e) => {
    e.stopPropagation();
    e.preventDefault();
    setActionMenuOpen(true);
  }, []);
  const closeActionMenu = useCallback(() => setActionMenuOpen(false), []);

  const openUserProfile = useCallback<Required<UserAvatarProps>["onClick"]>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (post?.author.username) {
        history.push(`/u/${post.author.username}`);
      }
    },
    [post?.author.username, history],
  );

  const openCommunityPage = useCallback<Required<UserAvatarProps>["onClick"]>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (post?.community.slug) {
        history.push(`/c/${post.community.slug}`);
      }
    },
    [post?.community.slug, history],
  );

  const joinCommunity = useAuthenticatedCallback(() => {
    if (!isSubscribed && currentUser?.uid && post.community) {
      addCommunity(currentUser.uid, post.community.id);
      addToast(`Joined c/${post.community.slug}!`, {
        appearance: "success",
        autoDismiss: true,
      });
      trackEvent(analyticsUtil.Community.Subscribed);
    }
  }, [currentUser?.uid, isSubscribed, post.community]);

  return (
    <>
      {!hideCommunity && (
        <CommunityMetaWrapper>
          <UserAvatar
            name={post.community.slug}
            onClick={openCommunityPage}
            size={20}
            src={post.community.avatar_url || undefined}
            username={post.community.slug}
          />
          <Spacer ml={1} />
          <Link to={`/c/${post.community.slug}`}>
            <Text fontSize={0} color="black" fontWeight={4}>{`c/${post.community.slug}`}</Text>
          </Link>
          <ActionWrapper>
            {!isSubscribed && (
              <>
                <Button onClick={joinCommunity} size="tiny" variant="secondary">
                  Join
                </Button>
                <Spacer ml={2} />
              </>
            )}
            {!hideActions && (
              <TitleActions onClick={openActionMenu}>
                <Icon name="ellipsis horizontal" color="darkGrey" />
              </TitleActions>
            )}
          </ActionWrapper>
        </CommunityMetaWrapper>
      )}
      <Box display="flex">
        <UserAvatar
          name={post.author.username || undefined}
          displayLetter={post.author.is_deleted ? "—" : undefined}
          onClick={openUserProfile}
          size={"medium"}
          src={post.author.profile_image || undefined}
          username={post.author.username}
        />
        <Spacer ml={2} />
        <PostTitleMeta post={post} hideActions={hideActions} showPinned={showPinned} />
        {!hideActions && hideCommunity && (
          <TitleActions onClick={openActionMenu}>
            <Icon name="ellipsis horizontal" color="darkGrey" />
          </TitleActions>
        )}
      </Box>
      {!hideActions && <PostActionsMenu isOpen={actionMenuOpen} close={closeActionMenu} post={post} />}
    </>
  );
};

const CommunityMetaWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

CommunityMetaWrapper.defaultProps = {
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  marginBottom: 3,
  paddingBottom: 1,
};

const ActionWrapper = styled(Box)`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleActions = styled(Box)`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;
TitleActions.defaultProps = {
  height: 32,
  width: 32,
  borderRadius: 1,
  role: "button",
};

export default PostMeta;
