import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import analyticsEvents, { trackEvent } from "../../helpers/analyticsUtil";
import { MenuProps } from "../../hooks/useMenuState";
import { Modal, TransitionablePortal } from "../../libs/semantic-ui";
import { Box, Button, Spacer, Text } from "../../UI";
import AuthScreensContext, { AuthScreen } from "../AuthScreenModal/AuthScreensContext";

export interface LoginModalProps extends MenuProps {}

const LoginModal: React.FC<LoginModalProps> = ({ close, isOpen }) => {
  const { setAuthScreen } = useContext(AuthScreensContext);

  useEffect(() => {
    if (isOpen) {
      trackEvent(analyticsEvents.LoginModal.Viewed);
    }
  }, [isOpen]);

  const openAuthScreen = useCallback(
    (authScreen: AuthScreen) => {
      setAuthScreen(authScreen);
      close();
    },
    [close, setAuthScreen],
  );

  return (
    <TransitionablePortal open={isOpen} animation="fade up" duration={400}>
      <Modal open={isOpen} onClose={close} size="mini">
        <ModalContent display="flex" flexDirection="column" alignItems="center" p={4}>
          <Text fontSize={2} fontWeight={600}>
            Sign Up or Log In
          </Text>
          <Spacer mt={3} />
          <Text fontSize={1}>Please sign up or log in to continue</Text>
          <Spacer mt={3} />
          <Button size="small" variant="primary" width="100%" onClick={openAuthScreen.bind(null, "login")}>
            Continue
          </Button>
          <Spacer mt={4} />
          <DismissText fontSize={0} onClick={close}>
            Dismiss
          </DismissText>
        </ModalContent>
      </Modal>
    </TransitionablePortal>
  );
};

const ModalContent = styled(Box)`
  z-index: 70;
`;

const DismissText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

export default LoginModal;
