import React from "react";
import styled from "styled-components";
import { theme } from "../../index";
import Box from "../Box";
import PlaceholderBox from "../PlaceholderBox";
import { CommunityMetaWrapper, CommunityNamesWrapper } from "./CommunityMeta";

export interface CommunityMetaPlaceholderProps {
  size?: "small" | "medium" | "large";
}

const CommunityMetaPlaceholder: React.FC<CommunityMetaPlaceholderProps> = ({ size }) => {
  const themeSize = size ? theme.imageSize[size] : theme.imageSize["medium"];
  return (
    <CommunityMetaWrapper>
      <PlaceholderBox height={themeSize} width={themeSize} borderRadius={1} />
      <CommunityNamesWrapper>
        <PlaceholderBox height={themeSize / 2} width={150} />
      </CommunityNamesWrapper>
    </CommunityMetaWrapper>
  );
};

const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
`;
TextWrapper.defaultProps = {
  ml: 2,
};

export default CommunityMetaPlaceholder;
