import { nanoid } from "nanoid";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuth } from "reactfire";
import { AffiliateClickTrackOptions, AffiliateLinkProps } from "../components/links/AffiliateLink";
import config from "../config";
import { makeUrl } from "../helpers/affiliateLinkUtil";
import events, { trackEvent } from "../helpers/analyticsUtil";
import { RootState } from "../store";
import { VendorsAPI } from "../typings/API";
import LocationState from "../typings/LocationState";

export type AffiliateLinkType = "price" | "release" | "raffle" | "post" | "comment" | "restock";

export type UseAffiliateLinkClick = (defaultOptions?: {
  affiliateData?: VendorsAPI.VendorAffiliateData | null;
  revenue?: number;
  target?: AffiliateLinkProps["target"];
  trackingOptions?: Partial<AffiliateClickTrackOptions>;
}) => (url: string, linkType: AffiliateLinkType) => void;

const useAffiliateLinkClick: UseAffiliateLinkClick = (defaultOptions = {}) => {
  const location = useLocation<undefined | LocationState.ClickTrackState>();
  const auth = useAuth();
  const referrerId = useSelector<RootState, string | null>((state) => state.app.plugdReferrerId);

  const { affiliateData, revenue, target, trackingOptions } = defaultOptions;

  const onAffiliateLinkClick = useCallback<(url: string, linkType: AffiliateLinkType) => void>(
    (url, linkType) => {
      const uniqueId = nanoid();
      const cuid = `pclid|${uniqueId}`;

      // segment tracking
      trackEvent(events.AffiliateLink.Clicked, {
        label: url,
        revenue: revenue,
        type: linkType,
      });

      // plugd tracking
      if (navigator.sendBeacon) {
        const clickAttributes: AffiliateClickTrackOptions = {
          id: uniqueId,
          user_id: auth.currentUser?.uid,
          anonymous_user_id:
            (window.analytics && window.analytics.user && window.analytics.user().anonymousId()) || null,
          referrer_id: referrerId,
          referrer_post_id: location.state?.pclidOptions?.referrer_post_id,
          ...trackingOptions,
        };
        navigator.sendBeacon(`${config.TRACK_API_URL}/affiliate-clicks`, JSON.stringify(clickAttributes));
      }

      // generat affiliate href
      const affiliateUrl = makeUrl(url, affiliateData || undefined, cuid);

      // navigate to target
      window.open(affiliateUrl, target ? target : "_blank");
    },
    [
      affiliateData,
      auth.currentUser?.uid,
      location.state?.pclidOptions?.referrer_post_id,
      referrerId,
      revenue,
      target,
      trackingOptions,
    ],
  );

  return onAffiliateLinkClick;
};

export default useAffiliateLinkClick;
