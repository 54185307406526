import React from "react";
import styled from "styled-components";
import { Card, CardProps, Icon, theme } from "../../../UI";

export interface NavigationButtonProps extends CardProps {
  onClick: Required<CardProps>["onClick"];
  className: string;
  type: "next" | "prev";
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ onClick, type, className, ...cardProps }) => {
  return (
    <NavigationButtonWrapper className={className} onClick={onClick} {...cardProps}>
      <Icon name={type === "prev" ? "chevron left" : "chevron right"} />
    </NavigationButtonWrapper>
  );
};

const NAVIGATION_BUTTON_SIZE = 30;
const SWIPER_BOTTOM_PADDING = theme.space[4];

const NavigationButtonWrapper = styled(Card)`
  cursor: pointer;
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transform: translateY(calc(-50% - ${SWIPER_BOTTOM_PADDING / 2}px));
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
`;
NavigationButtonWrapper.defaultProps = {
  height: NAVIGATION_BUTTON_SIZE,
  width: NAVIGATION_BUTTON_SIZE,
  borderRadius: NAVIGATION_BUTTON_SIZE / 2,
  bg: "white",
  role: "button",
};

export default NavigationButton;
