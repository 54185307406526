import { useCallback, useMemo, useState } from "react";

export interface OpenCloseState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}
const useOpenClose = (isDefaultOpen = false): OpenCloseState => {
  const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen((currentState) => !currentState);
  }, []);

  const menuState = useMemo(
    () => ({
      isOpen,
      open,
      close,
      toggle,
    }),
    [isOpen, close, open, toggle],
  );

  return menuState;
};

export default useOpenClose;
