import React from "react";
import { isMobileFrame } from "../../config";
import useTopNavActions from "../../hooks/useTopNavActions";
import { IconButton, MobileTopNav, TopNavTitle, TopNavTitleProps } from "../Layout/TopNav";
import TopNavLogo from "./TopNavLogo";
import TopNavSide from "./TopNavSide";
import TopNavUseAppButton from "./TopNavUseAppButton";

export type ProductsTabSecondaryTopNavProps = {
  title: string;
  titleProps?: Omit<TopNavTitleProps, "children">;
};

const ProductsTabSecondaryTopNav: React.FC<ProductsTabSecondaryTopNavProps> = ({ title, titleProps }) => {
  const { onProductSearchClick, goBack } = useTopNavActions({ goBackDefaultLocation: "/discover" });

  return (
    <MobileTopNav>
      <TopNavSide side="left">
        <IconButton onClick={goBack} iconName="chevron left" />
      </TopNavSide>
      {isMobileFrame ? <TopNavTitle {...titleProps}>{title}</TopNavTitle> : <TopNavLogo />}
      <TopNavSide side="right">
        <TopNavUseAppButton />
        <IconButton iconName="search" onClick={onProductSearchClick} />
      </TopNavSide>
    </MobileTopNav>
  );
};

export default ProductsTabSecondaryTopNav;
