import React, { ReactNode } from "react";
import styled from "styled-components";
import { Box, BoxProps, Text } from "../UI";

export interface ImageTagProps extends BoxProps {
  children: ReactNode;
}

const ImageTag: React.FC<ImageTagProps> = ({ children, ...boxProps }) => {
  return (
    <Tag {...boxProps}>
      <Text fontSize={0}>{children}</Text>
      <Arrow />
    </Tag>
  );
};

const ARROW_HEIGHT = 8;
const ARROW_WIDTH = 8;

const Tag = styled(Box)`
  cursor: pointer;
  text-align: center;
  position: absolute;
  transform: translateX(-50%) translateY(calc(-100% - ${ARROW_HEIGHT}px));
  min-width: 120px;
  line-height: 1em;
  &:hover {
    text-decoration: underline;
  }
`;
Tag.defaultProps = {
  p: 2,
  borderRadius: 1,
  color: "lightGrey",
  bg: "black",
};

const Arrow = styled(Box)`
  position: absolute;
  bottom: -${ARROW_HEIGHT}px;
  left: calc(50% - ${ARROW_WIDTH}px);
  /* transform: translateX(50%); */
  width: 0;
  height: 0;
  border-left: ${ARROW_WIDTH}px solid transparent;
  border-right: ${ARROW_WIDTH}px solid transparent;
  border-top: ${ARROW_HEIGHT}px solid ${({ theme }) => theme.colors.black};
`;

export default ImageTag;
