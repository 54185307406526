import produce, { Draft } from "immer";
import { union } from "lodash-es";
import { Reducer } from "redux";
import ActionType from "../enums/ActionType";
import { ActionValue } from "../helpers/thunkUtil";

export interface CommentsState {
  commentListIds: {
    [listKey: string]: number[];
  };
}

const initialState = {
  commentListIds: {},
};

const commentsReducer = produce((draft: Draft<CommentsState>, action: ActionValue) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case ActionType.APPEND_LIST_COMMENTS: {
      const currentList = draft.commentListIds[action.payload.key] || [];
      draft.commentListIds[action.payload.key] = union(currentList.concat(action.payload.commentIds));
      return draft;
    }
    case ActionType.REPLACE_LIST_COMMENTS: {
      draft.commentListIds[action.payload.key] = action.payload.commentIds;
      return draft;
    }
    default:
      return draft as CommentsState;
  }
}) as Reducer<CommentsState, ActionValue>;

export default commentsReducer;
