import styled from "styled-components";
import Spacer from "../../../UI/components/Spacer";
import { TOP_NAV_HEIGHT } from "./TopNavWrapper";

const TopNavSpacer = styled(Spacer)``;
TopNavSpacer.defaultProps = {
  pt: TOP_NAV_HEIGHT,
};

export default TopNavSpacer;
