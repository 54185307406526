import { useCallback, useMemo } from "react";

const useToggleActions = <Item extends { id: string | number }>(
  items: Item[],
  selectedItemIds: Set<Item["id"]>,
  setSelectedItemIds: React.Dispatch<React.SetStateAction<Set<Item["id"]>>>,
) => {
  /** Toggle selection of a single item */
  const toggleSelectedItem = useCallback(
    (itemId: Item["id"]) => setSelectedItemIds(updateSelectedIds<Item["id"]>(selectedItemIds, itemId)),
    [setSelectedItemIds, selectedItemIds],
  );

  /** Bulk select or deselect items */
  const toggleSelectedItemBulk = useCallback(() => {
    if (selectedItemIds.size < items.length) {
      /** bulk select all if not all items are selected */
      return setSelectedItemIds(new Set(items.map((item) => item.id)));
    }

    /** bulk deselect all items if all items are currently selected */
    return setSelectedItemIds(new Set());
  }, [setSelectedItemIds, selectedItemIds, items]);

  return useMemo(
    () => ({
      toggleSelectedItem,
      toggleSelectedItemBulk,
    }),
    [toggleSelectedItem, toggleSelectedItemBulk],
  );
};

const updateSelectedIds = <T = number>(currentIds: Set<T>, productId: T): Set<T> => {
  const updatedIds = new Set(currentIds);
  if (updatedIds.has(productId)) {
    updatedIds.delete(productId);
  } else {
    updatedIds.add(productId);
  }
  return updatedIds;
};

export default useToggleActions;
