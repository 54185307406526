import React from "react";
import styled from "styled-components";
import moneyUtil from "../../helpers/moneyUtils";
import { StripeAPI } from "../../typings/API";
import { Box, BoxProps, Text } from "../../UI";

interface CheckoutSessionTotalDetails {
  /** for type guard */
  type: "checkout_session";
  totalDetails: StripeAPI.CheckoutSession["total_details"];
  amountSubtotal: StripeAPI.CheckoutSession["amount_subtotal"];
  amountTotal: StripeAPI.CheckoutSession["amount_total"];
}

interface OrderTotalDetails {
  /** for type guard */
  type: "order";
  totalDetails: StripeAPI.Order["total_details"];
  amountSubtotal: StripeAPI.Order["amount_subtotal"];
  amountTotal: StripeAPI.Order["amount_total"];
}

export type TotalDetailsProps = (CheckoutSessionTotalDetails | OrderTotalDetails) & BoxProps;

const TotalDetails: React.FC<TotalDetailsProps> = ({ totalDetails, amountSubtotal, amountTotal, ...wrapperProps }) => {
  return (
    <TotalDetailsWrapper {...wrapperProps}>
      {amountSubtotal != null && (
        <TotalDetail>
          <Text>{`Subtotal:`}</Text>
          <Text>{`${moneyUtil.centsToDollarString(amountSubtotal)}`}</Text>
        </TotalDetail>
      )}
      {totalDetails?.amount_discount != null && totalDetails.amount_discount > 0 && (
        <TotalDetail>
          <Text>{`Discount:`}</Text>
          <Text>{`${moneyUtil.centsToDollarString(totalDetails.amount_discount)}`}</Text>
        </TotalDetail>
      )}
      {totalDetails?.amount_tax != null && (
        <TotalDetail>
          <Text>{`Tax:`}</Text>
          <Text>{`${moneyUtil.centsToDollarString(totalDetails.amount_tax)}`}</Text>
        </TotalDetail>
      )}
      {totalDetails?.amount_shipping != null && (
        <TotalDetail>
          <Text>{`Shipping:`}</Text>
          <Text>{`${moneyUtil.centsToDollarString(totalDetails.amount_shipping)}`}</Text>
        </TotalDetail>
      )}
      {amountTotal != null && (
        <Total>
          <Text fontWeight={5}>{`Total:`}</Text>
          <Text fontWeight={5}>{`${moneyUtil.centsToDollarString(amountTotal)}`}</Text>
        </Total>
      )}
    </TotalDetailsWrapper>
  );
};

const TotalDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const AmountLineItem = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
AmountLineItem.defaultProps = {
  mb: 1,
};

const Total = styled(AmountLineItem)``;

const TotalDetail = styled(AmountLineItem)``;
TotalDetail.defaultProps = {};

export default TotalDetails;
