import { LocationDescriptor, Path } from "history";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LocationState from "../typings/LocationState";

/**
 * This decorates history.push to add the current history's scrollY position before navigating to the next page
 * Meant to be used before useScrollPersist
 */
const useHistoryDecorators = () => {
  const currentLocation = useLocation<LocationState.ScrollYState>();
  const history = useHistory<LocationState.ScrollYState>();
  const [origPush] = useState(() => {
    /** creates copy of the original method */
    return history.push.bind({});
  });

  useEffect(() => {
    history.push = (
      nextLocation: Path | LocationDescriptor<LocationState.ScrollYState>,
      nextState?: LocationState.ScrollYState,
    ) => {
      /**
       * Store the current page's scroll position
       */
      history.replace(`${document.location.pathname}${document.location.search}`, {
        /** keep the current location's state */
        ...currentLocation.state,
        /** add the last scrollY position */
        scrollY: window.scrollY,
      });
      /**
       * continue with normal push behavior
       */
      origPush.call(history, nextLocation, nextState);
    };
  }, [history, origPush, currentLocation]);
};

export default useHistoryDecorators;
