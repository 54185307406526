import { configureScope } from "@sentry/browser/esm";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "reactfire";
import { retry } from "ts-retry-promise";
import { incrementVisits } from "./actions/createActions";
import { isDev, isMobileFrame } from "./config";
import LocalStorageKey from "./enums/LocalStorageKey";
import { MarketingMedium } from "./enums/Marketing";
import * as localStorageUtil from "./helpers/localStorageUtil";
// import { actionPromiseToPromise } from "./helpers/reactQueryUtil";
// import useControlledRequest from "./hooks/useControlledRequest";
import usePlugdReferralTracker from "./hooks/usePlugdReferralTracker";
import useQuery from "./hooks/useQuery";
// import { getIPInfo as getIPInfoQuery, isIPInfo, isIPInfoError } from "./queries/ipapi/ipapiQuery";
// import userSelector from "./selectors/userSelector";

const AuthInitializer = lazy(() =>
  retry(() => import(/* webpackChunkName: "AuthInitializer" */ "./AuthInitializer"), { retries: 5 }),
);
const MobileFrameInitializer = lazy(() =>
  retry(() => import(/* webpackChunkName: "MobileFrameInitializer" */ "./MobileFrameInitializer"), { retries: 5 }),
);

const AppInitializer: React.FC = () => {
  usePlugdReferralTracker();
  const [visitCounted, setVisitCounted] = useState<boolean>(false);
  const user = useUser();

  // const shippingLocation = useSelector(userSelector.shippingLocation());
  // const [, getIPInfo] = useControlledRequest(getIPInfoQuery);
  const dispatch = useDispatch();
  const queryParams = useQuery();

  useEffect(() => {
    if (!visitCounted) {
      dispatch(incrementVisits());
      setVisitCounted(true);
    }
  }, [dispatch, visitCounted]);

  // useEffect(() => {
  //   if (!shippingLocation) {
  //     actionPromiseToPromise(getIPInfo())
  //       .then((response) => {
  //         if (response.body && isIPInfo(response.body)) {
  //           dispatch(updateIPInfo(response.body));
  //         }
  //         if (response.body && isIPInfoError(response.body)) {
  //           throw new Error(response.body.message);
  //         }
  //       })
  //       .catch((error) => captureException(error));
  //   }
  // }, [dispatch]);

  // set sentry user id
  useEffect(() => {
    if (!isDev) {
      configureScope((scope) => {
        scope.setUser({
          id: user.data?.uid,
          email: user.data?.email || undefined,
        });
      });
    }
  }, [user.data?.email, user.data?.uid]);

  useEffect(() => {
    const medium = queryParams.get("utm_medium");
    const refUserId = queryParams.get("plugd_referral_user_id");
    if (medium === MarketingMedium.REFERRALS && refUserId) {
      localStorageUtil.setStringItem(LocalStorageKey.REFERRAL_USER_ID, refUserId);
    }
  }, [queryParams]);

  // these don't display anything so leave fallback as null
  return (
    <Suspense fallback={null}>
      {user.data?.uid ? <AuthInitializer uid={user.data.uid} /> : null}
      {isMobileFrame && <MobileFrameInitializer />}
    </Suspense>
  );
};

export default AppInitializer;
