import styled, { DefaultTheme } from "styled-components";
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from "styled-system";

export type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  BackgroundProps<DefaultTheme> &
  BorderProps<DefaultTheme> &
  ColorProps<DefaultTheme> &
  FlexboxProps<DefaultTheme> &
  LayoutProps<DefaultTheme> &
  PositionProps<DefaultTheme> &
  SpaceProps<DefaultTheme> &
  BoxShadowProps<DefaultTheme> & {
    hover?: string;
  };

const Anchor = styled.a<AnchorProps>`
  ${background}
  ${border}
  ${boxShadow}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
`;

export default Anchor;
