import TextareaAutosize from "react-autosize-textarea/lib";
import styled, { DefaultTheme } from "styled-components";
import { border, BorderProps, color, ColorProps, space, SpaceProps } from "styled-system";

export type ContentTextareaProps = TextareaAutosize.Props &
  BorderProps<DefaultTheme> &
  ColorProps<DefaultTheme> &
  SpaceProps<DefaultTheme> &
  React.HTMLAttributes<HTMLTextAreaElement>;

const ContentTextarea = styled(TextareaAutosize)<ContentTextareaProps>`
  width: 100%;
  border-color: transparent;
  border-style: none;
  border: none;
  outline: none;
  overflow: auto;
  padding: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
  ${border}
  ${color}
  ${space}
`;

export default ContentTextarea;
