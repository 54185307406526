import React from "react";
import styled from "styled-components";
import { Box, Text } from "../../UI";

export interface MentionDetailsProps {
  show: boolean;
  isAuthorized: boolean;
  authorizedText: string;
  unAuthorizedText: string;
}

const MentionDetails: React.FC<MentionDetailsProps> = ({ show, isAuthorized, authorizedText, unAuthorizedText }) => {
  return (
    <>
      {show && (
        <SubtitleWrapper mt={1}>
          {isAuthorized ? (
            <Text color="mediumGrey" fontSize={0} fontWeight={6}>
              {authorizedText}
            </Text>
          ) : (
            <Text color="red" fontSize={0} fontWeight={6}>
              {unAuthorizedText}
            </Text>
          )}
        </SubtitleWrapper>
      )}
    </>
  );
};

const SubtitleWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`;

export default MentionDetails;
