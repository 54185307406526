import { ParsedToken } from "firebase/auth";
import BoxCondition from "../../enums/BoxCondition";
import PriceType from "../../enums/PriceType";
import ShoeCondition from "../../enums/ShoeCondition";
import { PriceAddOn } from "../../helpers/askUtil";
import Vendor from "../Vendor";
import ProductsAPI from "./ProductsAPI";
export type { default as AdminAPI } from "./AdminAPI";
export type { default as AnnouncementsAPI } from "./AnnouncementsAPI";
export type { default as CommunitiesAPI } from "./CommunitiesAPI";
export type { default as LeaderboardAPI } from "./LeaderboardAPI";
export type { default as NotificationsAPI } from "./NotificationsAPI";
export type { default as OrdersAPI } from "./OrdersAPI";
export type { default as PartnersAPI } from "./PartnersAPI";
export type { default as PostsAPI } from "./PostsAPI";
export type { default as ProductACOAPI } from "./ProductACOAPI";
export type { default as ProductListingsAPI } from "./ProductListingsAPI";
export type { default as ProductRafflesAPI } from "./ProductRafflesAPI";
export type { default as ProductReleasesAPI } from "./ProductReleasesAPI";
export type { default as ProductsAPI } from "./ProductsAPI";
export type { default as RecommendationsAPI } from "./RecommendationsAPI";
export type { default as SalesAPI } from "./SalesAPI";
export type { default as StripeAPI } from "./StripeAPI";
export type { default as UserCollectionsAPI } from "./UserCollectionsAPI";
export type { default as UsersAPI } from "./UsersAPI";
export type { default as VendorProfilesAPI } from "./VendorProfilesAPI";
export type { default as VendorsAPI } from "./VendorsAPI";

type UserClaimsRole = "admin";

export type OrderBy = "asc" | "desc";
export type FilterOperators = "gt" | "lt" | "eq" | "gte" | "lte" | "neq";

export interface BrandResponse {
  brands: Brand[];
}

export interface Brand {
  id: number;
  brand_name: string;
  size_key?: string;
  slug: string;
}

export interface Price {
  vendor_id: number;
  hash_code: string;
  vendor: Vendor.Key;
  amount: number;
  url: string;
  size: string;
  image_urls?: string[];
  box_condition: BoxCondition;
  shoe_condition: ShoeCondition;
  slug?: string;
  fees?: PriceAddOn.Fee[]; // EBAY has fees defined on server.
}
export interface PriceValue extends Price {
  type: PriceType;
}

export interface AskValue extends PriceValue {
  type: PriceType.Ask;
}

export interface BidValue extends PriceValue {
  type: PriceType.Bid;
}

export interface LowestAsksBySizeReturn {
  [size: string]: AskValue;
}

export type LowestAsksByVendorReturn = Record<Vendor.Key, (AskValue | null)[]>;

export interface GoatProduct {
  data: {
    brand_name?: string;
    gender: [ProductsAPI.GoatGender];
    grid_picture_url: string;
    name: string;
    nickname?: string;
    release_date?: string;
    retail_price_cents?: number;
    size_brand?: string;
    size_range?: number[];
    sku: string;
    slug: string;
  };
  id?: number;
  product_id?: number;
  slug: string;
  vendor_id: string;
}

export interface GoatCollectionResult {
  vendor_id?: string;
  slug: string;
  name: string;
  plugd_products: ProductsAPI.Product[];
  products: GoatProduct[];
}

export interface ProductRaffleChannel {
  id: number;
  key: string;
  name: string;
}

export interface ProductReleaseChannel {
  id: number;
  key: string;
  name: string;
}

export interface UserRaffleEntry {
  id: number;
  user_id: string;
  product_raffle_id: number;
}

export interface FirebaseTokenClaims extends ParsedToken {
  cometChatAuthToken?: string;
  role?: UserClaimsRole;
  iss: string;
  aud: string;
  user_id: string;
  sub: string;
  email: string;
}

export interface FirebaseToken {
  token: string;
  expirationTime: string;
  authTime: string;
  issuedAtTime: string;
  claims?: FirebaseTokenClaims;
}

export enum IdType {
  Vendor = "vendorId",
  Slug = "slug",
  VendorSlug = "vendorSlug",
}

export enum UserProfileIdType {
  Username = "username",
  Id = "userId",
}
export interface ExchangeRates {
  base: string;
  date: string;
  rates: {
    [currencyCode: string]: number;
  };
  timestamp: number;
}
