import useScreenSize, { ScreenSize } from "./useScreenSize";

export type UseScreenSizeValueOptions<T> = Partial<Record<ScreenSize, T>>;

const useScreenSizeValue = <T>(options: UseScreenSizeValueOptions<T>, defaultValue: T): T => {
  const screenSize = useScreenSize();
  return options[screenSize] || defaultValue;
};

export default useScreenSizeValue;
