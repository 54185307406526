import { retry } from "ts-retry-promise";
import { captureException } from "../helpers/errorUtil";

const countryList = (() => {
  let lib: typeof import("country-list") | undefined;
  return (): Promise<typeof import("country-list") | void> => {
    if (lib) {
      return Promise.resolve(lib);
    }
    return retry(() => import(/* webpackChunkName: "country-list" */ "country-list"), {
      retries: 5,
    })
      .then((cl) => {
        cl.overwrite([{ code: "TW", name: "Taiwan" }]);
        lib = cl;
        return cl;
      })
      .catch((error) => {
        captureException(error);
      });
  };
})();

export default countryList;
