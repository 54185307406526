import React from "react";
import styled from "styled-components";
import { PostsAPI } from "../../typings/API-V2";
import { Box } from "../../UI";
import LinkingWrapper from "../links/LinkingWrapper";

export interface PostContentProps {
  content: Required<PostsAPI.Post>["content"];
}

const PostContent: React.FC<PostContentProps> = ({ content }) => {
  return (
    <ContentWrapper>
      <LinkingWrapper affiliateLinkType="post" text={content || ""} />
    </ContentWrapper>
  );
};

const ContentWrapper = styled(Box)`
  white-space: pre-wrap;
  width: 100%;
`;
ContentWrapper.defaultProps = {
  bg: "white",
  p: 3,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
};

export default PostContent;
