import immer from "immer";
import { VendorPricesMeta } from "../actions/createActions";
import ActionStatus from "../enums/ActionStatus";
import ActionType from "../enums/ActionType";
import PriceType from "../enums/PriceType";
import ShoeCondition from "../enums/ShoeCondition";
import { formatRawPrice, groupByShoeCondition, mapPrices } from "../helpers/askUtil";
import { ActionValue } from "../helpers/thunkUtil";
import { AskValue, BidValue, LowestAsksByVendorReturn } from "../typings/API";

export interface AsksState {
  asksFetching: Record<string, Set<string>>;
  asks: Record<string, Partial<LowestAsksByVendorReturn>>;
  asksUsedFetching: Record<string, Set<string>>;
  asksUsed: Partial<{
    [slug: string]: Partial<LowestAsksByVendorReturn>;
  }>;
  asksUsedSizesFetched: Record<string, Set<string>>;
  mappedBids: Record<string, BidValue>;
  priceHistory: {
    productIdsFetching: Set<number>;
    byProductId: Record<number, number[]>;
  };
}

const initialState: AsksState = {
  asks: {},
  asksFetching: {},
  asksUsed: {},
  asksUsedFetching: {},
  asksUsedSizesFetched: {},
  mappedBids: {},
  priceHistory: {
    productIdsFetching: new Set<number>(),
    byProductId: {},
  },
};

const getVendorPrices = (state: AsksState, action): AsksState => {
  switch (action.status) {
    case ActionStatus.FETCH: {
      const { productSlug, vendorSlug, conditions, size } = action.meta as VendorPricesMeta;
      return immer(state, (draft): AsksState => {
        if (conditions.includes(ShoeCondition.New)) {
          draft.asks[productSlug] = {};
          if (!draft.asksFetching[productSlug]) {
            draft.asksFetching[productSlug] = new Set();
          }
          draft.asksFetching[productSlug].add(vendorSlug);
        }
        if (conditions.includes(ShoeCondition.Used)) {
          const usedSlugObj = draft.asksUsed[productSlug];
          if (usedSlugObj) {
            usedSlugObj[vendorSlug] = [];
          }
          if (!draft.asksUsedFetching[productSlug]) {
            draft.asksUsedFetching[productSlug] = new Set();
          }
          draft.asksUsedFetching[productSlug].add(vendorSlug);
          if (!draft.asksUsedSizesFetched[productSlug]) {
            draft.asksUsedSizesFetched[productSlug] = new Set();
          }
          if (size) {
            draft.asksUsedSizesFetched[productSlug].add(size);
          }
        }
        return draft;
      });
    }
    case ActionStatus.SUCCESS: {
      // const askValues = action.payload;
      const priceValues = action.payload;
      const askValues: AskValue[] = priceValues.asks.map((a) => formatRawPrice(a, PriceType.Ask));
      const bidValues: BidValue[] = priceValues.bids
        ? priceValues.bids.map((b) => formatRawPrice(b, PriceType.Bid))
        : [];
      const asksByCondition = groupByShoeCondition(askValues);
      const mappedBids = mapPrices(bidValues);
      const { productSlug, vendorSlug, conditions } = action.meta;

      return immer(state, (draft): AsksState => {
        if (conditions.includes(ShoeCondition.New)) {
          const currentAsksByVendor = draft.asks[productSlug];
          draft.asks = {
            ...draft.asks,
            [productSlug]: {
              ...currentAsksByVendor,
              [vendorSlug]: asksByCondition.new,
            },
          };
          draft.asksFetching[productSlug].delete(vendorSlug);
        }

        if (conditions.includes(ShoeCondition.Used)) {
          const currentAsksUsedByVendor = draft.asksUsed[productSlug];
          draft.asksUsed = {
            ...draft.asksUsed,
            [productSlug]: {
              ...currentAsksUsedByVendor,
              [vendorSlug]: asksByCondition.used,
            },
          };
          draft.asksUsedFetching[productSlug].delete(vendorSlug);
        }

        if (mappedBids) {
          draft.mappedBids = {
            ...draft.mappedBids,
            ...mappedBids,
          };
        }

        return draft;
      });
    }
    case ActionStatus.FAIL: {
      // const error = action.payload;
      const { vendorSlug, productSlug, conditions } = action.meta;
      return immer(state, (draft): AsksState => {
        if (conditions.includes(ShoeCondition.New)) {
          const currentAsksByVendor = draft.asks[productSlug];
          draft.asks = {
            ...draft.asks,
            [productSlug]: {
              ...currentAsksByVendor,
              [vendorSlug]: null,
            },
          };
          draft.asksFetching[productSlug].delete(vendorSlug);
        }

        if (conditions.includes(ShoeCondition.Used)) {
          const currentAsksUsedByVendor = draft.asksUsed[productSlug];
          draft.asksUsed = {
            ...draft.asksUsed,
            [productSlug]: {
              ...currentAsksUsedByVendor,
              [vendorSlug]: null,
            },
          };
          draft.asksUsedFetching[productSlug].delete(vendorSlug);
        }
        return draft;
      });
    }
    default:
      return state;
  }
};

const getUsedBid = (state: AsksState, action): AsksState => {
  switch (action.status) {
    case ActionStatus.SUCCESS: {
      const bidValue = formatRawPrice(action.payload, PriceType.Bid);
      return immer(state, (draft): AsksState => {
        const mappedBids = mapPrices([bidValue as BidValue]);
        if (mappedBids) {
          draft.mappedBids = {
            ...draft.mappedBids,
            ...mappedBids,
          };
        }
        return draft;
      });
    }
    default:
      return state;
  }
};

const getPriceHistory = (state: AsksState, action): AsksState => {
  const { productId } = action.meta as { productId: number };
  switch (action.status) {
    case ActionStatus.FETCH: {
      return immer(state, (draft) => {
        draft.priceHistory.productIdsFetching.add(productId);
        return draft;
      });
    }
    case ActionStatus.SUCCESS: {
      const { priceHistory } = action.payload as { priceHistory: number[] };
      return immer(state, (draft) => {
        draft.priceHistory.byProductId[productId] = priceHistory;
        draft.priceHistory.productIdsFetching.delete(productId);
        return draft;
      });
    }
    case ActionStatus.FAIL: {
      return immer(state, (draft) => {
        draft.priceHistory.productIdsFetching.delete(productId);
        return draft;
      });
    }
    default:
      return state;
  }
};

export default (state: AsksState = initialState, action: ActionValue): AsksState => {
  switch (action.type) {
    case ActionType.GET_VENDOR_ASKS: {
      return getVendorPrices(state, action);
    }
    case ActionType.GET_USED_BID: {
      return getUsedBid(state, action);
    }
    case ActionType.GET_PRODUCT_PRICE_HISTORY: {
      return getPriceHistory(state, action);
    }
    default:
      return state;
  }
};
