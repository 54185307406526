import React, { useState } from "react";
import { MenuProps } from "../../../../hooks/useMenuState";
import { Spacer } from "../../../../UI";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
} from "../../../Layout/FullscreenMenu";
import { DEFAULT_NAV_TABS_HEIGHT } from "../../../NavTabs";
import DebugLogNativeApp from "./DebugLogNativeApp";
import DebugLogWebView from "./DebugLogWebView";
import DebugMenuTabs from "./DebugMenuTabs";

export interface DebugMenuProps extends MenuProps {}

const DebugMenu: React.FC<DebugMenuProps> = ({ isOpen, close }) => {
  const [activeScreen, setActiveScreen] = useState<"webview" | "nativeapp">("webview");
  return (
    <FullscreenMenu
      transition={transitionProps}
      isOpen={isOpen}
      close={close}
      overflowX={"hidden"}
      p={3}
      header={
        <>
          <FullscreenMenuHeader>
            <FullscreenMenuHeaderIcon onClick={close} name="chevron left" />
            <FullscreenMenuHeaderTitle>{"Error Log"}</FullscreenMenuHeaderTitle>
            <FullscreenMenuHeaderIcon />
          </FullscreenMenuHeader>
          <DebugMenuTabs activeScreen={activeScreen} setActiveScreen={setActiveScreen} />
        </>
      }
    >
      <>
        <Spacer mt={DEFAULT_NAV_TABS_HEIGHT} />
        {activeScreen === "webview" ? <DebugLogWebView isOpen={isOpen} /> : <DebugLogNativeApp isOpen={isOpen} />}
      </>
    </FullscreenMenu>
  );
};

const transitionProps = { duration: 400, animation: "fade left" };

export default DebugMenu;
