import React, { useContext } from "react";
import { DefaultToastContainer, ToastContainerProps as RTNToastContainerProps } from "react-toast-notifications";
import styled, { css } from "styled-components";
import { isMobileFrame } from "../../config";
import { theme } from "../../UI";
import { TOP_NAV_HEIGHT } from "../Layout/TopNav";
import { ToastContext } from "./ToastProvider";

export interface ToastContainerProps extends RTNToastContainerProps {}

const ToastContainer: React.FC<ToastContainerProps> = ({ ...props }) => {
  const { bottomOffset } = useContext(ToastContext);
  return <CustomToastContainer bottomOffset={bottomOffset} {...props} />;
};

const CustomToastContainer = styled(DefaultToastContainer)<ToastContainerProps & { bottomOffset: number }>`
  && {
    max-width: 100%;
    bottom: ${({ bottomOffset }) => bottomOffset}px;

    ${() =>
      !isMobileFrame &&
      css`
        ${theme.mediaQueries.large} {
          top: ${TOP_NAV_HEIGHT}px;
        }
      `}

    .react-toast-notifications__toast__icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .react-toast-notifications__toast__content {
      display: flex;
      align-items: center;
    }
    .react-toast-notifications__toast__dismiss-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default ToastContainer;
