import { ExifTagValue } from "blueimp-load-image";
import { useCallback, useMemo } from "react";
import { EntitiesState } from "redux-query";
import { useMutation } from "redux-query-react";
import { retry } from "ts-retry-promise";
import { captureException } from "../../../../helpers/errorUtil";
import { actionPromiseToPromise } from "../../../../helpers/reactQueryUtil";
import useLibImport from "../../../../hooks/useLibImport";
import { createPostImageUploadUrl } from "../../../../queries/api/postQuery";
import { PostsAPI } from "../../../../typings/API-V2";
import { ErrorResponse } from "../../../../typings/API-V2/common";
import { ImageMeta } from "./useImageInputHandler";

/* converts HTMLCanvasElement toBlob method to promise */
const canvasToBlobPromise = (
  canvas: HTMLCanvasElement,
  mimeType?: string | undefined,
  quality?: any, // "any" is what is used in official "toBlob" argument type
): Promise<Blob | null> => new Promise((resolve) => canvas.toBlob(resolve, mimeType, quality));

type UploadImage = (imageMeta: ImageMeta) =>
  | Promise<{
      localUrl: string;
      insert: Omit<PostsAPI.PostImageInsert, "tags">;
    }>
  | never;

export type UseImageUploader = () => {
  isReady: boolean;
  uploadImage: UploadImage;
};

const IMAGE_MIME_TYPE = "image/jpeg";

/**
 * Handles formatting, requesting a signed url from google cloud storage, and uploading the image
 * to the Google storage bucket.
 */
const useImageUploader: UseImageUploader = () => {
  const { lib: blueimpLoadImage } = useLibImport(import("blueimp-load-image"));
  const isReady = Boolean(blueimpLoadImage);
  const [, getPostImageUrl] = useMutation(createPostImageUploadUrl);

  const upload = useCallback<UploadImage>(
    async (imageMeta: ImageMeta) => {
      if (!blueimpLoadImage) {
        throw new Error(`blueimpLoadImage library not yet ready`);
      }

      const loadImage = blueimpLoadImage.default;

      /** get the uploadUrl and load image on canvas for manipulation */
      const [uploadUrlResponse, loadedImage] = await Promise.all([
        // get the signed url for uploading
        retry(
          () =>
            actionPromiseToPromise<EntitiesState, PostsAPI.GetSignedUrlResponse | ErrorResponse>(
              getPostImageUrl(IMAGE_MIME_TYPE),
            ),
          {
            retries: 3,
          },
        ),
        // write the image to canvas for formatting
        loadImage(imageMeta.file, { canvas: true, orientation: true, meta: true }),
      ]);

      if (
        !uploadUrlResponse.body ||
        !uploadUrlResponse.body.success ||
        !loadedImage.originalHeight ||
        !loadedImage.originalWidth
      ) {
        throw new Error("Could not retrieve upload url");
      }

      /** reference: https://sirv.com/help/articles/rotate-photos-to-be-upright/ */
      const landscapeValues: ExifTagValue[] = [6, 8, 5, 7];
      /** flips original height and width for images flipped in a variation of 90% */
      const imageDimensions =
        loadedImage.exif && landscapeValues.includes(loadedImage.exif.get("Orientation"))
          ? {
              height: loadedImage.originalWidth,
              width: loadedImage.originalHeight,
            }
          : {
              height: loadedImage.originalHeight,
              width: loadedImage.originalWidth,
            };

      // convert to the image back to blob for uploading
      const imgBlob = await canvasToBlobPromise(loadedImage.image as unknown as HTMLCanvasElement, IMAGE_MIME_TYPE, 1);

      if (!imgBlob) {
        throw new Error("could not convert image to blob");
      }

      /** upload the image blob to google cloud storage */
      const { url: uploadUrl, publicUrl } = uploadUrlResponse.body.data;
      try {
        await fetch(uploadUrl, {
          body: imgBlob,
          headers: {
            "Content-Type": IMAGE_MIME_TYPE,
          },
          method: "PUT",
        });

        console.log(`image uploaded to ${publicUrl}`);

        return {
          localUrl: imageMeta.localUrl,
          insert: {
            url: publicUrl,
            height: imageDimensions.height,
            width: imageDimensions.width,
          },
        };
      } catch (error) {
        captureException(error);
        throw error;
      }
    },
    [blueimpLoadImage, getPostImageUrl],
  );

  return useMemo(
    () => ({
      isReady,
      uploadImage: upload,
    }),
    [upload, isReady],
  );
};

export default useImageUploader;
