import produce from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSigninCheck } from "reactfire";
import { EntitiesState, updateEntities } from "redux-query";
import { createSelector } from "reselect";
import useDeepCompareEffect from "use-deep-compare-effect";
import { generateListKey, handleHasMore } from "../helpers/queryUtil";
import { GetRestocksOptions, listRestocks } from "../queries/api-v2/restocksQuery";
import { RootState } from "../store";
import RestocksAPI from "../typings/API-V2/RestocksAPI";
import useControlledRequest from "./useControlledRequest";

interface UseRestocksListOptions extends GetRestocksOptions {}

const useRestocksList = (
  options: UseRestocksListOptions = {},
): {
  fetchNext: () => void;
  hasMore: boolean;
  isFinished: boolean;
  isPending: boolean;
  listKey: string;
  restocks: RestocksAPI.Restock[];
  refresh: () => void;
} => {
  const [localOptions, setLocalOptions] = useState<UseRestocksListOptions>(options);
  const [hasMore, setHasMore] = useState<boolean>(true);
  useEffect(() => {
    if (!shallowEqual(localOptions, options)) {
      setLocalOptions(options);
    }
  }, [localOptions, options]);
  const dispatch = useDispatch();
  const listKey = generateListKey<UseRestocksListOptions>(localOptions);
  const { status: signInStatus } = useSigninCheck();
  const restocksSelector = useMemo(
    () =>
      createSelector(
        (state: RootState) => state.entities.restocksById,
        (state: RootState) => state.entities.restocksListsByKey,
        (restocksById, restocksListsByKey) => {
          const restocksListIdsList = restocksListsByKey[listKey] || [];
          return restocksListIdsList.map((restockId) => restocksById[restockId]);
        },
      ),
    [listKey],
  );

  const restocks = useSelector<RootState, RestocksAPI.Restock[]>(restocksSelector);

  const [{ isFinished, isPending }, fetchRestocks] = useControlledRequest(listRestocks);

  /** initial fetch */
  useDeepCompareEffect(() => {
    if (signInStatus !== "loading") {
      handleHasMore<RestocksAPI.ListRestocksResponse, RestocksAPI.Restock>(
        fetchRestocks(localOptions),
        (body) => body?.data.restocks,
        setHasMore,
        localOptions.limit,
      );
    }
  }, [signInStatus, fetchRestocks, localOptions]);

  const refresh = useCallback(() => {
    dispatch(
      updateEntities<EntitiesState>({
        restocksListsByKey: (prevState) =>
          produce(prevState, (draft) => {
            delete draft[listKey];
          }),
      }),
    );

    handleHasMore<RestocksAPI.ListRestocksResponse, RestocksAPI.Restock>(
      fetchRestocks(localOptions),
      (body) => body?.data.restocks,
      setHasMore,
      localOptions.limit,
    );
  }, [dispatch, fetchRestocks, listKey, localOptions]);

  const fetchNext = useCallback(() => {
    if (!isPending) {
      handleHasMore<RestocksAPI.ListRestocksResponse, RestocksAPI.Restock>(
        fetchRestocks({
          ...localOptions,
          offset: restocks.length,
        }),
        (body) => body?.data.restocks,
        setHasMore,
        localOptions.limit,
      );
    }
  }, [fetchRestocks, isPending, localOptions, restocks.length]);

  return useMemo(
    () => ({
      fetchNext,
      hasMore,
      isFinished,
      isPending,
      listKey,
      restocks,
      refresh,
    }),
    [fetchNext, hasMore, isPending, isFinished, listKey, restocks, refresh],
  );
};

export default useRestocksList;
