import { useCallback, useContext, useEffect } from "react";
import { useSigninCheck } from "reactfire";
import AuthScreensContext from "../components/AuthScreenModal/AuthScreensContext";
import { isSyntheticEvent } from "../helpers/reactUtil";

export interface UseAuthenticatedCallbackOptions<T> {
  beforeAuthCheck?: T;
  onAuthenticated?: () => void;
}

const useAuthenticatedCallback = <T extends (...args: any[]) => any>(
  cb: T,
  deps: React.DependencyList,
  options?: UseAuthenticatedCallbackOptions<T>,
): T | (() => void) => {
  const callback = useCallback<T>(cb, deps);
  const { data } = useSigninCheck();
  const isAuthenticated = data?.signedIn;
  const { setOnAuthenticated } = useContext(AuthScreensContext);
  const { setAuthScreen } = useContext(AuthScreensContext);
  const beforeAuthCheck = options?.beforeAuthCheck;
  const onAuthenticated = options?.onAuthenticated;

  useEffect(() => {
    if (onAuthenticated) {
      setOnAuthenticated(() => onAuthenticated);
    }
  }, [onAuthenticated, setOnAuthenticated]);

  return useCallback(
    (...args) => {
      if (beforeAuthCheck) {
        beforeAuthCheck(...args);
      }
      if (!isAuthenticated) {
        /** if the callback's first argument is a React SyntheticEvent and the user is not authenticated, automatically stop propagation and default behavior (e.g. navigation events) */
        if (isSyntheticEvent(args[0])) {
          const event = args[0];
          event.stopPropagation();
          event.preventDefault();
        }
        return setAuthScreen("login");
      }

      return callback(...args);
    },
    [callback, isAuthenticated, beforeAuthCheck, setAuthScreen],
  );
};

export default useAuthenticatedCallback;
