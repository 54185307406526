import React, { useEffect } from "react";
import styled from "styled-components";
import config from "../../../config";
import events, { trackEvent } from "../../../helpers/analyticsUtil";
import { MenuProps } from "../../../hooks/useMenuState";
import appStoreBadge from "../../../images/app-store-badge.svg";
import googlePlayBadge from "../../../images/google-play-badge.svg";
import { Modal } from "../../../libs/semantic-ui";
import { Box, Image, Spacer, Text } from "../../../UI";
import OutboundLink from "../../links/OutboundLink";

export interface AppDownloadModalProps extends MenuProps {}

const AppDownloadModal: React.FC<AppDownloadModalProps> = ({ isOpen, close }) => {
  useEffect(() => {
    trackEvent(events.AppDownloadModal.Viewed);
  }, []);

  return (
    <Modal open={isOpen} dimmer={true} size="mini" title="Download the app">
      <Box
        alignItems="center"
        bg="white"
        borderRadius={3}
        color="black"
        display="flex"
        flexDirection="column"
        px={4}
        py={5}
      >
        <Text textAlign="center" fontSize={3} fontWeight={5}>
          {`Download the app`}
        </Text>
        <Spacer mt={3} />
        <Text
          fontSize={2}
          color="darkGrey"
          textAlign="center"
        >{`This feature is only available on the Plugd mobile app.`}</Text>
        <Box display="flex" my={4}>
          <OutboundLink revenue={0.1} href={config.BRANCH_IOS_URL}>
            <BadgeImage alt="iOS store banner" className="lazyload" data-src={appStoreBadge} />
          </OutboundLink>
          <Spacer ml={1} />
          <OutboundLink revenue={0.1} href={config.BRANCH_ANDROID_URL}>
            <BadgeImage alt="Play store banner" className="lazyload" data-src={googlePlayBadge} />
          </OutboundLink>
        </Box>
        <Text fontSize={0} clickable={true} onClick={close}>
          Dismiss
        </Text>
      </Box>
    </Modal>
  );
};

const BadgeImage = styled(Image)``;
BadgeImage.defaultProps = {
  height: 35,
};

export default AppDownloadModal;
