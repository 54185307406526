import React from "react";
import styled from "styled-components";
import { Placeholder } from "../../libs/semantic-ui";
import { Box } from "../../UI";
import useScreenSize from "../../UI/utils/useScreenSize";

export interface PostPlaceholderProps {}

const PostPlaceholder: React.FC<PostPlaceholderProps> = () => {
  const screenSize = useScreenSize();
  return (
    <PostWrapper borderRadius={screenSize === "large" ? 3 : undefined}>
      <Box px={3} py={2} borderBottom="1px solid" borderColor="lightGrey">
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Box>
      <Box p={3}>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Box>
    </PostWrapper>
  );
};

const PostWrapper = styled(Box)``;
PostWrapper.defaultProps = {
  width: "100%",
  bg: "white",
  mt: 3,
};

export default PostPlaceholder;
