import React, { PropsWithChildren, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "reactfire";
import { useMutation } from "redux-query-react";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import useAuthenticatedCallback from "../../hooks/useAuthenticatedCallback";
import useConfirmationCallback from "../../hooks/useConfirmationCallback";
import { followUser, unfollowUser } from "../../queries/api/userFollowQuery";
import { RootState } from "../../store";
import { UsersAPI } from "../../typings/API";
import { Button, ButtonProps } from "../../UI";

export interface FollowButtonProps extends ButtonProps {
  targetUserId: string;
  isFollower: boolean;
  isFollowee: boolean;
  flat?: boolean;
}

const FollowButton: React.FC<FollowButtonProps> = ({
  targetUserId,
  isFollowee,
  isFollower,
  variant,
  flat,
  ...buttonProps
}) => {
  const [, follow] = useMutation(followUser);
  const [, unfollow] = useMutation(unfollowUser);
  const auth = useAuth();
  const targetUser = useSelector<RootState, UsersAPI.PublicProfile>(
    (state) => state.entities.userProfilesById[targetUserId],
  );

  const unfollowWithConfirmation = useConfirmationCallback(
    `Unfollow${targetUser ? ` @${targetUser.username}?` : `?`}`,
    unfollow,
    [],
  );

  const handleFollowButtonClick = useAuthenticatedCallback<
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  >(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (auth.currentUser?.uid && targetUserId) {
        if (isFollowee) {
          unfollowWithConfirmation(auth.currentUser?.uid, targetUserId);
          trackEvent(events.User.Unfollowed, {
            targetUserId,
          });
        } else {
          follow(auth.currentUser?.uid, targetUserId);
          trackEvent(events.User.Followed, {
            targetUserId,
          });
        }
      }
    },
    [targetUserId, follow, auth.currentUser?.uid, unfollow],
  );

  const generatedButtonProps = useMemo<PropsWithChildren<ButtonProps>>(() => {
    if (isFollower && isFollowee) {
      return {
        variant: variant || "tertiary",
        children: "Friends",
      };
    }
    if (isFollower && !isFollowee) {
      return {
        variant: variant || "primary",
        children: "Follow Back",
      };
    }
    if (!isFollower && isFollowee) {
      return {
        variant: variant || "tertiary",
        children: "Following",
      };
    }
    return {
      variant: variant || "primary",
      children: "Follow",
    };
  }, [isFollower, isFollowee, variant]);

  return <Button flat={flat} onClick={handleFollowButtonClick} {...generatedButtonProps} {...buttonProps} />;
};

export default FollowButton;
