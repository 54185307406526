import React, { useCallback } from "react";
import { FallbackProps } from "react-error-boundary";
import { Box, Button, Card, Spacer, Text } from "../UI";

export interface FallbackErrorProps extends FallbackProps {}

const FallbackError: React.FC<FallbackErrorProps> = () => {
  const handleClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Card display="flex" alignItems="center" justifyContent="center" flexDirection="column" bg="white" p={4} mb={4}>
        <Text fontWeight={5} fontSize={3} color="black">
          {`Update Available`}
        </Text>
        <Spacer mt={1} />
        <Text fontSize={2} color="darkGrey">
          {`We've made some improvements 💪`}
        </Text>
        <Spacer mt={4} />
        <Button variant="primary" onClick={handleClick}>
          {`Let's Go!`}
        </Button>
      </Card>
    </Box>
  );
};

export default FallbackError;
