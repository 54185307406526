import React from "react";
import Imgix, { SharedImgixAndSourceProps } from "react-imgix";
import styled, { DefaultTheme } from "styled-components";
import { border, BorderProps, layout, LayoutProps } from "styled-system";

const defaultImgixParams: SharedImgixAndSourceProps["imgixParams"] = {
  auto: "format",
};

export type ImgixImageComponentProps = SharedImgixAndSourceProps &
  BorderProps<DefaultTheme> &
  LayoutProps<DefaultTheme>;

export type ImgixImageProps = ImgixImageComponentProps & {
  alt: string;
};

const ImgixImage: React.FC<ImgixImageProps> = ({ imgixParams, alt, htmlAttributes, ...props }) => {
  return (
    <ImgixImageComponent
      htmlAttributes={{
        alt,
        ...htmlAttributes,
      }}
      imgixParams={{
        ...defaultImgixParams,
        ...imgixParams,
      }}
      {...props}
    />
  );
};

const ImgixImageComponent = styled(Imgix)<ImgixImageComponentProps>`
  ${border}
  ${layout}
`;

export default ImgixImage;
