import produce from "immer";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { StripeAPI } from "../../typings/API";
import EntitiesState from "../../typings/EntitiesState";
import { formatUserProfileIdsByUsername, formatUserProfilesById } from "./userProfileQuery";

export const getAccountLink = (): QueryConfig<Pick<EntitiesState, "stripeAccountLink">> => {
  return {
    url: `${config.API_URL}/stripe/account-links`,
    meta: {
      includeToken: true,
    },
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: StripeAPI.GetAccountLinkResponse) => ({
      stripeAccountLink: response.data.link,
    }),
    update: {
      stripeAccountLink: (_, newValue) => newValue,
    },
  };
};

export const getAccount = (): QueryConfig<Pick<EntitiesState, "stripeAccount">> => {
  return {
    url: `${config.API_URL}/stripe/account`,
    meta: {
      includeToken: true,
    },
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: StripeAPI.GetAccountResponse) => ({
      stripeAccount: response.data.account,
    }),
    update: {
      stripeAccount: (_, newValue) => newValue,
    },
  };
};

/**
 *
 * @param postId
 * @returns
 */
export const createCheckoutSession = (
  postId: number,
): QueryConfig<Pick<EntitiesState, "stripeCheckoutSessionsById">> => {
  return {
    url: `${config.API_URL}/stripe/checkout-sessions`,
    force: true, // always create a new checkout session
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    body: {
      post_id: postId,
    },
    transform: (response: StripeAPI.CreateCheckoutSessionResponse) => ({
      stripeCheckoutSessionsById: { [response.data.session.id]: response.data.session },
    }),
    update: {
      stripeCheckoutSessionsById: shallowObjectMerge,
    },
  };
};

/**
 *
 * @param sessionId Stripe checkout session ID
 * @returns
 */
export const getCheckoutSession = (
  sessionId: string,
): QueryConfig<Pick<EntitiesState, "stripeCheckoutSessionsById" | "userProfilesById" | "userProfileIdsByUsername">> => {
  return {
    url: `${config.API_URL}/stripe/checkout-sessions/${sessionId}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "GET",
      headers: STANDARD_HEADERS,
    },
    transform: (response: StripeAPI.GetCheckoutSessionResponse) => ({
      stripeCheckoutSessionsById: { [response.data.session.id]: response.data.session },
      userProfilesById: formatUserProfilesById(response.data.sellers),
      userProfileIdsByUsername: formatUserProfileIdsByUsername(response.data.sellers),
    }),
    update: {
      stripeCheckoutSessionsById: shallowObjectMerge,
      userProfilesById: shallowObjectMerge,
      userProfileIdsByUsername: shallowObjectMerge,
    },
  };
};

export const createLoginLink = (): QueryConfig<Pick<EntitiesState, "stripeLoginLink">> => {
  return {
    url: `${config.API_URL}/stripe/login-links`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (response: StripeAPI.CreateLoginLinkResponse) => ({
      stripeLoginLink: response.data.link,
    }),
    update: {
      stripeLoginLink: (_, newValue) => newValue,
    },
  };
};

export const createOrder = (productListingId: number): QueryConfig<Pick<EntitiesState, "stripeOrder">> => {
  return {
    url: `${config.API_URL}/stripe/orders`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    body: {
      product_listing_id: productListingId,
    },
    transform: (response: StripeAPI.Order.CreateOrderResponse) => ({
      stripeOrder: response.data.order,
    }),
    update: {
      stripeOrder: (_, newValue) => newValue,
    },
  };
};

export const getOrder = (
  orderId: string,
): QueryConfig<Pick<EntitiesState, "stripeOrder" | "userProfilesById" | "userProfileIdsByUsername">> => {
  return {
    url: `${config.API_URL}/stripe/orders/${orderId}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "GET",
      headers: STANDARD_HEADERS,
    },
    transform: (response: StripeAPI.Order.GetOrderResponse) => ({
      stripeOrder: response.data.order,
      userProfilesById: formatUserProfilesById(response.data.sellers),
      userProfileIdsByUsername: formatUserProfileIdsByUsername(response.data.sellers),
    }),
    update: {
      stripeOrder: (_, newValue) => newValue,
      userProfilesById: shallowObjectMerge,
      userProfileIdsByUsername: shallowObjectMerge,
    },
  };
};

export const patchOrder = (
  orderId: string,
  updates: StripeAPI.Order.PatchUpdate,
): QueryConfig<Pick<EntitiesState, "stripeOrder">> => {
  return {
    url: `${config.API_URL}/stripe/orders/${orderId}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "PATCH",
      headers: STANDARD_HEADERS,
    },
    body: updates,
    optimisticUpdate: {
      stripeOrder: produce((draft) => {
        if (draft) {
          for (const key in updates) {
            draft[key] = updates[key];
          }
        }
      }),
    },
    transform: (response: StripeAPI.Order.PatchOrderResponse) => ({
      stripeOrder: response.data.order,
    }),
    update: {
      stripeOrder: (_, newValue) => newValue,
    },
  };
};
