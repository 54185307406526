import { sortBy } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { retry } from "ts-retry-promise";
import { captureException } from "../../helpers/errorUtil";
import useExchangeRates from "../../hooks/useExchangeRates";
import { Form, Header, Modal } from "../../libs/semantic-ui";

export interface CurrencySettingsProps {
  display?: "currency" | "shipping" | "size";
  currency?: string;
  onChange: (value: string) => void;
}

const CurrencyOptionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CurrencySettings: React.FC<CurrencySettingsProps> = ({ display, currency, onChange }) => {
  const [currencySymbolLib, setCurrencySymbolLib] = useState<typeof import("currency-symbol-map") | undefined>(
    undefined,
  );
  const {
    exchangeRates,
    queryState: { isPending: exchangeRatesPending },
  } = useExchangeRates();

  useEffect(() => {
    retry(() => import("currency-symbol-map"), { retries: 5 })
      .then((lib) => setCurrencySymbolLib(() => lib.default))
      .catch((error) => captureException(error));
  }, [setCurrencySymbolLib]);

  const handleCurrencyChange = useCallback(
    (e, data): void => {
      onChange(data.value);
    },
    [onChange],
  );

  const currencyOptions = useMemo(
    () =>
      exchangeRates && exchangeRates.rates && currencySymbolLib
        ? sortBy(
            Object.keys(exchangeRates.rates).map((c) => ({
              key: c,
              value: c,
              text: c,
              content: (
                <CurrencyOptionItem>
                  <div>{c}</div>
                  <div>{currencySymbolLib(c)}</div>
                </CurrencyOptionItem>
              ),
            })),
            (v) => v.text,
          )
        : [],
    [exchangeRates, currencySymbolLib],
  );

  if (display && display !== "currency") {
    return null;
  }

  return (
    <Modal.Content>
      <Header as="h3">Currency Preference</Header>
      <Form>
        <Form.Field>
          <Form.Dropdown
            loading={exchangeRatesPending || !currencySymbolLib}
            required
            label={`Currency`}
            value={currency}
            placeholder="Select Currency"
            search
            selection
            options={currencyOptions}
            fluid
            onChange={handleCurrencyChange}
          />
        </Form.Field>
      </Form>
    </Modal.Content>
  );
};

export default CurrencySettings;
