import React, { lazy, Suspense, useCallback, useMemo, useState } from "react";
import { CurrencyInputProps } from "react-currency-input-field";
import styled from "styled-components";
import { retry } from "ts-retry-promise";
import { Box, Text } from "../../../UI";
import { UseInputHook } from "../types/inputHooks";

const CurrencyInput = lazy(() =>
  retry(() => import(/* webpackChunkName: "react-currency-input-field" */ "react-currency-input-field"), {
    retries: 5,
  }),
);

export interface UsePriceInputOptions {}

const usePriceInput: UseInputHook<string, string | undefined> = (defaultValue) => {
  const [price, setPrice] = useState<string>(defaultValue || "");

  const onChange = useCallback<NonNullable<CurrencyInputProps["onValueChange"]>>((value) => setPrice(value || ""), []);

  const input = useMemo(
    () => (
      <InputWrapper>
        <Label>Price</Label>
        <Suspense fallback={null}>
          <CustomCurrencyInput
            allowDecimals={false}
            allowNegativeValue={false}
            id="price-input"
            min="1"
            onValueChange={onChange}
            placeholder={"$0"}
            prefix="$"
            step={1}
            value={price}
          />
        </Suspense>
      </InputWrapper>
    ),
    [onChange, price],
  );

  const clearPrice = useCallback(() => setPrice(""), []);

  return useMemo(
    () => ({
      input,
      menus: null,
      clearValue: clearPrice,
      value: price,
    }),
    [input, price, clearPrice],
  );
};

const CustomCurrencyInput = styled(CurrencyInput)`
  outline-width: 0;
  border: none;
  text-align: right;
  flex-grow: 1;
`;

const Label = styled(Text)`
  flex-shrink: 0;
`;
Label.defaultProps = {
  fontWeight: 5,
  mr: 3,
  color: "darkGrey",
};

const InputWrapper = styled(Box)`
  & .price-input::placeholder {
    color: ${({ theme }) => theme.colors.mediumGrey};
  }
  & .price-input::before {
    content: "$";
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
InputWrapper.defaultProps = {
  p: 3,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
};

export default usePriceInput;
