import { isBoolean, keyBy, omitBy, toString } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { groupIdsBy, shallowObjectMerge, updateNestedUniqueArrays } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { OrderBy, ProductRaffleChannel, ProductRafflesAPI } from "../../typings/API";
import EntitiesState, { ProductLaunchState, VendorsState } from "../../typings/EntitiesState";
import { productsTransform } from "./productQuery";
import { vendorStateUpdates, vendorsToVendorsState } from "./vendorQuery";

export interface GetProductRafflesOptions {
  product_id?: number;
  country_code?: string;
  end_date?: string;
  order_by?: OrderBy;
  sort_by?: "end_date";
  has_url?: boolean;
}
export const getProductRaffles = (
  options: GetProductRafflesOptions,
): QueryConfig<Pick<EntitiesState, "raffleIdsByProductId" | "rafflesById" | "productsById" | "productIdsBySlug">> => {
  const searchParams = new URLSearchParams({
    order_by: options.order_by || "asc",
    sort_by: options.sort_by || "end_date",
  });
  if (options.product_id) {
    searchParams.set("product_id", toString(options.product_id));
  }
  if (options.country_code) {
    searchParams.set("country_code", options.country_code);
  }
  if (options.end_date) {
    searchParams.set("end_date", options.end_date);
  }
  if (options.order_by) {
    searchParams.set("order_by", options.order_by);
  }
  if (options.sort_by) {
    searchParams.set("sort_by", options.sort_by);
  }
  if (isBoolean(options.has_url)) {
    searchParams.set("has_url", toString(options.has_url));
  }
  const qs = searchParams.toString();

  return {
    url: `${config.API_URL}/product-raffles?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: ProductRafflesAPI.GetProductRafflesResponse) => ({
      rafflesById: keyBy(response.raffles, (r) => r.id),
      raffleIdsByProductId: groupIdsBy(response.raffles, (r) => r.product_id),
      ...productsTransform(response.products),
      ...vendorsToVendorsState(response.vendors),
    }),
    update: {
      rafflesById: shallowObjectMerge,
      raffleIdsByProductId: (oldValue, newValue) => updateNestedUniqueArrays(oldValue, newValue),
      productsById: shallowObjectMerge,
      productIdsBySlug: shallowObjectMerge,
      ...vendorStateUpdates,
    },
  };
};

export const updateProductRaffle = (
  raffle: ProductRafflesAPI.ProductRaffle,
): QueryConfig<Pick<ProductLaunchState, "rafflesById"> & Pick<VendorsState, "vendorsById" | "vendorIdsBySlug">> => ({
  url: `${config.API_URL}/product-raffles/${raffle.id}`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "PUT",
    headers: STANDARD_HEADERS,
  },
  body: { ...raffle },
  optimisticUpdate: {
    rafflesById: (prevValue) => ({
      ...prevValue,
      [raffle.id]: raffle,
    }),
  },
});

export const getUserRaffleEntries = (
  userId: string,
): QueryConfig<Pick<ProductLaunchState, "raffleEntriesByRaffleId">> => ({
  url: `${config.API_URL}/users/${userId}/raffle-entries`,
  meta: {
    includeToken: true,
  },
  options: {
    headers: STANDARD_HEADERS,
  },
  transform: (response: ProductRafflesAPI.GetUserRaffleEntriesResponse) => ({
    raffleEntriesByRaffleId: keyBy(response.entries, (e) => e.product_raffle_id),
  }),
  update: {
    raffleEntriesByRaffleId: shallowObjectMerge,
  },
});

export const createUserRaffleEntry = (
  userId: string,
  raffleId: number,
): QueryConfig<Pick<ProductLaunchState, "raffleEntriesByRaffleId">> => ({
  url: `${config.API_URL}/users/${userId}/raffle-entries`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  body: {
    user_id: userId,
    product_raffle_id: raffleId,
  },
  optimisticUpdate: {
    raffleEntriesByRaffleId: (prevValue) => ({
      ...prevValue,
      [raffleId]: {
        id: -1,
        product_raffle_id: raffleId,
        user_id: userId,
      },
    }),
  },
  transform: (response: ProductRafflesAPI.CreateUserRaffleEntryResponse) => ({
    raffleEntriesByRaffleId: {
      [raffleId]: {
        id: response.id,
        product_raffle_id: raffleId,
        user_id: userId,
      },
    },
  }),
  update: {
    raffleEntriesByRaffleId: shallowObjectMerge,
  },
});

export const deleteUserRaffleEntry = (
  userId: string,
  entryId: number,
): QueryConfig<Pick<ProductLaunchState, "raffleEntriesByRaffleId">> => ({
  url: `${config.API_URL}/users/${userId}/raffle-entries/${entryId}`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "DELETE",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    raffleEntriesByRaffleId: (prevValue) => omitBy(prevValue, (entry) => entry.id === entryId),
  },
});

export const getProductRaffleChannels = (): QueryConfig<Pick<ProductLaunchState, "productRaffleChannelsByKey">> => ({
  url: `${config.API_URL}/product-raffle-channels`,
  options: {
    headers: STANDARD_HEADERS,
  },
  transform: (response: { channels: ProductRaffleChannel[] }) => ({
    productRaffleChannelsByKey: keyBy(response.channels, (c) => c.key),
  }),
  update: {
    productRaffleChannelsByKey: (_, newValue) => newValue,
  },
});
