import React, { forwardRef, useCallback } from "react";
import styled from "styled-components";
import { isMutableRefObject } from "../../helpers/refUtil";
import Box, { BoxProps } from "./Box";
import Icon from "./Icon";
import Input, { InputProps } from "./Input";
import Spacer from "./Spacer";

export interface SearchInputProps extends Omit<InputProps, "color"> {
  wrapperProps?: BoxProps;
  height?: number;
  onClear?: React.MouseEventHandler<HTMLDivElement>;
  value?: string; // not sure why typescript doesn't get this from InputProps["value"]. Imports HTMLInputElement types from global.d.ts instead of lib.dom.d.ts. debug later
}

const DEFAULT_SEARCH_INPUT_HEIGHT = 32;

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ height, value, onClear, wrapperProps, ...inputProps }, ref) => {
    height = height || DEFAULT_SEARCH_INPUT_HEIGHT;

    const handleClear = useCallback<React.MouseEventHandler<HTMLDivElement>>(
      (e) => {
        if (onClear) {
          onClear(e);
          if (isMutableRefObject(ref) && ref.current) {
            ref.current.focus();
          }
        }
      },
      [onClear, ref],
    );

    return (
      <SearchWrapper height={height} borderRadius={height / 2} {...wrapperProps}>
        <Icon name="search" color="mediumGrey" />
        <Spacer ml={2} />
        <Input ref={ref} bg="lightGrey" type="search" width="100%" value={value} {...inputProps} />
        {onClear && value && value.length > 0 && (
          <Box role="button" onClick={handleClear}>
            <Icon name="times circle" color="mediumGrey" />
          </Box>
        )}
      </SearchWrapper>
    );
  },
);
SearchInput.displayName = "SearchInput";

const SearchWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
SearchWrapper.defaultProps = {
  bg: "lightGrey",
  px: 3,
  mx: 2,
};

export default SearchInput;
