import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../store";
import { CommunitiesAPI } from "../../typings/API";
import { Box, BoxProps, Icon, Label, Spacer, Text } from "../../UI";

export interface PostThemeMenuOpenerProps extends BoxProps {
  communityId: number;
  themeId?: number;
}

const PostThemeMenuOpener: React.FC<PostThemeMenuOpenerProps> = ({ communityId, themeId, ...boxProps }) => {
  const theme = useSelector<RootState, CommunitiesAPI.CommunityTheme | null>((state: RootState) => {
    if (!themeId) {
      return null;
    }
    const communityThemes = state.entities.communityThemesByCommunityId[communityId];
    if (!communityThemes) {
      return null;
    }
    return communityThemes.find((t) => t.id === themeId) || null;
  });

  return (
    <CursorOpenerContainer {...boxProps}>
      <Box display="flex" alignItems="center">
        <Text fontSize={1} color="darkGrey">
          Theme
        </Text>
        {theme && (
          <>
            <Spacer ml={2} />
            <Label color="black" bg="lightGrey" size="tiny">
              {theme.name}
            </Label>
          </>
        )}
      </Box>
      <Icon name="chevron right" color="mediumGrey" />
    </CursorOpenerContainer>
  );
};

const CursorOpenerContainer = styled(Box)`
  cursor: pointer;
`;
CursorOpenerContainer.defaultProps = {
  p: 3,
  alignItems: "center",
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  display: "flex",
  flexShrink: 0,
  justifyContent: "space-between",
};

export default PostThemeMenuOpener;
