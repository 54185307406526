import LocalStorageKey from "../enums/LocalStorageKey";

export const getJsonItem = <T>(key: LocalStorageKey): T | null | never => {
  const item = localStorage.getItem(key);

  if (typeof item === "string") {
    try {
      return JSON.parse(item);
    } catch (error) {
      throw error as Error;
    }
  }
  return item;
};

export const setJsonItem = <I = any>(key: LocalStorageKey, item: I): void => {
  localStorage.setItem(key, JSON.stringify(item));
};

export const setNumberItem = (key: LocalStorageKey, item: number): void => {
  localStorage.setItem(key, String(item));
};

export const getNumberItem = (key: LocalStorageKey, defaultValue: number): number => {
  const item = localStorage.getItem(key);
  if (!item) {
    return defaultValue;
  }

  return parseFloat(item);
};

export const getBoolItem = (key: LocalStorageKey, defaultValue = false): boolean => {
  const item = localStorage.getItem(key);

  if (!item) {
    return defaultValue;
  }

  return item === "true";
};

export const setStringItem = (key: LocalStorageKey, item: string): void => {
  localStorage.setItem(key, item);
};

export const getStringItem = (key: LocalStorageKey, defaultValue?: string): string | null => {
  const item = localStorage.getItem(key);
  if (!item) {
    return defaultValue || null;
  }
  return String(item);
};
