import React from "react";
import styled from "styled-components";
import dayjs from "../../libs/dayjs";
import { Box, BoxProps, Link, Text } from "../../UI";

export interface CopyrightProps extends BoxProps {}

const Copyright: React.FC<CopyrightProps> = ({ ...boxProps }) => (
  <Box {...boxProps}>
    <CopyrightText>
      &copy; {dayjs().year()} <CopyrightLink to={"/"}>Plugd, Inc.</CopyrightLink> All Rights Reserved
    </CopyrightText>
  </Box>
);

const CopyrightText = styled(Text)`
  letter-spacing: 0px;
`;
CopyrightText.defaultProps = {
  fontSize: 0,
};

const CopyrightLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

export default Copyright;
