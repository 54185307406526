import { applyMiddleware, combineReducers, createStore, Reducer, ReducersMapObject } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { entitiesReducer, EntitiesSelector, errorsReducer, queriesReducer, queryMiddleware } from "redux-query";
import thunk from "redux-thunk";
import axiosNetworkInterface from "./axiosNetworkInterface";
import authTokenMiddleware from "./middleware/authTokenMiddleware";
import queryFailureMiddleware from "./middleware/queryFailureMiddleware";
import appReducer from "./reducers/appReducer";
import asksReducer from "./reducers/asksReducer";
import cometChatReducer from "./reducers/cometChatReducer";
import commentsReducer from "./reducers/commentsReducer";
import nativeAppReducer from "./reducers/nativeAppReducer";
import postsReducer from "./reducers/postsReducer";
import userReducer from "./reducers/userReducer";
import initialEntitiesState from "./reducers/utils/initialEntitiesState";
import queriesSelector from "./selectors/queriesSelector";
import EntitiesState from "./typings/EntitiesState";
import ReducerState from "./typings/Reducer";

export const getEntities: EntitiesSelector<EntitiesState> = (state) => state.entities;

// todo: fix this reducer type
const reducerConfig: ReducersMapObject<ReducerState, any> = {
  app: appReducer,
  asks: asksReducer,
  cometChat: cometChatReducer,
  comments: commentsReducer,
  nativeApp: nativeAppReducer,
  posts: postsReducer,
  user: userReducer,
  // redux-query
  entities: entitiesReducer as unknown as Reducer<EntitiesState, any>,
  queries: queriesReducer,
  errors: errorsReducer,
};

const rootReducer = combineReducers(reducerConfig);

export type RootState = ReturnType<typeof rootReducer>;

const composedEnhancers = composeWithDevTools(
  applyMiddleware(
    thunk,
    authTokenMiddleware,
    queryFailureMiddleware,
    queryMiddleware<EntitiesState>(axiosNetworkInterface, queriesSelector.queries(), getEntities),
  ),
);

const store = createStore(rootReducer, initialEntitiesState, composedEnhancers);

export default store;
