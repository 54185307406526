import { retry } from "ts-retry-promise";
import PlugdAlgolia from "../clients/PlugdAlgolia";
import { captureException } from "../helpers/errorUtil";

const plugdAlgolia = (() => {
  let pa: PlugdAlgolia | undefined;
  return (): Promise<PlugdAlgolia | void> => {
    if (pa) {
      return Promise.resolve(pa);
    }
    return retry(() => import(/* webpackChunkName: "algoliasearch" */ "algoliasearch"), { retries: 5 })
      .then((asLib) => {
        pa = new PlugdAlgolia(asLib);
        return pa;
      })
      .catch((error) => {
        captureException(error);
      });
  };
})();

export default plugdAlgolia;
