import { toUpper } from "lodash-es";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { ProductsAPI } from "../../../typings/API";
import { Box, BoxProps, Text } from "../../../UI";
import SearchItemPlaceholder from "../../SearchMenu/SearchItemPlaceholder";
import ProductFollowListItem from "./ProductFollowListItem";

export interface ProductFollowListProps extends BoxProps {
  loading: boolean;
  products: ProductsAPI.Product[];
  selectedProductIds: Set<number>;
  setSelectedProductIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  title: string;
}

const ProductFollowList: React.FC<ProductFollowListProps> = ({
  loading,
  products,
  selectedProductIds,
  setSelectedProductIds,
  title,
  ...wrapperProps
}) => {
  /**
   * Select all product ids by default
   */
  useEffect(() => {
    if (!loading) {
      return setSelectedProductIds(new Set(products.map((p) => p.id)));
    }
  }, [setSelectedProductIds, loading, products]);

  /** Toggle selection of a single product */
  const toggleSelectedProduct = useCallback(
    (product: ProductsAPI.Product) => setSelectedProductIds(updateSelectedIds(selectedProductIds, product.id)),
    [setSelectedProductIds, selectedProductIds],
  );

  /** Bulk select or deselect products */
  const toggleSelectedProductBulk = useCallback(() => {
    if (selectedProductIds.size < products.length) {
      /** bulk select all if not all products are selected */
      return setSelectedProductIds(new Set(products.map((p) => p.id)));
    }

    /** bulk deselect all products if all products are currently selected */
    return setSelectedProductIds(new Set());
  }, [setSelectedProductIds, selectedProductIds, products]);

  if (loading) {
    return (
      <Box {...wrapperProps}>
        <TitleWrapper px={3} mb={3}>
          <Text fontWeight={5}>{toUpper(title)}</Text>
        </TitleWrapper>
        {Array.from({ length: 3 }).map((_, idx) => (
          <SearchItemPlaceholder key={idx} />
        ))}
      </Box>
    );
  }

  return (
    <Box {...wrapperProps}>
      <TitleWrapper px={3} mb={3}>
        <Text fontWeight={5}>{toUpper(title)}</Text>
        <Text
          color={selectedProductIds.size < products.length ? "black" : "mediumGrey"}
          textDecoration="underline"
          onClick={toggleSelectedProductBulk}
        >
          {selectedProductIds.size < products.length ? "Select all" : "Deselect all"}
        </Text>
      </TitleWrapper>
      <Box>
        {products.map((product) => (
          <ProductFollowListItem
            key={product.id}
            product={product}
            onSelect={toggleSelectedProduct}
            isSelected={selectedProductIds.has(product.id)}
          />
        ))}
      </Box>
    </Box>
  );
};

/** updates the selected product ids */
const updateSelectedIds = (currentIds: Set<number>, productId: number): Set<number> => {
  const updatedIds = new Set(currentIds);
  if (updatedIds.has(productId)) {
    updatedIds.delete(productId);
  } else {
    updatedIds.add(productId);
  }
  return updatedIds;
};

const TitleWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export default ProductFollowList;
