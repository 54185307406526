import produce from "immer";
import { concat, keyBy, without } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { UsersAPI } from "../../typings/API";
import { UserFollowState, UserProfileState } from "../../typings/EntitiesState";

export const followUser = (
  follower_id: string,
  followee_id: string,
): QueryConfig<UserFollowState & Pick<UserProfileState, "userStatisticsById">> => {
  return {
    url: `${config.API_URL}/users/${follower_id}/followers`,
    meta: {
      includeToken: true,
    },
    body: {
      user_id: followee_id,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    optimisticUpdate: {
      followeeIdsByUserId: (prevValue) => {
        const staleFollows = prevValue[follower_id] || [];
        return {
          ...prevValue,
          [follower_id]: concat(staleFollows, followee_id),
        };
      },
      followerIdsByUserId: (prevValue) => {
        const staleFollows = prevValue[followee_id] || [];
        return {
          ...prevValue,
          [followee_id]: concat(staleFollows, follower_id),
        };
      },
      userStatisticsById: (prevValue) => {
        return produce(prevValue, (draft) => {
          if (draft[followee_id]) {
            draft[followee_id].followers++;
          }
          if (draft[follower_id]) {
            draft[follower_id].followees++;
          }
          return draft;
        });
      },
    },
    rollback: {
      followeeIdsByUserId: (initialValue) => initialValue,
      followerIdsByUserId: (initialValue) => initialValue,
      userStatisticsById: (initialValue) => initialValue,
    },
  };
};

/** bulk follow users */
export const followUsers = (
  follower_id: string,
  followee_ids: string[],
): QueryConfig<UserFollowState & Pick<UserProfileState, "userStatisticsById">> => {
  return {
    url: `${config.API_URL}/users/follow`,
    meta: {
      includeToken: true,
    },
    body: {
      ids: followee_ids,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    optimisticUpdate: {
      followeeIdsByUserId: (prevValue) => {
        const staleFollows = prevValue[follower_id] || [];
        return {
          ...prevValue,
          [follower_id]: concat(staleFollows, followee_ids),
        };
      },
      followerIdsByUserId: (prevValue) =>
        produce(prevValue, (draft) => {
          followee_ids.forEach((followeeId) => {
            const oldValues: string[] = draft[followeeId] || [];
            draft[followeeId] = concat(oldValues, follower_id);
          });
        }),
      userStatisticsById: (prevValue) =>
        produce(prevValue, (draft) => {
          followee_ids.forEach((followeeId) => {
            if (draft[followeeId]) {
              draft[followeeId].followers++;
            }
          });
          if (draft[follower_id]) {
            draft[follower_id].followees = draft[follower_id].followees + followee_ids.length;
          }
        }),
    },
  };
};

export const unfollowUser = (
  follower_id: string,
  followee_id: string,
): QueryConfig<UserFollowState & Pick<UserProfileState, "userStatisticsById">> => {
  return {
    url: `${config.API_URL}/users/${follower_id}/followers/${followee_id}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "DELETE",
      headers: STANDARD_HEADERS,
    },
    optimisticUpdate: {
      followeeIdsByUserId: (prevValue) => {
        const staleFollows = prevValue[follower_id] || [];
        return {
          ...prevValue,
          [follower_id]: without(staleFollows, followee_id),
        };
      },
      followerIdsByUserId: (prevValue) => {
        const staleFollows = prevValue[followee_id] || [];
        return {
          ...prevValue,
          [followee_id]: without(staleFollows, follower_id),
        };
      },
      userStatisticsById: (prevValue) => {
        return produce(prevValue, (draft) => {
          if (draft[followee_id]) {
            draft[followee_id].followers--;
          }
          if (draft[follower_id]) {
            draft[follower_id].followees--;
          }
          return draft;
        });
      },
    },
    rollback: {
      followeeIdsByUserId: (initialValue) => initialValue,
      followerIdsByUserId: (initialValue) => initialValue,
      userStatisticsById: (initialValue) => initialValue,
    },
  };
};

export const getFollows = (user_id: string): QueryConfig<UserFollowState> => {
  return {
    url: `${config.API_URL}/users/${user_id}/follows`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (
      response: UsersAPI.GetFollowsResponse,
    ): Pick<UserFollowState, "followeeIdsByUserId" | "followerIdsByUserId"> => {
      return {
        followeeIdsByUserId: {
          [user_id]: response.followees,
        },
        followerIdsByUserId: {
          [user_id]: response.followers,
        },
      };
    },
    update: {
      followeeIdsByUserId: shallowObjectMerge,
      followUserDataByUserId: shallowObjectMerge,
    },
  };
};

export const getFollowerUsers = (user_id: string): QueryConfig<UserFollowState> => {
  return {
    url: `${config.API_URL}/users/${user_id}/followers`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (
      response: UsersAPI.GetFollowersResponse,
    ): Pick<UserFollowState, "followerIdsByUserId" | "followUserDataByUserId"> => {
      return {
        followerIdsByUserId: {
          [user_id]: response.followers.map((u) => u.id),
        },
        followUserDataByUserId: keyBy(response.followers, "id"),
      };
    },
    update: {
      followerIdsByUserId: shallowObjectMerge,
      followUserDataByUserId: shallowObjectMerge,
    },
  };
};

export const getFolloweeUsers = (user_id: string): QueryConfig<UserFollowState> => {
  return {
    url: `${config.API_URL}/users/${user_id}/followees`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (
      response: UsersAPI.GetFolloweesResponse,
    ): Pick<UserFollowState, "followeeIdsByUserId" | "followUserDataByUserId"> => {
      return {
        followeeIdsByUserId: {
          [user_id]: response.followees.map((u) => u.id),
        },
        followUserDataByUserId: keyBy(response.followees, "id"),
      };
    },
    update: {
      followeeIdsByUserId: shallowObjectMerge,
      followUserDataByUserId: shallowObjectMerge,
    },
  };
};
