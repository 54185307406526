import { useMemo } from "react";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { useRequest } from "redux-query-react";
import { getBrandsQuery } from "../queries/api/brandsQuery";
import { RootState } from "../store";
import { BrandsState } from "../typings/EntitiesState";

const useBrands = (): {
  queryState: QueryState;
  brandsById: BrandsState["brandsById"];
} => {
  const [queryState] = useRequest(getBrandsQuery());
  const brandsById = useSelector((state: RootState) => state.entities.brandsById);
  return useMemo(
    () => ({
      queryState,
      brandsById,
    }),
    [queryState, brandsById],
  );
};

export default useBrands;
