import { useMemo } from "react";
import { StripeAPI } from "../typings/API";
import useCurrentUserProfile, { UseCurrentUserProfileResponse } from "./useCurrentUserProfile";

export interface UseSellerResponse extends UseCurrentUserProfileResponse {
  canSell: boolean;
  stripeAccount: StripeAPI.PlugdStripe.Account | null;
}
export type UseSeller = () => UseSellerResponse;

/**
 * Hook to access seller account and utility metadata
 */
const useSeller: UseSeller = () => {
  const { profile: currentUserProfile, refresh, ...queryState } = useCurrentUserProfile();

  const stripeAccount = currentUserProfile?.commerce_account?.stripe_account || null;

  return useMemo(
    () => ({
      ...queryState,
      canSell: Boolean(stripeAccount?.payouts_enabled),
      profile: currentUserProfile,
      refresh,
      stripeAccount,
    }),
    [currentUserProfile, refresh, stripeAccount, queryState],
  );
};

export default useSeller;
