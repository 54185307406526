import { map, pullAt } from "lodash-es";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Updater } from "use-immer";
import useWindowSize from "../../../../hooks/useWindowSize";
import { PostsAPI } from "../../../../typings/API-V2";
import { Box, Image } from "../../../../UI";
import { TOP_NAV_HEIGHT } from "../../../Layout/TopNav";
import DraggableImageTag from "./DraggableImageTag";

export interface ImageTaggerProps {
  initializeTag: React.Dispatch<React.SetStateAction<Pick<PostsAPI.PostImageTagInsert, "x" | "y"> | null>>;
  setTempTags: Updater<(PostsAPI.PostImageTagInsert | PostsAPI.PostImageTag)[] | null>;
  /** the tag image's src url */
  src: string;
  tempTags: (PostsAPI.PostImageTagInsert | PostsAPI.PostImageTag)[] | null;
}

const ImageTagger: React.FC<ImageTaggerProps> = ({ initializeTag, setTempTags, src, tempTags }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const imgWrapperRef = useRef<HTMLDivElement>(null);
  const [imgAr, setImgAr] = useState<number | null>(null);

  const removeTag = useCallback(
    (productId: number) => {
      setTempTags((draft) => {
        if (draft) {
          const tagIndex = draft.findIndex((t) => t.product.id === productId);
          if (tagIndex > -1) {
            pullAt(draft, tagIndex);
          }
        }
      });
    },
    [setTempTags],
  );

  const updateTag = useCallback(
    (productId: number, tagUpdates: Pick<PostsAPI.PostImageTagInsert, "x" | "y">) => {
      setTempTags((draft) => {
        if (draft) {
          const tagIndex = draft.findIndex((t) => t.product.id === productId);
          if (tagIndex > -1) {
            draft[tagIndex] = {
              ...draft[tagIndex],
              ...tagUpdates,
            };
          }
        }
      });
    },
    [setTempTags],
  );

  const onImgLoad = useCallback(() => {
    if (imgRef.current) {
      setImgAr(imgRef.current.height / imgRef.current.width);
    }
  }, [imgRef]);

  const { innerHeight: windowHeight, innerWidth: windowWidth } = useWindowSize();

  const imageDimensions = useMemo<{ height: number; width: number } | undefined>(() => {
    // todo: enforce the top nav height consistency
    const maxImgWidth = Math.min(windowWidth, 614);
    const maxImgHeight = windowHeight - TOP_NAV_HEIGHT - 200;
    const maxAr = maxImgHeight / maxImgWidth;

    let dimensions: { height: number; width: number } | undefined;
    if (imgAr) {
      if (imgAr < maxAr) {
        dimensions = {
          width: maxImgWidth,
          height: imgAr * maxImgWidth,
        };
      } else {
        dimensions = {
          width: maxImgHeight / imgAr,
          height: maxImgHeight,
        };
      }
    }
    return dimensions;
  }, [imgAr, windowWidth, windowHeight]);

  const onImgClick = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      if (imgRef.current) {
        const top = e.clientY - imgRef.current.y;
        const left = e.clientX - imgRef.current.x;
        const y = Math.max(Math.min(top / imgRef.current.height, 1), 0);
        const x = Math.max(Math.min(left / imgRef.current.width, 1), 0);
        initializeTag({ x, y });
      }
    },
    [imgRef, initializeTag],
  );

  const imgWrapperEl = imgWrapperRef.current;

  return (
    <Box display="flex" justifyContent="center" bg="black">
      <Box position="relative" ref={imgWrapperRef} id="post-image-tag-wrapper">
        <Image
          alt={`Image`}
          onClick={onImgClick}
          onLoad={onImgLoad}
          ref={imgRef}
          src={src}
          width="100%"
          {...imageDimensions}
        />
        {imgWrapperEl &&
          imageDimensions &&
          tempTags &&
          map(tempTags, (t) => (
            <DraggableImageTag
              key={t.product.id}
              parentEl={imgWrapperEl}
              tag={t}
              imageDimensions={imageDimensions}
              removeTag={removeTag}
              updateTag={updateTag}
            />
          ))}
      </Box>
    </Box>
  );
};

export default ImageTagger;
