import { findLastIndex } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import theme from "../theme";

const createMediaQuery = (n): string => `(min-width: ${n})`;

const useValueByMedia = <V = any>(values: V[]): V => {
  // reverse to match largest first
  // const queries = ;
  const [mediaQueryLists] = useState<MediaQueryList[]>(
    [`(min-width: 0em)`].concat(theme.breakpoints.map((b) => createMediaQuery(b))).map((q) => window.matchMedia(q)),
  );

  const [vals] = useState<V[]>(values.concat([values[values.length - 1]]));

  // Function that gets value based on matching media query
  const getValue = useCallback((): V => {
    // Get index of first media query that matches
    const index = findLastIndex(mediaQueryLists, (mql) => mql.matches);
    // Return related value or defaultValue if none
    const returnValue = vals[index] || vals[0];

    return returnValue;
  }, [values, mediaQueryLists, vals]);

  // State and setter for matched value
  const [value, setValue] = useState<V>(getValue);

  const mediaQueryListener = useCallback((): void => setValue(getValue), [getValue]);

  useEffect(() => {
    // do not change to "addEventListener" and "removeEventListener". Methods are not supported on Safari
    // https://github.com/microsoft/TypeScript/issues/32210
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
    mediaQueryLists.forEach((mql) => mql.addListener(mediaQueryListener));
    return (): void => mediaQueryLists.forEach((mql) => mql.removeListener(mediaQueryListener));
  }, [mediaQueryLists, mediaQueryListener]);

  return value;
};

export default useValueByMedia;
