import { flatten, keyBy, reduce, sumBy } from "lodash-es";
import BoxCondition from "../enums/BoxCondition";
import PriceType from "../enums/PriceType";
import ShoeCondition from "../enums/ShoeCondition";
import { BidValue, ExchangeRates, LowestAsksByVendorReturn, Price, PriceValue, VendorsAPI } from "../typings/API";
import Vendor from "../typings/Vendor";
import { ShippingCostDetail } from "./shippingUtil";
import { USTaxConfig } from "./taxUtil";
import { FeeCalculator, VendorFees } from "./vendorFees";

export namespace PriceAddOn {
  export type FeeDisplayName = "StockX Processing Fee" | "Tax" | "Shipping" | "Processing Fee";
  export type FeeKey = "processing" | "tax" | "shipping";

  export interface Fee {
    key: FeeKey; // shipping | tax | vat | etc.
    amount: number;
    convertedFrom?: string; // currency code
    currency: string; // currency code
    displayName: FeeDisplayName;
    message?: string;
    isEstimate: boolean;
    subtextMessage?: string; // used for listing subtext
  }

  export interface WithFees extends PriceValue {
    fees: Fee[];
    total: number;
  }

  export interface WithFeesAndConversions extends WithFees {
    // "vendor" doesn't belong in this interface, but updating it will require a lot of code changes
    // and the entire code around vendors needs to be rewritten
    vendorConfig?: VendorsAPI.VendorConfig;
    convertedCurrency: string;
    convertedTotalAmount: number;
    convertedTotalAmountDisplay: string;
  }
}

export interface TotalCosts {
  shipping: ShippingCostDetail | null;
  totalAmount: number;
}

export interface PriceWithTotals extends PriceValue {
  shipping: ShippingCostDetail | null;
  tax: USTaxConfig | null;
  totalAmount: number;
}

export interface PriceWithConvertedTotals extends PriceWithTotals {
  convertedAmount: number;
  convertedAmountDisplay: string;
  convertedTotalAmount: number;
  convertedTotalAmountDisplay: string;
}

export interface PriceRange {
  vendors: Set<Vendor.Key>;
  min: PriceAddOn.WithFeesAndConversions;
  max: PriceAddOn.WithFeesAndConversions;
}

export interface SizeLabel {
  sizeLabel: string;
  shortSizeLabel: string;
}

export const formatRawPrice = (price: Price, type: PriceType): PriceValue => ({
  ...price,
  type: type,
});

export const flattenVendorAsks = (
  asksByVendors?: Partial<LowestAsksByVendorReturn>,
): (PriceValue | null | undefined)[] => {
  if (!asksByVendors) {
    return [];
  }

  return flatten(Object.values(asksByVendors));
};

export const removeInvalid = <T extends PriceValue>(asks: (T | null | undefined)[]): T[] => {
  return asks.filter((ask) => {
    if (ask === null || ask === undefined) {
      return false;
    }
    return isFinite(parseFloat(ask.size));
  }) as T[];
};

export const generateKey = (bidAsk: PriceValue): string =>
  `${bidAsk.shoe_condition}-${bidAsk.box_condition}-${bidAsk.size}-${bidAsk.vendor}-${bidAsk.url}`;

export const mapPrices = <T extends PriceValue>(bidAsk: T[]): { [refKey: string]: T } =>
  keyBy(bidAsk, (ba) => generateKey(ba));

export const filterBySize = <T extends PriceValue>(asks: T[], size: string, sizeRange: number[]): T[] => {
  if (!asks) {
    return [];
  }
  if (size === "all") {
    return asks.filter((ask) => sizeRange.includes(parseFloat(ask.size)));
  }
  return asks.filter((ask): boolean => ask.size === size);
};

export const groupByShoeCondition = (asks: PriceValue[]): { new: PriceValue[]; used: PriceValue[] } => {
  return reduce(
    asks,
    (result, ask) => {
      switch (ask.shoe_condition) {
        case ShoeCondition.New:
          result.new.push(ask);
          break;
        case ShoeCondition.Used:
          result.used.push(ask);
          break;
        default:
          break;
      }
      return result;
    },
    {
      new: [],
      used: [],
    } as { new: PriceValue[]; used: PriceValue[] },
  );
};

export const filterByBoxCondition = <T extends PriceValue>(asks: T[], boxCondition: BoxCondition): PriceValue[] => {
  return asks.filter((ask): boolean => ask.box_condition === boxCondition);
};

export const filterByShoeCondition = <T extends PriceValue>(asks: T[], shoeCondition: ShoeCondition): PriceValue[] => {
  return asks.filter((ask): boolean => ask.shoe_condition === shoeCondition);
};

// todo add tax
export const addTotalCosts = <T extends PriceValue>(
  prices: T[],
  vendorFees: VendorFees,
): Array<PriceAddOn.WithFees> => {
  return prices.map((price: T): PriceAddOn.WithFees => {
    const feeCalculators = vendorFees[price.vendor];
    const fees = feeCalculators.reduce<Array<ReturnType<FeeCalculator>>>((result, calculator) => {
      result.push(calculator(price));
      return result;
    }, []);

    const total = sumBy(fees, (f) => (f.amount ? f.amount : 0)) + price.amount;

    return {
      ...price,
      fees,
      total,
    };

    // const shippingDetail = shippingConfig ? shippingConfig[price.vendor] : null;
    // const taxDetail = taxConfigLookup ? taxConfigLookup(price.vendor) : null;
    // const total = shippingDetail ? price.amount + shippingDetail.amount : price.amount;
    // return {
    //   ...price,
    //   shipping: shippingDetail,
    //   tax: taxDetail,
    //   totalAmount: total,
    // };
  });
};

export const convertCurrency = <T extends PriceAddOn.WithFees>(
  asks: T[],
  toCurrency: string,
  exchangeRates: ExchangeRates | null,
): Array<PriceAddOn.WithFeesAndConversions> => {
  const conversionRate = (exchangeRates && exchangeRates.rates[toCurrency]) || 1;
  return asks.map((ask: T) => {
    // const convertedAmount = conversionRate * (ask.amount / 100);
    const convertedTotalAmount = conversionRate * (ask.total / 100);
    return {
      ...ask,
      convertedCurrency: toCurrency,
      // convertedAmount,
      // convertedAmountDisplay: new Intl.NumberFormat("en-US", { style: "currency", currency: toCurrency }).format(
      //   convertedAmount,
      // ),
      convertedTotalAmount,
      convertedTotalAmountDisplay: new Intl.NumberFormat("en-US", { style: "currency", currency: toCurrency }).format(
        convertedTotalAmount,
      ),
    };
  });
};

export const sortByPrice = <T extends PriceValue>(asks: T[]): T[] => {
  return asks.sort((a, b): number => a.amount - b.amount);
};

export const sortByTotal = <T extends PriceAddOn.WithFeesAndConversions>(asks: T[]): Array<T> => {
  return asks.sort((a, b): number => a.total - b.total);
};

export const getpriceRange = (prices: PriceAddOn.WithFeesAndConversions[]): { [key: string]: PriceRange } => {
  const priceRangeMap: { [key: string]: PriceRange } = {};
  prices.forEach((price) => {
    if (priceRangeMap[price.size]) {
      const existingRange = priceRangeMap[price.size];
      existingRange.vendors.add(price.vendor);
      if (price.convertedTotalAmount > existingRange.max.convertedTotalAmount) {
        existingRange.max = price;
      }
      if (price.convertedTotalAmount < existingRange.min.convertedTotalAmount) {
        existingRange.min = price;
      }
    } else {
      priceRangeMap[price.size] = {
        vendors: new Set([price.vendor]),
        min: price,
        max: price,
      };
    }
  });
  return priceRangeMap;
};

export const emptyBid = (bid: PriceAddOn.WithFeesAndConversions): BidValue => {
  return {
    ...bid,
    image_urls: [],
    type: PriceType.Bid,
    amount: 0,
  };
};
