import React, { useEffect } from "react";
import { MAX_MAIN_WIDTH } from "../../components/Layout/layoutConstants";
import Page from "../../components/Layout/Page";
import FeedTabSecondaryTopNav from "../../components/TopNav/FeedTabSecondaryTopNav";
import { pages, trackPage } from "../../helpers/analyticsUtil";
import { Icon } from "../../libs/semantic-ui";
import { Box, Spacer, Text } from "../../UI";
import useScreenSize from "../../UI/utils/useScreenSize";

export interface CheckoutCancelProps {}

const CheckoutCancel: React.FC<CheckoutCancelProps> = () => {
  /** track page visits */
  useEffect(() => trackPage(pages.CheckoutCancel), []);
  const screenSize = useScreenSize();

  return (
    <>
      <FeedTabSecondaryTopNav title={"Checkout"} />
      <Page>
        <Box
          width="100%"
          maxWidth={MAX_MAIN_WIDTH}
          bg="white"
          mt={3}
          p={4}
          borderRadius={screenSize === "large" ? 3 : undefined}
        >
          <Box display="flex" alignItems="center" flexDirection="column">
            <Icon.Group size="big">
              <Icon name="cart" />
              <Icon corner name="close" />
            </Icon.Group>
            <Spacer mt={3} />
            <Text fontWeight={5} fontSize={3}>
              Checkout Canceled
            </Text>
            <Spacer mt={3} />
            <Text color="darkGrey">You may close this window</Text>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default CheckoutCancel;
