import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useSigninCheck } from "reactfire";
import styled from "styled-components";
import useCommunities from "../../../hooks/useCommunities";
import useCurrentUserProfile from "../../../hooks/useCurrentUserProfile";
import communitySelector from "../../../selectors/communitySelector";
import userProfileSelector from "../../../selectors/userProfileSelector";
import { PostsAPI } from "../../../typings/API-V2";
import { Box, Text } from "../../../UI";
import PartialMenu from "../../Layout/PartialMenu";
import { Authenticated } from "../../Permission";
import PostMenuContext from "../../PostMenu/PostMenuContext";
import ActionsMenuItem from "../ActionsMenuItem";
import PostActionsBlockUserMenuItem from "../User/BlockUserActionsMenuItem";
import PostActionsArchiveMenuItem from "./ArchivePostActionsMenuItem";
import PostActionsDeleteMenuItem from "./DeletePostActionsMenuItem";
import PostActionsFlagMenuItem from "./FlagPostActionsMenuItem";
import PostActionsHideMenuItem from "./PostActionsHideMenuItem";
import PostActionsPinMenuItem from "./PostActionsPinMenuItem";

export interface PostActionsMenuProps {
  post: PostsAPI.Post;
  isOpen: boolean;
  close: () => void;
}

const PostActionsMenu: React.FC<PostActionsMenuProps> = ({ isOpen, close, post }) => {
  const { data } = useSigninCheck({
    requiredClaims: {
      role: "admin",
    },
  });
  useCommunities();
  const isAdmin = data?.hasRequiredClaims;
  const { setEditPost } = useContext(PostMenuContext);
  const { profile } = useCurrentUserProfile();
  const isAuthor = post.author.username === profile?.username;
  const isBlocked = useSelector(userProfileSelector.isBlocked(post.author.id || undefined));
  const isModerator = useSelector(communitySelector.userIsModerator(profile?.id, post.community.id));
  const isAuthorized = isAuthor || isAdmin;
  const canHide = isAdmin || isModerator; // user can only archive post if they are admin
  const canPin = isAdmin || isModerator;

  return (
    <>
      <PartialMenu isOpen={isOpen} close={close} overflowY="initial">
        {isAuthorized && (
          <ActionsMenuItem
            label="Edit"
            iconName="edit"
            onClick={() => {
              setEditPost(post);
              close();
            }}
          />
        )}
        {!isAuthor && <PostActionsFlagMenuItem label="Flag Post" iconName="flag" post={post} close={close} />}
        {isAuthorized && (
          <PostActionsArchiveMenuItem
            label={post.is_archived ? "Unarchive" : "Archive"}
            iconName="archive"
            post={post}
          />
        )}
        {isAuthorized && <PostActionsDeleteMenuItem label="Delete" iconName="trash" post={post} />}
        {canHide && (
          <PostActionsHideMenuItem
            label={post.is_hidden ? "Unhide" : "Hide"}
            iconName={post.is_hidden ? "unhide" : "hide"}
            post={post}
          />
        )}
        {canPin && <PostActionsPinMenuItem label={post.is_pinned ? "Unpin" : "Pin"} iconName={"pin"} post={post} />}
        {!isAuthor && post.author.id && (
          <Authenticated>
            <PostActionsBlockUserMenuItem
              label={`Block ${post.author.username ? `@${post.author.username}` : "User"}`}
              iconName="ban"
              isBlocked={isBlocked}
              blockeeId={post.author.id}
              blockeeUsername={post.author.username}
              close={close}
            />
          </Authenticated>
        )}
        <ActionsMenuItem label="Cancel" iconName="cancel" onClick={close} />
      </PartialMenu>
    </>
  );
};

const MenuItem = styled(Box)``;
MenuItem.defaultProps = {
  alignItems: "center",
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  display: "flex",
  flexShrink: 0,
  justifyContent: "center",
  p: 3,
  role: "button",
};

const MenuItemText = styled(Text)``;
MenuItemText.defaultProps = {
  fontSize: 2,
};

export default PostActionsMenu;
