import { Action as ReduxAction, Dispatch } from "redux";
import ActionStatus from "../enums/ActionStatus";
import ActionType from "../enums/ActionType";

interface ThunkUtil {
  success: <P>(payload?: P) => void;
  fetch: <P>(payload?: P) => void;
  fail: <P>(payload?: P) => void;
}

export interface ThunkUtilOptions<M = any> {
  requestId?: string | number;
  meta?: M;
}

export interface ThunkUtilAction<M = Record<string, unknown>> {
  meta: M;
  requestId: string | number;
}

export interface ActionValueCommon extends ReduxAction<ActionType> {
  meta: Record<string, unknown>;
  requestId?: string | number;
}

export interface ActionValue<P = any> extends ActionValueCommon {
  status: ActionStatus;
  payload: P;
}

export default <M>(dispatch: Dispatch, type: ActionType, options: ThunkUtilOptions<M> = {}): ThunkUtil => {
  const common: ActionValueCommon = {
    meta: options.meta || {},
    requestId: options.requestId ? options.requestId : new Date().valueOf(),
    type,
  };
  return {
    fetch: <P = any>(payload?: P): void => {
      const value: ActionValue = {
        ...common,
        status: ActionStatus.FETCH,
        payload,
      };
      dispatch(value);
    },
    success: <P = any>(payload?: P): void => {
      const value: ActionValue = {
        ...common,
        status: ActionStatus.SUCCESS,
        payload,
      };
      dispatch(value);
    },
    fail: <P = any>(payload?: P): void => {
      const value = {
        ...common,
        status: ActionStatus.FAIL,
        payload,
      };
      dispatch(value);
    },
  };
};
