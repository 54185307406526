import { isEmpty, pullAt } from "lodash-es";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import { Updater, useImmer } from "use-immer";
import { captureException } from "../../../helpers/errorUtil";
import { isMutableRefObject } from "../../../helpers/refUtil";
import { PostsAPI } from "../../../typings/API-V2";
import { Box, Text } from "../../../UI";
import useImageUploader from "../CreateImagePostMenu/hooks/useImageUploader";
import ImagesPreview, { ImagesPreviewProps } from "../CreateImagePostMenu/ImagesPreview";
import TagImageMenu, { TagImageMenuProps } from "../CreateImagePostMenu/TagImageMenu";
import { UseInputHook } from "../types/inputHooks";

export interface ImageMeta {
  file: File;
  localUrl: string;
  tags: PostsAPI.PostImageTagInsert[] | null;
  upload: {
    error?: string;
    isPending: boolean;
    isFinished: boolean;
  };
  /** the post image insert object, sans tags */
  insert: Omit<PostsAPI.PostImageInsert, "tags"> | null;
}

export interface InsertableImageMeta extends ImageMeta {
  insert: Omit<PostsAPI.PostImageInsert, "tags">;
  upload: {
    isPending: false;
    isFinished: true;
  };
}

export interface UseImageInputOptions {
  canTag: boolean;
}

export type UseImageInput = (options: UseImageInputOptions) => {
  input: JSX.Element;
  menus: JSX.Element | null;
  value: ImageMeta[];
  setValue: Updater<ImageMeta[]>;
};

/**
 * TODO: Rename this
 *
 * @returns
 */
const useImageInput: UseInputHook<ImageMeta[], UseImageInputOptions> = ({ canTag = false }) => {
  const { addToast } = useToasts();
  const imageInputRef = useRef<HTMLInputElement>(null);

  const [imagesMeta, setImagesMeta] = useImmer<ImageMeta[]>([]);

  /**
   * IMAGE UPLOADING
   * ===============
   */

  const { uploadImage } = useImageUploader();

  const removeImage = useCallback(
    (imageIndex: number) => {
      setImagesMeta((draft) => {
        pullAt(draft, imageIndex);
      });
    },
    [setImagesMeta],
  );

  /**
   * Method to handle the image files selected by the user.
   * 1. Covert image files into ImageMeta interface
   * 2. Upload images and track upload status
   */
  const onImageFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(e.target.files || []);

      if (!isEmpty(files)) {
        if (imagesMeta.length + files.length > 10) {
          /** Do nothing and warn user if they try to upload more than 10 photos */
          return addToast(`Posts may include 10 photos`, {
            appearance: "error",
            autoDismiss: true,
          });
        }

        const initialImageMetas = files.map((f) => ({
          file: f,
          insert: null,
          localUrl: URL.createObjectURL(f),
          tags: null,
          upload: {
            isPending: true,
            isFinished: false,
          },
        }));

        setImagesMeta((draft) => {
          initialImageMetas.forEach((o) => {
            draft.push(o);
          });
        });

        /**
         * upload the images files
         */
        initialImageMetas.forEach((imageMeta) => {
          uploadImage(imageMeta)
            .then((result) => {
              setImagesMeta((draft) => {
                const updateImage = draft.find((i) => i.localUrl === imageMeta.localUrl);
                if (updateImage) {
                  updateImage.upload.isFinished = true;
                  updateImage.upload.isPending = false;
                  updateImage.insert = result.insert;
                }
              });
            })
            .catch((error) => {
              setImagesMeta((draft) => {
                const updateImage = draft.find((i) => i.localUrl === imageMeta.localUrl);
                if (updateImage) {
                  updateImage.upload.isFinished = false;
                  updateImage.upload.isPending = false;
                  updateImage.upload.error = "An error occurred";
                }
              });
              captureException(error);
            });
        });
      }

      /** reset the input element's value */
      e.target.value = "";
    },
    [addToast, setImagesMeta, imagesMeta.length, uploadImage],
  );

  const addImages = useCallback(() => {
    if (isMutableRefObject(imageInputRef) && imageInputRef.current) {
      imageInputRef.current.click();
    }
  }, [imageInputRef]);

  /**
   * IMAGE TAGGING
   * =============
   */

  const [tagImageIndex, setTagImageIndex] = useState<number | null>(null);
  const closeTagMenu = useCallback(() => setTagImageIndex(null), []);
  const setTags = useCallback<TagImageMenuProps["setTags"]>(
    (tags) => {
      if (tagImageIndex !== null) {
        setImagesMeta((draft) => {
          draft[tagImageIndex].tags = tags;
        });
      }
    },
    [setImagesMeta, tagImageIndex],
  );

  const menus = useMemo(
    () =>
      tagImageIndex !== null ? (
        <TagImageMenu
          close={closeTagMenu}
          setTags={setTags}
          src={imagesMeta[tagImageIndex].localUrl}
          tags={imagesMeta[tagImageIndex].tags}
        />
      ) : null,
    [closeTagMenu, imagesMeta, setTags, tagImageIndex],
  );

  const onImagePreviewClick = useCallback((imageIndex: number) => setTagImageIndex(imageIndex), []);
  const imagePreviewImages = useMemo<ImagesPreviewProps["images"]>(
    () =>
      imagesMeta.map((m) => ({
        upload: m.upload,
        url: m.localUrl,
        tags: m.tags,
      })),
    [imagesMeta],
  );

  const input = useMemo(
    () => (
      <>
        <input
          accept="image/*"
          multiple={true}
          onChange={onImageFileChange}
          id="image-files-input"
          name="image-input"
          ref={imageInputRef}
          hidden={true}
          type="file"
        />
        <ImagesPreview
          add={addImages}
          images={imagePreviewImages}
          onImageClick={canTag ? onImagePreviewClick : undefined}
          remove={removeImage}
        />
        {canTag && imagePreviewImages.length > 0 && (
          <TaggingInstructionsWrapper py={3}>
            <Text fontSize={1} color="darkGrey">
              {`Tap photos to tag products`}
            </Text>
          </TaggingInstructionsWrapper>
        )}
      </>
    ),
    [addImages, canTag, imagePreviewImages, onImageFileChange, onImagePreviewClick, removeImage],
  );

  const clearImagesMeta = useCallback(() => setImagesMeta([]), []);

  return useMemo(
    () => ({
      input,
      menus,
      value: imagesMeta,
      clearValue: clearImagesMeta,
    }),
    [input, menus, imagesMeta, clearImagesMeta],
  );
};

const TaggingInstructionsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default useImageInput;
