import React, { ReactNode } from "react";
import styled from "styled-components";
import { BoxProps } from "../Box";
import Link, { LinkProps } from "../Link";
import ListSelectItem, { ListSelectItemProps } from "../List/ListSelectItem";
import UserMeta, { UserMetaProps } from "./UserMeta";

export interface UserListSelectItemProps extends BoxProps {
  action?: ReactNode;
  metaProps?: Partial<UserMetaProps>;
  profile: UserMetaProps["profile"];
  to?: LinkProps["to"];
}

const UserListSelectItem: React.FC<UserListSelectItemProps> = ({ profile, action, to, metaProps, ...wrapperProps }) => {
  return (
    <UserListSelectItemWrapper as={to ? Link : undefined} {...wrapperProps}>
      <UserMeta profile={profile} {...metaProps} />
      {action}
    </UserListSelectItemWrapper>
  );
};

export interface UserListSelectItemWrapperProps extends ListSelectItemProps {}
export const UserListSelectItemWrapper = styled(ListSelectItem)<UserListSelectItemWrapperProps>`
  justify-content: space-between;
`;

export default UserListSelectItem;
