import { filter, orderBy } from "lodash-es";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { CommunitiesAPI } from "../../typings/API";
import { PostsAPI, PostTypeKey } from "../../typings/API-V2";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
  FullscreenMenuProps,
} from "../Layout/FullscreenMenu";
import FullscreenMenuHeaderButton from "../Layout/FullscreenMenu/FullscreenMenuHeaderButton";
import RadioMenuItem from "../Layout/MenuItem/RadioMenuItem";

export interface PostThemeSelectorMenuProps extends Omit<FullscreenMenuProps, "children"> {
  communityId: number;
  postTypeKey: PostTypeKey;
  isSubmitting?: boolean;
  onSelectTheme: (theme: CommunitiesAPI.CommunityTheme | null) => void;
  onSubmit?: () => void;
  postContentType?: PostsAPI.PostThemeContentType;
  value: CommunitiesAPI.CommunityTheme | null;
}

const PostThemeSelectorMenu: React.FC<PostThemeSelectorMenuProps> = ({
  communityId,
  close,
  postTypeKey,
  isOpen,
  isSubmitting,
  onSelectTheme,
  onSubmit,
  value,
}) => {
  const communityThemes = useSelector((state: RootState) => {
    const themes = state.entities.communityThemesByCommunityId[communityId] || [];
    const filteredThemes = filter(themes, (t) => t.content_type === "common" || t.content_type === postTypeKey);
    return orderBy(filteredThemes, (t) => t.name, "asc");
  });

  const handleSelect = useCallback(
    (theme: CommunitiesAPI.CommunityTheme | null) => onSelectTheme(theme),
    [onSelectTheme],
  );

  return (
    <FullscreenMenu
      isOpen={isOpen}
      close={close}
      header={
        <FullscreenMenuHeader>
          <FullscreenMenuHeaderIcon />
          <FullscreenMenuHeaderTitle>Select Theme</FullscreenMenuHeaderTitle>
          <FullscreenMenuHeaderButton onClick={onSubmit || close} variant="primary" loading={isSubmitting}>
            DONE
          </FullscreenMenuHeaderButton>
        </FullscreenMenuHeader>
      }
    >
      <>
        <RadioMenuItem
          key="none"
          name="None"
          description="No theme"
          onClick={() => handleSelect(null)}
          isChecked={value === null}
        />
        {communityThemes.map((t) => (
          <RadioMenuItem
            key={t.key}
            name={t.name}
            description={t.description}
            onClick={() => handleSelect(t)}
            isChecked={Boolean(value && value.id === t.id)}
          />
        ))}
      </>
    </FullscreenMenu>
  );
};

export default PostThemeSelectorMenu;
