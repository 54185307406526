import React, { PropsWithChildren, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "reactfire";
import { useMutation } from "redux-query-react";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import useAuthenticatedCallback from "../../hooks/useAuthenticatedCallback";
import useConfirmationCallback from "../../hooks/useConfirmationCallback";
import { followUser, unfollowUser } from "../../queries/api/userFollowQuery";
import { RootState } from "../../store";
import { TextButton, TextButtonProps } from "../../UI";

export interface FollowButtonProps extends TextButtonProps {
  targetUserId: string;
  isFollowee: boolean;
  isFollower: boolean;
}

const FeedFollowButton: React.FC<FollowButtonProps> = ({
  targetUserId,
  isFollower,
  isFollowee,
  ...textButtonProps
}) => {
  const [, follow] = useMutation(followUser);
  const [, unfollow] = useMutation(unfollowUser);
  const auth = useAuth();
  const targetUser = useSelector((state: RootState) => state.entities.userProfilesById[targetUserId]);

  const unfollowWithConfirmation = useConfirmationCallback(
    `Unfollow${targetUser ? ` @${targetUser.username}?` : `?`}`,
    unfollow,
    [],
  );

  const handleFollowButtonClick = useAuthenticatedCallback<
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  >(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (auth && targetUserId) {
        if (isFollowee) {
          if (auth.currentUser?.uid) {
            unfollowWithConfirmation(auth.currentUser?.uid, targetUserId);
            trackEvent(events.User.Unfollowed, {
              targetUserId,
            });
          }
        } else {
          if (auth.currentUser?.uid) {
            follow(auth.currentUser?.uid, targetUserId);
            trackEvent(events.User.Followed, {
              targetUserId,
            });
          }
        }
      }
    },
    [targetUserId, follow, auth.currentUser?.uid, unfollow],
  );

  const generatedButtonProps = useMemo<PropsWithChildren<TextButtonProps>>(() => {
    if (isFollower && isFollowee) {
      return {
        variant: "tertiary",
        children: "Friends",
      };
    }
    if (isFollower && !isFollowee) {
      return {
        variant: "primary",
        children: "Follow Back",
      };
    }
    if (!isFollower && isFollowee) {
      return {
        variant: "tertiary",
        children: "Following",
      };
    }
    return {
      variant: "primary",
      children: "Follow",
    };
  }, [isFollower, isFollowee]);

  return <TextButton onClick={handleFollowButtonClick} {...generatedButtonProps} {...textButtonProps} />;
};

export default FeedFollowButton;
