import { useCallback, useMemo } from "react";
import { detectedMentionIntent } from "../../../helpers/postUtil";
import useMenuState from "../../../hooks/useMenuState";
import { PostMentionMenuProps } from "../PostMentionMenu";

type UseMentionMenuOptions = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  inputRefNode?: HTMLTextAreaElement | null;
};

const useMentionMenu = (options: UseMentionMenuOptions) => {
  const { open, menuProps } = useMenuState();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (detectedMentionIntent(options.value, e.currentTarget.value)) {
        // set open menu
        open();
      }
      options.setValue(e.currentTarget.value);
    },
    [options, open],
  );

  const closeMentionMenu = useCallback(() => {
    menuProps.close();
    if (options.inputRefNode) {
      options.inputRefNode.focus();
    }
  }, [menuProps, options]);

  const selectMentionUser = useCallback<PostMentionMenuProps["onSelect"]>(
    (u) => {
      options.setValue((oldTitle) => `${oldTitle}${u} `);
      closeMentionMenu();
    },
    [options, closeMentionMenu],
  );

  return useMemo(
    () => ({
      close: closeMentionMenu,
      isOpen: menuProps.isOpen,
      onChange,
      open,
      selectMentionUser,
    }),
    [closeMentionMenu, menuProps.isOpen, onChange, open, selectMentionUser],
  );
};

export default useMentionMenu;
