// TODO: move this to generic component with proper generic properties
import styled from "styled-components";
import { Input } from "../../UI";

const INPUT_HEIGHT = 48;
export const InputWrapper = styled.form`
  flex-basis: auto;
  position: relative;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: ${INPUT_HEIGHT}px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-color: ${({ theme }) => theme.colors.mediumGrey};
  border-radius: ${INPUT_HEIGHT / 2}px;
  padding: 1em;
  margin-bottom: 1em;
`;

export const AuthInput = styled(Input)`
  background-color: transparent;
`;
