import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { toggleSearch } from "../../actions/createActions";
import AuthScreensContext from "../../components/AuthScreenModal/AuthScreensContext";
import PanelPage from "../../components/Layout/PanelPage";
import Meta from "../../components/Meta";
import ProductsTabSecondaryTopNav from "../../components/TopNav/ProductsTabSecondaryTopNav";
import { pages, trackPage } from "../../helpers/analyticsUtil";
import { Message } from "../../libs/semantic-ui";
import { Link, Text } from "../../UI";

export interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { setAuthScreen } = useContext(AuthScreensContext);
  const isSearchPage = location.pathname.includes("/search");

  useEffect(() => {
    trackPage(pages.NotFound);
  }, []);

  const openSearch = useCallback(() => {
    dispatch(toggleSearch());
  }, [dispatch]);

  if (isSearchPage) {
    return null;
  }

  return (
    <>
      <Meta>
        <meta name="prerender-status-code" content="404" />
      </Meta>
      <ProductsTabSecondaryTopNav title={"Not Found"} />
      <PanelPage>
        <Wrapper>
          <Message>
            <Message.Header>Page not found</Message.Header>
            <Message.Content>
              <div>{`Find the shoes you're looking for with one of the following options.`}</div>
            </Message.Content>
            <CustomList>
              <MessageItem>
                <Link to="/">Browse the Community</Link>
              </MessageItem>
              <MessageItem>
                <Link to="/release-calendar">Release Calendar</Link>
              </MessageItem>
              <MessageItem>
                <Link to="/raffles">Product Raffles</Link>
              </MessageItem>
              <MessageItem>
                <Link to="/discover">Discover Products</Link>
              </MessageItem>
              <MessageItem>
                <AnchorDiv onClick={openSearch}>Search Plugd</AnchorDiv>
              </MessageItem>
              <MessageItem onClick={() => setAuthScreen("login")}>
                <Text>Sign in or Register</Text>
              </MessageItem>
            </CustomList>
          </Message>
        </Wrapper>
      </PanelPage>
    </>
  );
};

const CustomList = styled(Message.List)`
  &.list > li.content.content {
    margin-top: 1em;
  }
`;

const MessageItem = styled(Message.Item)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const AnchorDiv = styled.a`
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`;

export default NotFoundPage;
