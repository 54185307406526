import { intersection, map } from "lodash-es";
import { Brand, GoatProduct, ProductsAPI } from "../typings/API";
import { PlugdAlgoliaProduct } from "../typings/PlugdProduct";

const brandFromBrandNames = (brandNames: string[], brands: Brand[]): Brand | undefined => {
  const availableBrandNames = map(brands, (b) => b.brand_name);
  const matchedBrandNames = intersection(availableBrandNames, brandNames);
  const brand = brands.find((b) => b.brand_name === matchedBrandNames[0]); // if multiple matches, just use the first brand object
  return brand;
};

export const goatProductToProduct = (gp: GoatProduct, brands: Brand[]): Omit<ProductsAPI.Product, "id"> => {
  const brand = brandFromBrandNames(gp.data.brand_name ? [gp.data.brand_name] : [], brands);
  return {
    style_id: gp.data.sku,
    gender: gp.data.gender[0],
    slug: gp.slug,
    name: gp.data.name,
    brand: brand?.id,
    size_range: gp.data.size_range,
    image: gp.data.grid_picture_url,
    release_date: gp.data.release_date,
  };
};

export const algoliaProductToProduct = (ap: PlugdAlgoliaProduct, brands: Brand[]): ProductsAPI.Product => {
  const brand = brandFromBrandNames(ap.brand, brands);
  return {
    // verified: ap.data.
    style_id: ap.sku,
    slug: ap.data.slug,
    name: ap.data.name,
    gender: ap.gender,
    size_range: ap.data.size_range,
    image: ap.data.grid_picture_url,
    release_date: ap.data.release_date,
    id: ap.product_id,
    brand: brand?.id,
    // need to add this to back end
    retail_price_cents: ap.data.retail_price_cents,
  };
};

export const missingImageSrc = "https://image.goat.com/crop/375/placeholders/product_templates/grid/missing.png";
