import React, { Suspense } from "react";
import styled, { css } from "styled-components";
import { isMobileFrame } from "../../config";
import { Box, Grid, Spacer } from "../../UI";
import { TOP_NAV_HEIGHT } from "../Layout/TopNav";
import Copyright from "./Copyright";
import FooterContent from "./FooterContent";

export interface AsideFooterProps {}

const AsideFooter: React.FC<AsideFooterProps> = () => {
  return (
    <Suspense fallback={<div></div>}>
      <StickyWrapper>
        <FooterItemGrid>
          <FooterContent iconColor="black" />
        </FooterItemGrid>
        <Spacer mt={2} />
        <Copyright />
      </StickyWrapper>
    </Suspense>
  );
};

const StickyWrapper = styled(Box)`
  position: sticky;
  top: ${({ theme }) => `${TOP_NAV_HEIGHT + theme.space[4]}px;`};
`;
StickyWrapper.defaultProps = {
  bg: "white",
  color: "black",
  borderRadius: 3,
  p: 4,
};

const FooterItemGrid = styled(Grid)`
  ${() =>
    !isMobileFrame &&
    css`
      ${({ theme }): string => theme.mediaQueries.medium} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${({ theme }) => theme.space[3]}px;
      }
    `}
`;

export default AsideFooter;
