import React from "react";
import styled from "styled-components";
import { Loader } from "../../libs/semantic-ui";
import { Button, ButtonProps } from "../../UI";

export interface StickyFollowButtonProps extends ButtonProps {
  isFinished: boolean;
}

const StickyFollowButton: React.FC<React.PropsWithChildren<StickyFollowButtonProps>> = ({
  isFinished,
  children,
  ...buttonProps
}) => {
  return <StickyButton {...buttonProps}>{!isFinished ? <Loader active inline /> : children}</StickyButton>;
};

const StickyButton = styled(Button)`
  align-self: center;
  position: sticky;
  width: 80%;
`;
StickyButton.defaultProps = {
  bottom: 4,
  size: "medium",
  variant: "primary",
};

export default StickyFollowButton;
