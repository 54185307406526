import { AuthError } from "@firebase/auth-types";
import { createUserWithEmailAndPassword, getAdditionalUserInfo } from "firebase/auth";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "reactfire";
import { useMutation } from "redux-query-react";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import FirebaseErrors from "../../../enums/FirebaseErrors";
import LocalStorageKey from "../../../enums/LocalStorageKey";
import * as localStorageUtil from "../../../helpers/localStorageUtil";
import { ButtonContent, Loader } from "../../../libs/semantic-ui";
import { updateUserPreferences } from "../../../queries/api/userPreferencesQuery";
import { createCurrentUserProfile } from "../../../queries/api/userProfileQuery";
import userSelector from "../../../selectors/userSelector";
import { UsersAPI } from "../../../typings/API";
import { Button, Text } from "../../../UI";
import EmailForm from "../../Auth/EmailSignupForm/EmailForm";
import PasswordForm from "../../Auth/EmailSignupForm/PasswordForm";
import usePostAuthentication from "../../Auth/hooks/usePostAuthentication";
import AuthScreensContext from "../AuthScreensContext";

export interface EmailSignupScreenProps {}

const EmailSignupScreen: React.FC<EmailSignupScreenProps> = () => {
  const auth = useAuth();
  const refUserId = localStorageUtil.getStringItem(LocalStorageKey.REFERRAL_USER_ID);
  const { setAuthScreen, screen } = useContext(AuthScreensContext);
  const [email, setEmail] = useState<string>("");
  const preferences = useSelector(userSelector.preferences());
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPass, setConfirmPass] = useState<string>("");
  const [err, setErr] = useState<string>("");
  const postAuthentication = usePostAuthentication({ skipToast: true });
  const [, updatePreferences] = useMutation((uid: string, preferences: Partial<UsersAPI.UserPreferences>) =>
    updateUserPreferences(uid, preferences),
  );
  const [, initProfile] = useMutation((userFields: UsersAPI.UserProfileInsert) =>
    createCurrentUserProfile(userFields, refUserId),
  );

  const handleCreateAccount = useCallback(async (): Promise<void> => {
    if (!email.length || !password.length) {
      setErr("Please fill in the required fields");
      return;
    } else if (password !== confirmPass) {
      setErr("The passwords you entered don't match");
      return;
    }
    try {
      setLoading(true);
      const authResults = await createUserWithEmailAndPassword(auth, email, password);
      const userInfo = getAdditionalUserInfo(authResults);
      if (userInfo && userInfo.isNewUser) {
        await initProfile({
          id: authResults.user.uid,
          email: email,
          username: authResults.user.uid,
          profile_image: authResults.user.photoURL || undefined,
        });

        await updatePreferences(authResults.user.uid, preferences);
      }
      postAuthentication(userInfo); // handle tracking
      setAuthScreen("createUsername");
    } catch (error) {
      const { code, message } = error as AuthError;
      setErr(FirebaseErrors[code] || message);
    } finally {
      setLoading(false);
    }
  }, [
    auth,
    confirmPass,
    email,
    initProfile,
    password,
    postAuthentication,
    preferences,
    setAuthScreen,
    updatePreferences,
  ]);

  const handleNext = useCallback(async () => {
    if (screen === "emailSignupEmail") {
      setAuthScreen("emailSignupPassword");
    } else if (screen === "emailSignupPassword") {
      await handleCreateAccount();
    } else {
      setAuthScreen("emailSignupEmail");
    }
  }, [screen, setAuthScreen, handleCreateAccount]);

  const formContent =
    screen === "emailSignupPassword" ? (
      <PasswordForm
        password={password}
        handlePasswordChange={setPassword}
        passwordConfirm={confirmPass}
        handlePasswordConfirmChange={setConfirmPass}
      />
    ) : (
      <EmailForm email={email} handleEmailChange={setEmail} />
    );

  const validPasswordLength = password.length >= 8;

  const passwordsEqual = useMemo(() => {
    return validPasswordLength && password === confirmPass;
  }, [password, validPasswordLength, confirmPass]);

  const canSubmit = useMemo(() => {
    if (screen === "emailSignupEmail") {
      return isEmail(email);
    } else if (screen === "emailSignupPassword") {
      return passwordsEqual && validPasswordLength;
    }
  }, [screen, email, passwordsEqual, validPasswordLength]);

  return (
    <>
      {formContent}
      <NextButton
        mb={2}
        variant={canSubmit ? "primary" : "disabled"}
        type="submit"
        size="big"
        disabled={!canSubmit}
        onClick={handleNext}
      >
        <ButtonContent>{loading ? <Loader inverted active inline="centered" size="tiny" /> : "Next"}</ButtonContent>
      </NextButton>
      {err && <Text color="red">{err}</Text>}
    </>
  );
};

const NextButton = styled(Button)`
  width: 100%;
`;

export default EmailSignupScreen;
