import styled, { css } from "styled-components";
import { Box, BoxProps } from "../../UI";
import { TOP_NAV_HEIGHT } from "../Layout/TopNav";

export interface NavTabsProps extends BoxProps {
  sticky?:
    | true
    | {
        top: number;
      };
  fixed?:
    | true
    | {
        top: number;
      };
}

export const DEFAULT_NAV_TABS_HEIGHT = 36;

const NavTabs = styled(Box)<NavTabsProps>`
  ${({ fixed }) =>
    fixed
      ? css`
          position: fixed;
          top: ${fixed === true ? TOP_NAV_HEIGHT : fixed.top}px;
          z-index: 20;
        `
      : ""}
  ${({ sticky }) =>
    sticky
      ? css`
          position: sticky;
          top: ${sticky === true ? TOP_NAV_HEIGHT : sticky.top}px;
          z-index: 20;
        `
      : ""}
`;
NavTabs.defaultProps = {
  height: DEFAULT_NAV_TABS_HEIGHT,
  bg: "white",
  width: "100%",
  display: "flex",
};

export default NavTabs;
