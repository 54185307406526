import React, { ReactNode } from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../Box";
import { UserAvatarProps } from "../UserAvatar";
import UserMeta, { UserMetaProps } from "./UserMeta";

export interface UserActionListItemProps extends BoxProps {
  action: ReactNode;
  avatarProps?: UserAvatarProps;
  metaProps?: Partial<UserMetaProps>;
  profile: UserMetaProps["profile"];
}

const UserActionListItem: React.FC<UserActionListItemProps> = ({ metaProps, action, profile, ...boxProps }) => {
  return (
    <UserActionListItemWrapper {...boxProps}>
      <UserMeta profile={profile} {...metaProps} />
      {action}
    </UserActionListItemWrapper>
  );
};

export interface UserActionListItemWrapperProps extends BoxProps {}
export const UserActionListItemWrapper = styled(Box)<UserActionListItemWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
UserActionListItemWrapper.defaultProps = {
  mt: 3,
};

export default UserActionListItem;
