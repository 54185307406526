import { LocationDescriptor } from "history";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toggleSearch } from "../actions/createActions";
import useGoBack from "./useGoBack";

export interface UseTopNavActionsOptions {
  goBackDisabled?: boolean;
  goBackDefaultLocation?: LocationDescriptor;
}
const useTopNavActions = (
  options?: UseTopNavActionsOptions,
): {
  onProductSearchClick: () => void;
  goBack: () => void;
} => {
  const goBack = useGoBack(options?.goBackDisabled, options?.goBackDefaultLocation);
  const dispatch = useDispatch();

  const onProductSearchClick = useCallback(() => {
    dispatch(toggleSearch());
  }, [dispatch]);

  return useMemo(
    () => ({
      onProductSearchClick,
      goBack,
    }),
    [onProductSearchClick, goBack],
  );
};

export default useTopNavActions;
