import React from "react";
import { PostsAPI, PostTypeKey } from "../../typings/API-V2";
import { CreateImagePostMenuProps } from "./CreateImagePostMenu";
import { CreateLinkPostMenuProps } from "./CreateLinkPostMenu";
import { CreateListingPostMenuProps } from "./CreateListingPostMenu";
import { CreateTextPostMenuProps } from "./CreateTextPostMenu";

export type PostMenuState =
  | {
      type: null;
    }
  | {
      type: PostTypeKey.Image;
      props?: Omit<CreateImagePostMenuProps, "close" | "isOpen">;
    }
  | {
      type: PostTypeKey.Link;
      props?: Omit<CreateLinkPostMenuProps, "close" | "isOpen">;
    }
  | {
      type: PostTypeKey.Listing;
      props?: Omit<CreateListingPostMenuProps, "close" | "isOpen">;
    }
  | {
      type: PostTypeKey.Text;
      props?: Omit<CreateTextPostMenuProps, "close" | "isOpen">;
    };

export interface PostMenuContextValue {
  editPost: PostsAPI.Post | null;
  postType: PostTypeKey | null;
  setEditPost: (post: PostsAPI.Post | null) => void;
  setPostMenu: (postMenu: PostTypeKey | PostMenuState | null) => void;
  setPostType: (postType: PostTypeKey | null) => void;
}

const PostMenuContext = React.createContext<PostMenuContextValue>({
  editPost: null,
  postType: null,
  setEditPost: () => {},
  setPostMenu: () => {},
  setPostType: () => {},
});

export default PostMenuContext;
