import { AxiosError } from "axios";
import { ParsedToken } from "firebase/auth";
import { Action as HistoryAction } from "history";
import { forEach } from "lodash-es";
import { Action as ReduxAction, Dispatch } from "redux";
import { updateEntities } from "redux-query";
import dao from "../dao";
import ActionType from "../enums/ActionType";
import LocalStorageKey from "../enums/LocalStorageKey";
import PriceType from "../enums/PriceType";
import ShoeCondition from "../enums/ShoeCondition";
import { VendorKey, VendorSlug } from "../enums/Vendor";
import events, { trackEvent } from "../helpers/analyticsUtil";
import { formatRawPrice, PriceAddOn } from "../helpers/askUtil";
import { setJsonItem } from "../helpers/localStorageUtil";
import { ProductPriceAlarm } from "../helpers/productPriceAlertUtil";
import { SizeUnit } from "../helpers/sizeConversionUtil";
import thunkUtil from "../helpers/thunkUtil";
import { AppMeta, AppStackState } from "../reducers/appReducer";
import { NativeAppState } from "../reducers/nativeAppReducer";
import { Price, UsersAPI, VendorsAPI } from "../typings/API";
import EntitiesState from "../typings/EntitiesState";

export interface VendorPricesMeta {
  productSlug: string;
  vendorSlug: string;
  conditions: ShoeCondition[];
  size?: string;
}

export interface Actions {
  setPlugdReferrerId: (referrerId: string) => void;
  createProductAlarm: (userId: string, alarm: ProductPriceAlarm) => Promise<void>;
  deleteProductAlarm: (userId: string, alarmId: number) => Promise<void>;
  dismissReview: (review: UsersAPI.Review) => void;
  dismissAnnouncement: (announcementId: number) => void;
  getPartnerProductPrices: (partner: VendorsAPI.PartnerConfig, productSlug: string) => Promise<void>;
  getProductPriceHistory: (productId: number, start: string, end?: string) => Promise<void>;
  getUsedBid: (productSlug: string) => Promise<void>;
  getProductAlarms: (userId: string, productId?: number) => Promise<void>;
  getVendorPrices: (
    vendors: VendorsAPI.VendorConfig[],
    productSlug: string,
    size?: string,
    includeUsed?: boolean,
    countryCode?: string,
  ) => Promise<void>;
  getVendorUsedAsks: (productSlug: string, size: string, countryCode?: string) => Promise<void>;
  setAccountContext: (userId: string | null) => void;
  setCurrencyPreference: (currency: string) => void;
  setDisableShippingCosts: (disabled: boolean) => void;
  setShippingLocation: (shippingLocation: UsersAPI.ShippingLocation | null) => void;
  setSizePreferenceUnit: (unit: SizeUnit) => void;
  trackHistoryIndex: (action: HistoryAction) => void;
  updateProductAlarm: (userId: string, alarmId: number, alarm: ProductPriceAlarm) => Promise<void>;
}

export interface Action<T, P = unknown> extends ReduxAction<T> {
  payload?: P;
}

interface GetVendorAskMeta {
  conditions: ShoeCondition[];
  productSlug: string;
  size?: string;
  vendorSlug: string;
}

/* currently disabled since fetching location from IP was throwing errors */
// export const updateIPInfo = (ipInfo: IPAPI.IPInfo): Action<ActionType.UPDATE_IP_INFO, IPAPI.IPInfo> => ({
//   type: ActionType.UPDATE_IP_INFO,
//   payload: ipInfo,
// });

// start moving methods out of createActions so we don't create all actions whenever one is needed
export const incrementVisits = (): Action<ActionType.INCREMENT_VISITS> => ({
  type: ActionType.INCREMENT_VISITS,
});

export const toggleSideMenu = (open?: boolean): Action<ActionType.TOGGLE_SIDE_MENU> => ({
  type: ActionType.TOGGLE_SIDE_MENU,
  payload: open,
});

export const toggleSearch = (open?: boolean): Action<ActionType.TOGGLE_SEARCH> => ({
  type: ActionType.TOGGLE_SEARCH,
  payload: open,
});

export const setClaims = (claims: ParsedToken | null): Action<ActionType.SET_CLAIMS> => ({
  type: ActionType.SET_CLAIMS,
  payload: claims,
});

export const viewProduct = (productId: number): Action<ActionType.UPDATE_RECENTLY_VIEWED> => ({
  type: ActionType.UPDATE_RECENTLY_VIEWED,
  payload: productId,
});

export const updateAppMeta = (appMeta: Partial<AppMeta>): Action<ActionType.UPDATE_APP_META> => ({
  type: ActionType.UPDATE_APP_META,
  payload: appMeta,
});

export const replaceStackState = (
  pageKey: string,
  pageState: AppStackState,
): Action<ActionType.REPLACE_STACK_STATE> => ({
  type: ActionType.REPLACE_STACK_STATE,
  payload: {
    key: pageKey,
    state: pageState,
  },
});

export const updateNativeAppState = (updates: Partial<NativeAppState>): Action<ActionType.UPDATE_NATIVE_APP_STATE> => ({
  type: ActionType.UPDATE_NATIVE_APP_STATE,
  payload: updates,
});

export const appendListComments = (key: string, commentIds: number[]) => ({
  type: ActionType.APPEND_LIST_COMMENTS,
  payload: { key, commentIds },
});

export const replaceListComments = (key: string, commentIds: number[]) => ({
  type: ActionType.REPLACE_LIST_COMMENTS,
  payload: { key, commentIds },
});

export const appendListPosts = (key: string, postIds: number[]) => ({
  type: ActionType.APPEND_LIST_POSTS,
  payload: { key, postIds },
});

export const replaceListPosts = (key: string, postIds: number[]) => ({
  type: ActionType.REPLACE_LIST_POSTS,
  payload: { key, postIds },
});

const createActions = (dispatch: Dispatch): Actions => ({
  setPlugdReferrerId(referrerId) {
    dispatch({
      type: ActionType.SET_PLUGD_REFERRER_ID,
      payload: referrerId,
    });
  },

  dismissAnnouncement(announcementId: number): void {
    trackEvent(events.Announcement.Closed, { announcement_id: announcementId });
    dispatch({
      type: ActionType.DISMISS_ANNOUNCEMENT,
      payload: announcementId,
    });
  },

  trackHistoryIndex(action: HistoryAction): void {
    dispatch({
      type: ActionType.TRACK_HISTORY_INDEX,
      payload: action,
    });
  },

  setSizePreferenceUnit(sizeUnit): void {
    localStorage.setItem(LocalStorageKey.SIZE_PREFERENCE_UNIT, sizeUnit);
    dispatch(
      updateEntities<EntitiesState>({
        preferences: (prevState) => ({
          ...prevState,
          sizePreferenceUnit: sizeUnit,
        }),
      }),
    );
  },

  setCurrencyPreference(currency): void {
    localStorage.setItem(LocalStorageKey.CURRENCY_PREFERENCE, currency);
    dispatch(
      updateEntities<EntitiesState>({
        preferences: (prevValue) => ({
          ...prevValue,
          currencyPreference: currency,
        }),
      }),
    );
  },

  setShippingLocation(shippingLocation: UsersAPI.ShippingLocation | null): void {
    setJsonItem(LocalStorageKey.LAST_SHIPPING_LOCATION, shippingLocation);
    dispatch(
      updateEntities<EntitiesState>({
        preferences: (prevValue) => ({
          ...prevValue,
          shippingLocation,
        }),
      }),
    );
  },

  setDisableShippingCosts(disabled: boolean): void {
    localStorage.setItem(LocalStorageKey.DISABLE_SHIPPING_COSTS, String(disabled));
    dispatch(
      updateEntities<EntitiesState>({
        preferences: (prevValue) => ({
          ...prevValue,
          disableShippingCosts: disabled,
        }),
      }),
    );
  },

  dismissReview(review: UsersAPI.Review): void {
    setJsonItem(LocalStorageKey.REVIEW, review);
    dispatch(
      updateEntities<EntitiesState>({
        preferences: (prevValue) => ({
          ...prevValue,
          review,
        }),
      }),
    );
  },

  setAccountContext(userId: string | null): void {
    dispatch({
      type: ActionType.SWITCH_ADMIN_ACCOUNT_CONTEXT,
      payload: userId,
    });
  },

  async getProductPriceHistory(productId: number, start: string, end?: string): Promise<void> {
    const thunk = thunkUtil<{ productId: number }>(dispatch, ActionType.GET_PRODUCT_PRICE_HISTORY, {
      meta: { productId },
    });
    thunk.fetch();
    await dao
      .getProductPriceHistory(productId, start, end)
      .then((result): void => {
        thunk.success<{ priceHistory: number[] }>(result);
      })
      .catch((error): void => {
        thunk.fail(error as AxiosError);
      });
  },

  async getPartnerProductPrices(partner: VendorsAPI.PartnerConfig, productSlug: string): Promise<void> {
    const meta: VendorPricesMeta = {
      productSlug,
      vendorSlug: partner.slug,
      conditions: [ShoeCondition.New],
    };

    if (partner.has_used) {
      meta.conditions.push(ShoeCondition.Used);
    }

    const thunk = thunkUtil<GetVendorAskMeta>(dispatch, ActionType.GET_VENDOR_ASKS, {
      meta,
    });

    thunk.fetch();
    dao
      .getPartnerPrice(partner.partner_id, productSlug)
      .then((result): void => thunk.success<{ asks: Price[] }>(result))
      .catch((error): void => thunk.fail(error));
  },

  async createProductAlarm(userId: string, alarm: ProductPriceAlarm): Promise<void> {
    const thunk = thunkUtil(dispatch, ActionType.CREATE_PRODUCT_ALARM);
    thunk.fetch();
    await dao
      .createUserProductAlarm(userId, alarm)
      .then((result): void => {
        thunk.success<ProductPriceAlarm>({ ...alarm, id: result.id });
      })
      .catch((error): void => {
        thunk.fail(error);
        return error;
      });
  },

  async getProductAlarms(userId, productId?: number): Promise<void> {
    const thunk = thunkUtil(dispatch, ActionType.GET_PRODUCT_ALARM);
    thunk.fetch();
    await dao
      .getUserProductAlarms(userId, productId, true)
      .then((result): void => {
        thunk.success<ProductPriceAlarm[]>(result);
      })
      .catch((error: Error): void => {
        thunk.fail(error);
        throw error;
      });
  },

  async updateProductAlarm(userId: string, alarmId: number, alarm: ProductPriceAlarm): Promise<void> {
    const updatedAlarm = { ...alarm, id: alarmId };

    const thunk = thunkUtil(dispatch, ActionType.UPDATE_PRODUCT_ALARM);
    thunk.fetch();
    return await dao
      .updateUserProductAlarm(userId, alarmId, updatedAlarm)
      .then((): void => {
        thunk.success<ProductPriceAlarm>(updatedAlarm);
      })
      .catch((error: Error): void => {
        thunk.fail(error);
        throw error;
      });
  },

  async deleteProductAlarm(userId: string, alarmId: number): Promise<void> {
    const thunk = thunkUtil(dispatch, ActionType.DELETE_PRODUCT_ALARM);
    thunk.fetch();
    return await dao
      .deleteUserProductAlarm(userId, alarmId)
      .then((): void => {
        thunk.success({ id: alarmId });
      })
      .catch((error): void => {
        thunk.fail(error);
        throw error;
      });
  },

  async getVendorPrices(
    vendors: VendorsAPI.VendorConfig[],
    productSlug: string,
    size?: string,
    includeUsed?: boolean,
    countryCode?: string,
  ): Promise<void> {
    forEach(vendors, ({ slug: vendorSlug, partner_id: partnerId, has_used: hasUsed }) => {
      const meta: VendorPricesMeta = {
        productSlug,
        vendorSlug,
        conditions: [ShoeCondition.New],
        size,
      };

      // goat is excluded since there is a specific endpoint
      if (hasUsed && vendorSlug !== VendorSlug.Goat) {
        meta.conditions.push(ShoeCondition.Used);
      }

      const thunk = thunkUtil<GetVendorAskMeta>(dispatch, ActionType.GET_VENDOR_ASKS, {
        meta,
      });

      thunk.fetch();
      if (partnerId) {
        dao
          .getPartnerPrice(partnerId, productSlug)
          .then((result): void => thunk.success<{ asks: Price[] }>(result))
          .catch((error): void => thunk.fail(error));
      } else if (vendorSlug === VendorSlug.EBay) {
        dao
          .getEbayAsks(productSlug, undefined, "new", countryCode)
          .then((result): void => thunk.success<{ asks: Price[]; bids?: Price[] }>(result))
          .catch((error): void => thunk.fail(error));

        // will remove after testing and identifying average number of requests;
        // if (size && size !== "all") {
        //   dao
        //     .getEbayAsks(slug, undefined, "new", countryCode)
        //     .then((result): void => thunk.success<{ asks: Price[]; bids?: Price[] }>(result))
        //     .catch((error): void => thunk.fail(error));
        // } else {
        //   return;
        // }
      } else {
        dao
          .getVendorPrice(vendorSlug, productSlug)
          .then((result): void => thunk.success<{ asks: Price[]; bids?: Price[] }>(result))
          .catch((error): void => thunk.fail(error));
      }
    });

    if (includeUsed) {
      const goatThunk = thunkUtil<GetVendorAskMeta>(dispatch, ActionType.GET_VENDOR_ASKS, {
        meta: {
          conditions: [ShoeCondition.Used],
          productSlug,
          size,
          vendorSlug: VendorSlug.Goat,
        },
      });
      goatThunk.fetch();
      dao
        .getGoatUsedAsks(productSlug, size)
        .then((result): void => goatThunk.success<{ asks: Price[]; bids?: Price[] }>(result))
        .catch((error): void => goatThunk.fail(error));
      // const ebayThunk = thunkUtil<GetVendorAskMeta>(dispatch, ActionType.GET_VENDOR_ASKS, {
      //   meta: {
      //     conditions: [ShoeCondition.Used],
      //     slug,
      //     size,
      //     vendor: Vendor.EBay,
      //   },
      // });
      // ebayThunk.fetch();
      // dao
      //   .getEbayAsks(slug, size === "all" ? undefined : size, "used", false, size === "all" ? 10 : 100)
      //   .then((result): void => {
      //     ebayThunk.success<{ asks: PriceAddOn.WithFees[] }>(result);
      //   })
      //   .catch((error): void => {
      //     console.error(error);
      //     ebayThunk.fail(error);
      //   });
    }
  },

  async getVendorUsedAsks(productSlug: string, size: string, countryCode?: string): Promise<void> {
    const flightClubThunk = thunkUtil<GetVendorAskMeta>(dispatch, ActionType.GET_VENDOR_ASKS, {
      meta: {
        conditions: [ShoeCondition.Used],
        productSlug,
        size,
        vendorSlug: VendorSlug.FlightClub,
      },
    });
    flightClubThunk.fetch();
    dao
      .getVendorPrice(VendorSlug.FlightClub, productSlug)
      .then((result): void => flightClubThunk.success<{ asks: Price[]; bids?: Price[] }>(result))
      .catch((error): void => flightClubThunk.fail(error));

    const ebayThunk = thunkUtil<GetVendorAskMeta>(dispatch, ActionType.GET_VENDOR_ASKS, {
      meta: {
        conditions: [ShoeCondition.Used],
        productSlug,
        size,
        vendorSlug: VendorSlug.EBay,
      },
    });
    ebayThunk.fetch();
    dao
      .getEbayAsks(productSlug, size === "all" ? undefined : size, "used", countryCode, true, size === "all" ? 10 : 100)
      .then((result): void => {
        ebayThunk.success<{ asks: PriceAddOn.WithFees[] }>(result);
      })
      .catch((error): void => {
        console.error(error);
        ebayThunk.fail(error);
      });

    const shoepugsThunk = thunkUtil<GetVendorAskMeta>(dispatch, ActionType.GET_VENDOR_ASKS, {
      meta: {
        conditions: [ShoeCondition.Used],
        productSlug,
        size,
        vendorSlug: VendorSlug.ShoePugs,
      },
    });
    shoepugsThunk.fetch();
    dao
      .getPartnerPrice(VendorKey.ShoePugs, productSlug)
      .then((result): void => shoepugsThunk.success<{ asks: Price[] }>(result))
      .catch((error): void => shoepugsThunk.fail(error));

    const goatThunk = thunkUtil<GetVendorAskMeta>(dispatch, ActionType.GET_VENDOR_ASKS, {
      meta: {
        conditions: [ShoeCondition.Used],
        productSlug,
        size,
        vendorSlug: VendorSlug.Goat,
      },
    });
    goatThunk.fetch();
    dao
      .getGoatUsedAsks(productSlug, size)
      .then((result): void =>
        goatThunk.success<{ asks: Price[]; bids: Price[] }>({
          asks: result.asks.map((a) => formatRawPrice(a, PriceType.Ask)),
          bids: result.bids.map((b) => formatRawPrice(b, PriceType.Bid)),
        }),
      )
      .catch((error): void => goatThunk.fail(error));
  },

  async getUsedBid(goatSlug: string): Promise<void> {
    const meta = {
      goatSlug,
    };

    const thunk = thunkUtil<{ goatSlug: string }>(dispatch, ActionType.GET_USED_BID, { meta });
    thunk.fetch();
    await dao
      .getGoatUsedBid(goatSlug)
      .then((result): void => {
        thunk.success<Price>(result);
      })
      .catch((error): void => {
        thunk.fail(error);
      });
  },
});

export default createActions;
