export enum VendorKey {
  EBay = "ebay",
  FlightClub = "flight_club",
  Goat = "goat",
  ShoePugs = "shoepugs.myshopify.com",
  Stockx = "stockx",
  // Farfetch = "farfetch",
  // SoleStage = "sole_stage",
  // SoleSupremacy = "sole_supremacy",
  // StadiumGoods = "stadium_goods",
  // UrbanNecessities = "urban_necessities",
}

export enum VendorSlug {
  EBay = "ebay",
  FlightClub = "flight-club",
  Goat = "goat",
  ShoePugs = "shoepugs",
  Stockx = "stockx",
  // Farfetch = "farfetch",
  // SoleStage = "solestage",
  // SoleSupremacy = "sole-supremacy",
  // StadiumGoods = "stadium-goods",
  // UrbanNecessities = "urban-necessities",
}
