import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import config from "../config";

const app = initializeApp(config.FIREBASE_CONFIG);
const auth = getAuth(app);

export const firebaseInstances = {
  auth: auth,
  app: app,
};
