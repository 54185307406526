import { remove, uniq } from "lodash-es";
import { QueryConfig } from "redux-query";
import { UsersAPI } from "src/typings/API";
import config from "../../config";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { UserBlocksState } from "../../typings/EntitiesState";

export const blockUserQuery = (blockeeId: string): QueryConfig<UserBlocksState> => ({
  url: `${config.API_URL}/users/${blockeeId}/block`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    blockedUserIds: (prevVal) => uniq(prevVal.concat(blockeeId)),
  },
});

export const unblockUserQuery = (blockeeId: string): QueryConfig<UserBlocksState> => ({
  url: `${config.API_URL}/users/${blockeeId}/block`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "DELETE",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    blockedUserIds: (prevVal) => uniq(remove(prevVal, blockeeId)),
  },
});

export const getBlockedUsers = (userId: string): QueryConfig<UserBlocksState> => ({
  url: `${config.API_URL}/users/${userId}/blocked-users`,
  meta: {
    includeToken: true,
    headers: STANDARD_HEADERS,
  },
  transform: (response: UsersAPI.GetBlockedUsersResponse): UserBlocksState => {
    return {
      blockedUserIds: response.data,
    };
  },
  update: {
    blockedUserIds: (oldValue, newValue) => {
      return newValue;
    },
  },
});

// todo: add unblock method
