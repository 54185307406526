import copy from "copy-text-to-clipboard";
import React, { useCallback, useEffect, useState } from "react";
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "react-share";
import styled from "styled-components";
import { MarketingCampaign, MarketingMedium } from "../../enums/Marketing";
import { generateShareLink } from "../../helpers/shareUtils";
import { Button, Dimmer, Loader, Message } from "../../libs/semantic-ui";
import { Box } from "../../UI";

export interface ShareProps extends ShareData {
  url: string;
  title: string;
  image?: string;
  medium?: MarketingMedium;
}

const Share: React.FC<ShareProps> = ({ url, title, medium }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    async function initializeLink() {
      setLoading(true);
      const urlObj = new URL(url);
      const path = urlObj.pathname;
      try {
        const branchLink = await generateShareLink({
          path,
          campaign: MarketingCampaign.ORGANIC,
          medium: medium,
        });
        setShareUrl(branchLink);
        setLoading(false);
      } catch (error) {
        const { message } = error as Error;
        setError(message || "Unknown error please try again later");
        setLoading(false);
      }
    }
    initializeLink();
  }, [medium, url]);

  const copyToClipBoard = useCallback((): void => {
    copy(shareUrl);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
    return;
  }, [shareUrl]);

  return (
    <Box display="flex" justifyContent="space-evenly">
      {error ? (
        <Message negative>
          <Message.Header>{error}</Message.Header>
          <p>Please try again later</p>
        </Message>
      ) : (
        <>
          <Dimmer active={loading} inverted>
            <Loader active={loading} />
          </Dimmer>
          <TwitterShareButton title={title} url={shareUrl} via={"tryplugd"}>
            {/* <TwitterIcon size={30} /> */}
            <Button circular color="twitter" icon="twitter" />
          </TwitterShareButton>
          <FacebookShareButton quote={`${title}`} url={shareUrl}>
            <Button circular color="facebook" icon="facebook" />
          </FacebookShareButton>
          <EmailShareButton title={title} url={shareUrl}>
            <Button circular color="teal" icon="mail" />
          </EmailShareButton>
          <ShareButton onClick={copyToClipBoard}>
            <Button circular color="black" icon={copySuccess ? "check" : "linkify"} />
          </ShareButton>
        </>
      )}
    </Box>
  );
};

const ShareButton = styled.button`
  background-color: transparent;
  border: none;
  vertical-align: top;
  cursor: pointer;
  outline: 0;
`;

export default Share;
