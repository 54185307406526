import React from "react";
import { Box, SearchInput, Text } from "../../UI";
import { FullscreenMenuHeader, FullscreenMenuHeaderProps } from "./FullscreenMenu";

export interface FullscreenSearchMenuHeaderProps extends FullscreenMenuHeaderProps {
  close: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement> & React.FormEventHandler<HTMLInputElement>;
  onClear: React.MouseEventHandler<HTMLDivElement>;
  placeholder: string;
  queryText: string;
}

const FullscreenSearchMenuHeader = React.forwardRef<HTMLInputElement, FullscreenSearchMenuHeaderProps>(
  ({ close, onChange, onClear, placeholder, queryText }, ref) => {
    return (
      <FullscreenMenuHeader>
        <SearchInput ref={ref} value={queryText} onClear={onClear} placeholder={placeholder} onChange={onChange} />
        <Box
          onClick={close}
          height="100%"
          ml={3}
          display="flex"
          alignItems="center"
          role="button"
          style={{ cursor: "pointer" }}
        >
          <Text>Cancel</Text>
        </Box>
      </FullscreenMenuHeader>
    );
  },
);
FullscreenSearchMenuHeader.displayName = "FullscreenSearchMenuHeader";

export default FullscreenSearchMenuHeader;
