import * as FullStory from "@fullstory/browser";
import { Options } from "@segment/analytics-next";
import { Callback } from "@segment/analytics-next/dist/pkg/core/arguments-resolver";
import merge from "deepmerge";
import { reduce, toString } from "lodash-es";
import { ProductsAPI } from "../typings/API";
import { ProductFilters } from "../typings/ProductFilters";

/**
 * Spec: V2 Ecommerce Events
 * https://segment.com/docs/connections/spec/ecommerce/v2/
 * Segment’s e-commerce spec helps define the journey for a customer as they browse your store, click on promotions, view products, add those products to a cart, and complete a purchase.
 */
const segmentEcommerceSpec = {
  Products: {
    Searched: "Products Searched",
  },
  Product: {
    Viewed: "Product Viewed",
    AddedToWishlist: "Product Added to Wishlist",
    RemovedFromWishlist: "Product Removed from Wishlist",
    Shared: "Product Shared",
  },
  ProductList: {
    Viewed: "Product List Viewed",
    Filtered: "Product List Filtered",
  },
  Promotion: {
    PromotionViewed: "Promotion Viewed",
    PromotionClicked: "Promotion Clicked",
  },
};

/**
 * Native Mobile Spec
 * https://segment.com/docs/connections/spec/mobile/
 */
const segmentMobileNativeSpec = {
  PushNotification: {
    Tapped: "Push Notification Tapped",
  },
  Install: {
    Attributed: "Install Attributed",
  },
};

/**
 * Events passed from the native app to the webview to be tracked
 */
const nativeAppEvents = {
  ContactsPermission: {
    Updated: "Contacts Permission Updated",
  },
  NotificationsPermission: {
    Updated: "Notifications Permission Updated",
  },
};

/**
 * custom events
 */
const customEvents = {
  UpdateNotification: {
    Viewed: "Update Notification Viewed",
    Dismissed: "Update Notification Dismissed",
    Accepted: "Update Notification Accepted",
  },
  ProductSelectorMenu: {
    Viewed: "Product Selector Menu Viewed",
  },
  CommunitySelectorMenu: {
    Viewed: "Community Selector Menu Viewed",
  },
  CommunityMembersMenu: {
    Viewed: "Community Members Menu Viewed",
  },
  TagImageMenu: {
    Viewed: "Tag Image Menu Viewed",
  },
  FullscreenPost: {
    Viewed: "Fullscreen Post Viewed",
  },
  Announcement: {
    Viewed: "Announcement Viewed",
    Clicked: "Announcement Clicked",
    Closed: "Announcement Closed",
  },
  Message: {
    Sent: "Message Sent",
  },
  MonetizationWaitist: {
    Joined: "Monetization Waitist Joined",
  },
  User: {
    Blocked: "User Blocked",
    Deleted: "User Deleted",
    Followed: "User Followed",
    ResetPassword: "User Reset Password",
    SignedIn: "User Signed In",
    SignedOut: "User Signed Out",
    SignedUp: "User Signed Up",
    Unfollowed: "User Unfollowed",
  },
  UserProfile: {
    Shared: "User Profile Shared",
  },
  UserCollection: {
    ProductAdded: "User Collection Product Added",
    ProductRemoved: "User Collection Product Removed",
  },
  UserPreference: {
    Viewed: "User Preference Viewed",
    Updated: "User Preference Updated",
  },
  UsersList: {
    Viewed: "Users List Viewed",
  },
  PostList: {
    Filtered: "Post List Filtered",
  },
  Product: {
    AddedToFavorites: "Product Added to Favorites",
    RemovedFromFavorites: "Product Removed from Favorites",
  },
  ImageTag: {
    Clicked: "Image Tag Clicked",
    Viewed: "Image Tag Viewed",
  },
  Invite: {
    Copied: "Invite Copied",
    Shared: "Invite Shared",
  },
  ProductFilterMenu: {
    Viewed: "Product Filter Menu Viewed",
  },
  ProductListing: {
    Viewed: "Product Detail Viewed",
  },
  ProductSize: {
    Updated: "Product Size Updated",
  },
  RestocksSettings: {
    Viewed: "Restocks Settings Viewed",
    Updated: "Restocks Settings Updated",
  },
  AffiliateLink: {
    Clicked: "Affiliate Link Clicked",
  },
  /**
   * @deprecated
   * The side menu has been removed
   */
  SideMenu: {
    Viewed: "Side Menu Viewed",
  },
  ShareMenu: {
    Viewed: "Share Menu Viewed",
    Closed: "Share Menu Closed",
  },
  OutboundLink: {
    Clicked: "Outbound Link Clicked",
  },
  ProductAlarm: {
    Updated: "Product Alarm Updated",
    Created: "Product Alarm Created",
    Deleted: "Product Alarm Deleted",
    Viewed: "Product Alarm Viewed",
  },
  Post: {
    Archived: "Post Archived",
    Created: "Post Created",
    Edited: "Post Edited",
    Shared: "Post Shared",
    Voted: "Post Voted",
  },
  Raffle: {
    Entered: "Raffle Entered",
  },
  RaffleList: {
    Filtered: "Raffle List Filtered",
  },
  RaffleFilterMenu: {
    Viewed: "Raffle Filter Menu Viewed",
  },
  ReleaseList: {
    Filtered: "Release List Filtered",
  },
  ReleaseFilterMenu: {
    Viewed: "Release Filter Menu Viewed",
  },
  Comment: {
    Created: "Comment Created",
    Voted: "Comment Voted",
  },
  CommentsMenu: {
    Viewed: "Comments Menu Viewed",
  },
  Community: {
    Subscribed: "Community Subscribed",
    Unsubscribed: "Community Unsubscribed",
    Shared: "Community Shared",
  },
  CreatePostMenu: {
    Viewed: "Create Post Menu Viewed",
  },
  CreatePostSelectorMenu: {
    Viewed: "Create Post Selector Menu Viewed",
  },
  LoginModal: {
    Viewed: "Login Modal Viewed",
  },
  AppUpdateModal: {
    Viewed: "App Update Modal Viewed",
  },
  AppDownloadModal: {
    Viewed: "App Download Modal Viewed",
  },
  AuthScreen: {
    Dismissed: "Auth Screen Dismissed",
    Skipped: "Auth Screen Skipped",
    Viewed: "Auth Screen Viewed",
  },
  AppReviewPrompt: {
    Viewed: "App Review Prompt Viewed",
    Reviewed: "App Review Prompt Reviewed",
  },
  UserLeaderboard: {
    Filtered: "User Leaderboard Filtered",
  },
  UserLeaderboardMenu: {
    Viewed: "User Leaderboard Menu Viewed",
  },
  Style: {
    Filtered: "Style Filtered",
  },
  SubscriptionOnboardingMenu: {
    Viewed: "Subscription Onboarding Menu Viewed",
  },
};

const events = merge(merge(merge(segmentEcommerceSpec, segmentMobileNativeSpec), customEvents), nativeAppEvents);

export default events;

export const pages = {
  Alarms: "Alarm",
  Brand: "Brand",
  Brands: "Brands",
  CheckoutCancel: "Checkout Cancel",
  CheckoutSuccess: "Checkout Success",
  Collection: "Collection",
  Community: "Community",
  Conversation: "Conversation",
  ConversationList: "Conversation List",
  Feed: "Feed",
  ForYou: "ForYou",
  Listings: "Listings",
  Home: "Home",
  Inbox: "Inbox",
  Invite: "Invite",
  Login: "Login",
  MonetizationWaitlistAbout: "Monetization Waitlist About",
  MonetizationWaitlistApproved: "Monetization Waitlist Approved",
  MonetizationWaitlistLanding: "Monetization Waitlist Landing",
  MonetizationWaitlistPosition: "Monetization Waitlist Position",
  NotFound: "404",
  PasswordReset: "Reset Password",
  Post: "Post",
  Product: "Product",
  ProductACO: "Product ACO",
  ProductComments: "Product Comments",
  ProductLaunchComments: "Releases & Raffles Comments",
  ProductLaunches: "Releases & Raffles",
  ProductPosts: "Product Posts",
  ProductRaffles: "Product Raffles",
  ProductReleases: "Product Releases",
  ProductUsed: "Used Product",
  Raffles: "Raffles",
  Restocks: "Restocks",
  ReleaseCalendar: "Release Calendar",
  Search: "Search",
  Signup: "Signup",
  Style: "Style",
  Subscriptions: "Subscriptions",
  UserProfile: "User Profile",
  UserLeaderboard: "User Leaderboard",
  VendorCollection: "Vendor Collection",
  VendorLandingPage: "Vendor Landing Page",
  VendorProduct: "Vendor Product",
  VendorProductUsed: "Used Vendor Product",
};

namespace Analytics {
  // https://segment.com/docs/connections/spec/ecommerce/v2/#product-list-filtered
  export interface Filter {
    type: string;
    value: string;
  }

  export interface Product {
    product_id?: string;
    sku?: string;
    category?: string;
    name?: string;
    brand?: string;
    variant?: string;
    price?: number;
    quantity?: number;
    coupon?: string;
    position?: number;
    url?: string;
    image_url?: string;
  }

  export interface ProductListViewedProps {
    list_id: string;
    category?: string;
    products?: Product[];
  }
}

export function trackEvent(eventName: string, properties?: object, options?: Options, callback?: Callback): void {
  if (window.analytics) {
    window.analytics.ready(() => window.analytics.track(eventName, properties, options, callback));
  }

  if (FullStory.isInitialized()) {
    FullStory.event(eventName, properties || {});
  }
}

/**
 * NOTE: Segment allows the first two parameters to be "category" and "name" if the first two args are strings. We only allow "name" as the first param.
 *
 * @param pageName
 * @param properties
 * @param options
 * @param callback
 */
export function trackPage(pageName: string, properties?: object, options?: Options, callback?: Callback): void {
  if (window.analytics) {
    window.analytics.ready(() => window.analytics.page(pageName, properties, options, callback));
  }

  if (FullStory.isInitialized()) {
    FullStory.setVars("page", properties || {});
  }
}

export function reset(): void {
  if (window.analytics) {
    window.analytics.reset();
  }

  if (FullStory.isInitialized()) {
    FullStory.shutdown();
  }
}

// format and track filters
export const trackFilters = (listId: string, filters: ProductFilters): void => {
  // track the filters
  if (window.analytics) {
    // format the filters
    const formattedFilters = reduce<ProductFilters, Analytics.Filter[]>(
      filters,
      (result, filterValue, filterKey) => {
        if (filterValue instanceof Set) {
          filterValue.forEach((value) => {
            result.push({
              type: filterKey,
              value: value,
            });
          });
        }
        return result;
      },
      [],
    );

    window.analytics.ready(() =>
      window.analytics.track(events.ProductList.Filtered, {
        list_id: listId,
        filters: formattedFilters,
      }),
    );
  }
};

export const trackProductList = (listId: string, products: ProductsAPI.Product[]): void => {
  if (window.analytics) {
    const productListViewedProps: Analytics.ProductListViewedProps = {
      list_id: listId,
      products: products.map<Analytics.Product>((p, idx) => ({
        name: p.name,
        position: idx,
        product_id: toString(p.id),
        sku: p.style_id,
      })),
    };

    window.analytics.ready(() => window.analytics.track(events.ProductList.Viewed, productListViewedProps));
  }
};
