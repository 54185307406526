import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CommunitiesAPI } from "../../../typings/API";
import CreatePostTitleInput from "../CreatePostTitleInput";
import PostMentionMenu from "../PostMentionMenu";
import { UseInputHook } from "../types/inputHooks";
import useMentionMenu from "./useMentionMenu";

interface UseTitleInputOptions {
  defaultValue?: string;
  community?: CommunitiesAPI.Community | null;
}

const useTitleInput: UseInputHook<string, UseTitleInputOptions> = ({ defaultValue, community }) => {
  const [title, setTitle] = useState<string>(defaultValue || "");
  const titleInputRef = useRef<HTMLTextAreaElement>(null);

  const {
    close: handleMentionMenuClose,
    isOpen: mentionMenuIsOpen,
    onChange: onTitleChange,
    selectMentionUser,
  } = useMentionMenu({
    inputRefNode: titleInputRef.current,
    setValue: setTitle,
    value: title,
  });

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [titleInputRef]);

  const input = useMemo(
    () => <CreatePostTitleInput onChange={onTitleChange} ref={titleInputRef} value={title} />,
    [onTitleChange, title],
  );
  const menus = useMemo(
    () => (
      <PostMentionMenu
        community={community}
        isOpen={mentionMenuIsOpen}
        close={handleMentionMenuClose}
        onSelect={selectMentionUser}
      />
    ),
    [mentionMenuIsOpen, handleMentionMenuClose, selectMentionUser],
  );

  const clearTitle = useCallback(() => setTitle(""), []);

  return useMemo(
    () => ({
      input,
      menus,
      clearValue: clearTitle,
      value: title,
    }),
    [input, menus, title, clearTitle],
  );
};

export default useTitleInput;
