import { upperCase } from "lodash-es";
import React from "react";
import styled from "styled-components";
import { Box, Text, theme } from "../../../../UI";

export interface DebugLogItemProps {
  logType: "error" | "log" | "warn" | "info" | "debug" | "dir";
  message: string;
}

const DebugLogItem: React.FC<DebugLogItemProps> = ({ logType, message }) => {
  return (
    <LogWrapper width="100%">
      <Box>
        <Text fontWeight={5}>{upperCase(logType)}</Text>
      </Box>
      <Box color={colorSelector(logType)}>{message}</Box>
    </LogWrapper>
  );
};

const colorSelector = (logType: "error" | "log" | "warn" | "info" | "debug" | "dir"): keyof typeof theme.colors => {
  switch (logType) {
    case "error":
      return "red";
    case "warn":
      return "gold";
    default:
      return "black";
  }
};

const LogWrapper = styled(Box)`
  width: 100%;
`;
LogWrapper.defaultProps = {
  mb: 3,
};

export default DebugLogItem;
