import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useSigninCheck } from "reactfire";
import GlobalModalContext from "../components/GlobalModal/GlobalModalContext";
import { EntitlementID } from "../enums/RevenueCat";
import { isSyntheticEvent } from "../helpers/reactUtil";
import { displaySubscriptionOfferings } from "../messages/outboundMessages";
import { RootState } from "../store";
import useSendReactNativeMessage from "./useSendReactNativeMessage";

const useEntitlementCallback = <T extends (...args: any[]) => any>(
  entitlementId: EntitlementID,
  cb: T,
  deps: React.DependencyList,
) => {
  const callback = useCallback<T>(cb, deps);
  const sendReactNativeMessage = useSendReactNativeMessage();
  const isNativeApp = Boolean(window.ReactNativeWebView);
  const { open: openModal } = useContext(GlobalModalContext);
  const hasEntitlement = useSelector((state: RootState) =>
    Boolean(state.entities.currentUserProfile?.active_entitlements.includes(entitlementId)),
  );

  const { data } = useSigninCheck({
    requiredClaims: {
      role: "tester",
    },
  });
  const isAuthenticated = data?.signedIn;

  return useCallback(
    (...args) => {
      // if user has entitlement, execute callback
      if (hasEntitlement) {
        return callback(...args);
      }

      /** if the callback's first argument is a React SyntheticEvent and the user is not authenticated, automatically stop propagation and default behavior (e.g. navigation events) */
      if (isSyntheticEvent(args[0])) {
        const event = args[0];
        event.stopPropagation();
        event.preventDefault();
      }

      // if not the in the native app, and doesn't have subscription
      if (!isNativeApp) {
        // V1: display modal with app download message and link to store
        return openModal({ type: "app_download" });
        // TODO: When Stripe is set up, add signup flow via Stripe
      }

      /** IN NATIVE APP */

      // if using the native app but not authenticated,
      if (!isAuthenticated) {
        // display login screen
        return openModal({ type: "login" });

        // TODO: Add dynamic onboarding after the user signs up / logs in if the user opened the auth screen menu while trying to access Plugd Gold Feature
      }

      // if using the native app but missing entitlement
      if (!hasEntitlement) {
        // attempt to display offers
        return sendReactNativeMessage(displaySubscriptionOfferings());
      }
    },
    [callback, isAuthenticated, isNativeApp, openModal, sendReactNativeMessage, hasEntitlement],
  );
};

export default useEntitlementCallback;
