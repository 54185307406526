import React from "react";

export type CommunityMenuScreen = "name" | "slug" | "description";

export interface CreateCommunityMenuContextValue {
  setScreen: (screen: CommunityMenuScreen | null) => void;
  screen: CommunityMenuScreen | null;
}

const CreateCommunityMenuContext = React.createContext<CreateCommunityMenuContextValue>({
  screen: null,
  setScreen: () => {},
});

export default CreateCommunityMenuContext;
