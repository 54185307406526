import React from "react";
import styled from "styled-components";
import { Box, BoxProps, Text, theme } from "../../UI";

export interface PlugdGoldBadgeProps extends BoxProps {}

const PlugdGoldBadge: React.FC<PlugdGoldBadgeProps> = ({ ...boxProps }) => {
  return (
    <Container background={theme.colors.goldGold} {...boxProps}>
      <BadgeText color="black" fontWeight={5}>
        GOLD
      </BadgeText>
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
Container.defaultProps = {
  position: "absolute",
  borderRadius: 1,
  px: 1,
  py: 0,
  top: "2px",
  right: 2,
};

const BadgeText = styled(Text)`
  font-size: 8px; // non-standard font size
`;

export default PlugdGoldBadge;
