import React, { ReactNode } from "react";
import styled from "styled-components";
import { CommunitiesAPI } from "../../../typings/API";
import Box, { BoxProps } from "../Box";
import Link, { LinkProps } from "../Link";
import CommunityMeta, { CommunityMetaProps } from "./CommunityMeta";

export interface CommunityListSelectItemProps extends BoxProps {
  action?: ReactNode;
  metaProps?: Partial<CommunityMetaProps>;
  community: CommunitiesAPI.Community;
  to?: LinkProps["to"];
}

const CommunityListSelectItem: React.FC<CommunityListSelectItemProps> = ({
  community,
  action,
  to,
  metaProps,
  ...wrapperProps
}) => {
  return (
    <CommunityListSelectItemWrapper as={to ? Link : undefined} {...wrapperProps}>
      <CommunityMeta size={wrapperProps.size} community={community} {...metaProps} />
      {action}
    </CommunityListSelectItemWrapper>
  );
};

export const CommunityListSelectItemWrapper = styled(Box)`
  justify-content: space-between;
`;
CommunityListSelectItemWrapper.defaultProps = {
  py: 2,
};

export default CommunityListSelectItem;
