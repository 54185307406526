import { isInteger, isUndefined, omitBy, orderBy, toString, trim } from "lodash-es";
import { DefaultTheme } from "styled-components";
import isURL from "validator/lib/isURL";
import { hasGroupMentions } from "../components/links/utils/mentionDetect";
import dayjs from "../libs/dayjs";
import { CommentsAPI, PostsAPI, PostTypeKey } from "../typings/API-V2";

export const scoreFromPost = (postOrComment: PostsAPI.Post | CommentsAPI.Comment): number =>
  postOrComment.up_count - postOrComment.down_count;
export const scoreText = (score: number, hideNegative = true, emptyValue = `•`): string => {
  if (hideNegative) {
    return score > 0 ? toString(score) : emptyValue;
  }
  return score === 0 ? emptyValue : toString(score);
};

type ActionColor = keyof DefaultTheme["colors"];
export const actionColors = (
  currentVote?: number | null,
): { upColor?: ActionColor; downColor?: ActionColor; scoreColor?: ActionColor } => ({
  upColor: currentVote === 1 ? "red" : undefined,
  scoreColor: currentVote === 1 ? "red" : currentVote === -1 ? "blue" : undefined,
  downColor: currentVote === -1 ? "blue" : undefined,
});

export const isImagePost = (post: PostsAPI.Post | PostsAPI.ImagePost): post is PostsAPI.ImagePost =>
  post.images !== null && post.images.length > 0;

export const isListingPost = (post: PostsAPI.Post): post is PostsAPI.ListingPost => post.type === PostTypeKey.Listing;
export const isLinkPost = (post: PostsAPI.Post): post is PostsAPI.LinkPost => post.type === PostTypeKey.Link;
export const isTextPost = (post: PostsAPI.Post): post is PostsAPI.TextPost => post.type === PostTypeKey.Text;

export const orderByBest = (posts: PostsAPI.Post[]): PostsAPI.Post[] =>
  orderBy(posts, [(p) => p.up_count - p.down_count, "created_at"], ["desc", "desc"]);

const productText = (tag: PostsAPI.PostImageTag): string => {
  if (tag.product.style_id) {
    return `${tag.product.name} (${tag.product.style_id})`;
  }
  return `${tag.product.name}`;
};

/** replaces empty string value with null */
const nullIfEmpty = (value: string): string | null => {
  if (value.length === 0) {
    return null;
  }
  return value;
};

const undefinedIfEmpty = (value: string): string | undefined => {
  if (value.length === 0) {
    return undefined;
  }
  return value;
};

const postGroupMentionCheck = (post: PostsAPI.PostInsert | PostsAPI.PostPatch): boolean => {
  return hasGroupMentions(post.title) || hasGroupMentions(post?.content);
};

/**
 * Sanitizes a post insert object
 * @param insert PostsAPI.PostInsert
 * @returns PostsAPI.PostInsert
 */
export const sanitizePostInsert = (insert: PostsAPI.PostInsert): PostsAPI.PostInsert => ({
  community_id: insert.community_id,
  content: insert.content ? nullIfEmpty(trim(insert.content)) : null,
  images: insert.images,
  theme_key: insert.theme_key,
  theme_id: insert.theme_id,
  product_listing: insert.product_listing,
  title: trim(insert.title),
  to_route: insert.to_route ? nullIfEmpty(trim(insert.to_route)) : null,
  type: insert.type,
  url: insert.url ? nullIfEmpty(trim(insert.url)) : null,
});

/**
 * Sanitizes a post patch object
 * @param insert PostsAPI.PostPatch
 * @returns PostsAPI.PostPatch
 */
export const sanitizePostPatch = (patch: PostsAPI.PostPatch): PostsAPI.PostPatch => {
  const patchFields = omitBy<Omit<PostsAPI.PostPatch, "id">>(
    {
      community_id: patch.community_id,
      content: patch.content ? undefinedIfEmpty(trim(patch.content)) : undefined,
      images: patch.images,
      theme_key: patch.theme_key,
      theme_id: patch.theme_id,
      /** is_archived must be defined here to satisfy backend yup validation. https://github.com/jquense/yup/issues/1210 */
      is_archived: patch.is_archived,
      product_listing: patch.product_listing,
      title: trim(patch.title),
      to_route: patch.to_route ? undefinedIfEmpty(trim(patch.to_route)) : undefined,
      type: patch.type,
      url: patch.url ? undefinedIfEmpty(trim(patch.url)) : undefined,
    },
    isUndefined,
  );
  return {
    id: patch.id,
    /** duplicate same value here to satisfy TS */
    is_archived: patch.is_archived,
    ...patchFields,
  };
};

export const MAX_TITLE_LENGTH = 300;

/**
 * Validates a post insert object
 * @param insert PostsAPI.PostInsert
 * @returns { isValid: boolean; message: string }
 */
export const validatePostInsert = (
  insert: PostsAPI.PostInsert,
  isModOrAdmin = false,
): { isValid: boolean; message: string } => {
  if (!insert.title) {
    return { isValid: false, message: `Post must include title` };
  }

  if (insert.title.length > MAX_TITLE_LENGTH) {
    return { isValid: false, message: `Title must be fewer than 300 characters` };
  }

  if (insert.community_id && (!isInteger(insert.community_id) || insert.community_id <= 0)) {
    return { isValid: false, message: `Post community ID must be a positive integer` };
  }

  if (insert.type && !Object.values(PostTypeKey).includes(insert.type)) {
    return { isValid: false, message: `Post type "${insert.type}" is invalid` };
  }

  if (insert.url && !isURL(insert.url, { protocols: ["http", "https"] })) {
    return { isValid: false, message: `URL "${insert.url}" is invalid` };
  }

  if (postGroupMentionCheck(insert) && !isModOrAdmin) {
    return { isValid: false, message: "Not allowed to tag @everyone" };
  }

  if (insert.images) {
    if (!Array.isArray(insert.images)) {
      return { isValid: false, message: "Insert images must be an array" };
    }

    if (!insert.images.every((i) => isURL(i.url, { protocols: ["https"] }))) {
      return { isValid: false, message: "Invalid insert image URL(s)" };
    }
  }

  return { isValid: true, message: "" };
};

/**
 * Validates a post patch object
 * @param patch PostsAPI.PostPatch
 * @returns { isValid: boolean; message: string }
 */
export const validatePostPatch = (
  patch: PostsAPI.PostPatch,
  isModOrAdmin = false,
): { isValid: boolean; message: string } => {
  if (patch.title && patch.title.length > MAX_TITLE_LENGTH) {
    return { isValid: false, message: `Title must be fewer than 300 characters` };
  }

  if (patch.community_id && (!isInteger(patch.community_id) || patch.community_id <= 0)) {
    return { isValid: false, message: `Post community ID must be a positive integer` };
  }

  if (patch.type && !Object.values(PostTypeKey).includes(patch.type)) {
    return { isValid: false, message: `Post type "${patch.type}" is invalid` };
  }

  if (patch.url && !isURL(patch.url, { protocols: ["http", "https"] })) {
    return { isValid: false, message: `URL "${patch.url}" is invalid` };
  }

  if (postGroupMentionCheck(patch) && !isModOrAdmin) {
    return { isValid: false, message: "Not allowed to tag @everyone" };
  }

  if (patch.images) {
    if (!Array.isArray(patch.images)) {
      return { isValid: false, message: "Insert images must be an array" };
    }

    if (!patch.images.every((i) => isURL(i.url, { protocols: ["https"] }))) {
      return { isValid: false, message: "Invalid insert image URL(s)" };
    }
  }
  return { isValid: true, message: "" };
};

/**
 * Generates post alt attribute text for a post image
 * @param post
 * @param tags
 * @returns
 */
export const postImageAltText = (post: PostsAPI.Post, tags: PostsAPI.PostImageTag[] | null): string => {
  if (tags) {
    if (tags.length === 1) {
      return `${productText(tags[0])} by @${post.author.username}`;
    }

    if (tags.length > 1) {
      const numOthers = tags.length - 1;
      return `${productText(tags[0])} and ${numOthers} other sneaker${numOthers > 1 ? "s" : ""} by @${
        post.author.username
      }`;
    }
  }

  return `Photo by @${post.author.username} on ${dayjs(post.created_at).format("MMMM D, YYYY")}`;
};

/** Detects whether a user is trying to mention another user */
export const detectedMentionIntent = (prevText: string, text: string): boolean => {
  /** return if there is no text or the the user is deleting */
  if (!text || prevText.length > text.length) {
    return false;
  }

  /** if the text is only one character long and it is "@" return true */
  if (text.length === 1 && text === "@") {
    return true;
  }

  /** else slice the last two characters of the text and check for " @" */
  if (text.slice(-2) === " @") {
    return true;
  }

  return false;
};
