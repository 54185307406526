import "regenerator-runtime/runtime";

import "lazysizes";
import "./scripts/consoleOverrides";
import "./scripts/localStorageMigrations";

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

import { init as initSentry, setTag } from "@sentry/browser/esm";
import { enableMapSet } from "immer";
import React, { StrictMode } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import { Provider as ReduxQueryProvider } from "redux-query-react";
import { ThemeProvider } from "styled-components";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { ExperimentsProvider } from "../src/components/Experiment";
import AppInitializer from "./AppInitializer";
import App from "./components/App";
import AuthProvider from "./components/Auth/AuthProvider";
import AuthScreensProvider from "./components/AuthScreenModal/AuthScreensProvider";
import CreateCommunityMenuProvider from "./components/CreateCommunity/CreateCommunityMenuProvider";
import GlobalModalProvider from "./components/GlobalModal/GlobalModalProvider";
import PostMenuProvider from "./components/PostMenu/PostMenuProvider";
import ServiceWorkerHandler from "./components/ServiceWorker/ServiceWorkerHandler";
import ToastProvider from "./components/Toast/ToastProvider";
import config, { isDev } from "./config";
import { captureException } from "./helpers/errorUtil";
import { firebaseInstances } from "./helpers/firebaseUtil";
import history from "./history";
import HistoryIndexTracker from "./HistoryIndexTracker";
import queriesSelector from "./selectors/queriesSelector";
import store from "./store";
import GlobalStyle from "./styles/GlobalStyle";
import { theme } from "./UI";

enableMapSet();

if (!isDev) {
  window.addEventListener("load", () => {
    initSentry({
      release: __VERSION__,
      dsn: config.SENTRY_DSN,
      environment: config.SENTRY_ENVIRONMENT,
    });
    setTag("host", window.location.host);
  });
}

// Initialize branch here so we can capture initialization errors in sentry
// Loading of `window.branch` is still handled in index.html
if (window.branch && config.BRANCH_KEY) {
  window.branch.init(config.BRANCH_KEY, undefined, (error) => {
    if (error) {
      captureException(new Error(error));
    }
  });
}

render(
  <StrictMode>
    <Provider store={store}>
      <ReduxQueryProvider queriesSelector={queriesSelector.queries()}>
        <FirebaseAppProvider firebaseApp={firebaseInstances.app}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <ExperimentsProvider>
                <ToastProvider>
                  <ServiceWorkerHandler />
                  <Router history={history}>
                    <QueryParamProvider adapter={ReactRouter5Adapter}>
                      <AuthScreensProvider>
                        <CreateCommunityMenuProvider>
                          <PostMenuProvider>
                            <GlobalModalProvider>
                              <AppInitializer />
                              <HistoryIndexTracker />
                              <App />
                            </GlobalModalProvider>
                          </PostMenuProvider>
                        </CreateCommunityMenuProvider>
                      </AuthScreensProvider>
                    </QueryParamProvider>
                  </Router>
                </ToastProvider>
              </ExperimentsProvider>
            </ThemeProvider>
          </AuthProvider>
        </FirebaseAppProvider>
      </ReduxQueryProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root"),
);
