import React, { ReactElement } from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../Box";

export interface ListProps extends BoxProps {
  isFinished: boolean;
  numPlaceholders?: number;
  placeholderEl: ReactElement;
}

const List: React.FC<React.PropsWithChildren<ListProps>> = ({
  children,
  isFinished,
  numPlaceholders,
  placeholderEl,
  ...wrapperProps
}) => {
  if (!isFinished) {
    return (
      <ListWrapper {...wrapperProps}>
        {Array.from({ length: numPlaceholders ?? 10 }).map((_, idx) => React.cloneElement(placeholderEl, { key: idx }))}
      </ListWrapper>
    );
  }

  return <ListWrapper {...wrapperProps}>{children}</ListWrapper>;
};

const ListWrapper = styled(Box)``;

export default List;
