import { forEach, reduce } from "lodash-es";
import { isMobileFrame, isStaging, isWeb } from "../../config";

type ExperimentEnv = "staging" | "website" | "mobileApp";
type ExperimentEnvIds = Partial<Record<ExperimentEnv, string>>;

type ExperimentNames = "";
type ExperimentNameIds = Partial<Record<ExperimentNames, string>>;

const experimentIdsByName: Record<ExperimentNames, ExperimentEnvIds> = {
  "": {},
  /* leave commented code for future reference */
  // desktopCheckoutButtonLocation: {
  //   // https://optimize.google.com/optimize/home/#/accounts/4703215420/containers/13435889/experiments/25
  //   staging: "DOk5AIz5Sz-xMBWpd5V08g",
  //   // https://optimize.google.com/optimize/home/#/accounts/4703215420/containers/13431402/experiments/17
  //   website: "z5sEohwRQK6uL6L0dyQzEQ",
  // },
};

const experimentIdsByEnv: Record<ExperimentEnv, ExperimentNameIds> = reduce(
  experimentIdsByName,
  (result, envValues, expName) => {
    forEach(envValues, (experimentId, envName) => {
      result[envName][expName] = experimentId;
    });
    return result;
  },
  {
    staging: {},
    website: {},
    mobileApp: {},
  },
);

const experimentIds = ((): ExperimentNameIds => {
  if (isStaging) {
    return experimentIdsByEnv.staging;
  }

  if (isMobileFrame) {
    return experimentIdsByEnv.mobileApp;
  }

  if (isWeb) {
    return experimentIdsByEnv.website;
  }

  return {};
})();

export default experimentIds;
