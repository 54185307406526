import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import Box from "./Box";

export type Variant = "primary" | "secondary" | "tertiary";

export type TextButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  BorderProps<DefaultTheme> &
  ShadowProps<DefaultTheme> &
  ColorProps<DefaultTheme> &
  FlexboxProps<DefaultTheme> &
  LayoutProps<DefaultTheme> &
  PositionProps<DefaultTheme> &
  SpaceProps<DefaultTheme> &
  TypographyProps<DefaultTheme> & {
    variant?: Variant;
  };

const primaryStyles = css`
  & div {
    font-weight: ${({ theme }) => theme.fontWeights[7]};
    background-image: ${({ theme }) => theme.colors.goldRed};
    background-size: 100%;
    background-clip: text;
    -o-background-clip: text;
    -ms-background-clip: text;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -o-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
  }
`;

// todo
const secondaryStyles = css`
  background-color: transparent;
`;

/* font-weight: ${({ theme }) => theme.fontWeights[4]}; */
const tertiaryStyles = css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

const TextButton: React.FC<TextButtonProps> = ({ children, ...props }) => (
  <TextButtonWrapper {...props}>
    <Box>{children}</Box>
  </TextButtonWrapper>
);

const TextButtonWrapper = styled.button<TextButtonProps>`
  cursor: pointer;
  text-transform: uppercase;
  background: transparent;

  &:hover {
    text-decoration: underline;
  }

  ${({ variant }) => {
    switch (variant) {
      case "primary":
        return primaryStyles;
      case "secondary":
        return secondaryStyles;
      case "tertiary":
        return tertiaryStyles;
      default:
        return primaryStyles;
    }
  }}

  ${border}
  ${shadow}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${typography}
`;

TextButtonWrapper.defaultProps = {
  fontSize: 0,
  size: "medium",
  flexShrink: 0,
};

export default TextButton;
