import React from "react";
import { Accordion } from "../../libs/semantic-ui";
import { CommunitiesAPI } from "../../typings/API";
import { Box, Icon, Text } from "../../UI";
import { CommunityListSelectItem, CommunityListSelectItemPlaceholder } from "../../UI/components/CommunityList";

export interface ExpandableCommunityListProps {
  communities: CommunitiesAPI.Community[];
  isActive: boolean;
  title: string;
  toggleActive: () => void;
  onSelect?: () => void;
  loading?: boolean;
  numPlaceholders?: number;
}

const ExpandableCommunityList: React.FC<ExpandableCommunityListProps> = ({
  communities,
  isActive,
  toggleActive,
  onSelect,
  title,
  loading,
  numPlaceholders,
}) => {
  return (
    <>
      <Accordion.Title active={isActive} onClick={toggleActive}>
        <Box display="flex" justifyContent={"space-between"}>
          <Text fontSize={1} fontWeight={5}>
            {title}
          </Text>
          <Icon name="dropdown" color="darkGrey" />
        </Box>
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        {loading
          ? Array.from({ length: numPlaceholders ?? 10 }).map((_, idx) => (
              <CommunityListSelectItemPlaceholder key={idx} />
            ))
          : communities.map((community) => (
              <CommunityListSelectItem onClick={onSelect} size="small" key={community.id} community={community} />
            ))}
      </Accordion.Content>
    </>
  );
};

export default ExpandableCommunityList;
