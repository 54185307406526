import React, { useEffect, useState } from "react";
import DebugLogItem from "./DebugLogItem";

export interface DebugLogWebViewProps {
  isOpen: boolean;
}

const DebugLogWebView: React.FC<DebugLogWebViewProps> = ({ isOpen }) => {
  const [logs, setLogs] = useState<ReturnType<typeof console.stack>>(() => console.stack());
  useEffect(() => setLogs(console.stack()), [isOpen]);

  return (
    <>
      {logs.map(([logType, message], idx) => (
        <DebugLogItem key={idx} logType={logType} message={message} />
      ))}
    </>
  );
};

export default DebugLogWebView;
