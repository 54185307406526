import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { isMobile } from "../../libs/ua-parser-js";

export interface PostImageZoomWrapperProps {
  children: ReactNode;
}

const PostImageZoomWrapper: React.FC<PostImageZoomWrapperProps> = ({ children }) => {
  const [scale, setScale] = useState<number>(1);
  const [positionX, setPositionX] = useState<number>(0);
  const [positionY, setPositionY] = useState<number>(0);

  const updateZoom = useCallback((stats) => {
    setScale(stats.scale);
    setPositionX(stats.positionX);
    setPositionY(stats.positionY);
  }, []);

  const resetZoom = useCallback(() => {
    setScale(1);
    setPositionX(0);
    setPositionY(0);
  }, []);

  const doubleClickProps = useMemo(() => ({ disabled: true }), []);
  const wheelProps = useMemo(() => ({ disabled: true }), []);
  const panProps = useMemo(() => ({ disabled: true }), []);

  if (!isMobile) {
    return <>{children}</>;
  }

  return (
    <TransformWrapper
      scale={scale}
      positionX={positionX}
      positionY={positionY}
      onPinchingStop={resetZoom}
      onZoomChange={updateZoom}
      onPanning={updateZoom}
      onPanningStop={updateZoom}
      doubleClick={doubleClickProps}
      pan={panProps}
      wheel={wheelProps}
    >
      <TransformComponent>{children}</TransformComponent>
    </TransformWrapper>
  );
};

export default PostImageZoomWrapper;
