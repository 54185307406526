import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth, useSigninCheck } from "reactfire";
import { useMutation, useRequest } from "redux-query-react";
import styled from "styled-components";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import { followUsers as followUsersQuery } from "../../queries/api/userFollowQuery";
import { getFolloweeRecommendations } from "../../queries/api/userProfileQuery";
import { RootState } from "../../store";
import { Box } from "../../UI";
import UserFollowList from "./CheckboxList/UserFollowList/UserFollowList";
import StickyFollowButton from "./StickyFollowButton";

export interface FollowUsersOnboardProps {
  onNext: () => void;
}

const FollowUsersOnboard: React.FC<FollowUsersOnboardProps> = ({ onNext }) => {
  const { currentUser } = useAuth();
  const { status } = useSigninCheck();
  const isLoaded = status === "success";

  const [, followUsers] = useMutation(followUsersQuery);
  const [selectedUserIds, setSelectedUserIds] = useState<Set<string>>(new Set());
  const [{ isFinished }] = useRequest(isLoaded ? getFolloweeRecommendations() : null);
  const popularUserIds = useSelector((state: RootState) => state.entities.userFollowSuggestionIds);
  const userProfiles = useSelector((state: RootState) => state.entities.userProfilesById);
  const popularUsers = useMemo(() => popularUserIds.map((uid) => userProfiles[uid]), [popularUserIds, userProfiles]);

  const handleNext = useCallback(() => {
    /** close the auth screen */
    if (currentUser?.uid && selectedUserIds.size > 0) {
      followUsers(currentUser.uid, Array.from(selectedUserIds));
      trackEvent(events.Product.AddedToFavorites);
    }

    onNext();
  }, [currentUser?.uid, followUsers, onNext, selectedUserIds]);

  return (
    <ListWrapper>
      <UserFollowList
        title="Popular Users"
        loading={!isFinished}
        userProfiles={popularUsers}
        selectedUserIds={selectedUserIds}
        setSelectedUserIds={setSelectedUserIds}
      />
      <Box mb={4} flexGrow={1} />
      <StickyFollowButton
        isFinished={isFinished}
        onClick={handleNext}
        variant={selectedUserIds.size > 0 ? "primary" : "secondary"}
      >
        {selectedUserIds.size > 0 ? `Follow` : `Skip`}
      </StickyFollowButton>
    </ListWrapper>
  );
};

const ListWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export default FollowUsersOnboard;
