import { every, isNil } from "lodash-es";
import { UsersAPI } from "../typings/API";

export const isInvitedUser = (user: UsersAPI.InvitedUser | UsersAPI.PublicProfile): user is UsersAPI.InvitedUser =>
  !isNil(user.invited_at);

/**
 * Used to verify valid username
 *
 * Notes:
 *   - Can't start or end with a "."
 */
export const usernameRegEx = /^[a-z0-9_][a-z0-9_.]+[a-z0-9_]$/;
export const isValidUsername = (username: string): boolean => {
  return every([usernameRegEx.test(username), username.length >= 3, username.length <= 30]);
};
