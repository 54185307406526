import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "redux-query-react";
import { captureException } from "../../../helpers/errorUtil";
import { actionPromiseToPromise } from "../../../helpers/reactQueryUtil";
import useConfirmationCallback from "../../../hooks/useConfirmationCallback";
import { hidePost as hidePostQuery, unhidePost as unhidePostQuery } from "../../../queries/api-v2/postQuery";
import { PostsAPI } from "../../../typings/API-V2";
import ActionsMenuItem, { ActionsMenuItemProps } from "../ActionsMenuItem";

export interface PostActionsHideMenuItemProps extends ActionsMenuItemProps {
  post: PostsAPI.Post;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PostActionsHideMenuItem: React.FC<PostActionsHideMenuItemProps> = ({ post, onClick, ...menuItemProps }) => {
  const [, hidePost] = useMutation(hidePostQuery);
  const [, unhidePost] = useMutation(unhidePostQuery);
  const { addToast } = useToasts();

  const handleHidePost = useCallback(
    async (e) => {
      try {
        await actionPromiseToPromise(post.is_hidden ? unhidePost(post.id) : hidePost(post.id));
        addToast(`Post hidden`, {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (error) {
        addToast(`An error occurred`, {
          appearance: "warning",
          autoDismiss: true,
        });
        captureException(error);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [hidePost, unhidePost, post.id, post.is_hidden, addToast, onClick],
  );

  const hidePostWithConfirmation = useConfirmationCallback("Hide this post?", handleHidePost, []);

  return <ActionsMenuItem {...menuItemProps} onClick={hidePostWithConfirmation} />;
};

export default PostActionsHideMenuItem;
