import styled from "styled-components";
import { Box } from "../../UI";

export const ACTION_BUTTON_HEIGHT = 34;

const PostActionButton = styled(Box)`
  cursor: pointer;

  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.darkGrey};

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;
PostActionButton.defaultProps = {
  display: "flex",
  alignItems: "center",
  ml: 2,
  height: ACTION_BUTTON_HEIGHT,
  borderRadius: ACTION_BUTTON_HEIGHT / 2,
  minWidth: ACTION_BUTTON_HEIGHT,
  role: "button",
};

export default PostActionButton;
