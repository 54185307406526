import { useMemo } from "react";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { useRequest } from "redux-query-react";
import { exchangeRatesQuery } from "../queries/api/metaQuery";
import { RootState } from "../store";
import { MetaState } from "../typings/EntitiesState";

const useExchangeRates = (): {
  queryState: QueryState;
  exchangeRates: MetaState["exchangeRates"];
} => {
  const [queryState] = useRequest(exchangeRatesQuery());
  const exchangeRates = useSelector((state: RootState) => state.entities.exchangeRates);
  return useMemo(
    () => ({
      queryState,
      exchangeRates,
    }),
    [queryState, exchangeRates],
  );
};

export default useExchangeRates;
