import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "redux-query-react";
import { captureException } from "../../../helpers/errorUtil";
import { actionPromiseToPromise } from "../../../helpers/reactQueryUtil";
import useConfirmationCallback from "../../../hooks/useConfirmationCallback";
import { pinPost as pinPostQuery, unpinPost as unpinPostQuery } from "../../../queries/api-v2/postQuery";
import { PostsAPI } from "../../../typings/API-V2";
import ActionsMenuItem, { ActionsMenuItemProps } from "../ActionsMenuItem";

export interface PostActionsPinMenuItemProps extends ActionsMenuItemProps {
  post: PostsAPI.Post;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PostActionsPinMenuItem: React.FC<PostActionsPinMenuItemProps> = ({ post, onClick, ...menuItemProps }) => {
  const [, pinPost] = useMutation(pinPostQuery);
  const [, unpinPost] = useMutation(unpinPostQuery);
  const { addToast } = useToasts();

  const handlePinPost = useCallback(
    async (e) => {
      try {
        await actionPromiseToPromise(post.is_pinned ? unpinPost(post.id) : pinPost(post.id));
        addToast(post.is_pinned ? `Post unpinned` : `Post pinned`, {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (error) {
        addToast(`An error occurred`, {
          appearance: "warning",
          autoDismiss: true,
        });
        captureException(error);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [pinPost, unpinPost, post.id, post.is_pinned, addToast, onClick],
  );

  const pinPostWithConfirmation = useConfirmationCallback(
    `${post.is_pinned ? "Unpin" : "Pin"} this post?`,
    handlePinPost,
    [],
  );

  return <ActionsMenuItem {...menuItemProps} onClick={pinPostWithConfirmation} />;
};

export default PostActionsPinMenuItem;
