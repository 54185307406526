import React from "react";
import useBlockActions from "../../../hooks/useBlockActions";
import ActionsMenuItem, { ActionsMenuItemProps } from "../ActionsMenuItem";

export interface ActionsBlockUserMenuItemProps extends ActionsMenuItemProps {
  isBlocked: boolean;
  blockeeId: string;
  blockeeUsername?: string;
  close: () => void;
}

const ActionsBlockUserMenuItem: React.FC<ActionsBlockUserMenuItemProps> = ({
  isBlocked,
  blockeeId,
  blockeeUsername,
  close,
  ...menuItemProps
}) => {
  const blockActions = useBlockActions(isBlocked, { id: blockeeId, username: blockeeUsername || blockeeId }, close);

  return <ActionsMenuItem {...menuItemProps} onClick={blockActions.blockWithConfirmation} />;
};

export default ActionsBlockUserMenuItem;
