import React from "react";
import { isMobileFrame } from "../../../config";
import ScreenSize from "../ScreenSize";
import TopNavWrapper, { TopNavWrapperProps } from "./TopNavWrapper";

export type MobileTopNavWrapperProps = TopNavWrapperProps;

const MobileTopNavWrapper: React.FC<MobileTopNavWrapperProps> = ({ ...topNavWrapperProps }) => {
  return (
    <ScreenSize sizes={isMobileFrame ? ["small", "medium", "large"] : ["small", "medium"]}>
      <TopNavWrapper {...topNavWrapperProps} />
    </ScreenSize>
  );
};

export default MobileTopNavWrapper;
