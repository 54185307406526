import { isNil } from "lodash-es";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import { getNotificationsApproval } from "../../messages/outboundMessages";
import sendReactNativeMessage from "../../messages/sendReactNativeMessage";
import { PermissionStatus } from "../../reducers/nativeAppReducer";
import { RootState } from "../../store";
import { Box, Text } from "../../UI";
import AuthHome from "../Auth/AuthHome";
import EmailLoginContent from "../Auth/EmailLoginContent";
import PhoneLoginForm from "../Auth/PhoneLoginForm";
import UsernameForm from "../Auth/UsernameMobileForm";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderProps,
  FullscreenMenuHeaderTitle,
  FullscreenMenuProps,
} from "../Layout/FullscreenMenu";
import FollowProductsOnboard from "../Onboarding/FollowProductsOnboard";
import FollowUsersOnboard from "../Onboarding/FollowUsersOnboard";
import AuthScreensContext, { AuthScreen } from "./AuthScreensContext";
import EmailSignUpScreen from "./Screens/EmailSignUpScreen";
import ResetPasswordScreen from "./Screens/ResetPasswordScreen";

export interface AuthScreensPortalProps {}

const AuthScreensPortal: React.FC<AuthScreensPortalProps> = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { screen, setAuthScreen, setInfo } = useContext(AuthScreensContext);
  const notificationsPermissionsStatus = useSelector(
    (state: RootState) => state.nativeApp.notificationsPermissionStatus,
  );

  const requestNotificationsPermissions = useCallback(() => {
    if (notificationsPermissionsStatus === null || notificationsPermissionsStatus === PermissionStatus.UNDETERMINED) {
      sendReactNativeMessage(getNotificationsApproval());
    }
  }, [notificationsPermissionsStatus]);

  useEffect(() => {
    if (screen) {
      trackEvent(events.AuthScreen.Viewed, { screen });
    }
  }, [screen]);

  /** close the portal, reset values, and request for notification permission is not already asked */
  const closePortal = useCallback(() => {
    setAuthScreen(null);
    setInfo(null);

    /** request notification permissions when the onboarding screen is closed, permissions aren't currently granted, and */
    requestNotificationsPermissions();
  }, [setAuthScreen, setInfo, requestNotificationsPermissions]);

  const {
    menuHeaderProps,
    menuProps,
    prevScreen,
    skipTo,
    title: menuTitle,
  } = useMemo(() => generatePortalProps(screen), [screen]);

  const goBack = useMemo(() => {
    let goBackFunc: undefined | (() => void);

    if (prevScreen) {
      goBackFunc = () => setAuthScreen(prevScreen);
    }

    return goBackFunc;
  }, [prevScreen, setAuthScreen]);

  /**
   * Actions taken after onboarding is completed
   */
  const onOnboardingCompletion = useCallback(() => {
    /** navigate to the user's profile page */
    history.push(`/me`);

    /** close the portal and clear auth screens state */
    closePortal();

    /** welcome the user */
    addToast(`Welcome!`, {
      appearance: "success",
      autoDismiss: true,
    });
  }, [history, closePortal, addToast]);

  /** handles creating the "skip" button action */
  const handleSkip = useMemo(() => {
    /** if null, dismiss the onboarding screen */
    if (skipTo === null) {
      trackEvent(events.AuthScreen.Dismissed, {
        screen: screen,
      });

      return closePortal;
    }

    /** if a target screen is specified, skipping should advance to the next screen */
    if (skipTo) {
      trackEvent(events.AuthScreen.Skipped, {
        screen,
      });

      return () => setAuthScreen(skipTo);
    }

    /** return nothing if no cases met, and no "skip" button will be displayed */
  }, [closePortal, screen, setAuthScreen, skipTo]);

  return (
    <FullscreenMenu
      close={closePortal}
      header={
        <FullscreenMenuHeader {...menuHeaderProps}>
          {goBack ? (
            <FullscreenMenuHeaderIcon onClick={goBack} name="chevron left" containerProps={{ flexBasis: 0 }} />
          ) : (
            <FullscreenMenuHeaderIcon containerProps={{ flexBasis: 0 }} />
          )}
          <FullscreenMenuHeaderTitle>{menuTitle}</FullscreenMenuHeaderTitle>
          {handleSkip ? (
            <Box display="flex" justifyContent="flex-end" flexGrow={1} flexBasis={0}>
              <Box role="button">
                <Text color="mediumGrey" fontSize={0} onClick={handleSkip}>
                  SKIP
                </Text>
              </Box>
            </Box>
          ) : (
            <FullscreenMenuHeaderIcon containerProps={{ flexBasis: 0 }} />
          )}
        </FullscreenMenuHeader>
      }
      isOpen={!isNil(screen)}
      p={3}
      {...menuProps}
    >
      {screen === "login" ? (
        <AuthHome />
      ) : screen === "emailLogin" ? (
        <EmailLoginContent onClose={closePortal} />
      ) : screen === "signup" || screen === "emailSignupEmail" || screen === "emailSignupPassword" ? (
        <EmailSignUpScreen />
      ) : screen === "resetPassword" ? (
        <ResetPasswordScreen />
      ) : screen === "createUsername" ? (
        <UsernameForm />
      ) : screen === "phoneInput" || screen === "phoneConfirm" ? (
        <PhoneLoginForm onClose={closePortal} />
      ) : screen === "onboardFollowProducts" ? (
        <FollowProductsOnboard onClose={closePortal} />
      ) : screen === "onboardFollowUsers" ? (
        <FollowUsersOnboard onNext={onOnboardingCompletion} />
      ) : null}
    </FullscreenMenu>
  );
};

const generatePortalProps = (
  screen: AuthScreen | null,
): {
  menuHeaderProps?: FullscreenMenuHeaderProps;
  menuProps?: Partial<FullscreenMenuProps>;
  prevScreen?: AuthScreen;
  skipTo?: AuthScreen | null;
  title?: string;
} => {
  switch (screen) {
    case "login": {
      return {
        menuHeaderProps: {
          borderBottom: "none",
        },
        menuProps: {
          overflowY: "inherit",
        },
        skipTo: null,
      };
    }
    case "emailLogin": {
      return {
        menuProps: {
          overflowY: "inherit",
        },
        prevScreen: "login",
        title: "Email Login",
      };
    }
    case "phoneInput": {
      return {
        menuProps: {
          overflowY: "inherit",
        },
        prevScreen: "login",
        title: "Phone Number",
      };
    }
    case "phoneConfirm": {
      return {
        menuProps: {
          overflowY: "inherit",
        },
        prevScreen: "phoneInput",
        title: "Confirmation Code",
      };
    }
    case "emailSignupEmail": {
      return {
        menuProps: {
          overflowY: "inherit",
        },
        prevScreen: "login",
        title: "Email Signup",
      };
    }
    case "emailSignupPassword": {
      return {
        menuProps: {
          overflowY: "inherit",
        },
        prevScreen: "emailSignupEmail",
        title: "Password",
      };
    }
    case "createUsername": {
      return {
        menuProps: {
          overflowY: "inherit",
        },
        skipTo: "onboardFollowProducts",
        title: "Username",
      };
    }
    case "onboardFollowProducts": {
      return {
        menuProps: {
          bg: "lightGrey",
        },
        // prevScreen: "createUsername",
        skipTo: "onboardFollowUsers",
        title: "Follow Products",
      };
    }
    case "onboardFollowUsers": {
      return {
        menuProps: {
          bg: "lightGrey",
        },
        prevScreen: "onboardFollowProducts",
        skipTo: null,
        title: "Follow Users",
      };
    }
    case "resetPassword": {
      return {
        prevScreen: "emailLogin",
        title: "Reset Password",
      };
    }
    default: {
      return {
        title: "",
      };
    }
  }
};

export default AuthScreensPortal;
