import styled, { css } from "styled-components";
import { Box, BoxProps, Text } from "../../UI";
import React from "react";

export interface SizeButtonProps {
  isSelected: boolean;
  onClick: (s: string | number) => void;
  size: string | number;
  displaySize?: string | number;
}

const SizeButton: React.FC<SizeButtonProps> = ({ isSelected, onClick, size, displaySize }) => {
  return (
    <SizeItemContainer key={size}>
      <SizeItem isActive={isSelected} onClick={() => onClick(size)}>
        <Text fontWeight={isSelected ? 5 : undefined}>{displaySize ? displaySize : size}</Text>
      </SizeItem>
    </SizeItemContainer>
  );
};

const SizeItemContainer = styled(Box)`
  position: relative;
  padding-bottom: 100%;
`;

const SizeItem = styled(Box)<BoxProps & { isActive: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: ${theme.colors.lightGrey};
          background-color: ${theme.colors.black};
          border-color: ${theme.colors.black};
        `
      : css`
          color: ${theme.colors.black};
          background-color: ${theme.colors.lightGrey};
          border-color: ${theme.colors.lightGrey};
        `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.mediumGrey};
  }
`;
SizeItem.defaultProps = {
  borderRadius: 1,
  role: "button",
};

export default SizeButton;
