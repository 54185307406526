import React, { useEffect } from "react";
import { UsersAPI } from "../../../../typings/API";
import { Box } from "../../../../UI";
import List from "../../../../UI/components/List";
import { UserListSelectItem, UserListSelectItemPlaceholder } from "../../../../UI/components/UserList";
import Checkbox from "../Checkbox";
import CheckboxListHeader from "../CheckboxListHeader";
import useToggleActions from "../hooks/useToggleActions";

export interface UserFollowListProps {
  userProfiles: UsersAPI.PublicProfile[];
  loading: boolean;
  selectedUserIds: Set<string>;
  setSelectedUserIds: React.Dispatch<React.SetStateAction<Set<string>>>;
  title: string;
}

const UserFollowList: React.FC<UserFollowListProps> = ({
  userProfiles,
  loading,
  selectedUserIds,
  setSelectedUserIds,
  title,
}) => {
  /**
   * Select all user ids by default
   */
  useEffect(() => {
    if (!loading) {
      return setSelectedUserIds(new Set(userProfiles.map((p) => p.id)));
    }
  }, [setSelectedUserIds, loading, userProfiles]);

  const { toggleSelectedItem: toggleSelectedUser, toggleSelectedItemBulk: toggleSelectedUserBulk } = useToggleActions(
    userProfiles,
    selectedUserIds,
    setSelectedUserIds,
  );

  return (
    <Box>
      <CheckboxListHeader
        title={title}
        bulkProps={{
          items: userProfiles,
          selectedIds: selectedUserIds,
          toggle: toggleSelectedUserBulk,
        }}
      />
      <List width="100%" isFinished={!loading} placeholderEl={<UserListSelectItemPlaceholder />}>
        {userProfiles.map((profile) => (
          <UserListSelectItem
            metaProps={{ disableLink: true }}
            key={profile.id}
            profile={profile}
            onClick={() => toggleSelectedUser(profile.id)}
            action={<Checkbox isSelected={selectedUserIds.has(profile.id)} />}
          />
        ))}
      </List>
    </Box>
  );
};

export default UserFollowList;
