import { isEqual } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";
import { Box, Button, Text, Icon, theme, Input } from "../../UI";
import GroupCard from "../GroupCard";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderButton,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
} from "../Layout/FullscreenMenu";
import { Pill } from "../PillGroup";
import FullscreenMenuHeaderSide from "../Layout/FullscreenMenu/FullscreenMenuHeaderSide";

export interface RestocksKeywordMenuProps {
  existingKeywords: string[];
  setKeywords: (sizes: string[]) => void;
  close: () => void;
  isOpen: boolean;
}

const DEFAULT_INPUT_HEIGHT = 32;

const RestocksKeywordMenu: React.FC<RestocksKeywordMenuProps> = ({ close, isOpen, existingKeywords, setKeywords }) => {
  const [tempKeywords, setTempKeywords] = useImmer(new Set(existingKeywords));
  const [newKeyword, setNewKeyword] = useState("");

  const handleSubmitSettings = useCallback(() => {
    setKeywords(Array.from(tempKeywords));
    close();
  }, [tempKeywords, close, setTempKeywords]);

  const clearInput = useCallback(() => {
    setNewKeyword("");
  }, [setNewKeyword]);

  const addKeyword = useCallback(
    (keyword: string) => {
      setTempKeywords((draft) => {
        if (!draft.has(keyword)) {
          draft.add(keyword);
          clearInput();
        }
      });
    },
    [setTempKeywords, tempKeywords, clearInput],
  );

  const removeKeyword = useCallback(
    (keyword: string) => {
      setTempKeywords((draft) => {
        draft.delete(keyword);
      });
    },
    [setTempKeywords, tempKeywords],
  );

  useEffect(() => {
    setTempKeywords(new Set(existingKeywords));
  }, [existingKeywords, setTempKeywords]);

  const hasChanged = useMemo<boolean>(() => {
    if (!isEqual(existingKeywords, Array.from(tempKeywords))) {
      return true;
    } else {
      return false;
    }
  }, [existingKeywords, tempKeywords]);

  const updateNewKeywordInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setNewKeyword(e.target.value),
    [setNewKeyword],
  );

  return (
    <FullscreenMenu
      header={
        <FullscreenMenuHeader>
          <FullscreenMenuHeaderSide side="left">
            <FullscreenMenuHeaderIcon name="chevron left" onClick={close} />
          </FullscreenMenuHeaderSide>
          <FullscreenMenuHeaderTitle>Keyword Whitelist</FullscreenMenuHeaderTitle>
          <FullscreenMenuHeaderSide side="right">
            <FullscreenMenuHeaderButton
              onClick={handleSubmitSettings}
              disabled={!hasChanged}
              variant={hasChanged ? "secondary" : "disabled"}
            >
              Done
            </FullscreenMenuHeaderButton>
          </FullscreenMenuHeaderSide>
        </FullscreenMenuHeader>
      }
      isOpen={isOpen}
      close={close}
    >
      <GroupCard title={"Keywords"} subtitle={tempKeywords.size || null} flush={true}>
        <Box p={2} display="flex" alignItems={"center"}>
          <InputWrapper height={DEFAULT_INPUT_HEIGHT} borderRadius={DEFAULT_INPUT_HEIGHT / 2}>
            <Input value={newKeyword} bg="lightGrey" type="search" width="100%" onChange={updateNewKeywordInput} />
          </InputWrapper>
          <Button
            disabled={!newKeyword.length || tempKeywords.has(newKeyword)}
            onClick={() => addKeyword(newKeyword)}
            size="small"
            variant={newKeyword.length && !tempKeywords.has(newKeyword) ? "secondary" : "disabled"}
          >
            Add
          </Button>
        </Box>
        <Box p={2}>
          {Array.from(tempKeywords).map((keyword) => (
            <KeywordTag key={keyword} my={1} isActive={true}>
              <Box display={"flex"} justifyContent="space-between">
                <Box>
                  <Text>{keyword}</Text>
                </Box>
                <ActionIconWrapper onClick={() => removeKeyword(keyword)}>
                  <Icon name="delete" />
                </ActionIconWrapper>
              </Box>
            </KeywordTag>
          ))}
        </Box>
      </GroupCard>
    </FullscreenMenu>
  );
};

const KeywordTag = styled(Pill)`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.mediumGrey};
  color: ${theme.colors.darkGrey};
  align-items: left;
  justify-content: space-between;
  span {
    width: 100%;
  }
  cursor: default;
`;

const InputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

InputWrapper.defaultProps = {
  bg: "lightGrey",
  px: 3,
  mx: 2,
};

const ActionIconWrapper = styled(Box)`
  cursor: pointer;
  &:hover {
    ${({ theme }) => `
            color: ${theme.colors.black};
          `}
  }
`;

export default RestocksKeywordMenu;
