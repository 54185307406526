import { firebaseInstances } from "./firebaseUtil";

interface RequestHeader {
  [key: string]: string;
}

export const STANDARD_HEADERS = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Headers": "Content-Type",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Max-Age": "3600",
};

export const FORM_HEADERS = {
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Max-Age": "3600",
};

export const getIdToken = async (): Promise<string | undefined> => {
  return firebaseInstances.auth.currentUser?.getIdToken();
};

export const standardHeaders = (overrides?: RequestHeader): RequestHeader => {
  return {
    ...STANDARD_HEADERS,
    ...overrides,
  };
};

export const standardHeadersWithToken = async (overrides?: RequestHeader): Promise<RequestHeader> => {
  const token = await getIdToken();
  return {
    ...standardHeaders(overrides),
    Authorization: `Bearer ${token}`,
  };
};
