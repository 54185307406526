import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LocationState from "../typings/LocationState";

/**
 * to be used with useHistoryPush
 */
const useScrollPersist = () => {
  const location = useLocation<undefined | LocationState.ScrollYState>();
  useEffect(() => {
    const scrollY = location.state?.scrollY ?? 0;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
  }, [location.state]);
};

export default useScrollPersist;
