import { Property } from "csstype";
import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { ResponsiveValue } from "styled-system";
import { Loader } from "../../../libs/semantic-ui";
import { Box, BoxProps, Button, ButtonProps } from "../../../UI";

export interface FullscreenMenuHeaderButtonProps extends ButtonProps {
  containerProps?: BoxProps;
  loading?: boolean;
  justifyContent?: ResponsiveValue<Property.JustifyContent, DefaultTheme>;
}

const FullscreenMenuHeaderButton: React.FC<FullscreenMenuHeaderButtonProps> = ({
  loading = false,
  containerProps,
  justifyContent = "flex-end",
  children,
  ...buttonProps
}) => {
  return (
    <Container {...containerProps} justifyContent={justifyContent}>
      <Button size="small" {...buttonProps}>
        {loading ? <Loader active={true} inverted={true} size="mini" inline="centered" /> : children}
      </Button>
    </Container>
  );
};
const Container = styled(Box)`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export default FullscreenMenuHeaderButton;
