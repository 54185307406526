import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import mediaQuery, { mediaQueries } from "../../styles/mediaQuery";

export interface CustomPanelProps {
  noPadding?: boolean;
  grow?: boolean;
  backgroundColor?: string;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
}

export type PanelProps = CustomPanelProps & React.HTMLAttributes<HTMLDivElement>;

const Panel: React.FC<PanelProps> = ({ backgroundColor, children, grow, noPadding, as, ...divProps }) => {
  return (
    <PanelWrapper grow={grow || false} backgroundColor={backgroundColor} as={as}>
      <MaxWidthWrapper noPadding={noPadding || false} {...divProps}>
        <React.Fragment>{children}</React.Fragment>
      </MaxWidthWrapper>
    </PanelWrapper>
  );
};

const smallPadding = css`
  padding: 20px 20px;
`;

const bigPadding = css`
  padding: 30px 40px;
`;

const cssPadding = css`
  ${bigPadding}
  @media ${mediaQuery.small} {
    ${smallPadding};
  }
  @media ${mediaQuery.medium} {
    ${smallPadding};
  }
`;

const PanelWrapper = styled.section<{
  backgroundColor?: string;
  grow?: boolean;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  ${({ backgroundColor }): string => (backgroundColor ? `background-color: ${backgroundColor};` : "")}
  ${({ grow }): string => (grow ? `flex-grow: 1` : "")}
`;

const MaxWidthWrapper = styled.div<{ noPadding?: boolean }>`
  width: 100%;

  @media ${mediaQuery.big} {
    max-width: ${mediaQueries.medium}px;
  }
  @media ${mediaQuery.huge} {
    max-width: ${mediaQueries.medium}px;
  }
  ${({ noPadding }): string | FlattenSimpleInterpolation => (noPadding ? "" : cssPadding)}
`;

export default Panel;
