import { compact, isNil } from "lodash-es";
import memoizeOne from "memoize-one";
import { RootState } from "../store";
import {
  GoatCollectionResult,
  ProductACOAPI,
  ProductRaffleChannel,
  ProductRafflesAPI,
  ProductReleaseChannel,
  ProductReleasesAPI,
  ProductsAPI,
  UserRaffleEntry,
} from "../typings/API";

const appSelector = {
  collection: memoizeOne(
    (collectionSlug?: string): ((state: RootState) => Omit<GoatCollectionResult, "products"> | undefined) => {
      return (state): Omit<GoatCollectionResult, "products"> | undefined => {
        if (!collectionSlug) {
          return;
        }

        const collectionObj = state.entities.goatCollectionsBySlug[collectionSlug];
        const collectionProductIds = state.entities.productIdsByGoatCollectionSlug[collectionSlug];

        if (!collectionObj || !collectionProductIds) {
          return;
        }

        const collectionWithPlugdProducts = {
          ...collectionObj,
          plugd_products: collectionProductIds.map((id) => state.entities.productsById[id]),
        };

        return collectionWithPlugdProducts;
      };
    },
  ),

  historyIdx: memoizeOne(
    () =>
      (state: RootState): number =>
        state.app.historyIdx,
  ),

  product: memoizeOne((slug?: string | null): ((state: RootState) => ProductsAPI.Product | null) => {
    return (state: RootState): ProductsAPI.Product | null => {
      if (!slug) {
        return null;
      }
      const productId: number | undefined = state.entities.productIdsBySlug[slug];
      if (!productId) {
        return null;
      }
      const product: ProductsAPI.Product | undefined = state.entities.productsById[productId];
      return product;
    };
  }),

  productById: memoizeOne((productId?: number | null): ((state: RootState) => ProductsAPI.Product | null) => {
    return (state: RootState): ProductsAPI.Product | null => {
      if (!productId) {
        return null;
      }
      const product: ProductsAPI.Product | undefined = state.entities.productsById[productId];
      return product;
    };
  }),

  productsFromIds:
    (ids: number[]) =>
    (state: RootState): ProductsAPI.Product[] =>
      ids.map((id) => state.entities.productsById[id]).filter((p): p is ProductsAPI.Product => Boolean(p)),

  // returns ids that do not correspond to a given product
  missingProductsFromIds:
    (ids: number[]) =>
    (state: RootState): number[] => {
      return ids.reduce<number[]>((result, id) => {
        if (isNil(state.entities.productsById[id])) {
          result.push(id);
        }
        return result;
      }, []);
    },

  recentlyViewedProductIds:
    () =>
    (state: RootState): number[] => {
      return state.app.recentlyViewedProductIds;
    },

  recentlyViewedProducts: memoizeOne(() => (state: RootState): ProductsAPI.Product[] => {
    return state.app.recentlyViewedProductIds
      .map((productId) => {
        return state.entities.productsById[productId];
      })
      .filter((p) => Boolean(p)) as ProductsAPI.Product[];
  }),

  acosFromProductId:
    (productId?: number) =>
    (state: RootState): ProductACOAPI.ProductACO[] => {
      if (!productId) {
        return [];
      }

      const acoIds = state.entities.productACOIdsByProductId[productId] || [];
      const acoList = acoIds.map((id) => state.entities.productACOsById[id]);
      return compact(acoList.map((aco) => aco.aco));
    },

  rafflesFromProductId:
    (productId?: number) =>
    (state: RootState): ProductRafflesAPI.ProductRaffle[] => {
      if (!productId) {
        return [];
      }

      const raffleIds = state.entities.raffleIdsByProductId[productId] || [];
      return raffleIds.map((id) => state.entities.rafflesById[id]);
    },

  releasesFromProductId:
    (productId?: number) =>
    (state: RootState): ProductReleasesAPI.ProductRelease[] => {
      if (!productId) {
        return [];
      }

      const releaseIds = state.entities.releaseIdsByProductId[productId] || [];
      return releaseIds.map((id) => state.entities.releasesById[id]);
    },

  raffleEntryFromRaffleId:
    (raffleId: number) =>
    (state: RootState): UserRaffleEntry | undefined =>
      state.entities.raffleEntriesByRaffleId[raffleId],

  releaseChannelFromKey:
    (channelKey: string) =>
    (state: RootState): ProductReleaseChannel | undefined =>
      state.entities.productReleaseChannelsByKey[channelKey],

  raffleChannelFromKey:
    (channelKey: string) =>
    (state: RootState): ProductRaffleChannel | undefined =>
      state.entities.productRaffleChannelsByKey[channelKey],

  searchOpen:
    () =>
    (state: RootState): boolean =>
      state.app.searchOpen,
  sideMenuOpen: memoizeOne(
    () =>
      (state: RootState): boolean =>
        state.app.sideMenuOpen,
  ),
};

export default appSelector;
