import React, { useCallback, useMemo } from "react";
import analyticsUtil, { trackEvent } from "../../helpers/analyticsUtil";
import { formatMobileBrowserHref } from "../../helpers/urlUtil";
import { Anchor, AnchorProps } from "../../UI";

/**
 * tracks link as outbound click and opens the link
 * @param href
 * @param target AnchorProps["target"]
 * @param revenue number (optional)
 */
export const outboundLinkClick = (href?: string, target?: AnchorProps["target"], revenue?: number): void => {
  // segment tracking
  trackEvent(analyticsUtil.OutboundLink.Clicked, { label: href, revenue });

  // navigate to target
  window.open(href, target ? target : "_blank");
};

export interface OutboundLinkProps extends AnchorProps {
  href: string;
  revenue?: number;
}

const OutboundLink: React.FC<OutboundLinkProps> = ({ children, href, rel, revenue, target, ...nativeProps }) => {
  const formattedHref = useMemo(() => formatMobileBrowserHref(href), [href]);

  const handleOnClick = useCallback<Required<AnchorProps>["onClick"]>(
    (e) => {
      e.preventDefault();
      outboundLinkClick(formattedHref, target, revenue);
    },
    [formattedHref, target, revenue],
  );

  return (
    <Anchor
      {...nativeProps}
      href={href}
      target={target}
      onClick={handleOnClick}
      rel={rel ? rel : "noopener noreferrer"}
    >
      {children}
    </Anchor>
  );
};

export default OutboundLink;
