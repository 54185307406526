import React, { useMemo } from "react";
import config from "../../config";
import { MarketingMedium } from "../../enums/Marketing";
import events from "../../helpers/analyticsUtil";
import { generateOgTagsForPost } from "../../helpers/shareUtils";
import useShare from "../../hooks/useShare";
import { PostsAPI } from "../../typings/API-V2";
import { Icon } from "../../UI";
import PostActionButton from "./PostActionButton";

export interface PostActionShareButtonProps {
  post: PostsAPI.Post;
}

const PostActionShareButton: React.FC<PostActionShareButtonProps> = ({ post }) => {
  const ogTags = useMemo(() => generateOgTagsForPost(post), [post]);
  const { share } = useShare({
    url: `${config.URL}/posts/${post.id}`,
    title: post.title || "",
    medium: MarketingMedium.POST,
    ogTags,
    shareEvent: events.Post.Shared,
  });

  return (
    <PostActionButton onClick={share} px={2}>
      <Icon name="share alternate" />
    </PostActionButton>
  );
};

export default PostActionShareButton;
