import React from "react";
import styled from "styled-components";
import { ProductsAPI } from "../../../typings/API";
import Box from "../Box";
import Image from "../Image";
import Link from "../Link";
import Text from "../Text";

export interface ProductMetaProps {
  product: Pick<ProductsAPI.Product, "image" | "slug" | "name" | "style_id">;
  disableLink?: boolean;
  subtext?: string;
}

const ProductMeta: React.FC<ProductMetaProps> = ({ disableLink = false, product, subtext }) => {
  return (
    <ProductMetaWrapper
      as={disableLink ? undefined : Link}
      to={disableLink ? undefined : `/products/${product.slug}/feed`}
    >
      <ProductImageWrapper>
        <ProductImage alt={product.name} src={product.image} />
      </ProductImageWrapper>
      <ProductNameWrapper>
        <ProductNameText title={product.name}>{product.name}</ProductNameText>
        <ProductSubText title={subtext ?? product.style_id}>{subtext ?? product.style_id}</ProductSubText>
      </ProductNameWrapper>
    </ProductMetaWrapper>
  );
};

export const ProductMetaWrapper = styled(Box)`
  display: flex;
  align-items: center;
  min-width: 0px;
`;

export const ProductNameWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
`;
ProductNameWrapper.defaultProps = {
  ml: 2,
};

const ProductImageWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
`;

const ProductImage = styled(Image)`
  max-height: 40px;
  max-width: 40px;
`;

const ProductNameText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
ProductNameText.defaultProps = {
  fontSize: 2,
  fontWeight: 5,
  color: "black",
};

const ProductSubText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
ProductSubText.defaultProps = {
  color: "darkGrey",
};

export default ProductMeta;
