import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Box, Input, Text } from "../../../UI";
import { UseInputHook } from "../types/inputHooks";

export interface UsePriceInputOptions {}

const useShippingInput: UseInputHook<string, string | undefined> = (defaultValue) => {
  const [price, setPrice] = useState<string>(defaultValue || "");

  const input = useMemo(
    () => (
      <>
        <InputWrapper>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Label>Shipping</Label>
            <CustomShippingInput disabled={true} id="shipping-input" placeholder={"FREE"} />
          </Box>
          <Box mt={2}>
            <Text fontSize={0} color="darkGrey">
              {`We only allow free shipping while in beta. In the meantime, make sure to include shipping costs in your list price.`}
            </Text>
          </Box>
        </InputWrapper>
      </>
    ),
    [],
  );

  const clearPrice = useCallback(() => setPrice(""), []);

  return useMemo(
    () => ({
      input,
      menus: null,
      clearValue: clearPrice,
      value: price,
    }),
    [input, price, clearPrice],
  );
};

const CustomShippingInput = styled(Input)`
  outline-width: 0;
  border: none;
  text-align: right;
  flex-grow: 1;
`;
CustomShippingInput.defaultProps = {
  bg: "white",
};

const Label = styled(Text)`
  flex-shrink: 0;
`;
Label.defaultProps = {
  fontWeight: 5,
  mr: 3,
  color: "darkGrey",
};

const InputWrapper = styled(Box)`
  & .price-input::placeholder {
    color: ${({ theme }) => theme.colors.mediumGrey};
  }
  & .price-input::before {
    content: "$";
  }
  display: flex;
  flex-direction: column;
`;
InputWrapper.defaultProps = {
  p: 3,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
};

export default useShippingInput;
