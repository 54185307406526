import produce, { Draft } from "immer";
import { AppStateStatus, Platform } from "react-native";
import { Reducer } from "redux";
import ActionType from "../enums/ActionType";
import LocalStorageKey from "../enums/LocalStorageKey";
import { OutboundMessageType } from "../enums/MessageType";
import { getStringItem } from "../helpers/localStorageUtil";
import { ActionValue } from "../helpers/thunkUtil";
import { Contacts } from "../typings/Contacts";

/** copied from "expo-permissions" package */
export enum PermissionStatus {
  GRANTED = "granted",
  UNDETERMINED = "undetermined",
  DENIED = "denied",
}

export interface NativeAppState {
  applicationBuildVersion: string | null;
  applicationVersion: string | null;
  attributionCampaign: {
    campaign: { name?: string; source?: string; feature?: string; referral_user_id?: string; provider: string };
    meta: any;
  } | null;
  contacts: Contacts | null;
  contactsApproved: boolean;
  debugLog: ["error" | "log" | "warn" | "info" | "debug" | "dir", string][];
  deviceID: string | null;
  deviceToken: string | null;
  nativeAppState: AppStateStatus;
  notificationsPermissionStatus: PermissionStatus | null;
  platformOs: typeof Platform.OS | null;
  pushCampaign: {
    name: string | null;
    source: string | null;
    content: string | null;
  } | null;
  pushToken: string | null;
  redirect: string | null;
  /**
   * The RevenueCat API key varies depending on the user's device. This value is populated by the Native app (if on mobile).
   */
  revenuecatApiKey: string | null;
  /**
   * These are the message types that can be handled in the NativeApp. This list should be used to check whether or not a message will be handled. If the message type is missing, the user should be notified that their app needs to be updated
   */
  validMessageTypes: typeof OutboundMessageType[keyof typeof OutboundMessageType][];
}

const initialState: NativeAppState = {
  applicationBuildVersion: null,
  applicationVersion: null,
  attributionCampaign: null,
  contacts: null,
  contactsApproved: false,
  debugLog: [],
  deviceID: getStringItem(LocalStorageKey.PLUGD_DEVICE_ID) || null,
  deviceToken: null,
  nativeAppState: "active",
  notificationsPermissionStatus: null,
  platformOs: null,
  pushCampaign: null,
  pushToken: null,
  redirect: null,
  /**
   * TODO: Move this to another reducer since this isn't necessarily native app specific
   */
  revenuecatApiKey: null,
  /**
   * WARNING: Do not modify this list
   *
   * These values are a snapshot of the messages that were available before adding this property and should not be changed.
   */
  validMessageTypes: [
    OutboundMessageType.AUTH_STATE_CHANGE,
    OutboundMessageType.CONTACTS_SYNC,
    OutboundMessageType.GET_CONTACTS_APPROVAL,
    OutboundMessageType.GET_DEBUG_LOG,
    OutboundMessageType.GET_NOTIFICATIONS_APPROVAL,
    OutboundMessageType.LOG,
    OutboundMessageType.READY,
    OutboundMessageType.SET_CONTACTS_APPROVAL,
    OutboundMessageType.SMS_SEND,
  ],
};

const nativeAppReducer = produce((draft: Draft<NativeAppState> = initialState, action: ActionValue) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case ActionType.UPDATE_NATIVE_APP_STATE: {
      const payload = action.payload as Partial<NativeAppState>;
      for (const key in payload) {
        draft[key] = payload[key];
      }
      return draft;
    }
    default:
      return draft;
  }
}) as Reducer<NativeAppState, ActionValue>;

export default nativeAppReducer;
