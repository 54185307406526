import React from "react";
import { ShareModalProps } from "../Share/ShareModal";
import { AppDownloadModalProps } from "./AppDownloadModal";
import { AppUpdateModalProps } from "./AppUpdateModal/AppUpdateModal";
import { ConfirmationModalProps } from "./ConfirmationModal";
import { LoginModalProps } from "./LoginModal";
import { PlugdGoldOnboardingModalProps } from "./PlugdGoldOnboardingModal";

export type GlobalModalState =
  | {
      type: "login";
      props?: Omit<LoginModalProps, "isOpen" | "close">;
    }
  | {
      type: "confirmation";
      props: Omit<ConfirmationModalProps, "isOpen" | "close">;
    }
  | {
      type: "share";
      props: Omit<ShareModalProps, "isOpen" | "close">;
    }
  | {
      type: "app_download";
      props?: Omit<AppDownloadModalProps, "isOpen" | "close">;
    }
  | {
      type: "app_update";
      props?: Omit<AppUpdateModalProps, "isOpen" | "close">;
    }
  | {
      type: "plugd_gold_onboarding";
      props?: Omit<PlugdGoldOnboardingModalProps, "isOpen" | "close">;
    };

const GlobalModalContext = React.createContext<{
  close: () => void;
  open: (modalState: GlobalModalState) => void;
}>({
  close: () => {},
  open: () => {},
});

export default GlobalModalContext;
