import { map } from "lodash-es";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useUser } from "reactfire";
import { useRequest } from "redux-query-react";
import styled from "styled-components";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import { getCommunityList } from "../../queries/api/communityQuery";
import { RootState } from "../../store";
import { CommunitiesAPI } from "../../typings/API";
import { Box, Spacer, theme } from "../../UI";
import { CommunityListSelectItem, CommunityListSelectItemPlaceholder } from "../../UI/components/CommunityList";
import List from "../../UI/components/List";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
  FullscreenMenuProps,
} from "../Layout/FullscreenMenu";

export interface CommunitySelectorMenuProps extends Pick<FullscreenMenuProps, "isOpen" | "close"> {
  onSelect: (community: CommunitiesAPI.Community) => void;
}

const CommunitySelectorMenu: React.FC<CommunitySelectorMenuProps> = ({ isOpen, close, onSelect }) => {
  useEffect(() => {
    trackEvent(events.CommunitySelectorMenu.Viewed);
  }, []);

  const { data: currentUser } = useUser();
  const [{ isFinished }] = useRequest(currentUser ? getCommunityList({}) : null);
  const communities = useSelector((state: RootState) => state.entities.communitiesBySlug);

  const handleSelect = useCallback(
    (community: CommunitiesAPI.Community) => {
      onSelect(community);
      close();
    },
    [onSelect, close],
  );

  return (
    <FullscreenMenu
      isOpen={isOpen}
      close={close}
      bg="white"
      header={
        <FullscreenMenuHeader>
          <FullscreenMenuHeaderIcon onClick={close} name="chevron left" />
          <FullscreenMenuHeaderTitle>{`Select Community`}</FullscreenMenuHeaderTitle>
          <FullscreenMenuHeaderIcon />
        </FullscreenMenuHeader>
      }
    >
      <>
        <Spacer mt={2} />
        <List width="100%" isFinished={isFinished} placeholderEl={<CommunityListSelectItemPlaceholder />}>
          {map(communities, (c) => (
            <SelectItemWrapper key={c.id}>
              <CommunityListSelectItem
                community={c}
                metaProps={{ disableLink: true }}
                onClick={() => handleSelect(c)}
              />
            </SelectItemWrapper>
          ))}
        </List>
      </>
    </FullscreenMenu>
  );
};

const SelectItemWrapper = styled(Box)`
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.lightGrey};
  }
`;
SelectItemWrapper.defaultProps = {
  px: 3,
};

export default CommunitySelectorMenu;
