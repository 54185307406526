import React, { useCallback, useContext } from "react";
import useAutoFocusRefCallback from "../../../hooks/useAutoFocusRefCallback";
import { Box, Text } from "../../../UI";
import AuthScreenContentHeader from "../../AuthScreenModal/AuthScreenContentHeader";
import AuthScreensContext from "../../AuthScreenModal/AuthScreensContext";
import { AuthInput, InputWrapper } from "../AuthInput";

export interface EmailFormProps {
  handleEmailChange: (email: string) => void;
  email: string;
}

const EmailForm: React.FC<EmailFormProps> = ({ email, handleEmailChange }) => {
  const [emailInputRefCallback] = useAutoFocusRefCallback();
  const { setAuthScreen } = useContext(AuthScreensContext);

  const handleEmail = useCallback(
    (event): void => {
      handleEmailChange(event.target.value);
    },
    [handleEmailChange],
  );

  return (
    <>
      <AuthScreenContentHeader
        title={"Enter your email address"}
        description={
          <>
            <Text>{"Already have an account? "}</Text>
            <Text color="blue" clickable onClick={() => setAuthScreen("emailLogin")}>
              {"Log in"}
            </Text>
          </>
        }
      />
      <Box width="100%" my={2}>
        <InputWrapper>
          <AuthInput
            ref={emailInputRefCallback}
            placeholder="E-mail"
            type="email"
            value={email}
            onChange={handleEmail}
            autoComplete="email"
          />
        </InputWrapper>
      </Box>
    </>
  );
};

export default EmailForm;
