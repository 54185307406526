import React from "react";
import { SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import { Box, BoxProps, Icon, Spacer, Text } from "../../UI";

export interface CreatePostSelectorItemProps extends BoxProps {
  iconName: SemanticICONS;
  onClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  title: string;
}

const CreatePostSelectorItem: React.FC<CreatePostSelectorItemProps> = ({ onClick, title, iconName, ...boxProps }) => {
  return (
    <PostButtonWrapper {...boxProps} onClick={onClick}>
      <PostButton color="white">
        <Icon name={iconName} size="large" />
      </PostButton>
      <Spacer mt={2} />
      <Text fontWeight={600}>{title}</Text>
    </PostButtonWrapper>
  );
};

const POST_BUTTON_SIZE = 64;
const PostButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${POST_BUTTON_SIZE}px;
  width: ${POST_BUTTON_SIZE}px;
  border-radius: ${POST_BUTTON_SIZE / 2}px;
  background: ${({ theme }) => theme.colors.goldRed};
`;
PostButton.defaultProps = {
  color: "white",
};

const PostButtonWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover ${Text} {
    text-decoration: underline;
  }
`;

export default CreatePostSelectorItem;
