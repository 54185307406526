import React, { ReactNode } from "react";
import styled from "styled-components";
import { Box, BoxProps, Icon, Text } from "../../UI";

export interface InputSelectBoxProps extends BoxProps {
  disabled?: boolean;
  placeholder: string;
  displayValue?: ReactNode;
  value: string | number | null;
}

const InputSelectBox: React.FC<InputSelectBoxProps> = ({
  disabled,
  displayValue,
  onClick,
  placeholder,
  value,
  ...boxProps
}) => {
  return (
    <InputSelectBoxWrapper onClick={disabled ? undefined : onClick} {...boxProps}>
      {value && displayValue ? (
        displayValue
      ) : value ? (
        value
      ) : placeholder ? (
        <PlaceholderText>{placeholder}</PlaceholderText>
      ) : null}
      <Icon color="mediumGrey" name="chevron down" />
    </InputSelectBoxWrapper>
  );
};

const PlaceholderText = styled(Text)``;
PlaceholderText.defaultProps = {
  color: "mediumGrey",
};

const InputSelectBoxWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
`;
InputSelectBoxWrapper.defaultProps = {
  p: 3,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
};

export default InputSelectBox;
