import React, { ReactNode } from "react";
import styled from "styled-components";
import { BoxProps } from "../Box";
import ListSelectItem, { ListSelectItemProps } from "../List/ListSelectItem";
import UserMentionMeta from "./UserMentionMeta";

export interface UserMentionListSelectItemProps extends BoxProps {
  action?: ReactNode;
  title: string;
  subtitle?: string | null;
}

const UserMentionListSelectItem: React.FC<UserMentionListSelectItemProps> = ({ title, subtitle, ...wrapperProps }) => {
  return (
    <UserListSelectItemWrapper {...wrapperProps}>
      <UserMentionMeta title={title} subtitle={subtitle} />
    </UserListSelectItemWrapper>
  );
};

export interface UserListSelectItemWrapperProps extends ListSelectItemProps {}
export const UserListSelectItemWrapper = styled(ListSelectItem)<UserListSelectItemWrapperProps>`
  justify-content: space-between;
`;

export default UserMentionListSelectItem;
