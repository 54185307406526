import { useMemo } from "react";
import useOpenClose from "./useOpenClose";

export interface MenuProps {
  isOpen: boolean;
  close: () => void;
}

export interface MenuState {
  open: () => void;
  menuProps: MenuProps;
}

type UseMenuState = (isDefaultOpen?: boolean) => MenuState;

const useMenuState: UseMenuState = (isDefaultOpen = false) => {
  const { isOpen, open, close } = useOpenClose(isDefaultOpen);

  const menuState = useMemo(
    () => ({
      open,
      menuProps: {
        isOpen,
        close,
      },
    }),
    [isOpen, close, open],
  );

  return menuState;
};

export default useMenuState;
