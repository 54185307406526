import React from "react";
import styled, { css } from "styled-components";
import { Box, BoxProps, Text } from "../../UI";

export interface PillProps extends BoxProps {
  isActive: boolean;
}

const Pill: React.FC<React.PropsWithChildren<PillProps>> = ({ isActive, children, ...boxProps }) => {
  return (
    <PillContainer {...boxProps} isActive={isActive}>
      <PillText>{children}</PillText>
    </PillContainer>
  );
};

const PillText = styled(Text)`
  white-space: nowrap;
`;
const PillContainer = styled(Box)<BoxProps & { isActive }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.space[2]}px;
  &:hover {
    ${({ theme, isActive }) =>
      !isActive
        ? css`
            background-color: ${theme.colors.white};
            border: 1px solid ${theme.colors.darkGrey};
            color: ${theme.colors.black};
          `
        : ""}
  }
  ${({ theme, isActive }) =>
    isActive
      ? `
        background-color: ${theme.colors.black};
        border: 1px solid ${theme.colors.black};
        color: ${theme.colors.lightGrey};
      `
      : `
        background-color: ${theme.colors.white};
        border: 1px solid ${theme.colors.mediumGrey};
        color: ${theme.colors.darkGrey};
      `}
`;
PillContainer.defaultProps = {
  py: 2,
  px: 3,
  borderRadius: 999,
  bg: "lightGrey",
};

export default Pill;
