import LocalStorageKey from "../enums/LocalStorageKey";
import { getNumberItem, setNumberItem } from "../helpers/localStorageUtil";

interface Migration {
  up: () => void;
}

(function () {
  /**
   * runs local storage migrations
   * @param migrations
   */
  const runMigrations = (migrations: Migration[]) => {
    migrations.forEach((m, migrationVersion) => {
      const lastRunMigrationVersion = getNumberItem(LocalStorageKey.PLUGD_LOCALSTORAGE_MIGRATION_VERSION, -1);
      /**
       * run the migration if it hasn't been run before
       */
      if (lastRunMigrationVersion < migrationVersion) {
        m.up();
        /**
         * store the latest run migration version
         */
        console.log(`local storage migrated to version ${migrationVersion}`);
        setNumberItem(LocalStorageKey.PLUGD_LOCALSTORAGE_MIGRATION_VERSION, migrationVersion);
      }
    });
  };

  /**
   * define migrations here
   */
  const migrations = [
    {
      up: () => {
        /**
         * don't store the plugd push token in local storage. Explicitly told not to do this on Apple's website:
         * https://developer.apple.com/documentation/usernotifications/registering_your_app_with_apns#2942135
         */
        localStorage.removeItem(LocalStorageKey.PLUGD_PUSH_TOKEN);
      },
    },
  ];

  runMigrations(migrations);
})();
