import { keyBy, map, omit, reduce, toLower } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { FORM_HEADERS, STANDARD_HEADERS } from "../../helpers/requestUtil";
import initialEntitiesState from "../../reducers/utils/initialEntitiesState";
import { RecommendationsAPI, UserProfileIdType, UsersAPI } from "../../typings/API";
import EntitiesState, { UserProfileState } from "../../typings/EntitiesState";

export const formatUserProfileIdsByUsername = <U extends UsersAPI.PublicProfile>(users: U[]) =>
  reduce(
    users,
    (result, u) => {
      result[u.username] = u.id;
      return result;
    },
    {},
  );

export const formatUserProfilesById = <U extends UsersAPI.PublicProfile>(users: U[]) => keyBy(users, (u) => u.id);

/**
 * Get the current authenticated user's profile with additional private fields
 */
export const getCurrentUserProfile = (
  force?: boolean,
): QueryConfig<Pick<EntitiesState, "currentUserProfile" | "preferences">> => ({
  url: `${config.API_URL}/users/me`,
  meta: {
    includeToken: true,
  },
  options: {
    headers: STANDARD_HEADERS,
  },
  force,
  transform: (response: UsersAPI.GetCurrentUserResponse) => ({
    currentUserProfile: omit(response.data.user, "preferences"),
    preferences: response.data.user.preferences,
  }),
  update: {
    currentUserProfile: (_, newValue) => newValue,
    preferences: (_, newValue) => newValue,
  },
});

export const deleteCurrentUser = (): QueryConfig<
  Pick<EntitiesState, "currentUserProfile" | "preferences" | "userProfilesById">
> => ({
  url: `${config.API_URL}/users/me`,
  meta: {
    includeToken: true,
  },
  options: {
    headers: STANDARD_HEADERS,
    method: "DELETE",
  },
  transform: () => ({
    /** reset these values to the initial entity values */
    currentUserProfile: initialEntitiesState.entities.currentUserProfile,
    preferences: initialEntitiesState.entities.preferences,
  }),
  update: {
    currentUserProfile: (_, newValue) => newValue,
    preferences: (_, newValue) => newValue,
  },
});

export const getUserProfile = (
  userId: string,
  idType: UserProfileIdType,
): QueryConfig<Pick<UserProfileState, "userProfilesById" | "userProfileIdsByUsername">> => {
  const qs = new URLSearchParams({
    idType,
  }).toString();

  return {
    url: `${config.API_URL}/users/${userId}/profile?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: { profile: UsersAPI.PublicProfile }) => {
      const profile = response.profile;
      return {
        userProfilesById: { [profile.id]: profile },
        userProfileIdsByUsername: profile.username ? { [profile.username]: profile.id } : {},
      };
    },
    update: {
      userProfilesById: shallowObjectMerge,
      userProfileIdsByUsername: shallowObjectMerge,
    },
  };
};

export const createCurrentUserProfile = (
  userFields: UsersAPI.UserProfileInsert,
  referralUserId?: string | null,
): QueryConfig<Pick<EntitiesState, "currentUserProfile" | "preferences">> => {
  // make sure username is lowercase, else call will fail.
  const formattedUserFields: UsersAPI.UserProfileInsert = { ...userFields, username: toLower(userFields.username) };
  return {
    url: `${config.API_URL}/users/${userFields.id}/profile`,
    meta: {
      includeToken: true,
    },
    body: { ...formattedUserFields, ref_user_id: referralUserId },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (response: UsersAPI.CreateCurrentUserResponse) => ({
      currentUserProfile: omit(response.profile, "preferences"),
      preferences: response.profile.preferences,
    }),
    update: {
      currentUserProfile: (_, newValue) => newValue,
    },
  };
};

export const updateCurrentUserProfile = (
  uid: string,
  updateFields: Partial<UsersAPI.CurrentUserProfile>,
): QueryConfig<Pick<EntitiesState, "currentUserProfile">> => ({
  url: `${config.API_URL}/users/${uid}/profile`,
  meta: {
    includeToken: true,
  },
  body: updateFields,
  options: {
    method: "PUT",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    currentUserProfile: (oldValue) =>
      oldValue ? shallowObjectMerge<NonNullable<EntitiesState["currentUserProfile"]>>(oldValue, updateFields) : null,
  },
});

export const uploadAvatar = (
  formData: FormData,
  userId: string,
): QueryConfig<Pick<UserProfileState, "userProfilesById">> => ({
  url: `${config.API_URL}/users/${userId}/upload-avatar`,
  meta: {
    includeToken: true,
  },
  body: formData,
  options: {
    headers: {
      ...FORM_HEADERS,
      "Content-Type": "multipart/form-data",
    },
  },
});

export const getDisplayStatistics = (userId: string): QueryConfig<Pick<UserProfileState, "userStatisticsById">> => {
  return {
    url: `${config.API_URL}/users/${userId}/statistics`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: { stats: UsersAPI.Statistics }) => {
      const stats = response.stats;
      return {
        userStatisticsById: { [userId]: stats },
      };
    },
    update: {
      userStatisticsById: shallowObjectMerge,
    },
  };
};

export const getFolloweeRecommendations = (): QueryConfig<
  Pick<UserProfileState, "userFollowSuggestionIds" | "userProfilesById" | "userProfileIdsByUsername">
> => {
  return {
    url: `${config.API_URL}/recommendations/followees`,
    meta: {
      includeToken: true,
    },
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: RecommendationsAPI.FolloweeRecommendationsResponse) => {
      const { recommendations } = response.data;
      return {
        userProfilesById: formatUserProfilesById(recommendations),
        userProfileIdsByUsername: formatUserProfileIdsByUsername(recommendations),
        userFollowSuggestionIds: map(recommendations, (r) => r.id),
      };
    },
    update: {
      userProfilesById: shallowObjectMerge,
      userProfileIdsByUsername: shallowObjectMerge,
      userFollowSuggestionIds: (oldValue, newValue) => oldValue.concat(newValue),
    },
  };
};

export const getInvitedUsers = (
  userId: string,
): QueryConfig<Pick<UserProfileState, "userProfilesById" | "userProfileIdsByUsername" | "invitedUserIds">> => {
  return {
    url: `${config.API_URL}/users/${userId}/invites`,
    meta: {
      includeToken: true,
    },
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: UsersAPI.GetInvitedUsersResponse) => {
      const users = response.data;
      return {
        userProfilesById: formatUserProfilesById(users),
        userProfileIdsByUsername: formatUserProfileIdsByUsername(users),
        invitedUserIds: users.map((u) => u.id),
      };
    },
    update: {
      userProfilesById: shallowObjectMerge,
      userProfileIdsByUsername: shallowObjectMerge,
      invitedUserIds: (_, newValue) => newValue,
    },
  };
};
export const claimInvite = (
  userId: string,
  referralUserId: string,
): QueryConfig<Pick<UserProfileState, "inviteClaimId">> => {
  return {
    url: `${config.API_URL}/users/${userId}/invites`,
    meta: {
      includeToken: true,
    },
    body: {
      referral_user_id: referralUserId,
    },
    options: {
      headers: STANDARD_HEADERS,
      method: "POST",
    },
    transform: (response: UsersAPI.ClaimInviteResponse) => {
      return {
        inviteClaimId: response.data.id,
      };
    },
    update: {
      inviteClaimId: (_, newValue) => newValue,
    },
  };
};
