import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import ActionsMenuItem, { ActionsMenuItemProps } from "../ActionsMenuItem";

export interface SupportActionsMenuItemProps extends ActionsMenuItemProps {}

const SupportActionsMenuItem: React.FC<SupportActionsMenuItemProps> = ({ ...menuItemProps }) => {
  const history = useHistory();

  const onSupportClick = useCallback(() => {
    history.push(`/c/help`);
  }, [history]);

  return <ActionsMenuItem {...menuItemProps} onClick={onSupportClick} />;
};

export default SupportActionsMenuItem;
