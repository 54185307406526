import { toString } from "lodash-es";
import React, { useMemo } from "react";
import { SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import { Box, BoxProps, Icon, Text } from "../../../UI";
import { TOP_NAV_HEIGHT } from "./TopNavWrapper";

export type TopNavIconButtonProps = {
  iconName: SemanticICONS;
  label?: string;
  notificationCount?: number;
} & BoxProps;

const TopNavIconButton: React.FC<TopNavIconButtonProps> = ({ iconName, label, notificationCount = 0, ...boxProps }) => {
  const formattedNotificationCount = useMemo(() => {
    if (notificationCount > 99) {
      return `99+`;
    }
    return toString(notificationCount);
  }, [notificationCount]);
  return (
    <TopNavIconButtonWrapper {...boxProps}>
      <Box position="relative">
        <Icon name={iconName} />
        {notificationCount > 0 && (
          <NotificationBadge bg="notificationRed">
            <Text color="white" fontSize={0}>
              {formattedNotificationCount}
            </Text>
          </NotificationBadge>
        )}
      </Box>
      {label && <IconLabel>{label}</IconLabel>}
    </TopNavIconButtonWrapper>
  );
};

const IconLabel = styled(Text)`
  margin-top: 4px;
  font-size: 9px;
  line-height: 9px;
`;

const TopNavIconButtonWrapper = styled(Box)`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || TOP_NAV_HEIGHT}px;
  height: ${TOP_NAV_HEIGHT}px;
`;
TopNavIconButtonWrapper.defaultProps = {
  flexShrink: 0,
  role: "button",
  color: "black",
};

const BADGE_SIZE = 20;
const NotificationBadge = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: -12px;
  right: -12px;
`;
NotificationBadge.defaultProps = {
  height: BADGE_SIZE,
  minWidth: BADGE_SIZE,
  px: 1,
  borderRadius: BADGE_SIZE / 2,
  border: "2px solid",
  borderColor: "white",
};

export default TopNavIconButton;
