import React from "react";
import { Route, Switch } from "react-router-dom";
import CheckoutCancel from "./CheckoutCancel";
import CheckoutSuccess from "./CheckoutSuccess";

export interface CheckoutPageProps {}

const CheckoutPage: React.FC<CheckoutPageProps> = () => {
  return (
    <Switch>
      <Route path="/checkout/success" component={CheckoutSuccess} />
      <Route path="/checkout/cancel" component={CheckoutCancel} />
    </Switch>
  );
};

export default CheckoutPage;
