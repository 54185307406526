import styled from "styled-components";
import Box, { BoxProps } from "./Box";

interface CustomCardProps {}

export type CardProps = CustomCardProps & BoxProps;

/* for now, Card is just a Box with a specific box shadow */
const Card = styled(Box)<CardProps>`
  transition: box-shadow 0.25s ease-in-out;
  &:hover {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  }
`;
Card.defaultProps = {
  borderRadius: 3,
};

export default Card;
