import React, { useCallback, useContext } from "react";
import { Button, Segment, SegmentProps, Spacer, Text } from "../UI";
import AuthScreensContext, { AuthScreen } from "./AuthScreenModal/AuthScreensContext";

export interface SignUpOrLogInSegmentProps extends SegmentProps {}

const SignUpOrLogInSegment: React.FC<SignUpOrLogInSegmentProps> = ({ ...segmentProps }) => {
  const { setAuthScreen } = useContext(AuthScreensContext);

  const openAuthScreen = useCallback<(authScreen: AuthScreen) => void>(
    (authScreen) => setAuthScreen(authScreen),
    [setAuthScreen],
  );

  return (
    <Segment display="flex" flexDirection="column" alignItems="center" p={4} bg="white" mx={3} mb={3} {...segmentProps}>
      <Text fontSize={2} fontWeight={600}>
        Sign Up or Log In
      </Text>
      <Spacer mt={3} />
      <Text fontSize={1}>Please sign up or log in to continue</Text>
      <Spacer mt={3} />
      <Button size="small" variant="primary" width="100%" onClick={openAuthScreen.bind(null, "login")}>
        Continue
      </Button>
    </Segment>
  );
};

export default SignUpOrLogInSegment;
