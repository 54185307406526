import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import Page from "../../../components/Layout/Page";
import FeedTabSecondaryTopNav from "../../../components/TopNav/FeedTabSecondaryTopNav";
import { pages, trackPage } from "../../../helpers/analyticsUtil";
import useCheckoutSession from "../../../hooks/useCheckoutSession";
import useOrder from "../../../hooks/useOrder";
import useQuery from "../../../hooks/useQuery";
import { Box } from "../../../UI";
import NotFoundPage from "../../NotFoundPage";
import CheckoutSuccessContent, { CheckoutSuccessContentProps } from "./CheckoutSuccessContent";

export interface CheckoutSuccessProps {}

const CheckoutSuccess: React.FC<CheckoutSuccessProps> = () => {
  /** track page visits */
  useEffect(() => trackPage(pages.CheckoutSuccess), []);
  const searchParams = useQuery();
  const sessionId = searchParams.get("session_id");
  const orderId = searchParams.get("order");
  // const orderClientSecret = searchParams.get("order_client_secret");
  const orderData = useOrder(orderId);
  const checkoutData = useCheckoutSession(sessionId);

  const checkoutSessionContentProps = useMemo<CheckoutSuccessContentProps>(() => {
    if (checkoutData.checkoutSession) {
      return {
        checkoutSessionOrOrder: {
          type: "checkout_session",
          data: checkoutData.checkoutSession,
        },
        isFinished: checkoutData.isFinished,
        isPending: checkoutData.isPending,
        sellers: checkoutData.sellers,
      };
    }

    if (orderData.order) {
      return {
        checkoutSessionOrOrder: {
          type: "order",
          data: orderData.order,
        },
        isFinished: orderData.isFinished,
        isPending: orderData.isPending,
        sellers: orderData.sellers,
      };
    }

    return {
      checkoutSessionOrOrder: undefined,
      isFinished: orderData.isFinished || checkoutData.isFinished,
      isPending: orderData.isPending || checkoutData.isPending,
      sellers: [],
    };
  }, [checkoutData, orderData]);

  if (checkoutSessionContentProps.isFinished && !checkoutSessionContentProps.checkoutSessionOrOrder) {
    return <NotFoundPage />;
  }

  return (
    <>
      <FeedTabSecondaryTopNav title={"Order Details"} />
      <Page>
        <CheckoutSuccessContent {...checkoutSessionContentProps} />
      </Page>
    </>
  );
};

const AmountLineItem = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
AmountLineItem.defaultProps = {
  mb: 1,
};

export default CheckoutSuccess;
