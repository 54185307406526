import { AdditionalUserInfo } from "@firebase/auth-types";
import React, { useCallback, useContext, useState } from "react";
import { Divider, Message } from "../../libs/semantic-ui";
import { Box, Text } from "../../UI";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import usePostAuthentication from "./hooks/usePostAuthentication";
import LoginForm from "./LoginForm";
import SecondaryLogin from "./SecondaryLogin";

export interface LoginProps {
  onClose?: () => void;
  onResetPassword?: () => void;
}

const Login: React.FC<LoginProps> = ({ onClose, onResetPassword }) => {
  const [err, setErr] = useState<string | undefined>();
  const { setAuthScreen, info, setInfo, onAuthenticated } = useContext(AuthScreensContext);
  const postAuthentication = usePostAuthentication();

  const onSubmitSuccess = useCallback(
    (additionalUserInfo?: AdditionalUserInfo | null) => {
      setInfo(null);
      if (err) {
        setErr(undefined);
      }
      postAuthentication(additionalUserInfo);
      if (additionalUserInfo?.isNewUser) {
        setAuthScreen("createUsername");
      } else {
        if (onAuthenticated) {
          onAuthenticated();
        }
        if (onClose) {
          onClose();
        }
      }
    },
    [err, onAuthenticated, onClose, postAuthentication, setAuthScreen, setInfo],
  );

  const handleResetPasswordClick = useCallback(() => {
    if (onResetPassword) {
      onResetPassword();
    } else {
      setAuthScreen("resetPassword");
    }
  }, [onResetPassword, setAuthScreen]);

  return (
    <>
      <SecondaryLogin onError={setErr} onSubmitSuccess={onSubmitSuccess} />
      <Divider horizontal>Or</Divider>
      <LoginForm onError={setErr} onSubmitSuccess={onSubmitSuccess} />
      <Box display="flex" justifyContent="center">
        <Text
          clickable={true}
          color="blue"
          fontSize={0}
          mt={3}
          onClick={handleResetPasswordClick}
          textAlign="center"
          textDecoration="underline"
        >
          I forgot my password
        </Text>
      </Box>
      {err && <Message error content={err} />}
      {info && <Message success content={info} />}
    </>
  );
};

export default Login;
