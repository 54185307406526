import { forEach } from "lodash-es";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useActions from "./hooks/useActions";

/* Custom tracker for index since native index in inconsistent between browsers. */
const HistoryIndexTracker: React.FC = (): null => {
  const history = useHistory<{ sessionStorage: { [key: string]: string } }>();
  const { trackHistoryIndex } = useActions();

  useEffect(() => {
    return history.listen((location, action) => {
      if (location.state && location.state.sessionStorage) {
        forEach(location.state.sessionStorage, (value, key): void => sessionStorage.setItem(key, value));
      }

      trackHistoryIndex(action);
    });
  }, [history, trackHistoryIndex]);

  return null;
};

export default HistoryIndexTracker;
