import { AdditionalUserInfo } from "@firebase/auth-types";
import React, { useCallback, useContext, useState } from "react";
import { Divider, Message } from "../../libs/semantic-ui";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import usePostAuthentication from "./hooks/usePostAuthentication";
import SecondaryLogin from "./SecondaryLogin";
import SignupForm from "./SignupForm";

export interface SignupProps {
  onClose?: () => void;
}

const Signup: React.FC<SignupProps> = ({ onClose }) => {
  const [err, setErr] = useState<string | undefined>();
  const { setAuthScreen, onAuthenticated } = useContext(AuthScreensContext);
  const postAuthentication = usePostAuthentication();

  const onSubmitSuccess = useCallback(
    (additionalUserInfo?: AdditionalUserInfo | null) => {
      if (err) {
        setErr(undefined);
      }
      postAuthentication(additionalUserInfo);
      if (additionalUserInfo?.isNewUser) {
        setAuthScreen("createUsername");
      } else {
        if (onAuthenticated) {
          onAuthenticated();
        }
        if (onClose) {
          onClose();
        }
      }
    },
    [err, postAuthentication, setAuthScreen, onClose, onAuthenticated],
  );

  return (
    <>
      <SecondaryLogin onError={setErr} onSubmitSuccess={onSubmitSuccess} />
      <Divider horizontal>Or</Divider>
      <SignupForm onError={setErr} onSubmitSuccess={onSubmitSuccess} />
      {err && <Message error content={err} />}
    </>
  );
};

export default Signup;
