import { toLower } from "lodash-es";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useAuth } from "reactfire";
import { useMutation } from "redux-query-react";
import styled from "styled-components";
import AuthFields from "../../enums/AuthFields";
import { authFieldToLabel } from "../../helpers/authFieldsUtil";
import { isValidUsername } from "../../helpers/profileUtils";
import useAutoFocusRefCallback from "../../hooks/useAutoFocusRefCallback";
import { Form, FormProps, InputOnChangeData, Loader, Message } from "../../libs/semantic-ui";
import { updateCurrentUserProfile } from "../../queries/api/userProfileQuery";
import { UsersAPI } from "../../typings/API";
import { Box, Button, FormInput } from "../../UI";

export interface CreateUsernameFields {
  username: string;
}

interface CustomCreateUsernameFormProps {
  onSubmitSuccess?: (e: React.FormEvent<HTMLFormElement>, data: FormProps, fields: CreateUsernameFields) => void;
  onError: (error: string | undefined) => void;
}

export type CreateUsernameFormProps = CustomCreateUsernameFormProps & FormProps;

const CreateUsernameForm: React.FC<CreateUsernameFormProps> = ({ onSubmitSuccess, onError, ...formProps }) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<string | null>(null);
  const [fields, setFields] = useState<CreateUsernameFields>({
    username: "",
  });

  const [usernameInputRef] = useAutoFocusRefCallback();

  const [, updateProfile] = useMutation((userId: string, userFields: Partial<UsersAPI.PublicProfile>) =>
    updateCurrentUserProfile(userId, userFields),
  );

  const handleChange = useCallback(
    (e: ChangeEvent, data: InputOnChangeData): void => {
      const { name, value } = data;
      const formattedUsername = toLower(value.replace(/ /g, "_"));
      setFields({
        ...fields,
        [name]: formattedUsername,
      });
    },
    [fields],
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>, data: FormProps) => {
      e.preventDefault();
      setLoading(true);
      if (!auth.currentUser?.uid) {
        return;
      }
      try {
        const updateResults = await updateProfile(auth.currentUser.uid, { username: fields.username });
        if (updateResults?.status === 204 || updateResults?.status === 200) {
          setErr(null);
          if (onSubmitSuccess) {
            onSubmitSuccess(e, data, fields);
          }
        } else if (updateResults?.status === 409) {
          setErr("Username is already taken, please try a different one.");
        } else if (updateResults?.status === 422) {
          setErr("Only lowercase alphanumeric characters allowed.");
        } else {
          setErr("An error occurred while making changes to your profile, please try again later.");
        }
      } catch (error) {
        if (onError) {
          onError("An error occurred while making changes to your profile, please try again later.");
        }
        setErr("An error occurred while making changes to your profile, please try again later.");
      } finally {
        setLoading(false);
      }
    },
    [fields, auth.currentUser?.uid, onError, onSubmitSuccess, updateProfile],
  );

  const canSubmit = useMemo(() => {
    return isValidUsername(fields.username);
  }, [fields.username]);

  return (
    <Form style={{ width: "100%" }} {...formProps} onSubmit={handleSubmit} error={Boolean(err)}>
      <FormInput
        ref={usernameInputRef}
        fluid
        name={AuthFields.USERNAME}
        onChange={handleChange}
        placeholder={authFieldToLabel[AuthFields.USERNAME]}
        type="username"
        value={fields.username}
        autoComplete="username"
      />
      <SubmitButton variant={canSubmit ? "primary" : "disabled"} type="submit" size="big" disabled={!canSubmit}>
        <ButtonContent>{loading ? <Loader inverted active inline="centered" size="tiny" /> : "Submit"}</ButtonContent>
      </SubmitButton>
      <Message error content={err} />
    </Form>
  );
};

const ButtonContent = styled(Box)`
  height: 1.1em;
`;

ButtonContent.defaultProps = {
  justifyContent: "center",
  display: "flex",
};

const SubmitButton = styled(Button)`
  width: 100%;
`;

export default CreateUsernameForm;
