import React from "react";
import { useDispatch } from "react-redux";
import { toggleSideMenu } from "../../actions/createActions";
import useTopNavActions from "../../hooks/useTopNavActions";
import { SearchInput } from "../../UI";
import CommunitySideMenu from "../CommunitySideMenu";
import { MobileTopNav, MobileTopNavProps } from "../Layout/TopNav";
import TopNavIconButton from "../Layout/TopNav/TopNavIconButton";
import TopNavProfileIcon from "./TopNavProfileIcon";
import TopNavSide from "./TopNavSide";

export interface ProductsTabPrimaryTopNavProps extends MobileTopNavProps {}
const SEARCH_INPUT_HEIGHT = 32;

const ProductsTabPrimaryTopNav: React.FC<ProductsTabPrimaryTopNavProps> = ({ ...topNavProps }) => {
  const { onProductSearchClick } = useTopNavActions();
  const dispatch = useDispatch();

  return (
    <>
      <MobileTopNav borderBottom="none" {...topNavProps}>
        <TopNavSide side="left">
          <TopNavIconButton iconName="bars" onClick={() => dispatch(toggleSideMenu())} />
        </TopNavSide>
        <SearchInput onClick={onProductSearchClick} placeholder="Search Plugd" height={SEARCH_INPUT_HEIGHT} />
        <TopNavSide side="right">
          <TopNavProfileIcon />
        </TopNavSide>
      </MobileTopNav>
      <CommunitySideMenu />
    </>
  );
};

export default ProductsTabPrimaryTopNav;
