import { OrderBy } from "../../typings/API";
import { keyBy, toString, union, isNil } from "lodash-es";
import { generateListKey, shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import config from "../../config";
import RestocksAPI from "../../typings/API-V2/RestocksAPI";
import produce from "immer";
import { QueryConfig } from "redux-query";
import EntitiesState from "../../typings/EntitiesState";

export interface GetRestocksOptions {
  limit?: number;
  offset?: number;
  order_by?: OrderBy;
  sort_by?: "created_at";
  is_hyped?: boolean;
  sizes?: string[];
  keyword_whitelist?: string[];
}
export const listRestocks = (
  options: GetRestocksOptions,
): QueryConfig<Pick<EntitiesState, "restocksById" | "restocksListsByKey">> => {
  const listKey = generateListKey<GetRestocksOptions>(options);
  const searchParams = new URLSearchParams({
    order_by: options.order_by || "asc",
    sort_by: options.sort_by || "end_date",
  });
  if (options.limit) {
    searchParams.set("limit", toString(options.limit));
  }
  if (options.offset) {
    searchParams.set("offset", toString(options.offset));
  }
  if (options.order_by) {
    searchParams.set("order_by", options.order_by);
  }
  if (options.sort_by) {
    searchParams.set("sort_by", options.sort_by);
  }
  if (options.is_hyped) {
    searchParams.set("is_hyped", toString(options.is_hyped));
  }
  if (options.sizes) {
    options.sizes.forEach((size) => {
      searchParams.append("sizes", size);
    });
  }
  if (options.keyword_whitelist) {
    options.keyword_whitelist.forEach((keyword) => {
      searchParams.append("keyword_whitelist", keyword);
    });
  }
  const qs = searchParams.toString();

  return {
    url: `${config.API_V2_URL}/restocks?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    meta: {
      includeToken: true,
    },
    /** always fetch new */
    force: true,
    transform: (response: RestocksAPI.ListRestocksResponse) => ({
      restocksById: keyBy(response.data.restocks, (r) => r.id),
      restocksListsByKey: {
        [listKey]: response.data.restocks.map((r) => r.id),
      },
    }),
    update: {
      restocksById: shallowObjectMerge,
      restocksListsByKey: (oldValue, newValue) => {
        return produce(oldValue, (draft) => {
          const origListKeyList = draft[listKey] || [];
          const newListKeyList = newValue[listKey];
          draft[listKey] = union(origListKeyList, newListKeyList);
        });
      },
    },
  };
};

export const getRestockFilters = (): QueryConfig<Pick<EntitiesState, "restocksFilter">> => {
  return {
    url: `${config.API_V2_URL}/restocks/user-filter`,
    options: {
      headers: STANDARD_HEADERS,
    },
    meta: {
      includeToken: true,
    },
    transform: (response: RestocksAPI.GetRestocksFilterResponse) => ({
      restocksFilter: response.data.filter,
    }),
    update: {
      restocksFilter: (oldValue, newValue) => {
        return !isNil(newValue) ? newValue : oldValue;
      },
    },
  };
};

// will create if filter does not exist
export const updateRestockFilters = (
  filterValue: Partial<RestocksAPI.RestocksFilter>,
): QueryConfig<Pick<EntitiesState, "restocksFilter">> => ({
  url: `${config.API_V2_URL}/restocks/user-filter`,
  body: filterValue,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  transform: (response: RestocksAPI.PostRestocksFilterResponse) => ({
    restocksFilter: response.data.filter,
  }),
  update: {
    restocksFilter: (oldValue, newValue) => newValue,
  },
});
