import React, { useCallback, useMemo, useRef, useState } from "react";
import { CommunitiesAPI } from "../../../typings/API";
import { Box } from "../../../UI";
import ContentTextarea from "../ContentTextarea";
import PostMentionMenu from "../PostMentionMenu";
import { UseInputHook } from "../types/inputHooks";
import useMentionMenu from "./useMentionMenu";

interface UseContentInputOptions {
  defaultValue?: string;
  community?: CommunitiesAPI.Community | null;
}

const useContentInput: UseInputHook<string, UseContentInputOptions> = ({ defaultValue, community }) => {
  const [content, setContent] = useState<string>(defaultValue || "");
  const contentInputRef = useRef<HTMLTextAreaElement>(null);

  const {
    onChange: onContentChange,
    close: closeContentMentionMenu,
    selectMentionUser: selectContentMentionUser,
    isOpen: contentMentionMenuIsOpen,
  } = useMentionMenu({
    value: content,
    setValue: setContent,
    inputRefNode: contentInputRef.current,
  });

  const input = useMemo(
    () => (
      <Box p={3} borderBottom="1px solid" borderColor="lightGrey">
        <ContentTextarea
          async
          onChange={onContentChange}
          placeholder={"Text post (optional)"}
          ref={contentInputRef}
          value={content}
        />
      </Box>
    ),
    [onContentChange, content],
  );

  const mentionMenu = useMemo(
    () => (
      <PostMentionMenu
        community={community}
        isOpen={contentMentionMenuIsOpen}
        close={closeContentMentionMenu}
        onSelect={selectContentMentionUser}
      />
    ),
    [contentMentionMenuIsOpen, closeContentMentionMenu, selectContentMentionUser, community],
  );

  const clearValue = useCallback(() => setContent(""), []);

  return useMemo(
    () => ({
      input,
      menus: mentionMenu,
      value: content,
      clearValue,
    }),
    [input, mentionMenu, content, clearValue],
  );
};

export default useContentInput;
