import React, { useCallback } from "react";
import useAffiliateLinkClick from "../../hooks/useAffiliateLinkClick";
import { VendorsAPI } from "../../typings/API";
import { Anchor, AnchorProps } from "../../UI";

export interface AffiliateClickTrackOptions {
  id: string;
  anonymous_user_id?: string | null;
  product_id?: number | null;
  referrer_id?: string | null;
  referrer_post_id?: number | null;
  user_id?: string | null;
  vendor_id?: number | null;
}

type AffiliateLinkType = "price" | "release" | "raffle" | "post" | "restock";

export interface AffiliateLinkProps extends Omit<AnchorProps, "href"> {
  revenue?: number;
  trackOptions?: Pick<AffiliateClickTrackOptions, "product_id" | "vendor_id">;
  linkType: AffiliateLinkType;
  url: string;
  vendor?: Pick<VendorsAPI.VendorConfig, "affiliate_data">;
}

const AffiliateLink: React.FC<AffiliateLinkProps> = ({
  children,
  linkType,
  rel,
  revenue,
  target,
  trackOptions,
  url,
  vendor,
  ...anchorProps
}) => {
  const onAffiliateLinkClick = useAffiliateLinkClick({
    affiliateData: vendor?.affiliate_data,
    revenue,
    target,
    trackingOptions: trackOptions,
  });

  const onClick = useCallback<Required<AnchorProps>["onClick"]>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      onAffiliateLinkClick(url, linkType);
    },
    [onAffiliateLinkClick, url, linkType],
  );

  return (
    <Anchor href={url} onClick={onClick} rel={rel ? rel : "noopener noreferrer"} {...anchorProps}>
      {children}
    </Anchor>
  );
};

export default AffiliateLink;
