import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useMenuState from "../../../hooks/useMenuState";
import { ProductsAPI } from "../../../typings/API";
import { Box, Icon, Text } from "../../../UI";
import ProductMeta from "../../../UI/components/ProductList/ProductMeta";
import ProductSelectorMenu, { ProductSelectorMenuProps } from "../../ProductSelectorMenu";
import { UseInputHook } from "../types/inputHooks";

const useProductSelectorInput: UseInputHook<ProductsAPI.Product | null, ProductsAPI.Product | undefined> = (
  defaultProduct,
) => {
  const [product, setProduct] = useState<ProductsAPI.Product | null>(defaultProduct || null);
  const {
    open: openProductSelectorMenu,
    menuProps: { isOpen, close },
  } = useMenuState();

  /**
   * wrap default open behavior in useEffect, so it opens after parent component mounts, otherwise
   * the product selector menu is rendered behind the post creation menu
   */
  useEffect(() => {
    if (!product) {
      openProductSelectorMenu();
    }
  }, [product, openProductSelectorMenu]);

  const input = useMemo(
    () => (
      <Box onClick={openProductSelectorMenu}>
        {product ? (
          <ProductSelectorWrapper px={3} py={2}>
            <ProductMeta product={product} />
            <Icon color="mediumGrey" name="chevron right" />
          </ProductSelectorWrapper>
        ) : (
          <ProductSelectorWrapper p={3} color="darkGrey">
            <Text>{"Select product"}</Text>
            <Icon color="mediumGrey" name="chevron right" />
          </ProductSelectorWrapper>
        )}
      </Box>
    ),
    [product, openProductSelectorMenu],
  );

  const onProductSelect = useCallback<ProductSelectorMenuProps["onSelect"]>(
    (product) => setProduct(product),
    [setProduct],
  );

  const menus = useMemo(
    () => <ProductSelectorMenu isOpen={isOpen} close={close} onSelect={onProductSelect} />,
    [isOpen, close, onProductSelect],
  );

  const clearValue = useCallback(() => setProduct(null), []);

  return useMemo(
    () => ({
      input,
      menus,
      value: product,
      clearValue,
    }),
    [input, menus, product, clearValue],
  );
};

const ProductSelectorWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
ProductSelectorWrapper.defaultProps = {
  borderBottom: "1px solid",
  borderColor: "lightGrey",
};

export default useProductSelectorInput;
