import styled from "styled-components";
import { Box, BoxProps } from "../../../UI";
import { TOP_NAV_HEIGHT } from "../TopNav";

export interface MenuItemProps extends BoxProps {}

const MenuItem = styled(Box)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;
MenuItem.defaultProps = {
  px: 3,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  display: "flex",
  alignItems: "center",
  height: TOP_NAV_HEIGHT,
};

export default MenuItem;
