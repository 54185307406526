import React, { useCallback, useMemo, useState } from "react";
import { Box, Input, InputProps } from "../../../UI";
import { UseInputHook } from "../types/inputHooks";

export type UseLinkInputOptions =
  | (Partial<Omit<InputProps, "onChange" | "type" | "color">> & { defaultValue?: string })
  | void;

const useLinkInput: UseInputHook<string, UseLinkInputOptions> = (inputOptions) => {
  const [url, setUrl] = useState<string>(inputOptions ? inputOptions.defaultValue || "" : "");

  const onLinkChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(e.target.value);
    },
    [setUrl],
  );

  const input = useMemo(
    () => (
      <Box p={3} borderBottom="1px solid" borderColor="lightGrey">
        <Input
          placeholder={"Link your post (optional)"}
          onChange={onLinkChange}
          value={url}
          type={"url"}
          {...inputOptions}
        />
      </Box>
    ),
    [onLinkChange, url, inputOptions],
  );

  const clearUrl = useCallback(() => setUrl(""), []);

  return useMemo(
    () => ({
      input,
      menus: null,
      clearValue: clearUrl,
      value: url,
    }),
    [input, url, clearUrl],
  );
};

export default useLinkInput;
