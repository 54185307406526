import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import config from "../../../config";
import events, { trackEvent } from "../../../helpers/analyticsUtil";
import { MenuProps } from "../../../hooks/useMenuState";
import { Modal } from "../../../libs/semantic-ui";
import { RootState } from "../../../store";
import { Box, Button, ButtonProps, Image, Spacer, Text } from "../../../UI";

export interface AppUpdateModalProps extends MenuProps {}

const AppUpdateModal: React.FC<AppUpdateModalProps> = ({ isOpen, close }) => {
  useEffect(() => {
    trackEvent(events.AppUpdateModal.Viewed);
  }, []);

  const platformOs = useSelector((state: RootState) => state.nativeApp.platformOs);

  const onUpdateClick = useCallback<NonNullable<ButtonProps["onClick"]>>(() => {
    if (platformOs === "ios") {
      return window.open(config.IOS_APP_URL);
    }
    if (platformOs === "android") {
      return window.open(config.ANDROID_APP_URL);
    }
  }, [platformOs]);

  return (
    <Modal open={isOpen} dimmer={true} size="mini" title="Download the app">
      <Box
        alignItems="center"
        bg="white"
        borderRadius={3}
        color="black"
        display="flex"
        flexDirection="column"
        px={4}
        py={5}
      >
        <Text textAlign="center" fontSize={3} fontWeight={5}>
          {`Update Plugd`}
        </Text>
        <Spacer mt={3} />
        <Text fontSize={2} color="darkGrey" textAlign="center">{`Your app is out of date. Update the app in ${
          platformOs === "android" ? "Google Play" : "the App Store"
        } for the latest features and best experience.`}</Text>
        {/* If we aren't sure which store to link to, remove update button */}
        {(platformOs === "android" || platformOs === "ios") && (
          <Button mt={4} fluid={true} onClick={onUpdateClick} variant="primary">
            Update
          </Button>
        )}
        <Spacer mt={4} />
        <Text fontSize={0} clickable={true} onClick={close}>
          Dismiss
        </Text>
      </Box>
    </Modal>
  );
};

const BadgeImage = styled(Image)``;
BadgeImage.defaultProps = {
  height: 35,
};

export default AppUpdateModal;
