import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { isMobileFrame } from "../../config";
import useSendReactNativeMessage from "../../hooks/useSendReactNativeMessage";
import { openAppSettings } from "../../messages/outboundMessages";
import { RootState } from "../../store";
import { Box, Button, Text } from "../../UI";

type RefCallback<N extends HTMLElement> = (node: N | null) => void;

export interface NotificationPermissionsBannerProps {
  title: string;
  subtitle?: string;
  /**
   * Makes the banner fixed and adds spacer so content is pushed down by the banner
   * default: true
   */
  fixed?: boolean;
}

const NotificationPermissionsBanner: React.FC<NotificationPermissionsBannerProps> = ({
  title,
  subtitle = `Enable push notifications`,
  fixed = true,
}) => {
  const [height, setHeight] = useState<number>(0);
  const [refNode, setRefNode] = useState<HTMLDivElement | null>(null);
  const refCallback = useCallback<RefCallback<HTMLDivElement>>((node) => {
    if (node) {
      setHeight(node.getBoundingClientRect().height);
    }
    setRefNode(node);
  }, []);

  const notificationsPermissionStatus = useSelector(
    (state: RootState) => state.nativeApp.notificationsPermissionStatus,
  );
  const sendReactNativeMessage = useSendReactNativeMessage();
  const onEnableNotificationsClick = useCallback(
    () => sendReactNativeMessage(openAppSettings()),
    [sendReactNativeMessage],
  );

  if (!isMobileFrame) {
    return null;
  }

  /** Don't display the banner if notification permissions have not been set or if they are already granted */
  if (notificationsPermissionStatus === null || notificationsPermissionStatus === "granted") {
    return null;
  }

  return (
    <>
      <Banner ref={refCallback} fixed={fixed} px={3} py={2} bg="black">
        <Box display="flex" flexDirection="column">
          <Text color="lightGrey" title={title}>
            {title}
          </Text>
          <Text fontSize={0} color="mediumGrey" title={subtitle}>
            {subtitle}
          </Text>
        </Box>
        <Button variant="primary" size="tiny" onClick={onEnableNotificationsClick}>
          Enable
        </Button>
      </Banner>
      {/* This creates a spacer that matches the height of the Banner */}
      {fixed && refNode && <Box height={height} width="100%" />}
    </>
  );
};

const Banner = styled(Box)<{ fixed: boolean }>`
  ${({ fixed }) => (fixed ? `position: fixed;` : "")}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
`;

export default NotificationPermissionsBanner;
