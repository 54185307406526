import { isString } from "lodash-es";
import React from "react";
import styled from "styled-components";
import { Box, Text, TextProps } from "../../../UI";

export interface TopNavTitleProps extends TextProps {}

const TopNavTitle: React.FC<React.PropsWithChildren<TopNavTitleProps>> = ({ children, ...textProps }) => {
  return (
    <TitleContainer display="flex" alignItems="center" justifyContent="center">
      {isString(children) ? (
        <TitleText title={children} {...textProps}>
          {children}
        </TitleText>
      ) : (
        children
      )}
    </TitleContainer>
  );
};

const TitleContainer = styled(Box)`
  overflow: hidden;
`;

const TitleText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
TitleText.defaultProps = {
  fontWeight: 5,
  fontSize: 2,
  textAlign: "center",
};

export default TopNavTitle;
