import produce from "immer";
import { isBoolean, keyBy, map, toString, without } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { AdminAPI, UsersAPI } from "../../typings/API";
import { AdminState, UserProfileState } from "../../typings/EntitiesState";

export const getUnclaimedAccounts = (): QueryConfig<AdminState & UserProfileState> => ({
  url: `${config.ADMIN_API_URL}/user-account`,
  meta: {
    includeToken: true,
  },
  options: {
    headers: STANDARD_HEADERS,
  },
  transform: (response: AdminAPI.GetUsersResponse) => {
    return {
      unclaimedUserIds: map(response.users, "id"),
      userProfilesById: keyBy(response.users, "id"),
      userProfileIdsByUsername: response.users.reduce((result, user) => {
        result[user.username] = user.id;
        return result;
      }, {}),
    };
  },
  update: {
    unclaimedUserIds: (_, newValue) => newValue,
    userProfilesById: shallowObjectMerge,
    userProfileIdsByUsername: shallowObjectMerge,
  },
});

export const generateUserAccount = (userFields: UsersAPI.UserAccount): QueryConfig<AdminState & UserProfileState> => ({
  url: `${config.ADMIN_API_URL}/user-account`,
  body: userFields,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  transform: (response: AdminAPI.PostUserResponse) => ({
    unclaimedUserIds: [response.user.id],
    userProfilesById: { [response.user.id]: response.user },
    userProfileIdsByUsername: { [response.user.username]: response.user.id },
  }),
  update: {
    unclaimedUserIds: (oldValue, newValue) => [...oldValue, ...newValue],
    userProfilesById: shallowObjectMerge,
    userProfileIdsByUsername: shallowObjectMerge,
  },
});

export const transferUserAccount = (transferFields: AdminAPI.PostUserTransferBody): QueryConfig<AdminState> => ({
  url: `${config.ADMIN_API_URL}/user-account/transfer`,
  body: transferFields,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    unclaimedUserIds: (oldValue) => without(oldValue, transferFields.user_id),
  },
});

export const getGlobalNotifications = (scheduled: boolean | undefined): QueryConfig<AdminState> => {
  const qs = isBoolean(scheduled)
    ? new URLSearchParams({
        is_scheduled: toString(scheduled),
      }).toString()
    : "";

  return {
    url: `${config.ADMIN_API_URL}/global-notifications?${qs}`,
    meta: {
      includeToken: true,
    },
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: AdminAPI.GetGlobalNotificationsResponse) => {
      return {
        scheduledNotifications: response.notifications,
      };
    },
    update: {
      scheduledNotifications: (_, newValue) => newValue,
    },
  };
};

// create gloibal notifications needs to hit default service due to idle time limits.
export const createGlobalNotification = (
  notificationFields: AdminAPI.PostGlobalNotificationBody,
): QueryConfig<AdminState> => ({
  url: `${config.TASK_API_BASE_URL}/admin/global-notifications`,
  body: notificationFields,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  transform: (response: AdminAPI.PostGlobalNotificationResponse) => ({
    scheduledNotifications: [{ id: response.id, ...notificationFields }],
  }),
  update: {
    scheduledNotifications: (oldValue, newValue) => [...oldValue, ...newValue],
  },
});

export const initACO = (acoFields: AdminAPI.PostACOBody): QueryConfig<AdminState> => ({
  url: `${config.ADMIN_API_URL}/aco`,
  body: acoFields,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  transform: (response: AdminAPI.PostACOResponse) => ({
    aco: [{ id: response.aco.id, ...acoFields }],
  }),
  update: {
    aco: (oldValue, newValue) => [...oldValue, ...newValue],
  },
});

export const muteUser = (userId: string): QueryConfig<Pick<UserProfileState, "userProfilesById">> => ({
  url: `${config.ADMIN_API_URL}/user-account/${userId}/mute`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "PUT",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    userProfilesById: produce((draft) => {
      const targetUser = draft[userId];
      if (targetUser) {
        targetUser.is_muted = true;
      }
    }),
  },
});

export const unmuteUser = (userId: string): QueryConfig<Pick<UserProfileState, "userProfilesById">> => ({
  url: `${config.ADMIN_API_URL}/user-account/${userId}/unmute`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "PUT",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    userProfilesById: produce((draft) => {
      const targetUser = draft[userId];
      if (targetUser) {
        targetUser.is_muted = false;
      }
    }),
  },
});
