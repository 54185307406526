import { map, max } from "lodash-es";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/virtual";
import SwiperClass from "swiper/types/swiper-class";
import { postImageAltText } from "../../../helpers/postUtil";
import usePostVoteActions from "../../../hooks/usePostVoteActions";
import useWindowSize from "../../../hooks/useWindowSize";
import { PostsAPI } from "../../../typings/API-V2";
import { Box, BoxProps, ImgixBackgroundProps, Text } from "../../../UI";
import useScreenSizeValue from "../../../UI/utils/useScreenSizeValue";
import ScreenSize from "../../Layout/ScreenSize";
import NavigationButton from "../../Swiper/Navigation/NavigationButton";
import PostImageContent from "./PostImageContent";

export interface PostImagesProps {
  backgroundProps?: Omit<ImgixBackgroundProps, "src">;
  containerProps?: BoxProps;
  maxAspectRatio?: number;
  maxHeight?: number;
  maxWidth?: number;
  post: PostsAPI.ImagePost;
}

const PostImages: React.FC<PostImagesProps> = ({
  backgroundProps,
  containerProps,
  maxAspectRatio,
  maxHeight,
  maxWidth,
  post,
}) => {
  const { upVote } = usePostVoteActions(post);
  const [tagsVisible, setTagsVisible] = useState<boolean>(false);

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [realActiveIndex, setRealActiveIndex] = useState<number | null>(null);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [isBeginning, setIsBeginning] = useState<boolean>(false);

  const onSwiper = useCallback<NonNullable<SwiperProps["onSwiper"]>>((swiper) => {
    setSwiper(swiper);
    setRealActiveIndex(swiper.realIndex);
  }, []);

  const { innerWidth: windowWidth } = useWindowSize();

  /** Generate the dimensions of all the slide images and key them by image.id */
  const imageDimensions = useMemo<Record<number, { height: number; width: number }>>(() => {
    const maxImgWidth = Math.min(maxWidth || windowWidth, windowWidth);
    return post.images.reduce((result, image) => {
      let dimensions = {
        width: maxImgWidth,
        height: image.aspect_ratio * maxImgWidth,
      };
      if (maxHeight) {
        const maxAr = maxAspectRatio || maxHeight / maxImgWidth;
        if (image.aspect_ratio < maxAr) {
          dimensions = {
            height: image.aspect_ratio * maxImgWidth,
            width: maxImgWidth,
          };
        } else {
          dimensions = {
            height: maxHeight,
            width: maxHeight / image.aspect_ratio,
          };
        }
      }
      result[image.id] = dimensions;
      return result;
    }, {});
  }, [post.images, maxAspectRatio, windowWidth, maxHeight, maxWidth]);

  /** The height of all the swiper slides is the max height across all slides */
  const slideHeight = useMemo(() => max(map(imageDimensions, (d) => d.height)), [imageDimensions]);

  const goNext = useCallback(() => {
    if (swiper) {
      swiper.slideNext();
    }
  }, [swiper]);

  const goPrev = useCallback(() => {
    if (swiper) {
      swiper.slidePrev();
    }
  }, [swiper]);

  const swiperProps = useScreenSizeValue<SwiperProps>(
    {
      large: {
        modules: [Pagination, Virtual, Navigation],
        navigation: {
          nextEl: "swiper-nav-next",
          prevEl: "swiper-nav-prev",
        },
        pagination: true,
        virtual: true,
        style: {
          position: "relative",
        },
      },
    },
    {
      modules: [Pagination, Virtual],
      pagination: true,
      virtual: true,
      style: {
        position: "relative",
      },
    },
  );

  const onInit = useCallback<NonNullable<SwiperProps["onInit"]>>((swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, []);

  const onTransitionStart = useCallback<NonNullable<SwiperProps["onTransitionStart"]>>((swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, []);

  const onRealIndexChange = useCallback<NonNullable<SwiperProps["onRealIndexChange"]>>(
    (swiper) => setRealActiveIndex(swiper.realIndex),
    [],
  );

  return (
    <Swiper
      {...swiperProps}
      onInit={onInit}
      onSwiper={onSwiper}
      onTransitionStart={onTransitionStart}
      onRealIndexChange={onRealIndexChange}
    >
      {post.images.map((image) => (
        <SwiperSlide key={image.id} zoom={true}>
          <PostImageContent
            alt={postImageAltText(post, image.tags)}
            currentVote={post.current_vote}
            image={image}
            containerProps={containerProps}
            backgroundProps={backgroundProps}
            imageDimensions={imageDimensions[image.id]}
            slideWidth={windowWidth}
            slideHeight={slideHeight}
            tagsVisible={tagsVisible}
            setTagsVisible={setTagsVisible}
            postId={post.id}
            upVote={upVote}
          />
        </SwiperSlide>
      ))}
      <ScreenSize sizes="large">
        <>
          {!isBeginning && (
            <NavigationButton type={"prev"} className={"swiper-nav-prev"} onClick={goPrev} right="auto" left={3} />
          )}
          {!isEnd && (
            <NavigationButton type={"next"} className={"swiper-nav-next"} onClick={goNext} right={3} left="auto" />
          )}
        </>
      </ScreenSize>
      {realActiveIndex !== null && post.images.length > 1 && (
        <ImageCountBadge>
          <Text fontSize={0}>{`${realActiveIndex + 1}/${post.images.length}`}</Text>
        </ImageCountBadge>
      )}
    </Swiper>
  );
};

const ImageCountBadge = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  backdrop-filter: blur(40px);
  position: absolute;
  top: 16px;
  right: 16px;
`;
ImageCountBadge.defaultProps = {
  bg: "opaqueBgDark2",
  color: "lightGrey",
  borderRadius: 2,
  py: 1,
  px: 2,
};

export default PostImages;
