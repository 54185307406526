enum LocalStorageKey {
  APP_META = "PLUGD_APP_META",
  CURRENCY_PREFERENCE = "CURRENCY_PREFERENCE",
  DISABLE_SHIPPING_COSTS = "DISABLE_SHIPPING_COSTS",
  EXCHANGE_RATES = "EXCHANGE_RATES",
  LAST_SELECTED_SIZE = "LAST_SELECTED_SIZE",
  LAST_SHIPPING_LOCATION = "LAST_SHIPPING_LOCATION",
  PLUGD_AUTH_TOKEN = "PLUGD_AUTH_TOKEN",
  PLUGD_DEVICE_ID = "PLUGD_DEVICE_ID",
  PLUGD_DISMISSED_ANNOUNCEMENTS = "PLUGD_DISMISSED_ANNOUNCEMENTS",
  PLUGD_LOCALSTORAGE_MIGRATION_VERSION = "PLUGD_LOCALSTORAGE_MIGRATION_VERSION",
  PLUGD_PUSH_TOKEN = "PLUGD_PUSH_TOKEN",
  PLUGD_RECENTLY_VIEWED_PRODUCT_IDS = "PLUGD_RECENTLY_VIEWED_PRODUCT_IDS",
  PLUGD_RECENTLY_VIEWED_PRODUCTS = "PLUGD_RECENTLY_VIEWED_PRODUCTS", // type Product
  RECENTLY_VIEWED_PRODUCTS = "RECENTLY_VIEWED_PRODUCTS", // type GoatProduct
  REVIEW = "REVIEW",
  SIZE_PREFERENCE_UNIT = "SIZE_PREFERENCE_UNIT",
  SIZE_PREFERENCE_VALUE = "SIZE_PREFERENCE_VALUE",
  VISITS = "VISITS",
  REFERRAL_USER_ID = "REFERRAL_USER_ID",
}

export default LocalStorageKey;
