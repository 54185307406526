import { PostsAPI } from "../../typings/API-V2";
import { ImageMeta, InsertableImageMeta } from "./hooks/useImageInput";

export const isInsertableImageMeta = (meta: ImageMeta | InsertableImageMeta): meta is InsertableImageMeta => {
  return meta.insert !== null && meta.upload.isFinished === true;
};

export const imageMetaToImageInsert = (meta: InsertableImageMeta): PostsAPI.PostImageInsert => ({
  height: meta.insert.height,
  width: meta.insert.width,
  url: meta.insert.url,
  tags: meta.tags,
});
