import React from "react";
import { SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import { Box, Icon, NavLink, NavLinkProps, Text, theme } from "../../../UI";
import { TOP_NAV_HEIGHT } from "./TopNavWrapper";

export type TopNavIconLinkProps = {
  iconName: SemanticICONS;
  label?: string;
  hasNotification?: boolean;
} & NavLinkProps;

const TopNavIconLink: React.FC<TopNavIconLinkProps> = ({ iconName, label, hasNotification, ...navLinkProps }) => (
  <TopNavIconLinkWrapper activeStyle={{ color: theme.colors.black }} {...navLinkProps}>
    <Box position="relative">
      {hasNotification && <NotificationBadge bg="notificationRed" />}
      <Icon name={iconName} />
    </Box>
    {label && <IconLabel>{label}</IconLabel>}
  </TopNavIconLinkWrapper>
);

const IconLabel = styled(Text)`
  margin-top: 4px;
  font-size: 9px;
  line-height: 9px;
`;

const TopNavIconLinkWrapper = styled(NavLink)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || TOP_NAV_HEIGHT - 12}px;
  height: ${TOP_NAV_HEIGHT}px;
  color: ${({ theme }) => theme.colors.mediumGrey};
  &:hover {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

const BADGE_SIZE = 8;
const NotificationBadge = styled(Box)`
  z-index: 10;
  height: ${BADGE_SIZE}px;
  width: ${BADGE_SIZE}px;
  border-radius: ${BADGE_SIZE / 2}px;
  position: absolute;
  top: 0px;
  right: -${BADGE_SIZE / 4}px;
`;

export default TopNavIconLink;
