import React from "react";
import styled from "styled-components";
import { PostsAPI } from "../../../../typings/API-V2";
import { Grid } from "../../../../UI";
import AddImageBox from "./AddImageBox";
import PreviewImage from "./PreviewImage";

export interface ImagesPreviewProps {
  add?: () => void;
  onImageClick?: (imageIndex: number) => void;
  remove?: (imageIndex: number) => void;
  images: {
    upload?: {
      isFinished: boolean;
      error?: string;
    };
    url: string;
    tags: (PostsAPI.PostImageTagInsert | PostsAPI.PostImageTag)[] | null;
  }[];
}

const ImagesPreview: React.FC<ImagesPreviewProps> = ({ add, images, onImageClick, remove }) => {
  return (
    <GridContainer>
      {images.map((image, idx) => (
        <PreviewImage
          key={image.url}
          onClick={onImageClick ? () => onImageClick(idx) : undefined}
          remove={
            remove
              ? (e) => {
                  e.stopPropagation();
                  remove(idx);
                }
              : undefined
          }
          src={image.url}
          tags={image.tags}
          upload={image.upload}
        />
      ))}
      {add && images.length < 10 && <AddImageBox size={200} onClick={add} />}
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.space[2]}px;
`;
GridContainer.defaultProps = {
  p: 3,
};

export default ImagesPreview;
