import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useSeller from "../../hooks/useSeller";
import { PostTypeKey } from "../../typings/API-V2";
import { Box, Text } from "../../UI";
import { PostMenuContextValue } from "../PostMenu/PostMenuContext";
import CreatePostSelectorItem from "./CreatePostSelectorItem";

export interface CreateListingPostSelectorItemProps {
  setPostMenu: PostMenuContextValue["setPostMenu"];
}

const CreateListingPostSelectorItem: React.FC<CreateListingPostSelectorItemProps> = ({ setPostMenu }) => {
  const history = useHistory();
  const { stripeAccount } = useSeller();

  const onClick = useCallback(() => {
    /**
     * The user is not off the waitlist yet. Take them to the waitlist page for more information
     */
    if (!stripeAccount) {
      return history.push(`/monetization-waitlist`);
    }

    /**
     * The user is off the waitlist, but hasn't filled out their Stripe information yet. Take them to
     * the approved page with Stripe sign up link
     */
    if (!stripeAccount.details_submitted) {
      return history.push(`/monetization-waitlist/approved`);
    }

    /**
     * Take them to the post creation menu
     */
    setPostMenu(PostTypeKey.Listing);
  }, [history, stripeAccount, setPostMenu]);

  return (
    <Box position="relative">
      <CreatePostSelectorItem
        opacity={!stripeAccount ? 0.5 : undefined}
        iconName="box"
        onClick={onClick}
        title="Sell"
      />
      <BetaBadge>
        <Text fontSize={0} fontWeight={3}>
          {stripeAccount ? "beta" : "waitlist"}
        </Text>
      </BetaBadge>
    </Box>
  );
};

const BADGE_SIZE = 8;
const BetaBadge = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
`;
BetaBadge.defaultProps = {
  bg: "lightGrey",
  top: -BADGE_SIZE,
  right: -BADGE_SIZE,
  borderRadius: 2,
  py: 1,
  px: 2,
};

export default CreateListingPostSelectorItem;
