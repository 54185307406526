import React from "react";
import styled from "styled-components";
import { Icon, Link, LinkProps, Text } from "../../UI";

export interface PostLinkProps extends LinkProps {}

const PostLink: React.FC<PostLinkProps> = ({ to }) => {
  return (
    <CustomLink to={to} px={3} py={2} bg="white" borderBottom="1px solid" borderColor="lightGrey" color="black">
      <Text>{`Learn more`}</Text>
      <Icon name="chevron right" />
    </CustomLink>
  );
};

const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export default PostLink;
