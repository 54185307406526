import { Stripe as StripeNode } from "stripe";

/** typeguard to check whether the product is expanded from the api response */
export const isProductExpanded = (
  product?: string | StripeNode.Product | StripeNode.DeletedProduct,
): product is StripeNode.Product | StripeNode.DeletedProduct => {
  if (!product) {
    return false;
  }
  if (typeof product === "string") {
    return false;
  }
  return true;
};

/** typeguard to check whether the product is deleted */
export const isDeletedProduct = (
  product: StripeNode.Product | StripeNode.DeletedProduct,
): product is StripeNode.DeletedProduct => product.deleted === true;

/** typeguard to check whether the product is expanded from the api response and not deleted */
export const isLineItemProduct = (
  product?: string | StripeNode.Product | StripeNode.DeletedProduct,
): product is StripeNode.Product => isProductExpanded(product) && !isDeletedProduct(product);
