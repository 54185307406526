import { LocationDescriptor } from "history";
import React from "react";
import { isMobileFrame } from "../../config";
import useTopNavActions from "../../hooks/useTopNavActions";
import { IconButton, MobileTopNav, TopNavTitle } from "../Layout/TopNav";
import TopNavLogo from "./TopNavLogo";
import TopNavSide from "./TopNavSide";
import TopNavUseAppButton from "./TopNavUseAppButton";

export interface FeedSecondaryTopNavProps {
  title: string;
  goBackDefaultLocation?: LocationDescriptor;
}

const FeedTabSecondaryTopNav: React.FC<FeedSecondaryTopNavProps> = ({ title, goBackDefaultLocation = "/" }) => {
  const { goBack } = useTopNavActions({
    goBackDefaultLocation,
  });

  return (
    <MobileTopNav>
      <TopNavSide side="left">
        <IconButton onClick={goBack} iconName="chevron left" />
      </TopNavSide>
      {isMobileFrame ? <TopNavTitle>{title}</TopNavTitle> : <TopNavLogo />}
      {/* spacer until we figure out what to put where */}
      <TopNavSide side="right">
        <TopNavUseAppButton />
      </TopNavSide>
    </MobileTopNav>
  );
};

export default FeedTabSecondaryTopNav;
