import { reduce } from "lodash-es";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CometChatState } from "../reducers/cometChatReducer";
import { RootState } from "../store";

type UseUnreadMessageCounts = () => {
  total: number;
  users: number;
  groups: number;
};

/**
 * returns the unread message counts for all conversations, users, and groups
 * @returns { total: number; users: number; groups: number }
 */
const useUnreadMessageCounts: UseUnreadMessageCounts = () => {
  const { unreadMessageCounts } = useSelector<RootState, CometChatState>(({ cometChat }) => cometChat);
  const unreadUserMessagesCount = useMemo(
    () => reduce(unreadMessageCounts.users, (result, count) => result + count, 0),
    [unreadMessageCounts.users],
  );
  const unreadGroupMessagesCount = useMemo(
    () => reduce(unreadMessageCounts.groups, (result, count) => result + count, 0),
    [unreadMessageCounts.groups],
  );
  const totalUnreadMessagesCount = unreadUserMessagesCount + unreadGroupMessagesCount;

  return useMemo(
    () => ({
      total: totalUnreadMessagesCount,
      users: unreadUserMessagesCount,
      groups: unreadGroupMessagesCount,
    }),
    [totalUnreadMessagesCount, unreadUserMessagesCount, unreadGroupMessagesCount],
  );
};

export default useUnreadMessageCounts;
