import { Location } from "history";
import { some } from "lodash-es";
import { isMobileFrame } from "../config";

export const extractString = (parseResult?: string | string[]): string | undefined => {
  if (Array.isArray(parseResult)) {
    return parseResult[0];
  }

  return parseResult;
};

export const parseSearch = (search: string): URLSearchParams => {
  return new URLSearchParams(search);
};

export const getQueryVal = <T = string>(location: Location, key: string): T | null => {
  return parseSearch(location.search).get(key) as T | null;
};

export const getSize = (location: Location): string => {
  return getQueryVal(location, "size") || "all";
};

export const formatUrlProtocol = (url: string): string => {
  if (!some(["http://", "https://"], (protocol) => url.indexOf(protocol) === 0)) {
    return `http://${url}`;
  } else {
    return url;
  }
};

/**
 * formats urls to open in the WebBrowser component within the mobile app
 * @param href string
 * @returns formatted url with plugd_open=popup appended to the search params
 */
export const formatMobileBrowserHref = (href: string): string => {
  if (!href || !isMobileFrame) {
    return href;
  }
  const url = new URL(href);
  const searchParams = new URLSearchParams(url.searchParams);
  searchParams.set("plugd_open", "popup"); // to open WebBrowser component in app

  return `${url.protocol}//${url.hostname}${url.pathname}?${searchParams.toString()}`;
};
