import { filter, includes } from "lodash-es";
import { RootState } from "../store";
import { UsersAPI } from "../typings/API";

const isFollowee =
  (userId?: string, otherUserId?: string) =>
  (state: RootState): boolean => {
    if (userId && otherUserId) {
      return includes(state.entities.followeeIdsByUserId[userId], otherUserId);
    } else {
      return false;
    }
  };

const isFollower =
  (userId?: string, otherUserId?: string) =>
  (state: RootState): boolean => {
    if (userId && otherUserId) {
      return includes(state.entities.followerIdsByUserId[userId], otherUserId);
    } else {
      return false;
    }
  };

const displayStats =
  (userId?: string) =>
  (state: RootState): UsersAPI.Statistics | null => {
    if (!userId) {
      return null;
    }
    return state.entities.userStatisticsById[userId] || null;
  };

const influenceScore =
  (userId: string) =>
  (state: RootState): number => {
    const stats = displayStats(userId)(state);
    return stats?.upvotes || 0;
  };

const isFriend =
  (userId: string, otherUserid?: string) =>
  (state: RootState): boolean => {
    return isFollower(userId, otherUserid)(state) && isFollowee(userId, otherUserid)(state);
  };

const followersFromUserId =
  (userId: string) =>
  (state: RootState): UsersAPI.User[] => {
    const followerIds = state.entities.followerIdsByUserId[userId] || [];
    const followerData = followerIds.map((uid) => state.entities.followUserDataByUserId[uid]).filter((user) => user);
    return followerData;
  };

const followeesFromUserId =
  (userId: string) =>
  (state: RootState): UsersAPI.User[] => {
    const followeeIds = state.entities.followeeIdsByUserId[userId] || [];
    const followeeData = followeeIds.map((uid) => state.entities.followUserDataByUserId[uid]).filter((user) => user);
    return followeeData;
  };

const isBlocked =
  (otherUserId?: string) =>
  (state: RootState): boolean => {
    return otherUserId ? includes(state.entities.blockedUserIds, otherUserId) : false;
  };

const userCommunityRoles =
  (userId: string) =>
  (state: RootState): UsersAPI.UserCommunityRole[] => {
    return filter(state.entities.userCommunityRolesById, (role) => role.user_id === userId);
  };

const userProfileSelector = {
  userProfileFromId:
    (uid?: string) =>
    (state: RootState): UsersAPI.PublicProfile | undefined => {
      if (!uid) {
        return undefined;
      }
      return state.entities.userProfilesById[uid];
    },
  userProfileFromUsername:
    (username: string) =>
    (state: RootState): UsersAPI.PublicProfile | undefined => {
      const userId = state.entities.userProfileIdsByUsername[username];
      return userId ? state.entities.userProfilesById[userId] : undefined;
    },
  followerIdsFromUserId:
    (userId: string) =>
    (state: RootState): string[] =>
      state.entities.followerIdsByUserId[userId] || [],
  followeeIdsFromUserId:
    (userId: string) =>
    (state: RootState): string[] =>
      state.entities.followeeIdsByUserId[userId] || [],
  followeesFromUserId,
  followersFromUserId,
  isFollowee,
  isFollower,
  isFriend,
  displayStats,
  influenceScore,
  isBlocked,
  userCommunityRoles,
};

export default userProfileSelector;
