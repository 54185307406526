import React, { ReactNode, useCallback } from "react";
import styled, { css } from "styled-components";
import { Dimmer, DimmerProps, TransitionablePortal } from "../../../libs/semantic-ui";
import { Box, BoxProps, ScrollBox, ScrollBoxProps } from "../../../UI";

export interface SideMenuProps extends Omit<ScrollBoxProps, "maxWidth"> {
  close: () => void;
  closeOnDocumentClick?: boolean;
  containerProps?: BoxProps;
  footer?: ReactNode;
  isOpen: boolean;
  maxWidth?: number;
  wrapperProps?: BoxProps;
}

const transition = { duration: 400, animation: "fade right" };

const SideMenu: React.FC<React.PropsWithChildren<SideMenuProps>> = ({
  children,
  close,
  containerProps,
  footer,
  isOpen,
  maxWidth,
  wrapperProps,
  ...scrollBoxProps
}) => {
  const onDimmerClick = useCallback<Required<DimmerProps>["onClick"]>(
    (e) => {
      e.stopPropagation();
      close();
    },
    [close],
  );
  return (
    <TransitionablePortal
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      eventPool="SideMenu"
      onClose={close}
      open={isOpen}
      transition={transition}
    >
      <ContentWrapper width={300} height="100%" display="flex" flexShrink={0} justifyContent="center" {...wrapperProps}>
        <Dimmer style={{ zIndex: 60 }} active={isOpen} onClick={onDimmerClick} page />
        <Container maxWidth={maxWidth || 614} overflow="hidden" {...containerProps}>
          <ScrollBox scrollLockProps={{ isActive: isOpen }} overflowY="scroll" {...scrollBoxProps} flexGrow={1}>
            {children}
          </ScrollBox>
          {footer}
        </Container>
      </ContentWrapper>
    </TransitionablePortal>
  );
};

const Container = styled(Box)<BoxProps>``;
Container.defaultProps = {
  display: "flex",
  flexDirection: "column",
  bg: "white",
  flexGrow: 1,
};

const ContentWrapper = styled(Box)<BoxProps>`
  ${({ display }) =>
    display
      ? css`
          && {
            display: ${display} !important;
          }
        `
      : ""}
  z-index: 60;
  position: fixed;
  overflow: hidden;
`;
ContentWrapper.defaultProps = {
  bottom: 0,
  left: 0,
  right: 0,
};

export default SideMenu;
