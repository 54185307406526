import React from "react";
import { Placeholder } from "../../libs/semantic-ui";
import { Box } from "../../UI";

export interface ProductSelectorItemLoaderProps {}

const ProductSelectorItemLoader: React.FC<ProductSelectorItemLoaderProps> = () => (
  <Box
    px={3}
    py={2}
    borderBottom="1px solid"
    borderColor="lightGrey"
    display="flex"
    alignItems="center"
    width="100%"
    bg="white"
  >
    <Placeholder style={{ flexGrow: 1 }}>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
    </Placeholder>
  </Box>
);

export default ProductSelectorItemLoader;
