import React from "react";
import { DefaultToast, ToastProps as RTNToastProps } from "react-toast-notifications";
import styled from "styled-components";

export interface ToastProps extends RTNToastProps {
  /** removes the default icons set by the "appearance" prop. When this is set, the "appearance" prop has no effect */
  isBlank?: boolean;
}

const Toast: React.FC<ToastProps> = ({ ...props }) => {
  return <CustomToast {...props} />;
};

const CustomToast = styled(DefaultToast)<ToastProps>`
  &&&& {
    ${({ isBlank }) => (isBlank ? `.react-toast-notifications__toast__icon-wrapper { display: none; }` : "")}
    max-width: 100%;
  }
`;

export default Toast;
