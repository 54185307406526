/* eslint no-global-assign: 0 */
import { takeRight } from "lodash-es";
import { isMobileFrame, isProd } from "../config";
import { logMessage } from "../messages/outboundMessages";
import sendReactNativeMessage from "../messages/sendReactNativeMessage";

export type ConsoleType = "error" | "log" | "warn" | "info" | "debug" | "dir";
class PlugdLogger {
  private stack: [ConsoleType, string][] = [];
  public constructor(private originalConsole: Console, private maxStackLen: number = 200) {}
  private wrappedMethod =
    (consoleParam: ConsoleType) =>
    (...args) => {
      /** send messages to the native app for debugging */
      if (isMobileFrame && !isProd) {
        sendReactNativeMessage(
          logMessage({
            type: consoleParam,
            args,
          }),
        );
      }
      this.originalConsole[consoleParam].apply(null, args);
      this.stack.push([consoleParam, JSON.stringify(args)]);
      if (this.stack.length > this.maxStackLen) {
        this.stack = takeRight(this.stack, this.maxStackLen);
      }
    };
  public debug = this.wrappedMethod("debug");
  public dir = this.wrappedMethod("dir");
  public error = this.wrappedMethod("error");
  public info = this.wrappedMethod("info");
  public log = this.wrappedMethod("log");
  public warn = this.wrappedMethod("warn");
  public getStack = () => this.stack;
}

const newConsole = (function (originalConsole) {
  const logger = new PlugdLogger(originalConsole);
  const newCon: typeof console = {
    ...originalConsole,
    stack: logger.getStack,
    log: logger.log,
    info: logger.info,
    warn: logger.warn,
    error: logger.error,
  };
  return newCon;
})(console);

//Then redefine the old console
console = newConsole;
export {};
