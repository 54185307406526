import React from "react";
import styled from "styled-components";
import { NavLink, NavLinkProps } from "../../UI";

export interface NavTabProps extends NavLinkProps {}

const NavTab: React.FC<NavTabProps> = ({ ...navLinkProps }) => {
  return <NavTabLink activeClassName={"active"} {...navLinkProps} />;
};

const NavTabLink = styled(NavLink)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  font-weight: ${({ theme }) => theme.fontWeights[5]};
  &.active {
    border-bottom: 3px solid ${({ theme }) => theme.colors.gold};
    color: ${({ theme }) => theme.colors.black};
  }
`;
NavTabLink.defaultProps = {
  replace: true,
  py: 2,
  flexBasis: 0,
  flexGrow: 1,
  color: "darkGrey",
  borderColor: "gold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default NavTab;
