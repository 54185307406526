import { isBoolean, isNumber, omit, toString } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { ProductsAPI } from "../../typings/API";
import { PostsAPI, PostTypeKey } from "../../typings/API-V2";
import { BrandsState, PostsState, ProductsState } from "../../typings/EntitiesState";

type StandardPostTransformResponse = Pick<PostsState, "postsById"> &
  Pick<ProductsState, "productIdsBySlug" | "productsById"> &
  Pick<BrandsState, "brandsById">;

/**
 * transforms post options and normalizes them into the expected post entities”
 *
 * @param response
 * @returns
 */
const transformPosts = (posts: PostsAPI.ResponsePost[]): StandardPostTransformResponse => {
  return posts.reduce<StandardPostTransformResponse>(
    (result, post) => {
      /** normalize the product and brand data */
      if (post.product_listing) {
        const brand = post.product_listing.product.brand;
        const product: ProductsAPI.Product = {
          ...post.product_listing.product,
          brand: brand.id,
        };
        result.brandsById[brand.id] = brand;
        result.productsById[product.id] = product;
        result.productIdsBySlug[product.slug] = product.id;
        result.postsById[post.id] = {
          ...omit(post, "product_listing"),
          product_listing: {
            ...omit(post.product_listing, "product"),
            product_id: post.product_listing.product.id,
          },
        };
        return result;
      }

      /** postsById */
      result.postsById[post.id] = omit(post, "listing_product");

      return result;
    },
    {
      brandsById: {},
      postsById: {},
      productIdsBySlug: {},
      productsById: {},
    },
  );
};

export const getPost = (postId: number): QueryConfig<StandardPostTransformResponse> => ({
  url: `${config.API_V2_URL}/posts/${postId}`,
  meta: {
    includeToken: true,
  },
  options: {
    headers: STANDARD_HEADERS,
  },
  transform: (response: PostsAPI.GetPostResponse) => transformPosts([response.data.post]),
  update: {
    brandsById: shallowObjectMerge,
    postsById: shallowObjectMerge,
    productIdsBySlug: shallowObjectMerge,
    productsById: shallowObjectMerge,
  },
});

export interface GetPostsOptions {
  author_follow_status?: PostsAPI.AuthorFollowStatus;
  community_id?: number;
  created_at?: string;
  for_you?: boolean;
  has_image?: boolean;
  is_thread?: boolean;
  limit?: number;
  offset?: number;
  order_by?: string;
  sort_by?: string;
  theme_key?: string;
  type?: PostTypeKey;
  username?: string;
}
export interface GetPostsQueryOptions {
  force: boolean;
}
export const getPosts = (
  options: GetPostsOptions,
  queryOptions?: GetPostsQueryOptions,
): QueryConfig<StandardPostTransformResponse> => {
  const searchParams = new URLSearchParams({
    order_by: options.order_by || "desc",
    sort_by: options.sort_by || "created_at",
  });
  if (options.author_follow_status) {
    searchParams.set("author_follow_status", options.author_follow_status);
  }
  if (options.for_you) {
    searchParams.set("for_you", toString(options.for_you));
  }
  if (options.created_at) {
    searchParams.set("created_at", options.created_at);
  }
  if (isBoolean(options.has_image)) {
    searchParams.set("has_image", toString(options.has_image));
  }
  if (isBoolean(options.is_thread)) {
    searchParams.set("is_thread", toString(options.is_thread));
  }
  if (isNumber(options.limit)) {
    searchParams.set("limit", toString(options.limit));
  }
  if (isNumber(options.offset)) {
    searchParams.set("offset", toString(options.offset));
  }
  if (options.theme_key) {
    searchParams.set("theme_key", options.theme_key);
  }
  if (options.type) {
    searchParams.set("type", options.type);
  }
  if (options.username) {
    searchParams.set("username", options.username);
  }
  if (options.community_id) {
    searchParams.set("community_id", toString(options.community_id));
  }
  const qs = searchParams.toString();
  return {
    url: `${config.API_V2_URL}/posts?${qs}`,
    force: queryOptions?.force,
    meta: {
      includeToken: true,
    },
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: PostsAPI.GetPostsResponse) => transformPosts(response.data.posts),
    update: {
      brandsById: shallowObjectMerge,
      postsById: shallowObjectMerge,
      productIdsBySlug: shallowObjectMerge,
      productsById: shallowObjectMerge,
    },
  };
};

export const createPost = (insertObj: PostsAPI.PostInsert): QueryConfig<StandardPostTransformResponse> => {
  const searchParams = new URLSearchParams();
  return {
    url: `${config.API_V2_URL}/posts?${searchParams.toString()}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    body: insertObj,
    transform: (response: PostsAPI.CreatePostResponse) => transformPosts([response.data.post]),
    update: {
      brandsById: shallowObjectMerge,
      postsById: shallowObjectMerge,
      productIdsBySlug: shallowObjectMerge,
      productsById: shallowObjectMerge,
    },
  };
};

/**
 *
 * @param postId
 * @param patchObj
 * @returns
 */
export type PatchPostOptions = PostsAPI.PostPatch & { id: number };
export const patchPost = (patchObj: PatchPostOptions): QueryConfig<StandardPostTransformResponse> => {
  return {
    url: `${config.API_V2_URL}/posts/${patchObj.id}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "PATCH",
      headers: STANDARD_HEADERS,
    },
    body: patchObj,
    transform: (response: PostsAPI.PatchPostResponse) => transformPosts([response.data.post]),
    update: {
      brandsById: shallowObjectMerge,
      postsById: shallowObjectMerge,
      productIdsBySlug: shallowObjectMerge,
      productsById: shallowObjectMerge,
    },
  };
};

interface GetTaggedProductPostsOptions {
  limit?: number;
  offset?: number;
  order_by?: "asc" | "desc";
  sort_by?: PostsAPI.SortBy;
}
export const getTaggedProductPosts = (
  productId: number,
  options?: GetTaggedProductPostsOptions,
  queryOptions?: { force: boolean },
): QueryConfig<StandardPostTransformResponse> => {
  const searchParams = new URLSearchParams({
    order_by: options?.order_by || "desc",
    sort_by: options?.sort_by || "best",
  });
  if (options?.limit) {
    searchParams.set("limit", toString(options.limit));
  }
  if (options?.offset) {
    searchParams.set("offset", toString(options.offset));
  }
  const qs = searchParams.toString();
  return {
    url: `${config.API_V2_URL}/products/${productId}/tagged-posts?${qs}`,
    meta: {
      includeToken: true,
    },
    force: queryOptions?.force || false,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: PostsAPI.GetTaggedProductPostsResponse) => transformPosts(response.data.posts),
    update: {
      brandsById: shallowObjectMerge,
      postsById: shallowObjectMerge,
      productIdsBySlug: shallowObjectMerge,
      productsById: shallowObjectMerge,
    },
  };
};

export const archivePost = (postId: number): QueryConfig<PostsState> => {
  return {
    url: `${config.API_V2_URL}/posts/${postId}/archive`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (response: PostsAPI.ArchivePostResponse) => transformPosts([response.data.post]),
    update: {
      postsById: shallowObjectMerge,
    },
  };
};

export const unarchivePost = (postId: number): QueryConfig<PostsState> => {
  return {
    url: `${config.API_V2_URL}/posts/${postId}/unarchive`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (response: PostsAPI.UnarchivePostResponse) => transformPosts([response.data.post]),
    update: {
      postsById: shallowObjectMerge,
    },
  };
};

export const hidePost = (postId: number): QueryConfig<PostsState> => {
  return {
    url: `${config.API_V2_URL}/posts/${postId}/hide`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (response: PostsAPI.ArchivePostResponse) => transformPosts([response.data.post]),
    update: {
      postsById: shallowObjectMerge,
    },
  };
};

export const unhidePost = (postId: number): QueryConfig<PostsState> => {
  return {
    url: `${config.API_V2_URL}/posts/${postId}/unhide`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (response: PostsAPI.UnarchivePostResponse) => transformPosts([response.data.post]),
    update: {
      postsById: shallowObjectMerge,
    },
  };
};

export const pinPost = (postId: number): QueryConfig<PostsState> => {
  return {
    url: `${config.API_V2_URL}/posts/${postId}/pin`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (response: PostsAPI.PinPostResponse) => transformPosts([response.data.post]),
    update: {
      postsById: shallowObjectMerge,
    },
  };
};

export const unpinPost = (postId: number): QueryConfig<PostsState> => {
  return {
    url: `${config.API_V2_URL}/posts/${postId}/unpin`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (response: PostsAPI.UnpinPostResponse) => transformPosts([response.data.post]),
    update: {
      postsById: shallowObjectMerge,
    },
  };
};
