import React from "react";
import { Box } from "../../UI";

export interface RefreshMessageContentWrapperProps {}

const RefreshMessageContentWrapper: React.FC<React.PropsWithChildren<RefreshMessageContentWrapperProps>> = ({
  children,
}) => (
  <Box py={3} display="flex" alignItems="center" justifyContent="center" color="darkGrey">
    {children}
  </Box>
);

export default RefreshMessageContentWrapper;
