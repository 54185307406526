import { keyBy } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { ProductListingsAPI } from "../../typings/API";
import { ProductAttributesState } from "../../typings/EntitiesState";

export interface GetProductAttributesOptions {
  limit?: number;
  offset?: number;
  keys?: string[];
}

export const listProductAttributes = (options?: GetProductAttributesOptions): QueryConfig<ProductAttributesState> => {
  const searchParams = new URLSearchParams({});
  if (options?.keys) {
    options.keys.forEach((id) => {
      searchParams.append("keys", id);
    });
  }
  const qs = searchParams.toString();

  return {
    url: `${config.API_URL}/product-attributes?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: ProductListingsAPI.ListProductAttributesResponse) => ({
      productAttributesByKey: keyBy(response.data.attributes, (a) => a.key),
    }),
    update: {
      productAttributesByKey: shallowObjectMerge,
    },
  };
};
