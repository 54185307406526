import React from "react";

export interface ExperimentValues {
  [experimentId: string]: string;
}

export interface ExperimentContextValue {
  variantsById?: ExperimentValues;
  updateExperiments?: () => void;
}

const ExperimentContext = React.createContext<ExperimentContextValue>({});

export default ExperimentContext;
