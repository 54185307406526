import React from "react";
import { useHistory } from "react-router";
import { EntitlementID } from "../../../enums/RevenueCat";
import useEntitlementCallback from "../../../hooks/useEntitlementCallback";
import { Text } from "../../../UI";
import ScreenSize from "../../Layout/ScreenSize";
import NavTabs, { DEFAULT_NAV_TABS_HEIGHT, NavTab } from "../../NavTabs";
import PlugdGoldBadge from "../../PlugdGoldBadge";

export const HEIGHT = DEFAULT_NAV_TABS_HEIGHT;

export interface DiscoverNavTabsProps {}

const DropsNavTabs: React.FC<DiscoverNavTabsProps> = () => {
  const history = useHistory();
  const handleRestockRedirect = useEntitlementCallback(
    EntitlementID.GOLD,
    (e: Event) => {
      e.preventDefault();
      history.push("/restocks");
    },
    [],
  );

  return (
    <ScreenSize sizes={["small", "medium"]}>
      <NavTabs fixed>
        <NavTab to="/release-calendar">
          {({ isActive }) => <Text as={isActive ? "h1" : undefined}>Releases</Text>}
        </NavTab>
        <NavTab to="/raffles">{({ isActive }) => <Text as={isActive ? "h1" : undefined}>Raffles</Text>}</NavTab>
        <NavTab to="/restocks" onClick={handleRestockRedirect} position="relative">
          {({ isActive }) => (
            <>
              <Text as={isActive ? "h1" : undefined}>Drop Alerts</Text>
              <PlugdGoldBadge />
            </>
          )}
        </NavTab>
      </NavTabs>
    </ScreenSize>
  );
};

export default DropsNavTabs;
