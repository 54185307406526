import React from "react";
import { Box, PlaceholderBox } from "../../UI";
import { SelectItem } from "./SearchItem";

export interface SearchItemPlaceholderProps {}

const SearchItemPlaceholder: React.FC<SearchItemPlaceholderProps> = () => {
  return (
    <SelectItem as={Box} mb={2}>
      <PlaceholderBox height={50} width={50} />
      <Box display="flex" flexDirection="column" ml={2}>
        <PlaceholderBox height={16} width={120} />
        <PlaceholderBox height={14} width={100} mt={1} />
      </Box>
    </SelectItem>
  );
};

export default SearchItemPlaceholder;
