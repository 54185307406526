import { AdditionalUserInfo } from "@firebase/auth-types";
import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import config from "../../config";
import plugdLogo from "../../images/plugd-wordmark-black-logo-750.png";
import { Box, Image } from "../../UI";
import SecondaryLogin from "../Auth/SecondaryLogin";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import OutboundLink from "../links/OutboundLink";
import usePostAuthentication from "./hooks/usePostAuthentication";

export interface AuthHomeProps {}

const AuthHome: React.FC<AuthHomeProps> = () => {
  const [err, setErr] = useState<string | undefined>();
  const { setAuthScreen, setInfo, onAuthenticated } = useContext(AuthScreensContext);
  const postAuthentication = usePostAuthentication();

  const onSubmitSuccess = useCallback(
    (additionalUserInfo?: AdditionalUserInfo | null) => {
      setInfo(null);
      if (err) {
        setErr(undefined);
      }
      postAuthentication(additionalUserInfo);
      if (additionalUserInfo?.isNewUser) {
        setAuthScreen("createUsername");
      } else {
        if (onAuthenticated) {
          onAuthenticated();
        }
        setAuthScreen(null);
        setInfo(null);
      }
    },
    [err, onAuthenticated, postAuthentication, setAuthScreen, setInfo],
  );

  return (
    <AuthHomeWrapper>
      <ImageWrapper>
        <StyledImage alt="Plugd black wordmark" className="android lazyload" data-src={plugdLogo.src} />
      </ImageWrapper>
      <Box>
        <SecondaryLogin showEmailOption={true} onSubmitSuccess={onSubmitSuccess} />
      </Box>
      <FooterWrapper mt={3}>
        <TextContainer>
          <span>By signing up, you agree to our </span>
          <OutboundLink href={config.TERMS_OF_SERVICE_URL}>Terms of Service</OutboundLink>
          <span> and </span>
          <OutboundLink href={config.PRIVACY_POLICY_URL}>Privacy Policy</OutboundLink>
        </TextContainer>
      </FooterWrapper>
    </AuthHomeWrapper>
  );
};

const StyledImage = styled(Image)`
  min-height: 0px;
  min-width: 0px;
  object-fit: contain;
`;

const TextContainer = styled(Box)`
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const AuthHomeWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
`;

const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  flex-shrink: 1;
  min-height: 0px;
  margin-bottom: auto;
`;

const FooterWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export default AuthHome;
