import React, { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from "react";
import ShareModal from "../Share/ShareModal";
import AppDownloadModal from "./AppDownloadModal";
import AppUpdateModal from "./AppUpdateModal";
import ConfirmationModal from "./ConfirmationModal";
import GlobalModalContext, { GlobalModalState } from "./GlobalModalContext";
import LoginModal from "./LoginModal";
import PlugdGoldOnboardingModal from "./PlugdGoldOnboardingModal";

export interface GlobalModalProviderProps {}

const GlobalModalProvider: React.FC<PropsWithChildren<GlobalModalProviderProps>> = ({ children }) => {
  const [modalState, setModalState] = useState<GlobalModalState | null>(null);
  const closeModal = useCallback(() => setModalState(null), []);

  const value = useMemo(
    () => ({
      open: (modalState: GlobalModalState) => setModalState(modalState),
      close: closeModal,
    }),
    [closeModal],
  );

  return (
    <GlobalModalContext.Provider value={value}>
      {getOpenModal(modalState, closeModal)}
      {children}
    </GlobalModalContext.Provider>
  );
};

const getOpenModal = (modal: GlobalModalState | null, close: () => void): ReactElement | null => {
  switch (modal?.type) {
    case "app_download":
      return <AppDownloadModal isOpen={true} close={close} {...modal.props} />;
    case "app_update":
      return <AppUpdateModal isOpen={true} close={close} {...modal.props} />;
    case "confirmation":
      return <ConfirmationModal isOpen close={close} {...modal.props} />;
    case "login":
      return <LoginModal isOpen close={close} {...modal.props} />;
    case "plugd_gold_onboarding":
      return <PlugdGoldOnboardingModal isOpen close={close} {...modal.props} />;
    case "share":
      return <ShareModal isOpen close={close} {...modal.props} />;
    default:
      return null;
  }
};

export default GlobalModalProvider;
