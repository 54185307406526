import React from "react";
import ScrollLock, { ScrollLockProps } from "react-scrolllock";
import styled from "styled-components";
import Box, { BoxProps } from "./Box";

export type ScrollBoxProps = BoxProps & {
  scrollLockProps?: ScrollLockProps;
};

const ScrollBox: React.FC<React.PropsWithChildren<ScrollBoxProps>> = ({ children, scrollLockProps, ...boxProps }) => {
  return (
    <ScrollLock {...scrollLockProps}>
      <ScrollableBox {...boxProps}>{children}</ScrollableBox>
    </ScrollLock>
  );
};

const ScrollableBox = styled(Box)`
  -webkit-overflow-scrolling: touch;
`;

export default ScrollBox;
