import React, { useCallback, useContext, useState } from "react";
import analyticsUtil, { trackEvent } from "../../helpers/analyticsUtil";
import { Message } from "../../libs/semantic-ui";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import ResetPasswordForm from "./ResetPasswordForm";

export interface ResetPasswordProps {
  onClose?: () => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ onClose }) => {
  const [err, setErr] = useState<string | undefined>();
  const { setAuthScreen, setInfo } = useContext(AuthScreensContext);

  const onSubmitSuccess = useCallback(() => {
    if (err) {
      setErr(undefined);
    }
    setInfo("Password reset link sent to your email");
    trackEvent(analyticsUtil.User.ResetPassword);
    if (onClose) {
      onClose();
    } else {
      setAuthScreen("login");
    }
  }, [setErr, err, onClose, setInfo, setAuthScreen]);

  return (
    <>
      <ResetPasswordForm onSubmitSuccess={onSubmitSuccess} onError={setErr} />
      {err && <Message error content={err} />}
    </>
  );
};

export default ResetPassword;
