import classnames from "classnames";
import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../../UI";

export interface NavTabBoxProps extends BoxProps {
  isActive: boolean;
}

const NavTabBox: React.FC<NavTabBoxProps> = ({ isActive, className, ...navLinkProps }) => {
  return <NavTabBoxWrapper className={classnames(className, { active: isActive })} {...navLinkProps} />;
};

const NavTabBoxWrapper = styled(Box)`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  font-weight: ${({ theme }) => theme.fontWeights[5]};
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
  &.active {
    border-bottom: 3px solid ${({ theme }) => theme.colors.gold};
    color: ${({ theme }) => theme.colors.black};
  }
`;
NavTabBoxWrapper.defaultProps = {
  py: 2,
  flexBasis: 0,
  flexGrow: 1,
  color: "darkGrey",
  borderColor: "gold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  role: "button",
};

export default NavTabBox;
