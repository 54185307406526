import React from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../../UI/components/Box";

export interface PillGroupContainerProps extends BoxProps {}

export const PILL_GROUP_CONTAINER_HEIGHT = 48;

const PillGroupContainer: React.FC<PillGroupContainerProps> = ({ children, ...boxProps }) => (
  <Container {...boxProps}>
    <PillContainer>{children}</PillContainer>
  </Container>
);

const Container = styled(Box)`
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
  z-index: 50;
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;
Container.defaultProps = {
  height: PILL_GROUP_CONTAINER_HEIGHT,
  bg: "white",
  width: "100%",
};

const PillContainer = styled(Box)`
  align-items: center;
  height: 100%;
  display: inline-flex;
  justify-content: flex-start;
`;

export default PillGroupContainer;
