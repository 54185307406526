import "reset-css";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Inter, 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    background-color: ${({ theme }) => theme.colors.lightGrey};
    height: 100%;
    font-size: 14px; // default font size
    overflow-y: scroll;
    overflow-x: hidden; // Need this here instead of anywhere else to not interfere with "position: sticky". Source: https://stackoverflow.com/a/54116585/4074974
    -webkit-overflow-scrolling: touch;
    line-height: normal;
  }
  #root {
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    
    ::-webkit-scrollbar-track {
      background: transparent;
    }

  }

  button {
    border: 0;
    padding: 0;
  }

  /* for react-infinite-scroll-component */
  .infinite-scroll-component__outerdiv {
    width: 100%
  }

  /* for semantic-ui */
  *, :after, :before {
    box-sizing: border-box;
  }

  /* for semantic-ui Icons component */
  i.icon, i.icons {
    height: auto;
    margin: 0 0 0 0;
  }

  /* for semantic-ui Form component */
  .ui.form {
    .field > label {
      font-weight: 600; 
    }
  }

  .ui.dimmer {
    background-color: rgba(0,0,0,.4);
  }
  
  .pushable:not(body) {
    transform: none;
  }
  .pushable:not(body) > .ui.sidebar,
  .pushable:not(body) > .fixed,
  .pushable:not(body) > .pusher:after {
    position: fixed;
  }
  .pushable:not(body) > .pusher {
    overflow: initial;
  }
  /* override this to fix "position: sticky" components */
  .pushable {
    overflow-x: initial;
  }


  /* semantic comments */
  .ui.comments .comment .comments {
    padding: 0 0 0 1em;
    margin-top: 1em;
  }

  .ui.comments .comment .avatar {
    display: block;
    width: 24px;
    height: auto;
    float: left;
    margin: 0 0 0;
  }

  .ui.comments .comment .avatar img, .ui.comments .comment img.avatar {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  .ui.comments .comment > .avatar~.content {
    margin-left: 32px;
  }

  a {
    color: inherit;
    text-decoration: none;
    
    &:hover {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
  }
`;

export default GlobalStyle;
