import immer from "immer";
import Cookies from "js-cookie";
import { isBoolean, takeRight } from "lodash-es";
import ActionType from "../enums/ActionType";
import CookieName from "../enums/CookieName";
import LocalStorageKey from "../enums/LocalStorageKey";
import * as localStorageUtil from "../helpers/localStorageUtil";
import { ActionValue } from "../helpers/thunkUtil";
import { ExchangeRates } from "../typings/API";
import initialRecentlyViewedProductIds from "./utils/initialRecentlyViewedProductIds";

// todo: move product related state to its own reducer
export interface AppMeta {
  productLaunchFollowPopoverSeen: boolean;
}

export interface AppStackState {
  scrollY: number;
  pathname: string;
  search?: string;
}

export type StackName = "home" | "discover";

export interface AppState {
  initializedAt: Date;
  dismissedAnnouncements: Set<number>;
  historyIdx: number;
  plugdReferrerId: string | null;
  searchOpen: boolean;
  sideMenuOpen: boolean;
  recentlyViewedProductIds: number[];
  appMeta: AppMeta;
  stack: Record<StackName, AppStackState>;
}

export interface ExchangeRatesWithMeta {
  updated_at: string; // date string
  value: ExchangeRates;
}

const initialState: AppState = {
  initializedAt: new Date(),
  dismissedAnnouncements: new Set(
    localStorageUtil.getJsonItem<number[]>(LocalStorageKey.PLUGD_DISMISSED_ANNOUNCEMENTS) || [],
  ),
  historyIdx: 0,
  plugdReferrerId: Cookies.get(CookieName.PlugdReferrerId) || null,
  recentlyViewedProductIds: initialRecentlyViewedProductIds,
  searchOpen: false,
  sideMenuOpen: false,
  appMeta: localStorageUtil.getJsonItem<AppMeta>(LocalStorageKey.APP_META) || {
    productLaunchFollowPopoverSeen: false,
  },
  stack: {
    home: {
      scrollY: 0,
      pathname: `/`,
    },
    discover: {
      scrollY: 0,
      pathname: `/discover`,
    },
  },
};

const updateRecentlyViewed = (state: AppState, action: ActionValue): AppState => {
  const updatedState = immer(state, (draft): AppState => {
    const productId = action.payload as number;
    const idx = draft.recentlyViewedProductIds.findIndex((id) => id === productId);
    if (idx !== -1) {
      draft.recentlyViewedProductIds.splice(idx, 1);
    }
    draft.recentlyViewedProductIds.unshift(productId);
    draft.recentlyViewedProductIds = takeRight(draft.recentlyViewedProductIds, 20);
    return draft;
  });

  // update local storage with new recently viewed state
  localStorageUtil.setJsonItem(
    LocalStorageKey.PLUGD_RECENTLY_VIEWED_PRODUCT_IDS,
    updatedState.recentlyViewedProductIds,
  );

  return updatedState;
};

const dismissAnnouncement = (state: AppState, action: ActionValue): AppState => {
  const announcementId = action.payload as number;
  const updatedState = immer(state, (draft) => {
    draft.dismissedAnnouncements.add(announcementId);
    return draft;
  });
  localStorageUtil.setJsonItem<number[]>(
    LocalStorageKey.PLUGD_DISMISSED_ANNOUNCEMENTS,
    Array.from(updatedState.dismissedAnnouncements),
  );
  return updatedState;
};

export default (state: AppState = initialState, action: ActionValue): AppState => {
  switch (action.type) {
    case ActionType.REPLACE_STACK_STATE: {
      return immer(state, (draft): AppState => {
        draft.stack[action.payload.key] = action.payload.state;
        return draft;
      });
    }
    case ActionType.TOGGLE_SEARCH: {
      const forcedState = action.payload as boolean | undefined;
      return immer(state, (draft): AppState => {
        draft.searchOpen = isBoolean(forcedState) ? forcedState : !draft.searchOpen;
        return draft;
      });
    }
    case ActionType.TRACK_HISTORY_INDEX: {
      return immer(state, (draft): AppState => {
        const payload: "PUSH" | "REPLACE" | "POP" = action.payload;
        if (payload === "POP") {
          draft.historyIdx = draft.historyIdx - 1;
        }
        if (payload === "PUSH") {
          draft.historyIdx = draft.historyIdx + 1;
        }
        return draft;
      });
    }
    case ActionType.UPDATE_APP_META: {
      const payload = action.payload as Partial<AppMeta>;
      localStorageUtil.setJsonItem<AppMeta>(LocalStorageKey.APP_META, {
        ...state.appMeta,
        ...payload,
      });
      return immer(state, (draft): AppState => {
        draft.appMeta = {
          ...draft.appMeta,
          ...(action.payload as Partial<AppMeta>),
        };
        return draft;
      });
    }
    case ActionType.UPDATE_RECENTLY_VIEWED: {
      return updateRecentlyViewed(state, action);
    }
    case ActionType.DISMISS_ANNOUNCEMENT: {
      return dismissAnnouncement(state, action);
    }
    case ActionType.SET_PLUGD_REFERRER_ID: {
      const referrerId = action.payload as string;
      // set cookie
      Cookies.set(CookieName.PlugdReferrerId, referrerId, { expires: 7 }); // expires in 7 days
      // set in reducer
      return immer(state, (draft) => {
        draft.plugdReferrerId = referrerId;
        return draft;
      });
    }
    case ActionType.TOGGLE_SIDE_MENU: {
      const forcedState = action.payload as boolean | undefined;
      return immer(state, (draft): AppState => {
        draft.sideMenuOpen = isBoolean(forcedState) ? forcedState : !draft.sideMenuOpen;
        return draft;
      });
    }
    default:
      return state;
  }
};
