import React, { ReactElement, useCallback } from "react";
import styled from "styled-components";
import { SizeUnit, sizeUnits } from "../../helpers/sizeConversionUtil";
import { Button, Form, Header, Modal } from "../../libs/semantic-ui";

const SizeButtonGroup = styled(Button.Group)`
  width: 100%;
`;

export interface SizeSettingsProps {
  display?: "currency" | "shipping" | "size";
  sizeUnit: SizeUnit;
  onChange: (unit: SizeUnit) => void;
}

const SizeSettings = (props: SizeSettingsProps): ReactElement | null => {
  const { display, sizeUnit, onChange } = props;

  const handleSizeUnitClick = useCallback(
    (e, data) => {
      onChange(data.value);
    },
    [onChange],
  );

  if (display && display !== "size") {
    return null;
  }

  return (
    <Modal.Content>
      <Header as="h3">Size</Header>
      <Form>
        <Form.Field>
          <label>Size Unit</label>
          <SizeButtonGroup>
            {sizeUnits.map((unit) => (
              <Button
                active={unit === sizeUnit}
                basic={unit !== sizeUnit}
                color="grey"
                key={unit}
                onClick={handleSizeUnitClick}
                value={unit}
              >
                {unit.toUpperCase()}
              </Button>
            ))}
          </SizeButtonGroup>
        </Form.Field>
      </Form>
    </Modal.Content>
  );
};

export default SizeSettings;
