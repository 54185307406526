import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ActionPromiseValue, Entities, QueryConfig, QueryState, requestAsync } from "redux-query";
import { actionPromiseToPromise } from "../helpers/reactQueryUtil";

export interface CustomActionPromiseValue<E = Entities, B = any> extends ActionPromiseValue<E> {
  body: B | undefined;
}

export type CustomActionPromise<E = Entities, B = any> = Promise<CustomActionPromiseValue<E, B>> | undefined;

interface CustomQueryState<B = any> extends QueryState {
  body?: B;
}

const initialQueryState = {
  isPending: false,
  isFinished: false,
  body: undefined,
  lastUpdated: undefined,
  queryCount: undefined,
  status: undefined,
};

const useControlledRequest = <T extends (...args: any[]) => QueryConfig, B = any, E = any>(
  cb: T,
): [CustomQueryState<B>, (...args: Parameters<T>) => CustomActionPromise<E, B> | undefined] => {
  const [queryState, setQueryState] = useState<CustomQueryState<B>>(initialQueryState);

  const dispatch = useDispatch();
  const request = useCallback(
    (...args) => {
      setQueryState({
        isPending: true,
        isFinished: false,
      });
      return actionPromiseToPromise(dispatch(requestAsync(cb(...args))) as unknown as CustomActionPromise<E, B>).then(
        (result) => {
          setQueryState({
            body: result ? result.body : undefined,
            isFinished: true,
            isPending: false,
            headers: result ? { ...result.headers } : undefined,
            status: result ? result.status : undefined,
          });
          return result;
        },
      );
    },
    [dispatch, cb],
  );

  return useMemo(() => [queryState, request], [queryState, request]);
};

export default useControlledRequest;
