import { useCallback, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import { QueryState } from "redux-query";
import { useMutation } from "redux-query-react";
import events, { trackEvent } from "../../../helpers/analyticsUtil";
import { sanitizePostPatch } from "../../../helpers/postUtil";
import { actionPromiseToPromise } from "../../../helpers/reactQueryUtil";
import { patchPost as patchPostQuery } from "../../../queries/api-v2/postQuery";
import { PostsAPI } from "../../../typings/API-V2";
import { ErrorResponse } from "../../../typings/API/common";
import { PostsById } from "../../../typings/EntitiesState";

type UsePatchPost = () => [QueryState, (postPatch: PostsAPI.PostPatch) => Promise<void>];

/**
 * Hook that takes care of post patch and subsequent success or failure
 * @returns [QueryState, (postPatch: PostsAPI.PostPatch) => Promise<void>]
 */
const usePatchPost: UsePatchPost = () => {
  const { addToast } = useToasts();
  const [queryState, patchPost] = useMutation(patchPostQuery);

  const onSubmit = useCallback(
    async (postPatch: PostsAPI.PostPatch) => {
      const sanitizedPostPatch = sanitizePostPatch(postPatch);
      const { body } = await actionPromiseToPromise<PostsById, PostsAPI.CreatePostResponse | ErrorResponse>(
        patchPost(sanitizedPostPatch),
      );

      if (!body) {
        throw new Error("Edited post not received");
      }

      if (!body.success) {
        if (body.error) {
          throw new Error(body.error);
        }
        throw new Error("Failed to edit post");
      }

      /** analytics tracking */
      trackEvent(events.Post.Edited, {
        type: postPatch.type,
      });

      addToast(`Post updated`, {
        appearance: "success",
        autoDismiss: true,
      });
    },
    [addToast, patchPost],
  );

  return useMemo(() => [queryState, onSubmit], [queryState, onSubmit]);
};

export default usePatchPost;
