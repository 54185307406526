import { compact, filter, includes, map, values } from "lodash-es";
import memoizeOne from "memoize-one";
import { RootState } from "../store";
import { UsersAPI } from "../typings/API";
import CommunitiesAPI from "../typings/API/CommunitiesAPI";

const communitySelector = {
  allCommunities: memoizeOne(() => (state: RootState): CommunitiesAPI.Community[] => {
    return values(state.entities.communitiesBySlug);
  }),
  allUnsubscribedCommunities: memoizeOne((userId) => (state: RootState): CommunitiesAPI.Community[] => {
    if (userId) {
      const subscribedSet = new Set(state.entities.subscribedCommunityIdsByUserId[userId] || []);
      return filter(values(state.entities.communitiesBySlug), (community) => !subscribedSet.has(community.id));
    } else {
      return values(state.entities.communitiesBySlug);
    }
  }),
  allModeratedCommunities: memoizeOne((userId?: string) => (state: RootState): CommunitiesAPI.Community[] => {
    if (userId) {
      const userRoles = filter(
        state.entities.userCommunityRolesById,
        (role) => role.user_id === userId && (role.role_key === "owner" || role.role_key === "moderator"),
      );
      const moderatedSet = new Set(userRoles.map((role) => role.community_id));
      return filter(values(state.entities.communitiesBySlug), (community) => moderatedSet.has(community.id));
    } else {
      return [];
    }
  }),
  userIsModerator: memoizeOne((userId, communityId) => (state: RootState): boolean => {
    if (!communityId) {
      return false;
    }
    const userModeratedCommunityIds = map(communitySelector.allModeratedCommunities(userId)(state), (c) => c.id);
    return includes(userModeratedCommunityIds, communityId);
  }),
  communityBySlug: memoizeOne((communitySlug: string) => (state: RootState): CommunitiesAPI.Community => {
    return state.entities.communitiesBySlug[communitySlug];
  }),
  userIsSubscribed: memoizeOne((userId, communityId) => (state: RootState): boolean => {
    if (!communityId) {
      return false;
    }
    const userSubscribedCommunities = state.entities.subscribedCommunityIdsByUserId[userId];
    return includes(userSubscribedCommunities, communityId);
  }),
  subscribedCommunityIds: memoizeOne((userId: string | undefined) => (state: RootState): number[] | undefined => {
    return userId ? state.entities.subscribedCommunityIdsByUserId[userId] : undefined;
  }),
  subscribedCommunities: memoizeOne((userId: string | undefined) => (state: RootState): CommunitiesAPI.Community[] => {
    if (userId) {
      const userSubscribedCommunityIds = state.entities.subscribedCommunityIdsByUserId[userId];
      if (userSubscribedCommunityIds?.length) {
        const userSubscribedCommunities = userSubscribedCommunityIds.map((id) => {
          const slug = state.entities.communitySlugById[id];
          return state.entities.communitiesBySlug[slug];
        });
        return compact(userSubscribedCommunities);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }),
  communitySubscriptionByCommunityId: memoizeOne(
    (communityId: number | undefined) =>
      (state: RootState): CommunitiesAPI.CommunitySubscription | undefined => {
        return communityId ? state.entities.userCommunitySubscriptionByCommunityId[communityId] : undefined;
      },
  ),
  communityPostThemes: memoizeOne(
    (communityId: number | undefined) =>
      (state: RootState): CommunitiesAPI.CommunityTheme[] | undefined => {
        return communityId ? state.entities.communityThemesByCommunityId[communityId] : undefined;
      },
  ),
  communityMembers: memoizeOne((communityId: number | undefined) => (state: RootState): UsersAPI.User[] | undefined => {
    return communityId ? state.entities.communityMembersByCommunityId[communityId] : undefined;
  }),
};

export default communitySelector;
