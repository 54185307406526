import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import useCurrentUserProfile from "../../hooks/useCurrentUserProfile";
import useMenuState from "../../hooks/useMenuState";
import useTopNavActions from "../../hooks/useTopNavActions";
import { UsersAPI } from "../../typings/API";
import UserActionsMenu from "../ActionsMenu/User/UserActionsMenu";
import { IconButton, MobileTopNav, TopNavTitle } from "../Layout/TopNav";
import TopNavSide from "./TopNavSide";
import TopNavUseAppButton from "./TopNavUseAppButton";

export interface UserProfileTabPrimaryTopNavProps {
  user?: UsersAPI.PublicProfile;
  goBackDefaultLocation?: string;
}

const UserProfileTabPrimaryTopNav: React.FC<UserProfileTabPrimaryTopNavProps> = ({
  user,
  goBackDefaultLocation = "/",
}) => {
  const { profile: currentUser } = useCurrentUserProfile();
  const history = useHistory();
  const { goBack } = useTopNavActions({
    goBackDefaultLocation,
  });
  const isOwner = user && currentUser?.id === user?.id;
  const { open: openActionMenu, menuProps: actionMenuProps } = useMenuState();

  const navigateToInvitePage = useCallback(() => history.push("/invite"), [history]);

  /**
   * components defined here to reduce redundancy
   */
  const backIcon = <IconButton iconName="chevron left" onClick={goBack} />;
  const inviteIcon = <IconButton iconName="add user" onClick={navigateToInvitePage} />;
  const profileTitle = (
    <TopNavTitle>{user?.full_name || (user?.username ? `@${user.username}` : `User Profile`)}</TopNavTitle>
  );
  const menuIcon = <IconButton onClick={openActionMenu} iconName="ellipsis horizontal" />;

  /**
   * case: default, when viewing someone else's profile
   */
  let content = (
    <>
      {backIcon}
      {profileTitle}
      {menuIcon}
    </>
  );

  /**
   * case: viewing your own profile after navigating from somewhere else (with back button, needs spacer on right)
   */
  if (isOwner) {
    content = (
      <>
        <TopNavSide side="left">
          {backIcon}
          {inviteIcon}
        </TopNavSide>
        {profileTitle}
        <TopNavSide side="right">
          <TopNavUseAppButton />
          {menuIcon}
        </TopNavSide>
      </>
    );
  }

  return (
    <>
      <MobileTopNav>{content}</MobileTopNav>
      {user && <UserActionsMenu {...actionMenuProps} user={user} />}
    </>
  );
};

export default UserProfileTabPrimaryTopNav;
