import React from "react";
import styled from "styled-components";
import { Box, Icon, Text } from "../../../UI";

export interface PartialMenuHeaderProps {
  close: () => void;
  title: string;
}

const PartialMenuHeader: React.FC<PartialMenuHeaderProps> = ({ title, close }) => {
  return (
    <MenuHeader>
      <Box flexBasis="10%" />
      <Box flexBasis="80%" display="flex" alignItems="center" justifyContent="center">
        <Text fontWeight={600}>{title}</Text>
      </Box>
      <Box
        role="button"
        flexBasis="10%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={close}
        style={{ cursor: "pointer" }}
      >
        <Icon name="chevron down" />
      </Box>
    </MenuHeader>
  );
};

const MenuHeader = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;
MenuHeader.defaultProps = {
  flexShrink: 0,
  py: 2,
  display: "flex",
  alignItems: "center",
};

export default PartialMenuHeader;
