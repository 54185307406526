import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";
import { ProductsAPI } from "../../../../typings/API";
import { PostsAPI } from "../../../../typings/API-V2";
import { Box, Button, Spacer, Text } from "../../../../UI";
import FullscreenMenu, { FullscreenMenuHeader, FullscreenMenuHeaderTitle } from "../../../Layout/FullscreenMenu";
import ProductSelectorMenu from "../../../ProductSelectorMenu";
import ImageTagger from "./ImageTagger";

export interface TagImageMenuProps {
  close: () => void;
  setTags: (tagsState: (PostsAPI.PostImageTagInsert | PostsAPI.PostImageTag)[] | null) => void;
  src: string;
  /** initial tags value */
  tags: (PostsAPI.PostImageTagInsert | PostsAPI.PostImageTag)[] | null;
}

const TagImageMenu: React.FC<TagImageMenuProps> = ({ close, setTags, src, tags }) => {
  /** clone and store the image meta for manipulation in the image tag menu */
  const [tempTags, setTempTags] = useImmer<(PostsAPI.PostImageTagInsert | PostsAPI.PostImageTag)[] | null>(tags);

  /**
   * This state is used to indicate that the user wants to create a new tag after clicking the image
   * and keeps track of the new tag's initial position until the user selects which product to associate
   * with the new tag
   */
  const [initializedTag, setInitializedTag] = useState<Pick<PostsAPI.PostImageTagInsert, "x" | "y"> | null>(null);

  const addTag = useCallback(
    (newTag: PostsAPI.PostImageTagInsert) => {
      setTempTags((draft) => {
        if (!draft) {
          /** add first tag */
          draft = [newTag];
          return draft;
        }

        /** update a tag if it already exists */
        const existingTagIndex = draft.findIndex((t) => t.product.id === newTag.product.id);
        if (existingTagIndex > -1) {
          draft[existingTagIndex] = { ...draft[existingTagIndex], ...newTag };
          return draft;
        }

        /** add tag */
        if (draft) {
          draft.push(newTag);
          return draft;
        }
      });
    },
    [setTempTags],
  );

  /**
   * When the user selects a product to assign to the new tag
   * add the tag with the product information
   */
  const onProductSelect = useCallback(
    (product: ProductsAPI.Product) => {
      if (initializedTag) {
        addTag({
          product: {
            name: product.name,
            id: product.id,
          },
          x: initializedTag.x,
          y: initializedTag.y,
        });
      }
    },
    [addTag, initializedTag],
  );

  /**
   * When a product is selected to associate with a new tag,
   * close the product selection menu by setting the initialized tag to null
   */
  const closeProductSelectorMenu = useCallback(() => setInitializedTag(null), []);

  const onSubmit = useCallback(() => {
    setTags(tempTags);

    close();
  }, [close, setTags, tempTags]);

  return (
    <>
      {Boolean(initializedTag) && (
        <ProductSelectorMenu isOpen={true} close={closeProductSelectorMenu} onSelect={onProductSelect} />
      )}
      <FullscreenMenu
        isOpen={true}
        close={close}
        overflowY="inherit"
        header={
          <FullscreenMenuHeader>
            <Spacer mr={50} />
            <FullscreenMenuHeaderTitle>Tag Products</FullscreenMenuHeaderTitle>
            <Button onClick={onSubmit} size="small" variant="primary" width={50}>
              Done
            </Button>
          </FullscreenMenuHeader>
        }
      >
        <ImageTagger initializeTag={setInitializedTag} src={src} setTempTags={setTempTags} tempTags={tempTags} />
        <TaggingInstructionsWrapper py={3}>
          <Text fontSize={1} color="darkGrey">
            {`Tap photo to tag products`}
          </Text>
        </TaggingInstructionsWrapper>
      </FullscreenMenu>
    </>
  );
};

const TaggingInstructionsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default TagImageMenu;
