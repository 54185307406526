import React from "react";
import styled, { DefaultTheme, StyledComponentPropsWithRef, ThemedStyledProps } from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from "styled-system";

export namespace ButtonType {
  export type Size = "big" | "medium" | "small" | "tiny";
  export interface SizeStyle {
    fontSize: number;
    px: number;
    [key: string]: any;
  }

  export type SizeVariants = Record<Size, SizeStyle>;

  export type Variant = "primary" | "secondary" | "tertiary" | "disabled" | "frosted";
  export interface VariantStyle {
    [key: string]: any;
  }

  export type VariantVariants = Record<Variant, VariantStyle>;
}

export interface CustomButtonProps {
  variant?: ButtonType.Variant;
  size?: ButtonType.Size;
  fluid?: boolean;
  flat?: boolean;
  /** https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type */
  type?: "submit" | "reset" | "button";
  form?: string;
  disabled?: boolean;
}

export type ButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  CustomButtonProps &
  BorderProps<DefaultTheme> &
  ShadowProps<DefaultTheme> &
  ColorProps<DefaultTheme> &
  FlexboxProps<DefaultTheme> &
  LayoutProps<DefaultTheme> &
  PositionProps<DefaultTheme> &
  SpaceProps<DefaultTheme> &
  TypographyProps<DefaultTheme>;

const sizeVariants = (): ButtonType.SizeVariants => ({
  big: {
    height: 56,
    borderRadius: 56 / 2,
    fontSize: 15,
    px: 56 / 2,
  },
  medium: {
    height: 48,
    borderRadius: 48 / 2,
    fontSize: 14,
    px: 48 / 2,
    lineHeight: 1.4,
  },
  small: {
    height: 34,
    borderRadius: 34 / 2,
    fontSize: 11,
    px: 34 / 2,
    lineHeight: 1.4,
  },
  tiny: {
    height: 24,
    borderRadius: 24 / 2,
    fontSize: 10,
    px: 24 / 2,
    lineHeight: 1,
  },
});

const variants = (
  props: ThemedStyledProps<StyledComponentPropsWithRef<"button"> & ButtonProps, DefaultTheme>,
): ButtonType.VariantVariants => {
  return {
    primary: {
      color: props.theme.colors.lightGrey,
      background: props.theme.colors.goldRed,
      "&:hover": {
        color: props.theme.colors.lightGrey,
      },
    },
    secondary: {
      color: props.theme.colors.lightGrey,
      background: props.theme.colors.black,
      "&:hover": {
        color: props.theme.colors.lightGrey,
      },
    },
    tertiary: {
      color: props.theme.colors.black,
      background: props.theme.colors.white,
      "&:hover": {
        color: props.theme.colors.black,
        "box-shadow": "none",
      },
      border: `1px solid ${props.theme.colors.mediumGrey}`,
      "box-shadow": "none",
    },
    frosted: {
      color: props.theme.colors.lightGrey,
      background: props.theme.colors.opaqueBgDark,
      "&:hover": {
        background: props.theme.colors.opaqueBgDark,
        "box-shadow": "none",
      },
    },
    disabled: {
      color: props.theme.colors.lightGrey,
      background: props.theme.colors.mediumGrey,
      "&:hover": {
        color: props.theme.colors.lightGrey,
      },
    },
  };
};

const Button = styled.button<ButtonProps>`
  cursor: pointer;
  box-shadow: ${(props) => (props.flat ? "none" : "0px 10px 20px rgba(0, 0, 0, 0.15)")};
  transition: box-shadow 0.25s ease-in-out;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: ${(props) => (props.flat ? "none" : "0px 20px 40px rgba(0, 0, 0, 0.25)")};
  }

  ${() =>
    variant({
      prop: "size",
      variants: sizeVariants(),
    })}

  ${(props) =>
    variant({
      variants: variants(props),
    })}

  ${(props) =>
    props.fluid
      ? {
          width: "100%",
        }
      : {}}

  ${border}
  ${shadow}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${typography}
`;

Button.defaultProps = {
  tabIndex: 0,
  size: "medium",
  flexShrink: 0,
};

export default Button;
