import { RootState } from "../store";
import { QueriesState, ResponseHeaders, ResponseStatus, Url } from "redux-query";

// needed since not separated out in the type definitions
interface QueryState {
  headers?: ResponseHeaders;
  isFinished: boolean;
  isMutation: boolean;
  isPending: boolean;
  lastUpdated?: number;
  queryCount: number;
  status?: ResponseStatus;
  url: Url;
}

const queriesSelector = {
  queries:
    () =>
    (state: RootState): QueriesState =>
      state.queries,
  query:
    (queryKey: string) =>
    (state: RootState): QueryState =>
      state.queries[queryKey],
};

export default queriesSelector;
