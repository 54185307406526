import { useCallback, useMemo, useState } from "react";

const UPVOTE_DISPLAY_TIME = 700;
const UPVOTE_TRANSITION_TIME = 600;

const useUpvoteOverlay = () => {
  const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
  const [overlayContainerVisible, setOverlayContainerVisible] = useState<boolean>(false);
  const [timeoutRefs, setTimeoutRefs] = useState<NodeJS.Timeout[]>([]);

  const addTimeout = useCallback(
    (timeoutRef: NodeJS.Timeout) => setTimeoutRefs(timeoutRefs.concat([timeoutRef])),
    [timeoutRefs],
  );

  /**
   * need to show different components at different times for animation to work properly with pinch to zoom
   */
  const showUpvoteOverlay = useCallback(
    (vote?: number | null) => {
      if (vote !== 1) {
        setOverlayContainerVisible(true);
        addTimeout(
          setTimeout(() => {
            setOverlayVisible(true);
            addTimeout(
              setTimeout(() => {
                setOverlayVisible(false);
                addTimeout(setTimeout(() => setOverlayContainerVisible(false), UPVOTE_TRANSITION_TIME));
              }, UPVOTE_DISPLAY_TIME),
            );
          }, 0),
        );
      }
    },
    [addTimeout],
  );

  return useMemo(
    () => ({
      showUpvoteOverlay,
      overlayVisible,
      overlayContainerVisible,
      UPVOTE_TRANSITION_TIME,
    }),
    [showUpvoteOverlay, overlayVisible, overlayContainerVisible],
  );
};

export default useUpvoteOverlay;
