import { useMemo } from "react";
import createActions, { Actions } from "../actions/createActions";
import useThunkDispatch from "./useThunkDispatch";

const useActions = (): Actions => {
  const dispatch = useThunkDispatch();
  return useMemo((): Actions => createActions(dispatch), [dispatch]);
};

export default useActions;
