import React from "react";
import Box, { BoxProps } from "../Box";
import { UserListSelectItemWrapper } from "./UserListSelectItem";
import UserMetaPlaceholder from "./UserMetaPlaceholder";

export interface UserListSelectItemPlaceholderProps extends BoxProps {}

const UserListSelectItemPlaceholder: React.FC<UserListSelectItemPlaceholderProps> = ({ ...wrapperProps }) => {
  return (
    <UserListSelectItemWrapper as={Box} {...wrapperProps}>
      <UserMetaPlaceholder />
    </UserListSelectItemWrapper>
  );
};

export default UserListSelectItemPlaceholder;
