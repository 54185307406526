import { AuthError } from "@firebase/auth-types";
import { sendPasswordResetEmail } from "firebase/auth";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useAuth } from "reactfire";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import AuthFields from "../../enums/AuthFields";
import FirebaseErrors from "../../enums/FirebaseErrors";
import { authFieldToLabel } from "../../helpers/authFieldsUtil";
import { Form, FormProps, InputOnChangeData, Loader } from "../../libs/semantic-ui";
import { Box, Button, FormInput } from "../../UI";

export interface ResetPasswordFields {
  password: string;
}

interface CustomResetPasswordFormProps {
  onSubmitSuccess?: (e: React.FormEvent<HTMLFormElement>, data: FormProps, fields: ResetPasswordFields) => void;
  onError: (error: string | undefined) => void;
}

export type ResetPasswordFormProps = CustomResetPasswordFormProps & FormProps;

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmitSuccess, onError, ...formProps }) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState();
  const [fields, setFields] = useState<ResetPasswordFields>({
    password: "",
  });

  const handleChange = useCallback(
    (e: ChangeEvent, data: InputOnChangeData): void => {
      const { name, value } = data;
      setFields({
        ...fields,
        [name]: value,
      });
    },
    [fields],
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>, data: FormProps) => {
      e.preventDefault();
      setLoading(true);
      try {
        await sendPasswordResetEmail(auth, fields.password);
        if (onSubmitSuccess) {
          onSubmitSuccess(e, data, fields);
        }
      } catch (error) {
        const { code, message } = error as AuthError;
        if (onError) {
          onError(FirebaseErrors[code] || message);
        }
        setErr(FirebaseErrors[code] || message);
      } finally {
        setLoading(false);
      }
    },
    [auth, fields, onError, onSubmitSuccess],
  );

  const canSubmit = useMemo(() => {
    return isEmail(fields.password);
  }, [fields]);

  return (
    <Form style={{ width: "100%" }} {...formProps} onSubmit={handleSubmit} error={Boolean(err)}>
      <FormInput
        fluid
        name={AuthFields.PASSWORD}
        onChange={handleChange}
        placeholder={authFieldToLabel[AuthFields.PASSWORD]}
        type="password"
        value={fields.password}
        autoComplete="password"
      />
      <ResetButton variant={canSubmit ? "primary" : "disabled"} type="submit" size="big" disabled={!canSubmit}>
        <ButtonContent>
          {loading ? <Loader inverted active inline="centered" size="tiny" /> : "Reset password"}
        </ButtonContent>
      </ResetButton>
    </Form>
  );
};

const ButtonContent = styled(Box)`
  height: 1.1em;
`;

ButtonContent.defaultProps = {
  justifyContent: "center",
  display: "flex",
};

const ResetButton = styled(Button)`
  width: 100%;
`;

export default ResetPasswordForm;
