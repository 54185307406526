import { AdditionalUserInfo } from "@firebase/auth-types";
import { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import analyticsUtil, { trackEvent } from "../../../helpers/analyticsUtil";

export interface UsePostAuthenticationOptions {
  skipToast?: boolean;
}

export type PostAuthenticationHandler = (additionalUserInfo?: AdditionalUserInfo | null) => void;

export type UsePostAuthentication = (options?: UsePostAuthenticationOptions) => PostAuthenticationHandler;

/**
 * callback to be used after a user signs up or logs in
 * @returns
 */
const usePostAuthentication: UsePostAuthentication = (options = { skipToast: false }) => {
  const { skipToast } = options;
  const { addToast } = useToasts();

  return useCallback<PostAuthenticationHandler>(
    (additionalUserInfo) => {
      // notify user with toast modal
      if (!skipToast) {
        if (additionalUserInfo?.isNewUser) {
          addToast(`You're all set!`, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast("Welcome back!", {
            appearance: "success",
            autoDismiss: true,
          });
        }
      }

      // send analytics
      if (additionalUserInfo?.isNewUser) {
        trackEvent(analyticsUtil.User.SignedUp, {
          provider_id: additionalUserInfo?.providerId,
        });
      } else {
        trackEvent(analyticsUtil.User.SignedIn, {
          provider_id: additionalUserInfo?.providerId,
        });
      }
    },
    [addToast, skipToast],
  );
};

export default usePostAuthentication;
