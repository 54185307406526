import React from "react";
import styled from "styled-components";
import { Grid } from "../../UI";
import PlugdGoldFeatureCard from "./PlugdGoldFeatureCard";

export interface PlugdGoldFeaturesProps {}

const PlugdGoldFeatures: React.FC<PlugdGoldFeaturesProps> = () => {
  return (
    <Container>
      <PlugdGoldFeatureCard
        href="https://getplugd.com/blog/live-drop-alerts/"
        iconName="bolt"
        description="Custom Live Drop Alerts"
      />
      <PlugdGoldFeatureCard
        href="https://getplugd.com/blog/feature-exclusive-communities/"
        iconName="group"
        description="Exclusive Communities"
      />
      <PlugdGoldFeatureCard
        href="https://getplugd.com/blog/feature-track-and-filter-raffles/"
        iconName="ticket"
        description="Track and Filter Raffles"
      />
      <PlugdGoldFeatureCard
        href="https://getplugd.com/blog/new-feature-sneaker-availability-price-drop-alerts/"
        iconName="angle double down"
        description="Price-drop Alerts"
      />
      <PlugdGoldFeatureCard
        href="https://getplugd.com/blog/feature-live-chat/"
        isComingSoon={true}
        iconName="chat"
        description="Live Chat"
      />
      <PlugdGoldFeatureCard isComingSoon={true} iconName="wrench" description="More To-be Announced..." />
    </Container>
  );
};

const Container = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.space[3]}px;

  ${({ theme }): string => theme.mediaQueries.small} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.space[3]}px;
  }
`;

export default PlugdGoldFeatures;
