import React from "react";
import Box, { BoxProps } from "../Box";
import { CommunityListSelectItemWrapper } from "./CommunityListSelectItem";
import CommunityMetaPlaceholder from "./CommunityMetaPlaceholder";

export interface CommunityListSelectItemPlaceholderProps extends BoxProps {}

const CommunityListSelectItemPlaceholder: React.FC<CommunityListSelectItemPlaceholderProps> = ({ ...wrapperProps }) => {
  return (
    <CommunityListSelectItemWrapper as={Box} {...wrapperProps}>
      <CommunityMetaPlaceholder />
    </CommunityListSelectItemWrapper>
  );
};

export default CommunityListSelectItemPlaceholder;
