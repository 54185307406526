import React, { useCallback } from "react";
import styled from "styled-components";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import useNavigateToProduct from "../../hooks/useNavigateToProduct";
import { PostsAPI } from "../../typings/API-V2";
import { Box, BoxProps } from "../../UI";
import ImageTag from "../ImageTag";

export interface PostImageTagProps {
  tag: PostsAPI.PostImageTag;
  postId: number;
  x: number;
  y: number;
}

const PostImageTag: React.FC<PostImageTagProps> = ({ x, y, postId, tag }) => {
  const toProduct = useNavigateToProduct();
  const onClick = useCallback(() => {
    trackEvent(events.ImageTag.Clicked, {
      product_id: tag.product.id,
      post_image_id: tag.post_image_id,
    });
    toProduct(tag.product.slug, {
      state: {
        // this state is required to track how users get to a product and pay back authors of posts
        pclidOptions: {
          referrer_post_id: postId,
        },
      },
    });
  }, [toProduct, postId, tag]);

  return (
    <TagPoint x={x} y={y}>
      <ImageTag onClick={onClick} display="flex" alignItems="center">
        {tag.product.name}
      </ImageTag>
    </TagPoint>
  );
};

interface TagPointProps extends BoxProps {
  x: number;
  y: number;
}
const TagPoint = styled(Box)<TagPointProps>`
  position: absolute;
  left: 0;
  top: 0;
  ${({ x, y }) => `transform: translate(${x}px, ${y}px);`}
`;

export default PostImageTag;
