import styled, { DefaultTheme } from "styled-components";
import { border, BorderProps, layout, LayoutProps, position, PositionProps, space, SpaceProps } from "styled-system";

export interface ImageProps
  extends BorderProps<DefaultTheme>,
    LayoutProps<DefaultTheme>,
    PositionProps<DefaultTheme>,
    SpaceProps<DefaultTheme>,
    React.HTMLAttributes<HTMLImageElement> {
  alt: string;
}

const Image = styled.img<ImageProps>`
  ${border}
  ${space}
  ${position}
  ${layout}
`;

export default Image;
