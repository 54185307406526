import React, { ReactNode, useMemo, useState } from "react";
import CreateCommunityMenu from "./CreateCommunityMenu";
import CreateCommunityMenuContext, { CommunityMenuScreen } from "./CreateCommunityMenuContext";
export interface CreateCommunityMenuProviderProps {
  children: ReactNode;
}

const CreateCommunityMenuProvider: React.FC<CreateCommunityMenuProviderProps> = ({ children }) => {
  const [screen, setScreen] = useState<CommunityMenuScreen | null>(null);

  const value = useMemo(
    () => ({
      screen,
      setScreen,
    }),
    [screen, setScreen],
  );

  return (
    <CreateCommunityMenuContext.Provider value={value}>
      <CreateCommunityMenu />
      {children}
    </CreateCommunityMenuContext.Provider>
  );
};

export default CreateCommunityMenuProvider;
