import styled from "styled-components";
import Box, { BoxProps } from "../Box";

export interface ListSelectItemProps extends BoxProps {}

const ListSelectItem = styled(Box)<ListSelectItemProps>`
  cursor: pointer;
`;
ListSelectItem.defaultProps = {
  px: 3,
  py: 3,
  mb: 2,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  borderRadius: 3,
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  bg: "white",
};

export default ListSelectItem;
