import React, { useCallback } from "react";
import { useUser } from "reactfire";
import { useMutation } from "redux-query-react";
import styled from "styled-components";
import { MenuProps } from "../../hooks/useMenuState";
import dayjs from "../../libs/dayjs";
import { updateUserPreferences as updateUserPreferencesQuery } from "../../queries/api/userPreferencesQuery";
import { Box, Text } from "../../UI";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderButton,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
} from "../Layout/FullscreenMenu";

export interface ListingPostTermsMenuProps extends MenuProps {}

const ListingPostTermsMenu: React.FC<ListingPostTermsMenuProps> = ({ isOpen, close }) => {
  const { data: currentUser } = useUser();
  const [, updateUserPreferences] = useMutation(updateUserPreferencesQuery);

  const agreeToTerms = useCallback(() => {
    if (currentUser) {
      updateUserPreferences(currentUser.uid, {
        agreedToSellerTermsAt: dayjs().unix(),
      });
    }
  }, [currentUser, updateUserPreferences]);

  return (
    <FullscreenMenu
      isOpen={isOpen}
      close={close}
      header={
        <FullscreenMenuHeader>
          <FullscreenMenuHeaderIcon name="chevron left" onClick={close} containerProps={{ flexBasis: 0 }} />
          <FullscreenMenuHeaderTitle>{`Seller Terms`}</FullscreenMenuHeaderTitle>
          <FullscreenMenuHeaderButton variant="primary" onClick={agreeToTerms} containerProps={{ flexBasis: 0 }}>
            AGREE
          </FullscreenMenuHeaderButton>
        </FullscreenMenuHeader>
      }
      p={3}
      display="flex"
      flexDirection="column"
    >
      <>
        <SectionWrapper>
          <Header>{`Listing`}</Header>
          <BodyText>
            {`Include a clear item description and appropriate images that reflect your item. Make sure to describe and include images of any faults.`}
          </BodyText>
          <BodyText>
            {`Always use your own photos. Try to use natural daylight. Don't use stock images as your item will be removed.`}
          </BodyText>
          <BodyText>
            {`Double check if items are authentic. Sellers who list inauthentic products will be banned from selling on Plugd.`}
          </BodyText>
        </SectionWrapper>
        <SectionWrapper>
          <Header>{`Customer service and communication`}</Header>
          <BodyText>
            {`Reply to messages quickly - ideally within a day. This helps you sell faster, and keeps customers informed.`}
          </BodyText>
        </SectionWrapper>
        <SectionWrapper>
          <Header>{`Payments`}</Header>
          <BodyText>
            {`Make sure all purchases happen in-app. This means you're covered by Plugd so we can help if needed. `}
          </BodyText>
          <BodyText>
            {`To keep the community safe, we suspend users who don't buy and sell in-app. If you see something suspicious, let us know via direct message @plugd.`}
          </BodyText>
        </SectionWrapper>
        <SectionWrapper>
          <Header>{`Shipping`}</Header>
          <BodyText>
            {`Always use tracked shipping where possible. This will help keep your buyer informed on when they can expect their parcel to arrive, and ensures you're covered by seller protection. `}
          </BodyText>
          <BodyText>
            {`Ship your item as soon as you can. Ideally that's within 2 days, and at the latest, within 7 days. `}
          </BodyText>
          <BodyText>
            {`Make sure the item is clean before you pack it. Once shipped, keep your buyer updated. If there's going to be a delay, let them know.`}
          </BodyText>
        </SectionWrapper>
        <SectionWrapper>
          <Header>{`After your sale`}</Header>
          <BodyText>
            {`Aim to resolve any disputes with a buyer before involving Plugd. If we ask you for more information, it's important you respond within 24 hours so we can help quickly.`}
          </BodyText>
        </SectionWrapper>
      </>
    </FullscreenMenu>
  );
};

const SectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
SectionWrapper.defaultProps = {
  mb: 3,
};

const Header = styled(Text)``;
Header.defaultProps = {
  fontSize: 2,
  fontWeight: 5,
  mb: 2,
};

const BodyText = styled(Text)``;
BodyText.defaultProps = {
  mb: 2,
};

export default ListingPostTermsMenu;
