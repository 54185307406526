import { History } from "history";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appSelector from "../selectors/appSelector";

const useGoBack = (disable = false, defaultLocation?: History.LocationDescriptor): (() => void) => {
  const history = useHistory();
  const historyIdx = useSelector(appSelector.historyIdx());

  return useMemo<() => void>(() => {
    if (disable) {
      return () => {};
    }

    if (historyIdx > 0) {
      return history.goBack;
    }

    return () => history.replace(defaultLocation || "/");
  }, [disable, historyIdx, history, defaultLocation]);
};

export default useGoBack;
