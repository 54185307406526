import React, { useCallback, useContext, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Message } from "../../libs/semantic-ui";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import CreateUsernameForm from "./CreateUsernameForm";

export interface CreateUsernameProps {
  onClose?: () => void;
}

const CreateUsername: React.FC<CreateUsernameProps> = ({ onClose }) => {
  const [err, setErr] = useState<string | undefined>();
  const { addToast } = useToasts();
  const { setAuthScreen, onAuthenticated } = useContext(AuthScreensContext);

  const onSubmitSuccess = useCallback(() => {
    if (err) {
      setErr(undefined);
    }
    addToast(`You're all set!`, {
      appearance: "success",
      autoDismiss: true,
    });
    if (onClose) {
      onClose();
    } else {
      setAuthScreen(null);
    }
    if (onAuthenticated) {
      onAuthenticated();
    }
  }, [err, addToast, onClose, setAuthScreen, onAuthenticated]);

  return (
    <>
      <CreateUsernameForm onSubmitSuccess={onSubmitSuccess} onError={setErr} />
      {err && <Message error content={err} />}
    </>
  );
};

export default CreateUsername;
