import { keyBy } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { BrandResponse } from "../../typings/API";
import { BrandsState } from "../../typings/EntitiesState";

export const getBrandsQuery = (): QueryConfig<Pick<BrandsState, "brandsById">> => ({
  url: `${config.API_URL}/brands`,
  options: { headers: STANDARD_HEADERS },
  transform: (response: BrandResponse) => ({
    brandsById: keyBy(response.brands, (b) => b.id),
  }),
  update: {
    brandsById: shallowObjectMerge,
  },
});
