import { capitalize, isNil } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useMenuState from "../../../hooks/useMenuState";
import { RootState } from "../../../store";
import { ProductListingsAPI } from "../../../typings/API";
import RadioMenuItem from "../../Layout/MenuItem/RadioMenuItem";
import PartialMenu from "../../Layout/PartialMenu";
import InputSelectBox from "../InputSelectBox";
import { UseInputHook } from "../types/inputHooks";

const useSizeTypeInput: UseInputHook<string | null, string | undefined> = (detectedSizeType) => {
  const productAttribute = useSelector<RootState, ProductListingsAPI.ProductAttributeWithOptions | undefined>(
    (state) => state.entities.productAttributesByKey["size_type"],
  );

  const [sizeType, setSizeType] = useState<string | null>(detectedSizeType || null);

  /** automatically detect and set the size type from the product if it exists */
  useEffect(() => setSizeType(detectedSizeType || null), [detectedSizeType]);

  const {
    open: openMenu,
    menuProps: { close, isOpen },
  } = useMenuState();

  const input = useMemo(
    () => (
      <InputSelectBox
        disabled={!isNil(detectedSizeType)}
        onClick={openMenu}
        value={sizeType}
        displayValue={sizeType ? `${capitalize(sizeType)}'s` : undefined}
        placeholder="Size Type"
      />
    ),
    [openMenu, sizeType, detectedSizeType],
  );

  const onItemSelect = useCallback(
    (sizeType: string) => {
      setSizeType(sizeType);
      close();
    },
    [close],
  );

  const menus = useMemo(
    () => (
      <PartialMenu close={close} isOpen={isOpen}>
        {productAttribute?.options.map((o) => (
          <RadioMenuItem
            key={o.key}
            name={o.name}
            isChecked={sizeType === o.name}
            onClick={() => onItemSelect(o.name)}
          />
        ))}
      </PartialMenu>
    ),
    [close, isOpen, sizeType, productAttribute, onItemSelect],
  );

  const clearValue = useCallback(() => setSizeType(null), []);

  return useMemo(
    () => ({
      input,
      menus,
      value: sizeType,
      clearValue,
    }),
    [clearValue, sizeType, input, menus],
  );
};

export default useSizeTypeInput;
