import React from "react";
import { Anchor, Box, Link } from "../../UI";

export interface FooterItemProps {
  title: string;
  link?: string;
  href?: string;
  onClick?: () => void;
}

const FooterItem: React.FC<FooterItemProps> = ({ title, link, href, onClick }) => {
  if (link) {
    return (
      <Link to={link} onClick={onClick}>
        {title}
      </Link>
    );
  }
  if (href) {
    return (
      <Anchor href={href} onClick={onClick}>
        {title}
      </Anchor>
    );
  }
  return (
    <Box style={{ cursor: "pointer" }} onClick={onClick}>
      {title}
    </Box>
  );
};

export default FooterItem;
