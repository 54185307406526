import { capitalize, head, isNil, toUpper } from "lodash-es";
import { PostsAPI } from "../typings/API-V2";

export const getAttributeValuesByKey = (attributes: PostsAPI.ProductListingAttribute[]): { [key: string]: string } => {
  return attributes.reduce((result, value) => {
    const { attribute, attribute_value } = value;
    result[attribute.key] = attribute_value;
    return result;
  }, {});
};

export const formatAttributes = (attributes: PostsAPI.ProductListingAttribute[], styleId?: string): string => {
  const attributeValueByKey = getAttributeValuesByKey(attributes);

  const productCondition = attributeValueByKey["product_condition"];
  const shoeSize = attributeValueByKey["shoe_size"];
  const sizeType = attributeValueByKey["size_type"];

  return [`US ${shoeSize}${toUpper(head(sizeType))}`, capitalize(productCondition), styleId ? `SKU ${styleId}` : null]
    .filter((t): t is string => !isNil(t))
    .join("  |  ");
};
