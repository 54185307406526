import { getAuth, NextOrObserver, onAuthStateChanged, User } from "firebase/auth";
import React, { PropsWithChildren, useEffect } from "react";
import { AuthProvider as FirebaseAuthProvider, preloadUser, useFirebaseApp } from "reactfire";
import LocalStorageKey from "../../enums/LocalStorageKey";
import { captureException } from "../../helpers/errorUtil";
import { authStateChange, getTrackingApproval, SerializableUser } from "../../messages/outboundMessages";
import sendReactNativeMessage from "../../messages/sendReactNativeMessage";

export interface AuthProviderProps {}

const AuthProvider: React.FC<PropsWithChildren<AuthProviderProps>> = ({ children }) => {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  /** Manage the stored auth token */
  useEffect(() => {
    const handleAuthStateChange: NextOrObserver<User> = (user) => {
      if (user) {
        /**
         * TODO: move to onboarding & manually trigger
         */
        sendReactNativeMessage(getTrackingApproval());

        user.getIdToken(false /* force refresh */).then((token) => {
          if (token) {
            // set local storage
            localStorage.setItem(LocalStorageKey.PLUGD_AUTH_TOKEN, token);
          } else {
            // delete token from storage
            localStorage.removeItem(LocalStorageKey.PLUGD_AUTH_TOKEN);
          }
        });
      } else {
        // delete token from storage
        localStorage.removeItem(LocalStorageKey.PLUGD_AUTH_TOKEN);
      }
    };

    return onAuthStateChanged(auth, handleAuthStateChange, (error) => captureException(error));
  }, [auth]);

  /** Sync the auth state with the native app */
  useEffect(() => {
    if (window.ReactNativeWebView) {
      const handleAuthStateChange: NextOrObserver<User> = (user) =>
        sendReactNativeMessage(
          authStateChange(
            user
              ? /** Casting this object since firebase/auth's type for user.toJSON() is "object" */
                (user.toJSON() as SerializableUser)
              : user,
          ),
        );
      return onAuthStateChanged(auth, handleAuthStateChange, (error) => captureException(error));
    }
  }, [auth]);

  /** NOTE: Not sure if this is actually working. Nothing is returned from the promise */
  preloadUser(() => Promise.resolve(auth));

  return <FirebaseAuthProvider sdk={auth}>{children}</FirebaseAuthProvider>;
};

export default AuthProvider;
