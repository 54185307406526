import { SearchOptions } from "@algolia/client-search";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import usePlugdAlgolia from "../../hooks/usePlugdAlgolia";
import usePlugdAlgoliaSearch from "../../hooks/usePlugdAlgoliaSearch";
import { PlugdAlgoliaUser } from "../../typings/PlugdUser";
import { Spacer } from "../../UI";
import List from "../../UI/components/List";
import { UserListSelectItem, UserListSelectItemPlaceholder } from "../../UI/components/UserList";
import SearchMessage from "./SearchMessage";

export interface SearchContentUsersProps {
  query?: string;
}

export const defaultSearchOptions: SearchOptions = {
  restrictSearchableAttributes: ["username"],
  distinct: true,
};

const SearchContentUsers: React.FC<SearchContentUsersProps> = ({ query }) => {
  const history = useHistory();
  const [, algolia] = usePlugdAlgolia();
  const { isPending, searchResults } = usePlugdAlgoliaSearch<PlugdAlgoliaUser>(
    algolia?.userIndex,
    query,
    defaultSearchOptions,
  );

  const createUserClickHandler = useCallback<(user: PlugdAlgoliaUser) => React.MouseEventHandler>(
    (user) => (e) => {
      e.preventDefault();
      const qs = new URLSearchParams({ query: query || "", search_tab: "user" }).toString();
      history.push(`/search?${qs}`);
      history.push(`/u/${user.username}`);
    },
    [history, query],
  );

  if (!query || (!isPending && searchResults.length === 0)) {
    return <SearchMessage query={query} results={searchResults} />;
  }

  return (
    <>
      <Spacer mt={3} />
      <List isFinished={!isPending} width="100%" px={3} placeholderEl={<UserListSelectItemPlaceholder />}>
        {searchResults.map((u) => (
          <UserListSelectItem
            key={u.objectID}
            onClick={createUserClickHandler(u)}
            profile={u}
            to={`/u/${u.username}`}
          />
        ))}
      </List>
    </>
  );
};

export default SearchContentUsers;
