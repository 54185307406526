import { AdditionalUserInfo, AuthError } from "@firebase/auth-types";
import { createUserWithEmailAndPassword, getAdditionalUserInfo } from "firebase/auth";
import { every } from "lodash-es";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "reactfire";
import { useMutation } from "redux-query-react";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import config from "../../config";
import AuthFields from "../../enums/AuthFields";
import { FirebaseErrors } from "../../enums/FirebaseErrors";
import LocalStorageKey from "../../enums/LocalStorageKey";
import { authFieldToLabel } from "../../helpers/authFieldsUtil";
import * as localStorageUtil from "../../helpers/localStorageUtil";
import { Form, FormProps, InputOnChangeData, Loader, Message } from "../../libs/semantic-ui";
import { updateUserPreferences } from "../../queries/api/userPreferencesQuery";
import { createCurrentUserProfile } from "../../queries/api/userProfileQuery";
import userSelector from "../../selectors/userSelector";
import { UsersAPI } from "../../typings/API";
import { Box, Button, FormInput } from "../../UI";
import OutboundLink from "../links/OutboundLink";

export interface SignupFields {
  password: string;
  email: string;
  confirmPass: string;
  fullName: string;
}

export interface SignupFormProps extends FormProps {
  onSubmitSuccess?: (additionalUserInfo?: AdditionalUserInfo | null) => void;
}

const SignupForm: React.FC<SignupFormProps> = ({ onSubmitSuccess, ...formProps }) => {
  const auth = useAuth();
  const refUserId = localStorageUtil.getStringItem(LocalStorageKey.REFERRAL_USER_ID);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const preferences = useSelector(userSelector.preferences());
  const [, initProfile] = useMutation((userFields: UsersAPI.UserProfileInsert) =>
    createCurrentUserProfile(userFields, refUserId),
  );
  const [, updatePreferences] = useMutation((uid: string, preferences: Partial<UsersAPI.UserPreferences>) =>
    updateUserPreferences(uid, preferences),
  );
  const [fields, setFields] = useState<SignupFields>({
    password: "",
    confirmPass: "",
    fullName: "",
    email: "",
  });

  const handleChange = useCallback(
    (e: ChangeEvent, data: InputOnChangeData): void => {
      const { name, value } = data;
      setFields({
        ...fields,
        [name]: value,
      });
    },
    [fields],
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();
      // e.preventDefault();
      if (!fields.email.length || !fields.password.length || !fields.fullName.length) {
        setErr("Please fill in the required fields");
        return;
      } else if (fields.password !== fields.confirmPass) {
        setErr("The passwords you entered don't match");
        return;
      }

      try {
        setLoading(true);
        const authResults = await createUserWithEmailAndPassword(auth, fields.email, fields.password);
        const userInfo = getAdditionalUserInfo(authResults);
        if (userInfo && userInfo.isNewUser) {
          await initProfile({
            id: authResults.user.uid,
            full_name: fields.fullName,
            email: fields.email,
            username: authResults.user.uid,
            profile_image: authResults.user.photoURL || undefined,
          });

          await updatePreferences(authResults.user.uid, preferences);
        }

        if (onSubmitSuccess) {
          onSubmitSuccess(userInfo);
        }
      } catch (error) {
        const { code, message } = error as AuthError;
        setErr(FirebaseErrors[code] || message);
      } finally {
        setLoading(false);
      }
    },
    [
      fields.email,
      fields.password,
      fields.fullName,
      fields.confirmPass,
      auth,
      onSubmitSuccess,
      initProfile,
      updatePreferences,
      preferences,
    ],
  );

  const validPasswordLength = fields.password.length >= 8;

  const passwordsEqual = useMemo(() => {
    return validPasswordLength && fields.password === fields.confirmPass;
  }, [fields.password, fields.confirmPass, validPasswordLength]);

  const canSubmit = useMemo(() => {
    // all fields required to submit
    return every([
      every(fields, (value) => Boolean(value)),
      isEmail(fields.email),
      validPasswordLength,
      passwordsEqual,
    ]);
  }, [fields, passwordsEqual, validPasswordLength]);

  return (
    <Form style={{ width: "100%" }} {...formProps} onSubmit={handleSubmit} error={Boolean(err)}>
      <FormInput
        placeholder={authFieldToLabel[AuthFields.FULL_NAME]}
        name={AuthFields.FULL_NAME}
        value={fields.fullName}
        onChange={handleChange}
        autoComplete="name"
      />
      <FormInput
        placeholder={authFieldToLabel[AuthFields.EMAIL]}
        name={AuthFields.EMAIL}
        type="email"
        value={fields.email}
        onChange={handleChange}
        autoComplete="email"
      />
      <FormInput
        icon={validPasswordLength ? "check" : undefined}
        iconPosition="right"
        fluid
        placeholder={"Password (8 characters minimum)"}
        minLength={8}
        type="password"
        name={AuthFields.PASSWORD}
        value={fields.password}
        onChange={handleChange}
        autoComplete="new-password"
      />
      <FormInput
        icon={passwordsEqual ? "check" : undefined}
        iconPosition="right"
        fluid
        placeholder={authFieldToLabel[AuthFields.CONFIRM_PASSWORD]}
        type="password"
        name={AuthFields.CONFIRM_PASSWORD}
        value={fields.confirmPass}
        onChange={handleChange}
      />
      <Message info size="tiny">
        <span>By signing up, you agree to our </span>
        <OutboundLink href={config.TERMS_OF_SERVICE_URL}>Terms of Service</OutboundLink>
        <span> and </span>
        <OutboundLink href={config.PRIVACY_POLICY_URL}>Privacy Policy</OutboundLink>
      </Message>
      <SignupButton variant={canSubmit ? "primary" : "disabled"} type="submit" size="big" disabled={!canSubmit}>
        <ButtonContent>{loading ? <Loader inverted active inline="centered" size="tiny" /> : "Sign up"}</ButtonContent>
      </SignupButton>{" "}
      <Message error content={err} />
    </Form>
  );
};

const ButtonContent = styled(Box)`
  height: 1.1em;
`;

ButtonContent.defaultProps = {
  justifyContent: "center",
  display: "flex",
};

const SignupButton = styled(Button)`
  width: 100%;
`;

export default SignupForm;
