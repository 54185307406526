// Default Breakpoints
const breakpoints = ["40em", "52em", "64em"];

const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
};

// default fontSizes
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72];

const lineHeights = ["12px", "14px", "16px", "20px", "24px", "32px", "48px", "64px", "72px"];

// default space for margin and padding
const space = [0, 4, 8, 16, 24, 32, 64, 128, 256, 512];

const radii = [0, 8, 15, 20, 30];

const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900];

const colors = {
  opaqueBgLight: "rgba(255, 255, 255, 0.30)",
  opaqueBgDark: "rgba(0, 0, 0, 0.30)",
  opaqueBgDark2: "rgba(0, 0, 0, 0.50)",
  opaqueBgDark3: "rgba(0, 0, 0, 0.70)",
  green: "#21BA45",
  askColor: "#21BA45",
  black: "#222222",
  pureBlack: "#000000",
  darkBlue: "#2B3A67",
  darkPurple: "#824C71",
  anchorBlue: "#4fbcff",
  gold: "#D4AE6F",
  darkGold: "#C5923D",
  lightGrey: "#F0F3F5",
  mediumGrey: "#C3C3C3",
  darkGrey: "#808080",
  red: "#BA4E4C",
  notificationRed: "#DB2828",
  blue: "#2185d0",
  teal: "#06908F",
  white: "#FFFFFF",
  fireOrange: "#FF7700",
  transparent: "transparent",
};
const gradients = {
  goldGold: `linear-gradient(125.00deg, ${colors.gold} 0%, ${colors.darkGold} 100.00%)`,
  goldRed: `linear-gradient(125.00deg, ${colors.gold} 0%, ${colors.red} 100.00%)`,
  tealBlue: `linear-gradient(125.00deg, ${colors.teal} 0%, ${colors.darkBlue} 100.00%)`,
  redPurple: `linear-gradient(125.00deg, ${colors.red} 0%, ${colors.darkPurple} 100.00%)`,
  bluePurple: `linear-gradient(125.00deg, ${colors.darkBlue} 0%, ${colors.darkPurple} 100.00%)`,
};

const imageSize = {
  small: 24,
  medium: 30,
  large: 40,
};

const theme = {
  breakpoints,
  colors: { ...colors, ...gradients },
  fontSizes,
  fontWeights,
  imageSize,
  lineHeights,
  mediaQueries,
  radii,
  space,
};

export default theme;
