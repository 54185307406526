import { isBoolean, keyBy, toString } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { groupIdsBy, shallowObjectMerge, updateNestedUniqueArrays } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { OrderBy, ProductReleaseChannel, ProductReleasesAPI } from "../../typings/API";
import { ProductLaunchState, VendorsState } from "../../typings/EntitiesState";
import { vendorStateUpdates, vendorsToVendorsState } from "./vendorQuery";

export interface GetProductReleasesOptions {
  country_code?: string;
  has_url?: boolean;
  order_by?: OrderBy;
  product_id?: number;
  release_date?: string;
  sort_by?: "release_date";
}
export const getProductReleases = (
  options: GetProductReleasesOptions,
): QueryConfig<
  Pick<ProductLaunchState, "releaseIdsByProductId" | "releasesById"> &
    Pick<VendorsState, "vendorsById" | "vendorIdsBySlug">
> => {
  const searchParams = new URLSearchParams({
    order_by: options.order_by || "asc",
    sort_by: options.sort_by || "release_date",
  });
  if (options.country_code) {
    searchParams.set("country_code", options.country_code);
  }
  if (isBoolean(options.has_url)) {
    searchParams.set("has_url", toString(options.has_url));
  }
  if (options.order_by) {
    searchParams.set("order_by", options.order_by);
  }
  if (options.product_id) {
    searchParams.set("product_id", toString(options.product_id));
  }
  if (options.release_date) {
    searchParams.set("release_date", options.release_date);
  }
  if (options.sort_by) {
    searchParams.set("sort_by", options.sort_by);
  }
  const qs = searchParams.toString();

  return {
    url: `${config.API_URL}/product-releases?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: ProductReleasesAPI.GetProductReleasesResponse) => ({
      releasesById: keyBy(response.releases, (r) => r.id),
      releaseIdsByProductId: groupIdsBy(response.releases, (r) => r.product_id),
      ...vendorsToVendorsState(response.vendors),
    }),
    update: {
      releasesById: shallowObjectMerge,
      releaseIdsByProductId: (oldValue, newValue) => updateNestedUniqueArrays(oldValue, newValue),
      ...vendorStateUpdates,
    },
  };
};

export const updateProductRelease = (
  release: ProductReleasesAPI.ProductRelease,
): QueryConfig<Pick<ProductLaunchState, "releasesById">> => ({
  url: `${config.API_URL}/product-releases/${release.id}`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "PUT",
    headers: STANDARD_HEADERS,
  },
  body: { ...release },
  optimisticUpdate: {
    releasesById: (prevValue) => ({
      ...prevValue,
      [release.id]: release,
    }),
  },
});

export const getProductReleaseChannels = (): QueryConfig<Pick<ProductLaunchState, "productReleaseChannelsByKey">> => ({
  url: `${config.API_URL}/product-release-channels`,
  options: {
    headers: STANDARD_HEADERS,
  },
  transform: (response: { channels: ProductReleaseChannel[] }) => ({
    productReleaseChannelsByKey: keyBy(response.channels, (c) => c.key),
  }),
  update: {
    productReleaseChannelsByKey: (_, newValue) => newValue,
  },
});
