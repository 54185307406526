import React, { useEffect } from "react";
import styled from "styled-components";
import { EntitlementID } from "../../../enums/RevenueCat";
import events, { trackEvent } from "../../../helpers/analyticsUtil";
import { MenuProps } from "../../../hooks/useMenuState";
import { Box, Button, Spacer, Text } from "../../../UI";
import FullscreenMenu, { FullscreenMenuHeader, FullscreenMenuHeaderTitle } from "../../Layout/FullscreenMenu";
import FullscreenMenuHeaderSide from "../../Layout/FullscreenMenu/FullscreenMenuHeaderSide";
import PlugdGoldFeatures from "../../PlugdGold/PlugdGoldFeatures";

export interface PlugdGoldOnboardingModalProps extends MenuProps {}

const PlugdGoldOnboardingModal: React.FC<PlugdGoldOnboardingModalProps> = ({ isOpen, close }) => {
  useEffect(() => {
    if (isOpen) {
      trackEvent(events.SubscriptionOnboardingMenu.Viewed, {
        /** TODO: Make this dynamic when new entitlements are added */
        entitlement_id: EntitlementID.GOLD,
      });
    }
  }, [isOpen]);

  return (
    <FullscreenMenu
      isOpen={isOpen}
      close={close}
      bg="lightGrey"
      p={3}
      display="flex"
      flexDirection="column"
      header={
        <FullscreenMenuHeader>
          <FullscreenMenuHeaderSide side="left" />
          <FullscreenMenuHeaderTitle>Plugd Gold</FullscreenMenuHeaderTitle>
          <FullscreenMenuHeaderSide side="right" />
        </FullscreenMenuHeader>
      }
    >
      <Header mb={4} mt={3}>
        <Text fontSize={3} fontWeight={5} textAlign="center">{`Welcome and thanks for supporting Plugd!`}</Text>
        <Spacer mt={4} />
        <Text textAlign="center">{`Learn more about your exclusive benefits`}</Text>
      </Header>
      <PlugdGoldFeatures />
      <Footer>
        <Button onClick={close} variant="secondary" size="medium" fluid>{`Got It!`}</Button>
      </Footer>
    </FullscreenMenu>
  );
};

const Footer = styled(Box)`
  margin-top: auto;
  width: 100%;
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default PlugdGoldOnboardingModal;
