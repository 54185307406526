import { isEmpty, keyBy, range, toString } from "lodash-es";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import {
  sanitizePostInsert,
  sanitizePostPatch,
  validatePostInsert,
  validatePostPatch,
} from "../../../helpers/postUtil";
import { MenuProps } from "../../../hooks/useMenuState";
import { listProductAttributes } from "../../../queries/api/productAttributeQuery";
import { RootState } from "../../../store";
import { UsersAPI } from "../../../typings/API";
import { PostsAPI, PostTypeKey } from "../../../typings/API-V2";
import { Box, Text } from "../../../UI";
import ImagesPreview, { ImagesPreviewProps } from "../CreateImagePostMenu/ImagesPreview";
import CreatePostMenuGeneric, { PostDataMeta, PostInsertDataMeta, PostPatchDataMeta } from "../CreatePostMenuGeneric";
import useBoxConditionInput from "../hooks/useBoxConditionInput";
import useConditionInput from "../hooks/useConditionInput";
import useImageInput, { ImageMeta, InsertableImageMeta, UseImageInputOptions } from "../hooks/useImageInput";
import usePriceInput from "../hooks/usePriceInput";
import useProductSelectorInput from "../hooks/useProductSelectorInput";
import useShippingInput from "../hooks/useShippingInput";
import useSizeInput from "../hooks/useSizeInput";
import useSizeTypeInput from "../hooks/useSizeTypeInput";
import useTitleInput from "../hooks/useTitleInput";
import ListingPostTermsMenu from "../ListingPostTermsMenu";
import PostMenuNavTabs from "../PostMenuNavTabs";
import { imageMetaToImageInsert, isInsertableImageMeta } from "../postMenuUtils";

export interface CreateListingPostMenuProps extends MenuProps {
  editPost?: PostsAPI.ListingPost;
  onCreated?: () => void;
}

const useImageInputOptions: UseImageInputOptions = { canTag: false };
/** default size range is 3.5 through 20 by 0.5 steps */
const defaultSizeOptions = range(3.5, 20, 0.5);

const CreateListingPostMenu: React.FC<CreateListingPostMenuProps> = ({ close, isOpen, editPost, onCreated }) => {
  const preferences = useSelector<RootState, UsersAPI.UserPreferences>((state) => state.entities.preferences);
  /** fetch necessary attributes for option values */
  const [{ isFinished: attributesFinished }] = useRequest(
    listProductAttributes({
      keys: ["box_condition", "product_condition", "size_type"],
    }),
  );
  /** default product if the post is being edited */
  const defaultProduct = useSelector((state: RootState) =>
    editPost ? state.entities.productsById[editPost.product_listing.product_id] : undefined,
  );
  const attributesByAttributeKey = useMemo(() => {
    if (editPost) {
      return keyBy(editPost.product_listing.attributes, (a) => a.attribute.key);
    }
    return {};
  }, [editPost]);

  const {
    input: productInput,
    menus: productMenus,
    value: product,
    clearValue: clearProduct,
  } = useProductSelectorInput(defaultProduct);
  const {
    input: titleInput,
    menus: titleMenus,
    value: title,
    clearValue: clearTitle,
  } = useTitleInput({ defaultValue: editPost?.title });
  const {
    input: priceInput,
    menus: priceMenus,
    value: price,
    clearValue: clearPrice,
  } = usePriceInput(editPost ? toString(editPost.product_listing.price_cents / 100) : undefined);
  const {
    input: sizeInput,
    menus: sizeMenus,
    value: size,
    clearValue: clearSize,
  } = useSizeInput({
    defaultSize: parseFloat(attributesByAttributeKey["shoe_size"]?.attribute_value),
    sizeRange: product?.size_range || defaultSizeOptions,
  });
  const {
    input: conditionInput,
    menus: conditionMenus,
    value: condition,
    clearValue: clearCondition,
  } = useConditionInput(attributesByAttributeKey["product_condition"]?.attribute_value);
  const {
    input: boxConditionInput,
    menus: boxConditionMenus,
    value: boxCondition,
    clearValue: clearBoxCondition,
  } = useBoxConditionInput(attributesByAttributeKey["box_condition"]?.attribute_value);
  const {
    input: sizeTypeInput,
    menus: sizeTypeMenus,
    value: sizeType,
    clearValue: clearSizeType,
  } = useSizeTypeInput(attributesByAttributeKey["size_type"]?.attribute_value || product?.gender || undefined);
  const { input: shippingInput } = useShippingInput(undefined);
  const {
    input: imagesInput,
    menus: imagesMenus,
    value: imagesMeta,
    clearValue: clearImagesMeta,
  } = useImageInput(useImageInputOptions);

  const clearFields = useCallback(() => {
    clearBoxCondition();
    clearCondition();
    clearImagesMeta();
    clearPrice();
    clearProduct();
    clearSize();
    clearSizeType();
    clearTitle();
  }, [
    clearBoxCondition,
    clearCondition,
    clearImagesMeta,
    clearPrice,
    clearProduct,
    clearSize,
    clearSizeType,
    clearTitle,
  ]);

  /** build the product listing insert object */
  const productListing = useMemo<PostsAPI.ProductListingInsert | null>(() => {
    if (!boxCondition || !condition || !sizeType || !size || !price || !product?.id) {
      return null;
    }
    return {
      attributes: {
        box_condition: boxCondition,
        product_condition: condition,
        size_type: sizeType,
        shoe_size: toString(size),
      },
      price_cents: parseInt(price) * 100,
      product_id: product.id,
      /** TODO: Add UI to make quantity dynamic value */
      quantity: 1,
    };
  }, [price, boxCondition, condition, sizeType, size, product?.id]);

  const postDataMeta = useMemo<PostDataMeta>(
    () =>
      editPost
        ? createPostPatch({
            editPost,
            productListing,
            title,
          })
        : createPostInsert({
            productListing,
            imagesMeta,
            title,
          }),
    [editPost, productListing, imagesMeta, title],
  );

  const closeMenu = useCallback(() => {
    close();
    /** reset entire menu state when closed */
    clearFields();
  }, [close, clearFields]);

  const previewImages = useMemo<ImagesPreviewProps["images"] | null>(() => {
    if (editPost) {
      return editPost.images.map((i) => ({
        url: i.url,
        tags: null,
      }));
    }
    return null;
  }, [editPost]);

  const shouldDisplaySellerTerms = !preferences.agreedToSellerTermsAt;

  return (
    <>
      <CreatePostMenuGeneric
        close={closeMenu}
        isOpen={isOpen}
        onCreated={onCreated}
        overflowY="scroll"
        postDataMeta={postDataMeta}
      >
        {!editPost && <PostMenuNavTabs activeTab={PostTypeKey.Listing} />}
        {productInput}
        {product && attributesFinished && (
          <>
            <Box display="flex">
              {sizeTypeInput}
              {sizeInput}
            </Box>
            <Box display="flex">
              {conditionInput}
              {boxConditionInput}
            </Box>
            {priceInput}
            {shippingInput}
            {titleInput}
            {/* for now, do not allow users to edit images */}
            {editPost && previewImages ? (
              <ImagesPreview images={previewImages} />
            ) : (
              <>
                <Box px={3} pt={3}>
                  <Text fontWeight={5}>Product photos</Text>
                  <Text ml={1} fontSize={0} color="darkGrey" fontWeight={5}>
                    (3 or more)
                  </Text>
                </Box>
                {imagesInput}
              </>
            )}
          </>
        )}
      </CreatePostMenuGeneric>
      {shouldDisplaySellerTerms ? (
        <ListingPostTermsMenu isOpen={shouldDisplaySellerTerms} close={closeMenu} />
      ) : (
        <>
          {imagesMenus}
          {priceMenus}
          {conditionMenus}
          {boxConditionMenus}
          {productMenus}
          {sizeMenus}
          {sizeTypeMenus}
          {titleMenus}
        </>
      )}
    </>
  );
};

/**
 * Builds the post insert data and meta to be used to update the post
 * @param options
 * @returns {PostInsertDataMeta}
 */
const createPostInsert = (options: {
  productListing: PostsAPI.ProductListingInsert | null;
  imagesMeta: ImageMeta[];
  title: string;
}): PostInsertDataMeta => {
  const { productListing, imagesMeta, title } = options;
  if (!productListing) {
    return {
      type: "post",
      canSubmit: false,
      message: "Must include product listing",
    };
  }

  if (isEmpty(imagesMeta)) {
    return {
      type: "post",
      canSubmit: false,
      message: "Must include product images",
    };
  }

  if (imagesMeta.length < 3) {
    return {
      type: "post",
      canSubmit: false,
      message: "Must include at least 3 product images",
    };
  }

  const metasValid = imagesMeta.every((m) => isInsertableImageMeta(m));
  if (!metasValid) {
    return {
      type: "post",
      canSubmit: false,
      message: "Image metas are not valid",
    };
  }

  /**
   * Need the filter for TS purposes. No values should be filtered out since we check for object validity above
   */
  const imageInserts = imagesMeta
    .filter((m): m is InsertableImageMeta => isInsertableImageMeta(m))
    .map(imageMetaToImageInsert);

  /** build creation object */
  const postInsert: PostsAPI.PostInsert = {
    content: null,
    images: imageInserts,
    product_listing: productListing,
    /** Listing posts have no themes */
    theme_key: null,
    /** Listing posts have no themes */
    theme_id: null,
    title: title,
    type: PostTypeKey.Listing,
    url: null,
    to_route: null,
  };

  /** sanitize creation object */
  const sanitizedPostInsert = sanitizePostInsert(postInsert);

  /** validate creation object */
  const postInsertValidation = validatePostInsert(sanitizedPostInsert);
  if (!postInsertValidation.isValid) {
    /** capture error */
    return {
      type: "post",
      canSubmit: false,
      message: postInsertValidation.message,
    };
  }

  return {
    type: "post",
    canSubmit: true,
    postData: sanitizedPostInsert,
  };
};

/**
 * Builds the post patch data and meta to be used to update the post
 * @param options
 * @returns {PostPatchDataMeta}
 */
const createPostPatch = (options: {
  editPost?: PostsAPI.ListingPost;
  productListing: PostsAPI.ProductListingInsert | null;
  title: string;
}): PostPatchDataMeta => {
  const { editPost, productListing, title } = options;

  if (!editPost) {
    return {
      type: "patch",
      canSubmit: false,
      message: "Original post is missing",
    };
  }

  if (!productListing) {
    return {
      type: "patch",
      canSubmit: false,
      message: "Must include product listing",
    };
  }

  /** build creation object */
  const postPatch: PostsAPI.PostPatch = {
    id: editPost.id,
    is_archived: editPost.is_archived,
    product_listing: productListing,
    title: title,
  };

  /** sanitize creation object */
  const sanitizedPostPatch = sanitizePostPatch(postPatch);

  /** validate creation object */
  const postPatchValidation = validatePostPatch(sanitizedPostPatch);
  if (!postPatchValidation.isValid) {
    /** capture error */
    return {
      type: "patch",
      canSubmit: false,
      message: postPatchValidation.message,
    };
  }

  return {
    type: "patch",
    canSubmit: true,
    postData: sanitizedPostPatch,
  };
};

export default CreateListingPostMenu;
