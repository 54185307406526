import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideMenu } from "../../actions/createActions";
import useAuthenticatedCallback from "../../hooks/useAuthenticatedCallback";
import appSelector from "../../selectors/appSelector";
import { Box } from "../../UI";
import ActionsMenuItem from "../ActionsMenu/ActionsMenuItem";
import CreateCommunityMenuContext from "../CreateCommunity/CreateCommunityMenuContext";
import SideMenu from "../Layout/SideMenu";
import CommunityListPanel from "./CommunityListPanel";

export interface CommunitySideMenuProps {}

const CommunitySideMenu: React.FC<CommunitySideMenuProps> = () => {
  const isVisible = useSelector(appSelector.sideMenuOpen());
  const dispatch = useDispatch();

  const closeSideMenu = useCallback(() => {
    if (isVisible) {
      dispatch(toggleSideMenu());
    }
  }, [dispatch, isVisible]);

  const communityContext = useContext(CreateCommunityMenuContext);

  const onCommunityClick = useAuthenticatedCallback(() => {
    communityContext.setScreen("slug");
    closeSideMenu();
  }, [communityContext, closeSideMenu]);

  return (
    <SideMenu
      isOpen={isVisible}
      close={closeSideMenu}
      footer={<ActionsMenuItem iconName={"add"} label="Create a Community" onClick={onCommunityClick} />}
    >
      <Box px={3} py={2}>
        <CommunityListPanel onSelect={closeSideMenu} />
      </Box>
    </SideMenu>
  );
};

export default CommunitySideMenu;
