import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { formatMobileBrowserHref } from "../../helpers/urlUtil";
import useAffiliateLinkClick, { AffiliateLinkType } from "../../hooks/useAffiliateLinkClick";
import LinkifyWrapper from "./LinkifyWrapper";
import { isLocalUrl } from "./linksUtils";
import { outboundLinkClick } from "./OutboundLink";
import { communityStringReplace, userStringReplace } from "./utils/mentionDetect";

type LinkType = "mention" | "email" | "url";

// TODO: add more option types and move to lib declaration file https://linkify.js.org/docs/options.html
export interface LinkifyOptions {
  attributes?: Partial<Record<string, any>>;
  className?: string | ((href: string, type: LinkType) => string) | Partial<Record<LinkType, (href: string) => string>>;
  format?: ((value: string, type: LinkType) => string) | Partial<Record<LinkType, (value: string) => string>>;
  formatHref?: ((href: string, type: LinkType) => string) | Partial<Record<LinkType, (href: string) => string>>;
  rel?: string | ((href: string, type: LinkType) => string) | Partial<Record<LinkType, (href: string) => string>>;
  truncate?: number | ((href: string, type: LinkType) => number) | Partial<Record<LinkType, (href: string) => number>>;
  validate?:
    | boolean
    | ((href: string, type: LinkType) => boolean)
    | Partial<Record<LinkType, (href: string) => boolean>>;
}

export interface LinkingWrapperProps {
  affiliateLinkType?: AffiliateLinkType;
  linkifyOptions?: LinkifyOptions;
  tagName?: string;
  text: string;
}

/**
 * @param {LinkingWrapperProps} props
 * @returns {<span />} decorated with links
 */
const LinkingWrapper: React.FC<LinkingWrapperProps> = ({ tagName, text, linkifyOptions, affiliateLinkType }) => {
  const history = useHistory();

  const onAffiliateLinkClick = useAffiliateLinkClick();

  const onLinkClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      const href = e.currentTarget.getAttribute("href");

      /** exit if there is no href */
      if (!href) {
        return;
      }

      /** if the link is a Plugd link, push state */
      const urlObj = new URL(href);
      if (isLocalUrl(urlObj)) {
        return history.push(`${urlObj.pathname}?${urlObj.search}`);
      }

      /** if affiliate link type is passed, handle as affiliate link */
      if (affiliateLinkType) {
        return onAffiliateLinkClick(formatMobileBrowserHref(href), affiliateLinkType);
      }

      /** handle normal outbound link */
      outboundLinkClick(formatMobileBrowserHref(href));
    },
    [history, onAffiliateLinkClick, affiliateLinkType],
  );

  const linkifyOptionProps = useMemo<LinkifyOptions>(() => {
    const options: LinkifyOptions = {
      attributes: {
        onClick: onLinkClick,
        ...linkifyOptions?.attributes,
      },
      format: {
        url: (href) => {
          try {
            const urlObj = new URL(href);
            return `${urlObj.hostname}${urlObj.pathname.length > 1 ? urlObj.pathname : ""}`;
          } catch {
            return href;
          }
        },
        ...linkifyOptions?.format,
      },
      formatHref: {
        ...linkifyOptions?.formatHref,
      },
      rel: "noopener noreferrer",
    };
    if (linkifyOptions?.truncate) {
      options.truncate = linkifyOptions.truncate;
    }
    if (linkifyOptions?.validate) {
      options.validate = linkifyOptions.validate;
    }
    return options;
  }, [onLinkClick, linkifyOptions]);

  /**
   * have to use deprecated React.ReactNodeArray type because it is the type used by "react-string-replace" and React.ReactNode[] is not accepted
   */
  const customStringReplaces = useMemo<string | React.ReactNodeArray>(() => {
    let stringReplace: string | React.ReactNodeArray = text;

    /** find community mentions */
    stringReplace = communityStringReplace(stringReplace);

    /** find user mentions */
    stringReplace = userStringReplace(stringReplace);

    return stringReplace;
  }, [text]);

  return (
    <LinkifyWrapper tagName={tagName} options={linkifyOptionProps}>
      {customStringReplaces}
    </LinkifyWrapper>
  );
};

export default LinkingWrapper;
