import { SearchResponse } from "@algolia/client-search";
import { SearchClient, SearchIndex } from "algoliasearch";
import config from "../config";
import { PlugdAlgoliaProduct } from "../typings/PlugdProduct";
import { PlugdAlgoliaUser } from "../typings/PlugdUser";

export type PlugdProductsSearchResult = SearchResponse<PlugdAlgoliaProduct>;
export type PlugdUsearSearchResult = SearchResponse<PlugdAlgoliaUser>;

class PlugdAlgolia {
  private static readonly algolia_app_id: string = "5SJ29IE3S6";
  private static readonly algolia_api_key: string = "7ad0b3a327093551ad7d556ad6b4fba7";
  private static readonly algolia_product_index: string = config.ALGOLIA_PRODUCT_INDEX;
  private static readonly algolia_user_index: string = "USER";
  private algoliaClient: SearchClient;
  public productIndex: SearchIndex;
  public userIndex: SearchIndex;

  constructor(algoliasearch: typeof import("algoliasearch")) {
    this.algoliaClient = algoliasearch.default(PlugdAlgolia.algolia_app_id, PlugdAlgolia.algolia_api_key);
    this.productIndex = this.algoliaClient.initIndex(PlugdAlgolia.algolia_product_index);
    this.userIndex = this.algoliaClient.initIndex(PlugdAlgolia.algolia_user_index);
  }

  public async productSearch(
    query = "",
    page = 0,
    distinct = true,
    facetFilters?: string[] | string[][],
  ): Promise<SearchResponse<PlugdAlgoliaProduct>> {
    const result = await this.productIndex.search<PlugdAlgoliaProduct>(query, {
      page,
      distinct,
      facetFilters,
    });

    return result;
  }

  public async userSearch(
    query = "",
    page = 0,
    distinct = true,
    facetFilters?: string[] | string[][],
  ): Promise<SearchResponse<PlugdAlgoliaUser>> {
    const result = await this.userIndex.search<PlugdAlgoliaUser>(query, {
      page,
      distinct,
      facetFilters,
    });

    return result;
  }
}

export default PlugdAlgolia;
