import React from "react";
import styled from "styled-components";
import Box from "../Box";
import PlaceholderBox from "../PlaceholderBox";
import { UserMetaWrapper, UserNamesWrapper } from "./UserMeta";

export interface UserMetaPlaceholderProps {}

const UserMetaPlaceholder: React.FC<UserMetaPlaceholderProps> = () => {
  return (
    <UserMetaWrapper>
      <PlaceholderBox height={40} width={40} borderRadius={1} />
      <UserNamesWrapper>
        <PlaceholderBox height={14} width={150} />
        <PlaceholderBox height={12} width={130} mt={2} />
      </UserNamesWrapper>
    </UserMetaWrapper>
  );
};

const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
`;
TextWrapper.defaultProps = {
  ml: 2,
};

export default UserMetaPlaceholder;
