import React, { ReactNode } from "react";
import { Box, Text } from "../../UI";

export interface AuthScreenContentHeaderProps {
  title: string;
  description?: ReactNode;
}

const AuthScreenContentHeader: React.FC<AuthScreenContentHeaderProps> = ({ description, title }) => {
  return (
    <Box width="100%" mt={2} mb={3} px={2}>
      <Text textAlign="left" fontSize={3} fontWeight={600}>
        {title}
      </Text>
      {description && (
        <Box color="darkGrey" my={1}>
          {description}
        </Box>
      )}
    </Box>
  );
};

export default AuthScreenContentHeader;
