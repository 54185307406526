import React from "react";
import styled from "styled-components";
import useAutoFocusRefCallback from "../../hooks/useAutoFocusRefCallback";
import { Divider } from "../../libs/semantic-ui";
import { Box, Input, Text } from "../../UI";

export interface CommunityNameFormProps {
  name?: string;
  placeholder?: string;
  onChange: (name: string | undefined) => void;
}

const maxLength = 50;

const CommunityNameForm: React.FC<CommunityNameFormProps> = ({ name, onChange, placeholder }) => {
  const [communityNameInputRef] = useAutoFocusRefCallback();

  return (
    <Box px={2}>
      <Box width="100%" mt={2} mb={3}>
        <Text textAlign="left" fontSize={3} fontWeight={600}>
          Set a display name for your community
        </Text>
        <Box color="darkGrey" my={1}>
          {`Display name is the handle by default. Override this to show something else`}
        </Box>
        <Box mt={4} display="flex" alignItems="center">
          <Input
            ref={communityNameInputRef}
            placeholder={placeholder}
            value={name}
            onChange={(event) => onChange(event.target.value)}
            maxLength={maxLength}
            pattern="[a-zA-Z0-9]+"
          />
        </Box>
      </Box>
      <InputDivider />
      <Text color="mediumGrey" textAlign={"right"}>{`${name?.length || 0}/${maxLength}`}</Text>
    </Box>
  );
};

const InputDivider = styled(Divider)`
  &&&& {
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

export default CommunityNameForm;
