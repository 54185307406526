import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import PartialMenu from "../../components/Layout/PartialMenu";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import { PostTypeKey } from "../../typings/API-V2";
import { Box, Icon, Spacer, Text } from "../../UI";
import PostMenuContext, { PostMenuContextValue } from "../PostMenu/PostMenuContext";
import CreateListingPostSelectorItem from "./CreateListingPostSelectorItem";
import CreatePostSelectorItem from "./CreatePostSelectorItem";

export interface CreatePostSelectorMenuProps {
  isOpen: boolean;
  close: () => void;
}

const CreatePostSelectorMenu: React.FC<CreatePostSelectorMenuProps> = ({ isOpen, close }) => {
  const { setPostMenu } = useContext(PostMenuContext);
  useEffect(() => {
    if (isOpen) {
      trackEvent(events.CreatePostSelectorMenu.Viewed);
    }
  }, [isOpen]);

  const handlePostTypeSelect = useCallback<PostMenuContextValue["setPostMenu"]>(
    (option) => {
      setPostMenu(option);
      close();
    },
    [setPostMenu, close],
  );

  return (
    <>
      <PartialMenu close={close} height="100%" isOpen={isOpen} overflowY="inherit" wrapperProps={{ top: "75%" }}>
        <Box height="100%" display="flex" flexDirection="column" flexShrink={0}>
          <MenuHeader>
            <Box flexBasis="10%" />
            <Box flexBasis="80%" display="flex" alignItems="center" justifyContent="center">
              <Text fontWeight={600}>Create Post</Text>
            </Box>
            <Box
              alignItems="center"
              display="flex"
              flexBasis="10%"
              justifyContent="center"
              onClick={close}
              role="button"
            >
              <Icon name="chevron down" />
            </Box>
          </MenuHeader>
          <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
            <CreatePostSelectorItem
              iconName="newspaper"
              title="Text"
              onClick={() => handlePostTypeSelect(PostTypeKey.Text)}
            />
            <Spacer ml={4} />
            <CreatePostSelectorItem
              iconName="camera"
              title="Image"
              onClick={() => handlePostTypeSelect(PostTypeKey.Image)}
            />
            <Spacer ml={4} />
            <CreatePostSelectorItem
              iconName="linkify"
              title="Link"
              onClick={() => handlePostTypeSelect(PostTypeKey.Link)}
            />
            <>
              <Spacer ml={4} />
              <CreateListingPostSelectorItem setPostMenu={handlePostTypeSelect} />
            </>
          </Box>
        </Box>
      </PartialMenu>
    </>
  );
};

const MenuHeader = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;
MenuHeader.defaultProps = {
  flexShrink: 0,
  py: 2,
  display: "flex",
  alignItems: "center",
};

export default CreatePostSelectorMenu;
