import { orderBy } from "lodash-es";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "reactfire";
import { QueryState, updateEntities } from "redux-query";
import { getNotifications as getNotificationsQuery, GetNotificationsOptions } from "../queries/api/notificationQuery";
import { RootState } from "../store";
import { NotificationsAPI } from "../typings/API";
import useControlledRequest from "./useControlledRequest";

export type UseNotificationList = () => {
  notifications: (
    | NotificationsAPI.GlobalNotification
    | NotificationsAPI.PostNotification
    | NotificationsAPI.UserNotification
    | NotificationsAPI.ProductNotification
    | NotificationsAPI.OrderNotification
  )[];
  fetchNotificationsQueryState: QueryState;
  refreshQueryState: QueryState;
  fetchNotifications: () => Promise<void>;
  refreshNotifications: () => Promise<void>;
  // hasMore: boolean; // todo: add back later
};

const useNotificationList: UseNotificationList = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  // const [hasMore, setHasMore] = useState<boolean>(true); // todo: add back later

  const postNotifications = useSelector((state: RootState) => state.entities.postNotificationsById);
  const userNotifications = useSelector((state: RootState) => state.entities.userNotificationsById);
  const globalNotifications = useSelector((state: RootState) => state.entities.globalNotificationsById);
  const productNotifications = useSelector((state: RootState) => state.entities.productNotificationsById);
  const orderNotifications = useSelector((state: RootState) => state.entities.orderNotificationsById);

  const sortedNotifications = useMemo(() => {
    const combinedNotifications = [
      ...Object.values(globalNotifications),
      ...Object.values(postNotifications),
      ...Object.values(userNotifications),
      ...Object.values(productNotifications),
      ...Object.values(orderNotifications),
    ];
    return orderBy(combinedNotifications, (n) => n.created_at, ["desc"]);
  }, [postNotifications, userNotifications, globalNotifications, productNotifications, orderNotifications]);

  const getFeedNotificationsConfig = useCallback(
    (options: GetNotificationsOptions) => getNotificationsQuery(options, { force: true }),
    [],
  );

  const [fetchNotificationsQueryState, getNotifications] = useControlledRequest<
    typeof getNotificationsQuery,
    { posts: NotificationsAPI.Notification[] }
  >(getFeedNotificationsConfig);

  const [refreshQueryState, refresh] = useControlledRequest<
    typeof getNotificationsQuery,
    { posts: NotificationsAPI.Notification[] }
  >(getFeedNotificationsConfig);

  const fetchNotifications = useCallback(async () => {
    if (auth.currentUser?.uid) {
      getNotifications({ offset: sortedNotifications.length }, { force: true });
    }
  }, [getNotifications, auth.currentUser?.uid, sortedNotifications]);

  const refreshNotifications = useCallback(async () => {
    if (auth.currentUser?.uid) {
      // clear current notifications
      dispatch(
        updateEntities({
          postNotificationsById: () => ({}),
          userNotificationsById: () => ({}),
          globalNotificationsById: () => ({}),
          productNotificationsById: () => ({}),
        }),
      );
      refresh({ offset: 0 }, { force: true });
    }
  }, [dispatch, refresh, auth.currentUser?.uid]);

  // initial fetch of notifications
  useEffect(() => {
    // todo: just replace this with refresh notifications
    if (auth.currentUser?.uid && sortedNotifications.length === 0) {
      refresh({ offset: 0 }, { force: true });
    }
  }, [refresh, auth.currentUser?.uid, sortedNotifications.length]);

  return useMemo(
    () => ({
      // hasMore,
      notifications: sortedNotifications,
      fetchNotifications,
      fetchNotificationsQueryState,
      refreshNotifications,
      refreshQueryState,
    }),
    [
      sortedNotifications,
      refreshNotifications,
      fetchNotifications,
      fetchNotificationsQueryState,
      refreshQueryState,
      // hasMore,
    ],
  );
};

export default useNotificationList;
