import React, { lazy, Suspense, useCallback } from "react";
import { ErrorBoundary, ErrorBoundaryProps } from "react-error-boundary";
import { Redirect, Route, Switch } from "react-router-dom";
import { retry } from "ts-retry-promise";
import { isMobileFrame } from "../config";
import { EntitlementID } from "../enums/RevenueCat";
import { captureException } from "../helpers/errorUtil";
import useHistoryDecorators from "../hooks/useHistoryDecorators";
import { Loader } from "../libs/semantic-ui";
import CheckoutPage from "../pages/CheckoutPage";
import RestocksPage from "../pages/RestocksPage/RestocksPage";
import MePage from "../pages/UserProfilePage/MePage";
import AppDownloadToast from "./AppDownloadToast";
import AppMeta from "./AppMeta";
import FallbackError from "./FallbackError";
import Page from "./Layout/Page";
import ScreenSize from "./Layout/ScreenSize";
import ProtectedRoute from "./ProtectedRoute";

const suspenseLoader = (
  <Page hideFooter={true}>
    <Loader active>Loading...</Loader>
  </Page>
);

const SearchMenu = lazy(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "SearchMenu" */
        /* webpackPrefetch: true */
        "./SearchMenu"
      ),
    { retries: 5 },
  ),
);
const AdminPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "AdminPage" */ "../pages/AdminPage"), { retries: 5 }),
);
const Alarm = lazy(() => retry(() => import(/* webpackChunkName: "Alarm" */ "./Alarm"), { retries: 5 }));
const AuthPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "AuthPage" */ "../pages/AuthPage"), { retries: 5 }),
);
const BlogLandingPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "BlogLandingPage" */ "../pages/BlogLandingPage"), { retries: 5 }),
);
const BrandPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "BrandPage" */ "../pages/BrandPage"), { retries: 5 }),
);
const BrandsPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "BrandsPage" */ "../pages/BrandsPage"), { retries: 5 }),
);
const Collection = lazy(() => retry(() => import(/* webpackChunkName: "Collection" */ "./Collection"), { retries: 5 }));
const PlugdGoldPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "PlugdGoldPage" */ "../pages/PlugdGoldPage"), { retries: 5 }),
);
const ConversationPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "ConversationPage" */ "../pages/ConversationPage"), { retries: 5 }),
);
const ConversationsPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "ConversationsPage" */ "../pages/ConversationsPage"), { retries: 5 }),
);
const CommunityPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "CommunityPage" */ "../pages/CommunityPage"), { retries: 5 }),
);

const DesktopTopNav = lazy(() =>
  retry(() => import(/* webpackChunkName: "DesktopTopNav" */ "./TopNav/DesktopTopNav"), { retries: 5 }),
);
const FeedPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "FeedPage" */ "../pages/FeedPage"), { retries: 5 }),
);
const DiscoverPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "DiscoverPage" */ "../pages/DiscoverPage"), { retries: 5 }),
);
const InboxPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "InboxPage" */ "../pages/InboxPage"), { retries: 5 }),
);
const UserInvitePage = lazy(() =>
  retry(() => import(/* webpackChunkName: "UserInvitePage" */ "../pages/UserInvitePage"), { retries: 5 }),
);
const LeaderboardPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "LeaderboardPage" */ "../pages/LeaderboardPage"), { retries: 5 }),
);
const MonetizationWaitlistAboutPage = lazy(() =>
  retry(
    () => import(/* webpackChunkName: "MonetizationWaitlistAboutPage" */ "../pages/MonetizationWaitlist/AboutPage"),
    { retries: 5 },
  ),
);
const MonetizationWaitlistLandingPage = lazy(() =>
  retry(
    () => import(/* webpackChunkName: "MonetizationWaitlistLandingPage" */ "../pages/MonetizationWaitlist/LandingPage"),
    { retries: 5 },
  ),
);
const MonetizationWaitlistPositionPage = lazy(() =>
  retry(
    () =>
      import(/* webpackChunkName: "MonetizationWaitlistPositionPage" */ "../pages/MonetizationWaitlist/PositionPage"),
    { retries: 5 },
  ),
);
const MonetizationWaitlistApprovedPage = lazy(() =>
  retry(
    () =>
      import(/* webpackChunkName: "MonetizationWaitlistApprovedPage" */ "../pages/MonetizationWaitlist/ApprovedPage"),
    { retries: 5 },
  ),
);
const NotFoundPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "NotFoundPage" */ "../pages/NotFoundPage"), { retries: 5 }),
);
const PostPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "PostPage" */ "../pages/PostPage"), { retries: 5 }),
);
const ProductPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "ProductPage" */ "../pages/ProductPage"), { retries: 5 }),
);
const RafflesPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "RafflesPage" */ "../pages/RafflesPage"), { retries: 5 }),
);
const ReleaseCalendarPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "ReleaseCalendarPage" */ "../pages/ReleaseCalendarPage"), { retries: 5 }),
);
const SearchPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "SearchPage" */ "../pages/SearchPage"), { retries: 5 }),
);
const StylePage = lazy(() =>
  retry(() => import(/* webpackChunkName: "StylePage" */ "../pages/StylePage"), { retries: 5 }),
);
const SubscriptionsPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "SubscriptionsPage" */ "../pages/SubscriptionsPage"), { retries: 5 }),
);
const UserProfilePage = lazy(() =>
  retry(() => import(/* webpackChunkName: "UserProfilePage" */ "../pages/UserProfilePage"), { retries: 5 }),
);
const VendorCollectionPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "VendorCollectionPage" */ "../pages/VendorCollectionPage"), { retries: 5 }),
);
const VendorLandingPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "VendorLandingPage" */ "../pages/VendorLandingPage"), { retries: 5 }),
);
const VendorProductPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "VendorProductPage" */ "../pages/VendorProductPage"), { retries: 5 }),
);

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  useHistoryDecorators();

  const onError = useCallback<Required<ErrorBoundaryProps>["onError"]>((error) => captureException(error), []);

  return (
    <>
      <AppMeta />
      <AppDownloadToast />
      <ErrorBoundary FallbackComponent={FallbackError} onError={onError}>
        <Suspense fallback={suspenseLoader}>
          <SearchMenu />
          <ScreenSize sizes={!isMobileFrame ? ["large"] : []}>
            <DesktopTopNav />
          </ScreenSize>
          <Switch>
            {/* Feed Pages */}
            <Route path={["/feed", "/for-you"]}>
              <Redirect to="/" />
            </Route>
            <Route path={"/popular"} component={FeedPage} />
            <Route path={"/for-you"} component={FeedPage} />
            <Route path={"/listings"} component={FeedPage} />
            <Route exact path="/" component={FeedPage} />
            {/* Checkout */}
            <Route path="/checkout" component={CheckoutPage} />
            {/* Discover */}
            <Route path="/discover" component={DiscoverPage} />
            {isMobileFrame && <Route path="/blog" component={BlogLandingPage} />}
            <Route path={["/login", "/signup", "/reset-password"]} component={AuthPage} />
            {/* Monetization Waitlist */}
            <Route path="/monetization-waitlist/approved" component={MonetizationWaitlistApprovedPage} />
            <Route path="/monetization-waitlist/position" component={MonetizationWaitlistPositionPage} />
            <Route path="/monetization-waitlist/about" component={MonetizationWaitlistAboutPage} />
            <Route path="/monetization-waitlist" component={MonetizationWaitlistLandingPage} />
            {/* Drops */}
            <Route path="/release-calendar" component={ReleaseCalendarPage} />
            <Route path="/raffles" component={RafflesPage} />
            <ProtectedRoute path="/restocks" entitlementId={EntitlementID.GOLD}>
              <RestocksPage />
            </ProtectedRoute>
            {/* Products Tab */}
            <Route path="/products/release-calendar">
              <Redirect to="/release-calendar" />
            </Route>
            <Route path="/products/brands/:brandSlug" component={BrandPage} />
            <Route path="/products/brands" component={BrandsPage} />
            <Route path="/products/:productSlug" component={ProductPage} />
            <Route path="/collections/:collectionSlug" component={Collection} />
            <Route path="/search" component={SearchPage} />
            <Route path="/styles/:themeKey" component={StylePage} />
            <Route path="/styles" component={StylePage} />
            {/* Vendor Pages */}
            <Route path="/vendors/:vendorSlug/products/:productSlug" component={VendorProductPage} />
            <Route path="/vendors/:vendorSlug/collections/:collectionSlug" component={VendorCollectionPage} />
            <Route exact path="/vendors/:vendorSlug" component={VendorLandingPage} />
            {/* Post Page */}
            <Route path="/posts/:postId" component={PostPage} />
            {/* Profile Tab */}
            <Route path="/me" component={MePage} />
            <Route path="/u/:username" component={UserProfilePage} />
            {/* Inbox Tab */}
            <Route path="/inbox" component={InboxPage} />
            <ProtectedRoute path="/invite" component={UserInvitePage} />
            <ProtectedRoute path="/conversations/u/:username">
              <ConversationPage />
            </ProtectedRoute>
            <ProtectedRoute path="/conversations">
              <ConversationsPage />
            </ProtectedRoute>
            {/* <Route path="/chat" component={ChatPage} /> */}
            <Route path="/leaderboard" component={LeaderboardPage} />
            <Route path="/subscriptions" component={SubscriptionsPage} />
            <ProtectedRoute path="/alarms" component={Alarm} />
            <ProtectedRoute path="/admin" role="admin">
              <AdminPage />
            </ProtectedRoute>
            <Route path="/c/:communitySlug" component={CommunityPage} />
            <Route path="/gold" component={PlugdGoldPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default App;
