import React, { useContext } from "react";
import useSeller from "../../hooks/useSeller";
import { PostTypeKey } from "../../typings/API-V2";
import NavTabs, { NavTabBox } from "../NavTabs";
import PostMenuContext from "./PostMenuContext";

export interface PostMenuNavTabsProps {
  activeTab: PostTypeKey;
}

const PostMenuNavTabs: React.FC<PostMenuNavTabsProps> = ({ activeTab }) => {
  const { setPostType } = useContext(PostMenuContext);
  const { canSell } = useSeller();

  return (
    <NavTabs>
      <NavTabBox onClick={() => setPostType(PostTypeKey.Text)} isActive={activeTab === PostTypeKey.Text}>
        Text
      </NavTabBox>
      <NavTabBox onClick={() => setPostType(PostTypeKey.Image)} isActive={activeTab === PostTypeKey.Image}>
        Image
      </NavTabBox>
      <NavTabBox onClick={() => setPostType(PostTypeKey.Link)} isActive={activeTab === PostTypeKey.Link}>
        Link
      </NavTabBox>
      {/* TODO: expose to all users after GA release */}
      {canSell && (
        <NavTabBox onClick={() => setPostType(PostTypeKey.Listing)} isActive={activeTab === PostTypeKey.Listing}>
          Sell
        </NavTabBox>
      )}
    </NavTabs>
  );
};

export default PostMenuNavTabs;
