import React, { ReactNode, useCallback } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import { EntitlementID } from "../enums/RevenueCat";
import { Dimmer, Loader } from "../libs/semantic-ui";
import { RootState } from "../store";
import Page from "./Layout/Page";

export interface ProtectedRouteProps extends RouteProps {
  role?: "admin"; // only value for now
  entitlementId?: EntitlementID;
  loadingComponent?: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  children,
  role,
  loadingComponent,
  entitlementId,
  ...rest
}) => {
  const { status, data: signInCheckResult } = useSigninCheck(role ? { requiredClaims: { role } } : undefined);

  const hasRequiredClaims = signInCheckResult?.hasRequiredClaims;
  const isPending = status === "loading";
  const isFinished = status === "success" || status === "error";

  const hasEntitlement = useSelector((state: RootState) =>
    entitlementId ? Boolean(state.entities.currentUserProfile?.active_entitlements.includes(entitlementId)) : true,
  );

  const handleRender = useCallback<Required<RouteProps>["render"]>(
    (routeProps) => {
      /** authentication status is pending */
      if (isPending) {
        return (
          loadingComponent ?? (
            <Page>
              <Dimmer active={isPending}>
                <Loader active={isPending} />
              </Dimmer>
            </Page>
          )
        );
      }

      /** authentication status has been fetched, but user is not signed in or doesn't have the required claims */
      if (isFinished && (!signInCheckResult?.signedIn || !hasRequiredClaims || !hasEntitlement)) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location },
            }}
          />
        );
      }

      // user is authenticated, render the route
      return Component ? <Component {...routeProps} /> : children;
    },
    [children, Component, hasRequiredClaims, isFinished, isPending, loadingComponent, signInCheckResult?.signedIn],
  );

  return <Route {...rest} render={handleRender} />;
};

export default ProtectedRoute;
