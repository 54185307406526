import React, { useMemo } from "react";
import styled, { DefaultTheme, ThemeColor } from "styled-components";
import { color, ColorProps } from "styled-system";
import { Icon as SemanticIcon, IconProps as SemanticIconProps } from "../../libs/semantic-ui";
import { getThemeColor } from "../utils/stylesUtil";

export interface IconProps extends Omit<SemanticIconProps, "color">, Omit<ColorProps<DefaultTheme>, "color"> {
  color?: ThemeColor;
}

const Icon: React.FC<IconProps> = ({ color, style, ...iconProps }) => {
  const styleWithColor = useMemo(
    () => ({
      ...style,
      color: getThemeColor(color),
    }),
    [style, color],
  );
  return <IconComponent {...iconProps} style={styleWithColor} />;
};

const IconComponent = styled(SemanticIcon)<IconProps>`
  ${color}
`;

export default Icon;
