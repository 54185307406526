import React from "react";
import ScreenSize from "../../../Layout/ScreenSize";
import NavTabs, { DEFAULT_NAV_TABS_HEIGHT, NavTabBox } from "../../../NavTabs";

export const HEIGHT = DEFAULT_NAV_TABS_HEIGHT;

export interface DebugMenuTabsProps {
  activeScreen: "webview" | "nativeapp";
  setActiveScreen: React.Dispatch<React.SetStateAction<"webview" | "nativeapp">>;
}

const DebugMenuTabs: React.FC<DebugMenuTabsProps> = ({ activeScreen, setActiveScreen }) => {
  const selectTab = (newTab: "webview" | "nativeapp") => () => {
    setActiveScreen(newTab);
  };

  return (
    <ScreenSize sizes={["small", "medium"]}>
      <NavTabs fixed>
        <NavTabBox isActive={activeScreen === "webview"} onClick={selectTab("webview")}>
          Web View
        </NavTabBox>
        <NavTabBox isActive={activeScreen === "nativeapp"} onClick={selectTab("nativeapp")}>
          Native App
        </NavTabBox>
      </NavTabs>
    </ScreenSize>
  );
};

export default DebugMenuTabs;
