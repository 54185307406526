import React, { PropsWithChildren } from "react";
import DesktopBackToTopButton from "../../pages/FeedPage/DesktopBackToTopButton";
import { Box, Spacer } from "../../UI";
import AsideFooter from "../Footer/AsideFooter";
import ScreenSize from "./ScreenSize";

export interface SecondaryAsideProps {}

/**
 * The secondary aside is the aside shown on desktop on the right side
 */
const SecondaryAside: React.FC<PropsWithChildren<SecondaryAsideProps>> = ({ children }) => {
  return (
    <ScreenSize sizes="large">
      <Box width="100%" mr={4}>
        {children}
        <AsideFooter />
        <Spacer mt={3} />
        <DesktopBackToTopButton />
      </Box>
    </ScreenSize>
  );
};

export default SecondaryAside;
