import { toLower } from "lodash-es";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useAuth } from "reactfire";
import { useMutation } from "redux-query-react";
import styled from "styled-components";
import { isValidUsername } from "../../helpers/profileUtils";
import { ButtonContent, Loader } from "../../libs/semantic-ui";
import { updateCurrentUserProfile } from "../../queries/api/userProfileQuery";
import { UsersAPI } from "../../typings/API";
import { Box, Button, Text } from "../../UI";
import AuthScreenContentHeader from "../AuthScreenModal/AuthScreenContentHeader";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import { AuthInput, InputWrapper } from "./AuthInput";

export interface UsernameFormProps {}

const UsernameForm: React.FC<UsernameFormProps> = () => {
  const auth = useAuth();
  const [err, setErr] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const { setAuthScreen } = useContext(AuthScreensContext);

  const [, updateProfile] = useMutation((userId: string, userFields: Partial<UsersAPI.PublicProfile>) =>
    updateCurrentUserProfile(userId, userFields),
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (!auth.currentUser?.uid) {
      return;
    }
    try {
      const updateResults = await updateProfile(auth.currentUser.uid, { username: username });
      if (updateResults?.status === 204 || updateResults?.status === 200) {
        setErr(null);
      } else if (updateResults?.status === 422) {
        setErr("Only lowercase alphanumeric characters allowed.");
      } else {
        setErr("An error occurred while making changes to your profile, please try again later.");
      }
    } catch (error) {
      setErr("An error occurred while making changes to your profile, please try again later.");
    } finally {
      setLoading(false);
      if (err) {
        setErr(null);
      }
      setAuthScreen("onboardFollowProducts");
    }
  }, [updateProfile, auth.currentUser?.uid, username, err, setAuthScreen]);

  const handleChange = useCallback(
    (event): void => {
      const formattedUsername = toLower(event.target.value.replace(/\s/g, "_"));
      setUsername(formattedUsername);
    },
    [setUsername],
  );

  const canSubmit = useMemo(() => {
    return isValidUsername(username);
  }, [username]);

  return (
    <>
      <AuthScreenContentHeader
        title={"Create your username"}
        description={
          <Text>
            {
              "Choose a username 3-10 characters long. Usernames can contain letters (a-z), numbers (0-9), and periods (.). You can always change this later."
            }
          </Text>
        }
      />
      <Box width="100%" mt={2} mb={3}>
        <InputWrapper>
          <AuthInput value={username} onChange={handleChange} autoComplete="username" placeholder="Username" />
        </InputWrapper>
      </Box>
      <NextButton
        mb={2}
        variant={canSubmit ? "primary" : "disabled"}
        type="submit"
        size="big"
        disabled={!canSubmit}
        onClick={handleSubmit}
      >
        <ButtonContent>{loading ? <Loader inverted active inline="centered" size="tiny" /> : "Next"}</ButtonContent>
      </NextButton>
      {err && <Text color="red">{err}</Text>}
    </>
  );
};

const NextButton = styled(Button)`
  width: 100%;
`;

export default UsernameForm;
