import React, { useMemo } from "react";
import { MarketingCampaign, MarketingMedium } from "../../../enums/Marketing";
import events from "../../../helpers/analyticsUtil";
import { generateOgTagsForUser, OgTags } from "../../../helpers/shareUtils";
import useShare from "../../../hooks/useShare";
import { UsersAPI } from "../../../typings/API";
import ActionsMenuItem, { ActionsMenuItemProps } from "../ActionsMenuItem";

export interface ShareUserActionsMenuItemProps extends ActionsMenuItemProps {
  user: UsersAPI.PublicProfile | null;
}

const ShareUserActionsMenuItem: React.FC<ShareUserActionsMenuItemProps> = ({ user, ...menuItemProps }) => {
  const userOgTags = useMemo<OgTags | undefined>(() => (user ? generateOgTagsForUser(user) : undefined), [user]);
  const { share } = useShare({
    url: document.location.href,
    title: userOgTags?.$og_title || `@${user?.username} on Plugd`,
    medium: MarketingMedium.USER,
    campaign: MarketingCampaign.ORGANIC,
    ogTags: userOgTags,
    shareEvent: events.UserProfile.Shared,
  });

  return <ActionsMenuItem {...menuItemProps} onClick={share} />;
};

export default ShareUserActionsMenuItem;
