import { ConfirmationResult, getAdditionalUserInfo, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React, { lazy, Suspense, useCallback, useContext, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useAuth } from "reactfire";
import { useMutation } from "redux-query-react";
import styled from "styled-components";
import { retry } from "ts-retry-promise";
import LocalStorageKey from "../../../enums/LocalStorageKey";
import * as localStorageUtil from "../../../helpers/localStorageUtil";
import { ButtonContent, Loader } from "../../../libs/semantic-ui";
import { createCurrentUserProfile } from "../../../queries/api/userProfileQuery";
import { UsersAPI } from "../../../typings/API";
import { Box, Button, Text } from "../../../UI";
import AuthScreenContentHeader from "../../AuthScreenModal/AuthScreenContentHeader";
import AuthScreensContext from "../../AuthScreenModal/AuthScreensContext";
import usePostAuthentication from "../hooks/usePostAuthentication";

const PhoneInput = lazy(() => retry(() => import(/* webpackChunkName: "PhoneInput" */ "./PhoneInput"), { retries: 5 }));
const ConfirmationCodeInput = lazy(() =>
  retry(() => import(/* webpackChunkName: "ConfirmationCodeInput" */ "./ConfirmationCodeInput"), { retries: 5 }),
);

export interface PhoneLoginFormProps {
  onClose?: () => void;
  onPhoneInputSuccess?: () => void;
  onConfirmSuccess?: (isNewUser: boolean) => void;
  recaptchaSize?: "small" | "invisible";
}

const PhoneLoginForm: React.FC<PhoneLoginFormProps> = ({ onClose, onPhoneInputSuccess, recaptchaSize }) => {
  const refUserId = localStorageUtil.getStringItem(LocalStorageKey.REFERRAL_USER_ID);
  const auth = useAuth();
  const { addToast } = useToasts();
  const [, initProfile] = useMutation((userFields: UsersAPI.UserProfileInsert) =>
    createCurrentUserProfile(userFields, refUserId),
  );
  const postAuthentication = usePostAuthentication({ skipToast: true });
  const [phone, setPhone] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [err, setErr] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
  const { setAuthScreen, onAuthenticated, screen } = useContext(AuthScreensContext);
  const captchaRef = React.useRef<HTMLDivElement>(null);
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | undefined>(undefined);

  const handleSubmit = useCallback(async () => {
    let recaptchaVerifier = recaptcha;
    try {
      if (captchaRef.current) {
        if (!recaptchaVerifier) {
          recaptchaVerifier = new RecaptchaVerifier(
            captchaRef.current,
            {
              size: recaptchaSize || "invisible",
            },
            auth,
          );
          setRecaptcha(recaptchaVerifier);
        }
        const confirmationResult = await signInWithPhoneNumber(auth, `+${phone}`, recaptchaVerifier);
        setConfirmationResult(confirmationResult);
        if (onPhoneInputSuccess) {
          onPhoneInputSuccess();
        }
        setErr("");
        setRecaptcha(undefined);
        recaptchaVerifier.clear();
        setAuthScreen("phoneConfirm");
      }
    } catch (error) {
      if (error instanceof Error) {
        setErr(error.message);
      }
    }
    setLoading(false);
    addToast(`Code successfully sent to ${phone}`, {
      autoDismiss: true,
      appearance: "success",
    });
  }, [recaptcha, recaptchaSize, addToast, phone, auth, onPhoneInputSuccess, setAuthScreen]);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    if (confirmationResult && verificationCode) {
      try {
        const authResult = await confirmationResult.confirm(verificationCode);
        if (authResult && authResult.user) {
          const additionalUserInfo = getAdditionalUserInfo(authResult);
          const isNewUser = additionalUserInfo?.isNewUser;
          postAuthentication(additionalUserInfo);
          if (isNewUser) {
            await initProfile({
              id: authResult.user.uid,
              full_name: authResult.user.displayName || undefined,
              email: authResult.user.email || undefined,
              username: authResult.user.uid,
              profile_image: authResult.user.photoURL || undefined,
              phone_number: authResult.user.phoneNumber || undefined,
            });
            setAuthScreen("createUsername");
          } else {
            if (onAuthenticated) {
              onAuthenticated();
            }
            if (onClose) {
              onClose();
            }
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setErr(error.message);
        }
      }
    }
    setLoading(false);
  }, [confirmationResult, initProfile, onAuthenticated, onClose, postAuthentication, setAuthScreen, verificationCode]);

  return (
    <>
      <AuthScreenContentHeader
        title={screen === "phoneInput" ? "Enter your phone number" : "Enter your confirmation code"}
        description={
          screen === "phoneInput" ? (
            <Text>{"We'll send you a message with your 6 digit confirmation code."}</Text>
          ) : screen === "phoneConfirm" ? (
            <Text>{"The confirmation code was sent to your phone number."}</Text>
          ) : undefined
        }
      />

      <Box width="100%" mt={2} mb={3}>
        {screen === "phoneInput" ? (
          <Suspense fallback={null}>
            <PhoneInput phone={phone} onChange={setPhone} />
          </Suspense>
        ) : (
          <Suspense fallback={null}>
            <ConfirmationCodeInput onChange={setVerificationCode} />
          </Suspense>
        )}
      </Box>

      <NextButton
        mb={2}
        variant="primary"
        type="submit"
        size="big"
        onClick={screen === "phoneInput" ? handleSubmit : handleConfirm}
      >
        <ButtonContent>{loading ? <Loader inverted active inline="centered" size="tiny" /> : "Next"}</ButtonContent>
      </NextButton>
      {err && <Text color="red">{err}</Text>}
      {screen === "phoneConfirm" && (
        <Box mt={1}>
          <Text>{"Didn't receive code? "}</Text>
          <Text color="blue" fontWeight={5} clickable={true} onClick={handleSubmit}>
            Request Again
          </Text>
        </Box>
      )}
      <Box mt={1} ref={captchaRef} />
    </>
  );
};

const NextButton = styled(Button)`
  width: 100%;
`;

export default PhoneLoginForm;
