import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "redux-query-react";
import { captureException } from "../../../helpers/errorUtil";
import { actionPromiseToPromise } from "../../../helpers/reactQueryUtil";
import {
  archivePost as archivePostQuery,
  unarchivePost as unarchivePostQuery,
} from "../../../queries/api-v2/postQuery";
import { PostsAPI } from "../../../typings/API-V2";
import ActionsMenuItem, { ActionsMenuItemProps } from "../ActionsMenuItem";

export interface PostActionsArchiveMenuItemProps extends ActionsMenuItemProps {
  post: PostsAPI.Post;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PostActionsArchiveMenuItem: React.FC<React.PropsWithChildren<PostActionsArchiveMenuItemProps>> = ({
  post,
  onClick,
  ...menuItemProps
}) => {
  const [{ isPending: archiveIsPending }, archivePost] = useMutation(archivePostQuery);
  const [{ isPending: unarchiveIsPending }, unarchivePost] = useMutation(unarchivePostQuery);
  const isPending = archiveIsPending || unarchiveIsPending;
  const { addToast } = useToasts();

  const handleArchivePost = useCallback(
    async (e) => {
      try {
        await actionPromiseToPromise(post.is_archived ? unarchivePost(post.id) : archivePost(post.id));
        addToast(`Post updated`, {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (error) {
        addToast(`An error occurred`, {
          appearance: "warning",
          autoDismiss: true,
        });
        captureException(error);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [addToast, archivePost, onClick, post.id, post.is_archived, unarchivePost],
  );

  return <ActionsMenuItem {...menuItemProps} onClick={!isPending ? handleArchivePost : undefined} />;
};

export default PostActionsArchiveMenuItem;
