import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useAuth } from "reactfire";
import { useMutation, useRequest } from "redux-query-react";
import styled from "styled-components";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import useUpcomingReleasesRafflesProducts from "../../hooks/useUpcomingReleasesRafflesProducts";
import { addProductsToFavorites } from "../../queries/api/favoriteProductsQuery";
import { getFavoriteRecommendations } from "../../queries/api/productQuery";
import { RootState } from "../../store";
import { Box } from "../../UI";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import ProductFollowList from "../Product/ProductFollow/ProductFollowList";
import StickyFollowButton from "./StickyFollowButton";

export interface FollowProductsOnboardProps {
  onClose: () => void;
}

const FollowProductsOnboard: React.FC<FollowProductsOnboardProps> = ({ onClose }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const auth = useAuth();
  const [, followProducts] = useMutation(addProductsToFavorites);
  const { onAuthenticated, setAuthScreen } = useContext(AuthScreensContext);

  /**
   * UPCOMING PRODUCTS
   */
  const { products: upcomingProducts, isFinished: upcomingProductsFinished } = useUpcomingReleasesRafflesProducts();
  const [selectedUpcomingProductIds, setSelectedUpcomingProductIds] = useState<Set<number>>(new Set());

  /**
   * POPULAR PRODUCTS
   */
  const [{ isFinished: popularProductsFinished }] = useRequest(getFavoriteRecommendations());
  const popularProductIds = useSelector((state: RootState) => state.entities.productFavoriteSuggestionIds);
  const productsById = useSelector((state: RootState) => state.entities.productsById);
  const [selectedPopularProductIds, setSelectedPopularProductIds] = useState<Set<number>>(new Set());
  const popularProducts = useMemo(
    () => popularProductIds.map((pid) => productsById[pid]),
    [popularProductIds, productsById],
  );

  /** aggregate isFinished value */
  const aggIsFinished = upcomingProductsFinished && popularProductsFinished;
  /** aggregate selected product ids size value */
  const aggSelectedProductIds = useMemo<Set<number>>(() => {
    const selectedProductIds = new Set<number>();
    selectedUpcomingProductIds.forEach((id) => selectedProductIds.add(id));
    selectedPopularProductIds.forEach((id) => selectedProductIds.add(id));
    return selectedProductIds;
  }, [selectedUpcomingProductIds, selectedPopularProductIds]);

  const handleNext = useCallback(() => {
    /** close the auth screen */
    if (auth.currentUser?.uid && aggSelectedProductIds.size > 0) {
      followProducts(auth.currentUser?.uid, Array.from(aggSelectedProductIds));
      trackEvent(events.Product.AddedToFavorites);
    }

    setAuthScreen("onboardFollowUsers");
  }, [addToast, auth.currentUser?.uid, followProducts, history, onAuthenticated, onClose, aggSelectedProductIds]);

  return (
    <ListWrapper>
      <ProductFollowList
        loading={!upcomingProductsFinished}
        mt={3}
        products={upcomingProducts}
        selectedProductIds={selectedUpcomingProductIds}
        setSelectedProductIds={setSelectedUpcomingProductIds}
        title={"Product Launches"}
      />
      <ProductFollowList
        loading={!popularProductsFinished}
        mt={3}
        products={popularProducts}
        selectedProductIds={selectedPopularProductIds}
        setSelectedProductIds={setSelectedPopularProductIds}
        title={"Popular Products"}
      />
      <Box mb={4} flexGrow={1} />
      <StickyFollowButton
        isFinished={aggIsFinished}
        onClick={handleNext}
        variant={aggSelectedProductIds.size > 0 ? "primary" : "secondary"}
      >
        {aggSelectedProductIds.size > 0 ? `Follow` : `Skip`}
      </StickyFollowButton>
    </ListWrapper>
  );
};

const ListWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export default FollowProductsOnboard;
