import React, { ReactNode, useMemo, useRef } from "react";
import styled from "styled-components";
import { isMobileWeb } from "../../config";
import useScrollPersist from "../../hooks/useScrollPersist";
import { Box, BoxProps } from "../../UI";
import Footer from "../Footer";
import { TopNavSpacer, TOP_NAV_HEIGHT } from "./TopNav";

export interface CustomPageProps {
  fixedFooter?: ReactNode;
  hideFooter?: boolean;
  hideSpacer?: boolean;
  onPullToRefresh?: () => void;
  scrollLockDisabled?: boolean;
}

export type PageProps = CustomPageProps & React.HTMLAttributes<HTMLDivElement> & BoxProps;

export const PageContext = React.createContext<{ pageRef: React.MutableRefObject<HTMLDivElement | null> }>({
  pageRef: { current: null },
});

const Page: React.FC<PageProps> = ({ children, fixedFooter, hideFooter, hideSpacer, ...pageWrapperProps }) => {
  useScrollPersist();
  const pageRef = useRef<HTMLDivElement>(null);
  const pageProviderValue = useMemo(() => ({ pageRef }), []);

  return (
    <PageContext.Provider value={pageProviderValue}>
      {!hideSpacer && <TopNavSpacer />}
      <PageContent {...pageWrapperProps} ref={pageRef}>
        <>
          {children}
          {!hideFooter && isMobileWeb && <Footer />}
        </>
      </PageContent>
      {fixedFooter && fixedFooter}
    </PageContext.Provider>
  );
};

const PageContent = styled(Box)`
  min-height: calc(100vh - ${TOP_NAV_HEIGHT}px);
`;
PageContent.defaultProps = {
  pb: 0,
  alignItems: "center",
  flexDirection: "column",
  display: "flex",
  width: "100%",
  height: "100%",
};

export default Page;
