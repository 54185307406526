import { some } from "lodash-es";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CreatePostSelectorMenu from "../../components/Feed/CreatePostSelectorMenu";
import useMenuState from "../../hooks/useMenuState";
import usePreloadEffect from "../../hooks/usePreloadEffect";
import { RootState } from "../../store";
import { Box, Icon, Spacer } from "../../UI";
import ScreenSize from "../Layout/ScreenSize";
import { ToastContext } from "../Toast/ToastProvider";
import BottomNavIconLink from "./BottomNavIconLink";
import BottomNavInboxIconLink from "./BottomNavInboxIconLink";

export const BOTTOM_NAV_HEIGHT = 50;

export interface BottomNavProps {}

const BottomNav: React.FC<BottomNavProps> = () => {
  const stackStates = useSelector((state: RootState) => state.app.stack);
  const { open: openMenu, menuProps } = useMenuState();
  const { setBottomOffset } = useContext(ToastContext);

  /** To set the correct height of the toast container */
  useEffect(() => {
    setBottomOffset(BOTTOM_NAV_HEIGHT);
    return () => setBottomOffset(0);
  }, [setBottomOffset]);

  // preload routes if this component is
  usePreloadEffect([
    () => import(/* webpackChunkName: "DiscoverPage" */ "../../pages/DiscoverPage"),
    () => import(/* webpackChunkName: "FeedPage" */ "../../pages/FeedPage"),
    () => import(/* webpackChunkName: "InboxPage" */ "../../pages/InboxPage"),
    () => import(/* webpackChunkName: "UserProfilePage" */ "../../pages/UserProfilePage"),
  ]);

  return (
    <ScreenSize sizes={["small", "medium"]}>
      <>
        <Spacer mt={BOTTOM_NAV_HEIGHT} />
        <BottomNavWrapper>
          <BottomNavIconLink
            exact={true}
            iconName="home"
            isActive={(match, location) =>
              some([Boolean(match), location.pathname.includes(`/for-you`), location.pathname.includes(`/listings`)])
            }
            label="Home"
            pathname={stackStates.home.pathname}
            search={stackStates.home.search}
          />
          <BottomNavIconLink
            iconName="search"
            label="Discover"
            pathname={stackStates.discover.pathname}
            search={stackStates.discover.search}
          />
          <AddPostButtonWrapper onClick={openMenu}>
            <AddPostButton>
              <Icon name="add" color="lightGrey" />
            </AddPostButton>
          </AddPostButtonWrapper>
          <BottomNavIconLink
            iconName="bolt"
            label="Drops"
            pathname={`/release-calendar`}
            isActive={(match, location) =>
              some([
                Boolean(match),
                location.pathname.includes(`/release-calendar`),
                location.pathname.includes(`/raffles`),
                location.pathname.includes(`/restocks`),
              ])
            }
          />
          <BottomNavInboxIconLink iconName="envelope" label="Inbox" />
        </BottomNavWrapper>
        <CreatePostSelectorMenu {...menuProps} />
      </>
    </ScreenSize>
  );
};

const AddPostButtonWrapper = styled(Box)`
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomNavWrapper = styled(Box).attrs(() => ({
  as: "nav",
}))`
  z-index: 50;
  position: fixed;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${BOTTOM_NAV_HEIGHT}px;
`;
BottomNavWrapper.defaultProps = {
  px: 2,
  bg: "white",
  color: "black",
  borderTop: "1px solid",
  borderColor: "mediumGrey",
};

const BUTTON_SIZE = 36;
const AddPostButton = styled(Box).attrs(() => ({ role: "button" }))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${BUTTON_SIZE}px;
  width: ${BUTTON_SIZE}px;
  border-radius: ${BUTTON_SIZE / 2}px;
  background: ${({ theme }) => theme.colors.goldRed};
`;

export default BottomNav;
