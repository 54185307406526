import produce from "immer";
import { isBoolean, toString } from "lodash-es";
import { QueryConfig, Update } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { VendorsAPI } from "../../typings/API";
import { VendorsState } from "../../typings/EntitiesState";

export const vendorsToVendorsState = (vendors: VendorsAPI.VendorConfig[]): VendorsState =>
  vendors.reduce<VendorsState>(
    (result, vendor) => {
      result.vendorsById[vendor.id] = vendor;
      result.vendorIdsBySlug[vendor.slug] = vendor.id;
      if (vendor.partner_id) {
        result.partnerVendorIds.add(vendor.id);
      }
      return result;
    },
    {
      vendorsById: {},
      vendorIdsBySlug: {},
      partnerVendorIds: new Set<number>(),
    },
  );

export const vendorStateUpdates: Update<VendorsState> = {
  vendorsById: shallowObjectMerge,
  vendorIdsBySlug: shallowObjectMerge,
  partnerVendorIds: (oldValue, newValue) =>
    produce(oldValue, (draft) => {
      newValue.forEach((id) => draft.add(id));
      return draft;
    }),
};

export interface GetVendorsOptions {
  ids?: string; // 1,2,3
  is_partner?: boolean;
  has_prices?: boolean;
}

export const getVendors = (options?: GetVendorsOptions): QueryConfig<VendorsState> => {
  const searchParams = new URLSearchParams(); // spread to satisfy TS
  if (options?.ids) {
    searchParams.set("ids", options.ids);
  }
  if (options && isBoolean(options?.is_partner)) {
    searchParams.set("is_partner", toString(options.is_partner));
  }
  if (options && isBoolean(options?.has_prices)) {
    searchParams.set("has_prices", toString(options.has_prices));
  }
  const qs = searchParams.toString(); // spread to satisfy TS
  return {
    url: `${config.API_URL}/vendors?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (vendors: VendorsAPI.VendorConfig[]): VendorsState => vendorsToVendorsState(vendors),
    update: vendorStateUpdates,
  };
};
