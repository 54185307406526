import { useEffect, useMemo, useState } from "react";
import { useUser } from "reactfire";
import { EntitiesState } from "redux-query";
import { actionPromiseToPromise } from "../helpers/reactQueryUtil";
import { getOrder as getOrderQuery } from "../queries/api/stripeQuery";
import { StripeAPI, UsersAPI } from "../typings/API";
import useControlledRequest from "./useControlledRequest";

const useOrder = (
  orderId?: string | null,
): {
  isFinished: boolean;
  isPending: boolean;
  order: StripeAPI.Order | null;
  sellers: UsersAPI.PublicProfile[];
} => {
  const { data: currentUser, status: currentUserStatus } = useUser();
  const currentUserIsPending = currentUserStatus === "loading";
  const currentUserIsFinished = currentUserStatus === "error" || currentUserStatus === "success";
  const [sellers, setSellers] = useState<UsersAPI.PublicProfile[]>([]);
  const [order, setOrder] = useState<StripeAPI.Order | null>(null);
  const [{ isFinished, isPending }, getOrder] = useControlledRequest(getOrderQuery);

  useEffect(() => {
    const handleSession = async (oid: string) => {
      const { body } = await actionPromiseToPromise<
        Pick<EntitiesState, "stripeOrder" | "userProfilesById" | "userProfileIdsByUsername">,
        StripeAPI.Order.GetOrderResponse
      >(getOrder(oid));
      if (body?.data) {
        // set sellers
        setSellers(body.data.sellers);
        // set session
        setOrder(body.data.order);
      }
    };
    if (currentUser?.uid && orderId) {
      handleSession(orderId);
    }
  }, [getOrder, orderId, currentUser?.uid]);

  return useMemo(
    () => ({
      isFinished: currentUserIsFinished && isFinished,
      isPending: currentUserIsPending || isPending,
      order,
      sellers,
    }),
    [sellers, order, isFinished, isPending, currentUserIsFinished, currentUserIsPending],
  );
};

export default useOrder;
