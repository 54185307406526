import React from "react";
import RestocksAPI from "../../typings/API-V2/RestocksAPI";
import { Box } from "../../UI";
import useScreenSize from "../../UI/utils/useScreenSize";
import RestocksListItemBody from "./RestocksListItemBody";
import RestocksListItemHeader from "./RestocksListItemHeader";

export interface RestockListItemProps {
  restock: RestocksAPI.Restock;
}

const RestockListItem: React.FC<RestockListItemProps> = ({ restock }) => {
  const screenSize = useScreenSize();

  return (
    <Box bg="white" borderRadius={screenSize === "large" ? 3 : undefined} mb={3} p={3} pt={2} width="100%">
      <RestocksListItemHeader restock={restock} />
      <RestocksListItemBody restock={restock} />
    </Box>
  );
};

export default RestockListItem;
