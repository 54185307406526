import { noop } from "lodash-es";
import React, { useCallback, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import { captureException } from "../../helpers/errorUtil";
import { PostsAPI } from "../../typings/API-V2";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
  FullscreenMenuProps,
  FULLSCREEN_MENU_HEADER_HEIGHT,
} from "../Layout/FullscreenMenu";
import FullscreenMenuHeaderButton from "../Layout/FullscreenMenu/FullscreenMenuHeaderButton";
import useCreatePost from "./hooks/useCreatePost";
import usePatchPost from "./hooks/usePatchPost";

export const HEADER_HEIGHT = FULLSCREEN_MENU_HEADER_HEIGHT;

export type BasePostDataMeta<T, D> =
  | {
      type: T;
      canSubmit: false;
      message: string;
    }
  | {
      type: T;
      canSubmit: true;
      postData: D;
    };
export type PostInsertDataMeta = BasePostDataMeta<"post", PostsAPI.PostInsert>;
export type PostPatchDataMeta = BasePostDataMeta<"patch", PostsAPI.PostPatch>;
export type PostDataMeta = PostInsertDataMeta | PostPatchDataMeta;

export interface CreatePostMenuGenericProps extends FullscreenMenuProps {
  close: () => void;
  isOpen: boolean;
  onCreated?: () => void;
  postDataMeta: PostDataMeta;
  submitText?: string;
  title?: string;
}

const CreatePostMenuGeneric: React.FC<React.PropsWithChildren<CreatePostMenuGenericProps>> = ({
  children,
  close,
  isOpen,
  onCreated,
  postDataMeta,
  submitText,
  ...menuProps
}) => {
  const { addToast } = useToasts();
  const [{ isPending: createPostPending }, createPost] = useCreatePost(onCreated);
  const [{ isPending: patchPostPending }, patchPost] = usePatchPost();
  const isPending = createPostPending || patchPostPending;

  useEffect(() => {
    if (isOpen) {
      trackEvent(events.CreatePostMenu.Viewed);
    }
  }, [isOpen]);

  const onSubmit = useCallback(async () => {
    if (postDataMeta.canSubmit && !isPending) {
      try {
        if (postDataMeta.type === "post") {
          createPost(postDataMeta.postData);
        }
        if (postDataMeta.type === "patch") {
          patchPost(postDataMeta.postData);
        }
      } catch (error) {
        captureException(error);
        const errorMessage =
          typeof error === "string" ? error : error instanceof Error ? error.message : `Something went wrong`;
        addToast(errorMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        close();
      }
    }
  }, [addToast, close, createPost, patchPost, isPending, postDataMeta]);

  return (
    <FullscreenMenu
      isOpen={isOpen}
      close={close}
      display="flex"
      flexDirection="column"
      overflowY="inherit"
      height="100%"
      scrollLockProps={{ isActive: isOpen }}
      {...menuProps}
      header={
        <FullscreenMenuHeader px={3}>
          <FullscreenMenuHeaderIcon name="chevron left" onClick={close} />
          <FullscreenMenuHeaderTitle>
            {postDataMeta.type === "patch" ? "Edit Post" : "Create Post"}
          </FullscreenMenuHeaderTitle>
          <FullscreenMenuHeaderButton
            variant={postDataMeta.canSubmit ? "primary" : "disabled"}
            onClick={isPending || !postDataMeta.canSubmit ? noop : onSubmit}
          >
            {submitText || "Post"}
          </FullscreenMenuHeaderButton>
        </FullscreenMenuHeader>
      }
    >
      {children}
    </FullscreenMenu>
  );
};

export default CreatePostMenuGeneric;
