import { useCallback, useMemo, useState } from "react";

type RefCallback<N extends HTMLElement> = (node: N | null) => void;

/**
 * focuses the ref node on mount
 * @returns [refCallback, refNode]
 */
const useAutoFocusRefCallback = <N extends HTMLElement>(): [RefCallback<N>, N | null] => {
  const [refNode, setRef] = useState<N | null>(null);
  const handleRef = useCallback<RefCallback<N>>((node) => {
    if (node) {
      node.focus();
    }

    setRef(node);
  }, []);

  return useMemo(() => [handleRef, refNode], [handleRef, refNode]);
};

export default useAutoFocusRefCallback;
