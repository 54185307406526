import produce, { Draft } from "immer";
import { union } from "lodash-es";
import { Reducer } from "redux";
import ActionType from "../enums/ActionType";
import { ActionValue } from "../helpers/thunkUtil";

export interface PostsState {
  postListIds: {
    [listKey: string]: number[];
  };
}

const initialState = {
  postListIds: {},
};

const postsReducer = produce((draft: Draft<PostsState>, action: ActionValue) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case ActionType.APPEND_LIST_POSTS: {
      const currentList = draft.postListIds[action.payload.key] || [];
      draft.postListIds[action.payload.key] = union(currentList.concat(action.payload.postIds));
      return draft;
    }
    case ActionType.REPLACE_LIST_POSTS: {
      draft.postListIds[action.payload.key] = action.payload.postIds;
      return draft;
    }
    default:
      return draft as PostsState;
  }
}) as Reducer<PostsState, ActionValue>;

export default postsReducer;
