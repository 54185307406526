import { MarketingCampaign, MarketingMedium, MarketingSource } from "src/enums/Marketing";
import config from "../config";
import { CommunitiesAPI, ProductsAPI, UsersAPI } from "../typings/API";
import { PostsAPI } from "../typings/API-V2";

export interface OgTags {
  $og_desktop_url?: string;
  $og_title?: string;
  $og_description?: string;
  $og_image_url?: string;
  $og_video?: string;
  $og_redirect?: string;
}

export interface ShareLinkOptions {
  domain?: string;
  path?: string;
  campaign?: MarketingCampaign;
  title?: string;
  message?: string;
  source?: MarketingSource;
  medium?: MarketingMedium;
  og?: OgTags;
}

export const generateOgTagsForProduct = (product: ProductsAPI.Product): OgTags => ({
  $og_title: `${product.name} on Plugd`,
  $og_description: `Get pricing information on new & used sneakers including the ${product.name} on Plugd`,
  $og_image_url: product.image,
});

export const generateOgTagsForPost = (post: PostsAPI.Post): OgTags => ({
  $og_title: post.title || "",
  $og_description: post.content || undefined,
  $og_image_url: post.images && post.images.length > 0 ? post.images[0].url : undefined,
});

export const generateOgTagsForUser = (user: UsersAPI.PublicProfile): OgTags => {
  const displayName = user.id === user.username ? user.full_name : user.username;
  return {
    $og_title: displayName ? `${displayName} on Plugd` : "User on Plugd",
    $og_description: `@${user.username} - Check out fire fits and other sneaker & streetwear related content by ${
      user.full_name ? user.full_name : user.username
    }`,
    $og_image_url: user.profile_image || undefined,
  };
};

export const generateOgTagsForCommunity = (community: CommunitiesAPI.Community): OgTags => ({
  $og_title: `c/${community.slug} community on Plugd`,
  $og_description: `${community.name} - ${community.description ? community.description : "Join the discussion"}}`,
  $og_image_url: community.avatar_url || undefined,
});

export interface GenerateShareLinkOptions {
  campaign?: MarketingCampaign;
  data?: any;
  domain?: string;
  medium?: MarketingMedium;
  og?: OgTags;
  path?: string;
  source?: MarketingSource;
}
export const generateShareLink = async ({
  campaign,
  data,
  domain,
  medium,
  og,
  path,
  source,
}: GenerateShareLinkOptions): Promise<string> => {
  const branch = window.branch;
  const fullUrl = path || domain ? `${domain ? domain : config.URL}${path}` : undefined;
  return new Promise((resolve, reject) => {
    if (!branch) {
      return resolve(fullUrl || "");
    }
    branch.link(
      {
        campaign: campaign,
        channel: source,
        feature: medium,
        data: {
          url_param: path,
          $desktop_url: fullUrl,
          $ios_url: fullUrl,
          $ipad_url: fullUrl,
          $android_url: fullUrl,
          $og_url: og ? undefined : fullUrl,
          ...og,
          ...data,
        },
      },
      (error, link) => {
        if (error) {
          return reject(error);
        }
        return resolve(link || fullUrl || "");
      },
    );
  });
};
