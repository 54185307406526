import { isEqual, range, toString } from "lodash-es";
import React, { useCallback, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import { Grid } from "../../UI";
import GroupCard from "../GroupCard";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderButton,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
} from "../Layout/FullscreenMenu";
import FullscreenMenuHeaderSide from "../Layout/FullscreenMenu/FullscreenMenuHeaderSide";
import SizeButton from "../SizeButton/SizeButton";

const defaultMensSizeRange = range(3.5, 15, 0.5).map((s) => toString(s));

export interface RestocksSizeSelectorMenuProps {
  activeSizes: string[];
  setSizes: (sizes: string[]) => void;
  close: () => void;
  isOpen: boolean;
}

const RestocksSizeSelectorMenu: React.FC<RestocksSizeSelectorMenuProps> = ({
  close,
  isOpen,
  activeSizes,
  setSizes,
}) => {
  const [tempSizes, setTempSizes] = useImmer(new Set(activeSizes));

  const handleSubmitSettings = useCallback(() => {
    setSizes(Array.from(tempSizes));
    close();
  }, [tempSizes, close, setSizes]);

  const updateSizes = useCallback(
    (size: string) => {
      setTempSizes((draft) => {
        if (draft.has(size)) {
          draft.delete(size);
        } else {
          draft.add(size);
        }
      });
    },
    [setTempSizes],
  );

  useEffect(() => {
    setTempSizes(new Set(activeSizes));
  }, [activeSizes, setTempSizes]);

  const hasChanged = useMemo<boolean>(() => {
    if (!isEqual(activeSizes, Array.from(tempSizes))) {
      return true;
    } else {
      return false;
    }
  }, [activeSizes, tempSizes]);

  return (
    <FullscreenMenu
      header={
        <FullscreenMenuHeader>
          <FullscreenMenuHeaderSide side="left">
            <FullscreenMenuHeaderIcon name="chevron left" onClick={close} />
          </FullscreenMenuHeaderSide>
          <FullscreenMenuHeaderTitle>Sizes</FullscreenMenuHeaderTitle>
          <FullscreenMenuHeaderSide side="right">
            <FullscreenMenuHeaderButton
              onClick={handleSubmitSettings}
              disabled={!hasChanged}
              variant={hasChanged ? "secondary" : "disabled"}
            >
              Done
            </FullscreenMenuHeaderButton>
          </FullscreenMenuHeaderSide>
        </FullscreenMenuHeader>
      }
      isOpen={isOpen}
      close={close}
    >
      <GroupCard title={"Mens"} subtitle={tempSizes.size || null} flush={true}>
        <Grid gridTemplateColumns="repeat(8, 1fr)" gridGap={2} p={2}>
          {defaultMensSizeRange.map((s: string) => {
            const isSelected = Boolean(tempSizes.has(s));
            return <SizeButton key={s} size={s} isSelected={isSelected} onClick={() => updateSizes(s)} />;
          })}
        </Grid>
      </GroupCard>
    </FullscreenMenu>
  );
};

export default RestocksSizeSelectorMenu;
