enum ActionType {
  // comet chat reducer
  COMET_CHAT_ADD_CONVERSATION_MESSAGES = "COMET_CHAT_ADD_CONVERSATION_MESSAGES",
  COMET_CHAT_ADD_CONVERSATIONS = "COMET_CHAT_ADD_CONVERSATIONS",
  COMET_CHAT_INCREMENT_USER_UNREAD_MESSAGE_COUNT = "COMET_CHAT_INCREMENT_USER_UNREAD_MESSAGE_COUNT",
  COMET_CHAT_SET_INITIALIZED = "COMET_CHAT_SET_INITIALIZED",
  COMET_CHAT_SET_LOGGED_IN = "COMET_CHAT_SET_LOGGED_IN",
  COMET_CHAT_SET_UNREAD_MESSAGE_COUNTS = "COMET_CHAT_SET_UNREAD_MESSAGE_COUNTS",
  COMET_CHAT_SET_USER_UNREAD_MESSAGE_COUNT = "COMET_CHAT_SET_USER_UNREAD_MESSAGE_COUNT",
  // native app reducer
  UPDATE_NATIVE_APP_STATE = "UPDATE_NATIVE_APP_STATE",
  // other
  APPEND_LIST_COMMENTS = "APPEND_LIST_COMMENTS",
  APPEND_LIST_POSTS = "APPEND_LIST_POSTS",
  CLEAR_PAGE_DATA = "CLEAR_PAGE_DATA",
  CLEAR_STYLE_POSTS = "CLEAR_STYLE_POSTS",
  CREATE_PRODUCT_ALARM = "CREATE_PRODUCT_ALARM",
  DELETE_PRODUCT_ALARM = "DELETE_PRODUCT_ALARM",
  DISMISS_ANNOUNCEMENT = "DISMISS_ANNOUNCEMENT",
  DISMISS_REVIEW = "DISMISS_REVIEW",
  GET_PRODUCT_ALARM = "GET_PRODUCT_ALARM",
  GET_PRODUCT_PRICE_HISTORY = "GET_PRODUCT_PRICE_HISTORY",
  GET_UPCOMING_PRODUCT_RAFFLES = "GET_UPCOMING_PRODUCT_RAFFLES",
  GET_UPCOMING_PRODUCT_RELEASES = "GET_UPCOMING_PRODUCT_RELEASES",
  GET_USED_BID = "GET_USED_BID",
  GET_VENDOR_ASKS = "GET_VENDOR_ASKS",
  INCREMENT_VISITS = "INCREMENT_VISITS",
  REPLACE_LIST_COMMENTS = "REPLACE_LIST_COMMENTS",
  REPLACE_LIST_POSTS = "REPLACE_LIST_POSTS",
  REPLACE_PAGE_STATE = "REPLACE_PAGE_STATE",
  REPLACE_STACK_STATE = "REPLACE_STACK_STATE",
  SET_CLAIMS = "SET_CLAIMS",
  SET_CURRENCY_PREFERENCE = "SET_CURRENCY_PREFERENCE",
  SET_DISABLE_SHIPPING_COSTS = "SET_DISABLE_SHIPPING_COSTS",
  SET_PLUGD_REFERRER_ID = "SET_PLUGD_REFERRER_ID",
  SET_SHIPPING_LOCATION = "SET_SHIPPING_LOCATION",
  SWITCH_ADMIN_ACCOUNT_CONTEXT = "SWITCH_ADMIN_ACCOUNT_CONTEXT",
  TOGGLE_MENU = "TOGGLE_MENU",
  TOGGLE_SEARCH = "TOGGLE_SEARCH",
  TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU",
  TRACK_HISTORY_INDEX = "TRACK_HISTORY_INDEX",
  UPDATE_APP_META = "UPDATE_APP_META",
  UPDATE_IP_INFO = "UPDATE_IP_INFO",
  UPDATE_PRODUCT_ALARM = "UPDATE_PRODUCT_ALARM",
  UPDATE_PRODUCT_RAFFLE = "UPDATE_PRODUCT_RAFFLE",
  UPDATE_PRODUCT_RELEASE = "UPDATE_PRODUCT_RELEASE",
  UPDATE_RECENTLY_VIEWED = "UPDATE_RECENTLY_VIEWED",
  UPDATE_SIZE_PREFERENCE_UNIT = "UPDATE_SIZE_PREFERENCE_UNIT",
}

export default ActionType;
