import React, { ReactElement } from "react";
import useScreenSize, { ScreenSize } from "../../../UI/utils/useScreenSize";

export interface ScreenSizeProps {
  sizes: ScreenSize | ScreenSize[];
  children: ReactElement<any, any> | null;
}

const ScreenSize: React.FC<ScreenSizeProps> = ({ children, sizes }) => {
  const screenSize = useScreenSize();
  const acceptibleSizes = Array.isArray(sizes) ? sizes : [sizes];
  if (acceptibleSizes.includes(screenSize)) {
    return children;
  }
  return null;
};

export default ScreenSize;
