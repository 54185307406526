import React, { useMemo } from "react";
import styled, { DefaultTheme, ThemeColor } from "styled-components";
import { color, ColorProps } from "styled-system";
import { Label as SemanticLabel, LabelProps as SemanticLabelProps } from "../../libs/semantic-ui";
import { getThemeColor } from "../utils/stylesUtil";

export type LabelProps = Omit<SemanticLabelProps, "color"> &
  ColorProps<DefaultTheme> & {
    color?: ThemeColor;
  };

const Label: React.FC<LabelProps> = ({ color, style, ...labelProps }) => {
  const styleWithColor = useMemo(
    () => ({
      ...style,
      color: getThemeColor(color),
    }),
    [style, color],
  );
  return <LabelComponent {...labelProps} style={styleWithColor} />;
};

const LabelComponent = styled(SemanticLabel)`
  &&& {
    ${color}
  }
`;

export default Label;
