import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useMenuState from "../../../hooks/useMenuState";
import { RootState } from "../../../store";
import { ProductListingsAPI } from "../../../typings/API";
import RadioMenuItem from "../../Layout/MenuItem/RadioMenuItem";
import PartialMenu from "../../Layout/PartialMenu";
import InputSelectBox from "../InputSelectBox";
import { UseInputHook } from "../types/inputHooks";

const useConditionInput: UseInputHook<string | null, string | undefined> = (defaultValue) => {
  const productAttribute = useSelector<RootState, ProductListingsAPI.ProductAttributeWithOptions | undefined>(
    (state) => state.entities.productAttributesByKey["product_condition"],
  );

  const [condition, setCondition] = useState<string | null>(defaultValue || null);
  const {
    open: openMenu,
    menuProps: { close, isOpen },
  } = useMenuState();

  const input = useMemo(
    () => <InputSelectBox onClick={openMenu} placeholder="Condition" value={condition} />,
    [openMenu, condition],
  );

  const onItemSelect = useCallback(
    (condition: string) => {
      setCondition(condition);
      close();
    },
    [close],
  );

  const menus = useMemo(
    () => (
      <PartialMenu close={close} isOpen={isOpen}>
        {productAttribute?.options.map((o) => (
          <RadioMenuItem
            key={o.key}
            name={o.name}
            isChecked={condition === o.name}
            onClick={() => onItemSelect(o.name)}
          />
        ))}
      </PartialMenu>
    ),
    [close, isOpen, condition, productAttribute, onItemSelect],
  );

  const clearValue = useCallback(() => setCondition(null), []);

  return useMemo(
    () => ({
      input,
      menus,
      value: condition,
      clearValue,
    }),
    [clearValue, condition, input, menus],
  );
};

export default useConditionInput;
