import React from "react";
import { Background, BackgroundProps } from "react-imgix";
import styled, { DefaultTheme } from "styled-components";
import {
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
} from "styled-system";

const defaultImgixParams: BackgroundProps["imgixParams"] = {
  auto: "format",
};

export interface ImgixBackgroundProps
  extends BackgroundProps,
    LayoutProps<DefaultTheme>,
    FlexboxProps<DefaultTheme>,
    PositionProps<DefaultTheme>,
    BorderProps<DefaultTheme> {}

const ImgixBackground: React.FC<ImgixBackgroundProps> = ({ imgixParams, ...props }) => {
  return (
    <ImgixBackgroundComponent
      imgixParams={{
        ...defaultImgixParams,
        ...imgixParams,
      }}
      {...props}
    />
  );
};

const ImgixBackgroundComponent = styled(Background)<ImgixBackgroundProps>`
  ${border}
  ${flexbox}
  ${layout}
  ${position}
`;

export default ImgixBackground;
