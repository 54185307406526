import React from "react";
import styled from "styled-components";
import { ProductsAPI } from "../../../typings/API";
import { Box, Icon, Image, Text } from "../../../UI";

export interface ProductFollowItemProps {
  isSelected: boolean;
  onSelect: (product: ProductsAPI.Product) => void;
  product: ProductsAPI.Product;
}

const ProductFollowItem: React.FC<ProductFollowItemProps> = ({ product, isSelected, onSelect }) => {
  return (
    <SelectItem onClick={() => onSelect(product)}>
      <ImageWrapper width={50}>
        <Image alt={product.name} className="lazyload" data-src={product.image} height="auto" width="100%" />
      </ImageWrapper>
      <Box maxWidth={"60%"} display="flex" flexDirection="column" ml={2}>
        {product.name && (
          <Text textAlign="left" fontSize={0} fontWeight={5}>
            {product.name}
          </Text>
        )}
        <Box display="flex" flexDirection="row">
          <TagText>{product.style_id}</TagText>
        </Box>
      </Box>
      <IconWrapper>
        <Icon color="gold" name={isSelected ? "check circle outline" : "circle outline"} />
      </IconWrapper>
    </SelectItem>
  );
};

export const SelectItem = styled(Box)`
  cursor: pointer;
`;
SelectItem.defaultProps = {
  px: 3,
  py: 2,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  borderRadius: 3,
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  bg: "white",
  my: 2,
};

const ImageWrapper = styled(Box)`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

const TagText = styled(Text)`
  margin-left: ${({ theme }) => theme.space[2]}px;
  &:first-child {
    margin-left: 0px;
  }
`;
TagText.defaultProps = {
  fontSize: 0,
  color: "darkGrey",
};

const IconWrapper = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  margin-left: auto;
`;

export default ProductFollowItem;
