import React, { useCallback, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import config, { isMobileWeb } from "../config";
import { urlToImgixUrl } from "../helpers/imgixUtil";
import { Box, ImgixImage, Text } from "../UI";

const plugdLogoSrc = urlToImgixUrl(config.APP_ICON_URL, { mask: "corners" });

export interface AppDownloadToastProps {}

/**
 * displays a toast with link to download the app on Mobile Web
 */
const AppDownloadToast: React.FC<AppDownloadToastProps> = () => {
  const { addToast } = useToasts();

  const handleClick = useCallback(() => {
    window.open(config.BRANCH_TOAST_URL, "_blank");
  }, []);

  useEffect(() => {
    // display the toast
    if (isMobileWeb)
      addToast(
        <Box display="flex" alignItems="center" onClick={handleClick} width="100%">
          {plugdLogoSrc && <ImgixImage alt="Plugd app" width={40} height={40} src={plugdLogoSrc} />}
          <Box display="flex" flexDirection="column" ml={2}>
            <Text fontWeight={5}>{`Plugd`}</Text>
            <Text fontSize={0} color="darkGrey">{`Open with the Plugd app`}</Text>
          </Box>
        </Box>,
        {
          isBlank: true,
          appearance: "info",
          autoDismiss: false,
        },
      );
  }, [addToast, handleClick]);

  return null;
};

export default AppDownloadToast;
