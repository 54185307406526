import { useEffect } from "react";
import { retry } from "ts-retry-promise";
import { isMobileFrame } from "../config";
import { captureException } from "../helpers/errorUtil";

export type UsePreloadEffect = (importCbs: (() => Promise<unknown>)[], shouldPreload?: boolean) => void;

/**
 * Hook that imports components that are likely to be used when a user renders a specific component
 * (e.g.  when a user loads the BottomNav, they are likely to visit the feed and discover page. Preload those files)
 *
 * @param importCbs preload promise callbacks
 * @param shouldPreload default = isMobileFram (only preload for mobile app to keep web performance high)
 */
const usePreloadEffect: UsePreloadEffect = (importCbs, shouldPreload = isMobileFrame) => {
  useEffect(() => {
    setTimeout(() => {
      if (shouldPreload) {
        try {
          importCbs.forEach((cb) => retry(cb, { retries: 5 }));
        } catch (error) {
          captureException(error);
        }
      }
    }, 500);
  }, []); // only check on the first render
};

export default usePreloadEffect;
