import styled, { DefaultTheme } from "styled-components";
import { border, BorderProps, color, ColorProps, space, SpaceProps, typography, TypographyProps } from "styled-system";

export interface InputProps
  extends ColorProps<DefaultTheme>,
    SpaceProps<DefaultTheme>,
    TypographyProps<DefaultTheme>,
    BorderProps<DefaultTheme>,
    Omit<React.ComponentPropsWithRef<"input">, "color"> {}

const Input = styled.input<InputProps>`
  border: none;
  width: 100%;
  outline: none;
  ${border}
  ${color}
  ${space}
  ${typography}
`;
Input.defaultProps = {
  type: "text",
};

export default Input;
