import { floor, toUpper } from "lodash-es";
import React, { useMemo } from "react";
import styled, { DefaultTheme } from "styled-components";
import { BorderProps } from "styled-system";
import { urlToImgixUrl } from "../../../helpers/imgixUtil";
import theme from "../../theme";
import Box, { BoxProps } from "../Box";
import ImgixImage from "../ImgixImage";
import PlaceholderBox from "../PlaceholderBox";
import Text from "../Text";
import { getRandomColor } from "./utils";

export interface UserAvatarProps extends BorderProps<DefaultTheme> {
  borderRadius?: number;
  className?: string;
  displayLetter?: string;
  isPending?: boolean;
  name?: string;
  noColor?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  size?: "small" | "medium" | "large" | number;
  src?: string | null;
  username: string;
}

export const DEFAULT_AVATAR_SIZE = 24;
export const DEFAULT_BORDER_RADIUS = 1;

const getSize = (
  options: Pick<UserAvatarProps, "size">,
): {
  size: number;
} => {
  if (!options.size) {
    return {
      size: DEFAULT_AVATAR_SIZE,
    };
  }
  switch (options.size) {
    case "small":
      return {
        size: 24,
      };
    case "medium":
      return {
        size: 30,
      };
    case "large":
      return {
        size: 40,
      };
    default:
      return {
        size: options.size,
      };
  }
};

const defaultColors = ["#A62A21", "#7e3794", "#0B51C1", "#3A6024", "#A81563", "#B3003C"];

const UserAvatar: React.FC<UserAvatarProps> = ({
  borderRadius,
  className,
  displayLetter,
  isPending,
  noColor,
  onClick,
  size,
  src,
  username,
}) => {
  const { size: s } = getSize({ size });
  const br: number = borderRadius ?? DEFAULT_BORDER_RADIUS;
  const fs = useMemo(() => floor(s / 3), [s]);
  const generatedColor = useMemo(
    () => (noColor ? undefined : getRandomColor(username, defaultColors)),
    [username, noColor],
  );

  if (isPending) {
    return <PlaceholderBox height={s} width={s} borderRadius={br} />;
  }

  /** return the user's avatar image */
  if (src) {
    return (
      <ImgixImage
        alt={`@${username}`}
        borderRadius={br}
        className={className}
        height={s}
        src={urlToImgixUrl(src) || src}
        width={s}
        htmlAttributes={{
          onClick,
          title: username,
          style: {
            borderRadius: theme.radii[br],
            height: s,
            width: s,
          },
        }}
      />
    );
  }

  /** return colored container with first letter of username */
  return (
    <TextAvatar
      borderRadius={br}
      className={className}
      customBackgroundColor={generatedColor}
      height={s}
      onClick={onClick}
      title={username}
      width={s}
    >
      {/* ignores TS checking on this line because styled-system's types are incorrect. Raw css strings are allowed for fontSize */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Text color="white" fontSize={`${fs}px`}>
        {toUpper(displayLetter || Array.from(username)[0])}
      </Text>
    </TextAvatar>
  );
};

const AvatarImage = styled(ImgixImage)``;
AvatarImage.defaultProps = {
  borderRadius: 1,
};

const TextAvatar = styled(Box)<BoxProps & { customBackgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  ${({ customBackgroundColor }) => (customBackgroundColor ? `background-color: ${customBackgroundColor};` : "")}
`;

export default UserAvatar;
