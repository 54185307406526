import React, { ReactElement, ReactNode } from "react";
import Page, { PageProps } from "./Page";
import Panel, { PanelProps } from "./Panel";

export interface PanelPageProps {
  children?: ReactNode;
  pageProps?: PageProps;
  panelProps?: PanelProps;
}

const PanelPage = (props: PanelPageProps): ReactElement => {
  const { children, pageProps, panelProps } = props;
  return (
    <Page {...pageProps}>
      <Panel grow {...panelProps}>
        <React.Fragment>{children}</React.Fragment>
      </Panel>
    </Page>
  );
};

export default PanelPage;
