import { isEmpty } from "lodash-es";
import React, { RefAttributes, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { hasGroupMentions } from "../../links/utils/mentionDetect";
import { sanitizePostInsert, validatePostInsert } from "../../../helpers/postUtil";
import useCurrentUserProfile from "../../../hooks/useCurrentUserProfile";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { MenuProps } from "../../../hooks/useMenuState";
import communitySelector from "../../../selectors/communitySelector";
import { CommunitiesAPI } from "../../../typings/API";
import { PostsAPI, PostTypeKey } from "../../../typings/API-V2";
import { Admin } from "../../Permission";
import CreatePostMenuGeneric, { PostDataMeta, PostInsertDataMeta } from "../CreatePostMenuGeneric";
import useCommunitySelectorInput from "../hooks/useCommunitySelectorInput";
import useImageInput, { ImageMeta, UseImageInputOptions } from "../hooks/useImageInput";
import useLinkInput from "../hooks/useLinkInput";
import useThemeSelectorInput, { UseThemeSelectorInputOptions } from "../hooks/useThemeSelectorInput";
import useTitleInput from "../hooks/useTitleInput";
import MentionDetails from "../MentionDetails";
import PostMenuNavTabs from "../PostMenuNavTabs";
import { imageMetaToImageInsert, isInsertableImageMeta } from "../postMenuUtils";
import { InsertableImageMeta } from "./hooks/useImageInputHandler";

export interface CreateImagePostMenuProps extends MenuProps, RefAttributes<HTMLInputElement> {
  defaultTheme?: string;
  onCreated?: () => void;
  userAccountId?: string;
}

const useImageInputOptions: UseImageInputOptions = { canTag: true };

const CreateImagePostMenu: React.FC<CreateImagePostMenuProps> = ({ close, isOpen, onCreated }) => {
  const {
    input: communityInput,
    menus: communityMenus,
    value: community,
    clearValue: clearCommunity,
  } = useCommunitySelectorInput(undefined);
  const {
    input: titleInput,
    menus: titleMenus,
    value: title,
    clearValue: clearTitle,
  } = useTitleInput({ defaultValue: undefined, community: community });
  const { input: linkInput, menus: linkMenus, value: url, clearValue: clearUrl } = useLinkInput();
  const themeInputOptions = useMemo<UseThemeSelectorInputOptions>(
    () => ({
      communityId: community?.id,
      postTypeKey: PostTypeKey.Image,
    }),
    [community?.id],
  );
  const {
    input: themeInput,
    menus: themeMenus,
    value: themeId,
    clearValue: clearTheme,
  } = useThemeSelectorInput(themeInputOptions);
  const {
    input: imagesInput,
    menus: imagesMenus,
    value: imagesMeta,
    clearValue: clearImagesMeta,
  } = useImageInput(useImageInputOptions);

  /**
   * GENERIC POST CREATION
   * =====================
   */

  const clearFields = useCallback(() => {
    clearCommunity();
    clearImagesMeta();
    clearTitle();
    clearTheme();
    clearUrl();
  }, [clearCommunity, clearImagesMeta, clearTitle, clearUrl, clearTheme]);

  const { isAdmin } = useIsAdmin();
  const currentUser = useCurrentUserProfile();
  const profile = currentUser.profile;
  const isModerator = useSelector(communitySelector.userIsModerator(profile?.id, community?.id));
  const isAuthorized = isModerator || isAdmin;

  const postDataMeta = useMemo<PostDataMeta>(
    () =>
      createPostInsert({
        community,
        themeId,
        imagesMeta,
        url,
        title,
        isAuthorized,
      }),
    [community, themeId, url, title, imagesMeta, isAuthorized],
  );

  const closeMenu = useCallback(() => {
    close();
    /** reset entire menu state when closed */
    clearFields();
  }, [close, clearFields]);

  return (
    <>
      {communityMenus}
      {titleMenus}
      {linkMenus}
      {themeMenus}
      {imagesMenus}
      <CreatePostMenuGeneric
        close={closeMenu}
        isOpen={isOpen}
        onCreated={onCreated}
        overflowY="scroll"
        postDataMeta={postDataMeta}
      >
        <PostMenuNavTabs activeTab={PostTypeKey.Image} />
        {communityInput}
        {community && (
          <>
            {titleInput}
            <Admin>{linkInput}</Admin>
            {themeInput}
            <MentionDetails
              show={hasGroupMentions(title)}
              isAuthorized={isAuthorized}
              authorizedText={`This will notify everyone in c/${community.slug}`}
              unAuthorizedText={"You don't have permission to mention @everyone"}
            />
            {imagesInput}
          </>
        )}
      </CreatePostMenuGeneric>
    </>
  );
};

const createPostInsert = (options: {
  community: CommunitiesAPI.Community | null;
  themeId: number | null;
  imagesMeta: ImageMeta[];
  url: string | null;
  title: string;
  isAuthorized: boolean;
}): PostInsertDataMeta => {
  const { title, community, imagesMeta, themeId, url, isAuthorized } = options;

  if (isEmpty(imagesMeta)) {
    return {
      type: "post",
      canSubmit: false,
      message: "Must include an image",
    };
  }

  const metasValid = imagesMeta.every((m) => isInsertableImageMeta(m));
  if (!metasValid) {
    return {
      type: "post",
      canSubmit: false,
      message: "Image metas are not valid",
    };
  }

  /**
   * Need the filter for TS purposes. No values should be filtered out since we check for object validity above
   */
  const imageInserts = imagesMeta
    .filter((m): m is InsertableImageMeta => isInsertableImageMeta(m))
    .map(imageMetaToImageInsert);

  /** build creation object */
  const postInsert: PostsAPI.PostInsert = {
    community_id: community?.id,
    content: null,
    images: imageInserts,
    product_listing: null,
    theme_key: null,
    theme_id: themeId,
    title: title,
    type: PostTypeKey.Image,
    url: url && url[0] !== "/" ? url : null,
    to_route: url && url[0] === "/" ? url : null,
  };

  /** sanitize creation object */
  const sanitizedPostInsert = sanitizePostInsert(postInsert);

  /** validate creation object */
  const postInsertValidation = validatePostInsert(sanitizedPostInsert, isAuthorized);
  if (!postInsertValidation.isValid) {
    /** capture error */
    return {
      type: "post",
      canSubmit: false,
      message: postInsertValidation.message,
    };
  }

  return {
    type: "post",
    canSubmit: true,
    postData: sanitizedPostInsert,
  };
};

export default CreateImagePostMenu;
