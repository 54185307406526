import React, { ReactNode, useMemo, useState } from "react";
import ScreenSize from "../../components/Layout/ScreenSize";
import AuthScreensModal from "./AuthScreenModal";
import AuthScreensContext, { AuthScreen } from "./AuthScreensContext";
import AuthScreensPortal from "./AuthScreensPortal";

export interface AuthScreensProviderProps {
  children: ReactNode;
}

const AuthScreensProvider: React.FC<AuthScreensProviderProps> = ({ children }) => {
  const [authScreen, setAuthScreen] = useState<AuthScreen | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [onAuthenticated, setOnAuthenticated] = useState<(() => void) | undefined>(undefined);

  const value = useMemo(
    () => ({
      info: info,
      onAuthenticated,
      screen: authScreen,
      setAuthScreen,
      setInfo,
      setOnAuthenticated,
    }),
    [authScreen, setAuthScreen, info, setInfo, onAuthenticated],
  );

  return (
    <AuthScreensContext.Provider value={value}>
      <ScreenSize sizes="large">
        <AuthScreensModal />
      </ScreenSize>
      <ScreenSize sizes={["medium", "small"]}>
        <AuthScreensPortal />
      </ScreenSize>
      {children}
    </AuthScreensContext.Provider>
  );
};

export default AuthScreensProvider;
