import React from "react";
import { Stripe as StripeNode } from "stripe";
import { isLineItemProduct } from "../../helpers/stripeUtils";
import Box from "../../UI/components/Box";
import ProductOrderLineItem from "./ProductOrderLineItem";

export interface LineItemsProps {
  lineItems?: StripeNode.LineItem[];
}

const LineItems: React.FC<LineItemsProps> = ({ lineItems }) => {
  if (!lineItems) {
    return null;
  }
  return (
    <Box>
      {lineItems.map((item) => {
        const product = item.price?.product;
        const amountCents = item.price && item.price.unit_amount !== null ? item.price.unit_amount : undefined;
        return (
          <ProductOrderLineItem
            key={item.id}
            imageSrc={isLineItemProduct(product) && product.images.length > 0 ? product.images[0] : undefined}
            name={item.description}
            amountCents={amountCents}
            product={{
              slug: isLineItemProduct(product) ? product.metadata.plugd_product_slug : "",
              style_id: isLineItemProduct(product) ? product.metadata.plugd_product_style_id : "",
            }}
          />
        );
      })}
    </Box>
  );
};

export default LineItems;
