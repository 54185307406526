import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSigninCheck } from "reactfire";
import { QueryState } from "redux-query";
import { ForceRequestCallback, useRequest } from "redux-query-react";
import { getCurrentUserProfile } from "../queries/api/userProfileQuery";
import { RootState } from "../store";
import EntitiesState from "../typings/EntitiesState";

export interface UseCurrentUserProfileResponse extends QueryState {
  profile: EntitiesState["currentUserProfile"];
  refresh: ForceRequestCallback<Pick<EntitiesState, "currentUserProfile" | "preferences">>;
}

const useCurrentUserProfile = (): UseCurrentUserProfileResponse => {
  const { data: signInCheckResult, status: userStatus } = useSigninCheck();
  const [{ isPending: profilePending, isFinished: profileFinished }, refresh] = useRequest(
    signInCheckResult?.user?.uid ? getCurrentUserProfile() : null,
  );
  const isPending = userStatus === "loading" || profilePending;
  /**
   * If the user is not signed in, then the check is finished.
   * If the user is signed in, then getCurrentUserProfile is called, and we need to wait for that call to be finished
   */
  const isFinished = signInCheckResult?.signedIn === false || profileFinished;
  const userProfile = useSelector((state: RootState) => state.entities.currentUserProfile);

  return useMemo(
    () => ({
      isPending,
      isFinished,
      profile: userProfile,
      refresh,
    }),
    [isPending, isFinished, refresh, userProfile],
  );
};

export default useCurrentUserProfile;
