import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import styled from "styled-components";
import BottomNav from "../../components/BottomNav";
import OneTwoOneGrid from "../../components/Layout/Grids/OneTwoOneGrid";
import Page from "../../components/Layout/Page";
import ScreenSize from "../../components/Layout/ScreenSize";
import SecondaryAside from "../../components/Layout/SecondaryAside";
import StickyAside from "../../components/Layout/StickyAside";
import DropsNavTabs from "../../components/Mobile/Drops/DropsNavTabs";
import { DEFAULT_NAV_TABS_HEIGHT } from "../../components/NavTabs";
import NotificationPermissionsBanner from "../../components/NotificationPermissionsBanner";
import { PostPlaceholder } from "../../components/Post";
import { PullToRefreshContent, ReleaseToRefreshContent } from "../../components/PullToRefresh";
import RestocksListItem from "../../components/Restocks/RestocksListItem";
import RestocksSettingsMenu from "../../components/Restocks/RestocksSettingsMenu";
import PrimaryTopNav from "../../components/TopNav/PrimaryTopNav";
import { pages, trackPage } from "../../helpers/analyticsUtil";
import useMenuState from "../../hooks/useMenuState";
import useRestocksList from "../../hooks/useRestocksList";
import { getRestockFilters } from "../../queries/api-v2/restocksQuery";
import restocksSelector from "../../selectors/restocksSelector";
import { Box, Button, Spacer, Text, theme } from "../../UI";
import useScreenSize from "../../UI/utils/useScreenSize";

export interface RestocksPageProps {}

const RestocksPage: React.FC<RestocksPageProps> = () => {
  const screenSize = useScreenSize();

  const {
    open: openSettingsPortal,
    menuProps: { close: closeSettingsPortal, isOpen: settingsPortalIsOpen },
  } = useMenuState();

  useEffect(() => {
    trackPage(pages.Restocks);
  }, []);

  useRequest(getRestockFilters());

  const userRestockSettings = useSelector(restocksSelector.userFilters());
  const settingSizes = userRestockSettings.sizes || [];
  const keywordWhitelist = userRestockSettings.whitelist_keywords || [];
  const applyToFeed = userRestockSettings.apply_to_feed;

  const { listKey, restocks, fetchNext, refresh, hasMore } = useRestocksList({
    sort_by: "created_at",
    order_by: "desc",
    limit: 20,
    is_hyped: applyToFeed ? userRestockSettings.include_hyped : false,
    sizes: applyToFeed ? settingSizes : undefined,
    keyword_whitelist: applyToFeed ? keywordWhitelist : undefined,
  });

  return (
    <>
      <PrimaryTopNav />
      <DropsNavTabs />
      <Page key="restocks" pt={screenSize === "small" ? DEFAULT_NAV_TABS_HEIGHT : theme.space[4]}>
        <NotificationPermissionsBanner title={`Don't miss releases, raffles, or drops!`} />
        <OneTwoOneGrid>
          <ScreenSize sizes="large">
            <StickyAside>
              <HeaderWrapper borderRadius={screenSize === "large" ? 3 : undefined}>
                <Text as="h1" fontSize={4} fontWeight={5}>
                  Drop Alerts
                </Text>
                <Spacer mt={3} />
                <Text textAlign="center">
                  {`Get notified when retailers release or restock products you're watching. For best experience use mobile and have notifications turn notifications on`}
                </Text>
              </HeaderWrapper>
            </StickyAside>
          </ScreenSize>
          <Box width="100%" minWidth={0} pt={screenSize !== "large" ? 3 : 0}>
            <InfiniteScroll
              key={listKey}
              style={{ width: "100%" }}
              dataLength={restocks.length}
              loader={Array.from({ length: 2 }).map((_, idx) => (
                <PostPlaceholder key={idx} />
              ))}
              next={fetchNext}
              hasMore={hasMore}
              pullDownToRefresh={screenSize === "large" ? false : true}
              pullDownToRefreshContent={<PullToRefreshContent />}
              pullDownToRefreshThreshold={100}
              refreshFunction={refresh}
              releaseToRefreshContent={<ReleaseToRefreshContent />}
            >
              {restocks.map((r) => (
                <RestocksListItem key={r.id} restock={r} />
              ))}
            </InfiniteScroll>
          </Box>
          <SecondaryAside />
        </OneTwoOneGrid>
        <RestocksSettingsMenu isOpen={settingsPortalIsOpen} close={closeSettingsPortal} />
        <ScreenSize sizes={["medium", "small"]}>
          <StickyButton onClick={openSettingsPortal} variant="secondary">
            Settings
          </StickyButton>
        </ScreenSize>
      </Page>
      <BottomNav />
    </>
  );
};

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-self: start;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
HeaderWrapper.defaultProps = {
  p: [3, 4, 4],
  bg: "white",
};

const StickyButton = styled(Button)`
  align-self: center;
  position: fixed;
  bottom: 0;
  width: 60%;
  margin-bottom: 75px;
`;

export default RestocksPage;
