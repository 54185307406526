import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useMenuState from "../../../hooks/useMenuState";
import { RootState } from "../../../store";
import { ProductListingsAPI } from "../../../typings/API";
import RadioMenuItem from "../../Layout/MenuItem/RadioMenuItem";
import PartialMenu from "../../Layout/PartialMenu";
import InputSelectBox from "../InputSelectBox";
import { UseInputHook } from "../types/inputHooks";

const useConditionInput: UseInputHook<string | null, string | undefined> = (defaultValue) => {
  const productAttribute = useSelector<RootState, ProductListingsAPI.ProductAttributeWithOptions | undefined>(
    (state) => state.entities.productAttributesByKey["box_condition"],
  );

  const [boxCondition, setBoxCondition] = useState<string | null>(defaultValue || null);
  const {
    open: openMenu,
    menuProps: { close, isOpen },
  } = useMenuState();

  const input = useMemo(
    () => (
      <InputSelectBox onClick={openMenu} placeholder="Box Condition" value={boxCondition} borderLeft={"1px solid"} />
    ),
    [openMenu, boxCondition],
  );

  const onItemSelect = useCallback(
    (boxCondition: string) => {
      setBoxCondition(boxCondition);
      close();
    },
    [close],
  );

  const menus = useMemo(
    () => (
      <PartialMenu close={close} isOpen={isOpen}>
        {productAttribute?.options.map((o) => (
          <RadioMenuItem
            key={o.key}
            name={o.name}
            isChecked={boxCondition === o.name}
            onClick={() => onItemSelect(o.name)}
          />
        ))}
      </PartialMenu>
    ),
    [close, isOpen, boxCondition, productAttribute, onItemSelect],
  );

  const clearValue = useCallback(() => setBoxCondition(null), []);

  return useMemo(
    () => ({
      input,
      menus,
      value: boxCondition,
      clearValue,
    }),
    [clearValue, boxCondition, input, menus],
  );
};

export default useConditionInput;
