import { buildURL, SharedImgixAndSourceProps } from "react-imgix";

const defaultImgixParams: SharedImgixAndSourceProps["imgixParams"] = {
  auto: "format",
};

/**
 * Detects if the url can be converted to an Imgix url, and applies the Imgix params if it is, otherwise returns undefined
 *
 * TODO: Return placeholder image if undefined otherwise would have been returned
 *
 * @param url the original string url
 * @param imgixParams {SharedImgixAndSourceProps["imgixParams"]}
 * @param options {SharedImgixAndSourceProps}
 * @returns {string | undefined}
 */
export const urlToImgixUrl = (
  str?: string | null,
  imgixParams?: SharedImgixAndSourceProps["imgixParams"],
  options?: SharedImgixAndSourceProps,
): string | undefined => {
  if (!str) {
    return undefined;
  }

  let imgixSrc: string | undefined = undefined;
  if (str.includes("images.getplugd.com")) {
    imgixSrc = str.replace("images.getplugd.com", "plugd.imgix.net");
    return buildURL(imgixSrc, { ...defaultImgixParams, ...imgixParams }, options);
  }

  return imgixSrc;
};
