/**
 * Messages types sent to the mobile webview from the react-native mobile-app wrapper
 */
export enum OutboundMessageType {
  "AUTH_STATE_CHANGE" = "AUTH_STATE_CHANGE",
  "CONTACTS_SYNC" = "CONTACTS_SYNC",
  "DISPLAY_SUBSCRIPTION_OFFERINGS" = "DISPLAY_SUBSCRIPTION_OFFERINGS",
  "GET_CONTACTS_APPROVAL" = "GET_CONTACTS_APPROVAL",
  "GET_DEBUG_LOG" = "GET_DEBUG_LOG",
  "GET_NOTIFICATIONS_APPROVAL" = "GET_NOTIFICATIONS_APPROVAL",
  "GET_TRACKING_APPROVAL" = "GET_TRACKING_APPROVAL",
  "LOG" = "LOG",
  "OPEN_APP_SETTINGS" = "OPEN_APP_SETTINGS",
  "READY" = "READY",
  "SET_CONTACTS_APPROVAL" = "SET_CONTACTS_APPROVAL",
  "SMS_SEND" = "SMS_SEND",
}

/**
 * @deprecated these are legacy messages. Do not add additional types
 */
export enum LegacyOutboundMessageType {
  "LOGIN_FACEBOOK" = "login:facebook",
  "LOGIN_GOOGLE" = "login:google",
  "LOGIN_APPLE" = "login:apple.com",
  "CLEAR_NOTIFICATIONS" = "notifications:clear",
}

/**
 * Messages types received by the mobile webview from the react-native mobile-app wrapper
 */
export enum InboundMessageType {
  "ANALYTICS_TRACK" = "ANALYTICS_TRACK",
  "APP_META" = "APP_META",
  "ATTRIBUTION" = "ATTRIBUTION",
  "CONTACTS_SYNC" = "CONTACTS_SYNC",
  "GET_CONTACTS_APPROVAL" = "GET_CONTACTS_APPROVAL",
  "LOGIN" = "LOGIN",
  "NOTIFICATION_PERMISSION_STATUS" = "NOTIFICATION_PERMISSION_STATUS",
  "PLUGD_DEVICE_PUSH_TOKEN" = "PLUGD_DEVICE_PUSH_TOKEN",
  "PLUGD_PUSH_TOKEN" = "PLUGD_PUSH_TOKEN",
  "PURCHASE_PACKAGE_RESULT" = "PURCHASE_PACKAGE_RESULT",
  "PUSH_NOTIFICATION_OPEN" = "PUSH_NOTIFICATION_OPEN",
  "REDIRECT" = "REDIRECT",
  "SET_APP_STATE_STATUS" = "SET_APP_STATE_STATUS",
  "SET_DEBUG_LOG" = "SET_DEBUG_LOG",
  "SET_TRACKING_APPROVAL" = "SET_TRACKING_APPROVAL",
}
