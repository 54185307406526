import { isNumber, keyBy, reduce, toString } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { NotificationsAPI } from "../../typings/API";
import { NotificationsState } from "../../typings/EntitiesState";

export interface GetNotificationsOptions {
  limit?: number;
  offset?: number;
}
export const getNotifications = (
  options: GetNotificationsOptions,
  queryOptions: Pick<QueryConfig, "force">,
): QueryConfig<NotificationsState> => {
  const searchParams = new URLSearchParams();
  if (isNumber(options.limit)) {
    searchParams.set("limit", toString(options.limit));
  }
  if (isNumber(options.offset)) {
    searchParams.set("offset", toString(options.offset));
  }
  return {
    ...queryOptions,
    url: `${config.API_URL}/notifications?${searchParams.toString()}`,
    meta: {
      includeToken: true,
    },
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: NotificationsAPI.GetNotificationsResponse) => ({
      postNotificationsById: keyBy(response.post, (n) => n.id),
      userNotificationsById: keyBy(response.user, (n) => n.id),
      globalNotificationsById: keyBy(response.global, (n) => n.id),
      productNotificationsById: keyBy(response.product, (n) => n.id),
      orderNotificationsById: keyBy(response.order, (n) => n.id),
    }),
    update: {
      globalNotificationsById: shallowObjectMerge,
      postNotificationsById: shallowObjectMerge,
      userNotificationsById: shallowObjectMerge,
      productNotificationsById: shallowObjectMerge,
      orderNotificationsById: shallowObjectMerge,
    },
  };
};

// optimistically mark all notifications as read
const markNotificationsRead = <N extends NotificationsAPI.Notification>(
  notifications: Record<number, N>,
): Record<number, N> => {
  return reduce<Record<number, N>, Record<number, N>>(
    notifications,
    (result, n) => {
      const oldNotification = { ...notifications[n.id] };
      result[n.id] = {
        ...oldNotification,
        is_read: true,
      };
      return result;
    },
    {},
  );
};

export const readAllNotifications = (): QueryConfig<NotificationsState> => ({
  url: `${config.API_URL}/notifications/read`,
  meta: {
    includeToken: true,
  },
  options: {
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    postNotificationsById: markNotificationsRead,
    userNotificationsById: markNotificationsRead,
    globalNotificationsById: markNotificationsRead,
    productNotificationsById: markNotificationsRead,
  },
});

export const createOrUpdatePushToken = (deviceId: string, token: string): QueryConfig<NotificationsState> => ({
  url: `${config.API_URL}/push-tokens`,
  meta: {
    includeToken: true,
  },
  body: {
    device_id: deviceId,
    token: token,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
});
