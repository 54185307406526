import { Entities } from "redux-query";
import { CustomActionPromise, CustomActionPromiseValue } from "../hooks/useControlledRequest";

export const actionPromiseToPromise = <E extends Entities, B = any>(
  ap: CustomActionPromise<E, B>,
): Promise<CustomActionPromiseValue<E, B>> => {
  if (ap) {
    return ap;
  }
  return Promise.reject(`Action did not return a promise`);
};
