import { reduce, takeWhile } from "lodash-es";
import sizesImport from "./sizes.json";

const sizes = sizesImport as unknown as Sizes;

export interface SizeConversion {
  [unitCode: string]: {
    value: number;
    unit: string;
  };
}

export interface CountrySizeRanges {
  us: number[];
  eu: number[];
  uk: number[];
  fr: number[];
}

export interface SizeConfig {
  brand: string;
  gender: GoatGender;
  sizeRanges: CountrySizeRanges;
}

interface SizesBrand {
  [brand: string]: CountrySizeRanges;
}

interface Sizes {
  men: SizesBrand;
  women: SizesBrand;
}

type GoatGender = "men" | "women" | "youth" | "infant";
type StockxGender = "toddler" | "child" | "unisex" | "infant" | "men" | "women" | "preschool";

export const sizeUnits = ["us", "uk", "eu", "fr"];

export type SizeUnit = "us" | "uk" | "eu" | "fr";

type GenderIndicator = "M" | "W" | "Y" | "I";

const sizeConversionUtil = {
  normalizeGender(gender: GoatGender | StockxGender | undefined): GoatGender {
    switch (gender) {
      case "men":
      case "unisex":
        return "men";
      case "women":
        return "women";
      case "youth":
      case "child":
        return "youth";
      case "infant":
      case "preschool":
        return "infant";
      default:
        return "men";
    }
  },
  getGenderIndicator(gender: GoatGender): GenderIndicator {
    switch (gender) {
      case "men":
        return "M";
      case "women":
        return "W";
      case "youth":
        return "Y";
      case "infant":
        return "I";
      default:
        return "M";
    }
  },
  getGenderIndicatorForSize(convertedSizeValue: number, sizeConfig: SizeConfig, sizeUnit: SizeUnit): GenderIndicator {
    if (sizeConfig.gender === "youth" && (sizeUnit === "us" || sizeUnit === "uk")) {
      const sizeRange = sizeConfig.sizeRanges[sizeUnit];

      // todo: optimize: do not do this for ever size indicator
      const infantSizeRange = takeWhile(sizeRange, (size, idx) => {
        const prevSize = sizeRange[idx - 1];
        if (!prevSize) {
          return true;
        }
        return size > prevSize;
      });

      const isInfantSize = infantSizeRange.includes(convertedSizeValue);
      if (isInfantSize) {
        return sizeConversionUtil.getGenderIndicator("infant");
      }
    }
    return sizeConversionUtil.getGenderIndicator(sizeConfig.gender);
  },
  getSizeConfig(gender: GoatGender, brand?: string): SizeConfig {
    const brandSizes: CountrySizeRanges | undefined = sizes[gender][brand];
    const brandName = brand || "default";
    if (!brandSizes) {
      return {
        brand: "default",
        sizeRanges: sizes[gender].default,
        gender,
      };
    } else {
      return {
        brand: brandName,
        gender,
        sizeRanges: brandSizes,
      };
    }
  },
  convertSize(sizeCountries: CountrySizeRanges, usSize: number): SizeConversion {
    const sizeIdx = sizeCountries.us.indexOf(usSize);

    if (sizeIdx === -1) {
      throw new Error(`Invalid us size: ${usSize}`);
    }

    return reduce(
      sizeCountries,
      (result, sizeRange, sizeUnit): SizeConversion => {
        result[sizeUnit] = {
          value: sizeRange[sizeIdx],
          unit: sizeUnit,
        };
        return result;
      },
      {} as SizeConversion,
    );
  },
  /**
   * Generates readable string for sneaker size
   *
   * @param sizeUnit {SizeUnit} the size unit that we want to convert to
   * @param usSize The US size
   * @param sizeConfig SizeConfig | null (null instead of undefined so we don't forget to pass it in)
   * @returns {string} formatted size label for display purposes
   */
  makeSizeLabel(sizeUnit: SizeUnit, usSize: string, sizeConfig: SizeConfig | null): string {
    if (usSize === "all") {
      return `${sizeUnit.toUpperCase()} All`;
    }

    if (!sizeConfig) {
      return `${sizeUnit.toUpperCase()} ${usSize}`;
    }

    let sizeValue = parseFloat(usSize);
    let genderIndicator = sizeConversionUtil.getGenderIndicatorForSize(sizeValue, sizeConfig, sizeUnit);

    if (sizeUnit !== "us") {
      const sizeConversion = sizeConversionUtil.convertSize(sizeConfig.sizeRanges, sizeValue);
      const sizePreferenceObj = sizeConversion[sizeUnit];
      genderIndicator = sizeConversionUtil.getGenderIndicatorForSize(
        sizeConversion[sizeUnit].value,
        sizeConfig,
        sizeUnit,
      );
      sizeValue = sizePreferenceObj.value;
    }

    return `${sizeUnit.toUpperCase()} ${sizeValue}${genderIndicator}`;
  },

  filterSizeRange(sizeUnit: SizeUnit, sizeCountries: CountrySizeRanges, currentRange: number[]): number[] {
    const sizeUnitRangeLength = sizeCountries[sizeUnit].length;

    let usSizeRange = sizeCountries.us;
    if (sizeUnitRangeLength < sizeCountries.us.length) {
      usSizeRange = usSizeRange.slice(0, sizeUnitRangeLength);
    }

    const newRange = currentRange.filter((s) => {
      return usSizeRange.includes(s);
    });

    return newRange;
  },
};

export default sizeConversionUtil;
