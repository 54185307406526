import { orderBy } from "lodash-es";
import React, { useState } from "react";
import useCommunities from "../../hooks/useCommunities";
import { Accordion } from "../../libs/semantic-ui";
import ExpandableCommunityList from "../CommunitySideMenu/ExpandableCommunityList";

export interface CommunityListPanelProps {
  onSelect?: () => void;
}

const CommunityListPanel: React.FC<CommunityListPanelProps> = ({ onSelect }) => {
  const { moderatedCommunities, unsubscribedCommunities, subscribedCommunities, isPending } = useCommunities();
  const [yourCommunitiesActive, setYourCommunitiesActive] = useState(true);
  const [otherCommunitiesActive, setOtherCommunitiesActive] = useState(true);
  const [moderatedCommunitiesActive, setModeratedCommunitiesActive] = useState(true);

  const sortedModeratedCommunities = orderBy(
    moderatedCommunities,
    [(community) => community.num_subscribers],
    ["desc"],
  );

  const sortedSubbedCommunities = orderBy(subscribedCommunities, [(community) => community.num_subscribers], ["desc"]);

  const sortedUnSubbedCommunities = orderBy(
    unsubscribedCommunities,
    [(community) => community.num_subscribers],
    ["desc"],
  );

  return (
    <Accordion fluid>
      {moderatedCommunities.length ? (
        <ExpandableCommunityList
          title={"Moderating"}
          communities={sortedModeratedCommunities}
          isActive={moderatedCommunitiesActive}
          toggleActive={() => {
            setModeratedCommunitiesActive(!moderatedCommunitiesActive);
          }}
          onSelect={onSelect}
          loading={isPending}
        />
      ) : null}
      {subscribedCommunities.length ? (
        <ExpandableCommunityList
          title={"Your Communities"}
          communities={sortedSubbedCommunities}
          isActive={yourCommunitiesActive}
          toggleActive={() => {
            setYourCommunitiesActive(!yourCommunitiesActive);
          }}
          onSelect={onSelect}
          loading={isPending}
        />
      ) : null}
      <ExpandableCommunityList
        title={subscribedCommunities?.length ? "Other Communities" : "Communities"}
        communities={sortedUnSubbedCommunities}
        isActive={otherCommunitiesActive}
        toggleActive={() => {
          setOtherCommunitiesActive(!otherCommunitiesActive);
        }}
        onSelect={onSelect}
        loading={isPending}
      />
    </Accordion>
  );
};

export default CommunityListPanel;
