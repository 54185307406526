import { useMemo } from "react";
import { useSigninCheck } from "reactfire";

const useIsAdmin = (): {
  isAdmin: boolean;
  isPending: boolean;
  isFinished: boolean;
} => {
  const { data, status } = useSigninCheck({
    requiredClaims: {
      role: "admin",
    },
  });

  return useMemo(
    () => ({
      isAdmin: data?.hasRequiredClaims || false,
      isPending: status === "loading",
      isFinished: status === "success",
    }),
    [data?.hasRequiredClaims, status],
  );
};

export default useIsAdmin;
