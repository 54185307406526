import { useEffect, useMemo, useState } from "react";
import { QueryState } from "redux-query";
import PlugdAlgolia from "../clients/PlugdAlgolia";
import plugdAlgolia from "../libs/plugdAlgolia";

const usePlugdAlgolia = (): [QueryState, PlugdAlgolia | undefined] => {
  const [algolia, setAlgolia] = useState<PlugdAlgolia | undefined>(undefined);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  useEffect(() => {
    setIsPending(true);
    plugdAlgolia()
      .then((lib) => {
        if (lib) {
          setAlgolia(lib);
        }
      })
      .finally(() => {
        setIsPending(false);
        setIsFinished(true);
      });
  }, []);
  return useMemo(() => [{ isPending, isFinished }, algolia], [algolia, isFinished, isPending]);
};

export default usePlugdAlgolia;
