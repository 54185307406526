import React from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../Box";
import Link from "../Link";
import Text from "../Text";
import UserAvatar, { UserAvatarProps } from "../UserAvatar";

export interface UserMetaProps extends BoxProps {
  disableLink?: boolean;
  avatarProps?: UserAvatarProps;
  profile: {
    username: string;
    full_name?: string | null;
    profile_image?: string | null;
  };
}

const UserMeta: React.FC<UserMetaProps> = ({ avatarProps, profile, disableLink, ...wrapperProps }) => {
  return (
    <UserMetaWrapper
      {...wrapperProps}
      as={disableLink ? undefined : Link}
      to={disableLink ? undefined : `/u/${profile.username}`}
    >
      <UserAvatar
        src={profile.profile_image || undefined}
        name={profile.username || undefined}
        username={profile.username}
        size="large"
        {...avatarProps}
      />
      <UserNamesWrapper>
        {profile.username && <UsernameText title={profile.username}>{`@${profile.username}`}</UsernameText>}
        <FullNameText title={profile.full_name || "User"}>{profile.full_name || "User"}</FullNameText>
      </UserNamesWrapper>
    </UserMetaWrapper>
  );
};

const UsernameText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
UsernameText.defaultProps = {
  fontSize: 2,
  fontWeight: 5,
  color: "black",
};

const FullNameText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
FullNameText.defaultProps = {
  color: "darkGrey",
};

export const UserMetaWrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 0px;
`;

export const UserNamesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
`;
UserNamesWrapper.defaultProps = {
  ml: 2,
};

export default UserMeta;
