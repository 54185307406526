import styled, { css, DefaultTheme } from "styled-components";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  ThemeValue,
  typography,
  TypographyProps,
} from "styled-system";

// todo: add more values
interface CustomTextProps {
  textDecorationColor?: ThemeValue<"colors", DefaultTheme>;
  textDecoration?: "overline" | "line-through" | "underline" | "initial";
  clickable?: boolean;
}

export type TextProps = React.HTMLAttributes<HTMLSpanElement> &
  CustomTextProps &
  ColorProps<DefaultTheme> &
  LayoutProps<DefaultTheme> &
  SpaceProps<DefaultTheme> &
  TypographyProps<DefaultTheme>;

const Text = styled.span<TextProps>`
  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        `
      : ""}
  ${({ textDecoration }) =>
    textDecoration
      ? css`
          text-decoration: ${textDecoration};
        `
      : ""}
  ${({ textDecorationColor, theme }) =>
    textDecorationColor
      ? css`
          text-decoration-color: ${theme.colors[textDecorationColor]};
        `
      : ""}
  ${layout}
  ${color}
  ${space}
  ${typography}
`;

export default Text;
