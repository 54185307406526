import React, { ReactNode, useMemo } from "react";
import styled, { css } from "styled-components";
import useWindowSize from "../../../hooks/useWindowSize";
import { Dimmer, TransitionablePortal, TransitionProps } from "../../../libs/semantic-ui";
import { Box, BoxProps, ScrollBox, ScrollBoxProps } from "../../../UI";

export interface FullscreenMenuProps extends Omit<ScrollBoxProps, "maxWidth"> {
  children: ReactNode;
  close: () => void;
  closeOnDocumentClick?: boolean;
  containerProps?: BoxProps;
  header?: ReactNode;
  footer?: ReactNode;
  isOpen: boolean;
  maxWidth?: number;
  transition?: TransitionProps;
  wrapperProps?: BoxProps;
}

const defaultTransition = { duration: 400, animation: "fade up" };

const FullscreenMenu: React.FC<FullscreenMenuProps> = ({
  children,
  close,
  containerProps,
  header,
  footer,
  isOpen,
  transition,
  wrapperProps,
  maxWidth,
  ...scrollBoxProps
}) => {
  const { innerWidth: windowWidth } = useWindowSize();
  const scrollLockProps = useMemo(() => ({ isActive: isOpen }), [isOpen]);
  const transitionProp = useMemo(() => ({ ...defaultTransition, ...transition }), [transition]);

  return (
    <TransitionablePortal
      closeOnDocumentClick={false}
      closeOnDimmerClick={false}
      eventPool="FullscreenMenu"
      onClose={close}
      open={isOpen}
      transition={transitionProp}
    >
      <ContentWrapper width="100%" display="flex" flexShrink={0} justifyContent="center" {...wrapperProps}>
        <Dimmer style={{ zIndex: 60 }} active={isOpen} onClick={close} page />
        <Container maxWidth={maxWidth || Math.min(windowWidth, 614)} {...containerProps}>
          {header}
          <ScrollBox
            flexGrow={1}
            minHeight={0}
            overflowY="scroll"
            scrollLockProps={scrollLockProps}
            width="100%"
            {...scrollBoxProps}
          >
            {children}
          </ScrollBox>
          {footer}
        </Container>
      </ContentWrapper>
    </TransitionablePortal>
  );
};

const Container = styled(Box)<BoxProps>``;
Container.defaultProps = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  bg: "white",
  flexGrow: 1,
};

const ContentWrapper = styled(Box)<BoxProps>`
  ${({ display }) =>
    display
      ? css`
          && {
            display: ${display} !important;
          }
        `
      : ""}
  z-index: 60;
  position: fixed;
  overflow: hidden;
`;
ContentWrapper.defaultProps = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export default FullscreenMenu;
