import React from "react";
import { useLocation } from "react-router-dom";
import config, { isStaging } from "../config";
import plugdLogo from "../images/plugd-wordmark-black-logo-750.png";
import plugdIOSIcon from "../images/plugd-wordmark-gold-logo-192.png";
import Meta from "./Meta";

const metaDescription =
  "Plugd is a social shopping platform for sneakers & streetwear. Join the community for fashion inspiration, release information, and tips from other sneakerheads.";
const metaTitle = "Shop, Share, Discover Sneakers | Plugd";
const plugdLogoUrl = `${config.URL}${plugdLogo.src}`;

export interface AppMetaProps {}

const AppMeta: React.FC<AppMetaProps> = () => {
  const location = useLocation();
  /**
   * Indexable search params. Index page by default
   */
  const currentSearchParams = new URLSearchParams(location.search);
  const page = currentSearchParams.get("page");
  const searchParams = new URLSearchParams();
  if (page) {
    searchParams.set("page", page);
  }

  const searchParamsStr = searchParams.toString();
  const searchStr = searchParamsStr ? `?${searchParamsStr}` : "";

  return (
    <Meta>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={`${config.ROOT_DOMAIN}${location.pathname}${searchStr}`} />
      <link rel="icon" sizes="192x192" href={plugdIOSIcon.src} />
      <link rel="apple-touch-icon" href={plugdIOSIcon.src} />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:site_name" content="Plugd" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={config.URL} />
      <meta property="og:image" content={plugdLogoUrl} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={config.TWITTER_HANDLE} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:image" content={plugdLogoUrl} />
      <meta name="twitter:image:alt" content="Plugd" />
      <meta name="twitter:description" content={metaDescription} />
      {isStaging && <meta name="prerender-status-code" content="404" />}
    </Meta>
  );
};

export default AppMeta;
