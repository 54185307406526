import produce from "immer";
import stringify from "json-stable-stringify";
import { forEach, omit, reduce, union } from "lodash-es";
import { EntitiesState } from "redux-query";
import { CustomActionPromise } from "../hooks/useControlledRequest";
import { actionPromiseToPromise } from "./reactQueryUtil";

export const shallowObjectMerge = <T extends object>(oldValue: T, newValue: Partial<T>) =>
  produce(oldValue, (draft) => {
    forEach(newValue, (value, key) => {
      draft[key] = value;
    });
  });

export const updateNestedUniqueArrays = (
  oldValue: Record<number, number[]>,
  newValue: Record<number, number[]>,
  newestFirst = false,
): Record<number, number[]> => {
  return produce(oldValue, (draft) => {
    reduce(
      newValue,
      (result, newValueArray, key) => {
        const oldValueArray = result[key] || [];
        if (newestFirst) {
          result[key] = union(newValueArray.concat(oldValueArray));
        } else {
          result[key] = union(oldValueArray.concat(newValueArray));
        }
        return result;
      },
      draft,
    );

    return draft;
  });
};

export const groupIdsBy = <T extends { id: number }>(
  collection: { [key: number]: T },
  keyBy: (item: T) => number,
): { [key: number]: number[] } =>
  reduce(
    collection,
    (result, item) => {
      const currentIds = result[keyBy(item)];
      if (currentIds) {
        currentIds.push(item.id);
      } else {
        result[keyBy(item)] = [item.id];
      }
      return result;
    },
    {},
  );

/**
 * Used for generating a unique key for lists from their their query object and removing the pagination options
 * @param options
 * @returns
 */
export const generateListKey = <O extends object>(options: O): string => {
  const keyOptions = omit(options, ["limit", "offset"]);
  return stringify(keyOptions);
};

/**
 * Calculates whether a fetched resource has more to be fetched by comparing the limit and the returned value length (e.g. if the limit is 20, and 18 resources are returned, then there aren't any more to be fetched)
 * This is a temporary fix until we add pagination metadata from the back end to our responses.
 *
 * @param fetchCb
 * @param listResolver Resolve to the list of values to be evaluated
 * @param setHasMore
 * @param limit
 */
export const handleHasMore = async <R = unknown, V = unknown>(
  fetchCb: CustomActionPromise<EntitiesState, R>,
  listResolver: (result: R) => V[],
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>,
  limit?: number,
) => {
  const { body } = await actionPromiseToPromise<EntitiesState, R>(fetchCb);
  if (body) {
    const valueList = listResolver(body);
    if (limit ? valueList.length < limit : valueList.length === 0) {
      setHasMore(false);
    }
  }
};
