import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { OutboundMessageType } from "../../../../enums/MessageType";
import sendReactNativeMessage from "../../../../messages/sendReactNativeMessage";
import { NativeAppState } from "../../../../reducers/nativeAppReducer";
import { RootState } from "../../../../store";
import DebugLogItem from "./DebugLogItem";

export interface DebugLogNativeAppProps {
  isOpen: boolean;
}

const DebugLogNativeApp: React.FC<DebugLogNativeAppProps> = ({ isOpen }) => {
  const debugLogs = useSelector<RootState, NativeAppState["debugLog"]>((state) => state.nativeApp.debugLog);

  // fetch logs from native app
  useEffect(() => {
    if (isOpen) {
      sendReactNativeMessage({
        action: OutboundMessageType.GET_DEBUG_LOG,
      });
    }
  }, [isOpen]);

  return (
    <>
      {debugLogs.map(([logType, message], idx) => (
        <DebugLogItem key={idx} logType={logType} message={message} />
      ))}
    </>
  );
};

export default DebugLogNativeApp;
