import { QueryConfig } from "redux-query";
import config from "../../config";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { UsersAPI } from "../../typings/API";
import { PreferencesState } from "../../typings/EntitiesState";

export const updateUserPreferences = (
  uid: string,
  preferences: UsersAPI.UserPreferences,
): QueryConfig<PreferencesState> => {
  return {
    url: `${config.API_URL}/users/${uid}/preferences`,
    meta: {
      includeToken: true,
    },
    body: preferences,
    options: {
      method: "PUT",
      headers: STANDARD_HEADERS,
    },
    transform: (response: { preferences: UsersAPI.UserPreferences }): PreferencesState => {
      const preferences = response.preferences;
      return {
        preferences,
      };
    },
    optimisticUpdate: {
      preferences: (oldValue) => ({ ...oldValue, ...preferences }),
    },
  };
};
