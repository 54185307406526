import React from "react";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "redux-query-react";
import { captureException } from "../../../helpers/errorUtil";
import { actionPromiseToPromise } from "../../../helpers/reactQueryUtil";
import useAuthenticatedCallback from "../../../hooks/useAuthenticatedCallback";
import useConfirmationCallback from "../../../hooks/useConfirmationCallback";
import { flagPostQuery } from "../../../queries/api/postFlagQuery";
import { PostsAPI } from "../../../typings/API-V2";
import ActionsMenuItem, { ActionsMenuItemProps } from "../ActionsMenuItem";

export interface PostActionsFlagMenuItemProps extends ActionsMenuItemProps {
  post: PostsAPI.Post;
  close: () => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PostActionsFlagMenuItem: React.FC<PostActionsFlagMenuItemProps> = ({
  post,
  onClick,
  close,
  ...menuItemProps
}) => {
  const [, flagPost] = useMutation(() => flagPostQuery(post.id));
  const { addToast } = useToasts();

  const handleFlagPost = useAuthenticatedCallback(
    async (e) => {
      try {
        await actionPromiseToPromise(flagPost());
        addToast(`Post flagged`, {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (error) {
        addToast(`An error occurred`, {
          appearance: "warning",
          autoDismiss: true,
        });
        captureException(error);
      }
      if (onClick) {
        onClick(e);
      }
      close();
    },
    [flagPost, addToast, onClick, close],
  );

  const flagWithConfirmation = useConfirmationCallback("Flag this post?", handleFlagPost, []);

  return <ActionsMenuItem {...menuItemProps} onClick={flagWithConfirmation} />;
};

export default PostActionsFlagMenuItem;
