import React from "react";
import styled from "styled-components";
import { Box, BoxProps, Icon, Spacer, Text } from "../../../../UI";

export interface AddImageBoxProps extends BoxProps {
  onClick: Required<BoxProps>["onClick"];
}

const AddImageBox: React.FC<AddImageBoxProps> = ({ onClick, ...containerProps }) => {
  return (
    <SquareContainer>
      <Container onClick={onClick} {...containerProps}>
        <Icon name="add circle" size="large" />
        <Spacer mt={2} />
        <Text fontWeight={600} color="black">
          Add image
        </Text>
      </Container>
    </SquareContainer>
  );
};

const SquareContainer = styled(Box)`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
`;

const Container = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
Container.defaultProps = {
  borderColor: "mediumGrey",
  borderRadius: 2,
  border: "1px dashed",
  role: "button",
};

export default AddImageBox;
