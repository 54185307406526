import React, { useEffect } from "react";
import analyticsUtil, { trackEvent } from "../../helpers/analyticsUtil";
import { MenuProps } from "../../hooks/useMenuState";
import { Button, Icon, Modal } from "../../libs/semantic-ui";
import { ProductsAPI } from "../../typings/API";
import Share, { ShareProps } from "./Share";

export interface ShareModalProps extends MenuProps, ShareProps {
  product?: ProductsAPI.Product | null;
}

const ShareModal: React.FC<ShareModalProps> = ({ close, image, isOpen, medium, title, url, ...modalProps }) => {
  useEffect(() => {
    if (isOpen) {
      trackEvent(analyticsUtil.ShareMenu.Viewed);
    }
  }, [isOpen]);

  return (
    <Modal size="tiny" onClose={close} eventPool="ShareModal" open={isOpen} {...modalProps}>
      <Modal.Header as="h1">{"Share to..."}</Modal.Header>
      <Modal.Content>
        <Share url={url} title={title} image={image} medium={medium} />
      </Modal.Content>
      <Modal.Actions>
        <Button icon onClick={close} labelPosition="right">
          Close
          <Icon name="close" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ShareModal;
