import styled from "styled-components";
import { Form } from "../../libs/semantic-ui";

const FormInput = styled(Form.Input)`
  &&&& {
    input {
      border: 0;
      outline: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
      border-radius: 0;
    }
  }
`;

export default FormInput;
