import React, { useCallback } from "react";
import styled from "styled-components";
import { Modal, TransitionablePortal } from "../../libs/semantic-ui";
import { Button } from "../../UI";

export interface ConfirmationModalProps {
  close: () => void;
  isOpen: boolean;
  message: string;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ close, isOpen, message, onConfirm }) => {
  const handleConfirm = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onConfirm(e);
      close();
    },
    [onConfirm, close],
  );

  return (
    <TransitionablePortal open={isOpen} animation="fade up" duration={400}>
      <Modal open={isOpen} onClose={close} size="mini">
        <Modal.Header>{message}</Modal.Header>
        <ModalActions>
          <Button className="button" size="small" variant="tertiary" onClick={close}>
            Cancel
          </Button>
          <Button className="button" size="small" variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </ModalActions>
      </Modal>
    </TransitionablePortal>
  );
};

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: flex-end;
`;

export default ConfirmationModal;
