import { isEmpty, isNil } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import useMenuState from "../../../hooks/useMenuState";
import { getCommunityThemes } from "../../../queries/api/communityQuery";
import { RootState } from "../../../store";
import { CommunitiesAPI } from "../../../typings/API";
import { PostTypeKey } from "../../../typings/API-V2";
import PostThemeSelectorMenu from "../../PostThemeSelectorMenu";
import PostThemeMenuOpener from "../PostThemeMenuOpener";
import { UseInputHook } from "../types/inputHooks";

export interface UseThemeSelectorInputOptions {
  communityId?: number;
  defaultThemeId?: number;
  postTypeKey: PostTypeKey;
}

const useThemeSelectorInput: UseInputHook<number | null, UseThemeSelectorInputOptions> = ({
  communityId,
  defaultThemeId,
  postTypeKey,
}) => {
  const [theme, setTheme] = useState<CommunitiesAPI.CommunityTheme | null>(null);

  /** if the community changes, reset the theme */
  useEffect(() => setTheme(null), [communityId]);

  const { open: openThemeSelector, menuProps: themeSelectorMenuProps } = useMenuState();

  /** fetch community themes */
  useRequest(communityId ? getCommunityThemes(communityId) : null);
  const communityThemes = useSelector((state: RootState) =>
    communityId ? state.entities.communityThemesByCommunityId[communityId] : null,
  );
  const hasThemes = !isNil(communityThemes) && !isEmpty(communityThemes);

  const defaultTheme = useMemo<CommunitiesAPI.CommunityTheme | null>(() => {
    if (defaultThemeId && communityThemes) {
      return communityThemes.find((c) => c.id === defaultThemeId) || null;
    }
    return null;
  }, [defaultThemeId, communityThemes]);

  useEffect(() => {
    if (defaultTheme) {
      setTheme(defaultTheme);
    }
  }, [defaultTheme]);

  const input = useMemo(() => {
    return communityId && hasThemes ? (
      <PostThemeMenuOpener communityId={communityId} themeId={theme?.id} onClick={openThemeSelector} />
    ) : (
      <></>
    );
  }, [hasThemes, openThemeSelector, communityId, theme?.id]);

  const menus = useMemo(
    () =>
      communityId && themeSelectorMenuProps.isOpen ? (
        <PostThemeSelectorMenu
          postTypeKey={postTypeKey}
          communityId={communityId}
          isOpen={themeSelectorMenuProps.isOpen}
          close={themeSelectorMenuProps.close}
          onSelectTheme={setTheme}
          value={theme}
        />
      ) : null,
    [communityId, postTypeKey, theme, themeSelectorMenuProps],
  );

  const clearValue = useCallback(() => setTheme(null), []);

  return useMemo(
    () => ({
      input,
      menus,
      clearValue: clearValue,
      value: theme?.id || null,
    }),
    [input, menus, clearValue, theme],
  );
};

export default useThemeSelectorInput;
