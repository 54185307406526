import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from "react-router-dom";
import styled, { DefaultTheme } from "styled-components";
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from "styled-system";

export type LinkProps = ReactRouterLinkProps &
  BackgroundProps<DefaultTheme> &
  BorderProps<DefaultTheme> &
  ColorProps<DefaultTheme> &
  FlexboxProps<DefaultTheme> &
  LayoutProps<DefaultTheme> &
  PositionProps<DefaultTheme> &
  SpaceProps<DefaultTheme> &
  BoxShadowProps<DefaultTheme> & {
    disableUnderline?: boolean;
  };

const Link = styled(ReactRouterLink)<LinkProps>`
  &:hover {
    color: ${({ theme, color }) => (color ? theme.colors[color] : "inherit")};
    ${({ disableUnderline }) => (disableUnderline ? "" : "text-decoration: underline;")}
  }
  ${background}
  ${border}
  ${boxShadow}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
`;

export default Link;
