import React, { ReactNode, useCallback, useState } from "react";
import { NavLink as RRNavLink, NavLinkProps as RRNavLinkProps } from "react-router-dom";
import styled, { DefaultTheme } from "styled-components";
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from "styled-system";

export type NavLinkProps = RRNavLinkProps &
  BackgroundProps<DefaultTheme> &
  BorderProps<DefaultTheme> &
  ColorProps<DefaultTheme> &
  FlexboxProps<DefaultTheme> &
  LayoutProps<DefaultTheme> &
  PositionProps<DefaultTheme> &
  SpaceProps<DefaultTheme> &
  BoxShadowProps<DefaultTheme> & {
    children?: ReactNode | ((args: { isActive: boolean }) => ReactNode);
  };

const NavLink: React.FC<NavLinkProps> = ({ children, isActive, ...navLinkProps }) => {
  const [navLinkIsActive, setNavLinkIsActive] = useState<boolean>(false);
  const handleIsActive = useCallback<NonNullable<RRNavLinkProps["isActive"]>>(
    (match, location) => {
      let routeIsActive = Boolean(match);
      if (isActive) {
        routeIsActive = isActive(match, location);
      }

      if (routeIsActive && !navLinkIsActive) {
        setNavLinkIsActive(true);
      }

      if (!routeIsActive && navLinkIsActive) {
        setNavLinkIsActive(false);
      }

      return routeIsActive;
    },
    [navLinkIsActive, isActive],
  );

  return (
    <NavLinkComponent isActive={handleIsActive} {...navLinkProps}>
      {typeof children === "function" ? children({ isActive: navLinkIsActive }) : children}
    </NavLinkComponent>
  );
};

const NavLinkComponent = styled(RRNavLink)<NavLinkProps>`
  ${background}
  ${border}
  ${boxShadow}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
`;

export default NavLink;
