import React from "react";
import { Box, BoxProps } from "../../../UI";
import { TOP_NAV_HEIGHT } from "../TopNav";

export const FULLSCREEN_MENU_HEADER_HEIGHT = TOP_NAV_HEIGHT;

export interface FullscreenMenuHeaderProps extends BoxProps {}

const FullscreenMenuHeader: React.FC<FullscreenMenuHeaderProps> = ({ children, ...boxProps }) => {
  return (
    <Box
      bg="white"
      borderBottom="1px solid"
      borderColor="lightGrey"
      flexShrink={0}
      height={FULLSCREEN_MENU_HEADER_HEIGHT}
      px={2}
      width="100%"
      {...boxProps}
    >
      {children}
    </Box>
  );
};
FullscreenMenuHeader.defaultProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export default FullscreenMenuHeader;
