import styled from "styled-components";
import { Radio, RadioProps } from "../../libs/semantic-ui";

export interface ToggleProps extends RadioProps {}

const Toggle = styled(Radio)<ToggleProps>`
  &&& {
    input:checked ~ label:before {
      background: ${({ theme }) => theme.colors.goldRed} !important;
    }
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background-color: grey;
  }
`;
Toggle.defaultProps = {
  toggle: true,
  type: "radio",
};

export default Toggle;
