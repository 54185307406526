import React from "react";
import styled from "styled-components";
import { CommunitiesAPI } from "../../../typings/API";
import Box, { BoxProps } from "../Box";
import Link from "../Link";
import Text from "../Text";
import UserAvatar, { UserAvatarProps } from "../UserAvatar";

export interface CommunityMetaProps extends BoxProps {
  disableLink?: boolean;
  avatarProps?: UserAvatarProps;
  community: CommunitiesAPI.Community;
}

const CommunityMeta: React.FC<CommunityMetaProps> = ({ avatarProps, community, disableLink, ...wrapperProps }) => {
  return (
    <CommunityMetaWrapper
      {...wrapperProps}
      as={disableLink ? undefined : Link}
      to={disableLink ? undefined : `/c/${community.slug}`}
    >
      <UserAvatar
        src={community.avatar_url || undefined}
        username={community.slug}
        name={community.slug}
        size={avatarProps?.size || "small"}
        {...avatarProps}
      />
      <CommunityNamesWrapper>
        <CommunitySlugText title={community.slug}>{`c/${community.slug}`}</CommunitySlugText>
      </CommunityNamesWrapper>
    </CommunityMetaWrapper>
  );
};

const CommunitySlugText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
CommunitySlugText.defaultProps = {
  fontSize: 1,
  fontWeight: 4,
  color: "black",
};

const FullNameText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
FullNameText.defaultProps = {
  color: "darkGrey",
};

export const CommunityMetaWrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 0px;
`;

export const CommunityNamesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
`;
CommunityNamesWrapper.defaultProps = {
  ml: 2,
};

export default CommunityMeta;
