import { LocationDescriptorObject } from "history";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import LocationState from "../typings/LocationState";

export type UseNavigateToProduct = () => (
  slug: string,
  options?: Omit<LocationDescriptorObject<LocationState.ClickTrackState>, "pathname">,
) => void;

const useNavigateToProduct: UseNavigateToProduct = () => {
  const history = useHistory();
  return useCallback<ReturnType<UseNavigateToProduct>>(
    (slug, options) =>
      history.push({
        pathname: `/products/${slug}`,
        ...options,
      }),
    [history],
  );
};

export default useNavigateToProduct;
