import { uniq } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { PostFlagsState } from "../../typings/EntitiesState";

export const flagPostQuery = (postId: number): QueryConfig<PostFlagsState> => ({
  url: `${config.API_URL}/posts/${postId}/flag`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    flaggedPostIds: (prevValue) => uniq(prevValue.concat([postId])),
  },
});
