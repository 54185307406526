import React from "react";
import styled from "styled-components";
import { Progress } from "../../../../libs/semantic-ui";
import { PostsAPI } from "../../../../typings/API-V2";
import { Box, BoxProps, Icon } from "../../../../UI";

export interface PreviewImageProps extends BoxProps {
  remove?: React.MouseEventHandler<HTMLDivElement>;
  src: string;
  tags: (PostsAPI.PostImageTagInsert | PostsAPI.PostImageTag)[] | null;
  upload?: {
    isFinished: boolean;
    error?: string;
  };
}

const PreviewImage: React.FC<PreviewImageProps> = ({ src, remove, tags, upload, ...containerProps }) => {
  return (
    <Container>
      <ContainerInner src={src} {...containerProps}>
        {remove && (
          <CloseButtonWrapper onClick={remove}>
            <Icon name="close" circular={true} inverted={true} color="black" />
          </CloseButtonWrapper>
        )}
        {tags && tags.length > 0 && (
          <TagsWrapper>
            <Icon name="tags" circular={true} inverted={true} color="black" />
          </TagsWrapper>
        )}
        {upload && (
          <Progress
            active={!upload.error && !upload.isFinished}
            color={upload.error ? "red" : "green"}
            percent={upload.isFinished ? 100 : 33}
            size="tiny"
          />
        )}
      </ContainerInner>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
`;

const ContainerInner = styled(Box)<BoxProps & { src: string }>`
  overflow: hidden;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
ContainerInner.defaultProps = {
  borderColor: "mediumGrey",
  borderRadius: 2,
};

const ICON_SIZE = 24;
const IconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: ${({ theme }) => theme.colors.white} !important;
`;
IconWrapper.defaultProps = {
  bg: "black",
  role: "button",
  minWidth: ICON_SIZE,
  height: ICON_SIZE,
  borderRadius: ICON_SIZE / 2,
};

const CloseButtonWrapper = styled(IconWrapper)``;
CloseButtonWrapper.defaultProps = {
  top: ICON_SIZE / 2,
  right: ICON_SIZE / 2,
};

const TagsWrapper = styled(IconWrapper)``;
TagsWrapper.defaultProps = {
  bottom: ICON_SIZE / 2,
  left: ICON_SIZE / 2,
};

export default PreviewImage;
