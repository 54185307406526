import React from "react";
import { useAuth } from "reactfire";

export interface AuthenticatedProps {}

const Authenticated: React.FC<React.PropsWithChildren<AuthenticatedProps>> = ({ children }) => {
  const auth = useAuth();
  if (!auth.currentUser?.uid) {
    return null;
  }
  return <>{children}</>;
};

export default Authenticated;
