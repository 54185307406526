import { useEffect, useMemo, useState } from "react";
import { retry } from "ts-retry-promise";
import { captureException } from "../helpers/errorUtil";

/**
 * hooks that loads async imports
 */
const useLibImport = <Lib>(
  imp: Promise<Lib>,
): {
  isFinished: boolean;
  isPending: boolean;
  lib: Lib | undefined;
} => {
  const [lib, setLib] = useState<Lib | undefined>(undefined);
  const hasLib = lib !== undefined;
  const [{ isPending, isFinished }, setQueryState] = useState<{ isPending: boolean; isFinished: boolean }>({
    isPending: false,
    isFinished: false,
  });

  useEffect(() => {
    if (!hasLib && !isFinished && !isPending) {
      setQueryState({
        isPending: true,
        isFinished: false,
      });
      retry(() => imp, { retries: 5 })
        .then((lib) => setLib(() => lib))
        .catch((error) => captureException(error))
        .finally(() => {
          setQueryState({
            isPending: false,
            isFinished: true,
          });
        });
    }
  }, [hasLib, imp, isFinished, isPending]);

  return useMemo(
    () => ({
      isFinished,
      isPending,
      lib,
    }),
    [isPending, isFinished, lib],
  );
};

export default useLibImport;
