import React from "react";
import RefreshMessageContentWrapper from "./RefreshMessageContentWrapper";

export interface PullToRefreshContentProps {}

const PullToRefreshContent: React.FC<React.PropsWithChildren<PullToRefreshContentProps>> = ({ children }) => (
  <RefreshMessageContentWrapper>{children || "Pull to Refresh"}</RefreshMessageContentWrapper>
);

export default PullToRefreshContent;
