import React from "react";
import { UserActionListItemWrapper, UserActionListItemWrapperProps } from "./UserActionListItem";
import UserMetaPlaceholder from "./UserMetaPlaceholder";

export interface UserActionListItemPlaceholderProps extends UserActionListItemWrapperProps {}

const UserActionListItemPlaceholder: React.FC<UserActionListItemPlaceholderProps> = ({ ...wrapperProps }) => {
  return (
    <UserActionListItemWrapper {...wrapperProps}>
      <UserMetaPlaceholder />
    </UserActionListItemWrapper>
  );
};

export default UserActionListItemPlaceholder;
