import { toLower } from "lodash-es";
import React, { useCallback } from "react";
import styled from "styled-components";
import useAutoFocusRefCallback from "../../hooks/useAutoFocusRefCallback";
import { Divider } from "../../libs/semantic-ui";
import { Box, Input, Text } from "../../UI";

export interface CommunitySlugFormProps {
  slug?: string;
  onChange: (slug: string | undefined) => void;
}

const CommunitySlugForm: React.FC<CommunitySlugFormProps> = ({ slug, onChange }) => {
  const [communitySlugInputRef] = useAutoFocusRefCallback();

  const handleSlugChange = useCallback(
    (e): void => {
      const value = e.target.value;
      const formattedSlug = toLower(value.replace(/\s/g, "_"));
      onChange(formattedSlug);
    },
    [onChange],
  );

  return (
    <Box px={2}>
      <Box width="100%" mt={2} mb={3}>
        <Text textAlign="left" fontSize={3} fontWeight={600}>
          Set a handle for your community
        </Text>
        <Box color="darkGrey" my={1}>
          {`Your commnity will also be parked at getplugd.com/c/${slug}`}
        </Box>
        <Box mt={4} display="flex" alignItems="center">
          <Text color="mediumGrey">c/</Text>
          <Input
            ref={communitySlugInputRef}
            value={slug}
            onChange={handleSlugChange}
            maxLength={20}
            pattern="[a-zA-Z0-9_]+"
          />
        </Box>
      </Box>
      <InputDivider />
      <Text color="mediumGrey" textAlign={"right"}>{`${slug?.length || 0}/20`}</Text>
    </Box>
  );
};

const InputDivider = styled(Divider)`
  &&&& {
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

export default CommunitySlugForm;
