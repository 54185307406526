import React from "react";
import styled from "styled-components";
import { Button } from "../../libs/semantic-ui";
import { Box, ButtonProps, Text } from "../../UI";

export interface SecondaryLoginButtonProps {
  onClick: ButtonProps["onClick"];
  imgSrc: string;
}

const SecondaryLoginButton: React.FC<React.PropsWithChildren<SecondaryLoginButtonProps>> = ({
  children,
  imgSrc,
  onClick,
}) => {
  return (
    <ButtonWrapper>
      <LoginButton onClick={onClick}>
        <Box flex={1} display="flex">
          <img className="lazyload" alt="email-auth" data-src={imgSrc} />
        </Box>
        <Text verticalAlign="middle">{children}</Text>
        <Box flex={1} display="flex" />
      </LoginButton>
    </ButtonWrapper>
  );
};

const BUTTON_HEIGHT = 60;

const LoginButton = styled(Button)`
  &&& {
    display: flex;
    height: ${BUTTON_HEIGHT}px;
    border-radius: ${BUTTON_HEIGHT / 2}px;
    align-items: center;
    justify-content: space-around;
    padding-left: 1em;
    padding-right: 1em;
  }
`;
LoginButton.defaultProps = {
  color: "black",
  fluid: true,
  basic: true,
};

const ButtonWrapper = styled(Box)``;
ButtonWrapper.defaultProps = {
  height: BUTTON_HEIGHT,
  mt: 2,
};

export default SecondaryLoginButton;
