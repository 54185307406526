import React from "react";
import { List } from "../../libs/semantic-ui";
import { PostsAPI } from "../../typings/API-V2";
import { Box, Text } from "../../UI";

export interface CommunityRulesProps {
  parentPost?: PostsAPI.PostInsert & { id: number };
  closeMenu?: () => void;
}

const CommunityRules: React.FC<CommunityRulesProps> = () => {
  return (
    <Box py={2} px={3} color="mediumGrey">
      <Box py={1}>
        <Text fontWeight={600}>{`Community Rules`}</Text>
      </Box>
      <List bulleted>
        <List.Item>
          <Text>{`Be respectful`}</Text>
        </List.Item>
        <List.Item>
          <Text>{`No posts about buying, selling, or trading items`}</Text>
        </List.Item>
        <List.Item>
          <Text>{`No advertising, external promotion, or spam`}</Text>
        </List.Item>
        <List.Item>
          <Text>{`No posts about bots, backdoor links, etc.`}</Text>
        </List.Item>
      </List>
    </Box>
  );
};

export default CommunityRules;
