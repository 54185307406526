import { useCallback, useContext } from "react";
import GlobalModalContext from "../components/GlobalModal/GlobalModalContext";

const useConfirmationCallback = <T extends (...args: any[]) => any>(
  message: string,
  cb: T,
  deps: React.DependencyList,
): T | (() => void) => {
  const callback = useCallback(cb, deps);
  const { open: openModal } = useContext(GlobalModalContext);

  return useCallback(
    (...args) => {
      openModal({
        type: "confirmation",
        props: {
          message,
          onConfirm: () => callback(...args),
        },
      });
    },
    [callback, message, openModal],
  );
};

export default useConfirmationCallback;
