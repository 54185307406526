import React, { useMemo } from "react";
import { SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import config from "../../config";
import { Box, BoxProps, Icon, IconProps, Text } from "../../UI";
import OutboundLink from "../links/OutboundLink";

const socialMediaMetas: {
  name: string;
  url: string;
  icon: SemanticICONS;
}[] = [
  {
    name: "Instagram",
    url: config.socialUrls.instagram,
    icon: "instagram",
  },
  {
    name: "Twitter",
    url: config.socialUrls.twitter,
    icon: "twitter",
  },
  {
    name: "Facebook",
    url: config.socialUrls.facebook,
    icon: "facebook",
  },
  {
    name: "Email",
    url: `mailto:${config.EMAIL}`,
    icon: "mail",
  },
];

export interface SocialMediaFooterProps extends BoxProps {
  className?: string;
  iconColor?: IconProps["color"];
}

const SocialMediaFooter: React.FC<SocialMediaFooterProps> = ({ iconColor, ...boxProps }) => {
  const socialListItems = useMemo(
    () =>
      socialMediaMetas.map(({ url, name, icon }) => (
        <OutboundLink key={url} href={url} target="_blank" title={name}>
          <Icon key={url} name={icon} alt={`${name} Icon`} color={iconColor || "white"} />
          <Text>{name}</Text>
        </OutboundLink>
      )),
    [],
  );

  return <Wrapper {...boxProps}>{socialListItems}</Wrapper>;
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export default SocialMediaFooter;
