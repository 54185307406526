import { FirebaseOptions } from "@firebase/app";

export interface EnvConfig {
  common: {
    ALGOLIA_PRODUCT_INDEX: string;
    API_BASE_URL: string;
    BRANCH_KEY: string;
    COMETCHAT_APP_ID: string;
    FIREBASE_CONFIG: FirebaseOptions;
    SENTRY_ENVIRONMENT: string;
    STRIPE_PUBLIC_KEY: string;
    TASK_API_BASE_URL: string;
  };
  mobile: {
    domains: string[];
  };
  web: {
    domains: string[];
  };
}

export interface BaseConfig {
  common: {
    socialUrls: {
      [key: string]: string;
    };
    ANDROID_APP_URL: string;
    IOS_APP_URL: string;
    [key: string]: any;
  };
  development: EnvConfig;
  staging: EnvConfig;
  production: EnvConfig;
}

const baseConfig: BaseConfig = {
  common: {
    socialUrls: {
      angelList: "https://angel.co/company/tryplugd",
      crunchbase: "https://www.crunchbase.com/organization/plugd-inc",
      facebook: "https://www.facebook.com/tryplugd",
      instagram: "https://www.instagram.com/get.plugd",
      linkedin: "https://www.linkedin.com/company/tryplugd",
      medium: "https://medium.com/plugd",
      pinterest: "https://www.pinterest.com/tryplugd",
      reddit: "https://www.reddit.com/r/Plugd",
      tiktok: "https://www.tiktok.com/@get.plugd",
      twitter: "https://twitter.com/tryplugd",
    },
    ANDROID_APP_URL: "https://play.google.com/store/apps/details?id=com.getplugd.plugd",
    APP_ICON_URL: "https://images.getplugd.com/plugd-wordmark-gold-logo.png",
    BLOG: "https://getplugd.com/blog",
    BRANCH_ANDROID_URL: "https://plugd.app.link/HwVeOwVvw1", // https://dashboard.branch.io/link-stats/722345713539749905
    BRANCH_IOS_URL: "https://plugd.app.link/dEzqEDeww1", // https://dashboard.branch.io/link-stats/722346799012168299
    BRANCH_TOAST_URL: "https://plugd.app.link/icGPYfK9qrb", // https://dashboard.branch.io/link-stats/1073140147473014204
    BRANCH_TOP_NAV_APP_URL: "https://plugd.app.link/mobile-web", // https://dashboard.branch.io/link-stats/1072055543978559429
    COMETCHAT_REGION: "us",
    EMAIL: "hello@getplugd.com",
    FULLSTORY_ORG_ID: "o-1BF8GA-na1",
    IOS_APP_URL: "itms-apps://apps.apple.com/app/id1486098895",
    IPAPI_KEY: "cff7fb18e7ce56933d4a1baa0697b30b",
    PRIVACY_POLICY_URL: "https://www.iubenda.com/privacy-policy/66355606/legal",
    ROOT_DOMAIN: "https://getplugd.com",
    /** plugd-extension project */
    SENTRY_DSN: "https://fe1d85a0d52643af87da5e2b42581e84@sentry.io/1527863",
    TERMS_OF_SERVICE_URL: "https://www.iubenda.com/terms-and-conditions/66355606",
    TWITTER_HANDLE: "@tryplugd",
    URL: "https://getplugd.com",
    VIGLINK_KEY: "f6e12a4f62dfcc3281cdc2c38adff84e",
  },
  development: {
    common: {
      ALGOLIA_PRODUCT_INDEX: "staging_PRODUCT_V2",
      API_BASE_URL: "http://localhost:8080",
      BRANCH_KEY: "key_test_hjUAP2wZSujR0HAVZmhhBjdeuwlQQNQV",
      COMETCHAT_APP_ID: "1974518442dc1010",
      FIREBASE_CONFIG: {
        apiKey: "AIzaSyDoVSup6uzB1p7CCPGj1HMqpL1k_PUe_kM",
        authDomain: "plugd-staging-44d5b.firebaseapp.com",
        databaseURL: "https://plugd-staging-44d5b.firebaseio.com",
        projectId: "plugd-staging-44d5b",
        storageBucket: "plugd-staging-44d5b.appspot.com",
        messagingSenderId: "500012422621",
        appId: "1:500012422621:web:3ac4c71cc0bce71104d234",
        measurementId: "G-7HJFGJ5GKF",
      },
      SENTRY_ENVIRONMENT: "development",
      STRIPE_PUBLIC_KEY:
        "pk_test_51Hg6z2IFZ2jtKxrGMgurvSdeoIejV8z1lVBI1yAQ0xjL2oHCJlqKj7KL4lSRRmglfcIFKp04NxXxzbzHB9SIlFMJ003dfGE9Y6",
      TASK_API_BASE_URL: "http://localhost:8080",
    },
    web: {
      domains: ["localhost"],
    },
    mobile: {
      domains: ["localhost"],
    },
  },
  staging: {
    common: {
      ALGOLIA_PRODUCT_INDEX: "staging_PRODUCT_V2",
      API_BASE_URL: "https://plugd-staging-dot-crease.appspot.com",
      BRANCH_KEY: "key_live_onKqN9r5SsgN8OsR1dkk3okazAmNQQNY",
      COMETCHAT_APP_ID: "1974518442dc1010",
      FIREBASE_CONFIG: {
        apiKey: "AIzaSyDoVSup6uzB1p7CCPGj1HMqpL1k_PUe_kM",
        authDomain: "plugd-staging-44d5b.firebaseapp.com",
        databaseURL: "https://plugd-staging-44d5b.firebaseio.com",
        projectId: "plugd-staging-44d5b",
        storageBucket: "plugd-staging-44d5b.appspot.com",
        messagingSenderId: "500012422621",
        appId: "1:500012422621:web:3ac4c71cc0bce71104d234",
        measurementId: "G-7HJFGJ5GKF",
      },
      SENTRY_ENVIRONMENT: "staging",
      STRIPE_PUBLIC_KEY:
        "pk_test_51Hg6z2IFZ2jtKxrGMgurvSdeoIejV8z1lVBI1yAQ0xjL2oHCJlqKj7KL4lSRRmglfcIFKp04NxXxzbzHB9SIlFMJ003dfGE9Y6",
      TASK_API_BASE_URL: "https://staging-dot-crease.appspot.com",
    },
    web: {
      domains: [
        "staging.getplugd.com",
        "plugd-staging.web.app",
        "plugd-staging.firebaseapp.com",
        "plugdweb-staging-dot-crease.appspot.com",
      ],
    },
    mobile: {
      domains: ["plugd-mobile-frame.ngrok.io"],
    },
  },
  production: {
    common: {
      ALGOLIA_PRODUCT_INDEX: "PRODUCT_V2",
      API_BASE_URL: "https://plugd-dot-crease.appspot.com",
      /** TODO: Investigate whether it makes sense for the BRANCH_KEY to be the same in production and staging */
      BRANCH_KEY: "key_live_onKqN9r5SsgN8OsR1dkk3okazAmNQQNY",
      COMETCHAT_APP_ID: "197411ffad4e4e8b",
      FIREBASE_CONFIG: {
        apiKey: "AIzaSyDiymRHpM6O6IosATFgbD8ZXBYmFrILk6g",
        authDomain: "plugd-5b0bd.firebaseapp.com",
        databaseURL: "https://plugd-5b0bd.firebaseio.com",
        projectId: "plugd-5b0bd",
        storageBucket: "plugd-5b0bd.appspot.com",
        messagingSenderId: "840572969183",
        appId: "1:840572969183:web:297247a0abfb13a228c96a",
        measurementId: "G-MZPCJNRM35",
      },
      SENTRY_ENVIRONMENT: "production",
      STRIPE_PUBLIC_KEY:
        "pk_live_51Hg6z2IFZ2jtKxrGLwnqci0n2Ip6faxtl2uMFQVLbu5g2tRUTmYveIHQpGuqZ8Mpl8gO7xJPZILxwLMEtAcTNQVC00R7Yedf1S",
      TASK_API_BASE_URL: "https://crease.appspot.com",
    },
    web: {
      domains: ["getplugd.com"],
    },
    mobile: {
      domains: ["mobile-frame.getplugd.com"],
    },
  },
};

export default baseConfig;
