import { isNil } from "lodash-es";
import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { Label } from "../libs/semantic-ui";
import { Box, Segment, Text } from "../UI";

export interface GroupCardProps {
  title: string;
  subtitle?: number | string | null;
  flush?: boolean;
  children: ReactNode;
}

const GroupCard = (props: GroupCardProps): ReactElement => {
  const GroupCardWrapper = props.flush ? Box : Segment;
  return (
    <GroupCardWrapper display="flex" flexDirection="column" bg="white" width="100%">
      <GroupCardHeader px={3} py={2} display="flex" alignItems="center">
        <Text as="h4" color="black" margin={0} mr={1}>
          {props.title}
        </Text>
        {!isNil(props.subtitle) && (
          <Label size="mini" circular>
            {props.subtitle}
          </Label>
        )}
      </GroupCardHeader>
      <GroupCardBody>{props.children}</GroupCardBody>
    </GroupCardWrapper>
  );
};

const GroupCardHeader = styled(Box)``;
GroupCardHeader.defaultProps = {
  borderBottom: "1px solid",
  borderColor: "mediumGrey",
};

const GroupCardBody = styled(Box)`
  /* max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
`;

export default GroupCard;
