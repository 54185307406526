import { captureException as sentryCaptureException } from "@sentry/browser";
import { CaptureContext } from "@sentry/types";
import { isError } from "@sentry/utils/esm";
import { isPlainObject, isString } from "lodash-es";

const isMessageObject = (error: Record<string, unknown>): error is { message: string; [key: string]: unknown } =>
  "message" in error && isString(error.message);

const isPlainObj = (obj: unknown): obj is Record<string, unknown> => isPlainObject(obj);

export const captureException = (error: unknown, captureContext?: CaptureContext): string | void => {
  if (!error) {
    return;
  }

  /** use sentry's own utility method to check if error can be thrown  */
  if (isError(error)) {
    console.error(error);
    return sentryCaptureException(error, captureContext);
  }

  if (isString(error)) {
    console.error(error);
    return sentryCaptureException(new Error(error), captureContext);
  }

  /** lodash's isError checks for error-like objects, which fails sentry's isError method */
  if (isPlainObj(error) && isMessageObject(error)) {
    console.error(error.message);
    return sentryCaptureException(new Error(error.message), captureContext);
  }
};
