import { isNil } from "lodash-es";
import React from "react";
import moneyUtil from "../../helpers/moneyUtils";
import { formatAttributes } from "../../helpers/postListingUtil";
import { PostsAPI } from "../../typings/API-V2";
import { Box, Text } from "../../UI";
import ProductMeta from "../../UI/components/ProductList/ProductMeta";

export interface ProductOrderLineItemProps {
  name: string;
  product: { slug?: string; style_id?: string };
  attributes?: PostsAPI.ProductListingAttribute[];
  amountCents?: number;
  currency?: string;
  imageSrc?: string;
}

const ProductOrderLineItem: React.FC<ProductOrderLineItemProps> = ({
  name,
  amountCents,
  attributes,
  imageSrc,
  product,
  currency = "usd",
}) => {
  const attributeDisplayText = attributes ? formatAttributes(attributes, product.style_id) : undefined;
  return (
    <Box>
      <ProductMeta
        disableLink={true}
        product={{
          style_id: product.style_id || "",
          slug: product.slug || "",
          image: imageSrc || "",
          name: name,
        }}
        subtext={attributeDisplayText}
      />
      {!isNil(amountCents) && (
        <Box display="flex" justifyContent="flex-end">
          <Text textAlign="right">{moneyUtil.centsToDollarString(amountCents, currency || undefined)}</Text>
        </Box>
      )}
    </Box>
  );
};

export default ProductOrderLineItem;
