import {
  AdditionalUserInfo,
  AuthError,
  AuthProvider,
  FacebookAuthProvider,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
  signInWithPopup,
  UserCredential,
} from "firebase/auth";
import React, { useCallback, useContext, useEffect } from "react";
import { useMutation } from "redux-query-react";
import styled from "styled-components";
import FirebaseErrors from "../../enums/FirebaseErrors";
import LocalStorageKey from "../../enums/LocalStorageKey";
import { InboundMessageType, LegacyOutboundMessageType } from "../../enums/MessageType";
import * as localStorageUtil from "../../helpers/localStorageUtil";
import AppleLogo from "../../images/apple-logo.svg";
import EmailLogo from "../../images/email-logo.svg";
import GoogleLogo from "../../images/google-logo.svg";
import Phone from "../../images/phone-auth.svg";
import { legacySendReactNativeMessage } from "../../messages/sendReactNativeMessage";
import { createCurrentUserProfile } from "../../queries/api/userProfileQuery";
import { UsersAPI } from "../../typings/API";
import { Box } from "../../UI";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import SecondaryLoginButton from "./SecondaryLoginButton";

export interface SecondaryLoginProps {
  showEmailOption?: boolean;
  onSubmitSuccess?: (additionalUserInfo?: AdditionalUserInfo | null) => void;
  onError?: (error: string | undefined) => void;
}

const googleAuthProvider = new GoogleAuthProvider();
const appleAuthProvider = new OAuthProvider("apple.com");
// const facebookAuthProvider = new FacebookAuthProvider();

export type AuthProviders = "google" | "facebook" | "apple.com";

/**
 * this type should be kept in sync in plugd-mobile/src/messages/outboundMessages.ts
 */
type LoginMessagePayload =
  | {
      provider: "google";
      credential: {
        idToken: string | null;
        accessToken: string | null;
      };
    }
  | {
      provider: "apple.com";
      credential: {
        idToken: string;
        rawNonce: string;
      };
    }
  | {
      provider: "facebook";
      credential: string;
    };

const SecondaryLogin: React.FC<SecondaryLoginProps> = ({ onSubmitSuccess, onError, showEmailOption }) => {
  const auth = getAuth();
  const { setAuthScreen } = useContext(AuthScreensContext);
  const refUserId = localStorageUtil.getStringItem(LocalStorageKey.REFERRAL_USER_ID);
  const [, initProfile] = useMutation((userFields: UsersAPI.UserProfileInsert) =>
    createCurrentUserProfile(userFields, refUserId),
  );
  const handleNativeMessage = useCallback(
    async (e: MessageEvent) => {
      let event;
      try {
        event = JSON.parse(e.data);
      } catch (e) {
        return;
      }
      if (event && event.type === InboundMessageType.LOGIN) {
        let authResult: UserCredential | undefined = undefined;
        try {
          const payload = event.payload as LoginMessagePayload;
          // Facebook currently disabled until we get privacy issues sorted out https://app.shortcut.com/plugd/story/6043/facebook-login-in-development-mode
          if (payload.provider === "facebook") {
            const credentials = FacebookAuthProvider.credential(payload.credential);
            authResult = await signInWithCredential(auth, credentials);
          } else if (payload.provider === "google") {
            const credentials = GoogleAuthProvider.credential(null, payload.credential.accessToken);
            authResult = await signInWithCredential(auth, credentials);
          } else if (payload.provider === "apple.com") {
            const provider = new OAuthProvider("apple.com");
            const credentials = provider.credential({
              idToken: payload.credential.idToken,
              rawNonce: payload.credential.rawNonce,
            });
            authResult = await signInWithCredential(auth, credentials);
          }
          if (authResult && authResult.user) {
            const additionalUserInfo = getAdditionalUserInfo(authResult);
            if (additionalUserInfo?.isNewUser) {
              const profileResult = await initProfile({
                id: authResult.user.uid,
                full_name: authResult.user.displayName || undefined,
                email: authResult.user.email || undefined,
                username: authResult.user.uid,
                profile_image: authResult.user.photoURL || undefined,
                phone_number: authResult.user.phoneNumber || undefined,
              });
              if (profileResult?.status === 200) {
                if (onSubmitSuccess) {
                  onSubmitSuccess(additionalUserInfo);
                }
              }
            } else {
              console.log(additionalUserInfo, onSubmitSuccess);
              if (onSubmitSuccess) {
                onSubmitSuccess(additionalUserInfo);
              }
            }
          }
        } catch (error) {
          const { message } = error as AuthError;
          if (onError) {
            onError(`${message}`);
          }
        }
      }
    },
    [initProfile, onSubmitSuccess, onError, auth],
  );

  useEffect(() => {
    // This is for android WebView
    (document.addEventListener as typeof window.addEventListener)("message", handleNativeMessage);
    return () => (document.removeEventListener as typeof window.removeEventListener)("message", handleNativeMessage);
  }, []);

  useEffect(() => {
    // This is for iOS WebView
    window.addEventListener("message", handleNativeMessage);
    return () => window.removeEventListener("message", handleNativeMessage);
  }, []);

  const handlePhoneAuth = useCallback(() => {
    setAuthScreen("phoneInput");
  }, [setAuthScreen]);

  const handleEmailAuth = useCallback(() => {
    setAuthScreen("emailLogin");
  }, [setAuthScreen]);

  const handleAuth = useCallback(
    async <P extends AuthProvider>(provider: P) => {
      if (window.ReactNativeWebView) {
        switch (provider.providerId) {
          case GoogleAuthProvider.PROVIDER_ID:
            legacySendReactNativeMessage(LegacyOutboundMessageType.LOGIN_GOOGLE);
            break;
          case FacebookAuthProvider.PROVIDER_ID:
            legacySendReactNativeMessage(LegacyOutboundMessageType.LOGIN_FACEBOOK);
            break;
          case "apple.com":
            legacySendReactNativeMessage(LegacyOutboundMessageType.LOGIN_APPLE);
            break;
          default:
            break;
        }
      } else {
        try {
          const authResult = await signInWithPopup(auth, provider);
          if (authResult && authResult.user) {
            const additionalUserInfo = getAdditionalUserInfo(authResult);
            if (additionalUserInfo?.isNewUser) {
              const profileResult = await initProfile({
                id: authResult.user.uid,
                full_name: authResult.user.displayName || undefined,
                email: authResult.user.email || undefined,
                username: authResult.user.uid,
                profile_image: authResult.user.photoURL || undefined,
              });
              if (profileResult?.status === 200) {
                if (onSubmitSuccess) {
                  onSubmitSuccess(additionalUserInfo);
                }
              }
            } else {
              if (onSubmitSuccess) {
                onSubmitSuccess(additionalUserInfo);
              }
            }
          }
        } catch (error) {
          console.log(error);
          const { code } = error as AuthError;
          if (onError) {
            onError(FirebaseErrors[code]);
          }
        }
      }
    },
    [auth, initProfile, onSubmitSuccess, onError],
  );

  const hideAppleLogin = window.platform === "android";

  return (
    <SecondaryLoginContainer>
      <SecondaryLoginButton onClick={handlePhoneAuth} imgSrc={Phone}>
        Continue with Phone
      </SecondaryLoginButton>
      {!hideAppleLogin && (
        <SecondaryLoginButton onClick={() => handleAuth(appleAuthProvider)} imgSrc={AppleLogo}>
          Continue with Apple
        </SecondaryLoginButton>
      )}
      <SecondaryLoginButton onClick={() => handleAuth(googleAuthProvider)} imgSrc={GoogleLogo}>
        Continue with Google
      </SecondaryLoginButton>
      {showEmailOption && (
        <SecondaryLoginButton onClick={handleEmailAuth} imgSrc={EmailLogo}>
          Continue with Email
        </SecondaryLoginButton>
      )}
      {/* <SecondaryLoginButton onClick={() => handleAuth(facebookAuthProvider)} imgSrc={FacebookLogo}>
        Continue with Facebook
      </SecondaryLoginButton> */}
    </SecondaryLoginContainer>
  );
};

const SecondaryLoginContainer = styled(Box)`
  width: 100%;
`;

export default SecondaryLogin;
