import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import AuthScreensContext from "../../components/AuthScreenModal/AuthScreensContext";
import BottomNav from "../../components/BottomNav";
import Page from "../../components/Layout/Page";
import SignUpOrLogInSegment from "../../components/SignUpOrLogInSegment";
import UserProfileTabPrimaryTopNav from "../../components/TopNav/UserProfileTabPrimaryTopNav";
import useCurrentUserProfile from "../../hooks/useCurrentUserProfile";
import { Loader } from "../../libs/semantic-ui";

export interface MePageProps {}

const MePage: React.FC<React.PropsWithChildren<MePageProps>> = () => {
  const { status } = useSigninCheck();
  const {
    profile: currentUserProfile,
    isFinished: currentUserProfileFinished,
    isPending: currentUserProfilePending,
  } = useCurrentUserProfile();
  const { setAuthScreen } = useContext(AuthScreensContext);

  const isLoading = status === "loading" || currentUserProfilePending;
  /** if all required requests have been resolved */
  const isFinished = status === "success" && currentUserProfileFinished;

  /** display the auth screen if all requests have finished and there is no current user */
  useEffect(() => {
    if (isFinished && !currentUserProfile) {
      setAuthScreen("login");
    }
  }, [isFinished, setAuthScreen, currentUserProfile]);

  /** redirect user to their profile page if their profile is returned */
  if (currentUserProfile) {
    return (
      <Redirect
        to={{
          pathname: `/u/${currentUserProfile.username}`,
          state: { fromBottomNav: true },
        }}
      />
    );
  }

  /** display while loading the auth status and current user profile */
  return (
    <>
      <UserProfileTabPrimaryTopNav />
      <Page pt={5} hideFooter={true} alignItems="stretch">
        {isLoading ? <Loader active={true}>Loading...</Loader> : <SignUpOrLogInSegment />}
      </Page>
      <BottomNav />
    </>
  );
};

export default MePage;
