import React from "react";
import styled from "styled-components";
import { actionColors, scoreFromPost, scoreText } from "../../helpers/postUtil";
import { UsePostVoteActions } from "../../hooks/usePostVoteActions";
import { PostsAPI } from "../../typings/API-V2";
import { Box, BoxProps, Icon, Text } from "../../UI";
import { ACTION_BUTTON_HEIGHT } from "./PostActionButton";

export interface PostActionVoteButtonProps extends Pick<ReturnType<UsePostVoteActions>, "upVote" | "downVote"> {
  post: PostsAPI.Post;
}

const PostActionVoteButton: React.FC<PostActionVoteButtonProps> = ({ post, upVote, downVote }) => {
  const voteColor = actionColors(post.current_vote);

  return (
    <VoteButtonWrapper>
      <VoteButton onClick={upVote} voteDirection="up">
        <Icon color={voteColor.upColor} name="arrow up" />
      </VoteButton>
      <Box px={2} color={voteColor.scoreColor}>
        <Text fontSize={0} fontWeight={5}>
          {scoreText(scoreFromPost(post))}
        </Text>
      </Box>
      <VoteButton onClick={downVote} voteDirection="down">
        <Icon color={voteColor.downColor} name="arrow down" />
      </VoteButton>
    </VoteButtonWrapper>
  );
};

const VoteButtonWrapper = styled(Box)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.darkGrey};

  display: flex;
  align-items: center;
`;
VoteButtonWrapper.defaultProps = {
  ml: 0,
  height: ACTION_BUTTON_HEIGHT,
  borderRadius: ACTION_BUTTON_HEIGHT / 2,
};

const VoteButton = styled(Box)<BoxProps & { voteDirection: "up" | "down" }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ voteDirection, theme }) => (voteDirection === "up" ? theme.colors.red : theme.colors.blue)};
  }
`;
VoteButton.defaultProps = {
  height: ACTION_BUTTON_HEIGHT,
  width: ACTION_BUTTON_HEIGHT,
  role: "button",
};

export default PostActionVoteButton;
