import { sortBy } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "reactfire";
import { useMutation } from "redux-query-react";
import analyticsUtil, { trackEvent } from "../../helpers/analyticsUtil";
import { SizeUnit } from "../../helpers/sizeConversionUtil";
import { usaStates } from "../../helpers/usaStates";
import useActions from "../../hooks/useActions";
import countryList from "../../libs/countryList";
import {
  Button,
  Divider,
  DropdownItemProps,
  Form,
  Header,
  Icon,
  Message,
  Modal,
  ModalProps,
} from "../../libs/semantic-ui";
import { updateUserPreferences } from "../../queries/api/userPreferencesQuery";
import userSelector from "../../selectors/userSelector";
import { UsersAPI } from "../../typings/API";
import CurrencySettings from "./CurrencySettings";
import SizeSettings from "./SizeSettings";

const stateOptions = sortBy(
  usaStates
    .filter((s) => !s.territory)
    .map((s) => ({
      key: s.abbreviation,
      value: s.abbreviation,
      text: s.name,
    })),
  "text",
);

export interface SettingsModalProps extends ModalProps {
  display?: "shipping" | "size" | "currency";
  closeModal: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ closeModal, display, ...modalProps }) => {
  const auth = useAuth();
  const { setSizePreferenceUnit, setCurrencyPreference, setShippingLocation, setDisableShippingCosts } = useActions();
  const [countryListLib, setCountryListLib] = useState<typeof import("country-list") | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [, updatePreferences] = useMutation((userId: string, preferences: Partial<UsersAPI.UserPreferences>) => {
    return updateUserPreferences(userId, preferences);
  });

  // currency state
  const currencyPreference = useSelector(userSelector.currencyPreference());
  const [tempCurrency, setTempCurrency] = useState<string | undefined>(currencyPreference);

  // size unit state
  const sizeUnit = useSelector(userSelector.sizePreferenceUnit());
  const [tempSizeUnit, setTempSizeUnit] = useState<SizeUnit>(sizeUnit);

  // shipping location state
  const shippingLocation = useSelector(userSelector.shippingLocation());
  const disableShippingCosts = useSelector(userSelector.disableShippingCosts());
  const [tempDisableShippingCosts, setTempDisableShippingCosts] = useState<boolean>(disableShippingCosts);
  const [tempCountryCode, setTempCountryCode] = useState<string | undefined>(shippingLocation?.country);
  const [tempRegionCode, setTempRegionCode] = useState<string | undefined>(shippingLocation?.region_code);

  useEffect(() => {
    countryList().then((lib) => {
      if (lib) {
        setCountryListLib(lib);
      }
    });
  }, []);

  const countryOptions = useMemo<DropdownItemProps[]>(() => {
    if (countryListLib) {
      const countryData = countryListLib.getData();
      return sortBy(
        countryData.map((cd) => ({
          key: cd.code,
          value: cd.code,
          text: cd.name,
        })),
        "text",
      );
    }
    return [];
  }, [countryListLib]);

  const resetFields = useCallback(() => {
    setTempDisableShippingCosts(disableShippingCosts);
    setTempRegionCode(shippingLocation?.region_code);
    setTempCountryCode(shippingLocation?.country);
    setErrorMessage("");
  }, [disableShippingCosts, shippingLocation]);

  useEffect(() => {
    if (modalProps.open) {
      trackEvent(analyticsUtil.UserPreference.Viewed);
    }
  }, [modalProps.open]);

  useEffect(() => {
    setTempCountryCode(shippingLocation?.country);
  }, [shippingLocation]);

  useEffect(() => {
    setTempRegionCode(shippingLocation?.region_code);
  }, [shippingLocation]);

  useEffect(() => {
    setTempCurrency(tempCurrency);
  }, [tempCurrency]);

  const handleCountryChange = useCallback((e, selectedValueObj) => {
    trackEvent(analyticsUtil.UserPreference.Updated, {
      preference_name: "Country",
      preference_value: selectedValueObj.value,
    });
    const countryCode = selectedValueObj.value;
    setTempCountryCode(countryCode);
    setErrorMessage(""); // reset any errors
    setTempRegionCode(undefined); // clear region code if a different country is selected
  }, []);

  const handleStateChange = useCallback((e, selectedValueObj): void => {
    trackEvent(analyticsUtil.UserPreference.Updated, {
      preference_name: "State",
      preference_value: selectedValueObj.value,
    });
    const regionCode = selectedValueObj.value;
    setErrorMessage(""); // reset any errors
    setTempRegionCode(regionCode);
  }, []);

  const saveSizeUnit = useCallback((): void => {
    if (auth.currentUser?.uid && tempSizeUnit !== sizeUnit) {
      updatePreferences(auth.currentUser.uid, {
        sizePreferenceUnit: tempSizeUnit,
      });
    } else {
      setSizePreferenceUnit(tempSizeUnit);
    }
    trackEvent(analyticsUtil.UserPreference.Updated, {
      preference_name: "Size Unit",
      preference_value: tempSizeUnit,
    });
  }, [setSizePreferenceUnit, auth.currentUser?.uid, tempSizeUnit, updatePreferences, sizeUnit]);

  const saveCurrency = useCallback((): void => {
    if (tempCurrency) {
      if (auth.currentUser?.uid && tempCurrency !== currencyPreference) {
        updatePreferences(auth.currentUser.uid, {
          currencyPreference: tempCurrency,
        });
      } else {
        setCurrencyPreference(tempCurrency);
      }
      trackEvent(analyticsUtil.UserPreference.Updated, {
        preference_name: "Currency",
        preference_value: tempCurrency,
      });
    }
  }, [tempCurrency, auth.currentUser?.uid, currencyPreference, updatePreferences, setCurrencyPreference]);

  const saveShippingLocation = useCallback(() => {
    if (!tempCountryCode) {
      return setErrorMessage("You must select a shipping country");
    } else if (tempCountryCode === "US" && !tempRegionCode) {
      return setErrorMessage("You must select a shipping state");
    } else if (tempRegionCode) {
      if (auth.currentUser?.uid) {
        updatePreferences(auth.currentUser.uid, {
          disableShippingCosts: tempDisableShippingCosts,
          shippingLocation: {
            country: tempCountryCode,
            region_code: tempRegionCode,
          },
        });
      } else {
        setDisableShippingCosts(tempDisableShippingCosts);
        setShippingLocation({
          country: tempCountryCode,
          region_code: tempRegionCode,
        });
      }
    } else if (tempCountryCode) {
      if (auth.currentUser?.uid) {
        updatePreferences(auth.currentUser.uid, {
          disableShippingCosts: tempDisableShippingCosts,
          shippingLocation: {
            country: tempCountryCode,
          },
        });
      } else {
        setDisableShippingCosts(tempDisableShippingCosts);
        setShippingLocation({
          country: tempCountryCode,
        });
      }
    }
  }, [
    auth.currentUser?.uid,
    setDisableShippingCosts,
    setShippingLocation,
    tempCountryCode,
    tempDisableShippingCosts,
    tempRegionCode,
    updatePreferences,
  ]);

  const handleSave = useCallback(() => {
    if (display === "currency") {
      saveCurrency();
    } else if (display === "shipping") {
      saveShippingLocation();
    } else if (display === "size") {
      saveSizeUnit();
    }
    closeModal();
  }, [display, saveCurrency, saveShippingLocation, saveSizeUnit, closeModal]);

  const handleCancel = useCallback(
    (e: React.SyntheticEvent) => {
      resetFields();
      closeModal();
      e.stopPropagation();
      e.preventDefault();
    },
    [resetFields, closeModal],
  );

  const isUS = tempCountryCode === "US";

  const handleDisableCheck = useCallback((): void => {
    setDisableShippingCosts(!disableShippingCosts);
  }, [disableShippingCosts, setDisableShippingCosts]);

  const unsupportedCountry = Boolean(tempCountryCode && !isUS);

  return (
    <Modal size="tiny" onClose={handleCancel} eventPool="SettingsModal" {...modalProps}>
      <Modal.Header as="h1">{`Settings`}</Modal.Header>
      <SizeSettings sizeUnit={tempSizeUnit} onChange={setTempSizeUnit} display={display} />
      {!display && <Divider />}
      <CurrencySettings currency={tempCurrency} onChange={setTempCurrency} display={display} />
      {!display && <Divider />}
      {(!display || display === "shipping") && (
        <Modal.Content>
          <Header as="h3">Location</Header>
          <Form warning={Boolean(unsupportedCountry || disableShippingCosts)} error={Boolean(errorMessage)}>
            <Form.Dropdown
              required
              label={`Country`}
              value={tempCountryCode}
              placeholder="Select Country"
              search
              selection
              options={countryOptions}
              fluid
              onChange={handleCountryChange}
            />
            {isUS && (
              <>
                <Form.Dropdown
                  required
                  label={`State`}
                  value={tempRegionCode}
                  placeholder="Select State"
                  search
                  selection
                  options={stateOptions}
                  fluid
                  onChange={handleStateChange}
                />
                {errorMessage && <Message error content={errorMessage} />}
                <Form.Checkbox
                  onChange={handleDisableCheck}
                  checked={disableShippingCosts}
                  label={`Disable shipping costs`}
                />
                {disableShippingCosts && (
                  <Message
                    warning
                    header={`Shipping excluded`}
                    content={`Shipping costs will be excluded from price estimates.`}
                  />
                )}
              </>
            )}
          </Form>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button icon onClick={handleCancel} labelPosition="right">
          Cancel
          <Icon name="close" />
        </Button>
        <Button icon primary onClick={handleSave} labelPosition="right">
          Save
          <Icon name="arrow right" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SettingsModal;
