import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import GlobalModalContext from "../components/GlobalModal/GlobalModalContext";
import sendReactNativeMessage from "../messages/sendReactNativeMessage";
import { RootState } from "../store";

/**
 * Hook that posts messages to the ReactNativeWebVew. This also checks to make sure that the given OutboundMessageType is valid before sending, otherwise it displays a modal requesting the user to upgrade the app.
 * @returns
 */
const useSendReactNativeMessage = () => {
  const { open: openModal } = useContext(GlobalModalContext);
  const validMessageTypes = useSelector((state: RootState) => state.nativeApp.validMessageTypes);

  return useCallback<typeof sendReactNativeMessage>(
    (message) => {
      // if not a valid message type
      if (!validMessageTypes.includes(message.action)) {
        // display app update modal
        return openModal({ type: "app_update" });
      }

      sendReactNativeMessage(message);
    },
    [openModal, validMessageTypes],
  );
};

export default useSendReactNativeMessage;
