import { Brand, UsersAPI } from "../API";
import ProductListingsAPI from "../API/ProductListingsAPI";
import ProductsAPI from "../API/ProductsAPI";
import { SuccessResponse } from "./common";

export enum PostTypeKey {
  "Image" = "image",
  "Link" = "link",
  "Listing" = "listing",
  "Text" = "text",
  // "Video" = "video",
}

namespace PostsAPIV2 {
  export interface ProductValue
    extends Pick<
      ProductsAPI.Product,
      | "id"
      | "gender"
      | "image"
      | "name"
      | "release_date"
      | "retail_price_cents"
      | "size_range"
      | "slug"
      | "style_id"
      | "verified"
    > {
    brand: Brand;
  }

  export interface CommunityDetails {
    id: number;
    name: string;
    slug: string;
    avatar_url: string | undefined;
  }

  export type SortableColumns = "created_at" | "updated_at" | "up_count" | "down_count" | "hot_score";
  export type ScoringNames = "best";
  export type SortBy = SortableColumns | ScoringNames;
  export type AuthorFollowStatus = "followee" | "friend";

  /** TODO: This will be user-generated depending on the community. Change to generic type string */
  export type PostThemeKey = "discussion" | "question" | "outfit" | "onfeet" | "product" | "pickups";

  export type PostThemeContentType = "common" | PostTypeKey;
  export interface PostTheme {
    id: number;
    key: PostThemeKey;
    name: string;
    content_type: PostThemeContentType;
    description: string;
    position: number;
  }

  export interface PostType {
    id: number;
    key: PostTypeKey;
    name: string;
  }

  export type GetSignedUrlResponse = SuccessResponse<{
    expires: number;
    fileName: string;
    publicUrl: string;
    url: string;
  }>;

  export interface PostImage extends Pick<PostImageInsert, "url"> {
    aspect_ratio: number;
    id: number;
    position: number;
    post_id: number;
    tags: PostImageTag[] | null;
  }

  export interface ProductListingAttribute {
    id: number;
    attribute_value: string;
    attribute: Pick<ProductListingsAPI.ProductAttribute, "id" | "name" | "input_type" | "key">;
  }

  export interface ProductListing {
    id: number;
    post_id: number;
    attributes: ProductListingAttribute[];
    can_checkout: boolean;
    is_active: boolean;
    price_cents: number;
    product: Omit<ProductsAPI.Product, "brand"> & {
      brand: Brand;
    };
    quantity: number;
    created_at: string;
    updated_at: string | null;
  }

  export interface ProductListingInsert {
    /** Record<attribute key, string> */
    attributes: Record<string, string>;
    price_cents: number;
    product_id: number;
    quantity: number;
  }

  export interface PostImageTagInsert {
    x: number;
    y: number;
    product: {
      id: number;
      name: string; // only add this for UI. Not used on the back end
    };
  }

  /** TODO: add community id */
  // export interface PostInsert {
  //   title: string;
  //   content: string | null; // text post
  //   type: PostTypeKey | null;
  //   /**
  //    * The community id
  //    * TODO: This should be non-nullable and passed by the user in the future
  //    */
  //   community_id: number | null;
  //   /**
  //    * The community_theme id
  //    * TODO: This should be non-nullable and passed by the user in the future
  //    */
  //   theme_id: number | null;
  //   /** TODO: Replace with theme_id when theme_key is deprecated */
  //   theme_key: string | null;
  //   url: string | null; // link post
  //   to_route: string | null; // internal link post
  //   images:
  //     | (PostImageInsert & {
  //         tags?: Omit<PostImageTagInsert, "post_image_id">[];
  //       })[]
  //     | null;
  // }

  export interface PostAuthor
    extends Pick<UsersAPI.User, "full_name" | "id" | "profile_image" | "username" | "is_deleted"> {}

  export interface PostImageInsert {
    height: number;
    width: number;
    url: string;
    tags: PostImageTagInsert[] | null;
  }

  /** @deprecated */
  export interface AttributesOld {
    box_condition?: string | "Damaged" | "Good" | "Missing" | "No lid" | "Replacement" | "Alternate label";
    product_condition?: string | "New" | "Used" | "New with defects";
    shoe_size_type?: string | "men" | "women" | "infant" | "youth";
    shoe_size?: number; // increments of 0.5
    [key: string]: unknown;
  }

  export interface Attributes {
    box_condition?: string | "Damaged" | "Good" | "Missing" | "No lid" | "Replacement" | "Alternate label";
    product_condition?: string | "New" | "Used" | "New with defects";
    size_type?: string | "men" | "women" | "infant" | "youth";
    shoe_size?: number; // increments of 0.5
    [key: string]: unknown;
  }

  export interface PostInsert {
    community_id?: number;
    content: string | null;
    images: PostImageInsert[] | null;
    product_listing: ProductListingInsert | null;
    /** @deprecated */
    theme_key: PostThemeKey | null;
    theme_id: number | null;
    title: string;
    to_route: string | null;
    type: PostTypeKey | null;
    url: string | null;
  }

  export type PostPatchImageTag = PostImageTag | PostImageTagInsert;

  export interface PostPatchImage {
    id: number;
    url: string;
    tags?: PostPatchImageTag[] | null;
  }

  export interface PostPatch
    extends Partial<
      Pick<
        PostInsert,
        "content" | "theme_key" | "theme_id" | "url" | "type" | "to_route" | "community_id" | "product_listing"
      >
    > {
    id: number;
    title?: string; // title is not nullable since it is a required
    is_archived: boolean;
    images?: PostPatchImage[]; // images are not nullable since they can't be modified after a post with images is created
  }

  export interface PostAuthor extends Pick<UsersAPI.User, "full_name" | "id" | "profile_image" | "username"> {}

  /** normalized post object in the store */
  export interface BasePost extends Omit<PostInsert, "images" | "product_listing" | "community_id" | "theme_id"> {
    type: PostTypeKey;
    // meta
    comment_count: number;
    id: number;
    // visibility
    is_archived: boolean;
    is_hidden: boolean;
    is_deleted: boolean;
    is_pinned: boolean;
    // scores
    hot_score: number;
    // votes
    up_count: number;
    down_count: number;
    // author
    author: PostAuthor;
    /** community */
    community: CommunityDetails;
    /** The current user's vote */
    current_vote: number | null;
    images: PostImage[] | null;
    theme: {
      id: number;
      key: string;
      name: string;
    } | null;
    url: string | null;
    to_route: string | null;
    updated_at: string | null;
    created_at: string;
  }

  /**
   * Post types how they are returned from the server
   */
  export type ResponseImagePost = ImagePost;
  export type ResponseTextPost = TextPost;
  export type ResponseLinkPost = LinkPost;
  export interface ResponseListingPost extends Omit<BasePost, "product_listing"> {
    product_listing: ProductListing;
  }

  /** this is the shape of posts before the post gets normalized in the store */
  export type ResponsePost = ResponseImagePost | ResponseTextPost | ResponseLinkPost | ResponseListingPost;

  export interface PostImageTagProduct {
    id: number;
    image: string;
    name: string;
    slug: string;
    style_id: string;
  }

  export interface PostImageTag {
    id: number;
    post_image_id: number;
    product: PostImageTagProduct;
    x: number;
    y: number;
    // created_at: number;
  }

  /** Specific post types */
  export interface ImagePost extends BasePost {
    type: PostTypeKey.Image;
    images: PostImage[];
    listing: null;
    product_listing: null;
  }

  export interface ListingPost extends BasePost {
    type: PostTypeKey.Listing;
    images: PostImage[];
    product_listing: Omit<ProductListing, "product"> & {
      product_id: number;
    };
  }

  interface UrlLinkPost extends BasePost {
    type: PostTypeKey.Link;
    to_route: null;
    url: string;
    product_listing: null;
  }

  interface InternalLinkPost extends BasePost {
    type: PostTypeKey.Link;
    to_route: string;
    url: null;
    product_listing: null;
  }

  export type LinkPost = UrlLinkPost | InternalLinkPost;

  export interface TextPost extends BasePost {
    type: PostTypeKey.Text;
    images: null;
    listing: null;
    product_listing: null;
  }

  export type Post = ImagePost | ListingPost | LinkPost | TextPost | BasePost;

  export interface PostVote {
    post_id: number;
    vote: number;
  }

  export type GetPostResponse = SuccessResponse<{
    post: ResponsePost;
  }>;

  export type GetPostsResponse = SuccessResponse<{
    posts: ResponsePost[];
  }>;

  export type CreatePostResponse = SuccessResponse<{
    post: ResponsePost;
  }>;

  export type PatchPostResponse = SuccessResponse<{
    post: ResponsePost;
  }>;

  export type GetTaggedProductPostsResponse = SuccessResponse<{
    posts: ResponsePost[];
  }>;

  export type ArchivePostResponse = SuccessResponse<{
    post: ResponsePost;
  }>;

  export type UnarchivePostResponse = SuccessResponse<{
    post: ResponsePost;
  }>;

  export type PinPostResponse = SuccessResponse<{
    post: ResponsePost;
  }>;

  export type UnpinPostResponse = SuccessResponse<{
    post: ResponsePost;
  }>;
}

export default PostsAPIV2;
