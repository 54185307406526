import { isString } from "lodash-es";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Box, Image, Link, LinkProps, Text } from "../../UI";

export interface SearchItemProps extends LinkProps {
  title: string;
  image: ReactNode | string;
  tags: string[];
  to: string;
}

const SearchItem: React.FC<SearchItemProps> = ({ title, image, tags, ...linkProps }) => {
  return (
    <SelectItem {...linkProps}>
      <ImageWrapper width={50}>
        {isString(image) ? (
          <Image alt={`${title}`} width="100%" height="auto" data-src={image} className="lazyload" />
        ) : (
          image
        )}
      </ImageWrapper>
      <Box display="flex" flexDirection="column" ml={2}>
        {title && (
          <Text fontSize={0} fontWeight={5}>
            {title}
          </Text>
        )}
        <Box display="flex" flexDirection="row">
          {tags.map((tag, idx) => (
            <TagText key={idx}>{tag}</TagText>
          ))}
        </Box>
      </Box>
    </SelectItem>
  );
};

export const SelectItem = styled(Link)`
  cursor: pointer;
`;
SelectItem.defaultProps = {
  px: 3,
  py: 2,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  borderRadius: 3,
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  bg: "white",
};

const ImageWrapper = styled(Box)`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

const TagText = styled(Text)`
  margin-left: ${({ theme }) => theme.space[2]}px;
  &:first-child {
    margin-left: 0px;
  }
`;
TagText.defaultProps = {
  fontSize: 0,
  color: "darkGrey",
};

export default SearchItem;
