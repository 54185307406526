import produce from "immer";
import { isNumber, map, toString, union } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { generateListKey, shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { ProductsAPI, RecommendationsAPI } from "../../typings/API";
import { ProductsState } from "../../typings/EntitiesState";

export const getProductBySlug = (
  productSlug: string,
  sku?: string | null,
): QueryConfig<Pick<ProductsState, "productsById" | "productIdsBySlug">> => {
  const searchParams = new URLSearchParams({
    idType: "slug",
  });
  if (sku) {
    searchParams.set("sku", sku);
  }
  const qs = searchParams.toString();

  return {
    url: `${config.API_URL}/products/${productSlug}?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (product: ProductsAPI.Product) => ({
      productsById: { [product.id]: product },
      productIdsBySlug: { [product.slug]: product.id },
    }),
    update: {
      productsById: shallowObjectMerge,
      productIdsBySlug: shallowObjectMerge,
    },
  };
};

export interface GetProductsOptions {
  ids?: number[];
  limit?: number;
  offset?: number;
  release_date?: string;
  sort_by?: "release_date";
  order_by?: "asc" | "desc";
}

export const productsTransform = (
  products: ProductsAPI.Product[],
): Pick<ProductsState, "productsById" | "productIdsBySlug"> =>
  products.reduce<{
    productsById: Record<number, ProductsAPI.Product>;
    productIdsBySlug: Record<string, number>;
  }>(
    (result, product) => {
      result.productsById[product.id] = product;
      result.productIdsBySlug[product.slug] = product.id;
      return result;
    },
    { productsById: {}, productIdsBySlug: {} },
  );

export const getProducts = (
  options: GetProductsOptions,
  force = false,
): QueryConfig<Pick<ProductsState, "productsById" | "productIdsBySlug" | "productListsByKey">> => {
  const listKey = generateListKey<GetProductsOptions>(options);
  const searchParams = new URLSearchParams();
  if (options.ids) {
    searchParams.set("ids", options.ids.join(","));
  }
  if (isNumber(options.limit)) {
    searchParams.set("limit", toString(options.limit));
  }
  if (isNumber(options.offset)) {
    searchParams.set("offset", toString(options.offset));
  }
  if (options.release_date) {
    searchParams.set("release_date", options.release_date);
  }
  if (options.sort_by) {
    searchParams.set("sort_by", options.sort_by);
  }
  if (options.order_by) {
    searchParams.set("order_by", options.order_by);
  }
  const qs = searchParams.toString();

  return {
    url: `${config.API_URL}/products?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    force,
    transform: (response: ProductsAPI.ListProductsResponse) => ({
      ...productsTransform(response.products),
      productListsByKey: {
        [listKey]: response.products.map((p) => p.id),
      },
    }),
    update: {
      productsById: shallowObjectMerge,
      productIdsBySlug: shallowObjectMerge,
      productListsByKey: (oldValue, newValue) => {
        return produce(oldValue, (draft) => {
          const origListKeyList = draft[listKey] || [];
          const newListKeyList = newValue[listKey];
          draft[listKey] = union(origListKeyList, newListKeyList);
        });
      },
    },
  };
};

export const getProductStats = (productId: number): QueryConfig<Pick<ProductsState, "productStatsById">> => {
  return {
    url: `${config.API_URL}/products/${productId}/counts`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: ProductsAPI.ProductStatistics) => ({
      productStatsById: { [productId]: response },
    }),
    update: {
      productStatsById: shallowObjectMerge,
    },
  };
};

export const updateProduct = (updateObj: ProductsAPI.Product): QueryConfig<Pick<ProductsState, "productsById">> => {
  return {
    url: `${config.API_URL}/products/${updateObj.id}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "PUT",
      headers: STANDARD_HEADERS,
    },
    body: { ...updateObj },
    transform: (): Pick<ProductsState, "productsById"> => ({
      productsById: { [updateObj.id]: updateObj },
    }),
    update: {
      productsById: shallowObjectMerge,
    },
    optimisticUpdate: {
      productsById: (oldValue) => ({
        ...oldValue,
        [updateObj.id]: updateObj,
      }),
    },
    rollback: {
      productsById: (initialValue) => ({ ...initialValue }),
    },
  };
};

export const getFavoriteRecommendations = (): QueryConfig<
  Pick<ProductsState, "productFavoriteSuggestionIds" | "productsById" | "productIdsBySlug">
> => {
  return {
    url: `${config.API_URL}/recommendations/product-favorites`,
    meta: {
      includeToken: true,
    },
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: RecommendationsAPI.GetProductFavoriteRecommendations) => {
      const { recommendations } = response.data;
      return {
        ...productsTransform(recommendations),
        productFavoriteSuggestionIds: map(recommendations, (r) => r.id),
      };
    },
    update: {
      productsById: shallowObjectMerge,
      productIdsBySlug: shallowObjectMerge,
      productFavoriteSuggestionIds: (oldValue, newValue) => oldValue.concat(newValue),
    },
  };
};
