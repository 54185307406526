import { AnyAction, Middleware } from "redux";
import { getIdToken } from "../helpers/requestUtil";

// automatically add auth token to mutation requests
const authTokenMiddleware: Middleware = (/* store */) => (next) => (action: AnyAction) => {
  if (
    (action?.type === "@@query/MUTATE_ASYNC" || action?.type === "@@query/REQUEST_ASYNC") &&
    action?.meta?.includeToken // if includeToken is true, add token to the request
  ) {
    return getIdToken().then((token) => {
      if (!token) {
        return next(action);
      }
      const options = action.options || {};
      const headers = options.headers || {};
      const updatedAction = {
        ...action,
        options: {
          ...options,
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        },
      };
      return next(updatedAction);
    });
  } else {
    return next(action);
  }
};

export default authTokenMiddleware;
