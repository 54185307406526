import styled from "styled-components";
import Box, { BoxProps } from "./Box";

export interface PlaceholderBoxProps extends BoxProps {}

const PlaceholderBox = styled(Box)<PlaceholderBoxProps>`
  animation: placeholderShimmer 2s linear;
  animation-iteration-count: infinite;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.08) 30%);
  background-size: 1200px 100%;
`;

export default PlaceholderBox;
