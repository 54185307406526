import MQ from "mediaquery";

export const mediaQueries = {
  small: 320,
  medium: 835,
  big: 1200,
  huge: Infinity,
};

export default MQ.asObject(mediaQueries);
