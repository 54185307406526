import produce from "immer";
import { union, without } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { FavoriteProductsState, ProductsState } from "../../typings/EntitiesState";

export const getFavoritedProducts = (userId: string): QueryConfig<FavoriteProductsState> => {
  return {
    url: `${config.API_URL}/users/${userId}/favorites/products`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: { products: number[] }) => {
      const productIds = response.products;
      return {
        favoriteProductIdsByUserId: { [userId]: productIds },
      };
    },
    update: {
      favoriteProductIdsByUserId: shallowObjectMerge,
    },
  };
};

export const removeProductFromFavorites = (
  userId: string,
  productId: number,
): QueryConfig<FavoriteProductsState & ProductsState> => {
  return {
    url: `${config.API_URL}/users/${userId}/favorites/products/${productId}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "DELETE",
      headers: STANDARD_HEADERS,
    },
    optimisticUpdate: {
      favoriteProductIdsByUserId: produce((draft) => {
        const prevFavorites = draft[userId] || [];
        draft[userId] = without(prevFavorites, productId);
      }),
      productStatsById: (prevValue) => {
        const staleProductStats = prevValue[productId];
        const updatedProductStats = {
          ...staleProductStats,
          favorited: staleProductStats?.favorited ? staleProductStats.favorited - 1 : 0,
        };
        return { ...prevValue, [productId]: updatedProductStats };
      },
    },
    rollback: {
      favoriteProductIdsByUserId: (initialValue) => initialValue,
      productStatsById: (initialValue) => initialValue,
    },
  };
};

export const addProductsToFavorites = (
  userId: string,
  productIds: number[],
): QueryConfig<FavoriteProductsState & ProductsState> => {
  return {
    url: `${config.API_URL}/users/${userId}/favorites/products`,
    meta: {
      includeToken: true,
    },
    body: {
      product_ids: productIds,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    optimisticUpdate: {
      favoriteProductIdsByUserId: produce((draft) => {
        const prevFavorites = draft[userId] || [];
        draft[userId] = union(prevFavorites, productIds);
      }),
      productStatsById: (prevValue) => {
        const diff = {};
        productIds.forEach((pid) => {
          const staleProductStats = prevValue[pid];
          const updatedProductStats = {
            ...staleProductStats,
            favorited: staleProductStats?.favorited ? staleProductStats.favorited + 1 : 1,
          };
          diff[pid] = updatedProductStats;
        });

        return { ...prevValue, ...diff };
      },
    },
    rollback: {
      favoriteProductIdsByUserId: (initialValue) => initialValue,
      productStatsById: (initialValue) => initialValue,
    },
  };
};
