import React from "react";
import { UsePostVoteActions } from "../../hooks/usePostVoteActions";
import { PostsAPI } from "../../typings/API-V2";
import { Box, BoxProps, Icon, Spacer, Text } from "../../UI";
import PostActionButton from "./PostActionButton";
import PostActionShareButton from "./PostActionShareButton";
import PostActionVoteButton from "./PostActionVoteButton";

export interface PostActionsProps extends Pick<ReturnType<UsePostVoteActions>, "upVote" | "downVote">, BoxProps {
  post: PostsAPI.Post;
  onCommentsClick: () => void;
}

const PostActions: React.FC<PostActionsProps> = ({ post, onCommentsClick, upVote, downVote, ...boxProps }) => {
  return (
    <Box width="100%" display="flex" alignItems="center" px={4} py={2} {...boxProps}>
      <PostActionVoteButton upVote={upVote} downVote={downVote} post={post} />
      <PostActionButton px={2} onClick={onCommentsClick}>
        <Icon name="comment outline" />
        <Text fontSize={0} fontWeight={5} ml={1}>
          {post.comment_count}
        </Text>
      </PostActionButton>
      <Spacer style={{ marginLeft: "auto" }} />
      <PostActionShareButton post={post} />
    </Box>
  );
};

export default PostActions;
