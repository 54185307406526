import React, { useCallback } from "react";
import useAutoFocusRefCallback from "../../../hooks/useAutoFocusRefCallback";
import { Box, Text } from "../../../UI";
import AuthScreenContentHeader from "../../AuthScreenModal/AuthScreenContentHeader";
import { AuthInput, InputWrapper } from "../AuthInput";

export interface EmailLoginContentProps {
  handlePasswordChange: (value: string) => void;
  handlePasswordConfirmChange: (value: string) => void;
  password: string;
  passwordConfirm: string;
}

const PasswordForm: React.FC<EmailLoginContentProps> = ({
  handlePasswordChange,
  handlePasswordConfirmChange,
  password,
  passwordConfirm,
}) => {
  const [passwordInputRefCallback] = useAutoFocusRefCallback();

  const onPasswordConfirmChange = useCallback(
    (event): void => {
      handlePasswordConfirmChange(event.target.value);
    },
    [handlePasswordConfirmChange],
  );

  const onPasswordChange = useCallback(
    (event): void => {
      handlePasswordChange(event.target.value);
    },
    [handlePasswordChange],
  );

  return (
    <>
      <AuthScreenContentHeader
        title={"Create your password"}
        description={<Text>{"Create a password with 8 to 20 characters"}</Text>}
      />
      <Box width={"100%"} my={2}>
        <InputWrapper>
          <AuthInput
            ref={passwordInputRefCallback}
            placeholder="password"
            type="password"
            value={password}
            onChange={onPasswordChange}
            autoComplete="new-password"
          />
        </InputWrapper>
        <InputWrapper>
          <AuthInput
            placeholder="confirm password"
            type="password"
            value={passwordConfirm}
            onChange={onPasswordConfirmChange}
          />
        </InputWrapper>
      </Box>
    </>
  );
};

export default PasswordForm;
