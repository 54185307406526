import React from "react";
import { SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import { Card, Icon, Spacer, Text } from "../../UI";
import OutboundLink from "../links/OutboundLink";

export interface PlugdGoldFeatureCardProps {
  description: string;
  href?: string;
  iconName: SemanticICONS;
  isComingSoon?: boolean;
}

const PlugdGoldFeatureCard: React.FC<PlugdGoldFeatureCardProps> = ({ iconName, isComingSoon, description, href }) => {
  const content = (
    <StyledCard bg="white" p={3} pt={isComingSoon ? 1 : 3}>
      {isComingSoon && <Text fontSize={0} color="darkGrey" mb={3}>{`COMING SOON`}</Text>}
      <Icon name={iconName} size="large" />
      <Spacer mt={3} />
      <Text fontWeight={4} textAlign="center" title={description}>
        {description}
      </Text>
    </StyledCard>
  );

  if (href) {
    return <OutboundLink href={href}>{content}</OutboundLink>;
  }

  return content;
};

const StyledCard = styled(Card)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
`;

export default PlugdGoldFeatureCard;
