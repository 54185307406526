import React, { useMemo } from "react";
import styled, { DefaultTheme } from "styled-components";
import { SpaceProps } from "styled-system";
import { Box, Icon, Spacer, Text } from "../../UI";
import LinkingWrapper, { LinkifyOptions } from "../links/LinkingWrapper";

export interface PostUrlProps extends SpaceProps<DefaultTheme> {
  url: string;
}

const PostUrl: React.FC<PostUrlProps> = ({ url, ...spaceProps }) => {
  const linkifyOptions = useMemo<LinkifyOptions>(
    () => ({
      format: {
        url(href) {
          try {
            const urlInstance = new URL(href);
            const formattedUrl = `${urlInstance.hostname}${urlInstance.pathname}`;
            return formattedUrl.substr(0, 21).concat("...");
          } catch (error) {
            return href.substr(0, 21).concat("...");
          }
        },
      },
    }),
    [],
  );

  return (
    <Box {...spaceProps}>
      <UrlText clickable={true} textDecorationColor="anchorBlue">
        <LinkingWrapper linkifyOptions={linkifyOptions} affiliateLinkType="post" text={url} />
      </UrlText>
      <Spacer ml={1} display="inline-block" />
      <Icon name="external alternate" color="anchorBlue" size="small" />
    </Box>
  );
};

const UrlText = styled(Text)`
  text-decoration: initial;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default PostUrl;
