import { toUpper } from "lodash-es";
import React from "react";
import styled from "styled-components";
import { Box, Text } from "../../../UI";

export interface CheckboxListHeaderProps {
  bulkProps?: {
    items: unknown[];
    toggle: () => void;
    selectedIds: Set<number> | Set<string>;
  };
  title: string;
}

const CheckboxListHeader: React.FC<CheckboxListHeaderProps> = ({ bulkProps, title }) => {
  return (
    <TitleWrapper px={3} mb={3}>
      <Text fontWeight={5}>{toUpper(title)}</Text>
      {bulkProps && (
        <Text
          color={bulkProps.selectedIds.size < bulkProps.items.length ? "black" : "mediumGrey"}
          textDecoration="underline"
          onClick={bulkProps.toggle}
        >
          {bulkProps.selectedIds.size < bulkProps.items.length ? "Select all" : "Deselect all"}
        </Text>
      )}
    </TitleWrapper>
  );
};

const TitleWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export default CheckboxListHeader;
