import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "redux-query-react";
import { captureException } from "../../../helpers/errorUtil";
import { actionPromiseToPromise } from "../../../helpers/reactQueryUtil";
import useConfirmationCallback from "../../../hooks/useConfirmationCallback";
import { deletePost as deletePostQuery } from "../../../queries/api/postQuery";
import { PostsAPI } from "../../../typings/API-V2";
import ActionsMenuItem, { ActionsMenuItemProps } from "../ActionsMenuItem";

export interface PostActionsDeleteMenuItemProps extends ActionsMenuItemProps {
  post: PostsAPI.Post;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PostActionsDeleteMenuItem: React.FC<PostActionsDeleteMenuItemProps> = ({ post, onClick, ...menuItemProps }) => {
  const [, deletePost] = useMutation(() => deletePostQuery(post.id));
  const { addToast } = useToasts();

  const handleDeletePost = useCallback(
    async (e) => {
      try {
        await actionPromiseToPromise(deletePost());
        addToast(`Post deleted`, {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (error) {
        addToast(`An error occurred`, {
          appearance: "warning",
          autoDismiss: true,
        });
        captureException(error);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [deletePost, addToast, onClick],
  );
  const deleteWithConfirmation = useConfirmationCallback("Delete this post?", handleDeletePost, []);
  return <ActionsMenuItem {...menuItemProps} onClick={deleteWithConfirmation} />;
};

export default PostActionsDeleteMenuItem;
