import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { FULLSCREEN_MENU_HEADER_HEIGHT } from ".";
import { Box, BoxProps, Text, TextProps } from "../../../UI";

export interface FullscreenMenuHeaderTitleProps extends TextProps {
  wrapperProps?: BoxProps;
}

const FullscreenMenuHeaderTitle: React.FC<PropsWithChildren<FullscreenMenuHeaderTitleProps>> = ({
  children,
  wrapperProps,
  ...textProps
}) => {
  return (
    <TitleWrapper {...wrapperProps}>
      <Text fontWeight={5} {...textProps}>
        {children}
      </Text>
    </TitleWrapper>
  );
};

const TitleWrapper = styled(Box)`
  white-space: nowrap;
`;
TitleWrapper.defaultProps = {
  height: FULLSCREEN_MENU_HEADER_HEIGHT,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexBasis: 0,
};

export default FullscreenMenuHeaderTitle;
