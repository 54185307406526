import produce from "immer";
import { concat, isNumber, keyBy, toString, unionBy, without, zipObject } from "lodash-es";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { shallowObjectMerge } from "../../helpers/queryUtil";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { UsersAPI } from "../../typings/API";
import CommunitiesAPI from "../../typings/API/CommunitiesAPI";
import { CommunityState } from "../../typings/EntitiesState";

export interface GetCommunityOptions {
  ids?: number[];
  limit?: number;
  offset?: number;
}

export const getCommunity = (
  communitySlug: string,
): QueryConfig<Pick<CommunityState, "communitiesBySlug" | "communitySlugById">> => {
  return {
    url: `${config.API_URL}/communities/${communitySlug}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    meta: {
      includeToken: true,
    },
    transform: (
      response: CommunitiesAPI.GetCommunityResponse,
    ): Pick<CommunityState, "communitiesBySlug" | "communitySlugById"> => {
      return {
        communitiesBySlug: { [communitySlug]: response.data.community },
        communitySlugById: { [response.data.community.id]: communitySlug },
      };
    },
    update: {
      communitiesBySlug: shallowObjectMerge,
      communitySlugById: shallowObjectMerge,
    },
  };
};

export const getCommunityList = (
  options: GetCommunityOptions,
): QueryConfig<Pick<CommunityState, "communitiesBySlug" | "communitySlugById">> => {
  const searchParams = new URLSearchParams();
  if (options.ids) {
    searchParams.set("ids", options.ids.join(","));
  }
  if (isNumber(options.limit)) {
    searchParams.set("limit", toString(options.limit));
  }
  if (isNumber(options.offset)) {
    searchParams.set("offset", toString(options.offset));
  }
  const qs = searchParams.toString();
  return {
    url: `${config.API_URL}/communities?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    meta: {
      includeToken: true,
    },
    transform: (
      response: CommunitiesAPI.GetCommunityListResponse,
    ): Pick<CommunityState, "communitiesBySlug" | "communitySlugById"> => {
      return {
        communitiesBySlug: keyBy(response.data.communities, "slug"),
        communitySlugById: zipObject(
          response.data.communities.map((community) => community.id),
          response.data.communities.map((community) => community.slug),
        ),
      };
    },
    update: {
      communitiesBySlug: shallowObjectMerge,
      communitySlugById: shallowObjectMerge,
    },
  };
};

export const getCommunityThemes = (
  communityId: number,
): QueryConfig<Pick<CommunityState, "communityThemesByCommunityId">> => {
  return {
    url: `${config.API_URL}/communities/${communityId}/themes`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (
      response: CommunitiesAPI.GetCommunityThemeResponse,
    ): Pick<CommunityState, "communityThemesByCommunityId"> => {
      return {
        communityThemesByCommunityId: { [communityId]: response.data.themes },
      };
    },
    update: {
      communityThemesByCommunityId: shallowObjectMerge,
    },
  };
};

export const createCommunity = (
  name: string,
  slug: string,
  description: string,
): QueryConfig<Pick<CommunityState, "communitiesBySlug" | "communitySlugById">> => {
  return {
    url: `${config.API_URL}/communities`,
    meta: {
      includeToken: true,
    },
    body: {
      slug,
      description,
      name,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    transform: (
      response: CommunitiesAPI.CreateCommunityResponse,
    ): Pick<CommunityState, "communitiesBySlug" | "communitySlugById"> => {
      return {
        communitiesBySlug: { [slug]: response.data.community },
        communitySlugById: { [response.data.community.id]: slug },
      };
    },
    update: {
      communitiesBySlug: shallowObjectMerge,
      communitySlugById: shallowObjectMerge,
    },
  };
};

export const updateCommunity = (
  communityId: number,
  slug: string,
  updateFields: Partial<Omit<CommunitiesAPI.CommunityInput, "slug">>,
): QueryConfig<Pick<CommunityState, "communitiesBySlug">> => {
  return {
    url: `${config.API_URL}/communities/${communityId}`,
    meta: {
      includeToken: true,
    },
    body: updateFields,
    options: {
      method: "PUT",
      headers: STANDARD_HEADERS,
    },
    optimisticUpdate: {
      communitiesBySlug: (oldValue) => ({
        ...oldValue,
        [slug]: { ...oldValue[slug], ...updateFields },
      }),
    },
  };
};

export const uploadAvatar = (
  imageDetails: CommunitiesAPI.CommunityAvatarRequestBody,
  community: CommunitiesAPI.Community,
): QueryConfig<Pick<CommunityState, "communitiesBySlug">> => ({
  url: `${config.API_URL}/communities/${community.id}/avatars`,
  meta: {
    includeToken: true,
  },
  body: imageDetails,
  options: {
    headers: STANDARD_HEADERS,
    method: "POST",
  },
  optimisticUpdate: {
    communitiesBySlug: (oldValue) => ({
      ...oldValue,
      [community.slug]: { ...oldValue[community.slug], avatar_url: imageDetails.url },
    }),
  },
});

export const getUserCommunitySubscriptions = (
  userId: string,
): QueryConfig<Omit<CommunityState, "communitiesBySlug">> => {
  return {
    url: `${config.API_URL}/users/${userId}/community-subscriptions`,
    meta: {
      includeToken: true,
    },
    transform: (
      response: CommunitiesAPI.GetCommunitySubscriptionListResponse,
    ): Pick<CommunityState, "subscribedCommunityIdsByUserId" | "userCommunitySubscriptionByCommunityId"> => {
      const subscriptions = response.data.community_subscriptions;
      return {
        subscribedCommunityIdsByUserId: { [userId]: subscriptions.map((subscription) => subscription.community_id) },
        userCommunitySubscriptionByCommunityId: keyBy(subscriptions, (subscription) => subscription.community_id),
      };
    },
    update: {
      subscribedCommunityIdsByUserId: shallowObjectMerge,
      userCommunitySubscriptionByCommunityId: (_, newValue) => newValue,
    },
  };
};

export const addCommunitySubscription = (
  userId: string,
  communityId: number,
): QueryConfig<Omit<CommunityState, "communitiesBySlug">> => {
  return {
    url: `${config.API_URL}/users/${userId}/community-subscriptions`,
    meta: {
      includeToken: true,
    },
    body: {
      community_id: communityId,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
    optimisticUpdate: {
      subscribedCommunityIdsByUserId: (prevValue) => {
        const staleSubscriptions = prevValue[userId] || [];
        return { [userId]: concat(staleSubscriptions, communityId) };
      },
    },
    rollback: {
      subscribedCommunityIdsByUserId: (initialValue) => initialValue,
    },
    transform: (response: CommunitiesAPI.CreateCommnitySubscriptionResponse) => {
      return {
        userCommunitySubscriptionByCommunityId: {
          [communityId]: {
            id: response.data.id,
            community_id: communityId,
            created_at: Date.now.toString(),
            user_id: userId,
          },
        },
      };
    },
    update: {
      userCommunitySubscriptionByCommunityId: shallowObjectMerge,
    },
  };
};

export const removeCommunitySubscription = (
  userId: string,
  communitySubscription: CommunitiesAPI.CommunitySubscription,
): QueryConfig<Omit<CommunityState, "communitiesBySlug">> => {
  return {
    url: `${config.API_URL}/users/${userId}/community-subscriptions/${communitySubscription.id}`,
    meta: {
      includeToken: true,
    },
    options: {
      method: "DELETE",
      headers: STANDARD_HEADERS,
    },
    optimisticUpdate: {
      subscribedCommunityIdsByUserId: (prevValue) => {
        const staleSubscriptions = prevValue[userId] || [];
        return { [userId]: without(staleSubscriptions, communitySubscription.community_id) };
      },
      userCommunitySubscriptionByCommunityId: produce((draft) => {
        draft[communitySubscription.community_id] = undefined;
      }),
    },
    rollback: {
      subscribedCommunityIdsByUserId: (initialValue) => initialValue,
    },
  };
};

export const getUserCommunityRoles = (userId: string): QueryConfig<Pick<CommunityState, "userCommunityRolesById">> => {
  return {
    url: `${config.API_URL}/users/${userId}/community-roles`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: UsersAPI.GetCommunityRolesResponse) => {
      const roles = response.data.roles;
      return {
        userCommunityRolesById: keyBy(roles, (r) => r.id),
      };
    },
    update: {
      userCommunityRolesById: shallowObjectMerge,
    },
  };
};

export const getUserCommunityMembers = (
  communityId: number,
  limit?: number,
  offset?: number,
): QueryConfig<Pick<CommunityState, "communityMembersByCommunityId">> => {
  const searchParams = new URLSearchParams();
  if (isNumber(limit)) {
    searchParams.set("limit", toString(limit));
  }
  if (isNumber(offset)) {
    searchParams.set("offset", toString(offset));
  }
  const qs = searchParams.toString();
  return {
    url: `${config.API_URL}/communities/${communityId}/subscribers?${qs}`,
    options: {
      headers: STANDARD_HEADERS,
    },
    transform: (response: CommunitiesAPI.GetCommunityMembersResponse) => {
      const members = response.data.members;
      return {
        communityMembersByCommunityId: { [communityId]: members },
      };
    },
    update: {
      communityMembersByCommunityId: (oldValue, newValue) => {
        const prevData = oldValue[communityId];
        const newData = newValue[communityId];
        const combinedData = prevData ? unionBy(prevData.concat(newData), "id") : newData;
        return {
          ...oldValue,
          [communityId]: combinedData,
        };
      },
    },
  };
};
