import { useCallback, useContext, useMemo, useState } from "react";
import GlobalModalContext from "../components/GlobalModal/GlobalModalContext";
import { MarketingCampaign, MarketingMedium, MarketingSource } from "../enums/Marketing";
import analyticsUtil, { trackEvent } from "../helpers/analyticsUtil";
import { generateShareLink, OgTags } from "../helpers/shareUtils";

export interface UseShareOptions extends ShareData {
  campaign?: MarketingCampaign;
  medium: MarketingMedium;
  ogTags?: OgTags;
  shareEvent?: string;
  source?: MarketingSource;
  title: string;
  url: string;
}

export interface UseShareResponse {
  shareUrl: string;
  share: () => void;
}

export type UseShare = (options: UseShareOptions) => UseShareResponse;

const useShare: UseShare = ({ url, campaign, medium, source, ogTags, title, shareEvent }) => {
  const [shareUrl, setShareUrl] = useState<string>(url);
  const { open: openShareModal } = useContext(GlobalModalContext);

  const share = useCallback(async () => {
    let generatedUrl = shareUrl;
    if (!shareUrl || shareUrl === url) {
      const path = new URL(url).pathname;
      const generatedLink = await generateShareLink({
        campaign,
        medium,
        og: ogTags,
        path,
        source,
      });
      generatedUrl = generatedLink;
      setShareUrl(generatedUrl);
    }
    if (navigator.share) {
      navigator
        .share({
          title,
          url: generatedUrl,
        })
        .then(() => {
          trackEvent(analyticsUtil.ShareMenu.Viewed, { url });
          if (shareEvent) {
            trackEvent(shareEvent, { url });
          }
        })
        .catch((e) => {
          // user cancelled
          trackEvent(analyticsUtil.ShareMenu.Closed);
          console.warn(e.message);
        });
    } else {
      openShareModal({
        type: "share",
        props: {
          medium,
          title,
          url,
        },
      });
    }
  }, [campaign, medium, ogTags, openShareModal, shareEvent, shareUrl, source, title, url]);

  return useMemo(
    () => ({
      shareUrl,
      share,
    }),
    [shareUrl, share],
  );
};

export default useShare;
