import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import styled from "styled-components";
import useCommunities from "../../hooks/useCommunities";
import dayjs from "../../libs/dayjs";
import communitySelector from "../../selectors/communitySelector";
import { PostsAPI } from "../../typings/API-V2";
import { Box, Icon, Text } from "../../UI";
import UserFollowButton from "../UserProfile/UserFollowButton";

export interface PostTitleMetaProps {
  post: PostsAPI.Post;
  hideActions?: boolean;
  showPinned?: boolean;
}

const PostTitleMeta: React.FC<PostTitleMetaProps> = ({ post, hideActions, showPinned }) => {
  const { data } = useSigninCheck({
    requiredClaims: {
      role: "admin",
    },
  });
  useCommunities();
  const isAdmin = data?.hasRequiredClaims;
  const isModerator = useSelector(communitySelector.userIsModerator(data?.user?.uid, post.community.id));
  const showHidden = isModerator || isAdmin;
  const onAuthorClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          {post.author.is_deleted ? (
            <AuthorText>{`[deleted]`}</AuthorText>
          ) : (
            <Link to={`/u/${post.author.username}`} onClick={onAuthorClick}>
              <AuthorText>{`@${post.author.username}`}</AuthorText>
            </Link>
          )}
          {!hideActions && !post.author.is_deleted && post.author.id && (
            <UserFollowButton buttonType="text" targetUserId={post.author.id} ml={2} />
          )}
        </Box>
        <SubTextContainer>
          {showPinned && post.is_pinned && (
            <>
              <Text fontSize={0} color="darkGrey">
                <Icon name="pin" size="small" color="darkGrey" />
                <Text>Pinned</Text>
              </Text>
              <Text mx={1} color="darkGrey">
                {"•"}
              </Text>
            </>
          )}
          <Text fontSize={0} color="darkGrey">
            {dayjs(post.created_at).fromNow()}
          </Text>
        </SubTextContainer>
      </Box>
      {post.is_archived && (
        <Text fontSize={0} color="red" ml={2}>
          Archived
        </Text>
      )}
      {post.is_hidden && showHidden && (
        <Text fontSize={0} color="red" ml={2}>
          Hidden
        </Text>
      )}
    </Box>
  );
};
const SubTextContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const AuthorText = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`;
AuthorText.defaultProps = {
  fontSize: 1,
  color: "black",
  fontWeight: 4,
};

export default PostTitleMeta;
