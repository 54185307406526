import React, { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import { messageSW, Workbox } from "workbox-window";
import { WorkboxLifecycleWaitingEvent } from "workbox-window/utils/WorkboxEvent";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import { Box, BoxProps, Text } from "../../UI";

export interface ServiceWorkerHandlerProps {}

const ServiceWorkerHandler: React.FC<ServiceWorkerHandlerProps> = () => {
  const { addToast, removeAllToasts } = useToasts();

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      /**
       * wait for page load before executing service worker registration
       * https://developers.google.com/web/fundamentals/primers/service-workers/registration#conclusion
       */
      window.addEventListener("load", function () {
        const workbox = new Workbox(window.__SERVICE_WORKER_PATH__);
        let registration: ServiceWorkerRegistration | undefined;

        // Add an event listener to detect when the service worker is registered
        workbox
          .register()
          .then((r) => {
            console.log("Service worker registration succeeded:", r?.scope);
            registration = r;
          })
          .catch((error) => {
            console.log("Service worker registration failed:", error);
          });

        const onUpdateClick: Required<BoxProps>["onClick"] = () => {
          if (workbox) {
            workbox.addEventListener("controlling", () => {
              window.location.reload();
            });

            if (registration?.waiting) {
              // Send a message to the waiting service worker,
              // instructing it to activate.
              // Note: for this to work, you have to add a message
              // listener in your service worker. See below.
              messageSW(registration.waiting, { type: "SKIP_WAITING" });
            }
          }

          trackEvent(events.UpdateNotification.Accepted);

          removeAllToasts(); // always set false just in case update fails
        };

        const showUpdateToast: (event: WorkboxLifecycleWaitingEvent) => void = () => {
          trackEvent(events.UpdateNotification.Viewed);
          addToast(
            <Box display="flex" justifyContent="space-between" width="100%">
              <Text>Update available!</Text>
              <UpdateButton onClick={onUpdateClick}>
                <Text fontWeight={5}>REFRESH</Text>
              </UpdateButton>
            </Box>,
            {
              appearance: "info",
              autoDismiss: false,
              onDismiss() {
                trackEvent(events.UpdateNotification.Dismissed);
              },
            },
          );
        };

        // service worker has installed but is waiting to activate.
        workbox.addEventListener("waiting", showUpdateToast);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        workbox.addEventListener("externalwaiting", showUpdateToast);

        return () => {
          workbox.removeEventListener("waiting", showUpdateToast);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          workbox.removeEventListener("externalwaiting", showUpdateToast);
        };
      });
    }
  }, [addToast, removeAllToasts]);

  return null;
};

const UpdateButton = styled(Box)`
  cursor: pointer;
`;
UpdateButton.defaultProps = {
  role: "button",
};

export default ServiceWorkerHandler;
