import { MutableRefObject } from "react";

export type RefArg<T extends HTMLElement> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

/**
 * type guard for React.ForwardedRef value
 * @returns boolean
 */
export const isMutableRefObject = <T extends HTMLElement>(ref: RefArg<T>): ref is MutableRefObject<T | null> => {
  if (ref && "current" in ref) {
    return true;
  }
  return false;
};
