import { some } from "lodash-es";
import React, { useMemo } from "react";
import { SemanticICONS } from "semantic-ui-react";
import useNotificationList from "../../hooks/useNotificationList";
import useUnreadMessageCounts from "../../hooks/useUnreadMessageCounts";
import BottomNavIconLink from "./BottomNavIconLink";

export interface BottomNavInboxIconLinkProps {
  label: string;
  iconName: SemanticICONS;
}

const BottomNavInboxIconLink: React.FC<BottomNavInboxIconLinkProps> = ({ label, iconName }) => {
  const { notifications } = useNotificationList();
  const hasUnreadNotifications = useMemo(() => some(notifications, (n) => !n.is_read), [notifications]);
  const { total: totalUnreadMessages } = useUnreadMessageCounts();
  const displayNotificationBadge = hasUnreadNotifications || totalUnreadMessages > 0;

  return (
    <BottomNavIconLink
      label={label}
      pathname={`/inbox`}
      iconName={iconName}
      hasNotification={displayNotificationBadge}
    />
  );
};

export default BottomNavInboxIconLink;
