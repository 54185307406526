import React from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../Box";
import Text from "../Text";

export interface UserMentionMetaProps extends BoxProps {
  title: string;
  subtitle?: string | null;
}

const UserMentionMeta: React.FC<UserMentionMetaProps> = ({ title, subtitle, ...wrapperProps }) => {
  return (
    <UserMetaWrapper {...wrapperProps}>
      <ContentWrapper>
        <TitleText title={title}>{`@${title}`}</TitleText>
        {subtitle && <SubtitleText title={subtitle}>{subtitle}</SubtitleText>}
      </ContentWrapper>
    </UserMetaWrapper>
  );
};

const TitleText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
TitleText.defaultProps = {
  fontSize: 2,
  fontWeight: 5,
  color: "black",
};

const SubtitleText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
SubtitleText.defaultProps = {
  color: "darkGrey",
};

export const UserMetaWrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 0px;
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
`;
ContentWrapper.defaultProps = {
  ml: 2,
};

export default UserMentionMeta;
