import { QueryConfig } from "redux-query";
import config from "../../config";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { ExchangeRates } from "../../typings/API";
import { MetaState } from "../../typings/EntitiesState";

export const exchangeRatesQuery = (): QueryConfig<Pick<MetaState, "exchangeRates">> => ({
  url: `${config.API_URL}/meta/exchange-rates?${new URLSearchParams({
    base: "USD",
  }).toString()}`,
  options: {
    headers: STANDARD_HEADERS,
  },
  transform: (exchangeRates: ExchangeRates) => ({ exchangeRates }),
  update: {
    exchangeRates: (_, newValue) => newValue,
  },
});
