import { CaptureContext } from "@sentry/types";
import { AnyAction, Middleware } from "redux";
import { captureException } from "../helpers/errorUtil";

const queryFailureMiddleware: Middleware = (/* store */) => (next) => (action: AnyAction) => {
  if (
    action &&
    (action.type === "@@query/MUTATE_FAILURE" || action.type === "@@query/REQUEST_FAILURE") &&
    action.responseText &&
    action.status &&
    action.status >= 500
  ) {
    let captureContext: undefined | CaptureContext;
    if (action?.url) {
      captureContext = {
        extra: {
          url: action.url,
        },
      };
    }
    captureException(action.responseText, captureContext);
  }

  return next(action);
};

export default queryFailureMiddleware;
