import React, { Suspense } from "react";
import styled, { css } from "styled-components";
import { isMobileFrame } from "../../config";
import { Box, BoxProps, Grid } from "../../UI";
import { BOTTOM_NAV_HEIGHT } from "../BottomNav/BottomNav";
import Copyright from "./Copyright";
import FooterContent from "./FooterContent";

export interface FooterProps extends BoxProps {}

const Footer: React.FC<FooterProps> = ({ ...footerWrapperProps }) => {
  return (
    <Suspense fallback={<div></div>}>
      <FooterWrapper as="footer" {...footerWrapperProps}>
        <FooterItemGrid>
          <FooterContent />
        </FooterItemGrid>
        <Copyright py={2} color="white" />
      </FooterWrapper>
    </Suspense>
  );
};

const FooterWrapper = styled(Box)`
  width: 100%;
  box-sizing: border-box;
`;
FooterWrapper.defaultProps = {
  bg: "black",
  color: "white",
  mt: 3,
  px: 3,
  pt: 4,
  pb: BOTTOM_NAV_HEIGHT,
};

const FooterItemGrid = styled(Grid)`
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space[3]}px;

  ${() =>
    !isMobileFrame &&
    css`
      ${({ theme }): string => theme.mediaQueries.small} {
        grid-template-columns: 1fr 1fr;
        grid-gap: ${({ theme }) => theme.space[3]}px;
      }

      ${({ theme }): string => theme.mediaQueries.medium} {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: ${({ theme }) => theme.space[2]}px;
      }
    `}
`;

export default React.memo(Footer);
