import styled from "styled-components";
import Box, { BoxProps } from "./Box";

export interface SegmentProps extends BoxProps {}

/* for now, Segment is just a Box with a specific box shadow */
const Segment = styled(Box)<SegmentProps>`
  transition: box-shadow 0.25s ease-in-out;
  &:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  }
`;
Segment.defaultProps = {
  borderRadius: 3,
};

export default Segment;
