import { useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "redux-query-react";
import { captureException } from "../helpers/errorUtil";
import { actionPromiseToPromise } from "../helpers/reactQueryUtil";
import { blockUserQuery, unblockUserQuery } from "../queries/api/userBlockQuery";
import { UsersAPI } from "../typings/API";
import useAuthenticatedCallback from "./useAuthenticatedCallback";
import useConfirmationCallback from "./useConfirmationCallback";

export type BlockActions = {
  handleBlockUser: () => void;
  blockWithConfirmation: () => void;
};

const useBlockActions = (
  isBlocked: boolean,
  blockee: Pick<UsersAPI.PublicProfile, "id" | "username">,
  onBlock?: () => void,
): BlockActions => {
  const [, blockUser] = useMutation(blockUserQuery);
  const [, unblockUser] = useMutation(unblockUserQuery);
  const { addToast } = useToasts();
  const blockeeId = blockee.id;
  const blockeeUsername = blockee.username;

  const handleBlockUser = useAuthenticatedCallback(async () => {
    try {
      if (isBlocked) {
        await actionPromiseToPromise(unblockUser(blockeeId));
      } else {
        await actionPromiseToPromise(blockUser(blockeeId));
      }
      addToast(`@${blockeeUsername || blockeeId} ${isBlocked ? "unblocked" : "blocked"}`, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(`An error occurred`, {
        appearance: "warning",
        autoDismiss: true,
      });
      captureException(error);
    }
    if (onBlock) {
      onBlock();
    }
  }, [blockUser, unblockUser, addToast, blockeeId, blockeeUsername, onBlock, isBlocked]);

  const blockWithConfirmation = useConfirmationCallback(
    `${isBlocked ? "Unblock" : "Block"}${blockee ? ` @${blockee.username}?` : `?`}`,
    handleBlockUser,
    [isBlocked, blockee],
  );

  return useMemo(
    () => ({
      handleBlockUser,
      blockWithConfirmation,
    }),
    [handleBlockUser, blockWithConfirmation],
  );
};

export default useBlockActions;
