import produce from "immer";
import { keyBy } from "lodash-es";
import { nanoid } from "nanoid";
import { QueryConfig } from "redux-query";
import config from "../../config";
import { STANDARD_HEADERS } from "../../helpers/requestUtil";
import { PostsAPI } from "../../typings/API";
import EntitiesState, { PostsState } from "../../typings/EntitiesState";

/** @deprecated */
export const getPostMeta = (): QueryConfig<Pick<PostsState, "postTypesByKey" | "postThemesByKey">> => ({
  url: `${config.API_URL}/post-meta`,
  options: {
    headers: STANDARD_HEADERS,
  },
  transform: (response: PostsAPI.GetPostMetaResponse) => {
    const {
      data: { types, themes },
    } = response;
    return {
      postTypesByKey: keyBy(types, (t) => t.key),
      postThemesByKey: keyBy(themes, (t) => t.key),
    };
  },
  update: {
    postTypesByKey: (_, newValue) => newValue,
    postThemesByKey: (_, newValue) => newValue,
  },
});

export const deletePost = (postId: number): QueryConfig<Pick<PostsState, "postsById">> => ({
  url: `${config.API_URL}/posts/${postId}`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "DELETE",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    postsById: produce((draft) => {
      const originalPost = draft[postId];
      if (originalPost) {
        originalPost.is_deleted = true;
      }
    }),
  },
});

export const createPostImageUploadUrl = (contentType: string): QueryConfig<EntitiesState> => {
  return {
    url: `${config.API_URL}/posts/image-upload-url`,
    meta: {
      includeToken: true,
    },
    body: {
      contentType,
    },
    options: {
      method: "POST",
      headers: STANDARD_HEADERS,
    },
  };
};

/**
 * @deprecated
 * This should use method POST and pass in the content type to be used by the backend
 */
export const getPostImageUploadUrl = (): QueryConfig<EntitiesState> => ({
  /** Add cache busting: https://stackoverflow.com/q/59339561/4074974 */
  url: `${config.API_URL}/posts/image-upload-url?cachebust=${nanoid()}`,
  force: true,
  meta: {
    includeToken: true,
  },
  options: {
    headers: {
      ...STANDARD_HEADERS,
      "Cache-Control": "no-cache, no-store",
      /** https://stackoverflow.com/a/54335325/4074974 */
      Vary: "*",
    },
  },
});

export const createPostVote = (postId: number, vote: -1 | 1): QueryConfig<Pick<PostsState, "postsById">> => ({
  url: `${config.API_URL}/posts/${postId}/vote`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "POST",
    headers: STANDARD_HEADERS,
  },
  body: { vote },
  optimisticUpdate: {
    postsById: produce((draft) => {
      const originalPost = draft[postId];
      if (originalPost) {
        originalPost.current_vote = vote;
        originalPost.up_count = vote === 1 ? originalPost.up_count + 1 : originalPost.up_count;
        originalPost.down_count = vote === -1 ? originalPost.down_count + 1 : originalPost.down_count;
      }
    }),
  },
});

export const deletePostVote = (postId: number, vote: number): QueryConfig<Pick<PostsState, "postsById">> => ({
  url: `${config.API_URL}/posts/${postId}/vote`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "DELETE",
    headers: STANDARD_HEADERS,
  },
  optimisticUpdate: {
    postsById: produce((draft) => {
      const originalPost = draft[postId];
      if (originalPost) {
        originalPost.current_vote = null;
        if (vote === 1) {
          originalPost.up_count--;
        } else if (vote === -1) {
          originalPost.down_count--;
        }
      }
    }),
  },
});

export const updatePostVote = (postId: number, vote: -1 | 1): QueryConfig<Pick<PostsState, "postsById">> => ({
  url: `${config.API_URL}/posts/${postId}/vote`,
  meta: {
    includeToken: true,
  },
  options: {
    method: "PUT",
    headers: STANDARD_HEADERS,
  },
  body: {
    vote,
  },
  optimisticUpdate: {
    postsById: produce((draft) => {
      const originalPost = draft[postId];
      if (originalPost) {
        originalPost.current_vote = vote;
        originalPost.up_count += vote;
        originalPost.down_count -= vote;
      }
    }),
  },
});
