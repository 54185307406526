import { debounce, map } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import { algoliaProductToProduct } from "../../helpers/productUtil";
import useAutoFocusRefCallback from "../../hooks/useAutoFocusRefCallback";
import useBrands from "../../hooks/useBrands";
import usePlugdAlgolia from "../../hooks/usePlugdAlgolia";
import { ProductsAPI } from "../../typings/API";
import { PlugdAlgoliaProduct } from "../../typings/PlugdProduct";
import { Box, Image, Text } from "../../UI";
import FullscreenMenu, { FullscreenMenuProps } from "../Layout/FullscreenMenu";
import FullscreenSearchMenuHeader from "../Layout/FullscreenSearchMenuHeader";
import ProductSelectorItemLoader from "./ProductSelectorItemLoader";

export interface ProductSelectorMenuProps extends Pick<FullscreenMenuProps, "isOpen" | "close"> {
  onSelect: (product: ProductsAPI.Product) => void;
}

const ProductSelectorMenu: React.FC<ProductSelectorMenuProps> = ({ isOpen, close, onSelect }) => {
  useEffect(() => {
    trackEvent(events.ProductSelectorMenu.Viewed);
  }, []);

  const [, algolia] = usePlugdAlgolia();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [inputRefCallback] = useAutoFocusRefCallback<HTMLInputElement>();
  const [searchText, setSearchText] = useState<string>("");
  const [products, setProducts] = useState<ProductsAPI.Product[]>([]);
  const { brandsById } = useBrands();
  const brandsList = useMemo(() => map(brandsById, (b) => b), [brandsById]);
  const updateSearchText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value), []);
  const handleSelect = useCallback(
    (product: ProductsAPI.Product) => {
      onSelect(product);
      close();
    },
    [onSelect, close],
  );

  const debouncedProductSearch = useMemo(
    () =>
      debounce((queryText: string) => {
        if (algolia) {
          return algolia.productIndex
            .search<PlugdAlgoliaProduct>(queryText)
            .then((results) => setProducts(results.hits.slice().map((h) => algoliaProductToProduct(h, brandsList))))
            .finally(() => {
              setIsLoading(false);
              setHasLoaded(true);
            });
        }
      }, 250),
    [brandsList, algolia],
  );

  useEffect(() => {
    if (algolia) {
      setIsLoading(true);
      setHasLoaded(true);
      debouncedProductSearch(searchText);
    }
  }, [brandsList, searchText, debouncedProductSearch, algolia]);

  const clear = useCallback(() => {
    setSearchText("");
  }, [setSearchText]);

  return (
    <FullscreenMenu
      isOpen={isOpen}
      close={close}
      bg="lightGrey"
      header={
        <FullscreenSearchMenuHeader
          close={close}
          onChange={updateSearchText}
          onClear={clear}
          placeholder={`Search products by name, style, or brand`}
          queryText={searchText}
          ref={inputRefCallback}
        />
      }
    >
      <>
        {isLoading ? (
          Array.from({ length: 12 }).map((_, idx) => <ProductSelectorItemLoader key={idx} />)
        ) : hasLoaded && products.length === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center" mt={5}>{`We couldn't find any matches.`}</Box>
        ) : (
          products.map((p) => {
            const brand = p.brand ? brandsById[p.brand] : undefined;
            return (
              <SelectItem key={p.id} onClick={() => handleSelect(p)}>
                <ImageWrapper width={50}>
                  <Image alt={p.name} width="100%" height="auto" data-src={p.image} className="lazyload" />
                </ImageWrapper>
                <Box display="flex" flexDirection="column">
                  {brand && (
                    <Text ml={2} fontSize={0} fontWeight={5} color="darkGrey">
                      {brand.brand_name}
                    </Text>
                  )}
                  <Text ml={2} fontSize={0}>
                    {p.name}
                  </Text>
                </Box>
              </SelectItem>
            );
          })
        )}
      </>
    </FullscreenMenu>
  );
};

const SelectItem = styled(Box)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;
SelectItem.defaultProps = {
  role: "button",
  px: 3,
  py: 2,
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  bg: "white",
};

const ImageWrapper = styled(Box)`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
`;

export default ProductSelectorMenu;
