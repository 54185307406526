import React from "react";
import { Helmet, HelmetProps } from "react-helmet";
import { isStaging, isWeb } from "../config";

export interface MetaProps extends HelmetProps {}

const Meta: React.FC<React.PropsWithChildren<MetaProps>> = ({ children, ...helmetProps }) => {
  if (!isWeb && !isStaging) {
    return null;
  }

  if (!children) {
    return null;
  }

  return <Helmet {...helmetProps}>{children}</Helmet>;
};

export default Meta;
