import React, { lazy, PropsWithChildren, Suspense, useCallback, useMemo, useState } from "react";
import { DraggableEventHandler, DraggableProps } from "react-draggable";
import styled from "styled-components";
import { retry } from "ts-retry-promise";
import { PostsAPI } from "../../../../typings/API-V2";
import { Box } from "../../../../UI";
import ImageTag from "../../../ImageTag";

const Draggable = lazy(() =>
  retry(() => import(/* webpackChunkName: "react-draggable" */ "react-draggable"), {
    retries: 5,
  }),
);

export interface DraggableImageTagProps {
  parentEl: HTMLDivElement;
  imageDimensions: { height: number; width: number };
  tag: PostsAPI.PostImageTagInsert | PostsAPI.PostImageTag;
  updateTag: (productId: number, updates: Pick<PostsAPI.PostImageTagInsert, "x" | "y">) => void;
  removeTag: (productId: number) => void;
}

const DraggableImageTag: React.FC<DraggableImageTagProps> = ({
  removeTag,
  updateTag,
  parentEl,
  tag,
  imageDimensions,
}) => {
  const tagPosition = useMemo<{ x: number; y: number }>(
    () => ({
      x: tag.x * imageDimensions.width,
      y: tag.y * imageDimensions.height,
    }),
    [tag, imageDimensions],
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const onTagClick = useCallback(
    (e) => {
      e.stopPropagation();
      removeTag(tag.product.id);
    },
    [removeTag, tag.product.id],
  );

  const onDrag = useCallback<DraggableEventHandler>(
    (e) => {
      e.stopPropagation();
      if (!isDragging) {
        setIsDragging(true);
      }
    },
    [isDragging],
  );

  const onDragStop = useCallback<DraggableEventHandler>(
    (e, data) => {
      e.stopPropagation();
      if (isDragging) {
        updateTag(tag.product.id, {
          x: Math.max(Math.min(data.x / imageDimensions.width, 1), 0),
          y: Math.max(Math.min(data.y / imageDimensions.height, 1), 0),
        });
      } else {
        onTagClick(e);
      }
      setIsDragging(false);
    },
    [updateTag, onTagClick, isDragging, imageDimensions, tag],
  );

  return (
    <Suspense fallback={null}>
      <DraggableWrapper
        axis="both"
        bounds="parent"
        offsetParent={parentEl}
        onDrag={onDrag}
        onStop={onDragStop}
        defaultPosition={tagPosition}
      >
        <Box position="absolute" left={0} top={0}>
          <CustomImageTag style={{ cursor: "grab" }}>{tag.product.name}</CustomImageTag>
        </Box>
      </DraggableWrapper>
    </Suspense>
  );
};

interface DraggableWrapperProps extends PropsWithChildren<DraggableProps> {}
const DraggableWrapper = styled(Draggable)<DraggableWrapperProps>``;

const CustomImageTag = styled(ImageTag)`
  cursor: move;
  cursor: grab;

  &:hover {
    text-decoration: none;
  }
`;

export default DraggableImageTag;
