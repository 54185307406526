import { FirebaseOptions } from "firebase/app";
import { User } from "firebase/auth";
import config from "../config";
import { OutboundMessageType } from "../enums/MessageType";
import { ConsoleType } from "../scripts/consoleOverrides";

export interface OutboundMessage<P = unknown> {
  action: OutboundMessageType;
  payload?: P;
}

// TODO: add all message creators

export const logMessage = (payload: {
  type: ConsoleType;
  args: any[];
}): OutboundMessage<{ type: ConsoleType; args: any[] }> => ({
  action: OutboundMessageType.LOG,
  payload,
});

/**
 * Tells the native app that the mobile frame is ready
 */
export const readyMessage = (): OutboundMessage<undefined> => ({
  action: OutboundMessageType.READY,
});

/**
 * request notification permissions
 */
export const getNotificationsApproval = (): OutboundMessage<undefined> => ({
  action: OutboundMessageType.GET_NOTIFICATIONS_APPROVAL,
});

/**
 * request tracking permissions
 */
export const getTrackingApproval = (): OutboundMessage<undefined> => ({
  action: OutboundMessageType.GET_TRACKING_APPROVAL,
});

/**
 * Open the native device's app settings
 */
export const openAppSettings = (): OutboundMessage<undefined> => ({
  action: OutboundMessageType.OPEN_APP_SETTINGS,
});

/** Custom response type for user.toJSON method */
export type SerializableUser = Pick<
  User,
  | "uid"
  | "email"
  | "emailVerified"
  | "displayName"
  | "isAnonymous"
  | "photoURL"
  | "phoneNumber"
  | "tenantId"
  | "providerData"
  /** Not sure why the below cause a type issue */
  // | "stsTokenManager"
  // | "_redirectEventId"
  // | "createdAt"
  // | "lastLoginAt"
  // | "apiKey"
  // | "appName"
>;
export type SerializableUserWithAuthDomain = SerializableUser & Pick<FirebaseOptions, "authDomain">;
/**
 * Syncs the user's auth state with the native app
 *
 * @param user {User | null}
 */
export const authStateChange = (
  user: SerializableUser | null,
): OutboundMessage<SerializableUserWithAuthDomain | null> => ({
  action: OutboundMessageType.AUTH_STATE_CHANGE,
  payload: user
    ? {
        ...user,
        authDomain: config.FIREBASE_CONFIG.authDomain,
      }
    : null,
});

/**
 * Display the subscription offers in the native app
 */
export const displaySubscriptionOfferings = (): OutboundMessage<undefined> => ({
  action: OutboundMessageType.DISPLAY_SUBSCRIPTION_OFFERINGS,
});
