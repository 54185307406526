import { reduce, sortBy, union } from "lodash-es";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSigninCheck } from "reactfire";
import { QueryState } from "redux-query";
import { useRequest } from "redux-query-react";
import { getProductRaffles } from "../queries/api/productRafflesQuery";
import { getProductReleases } from "../queries/api/productReleasesQuery";
import userSelector from "../selectors/userSelector";
import { RootState } from "../store";
import { ProductsAPI } from "../typings/API";

const useUpcomingReleasesRafflesProducts = (): QueryState & {
  products: ProductsAPI.Product[];
  releaseProductIds: number[];
  raffleProductIds: number[];
} => {
  const appInitializedAt = useSelector((state: RootState) => state.app.initializedAt);
  const nowISOString = appInitializedAt.toISOString();
  const shippingLocationCountry = useSelector(userSelector.shippingLocationCountry());

  const [rafflesQueryState] = useRequest(
    getProductRaffles({
      country_code: shippingLocationCountry,
      end_date: `gte:${nowISOString}`,
    }),
  );
  const [releasesQueryState] = useRequest(
    getProductReleases({
      country_code: shippingLocationCountry,
      release_date: `gte:${nowISOString}`,
    }),
  );

  /**
   * admin data fetching
   */
  const { data } = useSigninCheck({
    requiredClaims: {
      role: "admin",
    },
  });
  const isAdmin = data?.hasRequiredClaims;
  useRequest(
    isAdmin
      ? getProductRaffles({
          country_code: shippingLocationCountry,
          end_date: `gte:${nowISOString}`,
          has_url: false,
        })
      : null,
  );
  useRequest(
    isAdmin
      ? getProductReleases({
          country_code: shippingLocationCountry,
          release_date: `gte:${nowISOString}`,
          has_url: false,
        })
      : null,
  );
  const releases = useSelector((state: RootState) => state.entities.releasesById);
  const raffles = useSelector((state: RootState) => state.entities.rafflesById);
  const releaseProductIds = useMemo<number[]>(
    () =>
      reduce<typeof releases, number[]>(
        releases,
        (result, release) => {
          if (release.release_date >= nowISOString) {
            result.push(release.product_id);
          }
          return result;
        },
        [],
      ),
    [releases, nowISOString],
  );

  const raffleProductIds = useMemo<number[]>(
    () =>
      reduce<typeof raffles, number[]>(
        raffles,
        (result, raffle) => {
          if (raffle.end_date >= nowISOString) {
            result.push(raffle.product_id);
          }
          return result;
        },
        [],
      ),
    [raffles, nowISOString],
  );

  const releaseAndRaffleProductIds = useMemo(
    () => union(releaseProductIds, raffleProductIds),
    [releaseProductIds, raffleProductIds],
  );

  const productsById = useSelector((state: RootState) => state.entities.productsById);

  const filteredProducts = useMemo(
    () =>
      sortBy(
        releaseAndRaffleProductIds.map((id) => productsById[id]).filter((p): p is ProductsAPI.Product => Boolean(p)),
        (p) => p.release_date,
      ),
    [productsById, releaseAndRaffleProductIds],
  );

  const queryState: QueryState = useMemo(
    () => ({
      isPending: releasesQueryState.isPending || rafflesQueryState.isPending,
      isFinished: releasesQueryState.isFinished || rafflesQueryState.isFinished,
    }),
    [releasesQueryState, rafflesQueryState],
  );

  return useMemo(
    () => ({
      ...queryState,
      products: filteredProducts,
      releaseProductIds,
      raffleProductIds,
    }),
    [queryState, filteredProducts, releaseProductIds, raffleProductIds],
  );
};

export default useUpcomingReleasesRafflesProducts;
