import { sortBy, toString } from "lodash-es";
import React, { useCallback, useMemo, useState } from "react";
import useMenuState from "../../../hooks/useMenuState";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
} from "../../Layout/FullscreenMenu";
import RadioMenuItem from "../../Layout/MenuItem/RadioMenuItem";
import InputSelectBox from "../InputSelectBox";
import { UseInputHook } from "../types/inputHooks";

type UseSizeInputOptions = {
  defaultSize?: number;
  sizeRange: number[];
};

const useSizeInput: UseInputHook<number | null, UseSizeInputOptions> = (options) => {
  const [size, setSize] = useState<number | null>(options.defaultSize || null);
  const {
    open: openMenu,
    menuProps: { close, isOpen },
  } = useMenuState();

  const sortedSizes = useMemo(() => sortBy(options.sizeRange, (s) => s), [options.sizeRange]);

  const onItemSelect = useCallback<(size: number) => void>(
    (size) => {
      setSize(size);
      close();
    },
    [close],
  );

  const input = useMemo(
    () => (
      <InputSelectBox
        onClick={openMenu}
        value={size}
        displayValue={`US ${size}`}
        placeholder="Size"
        borderLeft={"1px solid"}
      />
    ),
    [openMenu, size],
  );

  const menus = useMemo(
    () => (
      <FullscreenMenu
        header={
          <FullscreenMenuHeader>
            <FullscreenMenuHeaderIcon onClick={close} name="chevron left" />
            <FullscreenMenuHeaderTitle>Select Size</FullscreenMenuHeaderTitle>
            <FullscreenMenuHeaderIcon />
          </FullscreenMenuHeader>
        }
        close={close}
        isOpen={isOpen}
      >
        {sortedSizes.map((s) => (
          <RadioMenuItem key={s} name={`US ${toString(s)}`} onClick={() => onItemSelect(s)} isChecked={size === s} />
        ))}
      </FullscreenMenu>
    ),
    [close, isOpen, size, sortedSizes, onItemSelect],
  );

  const clearValue = useCallback(() => setSize(null), []);

  return useMemo(
    () => ({
      input,
      menus,
      value: size,
      clearValue,
    }),
    [clearValue, size, input, menus],
  );
};

export default useSizeInput;
