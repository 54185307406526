import axios, { AxiosError, AxiosResponse } from "axios";
import { HttpMethods, KnownHttpMethods, NetworkHandler, NetworkOptions, Url } from "redux-query";

const axiosNetworkInterface = (url: Url, method: HttpMethods, networkOptions: NetworkOptions): NetworkHandler => {
  const source = axios.CancelToken.source();
  return {
    abort: source.cancel,
    execute: (cb) => {
      axios({
        url: url,
        cancelToken: source.token,
        method: method as KnownHttpMethods,
        headers: networkOptions.headers,
        data: networkOptions.body,
      })
        .then((response: AxiosResponse) =>
          cb(null, response.status, response.data, response.statusText, response.headers),
        )
        .catch((error: AxiosError) =>
          cb(
            error,
            /**
             * if there is no status code, it usually means that there is an internal error and therefore no response.
             * Return 0 status code
             */
            error.response?.status ? error.response.status : 0,
            error.response?.data,
            error.message,
            error.response?.headers,
          ),
        );
    },
  };
};

export default axiosNetworkInterface;
