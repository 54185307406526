import { SearchResponse } from "@algolia/client-search";
import request, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import ExpiryMap from "expiry-map";
import { isBoolean, toString } from "lodash-es";
import pMemoize from "p-memoize";
import config from "./config";
import { VendorKey } from "./enums/Vendor";
import { PriceAddOn } from "./helpers/askUtil";
import { ProductPriceAlarm } from "./helpers/productPriceAlertUtil";
import { standardHeadersWithToken, STANDARD_HEADERS } from "./helpers/requestUtil";
import { isAndroid, isIOS } from "./libs/ua-parser-js";
import { GoatCollectionResult, IdType, Price, ProductsAPI } from "./typings/API";

export interface GetProductsOptions {
  ids?: number[];
  limit?: number;
  offset?: number;
  release_date?: string;
  sort_by?: "release_date";
  order_by?: "asc" | "desc";
}

export interface Dao {
  getCollection: (collectionSlug: string) => Promise<GoatCollectionResult>;
  getEbayAsks: (
    productSlug: string,
    size?: string,
    condition?: "new" | "used",
    countryCode?: string,
    detailed?: boolean,
    limit?: number,
    offset?: number,
  ) => Promise<{ asks: PriceAddOn.WithFees[] }>;
  getGoatUsedAsks: (goatSlug: string, size?: string) => Promise<{ asks: Price[]; bids: Price[] }>;
  getGoatUsedBid: (goatSlug: string) => Promise<Price>;
  getPartnerCollectionProducts: (
    partnerId: string,
    collectionId: number,
  ) => Promise<{ products: ProductsAPI.Product[] }>;
  getPartnerPrice: (partnerId: string, productSlug: string) => Promise<{ asks: Price[] }>;
  getProductPriceHistory: (productId: number, start: string, end?: string) => Promise<{ priceHistory: number[] }>;
  getVendorPrice: (
    vendorSlug: string,
    productSlug: string,
    app?: boolean,
  ) => Promise<{ asks: Price[]; bids?: Price[] }>;
  getUserProductAlarms: (userId: string, productId?: number, active?: boolean) => Promise<ProductPriceAlarm[]>;
  createUserProductAlarm: (userId: string, alarm: ProductPriceAlarm) => Promise<{ id: number }>;
  updateUserProductAlarm: (userId: string, alarmId: number, alarm: ProductPriceAlarm) => Promise<void>;
  deleteUserProductAlarm: (userId: string, alarmId: number) => Promise<{ rowCount: number }>;
  vendorsSearchUnderRetail: (page?: number) => Promise<SearchResponse<ProductsAPI.Product>>;
}

// "value" key automatically applied afterwards
interface TimingConfig {
  name: string;
  event_category: string;
  event_label?: string;
}

interface ExceptionConfig {
  description: string;
  fatal: boolean;
}

const withAnalytics = <T, F = never>(
  timingConfig: TimingConfig,
  exceptionConfig: (error: AxiosError) => ExceptionConfig,
  requestFn: () => AxiosPromise<T>,
  onSuccess: (result: AxiosResponse<T>) => T,
  onFail?: (error: AxiosError) => F | T,
): Promise<T | F> => {
  const startTime = new Date().valueOf();
  return requestFn()
    .then((result) => {
      if (typeof gtag === "function") {
        gtag("event", "timing_complete", {
          ...timingConfig,
          value: new Date().valueOf() - startTime,
        });
      }
      return onSuccess(result);
    })
    .catch((e: AxiosError) => {
      if (typeof gtag === "function") {
        gtag("event", "exception", exceptionConfig(e));
      }
      if (typeof onFail === "function") {
        return onFail(e);
      }
      throw e;
    });
};

const cache = new ExpiryMap(5 * 60 * 1000 /* 5 minutes */);

const dao: Dao = {
  getCollection: pMemoize(
    (collectionSlug: string) => {
      const requestUrl = `${config.API_URL}/goat/collections/${collectionSlug}`;
      return withAnalytics<GoatCollectionResult>(
        {
          name: "getCollection",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response): GoatCollectionResult => response.data,
      );
    },
    {
      cacheKey: (args) => args.join(","),
    },
  ),

  getVendorPrice: pMemoize(
    (
      vendorSlug: string,
      productSlug: string,
      app: boolean = isAndroid || isIOS,
    ): Promise<{ asks: Price[]; bids?: Price[] }> => {
      const qs = new URLSearchParams({
        idType: IdType.Slug,
        app: toString(app),
      }).toString();

      const requestUrl = `${config.API_URL}/${vendorSlug}/products/${productSlug}/prices/dump?${qs}`;

      return withAnalytics<{ asks: Price[]; bids?: Price[] }>(
        {
          name: "getVendorPrice",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response) => response.data,
        (error) => {
          if (error.code === "404") {
            return { asks: [] };
          }
          throw error;
        },
      );
    },
    {
      cacheKey: (args) => args.join(","),
      cache,
    },
  ),

  getPartnerPrice: pMemoize(
    (partnerId: string, productSlug: string): Promise<{ asks: Price[] }> => {
      const qs = new URLSearchParams({
        idType: IdType.Slug,
        partner: partnerId,
      }).toString();

      const requestUrl = `${config.API_URL}/partners/products/${productSlug}/prices?${qs}`;

      return withAnalytics<{ asks: Price[] }>(
        {
          name: "getPartnerPrice",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response) => response.data,
        (error) => {
          if (error.code === "404") {
            return { asks: [] };
          }
          throw error;
        },
      );
    },
    {
      cacheKey: (args) => args.join(","),
      cache,
    },
  ),

  getUserProductAlarms: pMemoize(
    (userId: string, productId?: number, active?: boolean) => {
      const searchParams = new URLSearchParams();
      if (productId) {
        searchParams.set("productId", toString(productId));
      }
      if (typeof active === "boolean") {
        searchParams.set("active", toString(active));
      }
      const qs = searchParams.toString();
      const requestUrl = `${config.API_URL}/users/${userId}/product-alarms?${qs}`;
      return withAnalytics<ProductPriceAlarm[]>(
        {
          name: "getUserProductAlarms",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        async () => {
          return request({
            method: "GET",
            headers: await standardHeadersWithToken(),
            url: requestUrl,
          });
        },
        (response): ProductPriceAlarm[] => response.data["alarms"],
      );
    },
    {
      cacheKey: (args) => args.join(","),
    },
  ),

  createUserProductAlarm: (userId: string, alarm: ProductPriceAlarm) => {
    const requestUrl = `${config.API_URL}/users/${userId}/product-alarms`;
    return withAnalytics<{ id: number }>(
      {
        name: "createUserProductAlarm",
        event_category: "api_request",
        event_label: requestUrl,
      },
      (error) => ({
        description: `[${error.code}] ${error.message}`,
        fatal: false,
      }),
      async () => {
        return request({
          method: "POST",
          headers: await standardHeadersWithToken(),
          data: alarm,
          url: requestUrl,
        });
      },
      (response): { id: number } => response.data,
    );
  },

  updateUserProductAlarm: (userId: string, alarmId: number, alarm: ProductPriceAlarm) => {
    const requestUrl = `${config.API_URL}/users/${userId}/product-alarms/${alarmId}`;
    return withAnalytics<void>(
      {
        name: "updateUserProductAlarm",
        event_category: "api_request",
        event_label: requestUrl,
      },
      (error) => ({
        description: `[${error.code}] ${error.message}`,
        fatal: false,
      }),
      async () => {
        return request({
          method: "PUT",
          headers: await standardHeadersWithToken(),
          data: alarm,
          url: requestUrl,
        });
      },
      (response): void => response.data,
    );
  },

  deleteUserProductAlarm: (userId: string, alarmId: number) => {
    const requestUrl = `${config.API_URL}/users/${userId}/product-alarms/${alarmId}`;
    return withAnalytics<{ rowCount: number }>(
      {
        name: "deleteUserProductAlarm",
        event_category: "api_request",
        event_label: requestUrl,
      },
      (error) => ({
        description: `[${error.code}] ${error.message}`,
        fatal: false,
      }),
      async () => {
        return request({
          method: "DELETE",
          headers: await standardHeadersWithToken(),
          url: requestUrl,
        });
      },
      (response): { rowCount: number } => response.data,
    );
  },

  vendorsSearchUnderRetail: pMemoize(
    (page?: number) => {
      const qs = new URLSearchParams({
        page: toString(page || 0),
      }).toString();
      const requestUrl = `${config.API_URL}/vendors-search/product-categories/under-retail?${qs}`;

      return withAnalytics<SearchResponse<ProductsAPI.Product>>(
        {
          name: "vendorsSearchUnderRetail",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response): SearchResponse<ProductsAPI.Product> => response.data,
      );
    },
    {
      cacheKey: (args) => args.join(","),
    },
  ),

  getEbayAsks: pMemoize(
    (
      slug: string,
      size?: string,
      condition?: "new" | "used",
      countryCode?: string,
      detailed?: boolean,
      limit?: number,
      offset?: number,
    ) => {
      const searchParams = new URLSearchParams({
        vendor: VendorKey.EBay,
        idType: IdType.Slug,
      });
      if (condition) {
        searchParams.set("condition", condition);
      }
      if (countryCode) {
        searchParams.set("country_code", countryCode);
      }
      if (isBoolean(detailed)) {
        searchParams.set("detailed", toString(detailed));
      }
      if (limit) {
        searchParams.set("limit", toString(limit));
      }
      if (offset) {
        searchParams.set("offset", toString(offset));
      }
      if (size && size !== "all") {
        searchParams.set("size", size);
      }
      const qs = searchParams.toString();
      const requestUrl = `${config.API_URL}/ebay/products/${slug}/prices/dump?${qs}`;
      return withAnalytics<{ asks: PriceAddOn.WithFees[] }>(
        {
          name: "getEbayAsks",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response) => response.data,
        (error) => {
          if (error.code === "404") {
            return { asks: [] };
          }
          throw error;
        },
      );
    },
    {
      cacheKey: (args) => args.join(","),
      cache,
    },
  ),

  getGoatUsedAsks: pMemoize(
    (goatSlug: string, size?: string) => {
      const query = {
        vendor: VendorKey.Goat,
        idType: IdType.Slug,
        shoeCondition: "used",
      };
      if (size && size !== "all") {
        query["size"] = size;
      }
      const qs = new URLSearchParams(query).toString();
      const requestUrl = `${config.API_URL}/goat/products/${goatSlug}/prices/dump?${qs}`;
      return withAnalytics<{ asks: Price[]; bids: Price[] }>(
        {
          name: "getGoatUsedAsks",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response) => response.data,
        (error) => {
          if (error.code === "404") {
            return { asks: [], bids: [] };
          }
          throw error;
        },
      );
    },
    {
      cacheKey: (args) => args.join(","),
      cache,
    },
  ),

  getGoatUsedBid: pMemoize(
    (goatSlug: string) => {
      const requestUrl = `${config.API_URL}/goat/products/used/${goatSlug}/bid`;
      return withAnalytics<Price>(
        {
          name: "getGoatUsedBid",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response) => response.data,
      );
    },
    {
      cacheKey: (args) => args.join(","),
      cache,
    },
  ),

  getPartnerCollectionProducts: pMemoize(
    (partnerId: string, collectionId: number) => {
      const requestUrl = `${config.API_URL}/partners/${partnerId}/collections/${collectionId}/products`;
      return withAnalytics<{ products: ProductsAPI.Product[] }>(
        {
          name: "getPartnerCollectionProducts",
          event_category: "api_request",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response) => response.data,
      );
    },
    {
      cacheKey: (args) => args.join(","),
    },
  ),
  getProductPriceHistory: pMemoize(
    (productId: number, start: string, end?: string) => {
      const searchParams = new URLSearchParams({ start });
      if (end) {
        searchParams.set("end", end);
      }
      const qs = searchParams.toString();

      const requestUrl = `${config.API_URL}/products/${productId}/chart/dump?${qs}`;
      return withAnalytics<{ priceHistory: number[] }>(
        {
          name: "getProductPriceHistory",
          event_category: "api_requests",
          event_label: requestUrl,
        },
        (error) => ({
          description: `[${error.code}] ${error.message}`,
          fatal: false,
        }),
        () =>
          request({
            method: "GET",
            headers: STANDARD_HEADERS,
            url: requestUrl,
          }),
        (response) => response.data,
      );
    },
    {
      cacheKey: (args) => args.join(","),
    },
  ),
};

export default dao;
