import { PropsWithChildren } from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../../../UI";

// default top nav height
export const TOP_NAV_HEIGHT = 50;

export type TopNavWrapperProps = PropsWithChildren<BoxProps>;

const TopNavWrapper = styled(Box)<TopNavWrapperProps>.attrs(() => ({
  as: "nav",
}))`
  z-index: 60;
  position: fixed;
  width: 100%;
`;
TopNavWrapper.defaultProps = {
  bg: "white",
  height: TOP_NAV_HEIGHT,
  borderBottom: "1px solid",
  borderColor: "mediumGrey",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  py: 0,
  px: 2,
};

export default TopNavWrapper;
