import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { QueryState } from "redux-query";
import { useMutation } from "redux-query-react";
import events, { trackEvent } from "../../../helpers/analyticsUtil";
import { sanitizePostInsert } from "../../../helpers/postUtil";
import { actionPromiseToPromise } from "../../../helpers/reactQueryUtil";
import { createPost as createPostQuery } from "../../../queries/api-v2/postQuery";
import { PostsAPI } from "../../../typings/API-V2";
import { ErrorResponse } from "../../../typings/API/common";
import { PostsById } from "../../../typings/EntitiesState";
import { Box, Text } from "../../../UI";

type UseCreatePost = (onCreated?: () => void) => [QueryState, (postInsert: PostsAPI.PostInsert) => Promise<void>];

/**
 * Hook that takes care of post creation and subsequent success or failure
 * @returns [QueryState, (postInsert: PostsAPI.PostInsert) => Promise<void>]
 */
const useCreatePost: UseCreatePost = (onCreated) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [queryState, createPost] = useMutation(createPostQuery);

  const onSubmit = useCallback(
    async (postInsert: PostsAPI.PostInsert) => {
      const sanitizedPostInsert = sanitizePostInsert(postInsert);
      const { body } = await actionPromiseToPromise<PostsById, PostsAPI.CreatePostResponse | ErrorResponse>(
        createPost(sanitizedPostInsert),
      );

      if (!body) {
        throw new Error("Created post not received");
      }

      if (!body.success) {
        if (body.error) {
          throw new Error(body.error);
        }
        throw new Error("Failed to create post");
      }

      const createdPost = body.data.post;

      if (onCreated) {
        onCreated();
      }

      /** analytics tracking */
      trackEvent(events.Post.Created, {
        type: postInsert.type,
      });

      /** display toast that links to created post */
      addToast(
        <Box display="flex" justifyContent="space-between" width="100%">
          <Text>Post created!</Text>
          {createdPost && (
            <Box
              role="button"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/posts/${createdPost.id}`);
              }}
            >
              <Text fontWeight={5}>VIEW</Text>
            </Box>
          )}
        </Box>,
        {
          appearance: "success",
          autoDismiss: true,
        },
      );
    },
    [addToast, createPost, history, onCreated],
  );

  return useMemo(() => [queryState, onSubmit], [queryState, onSubmit]);
};

export default useCreatePost;
