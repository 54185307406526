import { useMemo } from "react";
import { useMutation } from "redux-query-react";
import events, { trackEvent } from "../helpers/analyticsUtil";
import {
  createPostVote as createPostVoteQuery,
  deletePostVote as deletePostVoteQuery,
  updatePostVote as updatePostVoteQuery,
} from "../queries/api/postQuery";
import { PostsAPI } from "../typings/API-V2";
import useAuthenticatedCallback from "./useAuthenticatedCallback";

export interface UseVoteActionsOptions {
  onUpVote?: (vote?: number | null) => void;
  onDownVote?: (vote?: number | null) => void;
}

export type UsePostVoteActions = (
  post: PostsAPI.Post,
  options?: UseVoteActionsOptions,
) => {
  upVote: () => void;
  downVote: () => void;
};

const usePostVoteActions: UsePostVoteActions = (post, options) => {
  const currentVote = post.current_vote;
  /** TODO: handle pending vote cases */
  const [, createPostVote] = useMutation((vote: -1 | 1) => createPostVoteQuery(post.id, vote));
  const [, updatePostVote] = useMutation((vote: -1 | 1) => updatePostVoteQuery(post.id, vote));
  const [, deletePostVote] = useMutation((vote: -1 | 1) => deletePostVoteQuery(post.id, vote));
  // const analyticsEvent = isComment(post) ? events.Comment.Voted : events.Post.Voted;
  const analyticsEvent = events.Post.Voted;

  const upVote = useAuthenticatedCallback(() => {
    if (!currentVote) {
      // only track when vote is created
      trackEvent(analyticsEvent, {
        direction: "up",
      });
      // create vote
      createPostVote(1);
    } else if (currentVote === 1) {
      // delete vote
      deletePostVote(currentVote);
    } else {
      // update vote
      updatePostVote(1);
    }
    if (options?.onUpVote) {
      options.onUpVote(currentVote);
    }
  }, [createPostVote, updatePostVote, deletePostVote, currentVote]);

  const downVote = useAuthenticatedCallback(() => {
    if (!currentVote) {
      // only track when vote is created
      trackEvent(analyticsEvent, {
        direction: "down",
      });
      // create vote
      createPostVote(-1);
    } else if (currentVote === -1) {
      // delete vote
      deletePostVote(currentVote);
    } else {
      // update vote
      updatePostVote(-1);
    }
    if (options?.onDownVote) {
      options.onDownVote(currentVote);
    }
  }, [createPostVote, updatePostVote, deletePostVote, currentVote]);

  return useMemo(
    () => ({
      upVote,
      downVote,
    }),
    [upVote, downVote],
  );
};

export default usePostVoteActions;
