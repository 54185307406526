import Linkify from "linkify-react";
import React, { PropsWithChildren, useMemo } from "react";
import { TextProps, theme } from "../../UI";
import { LinkifyOptions } from "./LinkingWrapper";

export type LinkifyWrapperProps = TextProps & {
  tagName?: string;
  options: Omit<LinkifyOptions, "className">;
};

const LinkifyWrapper: React.FC<PropsWithChildren<LinkifyWrapperProps>> = ({ children, options, tagName }) => {
  const formattedOptions = useMemo<LinkifyOptions>(
    () => ({
      ...options,
      attributes: {
        ...options.attributes,
        /** apply default link text styles */
        style: {
          ...options.attributes?.style,
          color: theme.colors.anchorBlue,
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    }),
    [options],
  );

  return (
    <Linkify tagName={tagName} options={formattedOptions}>
      {children}
    </Linkify>
  );
};

export default LinkifyWrapper;
