import { AdditionalUserInfo, AuthError } from "@firebase/auth-types";
import { getAdditionalUserInfo, signInWithEmailAndPassword } from "firebase/auth";
import { every } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "reactfire";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import AuthFields from "../../enums/AuthFields";
import FirebaseErrors from "../../enums/FirebaseErrors";
import { authFieldToLabel } from "../../helpers/authFieldsUtil";
import useAutoFocusRefCallback from "../../hooks/useAutoFocusRefCallback";
import { FormProps, Loader } from "../../libs/semantic-ui";
import { Box, Button } from "../../UI";
import { AuthInput, InputWrapper } from "./AuthInput";

export interface LoginFields {
  password: string;
  email: string;
}

interface CustomLoginFormProps {
  onSubmitSuccess?: (additionalUserInfo?: AdditionalUserInfo | null) => void;
  onError: (error: string | undefined) => void;
}

export type LoginFormProps = CustomLoginFormProps & FormProps;

const LoginForm: React.FC<LoginFormProps> = ({ onSubmitSuccess, onError }) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<LoginFields>({
    password: "",
    email: "",
  });

  const [emailInputRefCallback] = useAutoFocusRefCallback<HTMLInputElement>();

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({
        ...fields,
        email: event.target.value,
      });
    },
    [setFields, fields],
  );

  const handlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({
        ...fields,
        password: event.target.value,
      });
    },
    [setFields, fields],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const userCredentials = await signInWithEmailAndPassword(auth, fields.email, fields.password);
      const userInfo = getAdditionalUserInfo(userCredentials);
      if (onSubmitSuccess) {
        onSubmitSuccess(userInfo);
      }
    } catch (error) {
      const { code, message } = error as AuthError;
      if (onError) {
        onError(FirebaseErrors[code] || message);
      }
    } finally {
      setLoading(false);
    }
  }, [fields, onSubmitSuccess, onError]);

  const canSubmit = useMemo(() => {
    return every([every(fields, (value) => Boolean(value)), isEmail(fields.email)]);
  }, [fields]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (canSubmit && (event.code === "Enter" || event.code === "NumpadEnter")) {
        handleSubmit();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [canSubmit, handleSubmit]);

  return (
    <>
      <Box width={"100%"}>
        <InputWrapper>
          <AuthInput
            ref={emailInputRefCallback}
            name={AuthFields.EMAIL}
            placeholder={authFieldToLabel[AuthFields.EMAIL]}
            type="email"
            value={fields.email}
            onChange={handleEmailChange}
            autoComplete="email"
          />
        </InputWrapper>
        <InputWrapper>
          <AuthInput
            placeholder={authFieldToLabel[AuthFields.PASSWORD]}
            type="password"
            name={AuthFields.PASSWORD}
            value={fields.password}
            onChange={handlePasswordChange}
            autoComplete="current-password"
          />
        </InputWrapper>
        <LoginButton
          variant={canSubmit ? "primary" : "disabled"}
          onClick={handleSubmit}
          size="big"
          disabled={!canSubmit}
        >
          <ButtonContent>{loading ? <Loader inverted active inline="centered" size="tiny" /> : "Log in"}</ButtonContent>
        </LoginButton>
      </Box>
    </>
  );
};

const ButtonContent = styled(Box)`
  height: 1.1em;
`;

ButtonContent.defaultProps = {
  justifyContent: "center",
  display: "flex",
};

const LoginButton = styled(Button)`
  width: 100%;
`;

export default LoginForm;
