import UAParser from "ua-parser-js";

const uaParser = new UAParser();
const uaResults = uaParser.getResult();

/** Device */
export const isMobile = uaResults.device.type === "mobile";
export const isTablet = uaResults.device.type === "tablet";

/** OS */
export const isIOS = uaResults.os.name === "iOS";
export const isAndroid = uaResults.os.name === "Android";
