import React from "react";
import { useSelector } from "react-redux";
import { useAuth, useSigninCheck } from "reactfire";
import { useRequest } from "redux-query-react";
import { Loader } from "../../libs/semantic-ui";
import { getFollows } from "../../queries/api/userFollowQuery";
import userProfileSelector from "../../selectors/userProfileSelector";
import { ButtonProps, TextButtonProps } from "../../UI";
import FollowButton from "../buttons/FollowButton";
import TextFollowButton from "../buttons/TextFollowButton";

interface FollowButtonProps extends ButtonProps {
  buttonType?: "normal";
  targetUserId: string;
  fluid?: boolean;
  size?: ButtonProps["size"];
  flat?: boolean;
}

interface TextFollowButtonProps extends TextButtonProps {
  buttonType: "text";
  targetUserId: string;
}

export type UserFollowButtonProps = FollowButtonProps | TextFollowButtonProps;

const isTextButtonProps = (props: UserFollowButtonProps): props is TextFollowButtonProps => props.buttonType === "text";

const UserFollowButton: React.FC<UserFollowButtonProps> = (props) => {
  const auth = useAuth();
  const { status } = useSigninCheck();
  const userId = auth.currentUser?.uid;
  const [{ isFinished }] = useRequest(auth.currentUser?.uid ? getFollows(auth.currentUser?.uid) : null);
  const isFollowee = useSelector(userProfileSelector.isFollowee(userId, props.targetUserId));
  const isFollower = useSelector(userProfileSelector.isFollower(userId, props.targetUserId));
  const isLoaded = status === "success";

  if (userId === props.targetUserId) {
    return <></>;
  }

  if (!isLoaded || (isLoaded && auth.currentUser?.uid && !isFinished)) {
    return <Loader inverted active inline="centered" size="tiny" />;
  }

  if (isTextButtonProps(props)) {
    return <TextFollowButton {...props} isFollowee={isFollowee} isFollower={isFollower} />;
  }

  const { flat, size, fluid, targetUserId, ...buttonProps } = props;
  return (
    <FollowButton
      {...buttonProps}
      flat={flat}
      isFollowee={isFollowee}
      isFollower={isFollower}
      size={size || "small"}
      targetUserId={targetUserId}
      width={fluid ? "100%" : "inherit"}
    />
  );
};

export default UserFollowButton;
