const moneyUtil = {
  formatCurrency: (
    amount: number,
    currency = "usd",
    options?: Omit<Intl.NumberFormatOptions, "style" | "currency">,
  ) => {
    return new Intl.NumberFormat("en-US", {
      ...options,
      style: "currency",
      currency: currency,
    }).format(amount);
  },
  centsToDollarString: (
    cents: number,
    currency = "usd",
    options?: Omit<Intl.NumberFormatOptions, "style" | "currency">,
  ) => {
    return moneyUtil.formatCurrency(cents / 100, currency, options);
  },
};

export default moneyUtil;
