import filter from "lodash-es/filter";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useCurrentUserProfile from "../../hooks/useCurrentUserProfile";
import useIsAdmin from "../../hooks/useIsAdmin";
import usePlugdAlgolia from "../../hooks/usePlugdAlgolia";
import usePlugdAlgoliaSearch from "../../hooks/usePlugdAlgoliaSearch";
import communitySelector from "../../selectors/communitySelector";
import { CommunitiesAPI } from "../../typings/API";
import PostsAPIV2 from "../../typings/API-V2/PostsAPIV2";
import { PlugdAlgoliaUser } from "../../typings/PlugdUser";
import { SearchInput } from "../../UI";
import List from "../../UI/components/List";
import { UserListSelectItem, UserListSelectItemPlaceholder } from "../../UI/components/UserList";
import UserMentionListSelectItem from "../../UI/components/UserList/UserMentionSelectItem";
import FullscreenMenu, { FullscreenMenuHeader, FullscreenMenuHeaderIcon } from "../Layout/FullscreenMenu";
import { defaultSearchOptions } from "../SearchMenu/SearchContentUsers";

export interface PostMentionMenuProps {
  close: () => void;
  isOpen: boolean;
  onSelect: (name: string) => void;
  community?: PostsAPIV2.CommunityDetails | CommunitiesAPI.Community | null;
}

const transitionProps = { animation: "fade left" };

const PostMentionMenu: React.FC<PostMentionMenuProps> = ({ isOpen, close, onSelect, community }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [, algolia] = usePlugdAlgolia();
  const { isPending, searchResults } = usePlugdAlgoliaSearch<PlugdAlgoliaUser>(
    algolia?.userIndex,
    searchValue,
    defaultSearchOptions,
  );

  const { profile } = useCurrentUserProfile();
  const { isAdmin } = useIsAdmin();
  const isModerator = useSelector(communitySelector.userIsModerator(profile?.id, community?.id));
  const isAuthorized = isModerator || isAdmin;

  useEffect(() => {
    if (isOpen && ref.current) {
      ref.current.focus();
    }
  }, [isOpen]);

  const clearInput = useCallback(() => {
    setSearchValue("");
  }, [setSearchValue]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.currentTarget.value);
    },
    [setSearchValue],
  );

  const handleUserSelect = useCallback<(username: string) => void>(
    (username) => {
      onSelect(username);
      clearInput();
      close();
    },
    [onSelect, clearInput, close],
  );

  const filteredSearchResults = filter(searchResults, (u) => u.username !== "everyone");

  return (
    <FullscreenMenu
      close={close}
      isOpen={isOpen}
      transition={transitionProps}
      bg="lightGrey"
      pt={3}
      header={
        <FullscreenMenuHeader>
          <FullscreenMenuHeaderIcon onClick={close} name="chevron left" containerProps={{ flexGrow: 0 }} />
          <SearchInput
            ref={ref}
            value={searchValue}
            onClear={clearInput}
            placeholder={"Mention a friend"}
            onChange={handleChange}
          />
        </FullscreenMenuHeader>
      }
    >
      <List isFinished={!isPending} width="100%" px={3} placeholderEl={<UserListSelectItemPlaceholder />}>
        {community?.id && isAuthorized && (
          <UserMentionListSelectItem
            onClick={() => handleUserSelect("everyone")}
            title="everyone"
            subtitle="This will notify all members in this community"
          />
        )}
        {filteredSearchResults.map((u) => (
          <UserListSelectItem
            key={u.objectID}
            onClick={() => handleUserSelect(u.username)}
            profile={u}
            metaProps={{ disableLink: true }}
          />
        ))}
      </List>
    </FullscreenMenu>
  );
};

export default PostMentionMenu;
