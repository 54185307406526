import { AdditionalUserInfo } from "@firebase/auth-types";
import React, { useCallback, useContext, useState } from "react";
import { Message } from "../../libs/semantic-ui";
import { Box, Text } from "../../UI";
import AuthScreenContentHeader from "../AuthScreenModal/AuthScreenContentHeader";
import AuthScreensContext from "../AuthScreenModal/AuthScreensContext";
import usePostAuthentication from "./hooks/usePostAuthentication";
import LoginForm from "./LoginForm";

export interface EmailLoginContentProps {
  onClose?: () => void;
  onResetPassword?: () => void;
}

const EmailLoginContent: React.FC<EmailLoginContentProps> = ({ onClose, onResetPassword }) => {
  const [err, setErr] = useState<string | undefined>();
  const { setAuthScreen, info, setInfo, onAuthenticated } = useContext(AuthScreensContext);
  const postAuthentication = usePostAuthentication();

  const onSubmitSuccess = useCallback(
    (additionalUserInfo?: AdditionalUserInfo | null) => {
      setInfo(null);
      if (err) {
        setErr(undefined);
      }
      postAuthentication(additionalUserInfo);
      if (additionalUserInfo?.isNewUser) {
        setAuthScreen("createUsername");
      } else {
        if (onAuthenticated) {
          onAuthenticated();
        }
        if (onClose) {
          onClose();
        }
      }
    },
    [setInfo, err, postAuthentication, setAuthScreen, onClose, onAuthenticated],
  );

  const handleResetPasswordClick = useCallback(() => {
    if (onResetPassword) {
      onResetPassword();
    } else {
      setAuthScreen("resetPassword");
    }
  }, [onResetPassword, setAuthScreen]);

  return (
    <>
      <AuthScreenContentHeader
        title={"Enter your email address and password"}
        description={
          <>
            <Text>{"Don't have an account yet? "}</Text>
            <Text color="blue" clickable onClick={() => setAuthScreen("emailSignupEmail")}>
              {"Sign up"}
            </Text>
          </>
        }
      />
      <LoginForm onError={setErr} onSubmitSuccess={onSubmitSuccess} />
      <Box display="flex" justifyContent="center">
        <Text clickable={true} fontSize={0} mt={3} onClick={handleResetPasswordClick} textDecoration="underline">
          I forgot my password
        </Text>
      </Box>
      {err && <Message error content={err} />}
      {info && <Message success content={info} />}
    </>
  );
};

export default EmailLoginContent;
