import React, { useCallback } from "react";
import styled from "styled-components";
import { Box, Button } from "../../UI";

export interface DesktopBackToTopButtonProps {}

const DesktopBackToTopButton: React.FC<DesktopBackToTopButtonProps> = () => {
  const onClick = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <StickyContainer>
      <Button variant="secondary" size="small" onClick={onClick}>{`Back to Top`}</Button>
    </StickyContainer>
  );
};

const StickyContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: calc(100vh - ${({ theme }) => theme.space[2]}px);
  transform: translateY(-100%);
`;
StickyContainer.defaultProps = {
  mt: 6,
};

export default DesktopBackToTopButton;
