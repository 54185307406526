import React from "react";
import { SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import { Box, Icon, NavLink, NavLinkProps, Text, theme } from "../../UI";

export type BottomNavIconLinkProps = {
  pathname: string;
  search?: string;
  iconName: SemanticICONS;
  label: string;
  hasNotification?: boolean;
} & Omit<NavLinkProps, "to">;

const BottomNavIconLink: React.FC<BottomNavIconLinkProps> = ({
  hasNotification,
  iconName,
  label,
  pathname,
  search,
  ...navLinkProps
}) => {
  return (
    <NavLink
      activeStyle={{ color: theme.colors.black }}
      alignItems="center"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexBasis={0}
      justifyContent="center"
      style={{ color: theme.colors.mediumGrey }}
      to={{
        pathname,
        search,
        state: { fromBottomNav: true },
      }}
      {...navLinkProps}
    >
      <Box position="relative">
        {hasNotification && <NotificationBadge bg="notificationRed" />}
        <Icon size="large" name={iconName} />
      </Box>
      <IconLabel>{label}</IconLabel>
    </NavLink>
  );
};

const IconLabel = styled(Text)`
  margin-top: 4px;
  font-size: 9px;
  line-height: 9px;
`;

const BADGE_SIZE = 8;
const NotificationBadge = styled(Box)`
  z-index: 10;
  height: ${BADGE_SIZE}px;
  width: ${BADGE_SIZE}px;
  border-radius: ${BADGE_SIZE / 2}px;
  position: absolute;
  top: 0px;
  right: -${BADGE_SIZE / 4}px;
`;

export default BottomNavIconLink;
