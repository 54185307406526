import React from "react";
import styled from "styled-components";
import { MAX_TITLE_LENGTH } from "../../helpers/postUtil";
import { Box, Text } from "../../UI";
import ContentTextarea, { ContentTextareaProps } from "./ContentTextarea";

export interface CreatePostTitleInputProps extends ContentTextareaProps {
  value: string;
  error?: string;
}

const inputStyle = { fontWeight: 600 };

const CreatePostTitleInput = React.forwardRef<HTMLTextAreaElement, CreatePostTitleInputProps>(
  ({ onChange, value, placeholder, error }, ref) => {
    let errorMessage = error || "";
    if (value.length > MAX_TITLE_LENGTH) {
      errorMessage = `The title is ${value.length - MAX_TITLE_LENGTH} characters too long`;
    }
    return (
      <Box p={3} borderBottom="1px solid" borderColor="lightGrey">
        <ContentTextarea
          async
          onChange={onChange}
          placeholder={placeholder || "An interesting title"}
          ref={ref}
          style={inputStyle}
          value={value}
        />
        {errorMessage && (
          <WordCountWrapper mt={1}>
            <Text color="red" fontSize={0} fontWeight={6}>
              {errorMessage || error}
            </Text>
          </WordCountWrapper>
        )}
      </Box>
    );
  },
);
CreatePostTitleInput.displayName = "CreatePostTitleInput";

const WordCountWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`;

export default CreatePostTitleInput;
