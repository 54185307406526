import React from "react";

export type AuthScreen =
  | "login"
  | "signup"
  | "resetPassword"
  | "createUsername"
  | "phoneInput"
  | "phoneConfirm"
  | "emailLogin"
  | "emailSignupEmail"
  | "emailSignupPassword"
  | "onboardFollowProducts"
  | "onboardFollowUsers";

const AuthScreensContext = React.createContext<{
  info: string | null;
  onAuthenticated?: () => void;
  screen: AuthScreen | null;
  setAuthScreen: (screen: AuthScreen | null) => void;
  setInfo: (infoMessage: string | null) => void;
  setOnAuthenticated: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}>({
  info: null,
  screen: null,
  setAuthScreen: () => {},
  setInfo: () => {},
  setOnAuthenticated: () => {},
});

export default AuthScreensContext;
