import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { actionPromiseToPromise } from "../helpers/reactQueryUtil";
import { getUserProfile } from "../queries/api/userProfileQuery";
import { UserProfileIdType, UsersAPI } from "../typings/API";
import useActions from "./useActions";
import useControlledRequest from "./useControlledRequest";
import useQuery from "./useQuery";

const usePlugdReferralTracker = (): void => {
  const match = useRouteMatch<{ usernameOrId?: string }>("/u/:usernameOrId");
  const queryParams = useQuery();
  const [, getReferrerProfile] = useControlledRequest(getUserProfile);
  const { setPlugdReferrerId } = useActions();

  useEffect(() => {
    if (match?.params.usernameOrId) {
      const idType = queryParams.get("idType") as UserProfileIdType | undefined;
      actionPromiseToPromise(getReferrerProfile(match.params.usernameOrId, idType || UserProfileIdType.Username)).then(
        (response) => {
          const profile = response.body?.profile as UsersAPI.PublicProfile | undefined;
          if (profile?.id) {
            setPlugdReferrerId(response.body.profile.id);
          }
        },
      );
    }
  }, []); // purposely leave this empty. Should only check when app is first initialized.
};

export default usePlugdReferralTracker;
