import React, { useMemo } from "react";
import { retry } from "ts-retry-promise";
import { isWeb } from "../../config";
import { Box, IconProps, Link, Text } from "../../UI";
import FooterItem from "./FooterItem";
import SocialMediaFooter from "./SocialMediaFooter";

const AppBanners = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "AppBanner" */ "../AppBanners"), { retries: 5 }),
);

export interface FooterContentProps {
  iconColor?: IconProps["color"];
}

const FooterContent: React.FC<FooterContentProps> = ({ iconColor }) => {
  const items = useMemo<
    Array<{
      title: string;
      link?: string;
      subItems?: {
        title: string;
        link?: string;
        href?: string;
        onClick?: () => void;
      }[];
    }>
  >(
    () => [
      {
        title: "Air Jordan",
        link: `/products/brands/air-jordan`,
        subItems: [
          {
            title: "Air Jordan 1",
            link: "/products/brands/air-jordan?silhouettes=Air+Jordan+1",
          },
          {
            title: "Air Jordan 2",
            link: "/products/brands/air-jordan?silhouettes=Air+Jordan+2",
          },
          {
            title: "Air Jordan 3",
            link: "/products/brands/air-jordan?silhouettes=Air+Jordan+3",
          },
          {
            title: "Air Jordan 4",
            link: "/products/brands/air-jordan?silhouettes=Air+Jordan+4",
          },
          {
            title: "Air Jordan 5",
            link: "/products/brands/air-jordan?silhouettes=Air+Jordan+5",
          },
          {
            title: "Air Jordan 11",
            link: "/products/brands/air-jordan?silhouettes=Air+Jordan+11",
          },
        ],
      },
      {
        title: "Yeezy",
        link: `/products/brands/yeezy`,
        subItems: [
          {
            title: "Yeezy Slides",
            link: `/products/brands/yeezy?silhouettes=Yeezy+Slides`,
          },
          {
            title: "Yeezy Boost 350",
            link: `/products/brands/yeezy?silhouettes=Yeezy+Boost+350`,
          },
          {
            title: "Yeezy Boost 380",
            link: `/products/brands/yeezy?silhouettes=Yeezy+Boost+380`,
          },
          {
            title: "Yeezy Boost 500",
            link: "/products/brands/yeezy?silhouettes=Yeezy+500",
          },
          {
            title: "Yeezy Boost 700",
            link: "/products/brands/yeezy?silhouettes=Yeezy+Boost+700",
          },
          {
            title: "Yeezy Foam Runner",
            link: "/products/brands/yeezy?silhouettes=Yeezy+Foam+Runner",
          },
        ],
      },
      {
        title: "More Brands",
        link: "/products/brands",
        subItems: [
          {
            title: "Nike",
            link: `/products/brands/nike`,
          },
          {
            title: "adidas",
            link: `/products/brands/adidas`,
          },
          {
            title: "New Balance",
            link: `/products/brands/new-balance`,
          },
          {
            title: "Converse",
            link: `/products/brands/converse`,
          },
          {
            title: "Vans",
            link: `/products/brands/vans`,
          },
          {
            title: "Puma",
            link: `/products/brands/puma`,
          },
        ],
      },
      {
        title: "Plugd",
        link: `/u/plugd`,
        subItems: [
          {
            title: "Discover",
            link: `/discover`,
          },
          {
            title: "Release Calendar",
            link: `/release-calendar`,
          },
          {
            title: "Product Raffles",
            link: `/raffles`,
          },
          {
            title: "Leaderboard",
            link: `/leaderboard`,
          },
          {
            title: "Blog",
            href: `https://getplugd.com/blog`,
          },
          {
            title: "Help",
            link: `/c/help`,
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      {items.map(({ title, link, subItems }) => (
        <Box key={title} display="flex" flexDirection="column">
          <Box as={link ? Link : undefined} to={link ? link : undefined}>
            <Text fontWeight={5}>{title}</Text>
          </Box>
          {subItems ? subItems.map((subItemProps) => <FooterItem key={subItemProps.title} {...subItemProps} />) : null}
        </Box>
      ))}
      <Box>
        <Text fontWeight={5}>Socials</Text>
        <SocialMediaFooter iconColor={iconColor} />
      </Box>
      {isWeb && (
        <Box>
          <Text fontWeight={5}>Mobile App</Text>
          <AppBanners />
        </Box>
      )}
    </>
  );
};

export default FooterContent;
