import React, { useCallback, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { isImagePost, isLinkPost, isListingPost, isTextPost } from "../../helpers/postUtil";
import useAuthenticatedCallback from "../../hooks/useAuthenticatedCallback";
import { PostsAPI, PostTypeKey } from "../../typings/API-V2";
import CreateImagePostMenu from "./CreateImagePostMenu";
import EditImagePostMenu from "./CreateImagePostMenu/EditImagePostMenu";
import CreateLinkPostMenu from "./CreateLinkPostMenu";
import CreateListingPostMenu from "./CreateListingPostMenu";
import CreateTextPostMenu from "./CreateTextPostMenu";
import PostMenuContext, { PostMenuContextValue, PostMenuState } from "./PostMenuContext";

export interface PostMenuProviderProps {}

const PostMenuProvider: React.FC<React.PropsWithChildren<PostMenuProviderProps>> = ({ children }) => {
  /** TODO: Convert this to only use one state that tracks edit / create state */
  const [postMenu, setPostMenu] = useImmer<PostMenuState>({
    type: null,
  });
  const [editPost, setEditPost] = useState<PostsAPI.Post | null>(null);

  /** wrap setPostType in authentication requirement */
  const authenticatedSetPostMenuState = useAuthenticatedCallback<PostMenuContextValue["setPostMenu"]>(
    (postStateOrType) => {
      if (typeof postStateOrType === "string" || postStateOrType === null) {
        setPostMenu((draft) => {
          draft.type = postStateOrType;
        });
        return;
      }

      setPostMenu(postStateOrType);
    },
    [],
  );

  /**
   * this is a wrapper and convenience method when the user doesn't need to pass in any other options
   * and also adds backwards compatibility
   */
  const authenticatedSetPostType = useCallback<PostMenuContextValue["setPostType"]>(
    (postType) => authenticatedSetPostMenuState(postType),
    [authenticatedSetPostMenuState],
  );

  /** wrap setPostType in authentication requirement */
  const authenticatedSetEditPost = useAuthenticatedCallback<React.Dispatch<React.SetStateAction<PostsAPI.Post | null>>>(
    (postType) => setEditPost(postType),
    [],
  );

  const value = useMemo<PostMenuContextValue>(
    () => ({
      editPost,
      postType: postMenu.type,
      setEditPost: authenticatedSetEditPost,
      setPostMenu: authenticatedSetPostMenuState,
      setPostType: authenticatedSetPostType,
    }),
    [authenticatedSetPostMenuState, authenticatedSetEditPost, authenticatedSetPostType, postMenu.type, editPost],
  );

  const closeMenu = useCallback(() => {
    setPostMenu({
      type: null,
    });
    setEditPost(null);
  }, [setPostMenu]);

  /**
   * TODO: Refactor and combine menus
   */
  const displayedMenu = useMemo<JSX.Element | null>(() => {
    if (editPost) {
      /** NOTE: isListingPost needs to be be above image post  */
      if (isListingPost(editPost)) {
        return <CreateListingPostMenu isOpen={true} close={closeMenu} editPost={editPost} />;
      }
      if (isTextPost(editPost)) {
        return <CreateTextPostMenu isOpen={true} close={closeMenu} editPost={editPost} />;
      }
      if (isImagePost(editPost)) {
        return <EditImagePostMenu isOpen={true} close={closeMenu} editPost={editPost} />;
      }
      if (isLinkPost(editPost)) {
        return <CreateLinkPostMenu isOpen={true} close={closeMenu} editPost={editPost} />;
      }
    }
    if (postMenu.type === PostTypeKey.Text) {
      return <CreateTextPostMenu {...postMenu.props} isOpen={true} close={closeMenu} />;
    }
    if (postMenu.type === PostTypeKey.Image) {
      return <CreateImagePostMenu {...postMenu.props} isOpen={true} close={closeMenu} />;
    }
    if (postMenu.type === PostTypeKey.Link) {
      return <CreateLinkPostMenu {...postMenu.props} isOpen={true} close={closeMenu} />;
    }
    if (postMenu.type === PostTypeKey.Listing) {
      return <CreateListingPostMenu {...postMenu.props} isOpen={true} close={closeMenu} />;
    }
    return null;
  }, [postMenu, editPost, closeMenu]);

  return (
    <PostMenuContext.Provider value={value}>
      {children}
      {displayedMenu}
    </PostMenuContext.Provider>
  );
};

export default PostMenuProvider;
