import { useEffect, useMemo, useState } from "react";
import { EntitiesState } from "redux-query";
import { actionPromiseToPromise } from "../helpers/reactQueryUtil";
import { getCheckoutSession as getCheckoutSessionQuery } from "../queries/api/stripeQuery";
import { StripeAPI, UsersAPI } from "../typings/API";
import useControlledRequest from "./useControlledRequest";

const useCheckoutSession = (
  sessionId?: string | null,
): {
  isFinished: boolean;
  isPending: boolean;
  checkoutSession: StripeAPI.CheckoutSession | null;
  sellers: UsersAPI.PublicProfile[];
} => {
  const [sellers, setSellers] = useState<UsersAPI.PublicProfile[]>([]);
  const [checkoutSession, setCheckoutSession] = useState<StripeAPI.CheckoutSession | null>(null);
  const [{ isFinished, isPending }, getCheckoutSession] = useControlledRequest(getCheckoutSessionQuery);

  useEffect(() => {
    const handleSession = async (sessId: string) => {
      const { body } = await actionPromiseToPromise<
        Pick<EntitiesState, "stripeCheckoutSessionsById" | "userProfilesById" | "userProfileIdsByUsername">,
        StripeAPI.GetCheckoutSessionResponse
      >(getCheckoutSession(sessId));
      if (body?.data) {
        // set sellers
        setSellers(body.data.sellers);
        // set session
        setCheckoutSession(body.data.session);
      }
    };
    if (sessionId) {
      handleSession(sessionId);
    }
  }, [getCheckoutSession, sessionId]);

  return useMemo(
    () => ({
      isFinished,
      isPending,
      sellers,
      checkoutSession,
    }),
    [sellers, checkoutSession, isFinished, isPending],
  );
};

export default useCheckoutSession;
