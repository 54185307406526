import React, { ReactNode } from "react";
import styled from "styled-components";
import { PostsAPI } from "../../typings/API-V2";
import { Box, Label, LabelProps, Spacer } from "../../UI";
import PostMeta from "./PostMeta";
import PostUrl from "./PostUrl";

export interface PostHeaderProps {
  post?: PostsAPI.Post;
  subHeader?: ReactNode;
  hideActions?: boolean;
  onThemeClick?: (themeKey: string, event: React.MouseEvent<HTMLElement>, data: LabelProps) => void;
  postTitle: ReactNode;
  /**
   * hides the community metadata
   * @default true
   */
  hideCommunity?: boolean;
  /**
   * shows if the post is pinned
   */
  showPinned?: boolean;
}

const PostHeader: React.FC<PostHeaderProps> = ({
  hideActions,
  hideCommunity = true,
  onThemeClick,
  post,
  postTitle,
  showPinned = false,
  subHeader,
}) => {
  const theme = post?.theme;

  if (!post?.title) {
    return null;
  }

  const content = (
    <>
      <Box>
        <PostMeta post={post} hideActions={hideActions} hideCommunity={hideCommunity} showPinned={showPinned} />
        <Spacer mt={2} />
        {postTitle}
        {post.url && <PostUrl url={post.url} my={2} />}
        {!hideActions && theme && (
          <>
            <Spacer mt={2} />
            <ThemeLabel
              onClick={
                onThemeClick
                  ? (event: React.MouseEvent<HTMLElement>, data: LabelProps) => onThemeClick(theme.key, event, data)
                  : undefined
              }
            >
              {theme.name}
            </ThemeLabel>
          </>
        )}
      </Box>
    </>
  );

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {content}
      {subHeader ? subHeader : null}
    </Box>
  );
};

const ThemeLabel = styled(Label)<LabelProps>`
  ${({ onClick }) => (onClick ? `cursor: pointer;` : "")}
`;
ThemeLabel.defaultProps = {
  color: "black",
  bg: "lightGrey",
  size: "mini",
  role: "button",
};

export default PostHeader;
