import React from "react";
import { Radio } from "../../../libs/semantic-ui";
import { Box, BoxProps, Text } from "../../../UI";
import MenuItem from "./MenuItem";

export interface RadioMenuItemProps {
  onClick: BoxProps["onClick"];
  isChecked: boolean;
  name: string;
  description?: string;
}

const RadioMenuItem: React.FC<RadioMenuItemProps> = ({ onClick, isChecked, name, description }) => (
  <MenuItem onClick={onClick}>
    <Radio checked={isChecked} />
    <Box ml={2}>
      <Box color="black">
        <Text fontSize={1} fontWeight={5}>
          {name}
        </Text>
      </Box>
      {description && (
        <Box color="darkGrey">
          <Text fontSize={0}>{description}</Text>
        </Box>
      )}
    </Box>
  </MenuItem>
);

export default RadioMenuItem;
