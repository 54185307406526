import { some, toLower } from "lodash-es";
import React from "react";
import reactStringReplace from "react-string-replace";
import { Link, Text } from "../../../UI";

/**
 * IMPORTANT
 * =========
 *
 * About RegEx patterns:
 *
 * https://github.com/iansinnott/react-string-replace#match
 * > NOTE: When using a RegExp you MUST include a capturing group. (/(hey)/g is ok, /hey/g is not.)
 */

/**
 * Detects community slug mentions
 *
 * Allowed characters
 *   - Alphanumeric (lower and uppercase)
 *   - Symbols ("_")
 * Positive examples:
 *   c/Sneakers
 *   c/sneaker_head
 * Negative examples:
 *   c/sneaker-head (no dashes)
 *   c/sneaker/head (no slashes)
 *   c/sneaker.head (no periods)
 */
const communityRegEx = /(\bc\/[a-zA-Z0-9_]{3,})/gm;
export const communityStringReplace = (text: string | React.ReactNodeArray) =>
  reactStringReplace(text, communityRegEx, (match, i) => (
    <Link key={i} to={`/${match}`} color="anchorBlue">
      {match.toString()}
    </Link>
  ));

/**
 * Detects usernames mentions
 *
 * Do's:
 *   - Alphanumeric (lower and uppercase)
 *   - Symbols ("_", ".")
 * Dont's:
 *   - Start with "."
 *   - End with "."
 */
export const userRegEx = /(\B@[a-z0-9_][a-z0-9_.]+[a-z0-9_])/gm;
export const userStringReplace = (text: string | React.ReactNodeArray) =>
  reactStringReplace(text, userRegEx, (match, i) => {
    const username = match.substring(1);
    return username !== "everyone" ? (
      <Link key={i} to={`/u/${username}`} color="anchorBlue">
        {match.toString()}
      </Link>
    ) : (
      <Text color="anchorBlue">{match.toString()}</Text>
    );
  });

export const hasGroupMentions = (input?: string | null): boolean => {
  if (!input) {
    return false;
  }
  const mentions = toLower(input).match(userRegEx);
  return some(mentions, (mention) => mention === "@everyone");
};
