import React, { ReactNode } from "react";
import { useSigninCheck } from "reactfire";

export interface AdminProps {
  children: ReactNode;
}

const Admin: React.FC<React.PropsWithChildren<AdminProps>> = ({ children }) => {
  const { data } = useSigninCheck({
    requiredClaims: {
      role: "admin",
    },
  });
  const isAdmin = data?.hasRequiredClaims;
  if (!isAdmin) {
    return null;
  }
  return <>{children}</>;
};

export default Admin;
