import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { hasGroupMentions } from "../../links/utils/mentionDetect";
import {
  sanitizePostInsert,
  sanitizePostPatch,
  validatePostInsert,
  validatePostPatch,
} from "../../../helpers/postUtil";
import useCurrentUserProfile from "../../../hooks/useCurrentUserProfile";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { MenuProps } from "../../../hooks/useMenuState";
import communitySelector from "../../../selectors/communitySelector";
import { CommunitiesAPI } from "../../../typings/API";
import { PostsAPI, PostTypeKey } from "../../../typings/API-V2";
import CommunityRules from "../CommunityRules";
import CreatePostMenuGeneric, { PostDataMeta, PostInsertDataMeta, PostPatchDataMeta } from "../CreatePostMenuGeneric";
import useCommunitySelectorInput from "../hooks/useCommunitySelectorInput";
import useLinkInput, { UseLinkInputOptions } from "../hooks/useLinkInput";
import useThemeSelectorInput, { UseThemeSelectorInputOptions } from "../hooks/useThemeSelectorInput";
import useTitleInput from "../hooks/useTitleInput";
import MentionDetails from "../MentionDetails";
import PostMenuNavTabs from "../PostMenuNavTabs";

export interface CreateLinkPostMenuProps extends MenuProps {
  defaultTheme?: PostsAPI.PostThemeKey;
  editPost?: PostsAPI.LinkPost;
  onCreated?: () => void;
}

const linkOptions: UseLinkInputOptions = { placeholder: "Url" };

const CreateLinkPostMenu: React.FC<CreateLinkPostMenuProps> = ({ close, isOpen, editPost, onCreated }) => {
  const {
    input: communityInput,
    menus: communityMenus,
    value: community,
    clearValue: clearCommunity,
  } = useCommunitySelectorInput(editPost?.community.slug || undefined);
  const {
    input: titleInput,
    menus: titleMenus,
    value: title,
    clearValue: clearTitle,
  } = useTitleInput({ defaultValue: editPost?.title, community: community });
  const {
    input: linkInput,
    menus: linkMenus,
    value: url,
    clearValue: clearUrl,
  } = useLinkInput({ ...linkOptions, defaultValue: editPost?.url || editPost?.to_route || undefined });
  const themeInputOptions = useMemo<UseThemeSelectorInputOptions>(
    () => ({
      communityId: community?.id,
      defaultThemeId: editPost?.theme?.id || undefined,
      postTypeKey: PostTypeKey.Link,
    }),
    [community?.id, editPost?.theme?.id],
  );
  const {
    input: themeInput,
    menus: themeMenus,
    value: themeId,
    clearValue: clearTheme,
  } = useThemeSelectorInput(themeInputOptions);

  const clearFields = useCallback(() => {
    clearCommunity();
    clearTitle();
    clearTheme();
    clearUrl();
  }, [clearCommunity, clearTitle, clearUrl, clearTheme]);

  const { profile } = useCurrentUserProfile();
  const { isAdmin } = useIsAdmin();
  const isModerator = useSelector(communitySelector.userIsModerator(profile?.id, community?.id));
  const isAuthorized = isModerator || isAdmin;

  // const hasGroupMention = useMemo<boolean>(() => {
  //   const groupMentions = title.match(userRegEx);
  //   return groupMentions && groupMentions.length > 0 ? true : false;
  // }, [title]);

  const postDataMeta = useMemo<PostDataMeta>(
    () =>
      editPost
        ? createPostPatch({
            editPost,
            community,
            themeId,
            url,
            title,
            isAuthorized,
          })
        : createPostInsert({
            community,
            themeId,
            url,
            title,
            isAuthorized,
          }),
    [editPost, community, themeId, url, title, isAuthorized],
  );

  const closeMenu = useCallback(() => {
    close();
    /** reset entire menu state when closed */
    clearFields();
  }, [close, clearFields]);

  return (
    <>
      {communityMenus}
      {titleMenus}
      {linkMenus}
      {themeMenus}
      <CreatePostMenuGeneric close={closeMenu} isOpen={isOpen} onCreated={onCreated} postDataMeta={postDataMeta}>
        {!editPost && <PostMenuNavTabs activeTab={PostTypeKey.Link} />}
        {communityInput}
        {community && (
          <>
            {titleInput}
            {linkInput}
            {themeInput}
          </>
        )}
        <CommunityRules />
        <MentionDetails
          show={hasGroupMentions(title)}
          isAuthorized={isAuthorized}
          authorizedText={`This will notify everyone in c/${community?.slug}`}
          unAuthorizedText={"You don't have permission to mention @everyone"}
        />
      </CreatePostMenuGeneric>
    </>
  );
};

const createPostInsert = (options: {
  community: CommunitiesAPI.Community | null;
  themeId: number | null;
  title: string;
  url: string | null;
  isAuthorized: boolean;
}): PostInsertDataMeta => {
  const { title, community, url, themeId, isAuthorized } = options;
  const postInsert: PostsAPI.PostInsert = {
    community_id: community?.id,
    content: null,
    images: null,
    product_listing: null,
    theme_id: themeId,
    theme_key: null,
    title,
    type: PostTypeKey.Link,
    url: url && url[0] !== "/" ? url : null,
    to_route: url && url[0] === "/" ? url : null,
  };

  /** sanitize creation object */
  const sanitizedPostInsert = sanitizePostInsert(postInsert);

  /** validate creation object */
  const postInsertValidation = validatePostInsert(sanitizedPostInsert, isAuthorized);
  if (!postInsertValidation.isValid) {
    /** capture error */
    return {
      type: "post",
      canSubmit: false,
      message: postInsertValidation.message,
    };
  }

  return {
    type: "post",
    canSubmit: true,
    postData: sanitizedPostInsert,
  };
};

const createPostPatch = (options: {
  editPost: PostsAPI.LinkPost;
  community: CommunitiesAPI.Community | null;
  themeId: number | null;
  title: string;
  url: string | null;
  isAuthorized: boolean;
}): PostPatchDataMeta => {
  const { editPost, title, community, url, themeId, isAuthorized } = options;

  if (!editPost) {
    return {
      type: "patch",
      canSubmit: false,
      message: "Original post is missing",
    };
  }

  if (!community) {
    return {
      type: "patch",
      canSubmit: false,
      message: "A community must be selected",
    };
  }

  const postPatch: PostsAPI.PostPatch = {
    id: editPost.id,
    community_id: community.id,
    theme_id: themeId,
    is_archived: editPost.is_archived,
    title,
    to_route: url && url[0] === "/" ? url : null,
    url: url && url[0] !== "/" ? url : null,
  };

  /** sanitize creation object */
  const sanitizedPostPatch = sanitizePostPatch(postPatch);

  /** validate creation object */
  const postPatchValidation = validatePostPatch(sanitizedPostPatch, isAuthorized);
  if (!postPatchValidation.isValid) {
    /** capture error */
    return {
      type: "patch",
      canSubmit: false,
      message: postPatchValidation.message,
    };
  }

  return {
    type: "patch",
    canSubmit: true,
    postData: sanitizedPostPatch,
  };
};

export default CreateLinkPostMenu;
