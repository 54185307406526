import React from "react";
import styled from "styled-components";
import { Box, Icon } from "../../../UI";

export interface CheckboxProps {
  isSelected: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ isSelected }) => {
  return (
    <IconWrapper>
      <Icon color="gold" name={isSelected ? "check circle outline" : "circle outline"} />
    </IconWrapper>
  );
};

const IconWrapper = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  margin-left: auto;
`;

export default Checkbox;
