import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSigninCheck, useUser } from "reactfire";
import { QueryState } from "redux-query";
import { useRequest } from "redux-query-react";
import { getCommunityList, getUserCommunityRoles, getUserCommunitySubscriptions } from "../queries/api/communityQuery";
import communitySelector from "../selectors/communitySelector";
import { CommunitiesAPI } from "../typings/API";

interface CommunityResponse extends QueryState {
  subscribedCommunities: CommunitiesAPI.Community[];
  unsubscribedCommunities: CommunitiesAPI.Community[];
  moderatedCommunities: CommunitiesAPI.Community[];
}

const useCommunities = (): CommunityResponse => {
  const { data: currentUser } = useUser();
  const { status: signInStatus } = useSigninCheck();
  const uid = currentUser?.uid;
  const [subscriptionRequestStatus] = useRequest(uid ? getUserCommunitySubscriptions(uid) : null);
  const [roleRequestStatus] = useRequest(uid ? getUserCommunityRoles(uid) : null);
  const [listRequestStatus] = useRequest(signInStatus !== "loading" ? getCommunityList({}) : null);
  const subscribedCommunities = useSelector(communitySelector.subscribedCommunities(uid));
  const moderatedCommunities = useSelector(communitySelector.allModeratedCommunities(uid));
  const unsubscribedCommunities = useSelector(communitySelector.allUnsubscribedCommunities(uid));

  const isPending = roleRequestStatus.isPending || subscriptionRequestStatus.isPending || listRequestStatus.isPending;
  const isFinished =
    roleRequestStatus.isFinished && subscriptionRequestStatus.isFinished && listRequestStatus.isFinished;

  return useMemo(
    () => ({
      isFinished,
      isPending,
      subscribedCommunities,
      moderatedCommunities,
      unsubscribedCommunities,
    }),
    [subscribedCommunities, moderatedCommunities, unsubscribedCommunities, isPending, isFinished],
  );
};

export default useCommunities;
