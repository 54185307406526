import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useRequest } from "redux-query-react";
import styled from "styled-components";
import { CommunitySlug } from "../../../enums/Community";
import useMenuState from "../../../hooks/useMenuState";
import { getCommunity } from "../../../queries/api/communityQuery";
import { RootState } from "../../../store";
import { CommunitiesAPI } from "../../../typings/API";
import { Box, Icon, Text } from "../../../UI";
import { CommunityListSelectItemPlaceholder } from "../../../UI/components/CommunityList";
import CommunityMeta from "../../../UI/components/CommunityList/CommunityMeta";
import CommunitySelectorMenu, { CommunitySelectorMenuProps } from "../../CommunitySelectorMenu";
import { UseInputHook } from "../types/inputHooks";

const useCommunitySelectorInput: UseInputHook<CommunitiesAPI.Community | null, string | undefined> = (
  defaultCommunitySlug,
) => {
  const match = useRouteMatch<{ communitySlug?: string }>(`/c/:communitySlug`);
  /**
   * Resolve a default slug by
   *   1. Checking the default slug argument
   *   2. Searching for a community slug from the url search params
   *   3. Falling back to the default "sneakers"
   */
  const resolveDefaultSlug = defaultCommunitySlug || match?.params.communitySlug || CommunitySlug.SNEAKERS;

  const defaultCommunity = useSelector<RootState, CommunitiesAPI.Community | undefined>(
    (state) => state.entities.communitiesBySlug[resolveDefaultSlug],
  );
  const [community, setCommunity] = useState<CommunitiesAPI.Community | null>(defaultCommunity || null);
  const [{ isPending: defaultCommunityPending }] = useRequest(
    !defaultCommunity ? getCommunity(resolveDefaultSlug) : null,
  );

  const {
    open: openCommunitySelectorMenu,
    menuProps: { isOpen, close },
  } = useMenuState();

  const input = useMemo(
    () => (
      <Box onClick={community ? openCommunitySelectorMenu : undefined}>
        {defaultCommunityPending ? (
          <CommunitySelectorWrapper p={3}>
            <CommunityListSelectItemPlaceholder />
          </CommunitySelectorWrapper>
        ) : community ? (
          <CommunitySelectorWrapper p={3}>
            <CommunityMeta community={community} disableLink={true} />
            <Icon color="mediumGrey" name="chevron right" />
          </CommunitySelectorWrapper>
        ) : (
          <CommunitySelectorWrapper p={3} color="darkGrey">
            <Text>{"Select community"}</Text>
            <Icon color="mediumGrey" name="chevron right" />
          </CommunitySelectorWrapper>
        )}
      </Box>
    ),
    [community, defaultCommunityPending, openCommunitySelectorMenu],
  );

  const onCommunitySelect = useCallback<CommunitySelectorMenuProps["onSelect"]>(
    (community) => setCommunity(community),
    [setCommunity],
  );

  const menus = useMemo(
    () => <CommunitySelectorMenu isOpen={isOpen} close={close} onSelect={onCommunitySelect} />,
    [isOpen, close, onCommunitySelect],
  );

  const clearValue = useCallback(() => setCommunity(null), []);

  return useMemo(
    () => ({
      input,
      menus,
      value: community,
      clearValue,
    }),
    [input, menus, community, clearValue],
  );
};

const CommunitySelectorWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
CommunitySelectorWrapper.defaultProps = {
  borderBottom: "1px solid",
  borderColor: "lightGrey",
};

export default useCommunitySelectorInput;
