import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import plugdWordmark from "../../images/plugd-wordmark-black-128.png";
import { Box, BoxProps } from "../../UI";
import { TOP_NAV_HEIGHT } from "../Layout/TopNav";

export interface TopNavLogoProps extends BoxProps {}

const TopNavLogo: React.FC<TopNavLogoProps> = ({ ...wrapperProps }) => {
  return (
    <LogoWrapper flexBasis={0} flexGrow={1} {...wrapperProps}>
      <LogoLink to={"/"}>Plugd</LogoLink>
    </LogoWrapper>
  );
};

const LogoLink = styled(Link)`
  background-image: url(${plugdWordmark.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  &:hover {
    color: transparent;
  }
  height: 35px;
  width: 48px;
`;

const LogoWrapper = styled(Box)`
  height: ${TOP_NAV_HEIGHT}px;
`;
LogoWrapper.defaultProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default TopNavLogo;
