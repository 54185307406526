import React from "react";
import styled from "styled-components";
import { StripeAPI } from "../../typings/API";
import { Box, Text } from "../../UI";

export type DeliveryAddressProps =
  | NonNullable<StripeAPI.CheckoutSession["shipping"]>
  | NonNullable<StripeAPI.Order["shipping_details"]>;

const DeliveryAddress: React.FC<DeliveryAddressProps> = ({ name, address }) => {
  const { line1, line2, city, state, postal_code } = address || {};
  return (
    <AddressWrapper>
      {name && (
        <Box>
          <Text>{name}</Text>
        </Box>
      )}
      {line1 && (
        <Box>
          <Text>{line1}</Text>
        </Box>
      )}
      {line2 && (
        <Box>
          <Text>{line2}</Text>
        </Box>
      )}
      <Box>
        <Text>{city}</Text>
        {", "}
        <Text>{state}</Text> <Text>{postal_code}</Text>
      </Box>
    </AddressWrapper>
  );
};

const AddressWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export default DeliveryAddress;
