import LocalStorageKey from "../../enums/LocalStorageKey";
import { getBoolItem, getJsonItem } from "../../helpers/localStorageUtil";
import { SizeUnit } from "../../helpers/sizeConversionUtil";
import { UsersAPI } from "../../typings/API";
import EntitiesState from "../../typings/EntitiesState";

const initialEntitiesState: { entities: EntitiesState } = {
  entities: {
    // IPAPIState
    ipInfo: null,
    // BrandsState
    brandsById: {},
    // CommunityState
    communitiesBySlug: {},
    communityMembersByCommunityId: {},
    communitySlugById: {},
    communityThemesByCommunityId: {},
    subscribedCommunityIdsByUserId: {},
    userCommunitySubscriptionByCommunityId: {},
    // CommentsState
    commentsById: {},
    commentIdsByRootId: {},
    // MetaState
    exchangeRates: null,
    // VendorsState
    vendorsById: {},
    vendorIdsBySlug: {},
    partnerVendorIds: new Set<number>(),
    // VendorProfilesState
    vendorProfilesById: {},
    // PartnersState
    partnerCollectionsByPartnerId: {},
    productIdsByProductPartnerCollectionId: {},
    // ProductsState
    productsById: {},
    productIdsBySlug: {},
    productStatsById: {},
    productListsByKey: {},
    productFavoriteSuggestionIds: [],
    // ProductAttributesState
    productAttributesByKey: {},
    // GoatCollectionsState
    goatCollectionsBySlug: {},
    productIdsByGoatCollectionSlug: {},
    // PostFlagsState
    flaggedPostIds: [],
    // PostsState
    postsById: {},
    postThemesByKey: {},
    postTypesByKey: {},
    // ProductACOState
    productACOsById: {},
    productACOIdsByProductId: {},
    // PreferencesState
    preferences: {
      currencyPreference: (localStorage.getItem(LocalStorageKey.CURRENCY_PREFERENCE) || "USD") as string,
      disableShippingCosts: getBoolItem(LocalStorageKey.DISABLE_SHIPPING_COSTS),
      review: getJsonItem<UsersAPI.Review>(LocalStorageKey.REVIEW) || undefined,
      shippingLocation: getJsonItem<UsersAPI.ShippingLocation>(LocalStorageKey.LAST_SHIPPING_LOCATION),
      sizePreferenceUnit: (localStorage.getItem(LocalStorageKey.SIZE_PREFERENCE_UNIT) || "us") as SizeUnit,
      beta: false,
    },
    // RafflesState
    rafflesV2ById: {},
    rafflesV2ListsByKey: {},
    // RestocksState
    restocksById: {},
    restocksListsByKey: {},
    restocksFilter: null,
    // ProductLaunchesState
    raffleEntriesByRaffleId: {},
    rafflesById: {},
    raffleIdsByProductId: {},
    releasesById: {},
    releaseIdsByProductId: {},
    productRaffleChannelsByKey: {},
    productReleaseChannelsByKey: {},
    // UserProfileState
    currentUserProfile: null,
    userCommunityRolesById: {},
    userProfileIdsByUsername: {},
    userProfilesById: {},
    userStatisticsById: {},
    userFollowSuggestionIds: [],
    invitedUserIds: [],
    inviteClaimId: null, // used to retroactively claim invites https://app.shortcut.com/plugd/story/5824/some-referred-signups-are-not-tracked-correctly
    followeeIdsByUserId: {},
    followerIdsByUserId: {},
    followUserDataByUserId: {},
    // NotificationState
    globalNotificationsById: {},
    orderNotificationsById: {},
    postNotificationsById: {},
    productNotificationsById: {},
    userNotificationsById: {},
    // AnnouncementsState
    announcementsById: {},
    // UserCollectionsState
    collectionsById: {},
    // UserBlocksState
    blockedUserIds: [],
    // FavoriteProductsState
    favoriteProductIdsByUserId: {},
    // WaitlistState
    monetizationWaitlistPosition: null,
    // LeaderboardState
    topUsers: {},
    // SalesState
    salesById: {},
    // OrdersState
    ordersById: {},
    // StripeState
    stripeAccount: null,
    stripeAccountLink: null,
    stripeCheckoutSessionsById: {},
    stripeLoginLink: null,
    stripeOrder: null,
    // AdminState
    stripeAccountsByUserId: {},
    unclaimedUserIds: [],
    scheduledNotifications: [],
    aco: [],
  },
};

export default initialEntitiesState;
