import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useDoubleTap } from "use-double-tap";
import { PostImageTag } from "..";
import events, { trackEvent } from "../../../helpers/analyticsUtil";
import { urlToImgixUrl } from "../../../helpers/imgixUtil";
import { Loader, Transition } from "../../../libs/semantic-ui";
import { PostsAPI } from "../../../typings/API-V2";
import { Box, BoxProps, Icon, ImgixBackground, ImgixBackgroundProps, ImgixImage } from "../../../UI";
import PostImageZoomWrapper from "../PostImageZoomWrapper";
import useUpvoteOverlay from "./useUpvoteOverlay";

export interface PostImageContentProps {
  alt: string;
  backgroundProps?: Omit<ImgixBackgroundProps, "src">;
  containerProps?: BoxProps;
  currentVote: number | null;
  image: PostsAPI.PostImage;
  slideWidth?: number;
  slideHeight?: number;
  imageDimensions: { height: number; width: number };
  maxAspectRatio?: number;
  tagsVisible: boolean;
  setTagsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  maxHeight?: number;
  maxWidth?: number;
  postId: number;
  upVote: () => void;
}

const PostImageContent: React.FC<PostImageContentProps> = ({
  alt,
  backgroundProps,
  containerProps,
  currentVote,
  image,
  slideWidth,
  slideHeight,
  imageDimensions,
  postId,
  tagsVisible,
  setTagsVisible,
  upVote,
}) => {
  const imgixSrc = urlToImgixUrl(image.url);
  const backgroundImgixParams = useMemo<ImgixBackgroundProps["imgixParams"]>(
    () => ({ blur: 700, ...backgroundProps?.imgixParams }),
    [backgroundProps?.imgixParams],
  );
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);

  const { showUpvoteOverlay, overlayVisible, overlayContainerVisible, UPVOTE_TRANSITION_TIME } = useUpvoteOverlay();

  const onDoubleTap = useCallback(() => {
    if (currentVote !== 1) {
      upVote();
      showUpvoteOverlay(currentVote);
    }
  }, [upVote, currentVote, showUpvoteOverlay]);

  const onSingleTap = useCallback(() => {
    const newVisibleState = !tagsVisible;
    if (newVisibleState) {
      trackEvent(events.ImageTag.Viewed, {
        post_image_id: image.id,
      });
    }
    setTagsVisible(newVisibleState);
  }, [image.id, tagsVisible, setTagsVisible]);

  const { onClick } = useDoubleTap(onDoubleTap, 300, { onSingleTap });

  if (!imgixSrc) {
    return null;
  }

  return (
    <PostImageContainer {...containerProps} onClick={onClick} hasTag={Boolean(image.tags && image.tags.length > 0)}>
      {
        // hides overlay when not active so pinch to zoom gestures aren't blocked
        overlayContainerVisible && (
          <Overlay>
            <Transition
              animation="fade"
              duration={UPVOTE_TRANSITION_TIME}
              visible={overlayVisible}
              unmountOnHide={true}
            >
              <Icon name="arrow up" size="massive" color="white" />
            </Transition>
          </Overlay>
        )
      }
      {
        /** shows shopping indicator icon when product tags exist */
        image.tags && image.tags.length > 0 && (
          <ImageTagBadgeWrapper>
            <Icon circular={true} name="tags" color="lightGrey" bg="black" />
          </ImageTagBadgeWrapper>
        )
      }
      <ImgixBackground
        src={imgixSrc}
        display="flex"
        height={slideHeight}
        width={slideWidth}
        maxWidth="100%"
        justifyContent="center"
        alignItems="center"
        {...backgroundProps}
        imgixParams={backgroundImgixParams} // keep after backgroundProps so not overwritten
      >
        <ImageContainer>
          {image.tags && image.tags.length > 0 && tagsVisible && (
            <Overlay>
              {image.tags.map((t) => (
                <PostImageTag
                  key={t.id}
                  postId={postId}
                  tag={t}
                  x={t.x * imageDimensions.width}
                  y={t.y * imageDimensions.height}
                />
              ))}
            </Overlay>
          )}
          <PostImageZoomWrapper>
            <ImgixImage
              alt={alt}
              src={imgixSrc}
              maxWidth="100%"
              htmlAttributes={{
                onLoad: () => setImgLoaded(true),
                // ...imgixProps.htmlAttributes,
              }}
              {...imageDimensions}
              // {...imgixProps}
            />
          </PostImageZoomWrapper>
          {!imgLoaded && <Loader />}
        </ImageContainer>
      </ImgixBackground>
    </PostImageContainer>
  );
};

const ImageTagBadgeWrapper = styled(Box)`
  z-index: 10;
  position: absolute;
  cursor: pointer;
`;
ImageTagBadgeWrapper.defaultProps = {
  role: "button",
  bottom: 3,
  left: 3,
};

const PostImageContainer = styled(Box)<BoxProps & { hasTag: boolean }>`
  ${({ hasTag }) => (hasTag ? `cursor: pointer;` : "")}
  position: relative;
  line-height: 0px;

  & .react-transform-component {
    overflow: visible;
  }
`;

const ImageContainer = styled(Box)`
  position: relative;
  line-height: 0px;
`;

const Overlay = styled(Box)`
  transform: translate(0px, 0px);
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PostImageContent;
