import { SearchOptions } from "@algolia/client-search";
import { RequestOptions } from "@algolia/transporter";
import { SearchIndex } from "algoliasearch";
import { useCallback, useEffect, useMemo, useState } from "react";
import { QueryState } from "redux-query";

interface SearchState {
  page: number;
  nbPages: number;
  nbHits: number;
}

export type ResultsFormatter<T, F> = (prevResults: F[], newResults: T[]) => F[];

/**
 * Generic Types
 *   T: the search results object from algolia
 *   F: the formatted search results that is returned to the user
 * @param algoliaIndex
 * @param query
 * @param defaultSearchOptions
 * @param resultsFormatter
 * @returns
 */
const usePlugdAlgoliaSearch = <T, F = T>(
  algoliaIndex?: SearchIndex,
  query?: string,
  defaultSearchOptions?: RequestOptions & SearchOptions,
  resultsFormatter?: ResultsFormatter<T, F>,
): {
  hasMorePages: boolean;
  isFinished: boolean;
  isPending: boolean;
  nextPage: () => void;
  searchResults: F[];
  searchState: SearchState;
} => {
  const [searchState, setSearchState] = useState<SearchState>({
    page: 0,
    nbPages: 0,
    nbHits: 0,
  });
  const [queryState, setQueryState] = useState<Pick<QueryState, "isPending" | "isFinished">>({
    isFinished: false,
    isPending: false,
  });
  const [searchResults, setSearchResults] = useState<F[]>([]);
  const hasMorePages = searchState.page < searchState.nbPages - 1;

  const formatResults = useCallback(
    (prevResults, nextResults) =>
      resultsFormatter ? resultsFormatter(prevResults, nextResults) : (nextResults as unknown as F[]), // todo: figure out how to do this properly
    [resultsFormatter],
  );

  useEffect(() => {
    if (algoliaIndex) {
      setQueryState({
        isPending: true,
        isFinished: false,
      });
      algoliaIndex
        .search<T>("", {
          ...defaultSearchOptions,
          query,
          page: searchState.page,
        })
        .then((results) => {
          setSearchState((prevState) => ({
            ...prevState,
            nbPages: results.nbPages,
            nbHits: results.nbHits,
          }));
          setSearchResults((prevResults) => formatResults(prevResults, results.hits));
        })
        .finally(() => {
          setQueryState({
            isPending: false,
            isFinished: true,
          });
        });
    }
  }, [algoliaIndex, defaultSearchOptions, formatResults, query, searchState.page]);

  // fetch next page
  const nextPage = useCallback(() => {
    if (algoliaIndex) {
      const nextPage = searchState.page + 1;
      algoliaIndex
        .search<T>("", { ...defaultSearchOptions, page: nextPage })
        .then((results) => setSearchResults((prevResults) => formatResults(prevResults, results.hits)));
      setSearchState((prevState) => ({
        ...prevState,
        page: nextPage,
      }));
    }
  }, [algoliaIndex, defaultSearchOptions, formatResults, setSearchState, searchState.page]);

  return useMemo(
    () => ({
      hasMorePages,
      isPending: queryState.isPending,
      isFinished: queryState.isFinished,
      searchState,
      searchResults,
      nextPage,
    }),
    [hasMorePages, queryState, nextPage, searchResults, searchState],
  );
};

export default usePlugdAlgoliaSearch;
