import baseConfig, { BaseConfig, EnvConfig } from "./config.base";
import { isMobile } from "./libs/ua-parser-js";

type ENV = "staging" | "production" | "development";
type Config = BaseConfig["common"] & EnvConfig["common"] & (EnvConfig["mobile"] | EnvConfig["web"]);

/**
 * DOMAINS
 */
export const devDomains = baseConfig.development.mobile.domains.concat(baseConfig.development.web.domains);
export const prodDomains = baseConfig.production.mobile.domains.concat(baseConfig.production.web.domains);
export const stagingDomains = baseConfig.staging.mobile.domains.concat(baseConfig.staging.web.domains);
export const mobileFrameDomains = baseConfig.staging.mobile.domains.concat(baseConfig.production.mobile.domains);

/**
 * ENV
 *
 * TODO: Move these to env helper file
 */
export const isProd = process.env.NODE_ENV === "production";
export const isDev =
  process.env.NODE_ENV !== "production" && devDomains.some((domain) => document.location.href.includes(domain));
export const isStaging = stagingDomains.some((domain) => document.location.href.includes(domain));
export const isMobileFrame = mobileFrameDomains.some((domain) => document.location.href.includes(domain));
export const isWeb = !isMobileFrame;
export const isBot = /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex|headlesschrome|prerender/i.test(
  navigator.userAgent,
);
export const isMobileWeb = isBot || (isMobile && isWeb);

/**
 * selects an option based on the current env
 * @param options
 * @param defaultValue
 */
export function envSelector<T>(options: Record<ENV, T>, defaultValue: T): T;
export function envSelector<T>(options: Record<ENV, T | undefined>): T | undefined;
export function envSelector<T>(options: Record<ENV, T | undefined>, defaultValue?: T): T | undefined {
  if (isStaging) {
    return options.staging;
  }

  if (isProd) {
    return options.production;
  }

  if (isDev) {
    return options.development;
  }

  return defaultValue;
}

/**
 * Flattens and returns config based on device and env
 */
const config = (() => {
  const envConfig = Object.assign({}, envSelector(baseConfig));
  const devConfig: Config = Object.assign(
    {},
    baseConfig.common,
    envConfig.common,
    isMobileFrame ? envConfig.mobile : envConfig.web,
  );
  return Object.assign(
    /** Derived values go here */
    {
      ADMIN_API_URL: `${devConfig.API_BASE_URL}/admin`,
      API_URL: `${devConfig.API_BASE_URL}/api`,
      API_V2_URL: `${devConfig.API_BASE_URL}/api/v2`,
      TRACK_API_URL: `${devConfig.API_BASE_URL}/track`,
    },
    devConfig,
  );
})();

export default config;
