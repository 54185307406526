import React, { useMemo } from "react";
import styled from "styled-components";
import missingPlaceholder from "../../images/missing.png";
import RestocksAPI from "../../typings/API-V2/RestocksAPI";
import { Box, Image, Spacer, Text } from "../../UI";
import AffiliateLink from "../links/AffiliateLink";

export interface RestocksListItemBodyProps {
  restock: RestocksAPI.Restock;
}
const PRODUCT_PHOTO_SIZE = 50;

const RestocksListItemBody: React.FC<RestocksListItemBodyProps> = ({ restock }) => {
  const sizesDisplay = useMemo(
    () =>
      restock.data.productVariants
        ?.map((v) => (v.type && !isNaN(parseFloat(v.type)) ? parseFloat(v.type) : ""))
        .join(" "),
    [restock.data.productVariants],
  );
  const productPhotoUrl = restock.product_image || missingPlaceholder.src;
  const displayName = restock.product_name;

  return (
    <BodyContainer as={AffiliateLink} url={restock.product_url} linkType="restock">
      <ImageBorder width={PRODUCT_PHOTO_SIZE} height={PRODUCT_PHOTO_SIZE}>
        <ProductImage
          maxHeight={PRODUCT_PHOTO_SIZE}
          maxWidth={PRODUCT_PHOTO_SIZE}
          src={productPhotoUrl}
          alt={`${displayName} Photo`}
        />
      </ImageBorder>
      <Box overflow="hidden" display="flex" flexDirection="column" justifyContent="center" ml={[2, 3]}>
        <ProductText title={displayName}>{displayName}</ProductText>
        {sizesDisplay && (
          <>
            <Spacer mt={1} />
            <Subtext>Sizes</Subtext>
            <Subtext color="darkGrey">{sizesDisplay} </Subtext>
          </>
        )}
      </Box>
    </BodyContainer>
  );
};

const ProductText = styled(Text)`
  margin: 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
ProductText.defaultProps = {
  fontSize: 1,
  fontWeight: 5,
};

const BodyContainer = styled(Box)`
  display: flex;
`;

const ProductImage = styled(Image)`
  object-fit: contain;
`;

const ImageBorder = styled(Box)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
`;

const Subtext = styled(Text)``;
Subtext.defaultProps = {
  fontSize: 0,
};

export default RestocksListItemBody;
