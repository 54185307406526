import { isEqual } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "redux-query-react";
import analyticsUtil, { trackEvent } from "../../helpers/analyticsUtil";
import useMenuState from "../../hooks/useMenuState";
import { updateRestockFilters } from "../../queries/api-v2/restocksQuery";
import restocksSelector from "../../selectors/restocksSelector";
import { Text, Toggle } from "../../UI";
import ActionsMenuItem from "../ActionsMenu/ActionsMenuItem";
import FullscreenMenu, {
  FullscreenMenuHeader,
  FullscreenMenuHeaderButton,
  FullscreenMenuHeaderIcon,
  FullscreenMenuHeaderTitle,
} from "../Layout/FullscreenMenu";
import FullscreenMenuHeaderSide from "../Layout/FullscreenMenu/FullscreenMenuHeaderSide";
import RestocksKeywordMenu from "./RestocksKeywordMenu";
import RestocksSizeSelectorMenu from "./RestocksSizeSelectorMenu";

export interface RestocksSettingsMenuProps {
  close: () => void;
  isOpen: boolean;
}

const RestocksSettingsMenu: React.FC<RestocksSettingsMenuProps> = ({ close, isOpen }) => {
  const { addToast } = useToasts();

  const {
    open: openSizeSettingsPortal,
    menuProps: { close: closeSizeSettingsPortal, isOpen: sizeSettingsPortalIsOpen },
  } = useMenuState();

  const {
    open: openKeywordWhitelistPortal,
    menuProps: { close: closeKeywordWhitelistPortal, isOpen: keywordWhitelistPortalIsOpen },
  } = useMenuState();

  useEffect(() => {
    if (isOpen) {
      trackEvent(analyticsUtil.RestocksSettings.Viewed);
    }
  }, [isOpen]);

  const [, updateFilters] = useMutation(updateRestockFilters);

  const userSettings = useSelector(restocksSelector.userFilters());

  const [includeHyped, setIncludeHyped] = useState<boolean>(userSettings.include_hyped);
  const [applyToFeed, setApplyToFeed] = useState<boolean>(userSettings.apply_to_feed);
  const [applyToNotifications, setApplyToNotifications] = useState<boolean>(userSettings.apply_to_notifications);
  const [sizes, setSizes] = useState<string[]>(userSettings.sizes || []);
  const [whitelistKeywords, setWhitelistKeywords] = useState<string[]>(userSettings.whitelist_keywords || []);

  const hasChanged = useMemo<boolean>(() => {
    if (
      includeHyped !== userSettings.include_hyped ||
      applyToFeed !== userSettings.apply_to_feed ||
      applyToNotifications !== userSettings.apply_to_notifications ||
      !isEqual(sizes, userSettings.sizes) ||
      !isEqual(whitelistKeywords, userSettings.whitelist_keywords)
    ) {
      return true;
    } else {
      return false;
    }
  }, [userSettings, includeHyped, applyToNotifications, applyToFeed, sizes, whitelistKeywords]);

  useEffect(() => {
    setIncludeHyped(userSettings.include_hyped);
    setApplyToFeed(userSettings.apply_to_feed);
    setApplyToNotifications(userSettings.apply_to_notifications);
    setSizes(userSettings.sizes || []);
    setWhitelistKeywords(userSettings.whitelist_keywords || []);
  }, [isOpen]);

  const handleSubmitSettings = useCallback(async () => {
    if (hasChanged) {
      trackEvent(analyticsUtil.RestocksSettings.Updated);
      try {
        await updateFilters({
          sizes: sizes,
          whitelist_keywords: whitelistKeywords,
          blacklist_keywords: userSettings.blacklist_keywords,
          include_hyped: includeHyped,
          apply_to_feed: applyToFeed,
          apply_to_notifications: applyToNotifications,
        });
        addToast(`Settings updated`, {
          appearance: "success",
          autoDismiss: true,
        });
        close();
      } catch (e) {
        addToast(`An error occurred`, {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    }
  }, [
    hasChanged,
    updateFilters,
    sizes,
    whitelistKeywords,
    userSettings.blacklist_keywords,
    includeHyped,
    applyToFeed,
    applyToNotifications,
    addToast,
    close,
  ]);

  return (
    <FullscreenMenu
      header={
        <FullscreenMenuHeader>
          <FullscreenMenuHeaderSide side="left">
            <FullscreenMenuHeaderIcon name="chevron left" onClick={close} containerProps={{ flexBasis: 0 }} />
          </FullscreenMenuHeaderSide>
          <FullscreenMenuHeaderTitle>Drop Alert Settings</FullscreenMenuHeaderTitle>
          <FullscreenMenuHeaderSide side="right">
            <FullscreenMenuHeaderButton
              onClick={handleSubmitSettings}
              disabled={!hasChanged}
              variant={hasChanged ? "secondary" : "disabled"}
            >
              Save
            </FullscreenMenuHeaderButton>
          </FullscreenMenuHeaderSide>
        </FullscreenMenuHeader>
      }
      isOpen={isOpen}
      close={close}
    >
      <>
        <ActionsMenuItem
          hideBorder={true}
          paddingLeft={2}
          label="Apply Filters to Feed"
          onClick={() => setApplyToFeed(!applyToFeed)}
          detail={<Toggle checked={applyToFeed} />}
        />
        <ActionsMenuItem
          hideBorder={true}
          paddingLeft={2}
          onClick={() => setApplyToNotifications(!applyToNotifications)}
          label="Apply Filters to Notifications"
          detail={<Toggle checked={applyToNotifications} />}
        />
        <Text fontWeight={5} fontSize={2} p={2}>
          Filters
        </Text>
        <ActionsMenuItem
          paddingLeft={2}
          label="Keyword Whitelist"
          detail={<Text>{`${whitelistKeywords?.length ? whitelistKeywords.length : "None"} selected`}</Text>}
          onClick={openKeywordWhitelistPortal}
        />
        <ActionsMenuItem
          paddingLeft={2}
          label="Only Hyped Products"
          detail={<Toggle checked={includeHyped} />}
          onClick={() => setIncludeHyped(!includeHyped)}
        />
        <ActionsMenuItem
          paddingLeft={2}
          label="Sizes"
          detail={<Text>{`${sizes?.length ? sizes.length : "None"} selected`}</Text>}
          onClick={openSizeSettingsPortal}
        />
      </>
      <RestocksSizeSelectorMenu
        setSizes={setSizes}
        isOpen={sizeSettingsPortalIsOpen}
        activeSizes={sizes}
        close={closeSizeSettingsPortal}
      />
      <RestocksKeywordMenu
        setKeywords={setWhitelistKeywords}
        isOpen={keywordWhitelistPortalIsOpen}
        existingKeywords={whitelistKeywords}
        close={closeKeywordWhitelistPortal}
      />
    </FullscreenMenu>
  );
};

export default RestocksSettingsMenu;
