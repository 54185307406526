import useValueByMedia from "./useValueByMedia";
import { isMobileFrame } from "../../config";

export type ScreenSize = "small" | "medium" | "large";

const useScreenSize = (): ScreenSize => {
  const size = useValueByMedia<ScreenSize>(["small", "medium", "large"]);
  return isMobileFrame ? "small" : size;
};

export default useScreenSize;
