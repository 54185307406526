import { RootState } from "../store";
import { RestocksAPI } from "../typings/API-V2";

const restocksSelector = {
  // settings
  userFilters:
    () =>
    (
      state: RootState,
    ): Pick<
      RestocksAPI.RestocksFilter,
      | "sizes"
      | "include_hyped"
      | "apply_to_notifications"
      | "apply_to_feed"
      | "blacklist_keywords"
      | "whitelist_keywords"
    > => {
      const defaultFilter = {
        include_hyped: true,
        apply_to_feed: true,
        apply_to_notifications: false,
        blacklist_keywords: [],
        whitelist_keywords: [],
        sizes: [],
      };
      const userRestocksSettings = state.entities.restocksFilter;
      return userRestocksSettings || defaultFilter;
    },
};

export default restocksSelector;
