import React, { PropsWithChildren } from "react";
import { Box } from "../../UI";
import { TOP_NAV_HEIGHT } from "../Layout/TopNav";

export interface TopNavSideProps {
  side: "left" | "right";
}

/**
 * This component wraps its containing elements in a Box with flex-basis: 0, and aligns the elements to the specified side
 * such that the middle element will be centered in the middle of the screen whether or not the two sides are the same width
 */
const TopNavSide: React.FC<PropsWithChildren<TopNavSideProps>> = ({ children, side }) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexBasis={0}
      flexGrow={1}
      flexShrink={0}
      justifyContent={side === "left" ? "flex-start" : "flex-end"}
      height={TOP_NAV_HEIGHT}
    >
      {children}
    </Box>
  );
};

export default TopNavSide;
