import React from "react";
import RefreshMessageContentWrapper from "./RefreshMessageContentWrapper";

export interface ReleaseToRefreshContentProps {}

const ReleaseToRefreshContent: React.FC<React.PropsWithChildren<ReleaseToRefreshContentProps>> = ({ children }) => (
  <RefreshMessageContentWrapper>{children || "Release to Refresh"}</RefreshMessageContentWrapper>
);

export default ReleaseToRefreshContent;
