import { toLower } from "lodash-es";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useUser } from "reactfire";
import { useMutation, useRequest } from "redux-query-react";
import { isValidSlug } from "../../helpers/communityUtil";
import { captureException } from "../../helpers/errorUtil";
import { actionPromiseToPromise } from "../../helpers/reactQueryUtil";
import useControlledRequest from "../../hooks/useControlledRequest";
import { Loader } from "../../libs/semantic-ui";
import {
  createCommunity,
  getCommunity as getCommunityQuery,
  getUserCommunityRoles,
} from "../../queries/api/communityQuery";
import communitySelector from "../../selectors/communitySelector";
import { CommunitiesAPI } from "../../typings/API";
import { ErrorResponse } from "../../typings/API/common";
import { CommunityState } from "../../typings/EntitiesState";
import { Box, Button } from "../../UI";
import FullscreenMenu, { FullscreenMenuHeader, FullscreenMenuHeaderTitle } from "../Layout/FullscreenMenu";
import CommunityDescriptionForm from "./CommunityDescriptionForm";
import CommunityNameForm from "./CommunityNameForm";
import CommunitySlugForm from "./CommunitySlugForm";
import CreateCommunityMenuContext from "./CreateCommunityMenuContext";

export interface CreateCommunityMenuProps {}

export type CommunityMenuScreen = "name" | "slug" | "description";

const CreateCommunityMenu: React.FC<CreateCommunityMenuProps> = () => {
  const { data: currentUser } = useUser();
  const uid = currentUser?.uid;
  const { screen, setScreen } = useContext(CreateCommunityMenuContext);
  const [, fetchRoles] = useRequest(uid ? getUserCommunityRoles(uid) : null);
  const [communitySlug, setCommunitySlug] = useState<string | undefined>("");
  const [slugToValidate, setSlugToValidate] = useState<string | undefined>("");
  const [communityName, setCommunityName] = useState<string | undefined>("");
  const [communityDescription, setCommunityDescription] = useState<string | undefined>("");
  const [createCommunityRequestStatus, postCommunity] = useMutation(createCommunity);
  const { addToast } = useToasts();
  const history = useHistory();
  const existingCommunity = useSelector(communitySelector.communityBySlug(communitySlug || ""));

  const [fetchCommunityState, getCommunity] = useControlledRequest<
    typeof getCommunityQuery,
    CommunitiesAPI.GetCommunityResponse
  >((slug) => getCommunityQuery(slug));

  const isPending = fetchCommunityState.isPending || createCommunityRequestStatus.isPending;

  const close = useCallback(() => {
    setScreen(null);
  }, [setScreen]);

  const handleNext = useCallback(async () => {
    if (screen === "slug") {
      // validate slug
      if (!communitySlug || !isValidSlug(communitySlug)) {
        addToast("The handle must be at least 3 characters and can only consist of letters, numbers, and underscores", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        // make sure community does not exist before moving to next screen
        if (existingCommunity) {
          addToast("Community handle already taken. Please choose another one.", {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        } else {
          try {
            if (slugToValidate !== communitySlug) {
              setSlugToValidate(communitySlug);
              const response = await actionPromiseToPromise(getCommunity(communitySlug));
              if (response.body?.data?.community) {
                throw new Error("Community handle already taken. Please choose another one.");
              }
            }
          } catch (error) {
            if (error instanceof Error) {
              addToast(error.message, {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              addToast("Unknown error", {
                appearance: "error",
                autoDismiss: true,
              });
            }
            return;
          }
        }
        setScreen("name");
      }
    } else if (screen === "name") {
      //default display name to handle
      setScreen("description");
    } else if (screen === "description") {
      if (communitySlug) {
        const formattedSlug = toLower(communitySlug);
        try {
          const { body } = await actionPromiseToPromise<
            Pick<CommunityState, "communitiesBySlug">,
            CommunitiesAPI.CreateCommunityResponse | ErrorResponse
          >(postCommunity(communityName || communitySlug, formattedSlug, communityDescription || ""));

          if (!body) {
            throw new Error("Create community not received");
          }

          if (!body.success) {
            if (body.error) {
              throw new Error(body.error);
            }
            throw new Error("Failed to create community");
          }
          if (uid) {
            await fetchRoles();
          }
          addToast(`Community created`, {
            appearance: "success",
            autoDismiss: true,
          });
          history.push(`/c/${formattedSlug}`);
          close();
        } catch (error) {
          captureException(error);
          const errorMessage =
            typeof error === "string" ? error : error instanceof Error ? error.message : `Something went wrong`;
          addToast(errorMessage, {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      }
    }
  }, [
    screen,
    communitySlug,
    addToast,
    existingCommunity,
    slugToValidate,
    setScreen,
    getCommunity,
    postCommunity,
    communityName,
    communityDescription,
    history,
    close,
  ]);

  const handleBack = useCallback(() => {
    if (screen === "slug") {
      close();
    } else if (screen === "name") {
      setScreen("slug");
    } else if (screen === "description") {
      setScreen("name");
    }
  }, [close, screen, setScreen]);

  const nextButtonText = useMemo(() => {
    if (screen === "slug" || screen === "name") {
      return "Next";
    } else {
      return "Submit";
    }
  }, [screen]);

  const backButtonText = useMemo(() => {
    if (screen === "name" || screen === "description") {
      return "Back";
    } else {
      return "Cancel";
    }
  }, [screen]);

  const header = (
    <FullscreenMenuHeader px={3}>
      <Box role="button" onClick={handleBack} width={50} style={{ cursor: "pointer" }}>
        {backButtonText}
      </Box>
      <FullscreenMenuHeaderTitle>New Community</FullscreenMenuHeaderTitle>
      <Box height="100%" width={50} display="flex" alignItems="center" justifyContent="flex-end">
        <Button disabled={isPending} variant="primary" size="small" onClick={handleNext}>
          {isPending ? <Loader active={isPending} inverted={true} size="mini" inline="centered" /> : nextButtonText}
        </Button>
      </Box>
    </FullscreenMenuHeader>
  );

  const renderContent = useMemo(() => {
    switch (screen) {
      case "slug":
        return <CommunitySlugForm slug={communitySlug} onChange={setCommunitySlug} />;
      case "name":
        return <CommunityNameForm placeholder={communitySlug} name={communityName} onChange={setCommunityName} />;
      case "description":
        return <CommunityDescriptionForm description={communityDescription} onChange={setCommunityDescription} />;
      default:
        return <CommunitySlugForm slug={communitySlug} onChange={setCommunitySlug} />;
    }
  }, [screen, communitySlug, communityName, communityDescription]);

  return (
    <FullscreenMenu
      isOpen={Boolean(screen)}
      close={close}
      display="flex"
      flexDirection="column"
      overflowY="inherit"
      height="100%"
      header={header}
    >
      <Box width="100%" mt={2} mb={3} px={2}>
        {renderContent}
      </Box>
    </FullscreenMenu>
  );
};

export default CreateCommunityMenu;
