import { LegacyOutboundMessageType } from "../enums/MessageType";
import { OutboundMessage } from "./outboundMessages";

/**
 * utility function that stringifies and sends the message to the native app
 * @param message <M extends OutboundMessage>
 */
const sendReactNativeMessage = <Payload>(message: OutboundMessage<Payload>) => {
  if (window.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  }
};

/**
 * @deprecated
 * utility function that sends string messages to the native app. Do not use. Only used for old
 * legacy messages that do not match current message standards
 * @param message <M extends OutboundMessage>
 */
export const legacySendReactNativeMessage = (message: LegacyOutboundMessageType) => {
  if (window.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(message);
  }
};

export default sendReactNativeMessage;
