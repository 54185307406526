import { Property } from "csstype";
import React from "react";
import { SemanticICONS } from "semantic-ui-react";
import styled, { DefaultTheme } from "styled-components";
import { ResponsiveValue } from "styled-system";
import { Box, BoxProps, Icon } from "../../../UI";
import { FULLSCREEN_MENU_HEADER_HEIGHT } from "./FullscreenMenuHeader";

export interface FullscreenMenuHeaderIconProps extends BoxProps {
  containerProps?: BoxProps;
  justifyContent?: ResponsiveValue<Property.JustifyContent, DefaultTheme>;
  name?: SemanticICONS;
}

const FullscreenMenuHeaderIcon: React.FC<FullscreenMenuHeaderIconProps> = ({
  name,
  containerProps,
  justifyContent = "flex-start",
  ...boxProps
}) => {
  return (
    <Container {...containerProps} justifyContent={justifyContent}>
      <Box
        tabIndex={0}
        alignItems="center"
        justifyContent="center"
        display="flex"
        height={FULLSCREEN_MENU_HEADER_HEIGHT}
        role="button"
        style={styleProps}
        width={FULLSCREEN_MENU_HEADER_HEIGHT}
        {...boxProps}
      >
        {name && <Icon name={name} />}
      </Box>
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  align-items: center;
`;
Container.defaultProps = {
  flexShrink: 0,
  flexGrow: 1,
};

const styleProps = {
  cursor: "pointer",
};

export default FullscreenMenuHeaderIcon;
