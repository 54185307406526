import { isNil } from "lodash-es";
import LocalStorageKey from "../../enums/LocalStorageKey";
import * as localStorageUtil from "../../helpers/localStorageUtil";
import { GoatProduct, ProductsAPI } from "../../typings/API";

const initialRecentlyViewedProductIds = ((): number[] => {
  const initialRecentlyViewedProductIds: number[] | null = localStorageUtil.getJsonItem<number[]>(
    LocalStorageKey.PLUGD_RECENTLY_VIEWED_PRODUCT_IDS,
  );

  if (!isNil(initialRecentlyViewedProductIds)) {
    return initialRecentlyViewedProductIds;
  }

  // deprecated
  const initialRecentlyViewedProductsV2: Optional<ProductsAPI.Product, "id">[] | null = localStorageUtil.getJsonItem<
    ProductsAPI.Product[] | null
  >(LocalStorageKey.PLUGD_RECENTLY_VIEWED_PRODUCTS);

  if (!isNil(initialRecentlyViewedProductsV2)) {
    const productIds = initialRecentlyViewedProductsV2.map((p) => p.id).filter((id) => Boolean(id)) as number[];
    localStorageUtil.setJsonItem(LocalStorageKey.PLUGD_RECENTLY_VIEWED_PRODUCT_IDS, productIds);
    localStorage.removeItem(LocalStorageKey.PLUGD_RECENTLY_VIEWED_PRODUCTS);
    return productIds;
  }

  // deprecated
  const initialRecentlyViewedProductsV1: GoatProduct[] | null = localStorageUtil.getJsonItem<GoatProduct[] | null>(
    LocalStorageKey.RECENTLY_VIEWED_PRODUCTS,
  );

  if (!isNil(initialRecentlyViewedProductsV1)) {
    localStorage.removeItem(LocalStorageKey.RECENTLY_VIEWED_PRODUCTS);
    return [];
  }

  return [];
})();

export default initialRecentlyViewedProductIds;
