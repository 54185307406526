import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { isMobileFrame } from "../../../config";
import { Grid, GridProps } from "../../../UI";
import useScreenSize from "../../../UI/utils/useScreenSize";

export interface OneTwoOneGridProps extends GridProps {}

const OneTwoOneGrid: React.FC<PropsWithChildren<OneTwoOneGridProps>> = ({ children, ...gridProps }) => {
  const screenSize = useScreenSize();
  return (
    <OneTwoOneGridComp width="100%" maxWidth={screenSize === "large" ? "100em" : undefined} {...gridProps}>
      {children}
    </OneTwoOneGridComp>
  );
};

const OneTwoOneGridComp = styled(Grid)`
  width: 100%;
  grid-template-columns: minmax(0, 1fr);

  ${() =>
    !isMobileFrame &&
    css`
      ${({ theme }): string => theme.mediaQueries.small} {
        grid-template-columns: minmax(0, 1fr);
      }

      ${({ theme }): string => theme.mediaQueries.medium} {
        grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
        grid-gap: ${({ theme }) => theme.space[4]}px;
      }
    `}
`;

export default OneTwoOneGrid;
