import styled, { DefaultTheme } from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

export interface SpacerProps extends SpaceProps<DefaultTheme>, LayoutProps<DefaultTheme> {}

const Spacer = styled.div<SpacerProps>`
  flex-shrink: 0;
  ${layout}
  ${space}
`;

export default Spacer;
