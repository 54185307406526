export enum MarketingSource {
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  EMAIL = "email",
  REDDIT = "reddit",
}

export enum MarketingCampaign {
  ORGANIC = "organic",
}

export enum MarketingMedium {
  PRODUCT = "product",
  POST = "post",
  COLLECTION = "collection",
  COMMUNITY = "community",
  USER = "user",
  REFERRALS = "referrals",
}

export default {
  MarketingCampaign,
  MarketingSource,
  MarketingMedium,
};
