import dayjs from "dayjs";
import React from "react";

import styled from "styled-components";
import RestocksAPI from "../../typings/API-V2/RestocksAPI";
import { Box, Text } from "../../UI";
import AffiliateLink from "../links/AffiliateLink";

export interface RestocksListItemHeaderProps {
  restock: RestocksAPI.Restock;
}

const RestocksListItemHeader: React.FC<RestocksListItemHeaderProps> = ({ restock }) => {
  const { hostname } = new URL(restock.data.siteUrl);

  return (
    <HeaderContainer>
      <Box as={AffiliateLink} linkType="restock" url={restock.data.siteUrl} display="flex" alignItems={"center"}>
        <StoreNameText title={hostname}>{hostname}</StoreNameText>
        <Text ml={1} fontSize={0} color="darkGrey">
          {" · "}
        </Text>
        <RelativeCreationTime ml={1} fontSize={0} color="darkGrey">
          {dayjs(restock.created_at).fromNow()}
        </RelativeCreationTime>
      </Box>
      {/* <Spacer ml={2} />
      <RestocksActionIconWrapper onClick={openMenu}>
        <Icon name="ellipsis horizontal" color="darkGrey" />
      </RestocksActionIconWrapper> */}
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;
HeaderContainer.defaultProps = {
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  marginBottom: 2,
  paddingBottom: 2,
};

const StoreNameText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

StoreNameText.defaultProps = {
  fontSize: 0,
  fontWeight: 4,
  color: "black",
};

const RelativeCreationTime = styled(Text)`
  flex-shrink: 0;
`;

const RestocksActionIconWrapper = styled(Box)`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;
RestocksActionIconWrapper.defaultProps = {
  height: 24,
  width: 24,
  borderRadius: 1,
  role: "button",
};

export default RestocksListItemHeader;
