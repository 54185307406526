import immer from "immer";
import { forEach } from "lodash-es";
import ActionStatus from "../enums/ActionStatus";
import ActionType from "../enums/ActionType";
import LocalStorageKey from "../enums/LocalStorageKey";
import { getJsonItem, setJsonItem } from "../helpers/localStorageUtil";
import { ProductPriceAlarm } from "../helpers/productPriceAlertUtil";
import { ActionValue } from "../helpers/thunkUtil";
import { FirebaseTokenClaims } from "../typings/API";

export interface UserState {
  claims: FirebaseTokenClaims | null;
  productAlarms: Record<number, ProductPriceAlarm>;
  visits: number;
}

// preferences for users that are not logged in
const initialState: UserState = {
  claims: null,
  productAlarms: {},
  visits: getJsonItem<number | null>(LocalStorageKey.VISITS) || 0,
};

/* functionality currently disabled. When reenabled, need to update this to use entities reducer */
// const updateIpInfo = (state: UserState, action): UserState => {
//   const ipInfo = action.payload as IPAPI.IPInfo;
//   const shippingLocation = {
//     continent_code: ipInfo.continent_code || null,
//     continent_name: ipInfo.continent_name || null,
//     country_name: ipInfo.country_name,
//     country: ipInfo.country,
//     region_code: ipInfo.region_code,
//     region: ipInfo.region,
//   };

//   const currentShippingLocation = state.shippingLocation;
//   if (!currentShippingLocation) {
//     // store shipping location in local storage
//     setJsonItem(LocalStorageKey.LAST_SHIPPING_LOCATION, shippingLocation);
//   }

//   // set the size unit based on user location but do not override if already set
//   let sizeUnit: SizeUnit = state.sizePreferenceUnit;

//   if (!localStorage.getItem(LocalStorageKey.SIZE_PREFERENCE_UNIT)) {
//     if (shippingLocation.country === "GB") {
//       sizeUnit = "uk";
//     } else if (shippingLocation.country === "FR") {
//       sizeUnit = "fr";
//     } else if (shippingLocation.continent_code === "EU") {
//       sizeUnit = "eu";
//     }
//     localStorage.setItem(LocalStorageKey.SIZE_PREFERENCE_UNIT, sizeUnit);
//   }

//   // set the currency based on the user ip
//   let currencyPreference = state.currencyPreference;

//   if (!localStorage.getItem(LocalStorageKey.CURRENCY_PREFERENCE)) {
//     currencyPreference = ipInfo.currency;
//   }

//   return immer(
//     state,
//     (draft): UserState => {
//       draft.shippingLocation = shippingLocation;
//       draft.sizePreferenceUnit = sizeUnit;
//       draft.currencyPreference = currencyPreference;
//       return draft;
//     },
//   );
// };

const incrementVisits = (state: UserState): UserState => {
  const visits = state.visits + 1;
  return immer(state, (draft): UserState => {
    setJsonItem(LocalStorageKey.VISITS, visits);
    draft.visits = visits;
    return draft;
  });
};

const setProductAlarms = (state: UserState, action: ActionValue<ProductPriceAlarm[]>): UserState => {
  switch (action.status) {
    case ActionStatus.SUCCESS: {
      const alarms = action.payload;
      return immer(state, (draft): UserState => {
        forEach(alarms, (alarm) => {
          if (alarm.id) {
            draft.productAlarms[alarm.id] = alarm;
          }
        });
        return draft;
      });
    }
    default:
      return state;
  }
};

const updateProductAlarm = (state: UserState, action: ActionValue<ProductPriceAlarm>): UserState => {
  switch (action.status) {
    case ActionStatus.SUCCESS: {
      const alarm = action.payload;
      return immer(state, (draft): UserState => {
        if (alarm.id) {
          draft.productAlarms[alarm.id] = alarm;
        }
        return draft;
      });
    }
    default:
      return state;
  }
};

const removeProductAlarm = (state: UserState, action: ActionValue<{ id: number }>): UserState => {
  switch (action.status) {
    case ActionStatus.SUCCESS: {
      const alarmId = action.payload.id;
      return immer(state, (draft): UserState => {
        delete draft.productAlarms[alarmId];
        return draft;
      });
    }
    default:
      return state;
  }
};

const addProductAlarm = (state: UserState, action: ActionValue<ProductPriceAlarm>): UserState => {
  switch (action.status) {
    case ActionStatus.SUCCESS: {
      const alarmId = action.payload.id as number;
      return immer(state, (draft): UserState => {
        draft.productAlarms[alarmId] = action.payload as ProductPriceAlarm;
        return draft;
      });
    }
    default:
      return state;
  }
};

const setClaims = (state: UserState, action: ActionValue<FirebaseTokenClaims | null>): UserState => {
  const claims = action.payload;
  return immer(state, (draft): UserState => {
    draft.claims = claims;
    return draft;
  });
};

const userReducer = (state: UserState = initialState, action: ActionValue): UserState => {
  switch (action.type) {
    // case ActionType.UPDATE_IP_INFO:
    //   return updateIpInfo(state, action);
    case ActionType.INCREMENT_VISITS:
      return incrementVisits(state);
    case ActionType.GET_PRODUCT_ALARM:
      return setProductAlarms(state, action);
    case ActionType.UPDATE_PRODUCT_ALARM:
      return updateProductAlarm(state, action);
    case ActionType.DELETE_PRODUCT_ALARM:
      return removeProductAlarm(state, action);
    case ActionType.CREATE_PRODUCT_ALARM:
      return addProductAlarm(state, action);
    case ActionType.SET_CLAIMS:
      return setClaims(state, action);
    default:
      return state;
  }
};

export default userReducer;
