import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import ExperimentsContext, { ExperimentValues } from "./ExperimentsContext";

export interface ExperimentsProviderProps {
  children: ReactNode;
}

const ExperimentProvider = (props: ExperimentsProviderProps): ReactElement => {
  const [experimentVariants, setExperimentVariants] = useState<Readonly<ExperimentValues>>({});

  const optimizeCallback = useCallback(
    (value: string, name: string) => {
      setExperimentVariants((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setExperimentVariants],
  );

  const updateExperimentVariants = useCallback((): void => {
    gtag("event", "optimize.callback", {
      callback: optimizeCallback,
    });
  }, []);

  const contextValue = {
    variantsById: experimentVariants,
    updateExperiments: updateExperimentVariants,
  };

  return <ExperimentsContext.Provider value={contextValue}>{props.children}</ExperimentsContext.Provider>;
};

export default ExperimentProvider;
