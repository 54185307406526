import styled, { css } from "styled-components";
import { isMobileFrame } from "../../config";
import { Box } from "../../UI";
import { TOP_NAV_HEIGHT } from "./TopNav";

const StickyAside = styled(Box)`
  width: 100%;
  align-self: flex-start;

  ${() =>
    !isMobileFrame &&
    css`
      ${({ theme }): string => theme.mediaQueries.medium} {
        position: sticky;
        top: ${({ theme }) => `${TOP_NAV_HEIGHT + theme.space[4]}px`};
      }
    `}
`;
StickyAside.defaultProps = {
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
};

export default StickyAside;
