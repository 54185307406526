import { some } from "lodash-es";
import { devDomains, envSelector, prodDomains, stagingDomains } from "../../config";

const whitelistedDomains = envSelector<string[]>({
  production: prodDomains,
  staging: stagingDomains,
  development: devDomains,
});

const blacklistedPathnames = ["/blog"];

/**
 * Detects whether a url should be opened locally or opened externally
 */
export const isLocalUrl = (url: URL) => {
  /** check if domain matches any of the whitelisted domains */
  if (whitelistedDomains?.includes(url.hostname)) {
    /** if any of the urls have a blacklisted path, return false */
    if (some(blacklistedPathnames, (blacklistedPathname) => url.pathname.startsWith(blacklistedPathname))) {
      /** url is not local */
      return false;
    }

    /** url is local */
    return true;
  }

  /** url is not local */
  return false;
};
