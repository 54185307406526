import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useUser } from "reactfire";
import { isMobileFrame } from "../../../config";
import useCurrentUserProfile from "../../../hooks/useCurrentUserProfile";
import useMenuState from "../../../hooks/useMenuState";
import userProfileSelector from "../../../selectors/userProfileSelector";
import { UsersAPI } from "../../../typings/API";
import PartialMenu from "../../Layout/PartialMenu";
import { Admin, Authenticated } from "../../Permission";
import SettingsModal, { SettingsModalProps } from "../../Settings/SettingsModal";
import ActionsMenuItem from "../ActionsMenuItem";
import BlockUserActionsMenuItem from "./BlockUserActionsMenuItem";
import DebugMenu from "./DebugMenu/DebugMenu";
import MuteUserActionsMenuItem from "./MuteUserActionsMenuItem";
import ShareUserActionsMenuItem from "./ShareUserActionsMenuItem";
import SupportActionsMenuItem from "./SupportActionsMenuItem";

export interface UserActionsMenuProps {
  isOpen: boolean;
  user: UsersAPI.PublicProfile;
  close: () => void;
}

const UserActionsMenu: React.FC<UserActionsMenuProps> = ({ isOpen, close, user }) => {
  const { data: currentUser } = useUser();
  const { profile: currentUserProfile } = useCurrentUserProfile();
  const isBlocked = useSelector(userProfileSelector.isBlocked(user.id));
  const history = useHistory();
  const isOwner = currentUser?.uid === user.id;

  // to display settings modals
  const [settingModalDisplay, setSettingsModalDisplay] = useState<SettingsModalProps["display"]>(undefined);
  const closeSettingsModal = useCallback(() => {
    setSettingsModalDisplay(undefined);
  }, []);

  const { open: openErrorLog, menuProps: errorLogMenuProps } = useMenuState();
  const openPurchasesMenu = useCallback(() => {
    if (currentUserProfile?.username) {
      history.push(`/u/${currentUserProfile.username}/purchases`);
    }
  }, [history, currentUserProfile?.username]);

  return (
    <>
      {settingModalDisplay && (
        <SettingsModal
          closeModal={closeSettingsModal}
          open={Boolean(settingModalDisplay)}
          display={settingModalDisplay}
        />
      )}
      {isMobileFrame && isOwner && (
        <Admin>
          <DebugMenu {...errorLogMenuProps} />
        </Admin>
      )}
      <PartialMenu isOpen={isOpen} close={close} overflowY="initial">
        <ShareUserActionsMenuItem label={"Share"} iconName="share alternate" user={user} />
        {!isOwner && (
          <Authenticated>
            <>
              <BlockUserActionsMenuItem
                label={`${isBlocked ? "Unblock" : "Block"} ${user.username ? `@${user.username}` : "User"}`}
                iconName="ban"
                isBlocked={isBlocked}
                blockeeId={user.id}
                blockeeUsername={user.username}
                close={close}
              />
              <MuteUserActionsMenuItem user={user} />
            </>
          </Authenticated>
        )}
        {isOwner && (
          <>
            {currentUserProfile && (
              <ActionsMenuItem
                label="Settings"
                iconName="setting"
                onClick={() => history.push(`/u/${currentUserProfile.username}/settings`)}
              />
            )}
            <ActionsMenuItem label="Purchases" iconName="box" onClick={openPurchasesMenu} />
            <SupportActionsMenuItem label="Help" iconName="question circle outline" />
            <Admin>
              <ActionsMenuItem label="Admin" iconName="user secret" onClick={() => history.push("/admin")} />
              {isMobileFrame && <ActionsMenuItem label="Error Log" iconName="bug" onClick={openErrorLog} />}
            </Admin>
          </>
        )}
        <ActionsMenuItem label="Cancel" iconName="cancel" onClick={close} />
      </PartialMenu>
    </>
  );
};

export default UserActionsMenu;
