import { CometChat } from "@cometchat-pro/chat";
import produce, { Draft } from "immer";
import { keyBy } from "lodash-es";
import { Reducer } from "redux";
import ActionType from "../enums/ActionType";
import { ActionValue } from "../helpers/thunkUtil";

export interface MessagesById {
  [messageId: string]: CometChat.BaseMessage;
}

export interface MessagesByConversationId {
  [conversationId: string]: MessagesById;
}

export interface ConversationsById {
  [conversationId: string]: CometChat.Conversation;
}

export interface CometChatState {
  conversationsById: ConversationsById;
  isInitialized: boolean;
  isLoggedIn: boolean;
  messagesByConversationId: MessagesByConversationId;
  unreadMessageCounts: CometChat.UnreadMessageCounts;
}

const initialState: CometChatState = {
  conversationsById: {},
  isInitialized: false,
  isLoggedIn: false,
  messagesByConversationId: {},
  unreadMessageCounts: {
    users: {},
    groups: {},
  },
};

const cometChatReducer = produce((draft: Draft<CometChatState> = initialState, action: ActionValue) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case ActionType.COMET_CHAT_SET_LOGGED_IN: {
      draft.isLoggedIn = true;
      return draft;
    }
    case ActionType.COMET_CHAT_SET_INITIALIZED: {
      draft.isInitialized = true;
      return draft;
    }
    case ActionType.COMET_CHAT_ADD_CONVERSATIONS: {
      const conversations = action.payload as CometChat.Conversation[];
      draft.conversationsById = {
        ...draft.conversationsById,
        ...keyBy(conversations, (c) => c.getConversationId()),
      };
      return draft;
    }
    case ActionType.COMET_CHAT_SET_UNREAD_MESSAGE_COUNTS: {
      const umc = action.payload as CometChat.UnreadMessageCounts;
      draft.unreadMessageCounts = umc;
      return draft;
    }
    case ActionType.COMET_CHAT_INCREMENT_USER_UNREAD_MESSAGE_COUNT: {
      const userId = action.payload as string;
      const currentCount = draft.unreadMessageCounts.users[userId] || 0;
      draft.unreadMessageCounts.users = {
        ...draft.unreadMessageCounts.users,
        [userId]: currentCount + 1,
      };
      return draft;
    }
    case ActionType.COMET_CHAT_SET_USER_UNREAD_MESSAGE_COUNT: {
      const { userId, value } = action.payload as { userId: string; value: number };
      draft.unreadMessageCounts.users[userId] = value;
      return draft;
    }
    case ActionType.COMET_CHAT_ADD_CONVERSATION_MESSAGES: {
      const { conversationId, messages } = action.payload as {
        conversationId: string;
        messages: CometChat.BaseMessage[];
      };
      const prevConvoMessages = draft.messagesByConversationId[conversationId];
      draft.messagesByConversationId = {
        ...draft.messagesByConversationId,
        [conversationId]: {
          ...prevConvoMessages,
          ...keyBy(messages, (m) => m.getId()),
        },
      };
      return draft;
    }
    default:
      return draft;
  }
}) as Reducer<CometChatState, ActionValue>;

export default cometChatReducer;
