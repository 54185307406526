import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import events, { trackEvent } from "../../helpers/analyticsUtil";
import { Modal } from "../../libs/semantic-ui";
import { Box, Spacer, Text } from "../../UI";
import CreateUsername from "../Auth/CreateUsername";
import Login from "../Auth/Login";
import PhoneLoginForm from "../Auth/PhoneLoginForm";
import ResetPassword from "../Auth/ResetPassword";
import Signup from "../Auth/Signup";
import AuthScreensContext from "./AuthScreensContext";

export interface AuthScreensModalProps {}

const AuthScreensModal: React.FC<AuthScreensModalProps> = () => {
  const { screen, setAuthScreen, setInfo } = useContext(AuthScreensContext);
  useEffect(() => {
    if (screen) {
      trackEvent(events.AuthScreen.Viewed);
    }
  }, [screen]);
  const closePortal = useCallback(() => {
    trackEvent(events.AuthScreen.Dismissed, {
      screen: screen,
    });
    setAuthScreen(null);
    setInfo(null);
  }, [screen, setAuthScreen, setInfo]);

  return (
    <Modal
      dimmer={screen !== "phoneConfirm" && screen !== "phoneInput" ? "dimmer" : undefined}
      onClose={closePortal}
      open={Boolean(screen)}
      size="mini"
      title={screen === "login" ? "Login" : "Sign Up"}
    >
      {screen === "login" ? (
        <ModalContent>
          <Text fontSize={3} fontWeight={600}>
            Login
          </Text>
          <Spacer mt={3} />
          <Box display="inline-block">
            <Text>{"Don't have an account yet? "}</Text>
            <Text color="blue" clickable onClick={() => setAuthScreen("signup")}>
              {"Sign up"}
            </Text>
          </Box>
          <Spacer mt={4} />
          <Login onClose={closePortal} />
          <Spacer mt={4} />
          <DismissText onClick={closePortal}>Dismiss</DismissText>
        </ModalContent>
      ) : screen === "signup" ? (
        <ModalContent>
          <Text fontSize={3} fontWeight={600}>
            Sign Up
          </Text>
          <Spacer mt={3} />
          <Box display="inline-block">
            <Text>{" Already have an account? "}</Text>
            <Text color="blue" clickable onClick={() => setAuthScreen("login")}>
              {"Log in"}
            </Text>
          </Box>
          <Spacer mt={4} />
          <Signup onClose={closePortal} />
          <Spacer mt={4} />
          <DismissText onClick={closePortal}>Dismiss</DismissText>
        </ModalContent>
      ) : screen === "resetPassword" ? (
        <ModalContent>
          <Text color="blue" fontSize={3} fontWeight={600}>
            Reset Password
          </Text>
          <Spacer mt={3} />
          <Box display="inline-block">
            <Text>{"Try again? "}</Text>
            <Text clickable onClick={() => setAuthScreen("login")}>
              {"Log in"}
            </Text>
          </Box>
          <Spacer mt={4} />
          <ResetPassword />
          <Spacer mt={4} />
          <DismissText onClick={closePortal}>Dismiss</DismissText>
        </ModalContent>
      ) : screen === "createUsername" ? (
        <ModalContent>
          <Text fontSize={3} fontWeight={600}>
            Create Username
          </Text>
          <Spacer mt={3} />
          <Text>{`You can always change this later in your profile settings.`}</Text>
          <Spacer mt={4} />
          <CreateUsername />
          <Spacer mt={4} />
          <DismissText onClick={closePortal}>Skip</DismissText>
        </ModalContent>
      ) : screen === "phoneInput" || screen === "phoneConfirm" ? (
        <ModalContent>
          <PhoneLoginForm recaptchaSize="small" onClose={closePortal} />
        </ModalContent>
      ) : null}
    </Modal>
  );
};

const ModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
ModalContent.defaultProps = {
  p: 4,
  width: "100%",
};

const DismissText = styled(Text)``;
DismissText.defaultProps = {
  clickable: true,
  fontSize: 0,
};

export default AuthScreensModal;
