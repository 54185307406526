import { isEmpty } from "lodash-es";
import React from "react";
import { Link } from "react-router-dom";
import { MAX_MAIN_WIDTH } from "../../../components/Layout/layoutConstants";
import DeliveryAddress from "../../../components/OrderDetails/DeliveryAddress";
import LineItems from "../../../components/OrderDetails/LineItems";
import TotalDetails from "../../../components/OrderDetails/TotalDetails";
import { Divider, Loader } from "../../../libs/semantic-ui";
import { StripeAPI, UsersAPI } from "../../../typings/API";
import { Box, Spacer, Text } from "../../../UI";
import UserMeta from "../../../UI/components/UserList/UserMeta";
import useScreenSize from "../../../UI/utils/useScreenSize";

export interface CheckoutSuccessContentProps {
  checkoutSessionOrOrder?:
    | {
        type: "checkout_session";
        data: StripeAPI.CheckoutSession;
      }
    | {
        type: "order";
        data: StripeAPI.Order;
      };
  isFinished: boolean;
  isPending: boolean;
  sellers: UsersAPI.PublicProfile[];
}

const CheckoutSuccessContent: React.FC<CheckoutSuccessContentProps> = ({
  checkoutSessionOrOrder,
  isPending,
  sellers,
}) => {
  const screenSize = useScreenSize();

  if (!checkoutSessionOrOrder || isPending) {
    return <Loader active />;
  }

  const seller = sellers && !isEmpty(sellers) ? sellers[0] : null;

  const shippingInfo = checkoutSessionOrOrder
    ? checkoutSessionOrOrder.type === "checkout_session"
      ? checkoutSessionOrOrder.data.shipping
      : checkoutSessionOrOrder.data.shipping_details
    : null;

  return (
    <Box
      width="100%"
      maxWidth={MAX_MAIN_WIDTH}
      bg="white"
      mt={3}
      p={4}
      borderRadius={screenSize === "large" ? 3 : undefined}
    >
      <Box>
        <Text fontSize={4} fontWeight={5}>{`You just bought an item`}</Text>
      </Box>
      <Spacer mt={2} />
      <Box>
        <Text fontSize={2}>{`Here's your order confirmation`}</Text>
      </Box>
      {seller && (
        <>
          <Spacer mt={3} />
          <Divider />
          <Box>
            <Text fontWeight={5} fontSize={3}>
              Seller
            </Text>
          </Box>
          <Spacer mt={2} />
          <Box>
            <UserMeta profile={seller} />
          </Box>
        </>
      )}
      <Spacer mt={3} />
      <Divider />
      <LineItems lineItems={checkoutSessionOrOrder.data.line_items?.data} />
      <Spacer mt={3} />
      <Divider />
      <TotalDetails
        type="checkout_session"
        totalDetails={checkoutSessionOrOrder.data.total_details}
        amountSubtotal={checkoutSessionOrOrder.data.amount_subtotal}
        amountTotal={checkoutSessionOrOrder.data.amount_total}
      />
      <Spacer mt={3} />
      <Divider />
      <Box>
        <Box>
          <Text fontWeight={5} fontSize={3}>
            What next?
          </Text>
        </Box>
        <Spacer mt={2} />
        <Box>
          <Text>
            {`We've let `}
            {seller ? (
              <Link to={`/u/${seller.username}`}>
                <Text clickable textDecoration="underline" color="anchorBlue">{`@${seller.username}`}</Text>
              </Link>
            ) : (
              `the seller`
            )}
            {` know and they should ship your items within the next 7 days. You can message them directly in the app if you have any questions.`}
          </Text>
        </Box>
      </Box>
      <Spacer mt={3} />
      {shippingInfo && (
        <Box>
          <Box>
            <Text fontSize={3} fontWeight={5}>
              Your items will be shipped to
            </Text>
          </Box>
          <Spacer mt={2} />
          <DeliveryAddress name={shippingInfo.name || null} address={shippingInfo.address || null} phone={null} />
        </Box>
      )}
    </Box>
  );
};

export default CheckoutSuccessContent;
