import { find, isNil, keys, reduce, toNumber } from "lodash-es";
import memoizeOne from "memoize-one";
import { createSelector } from "reselect";
import { EntitlementID } from "../enums/RevenueCat";
import { ProductPriceAlarm } from "../helpers/productPriceAlertUtil";
import { SizeUnit } from "../helpers/sizeConversionUtil";
import dayjs from "../libs/dayjs";
import { RootState } from "../store";
import { UsersAPI } from "../typings/API";
import ReducerState from "../typings/Reducer";
import { Entitlement, Subscriber, Subscription } from "../typings/RevenueCat";

export type SubscriptionStatus = {
  entitlementId: EntitlementID;
  isActive: boolean;
  isSandbox: boolean;
  periodType: Subscription["period_type"];
  store: Subscription["store"];
};

export type SubscriptionStatusById = {
  [entitlementId: string]: SubscriptionStatus;
};

export const subscriptionStatusSelector = createSelector(
  (state: RootState) => state.entities.currentUserProfile?.subscriber || null,
  (_, entitlementId: EntitlementID) => entitlementId,
  (subscriber: Subscriber | null, entitlementId) => {
    /**
     * @link https://community.revenuecat.com/general-questions-7/how-to-sync-subscription-status-of-a-user-using-the-rest-api-1353?postid=4284#post4284
     */
    const now = dayjs();

    if (!subscriber) {
      return {};
    }

    const subscriptionStatusById = reduce(
      subscriber.entitlements,
      (result, entitlement, entitlementId) => {
        const subscription = subscriber.subscriptions[entitlement.product_identifier];
        result[entitlementId] = {
          entitlementId: entitlementId,
          isActive: dayjs(entitlement.expires_date).isAfter(now),
          isSandbox: subscription.is_sandbox,
          periodType: subscription.period_type,
          store: subscription.store,
        };
        return result;
      },
      {},
    );

    return subscriptionStatusById[entitlementId] || {};
  },
);

const userSelector = {
  isGoldSubscriber:
    () =>
    (state: RootState): boolean => {
      /**
       * https://community.revenuecat.com/general-questions-7/how-to-sync-subscription-status-of-a-user-using-the-rest-api-1353?postid=4284#post4284
       */
      const now = dayjs();
      const { currentUserProfile } = state.entities;

      if (!currentUserProfile?.subscriber) {
        return false;
      }

      const { subscriber } = currentUserProfile;

      const goldEntitlement: Entitlement | undefined = subscriber.entitlements[EntitlementID.GOLD];

      if (!goldEntitlement) {
        return false;
      }

      return dayjs(goldEntitlement.expires_date).isAfter(now);
    },
  /**
   * Selects whether the user can sell or not
   * @returns boolean
   */
  isSeller:
    () =>
    (state: RootState): boolean => {
      const { currentUserProfile: profile } = state.entities;
      if (!profile) {
        return false;
      }

      return !isNil(profile.commerce_account?.stripe_account);
    },
  /**
   * Selects whether the user has a Stripe account
   * @returns boolean
   */
  hasStripeAccount:
    () =>
    (state: RootState): boolean => {
      const { currentUserProfile: profile } = state.entities;
      if (!profile) {
        return false;
      }
      return !isNil(profile.commerce_account?.stripe_account);
    },
  alarms: memoizeOne((active?: boolean) => (state: ReducerState): ProductPriceAlarm[] => {
    return keys(state.user.productAlarms)
      .filter((alarmId) => {
        const targetAlarm = state.user.productAlarms[toNumber(alarmId)];
        if (active === true) {
          return !targetAlarm.triggered_at;
        } else if (active === false) {
          return targetAlarm.triggered_at;
        } else {
          return true;
        }
      })
      .map((alarmId) => state.user.productAlarms[alarmId]);
  }),
  currencyPreference:
    () =>
    (state: ReducerState): string =>
      state.entities.preferences.currencyPreference || "USD",
  disableShippingCosts:
    () =>
    (state: ReducerState): boolean =>
      Boolean(state.entities.preferences.disableShippingCosts),
  isBeta:
    () =>
    (state: ReducerState): boolean =>
      Boolean(state.entities.preferences.beta),
  marketingEmails:
    () =>
    (state: ReducerState): boolean => {
      return state.entities.preferences.emailMarketing === false ? false : true;
    },
  preferences:
    () =>
    (state: ReducerState): UsersAPI.UserPreferences =>
      state.entities.preferences,
  productAlarmByProduct: memoizeOne(
    (productId?: number) =>
      (state: ReducerState): ProductPriceAlarm | undefined =>
        find(state.user.productAlarms, (alarm) => alarm.product_id === productId && !alarm.triggered_at),
  ),
  review:
    () =>
    (state: ReducerState): UsersAPI.Review | null =>
      state.entities.preferences.review || null,
  shippingLocation:
    () =>
    (state: ReducerState): UsersAPI.ShippingLocation | null =>
      state.entities.preferences.shippingLocation || null,
  shippingLocationCountry:
    () =>
    (state: ReducerState): string =>
      state.entities.preferences.shippingLocation?.country || "US",
  sizePreferenceUnit:
    () =>
    (state: ReducerState): SizeUnit =>
      state.entities.preferences.sizePreferenceUnit || "us",
  visits:
    () =>
    (state: ReducerState): number =>
      state.user.visits,
};

export default userSelector;
