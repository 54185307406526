import React, { useCallback } from "react";
import config, { isMobileWeb } from "../../config";
import { Box, Button, ButtonProps } from "../../UI";
import { TOP_NAV_HEIGHT } from "../Layout/TopNav";

export interface TopNavUseAppButtonProps extends ButtonProps {}

const TopNavUseAppButton: React.FC<TopNavUseAppButtonProps> = ({ ...buttonProps }) => {
  const handleClick = useCallback(() => {
    window.open(config.BRANCH_TOP_NAV_APP_URL, "_blank");
  }, []);

  /** only render this component on the mobile website */
  if (!isMobileWeb) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={TOP_NAV_HEIGHT} width={TOP_NAV_HEIGHT}>
      <Button size="tiny" variant="primary" {...buttonProps} onClick={handleClick}>
        {"App"}
      </Button>
    </Box>
  );
};

export default TopNavUseAppButton;
