import { isEmpty, isEqual } from "lodash-es";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";
import { sanitizePostPatch, validatePostPatch } from "../../../helpers/postUtil";
import { CommunitiesAPI } from "../../../typings/API";
import { PostsAPI, PostTypeKey } from "../../../typings/API-V2";
import { Box, Text } from "../../../UI";
import { FullscreenMenuProps } from "../../Layout/FullscreenMenu";
import { Admin } from "../../Permission";
import CreatePostMenuGeneric, { PostDataMeta, PostPatchDataMeta } from "../CreatePostMenuGeneric";
import useCommunitySelectorInput from "../hooks/useCommunitySelectorInput";
import useLinkInput from "../hooks/useLinkInput";
import useThemeSelectorInput, { UseThemeSelectorInputOptions } from "../hooks/useThemeSelectorInput";
import useTitleInput from "../hooks/useTitleInput";
import ImagesPreview, { ImagesPreviewProps } from "./ImagesPreview";
import TagImageMenu from "./TagImageMenu";

export interface EditImagePostMenuProps extends Omit<FullscreenMenuProps, "children"> {
  close: () => void;
  isOpen: boolean;
  editPost: PostsAPI.ImagePost;
  onCreated?: () => void;
}

type UpdateImage = Omit<PostsAPI.PostImage, "tags"> & { tags: PostsAPI.PostPatchImageTag[] | null };

const EditImagePostMenu: React.FC<EditImagePostMenuProps> = ({ close, isOpen, editPost, onCreated }) => {
  const {
    input: communityInput,
    menus: communityMenus,
    value: community,
    clearValue: clearCommunity,
  } = useCommunitySelectorInput(editPost?.community.slug || undefined);
  const {
    input: titleInput,
    menus: titleMenus,
    value: title,
    clearValue: clearTitle,
  } = useTitleInput({ defaultValue: editPost?.title, community: community });
  const {
    input: linkInput,
    menus: linkMenus,
    value: url,
    clearValue: clearUrl,
  } = useLinkInput({ defaultValue: editPost?.url || editPost?.to_route || undefined });
  const themeInputOptions = useMemo<UseThemeSelectorInputOptions>(
    () => ({
      communityId: community?.id,
      defaultThemeId: editPost?.theme?.id || undefined,
      postTypeKey: PostTypeKey.Image,
    }),
    [community?.id, editPost?.theme?.id],
  );
  const {
    input: themeInput,
    menus: themeMenus,
    value: themeId,
    clearValue: clearTheme,
  } = useThemeSelectorInput(themeInputOptions);

  /**
   * IMAGE TAGGING
   * =============
   */
  const [tagImageIndex, setTagImageIndex] = useState<number | null>(null);
  const [images, setImages] = useImmer<UpdateImage[] | null>(editPost.images);
  const clearImages = useCallback(() => setImages(editPost.images), [setImages, editPost.images]);
  const closeTagMenu = useCallback(() => setTagImageIndex(null), []);
  const onImagePreviewClick = useCallback((imageIndex: number) => setTagImageIndex(imageIndex), []);
  const imagePreviewImages = useMemo<ImagesPreviewProps["images"]>(() => {
    if (images) {
      return images.map((i) => ({
        id: i.id,
        url: i.url,
        tags: i.tags,
      }));
    }
    return [];
  }, [images]);
  const setTags = useCallback<(tagsState: PostsAPI.PostPatchImageTag[] | null) => void>(
    (newTags) => {
      if (tagImageIndex !== null) {
        setImages((draft) => {
          if (draft) {
            draft[tagImageIndex].tags = newTags;
          }
        });
      }
    },
    [setImages, tagImageIndex],
  );

  const clearFields = useCallback(() => {
    clearCommunity();
    clearImages();
    clearTitle();
    clearTheme();
    clearUrl();
  }, [clearCommunity, clearImages, clearTitle, clearUrl, clearTheme]);

  const postDataMeta = useMemo<PostDataMeta>(
    () =>
      createPostPatch({
        editPost,
        community,
        themeId,
        updateImages: images,
        url,
        title,
      }),
    [editPost, community, themeId, url, title, images],
  );

  const closeMenu = useCallback(() => {
    close();
    /** reset entire menu state when closed */
    clearFields();
  }, [close, clearFields]);

  return (
    <>
      {communityMenus}
      {titleMenus}
      {linkMenus}
      {themeMenus}
      {images && tagImageIndex !== null && (
        <TagImageMenu
          close={closeTagMenu}
          setTags={setTags}
          src={images[tagImageIndex].url}
          tags={images[tagImageIndex].tags}
        />
      )}
      <CreatePostMenuGeneric
        close={closeMenu}
        isOpen={isOpen}
        onCreated={onCreated}
        overflowY="scroll"
        postDataMeta={postDataMeta}
      >
        {communityInput}
        {community && (
          <>
            {titleInput}
            <Admin>{linkInput}</Admin>
            {themeInput}
            <ImagesPreview images={imagePreviewImages} onImageClick={onImagePreviewClick} />
            {imagePreviewImages.length > 0 && (
              <TaggingInstructionsWrapper py={3}>
                <Text fontSize={1} color="darkGrey">
                  {`Tap photos to tag products`}
                </Text>
              </TaggingInstructionsWrapper>
            )}
          </>
        )}
      </CreatePostMenuGeneric>
    </>
  );
};

const createPostPatch = (options: {
  editPost?: PostsAPI.ImagePost;
  community: CommunitiesAPI.Community | null;
  themeId: number | null;
  updateImages: UpdateImage[] | null;
  url: string | null;
  title: string;
}): PostPatchDataMeta => {
  const { editPost, title, community, themeId, url, updateImages } = options;

  if (!editPost) {
    return {
      type: "patch",
      canSubmit: false,
      message: "Original post is missing",
    };
  }

  if (!community) {
    return {
      type: "patch",
      canSubmit: false,
      message: "A community must be selected",
    };
  }

  /** build creation object */
  const postPatch: PostsAPI.PostPatch = {
    id: editPost.id,
    community_id: community.id,
    images: getUpdatedImages(editPost.images, updateImages),
    theme_key: editPost.theme_key,
    is_archived: editPost.is_archived,
    theme_id: themeId,
    title: title,
    type: PostTypeKey.Image,
    url: url && url[0] !== "/" ? url : null,
    to_route: url && url[0] === "/" ? url : null,
  };

  /** sanitize creation object */
  const sanitizedPostPatch = sanitizePostPatch(postPatch);

  /** validate creation object */
  const postPatchValidation = validatePostPatch(sanitizedPostPatch);
  if (!postPatchValidation.isValid) {
    /** capture error */
    return {
      type: "patch",
      canSubmit: false,
      message: postPatchValidation.message,
    };
  }

  return {
    type: "patch",
    canSubmit: true,
    postData: sanitizedPostPatch,
  };
};

/**
 * Generates the PostsAPI.PostPatchImageTags given the original and updated tags.
 * If the tags are equal, tags is set to undefined, and none of the tags are changed
 * @param originalTags the existing tags
 * @param newTags the tags to replace the existing tags
 * @returns PostsAPI.PostPatchImage["tags"]
 */
const getUpdatedTags = (
  originalTags: PostsAPI.PostImage["tags"],
  newTags: PostsAPI.PostPatchImage["tags"],
): PostsAPI.PostPatchImage["tags"] => {
  /** NO UPDATE: if prev and new tags are equal, do not update tags */
  if (isEqual(originalTags, newTags)) {
    return undefined;
  }

  /**
   * UPDATE: if new tags exist and are empty, set tags to null
   * This is needed because the backend currently does not handle an empty tags array
   */
  if (newTags && isEmpty(newTags)) {
    return null;
  }

  /** UPDATE: update to the new tags */
  return newTags;
};

const getUpdatedImages = (
  originalImages: PostsAPI.Post["images"],
  tempImages: (Omit<PostsAPI.PostImage, "tags"> & { tags: PostsAPI.PostPatchImageTag[] | null })[] | null,
): PostsAPI.PostPatch["images"] => {
  if (!originalImages || !tempImages) {
    return undefined;
  }
  return originalImages.map((i, idx) => ({
    id: i.id,
    url: i.url,
    tags: getUpdatedTags(i.tags, tempImages[idx].tags),
  }));
};

const TaggingInstructionsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default EditImagePostMenu;
