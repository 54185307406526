import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import useAuthenticatedCallback from "../../hooks/useAuthenticatedCallback";
import useCurrentUserProfile from "../../hooks/useCurrentUserProfile";
import { Loader } from "../../libs/semantic-ui";
import { Box, Icon, UserAvatar } from "../../UI";
import { TOP_NAV_HEIGHT } from "../Layout/TopNav";

const AVATAR_SIZE = 30;

export interface TopNavProfileIconProps {}

const TopNavProfileIcon: React.FC<TopNavProfileIconProps> = () => {
  const history = useHistory();
  const { profile, isFinished } = useCurrentUserProfile();
  const handleProfileClick = useAuthenticatedCallback(() => {
    if (profile) {
      history.push({
        pathname: `/u/${profile.username}`,
        state: { fromBottomNav: true },
      });
    } else {
      history.push(`/me`);
    }
  }, [history, profile]);

  const avatar = !isFinished ? (
    // if the user is loading, display a loader
    <IconWrapper>
      <Loader active inline size="tiny" />
    </IconWrapper>
  ) : profile ? (
    // if the user is logged in, display their user avatar
    <UserAvatar size={AVATAR_SIZE} src={profile.profile_image} username={profile.username} />
  ) : (
    // if the user isn't logged in, display user icon
    <IconWrapper>
      <Icon name="user" color="black" />
    </IconWrapper>
  );

  // if the user is logged in, display their user avatar
  return <Wrapper onClick={handleProfileClick}>{avatar}</Wrapper>;
};

const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
IconWrapper.defaultProps = {
  bg: "lightGrey",
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  borderRadius: 1,
};

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
Wrapper.defaultProps = {
  width: TOP_NAV_HEIGHT,
  height: TOP_NAV_HEIGHT,
};

export default TopNavProfileIcon;
