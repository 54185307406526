import styled, { DefaultTheme } from "styled-components";
import { grid, GridProps as SystemGridProps } from "styled-system";
import Box, { BoxProps } from "./Box";

interface CustomGridProps {}

export type GridProps = CustomGridProps & SystemGridProps<DefaultTheme> & BoxProps;

const Grid = styled(Box)<GridProps>`
  ${grid}
`;

Grid.defaultProps = {
  display: "grid",
};

export default Grid;
