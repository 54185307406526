import React from "react";
import { Box, Text } from "../../UI";

export interface SearchMessageProps {
  query?: string;
  results: any[] | null;
}

const SearchMessage: React.FC<SearchMessageProps> = ({ query, results }) => {
  if (query && results && results.length === 0) {
    const message = `There were no matching results.`;
    return (
      <Box my={[4, 4, 5]} display="flex" flexShrink={0} alignItems="center" justifyContent="space-around">
        <Text fontSize={3}>{message}</Text>
      </Box>
    );
  } else {
    // typescript needs this "else" for some reason
    return null;
  }
};

export default SearchMessage;
