import { isString } from "lodash-es";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Loader } from "../../libs/semantic-ui";
import { Box, BoxProps, Icon, IconProps, Text } from "../../UI";

export interface ActionsMenuItemProps extends BoxProps {
  label: ReactNode;
  detail?: ReactNode;
  iconName?: IconProps["name"];
  loading?: boolean;
  hideBorder?: boolean;
}

const ActionsMenuItem: React.FC<ActionsMenuItemProps> = ({
  hideBorder,
  iconName,
  label,
  loading,
  detail,
  ...boxProps
}) => (
  <MenuItem {...boxProps}>
    {iconName && (
      <IconWrapper width={70}>
        <Icon name={iconName} />
      </IconWrapper>
    )}
    <MenuItemTextWrapper borderBottom={hideBorder ? "0px" : undefined}>
      {isString(label) ? <MenuItemText>{label}</MenuItemText> : label}
      {loading ? <Loader active inline size="small" /> : <MenuItemText color="darkGrey">{detail}</MenuItemText>}
    </MenuItemTextWrapper>
  </MenuItem>
);

const MenuItem = styled(Box)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;
MenuItem.defaultProps = {
  height: 50,
  alignItems: "center",
  display: "flex",
  flexShrink: 0,
  role: "button",
};

const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuItemTextWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
MenuItemTextWrapper.defaultProps = {
  borderBottom: "1px solid",
  borderColor: "lightGrey",
  pr: 3,
};

const MenuItemText = styled(Text)``;
MenuItemText.defaultProps = {
  fontSize: 2,
};

export default ActionsMenuItem;
