import config from "../config";
import { VendorsAPI } from "../typings/API";

export const makeUrl = (
  url: string,
  affiliateData: VendorsAPI.VendorAffiliateData | undefined,
  cuid: string,
): string => {
  switch (affiliateData?.type) {
    case "awin": {
      // https://wiki.awin.com/index.php/Deeplink_Builder
      const qs = new URLSearchParams({
        awinmid: affiliateData.mid,
        awinaffid: "746697", // plugd account id
        ued: url,
        pref1: cuid,
      }).toString();
      return `https://www.awin1.com/cread.php?${qs}`;
    }
    case "cj": {
      const propertyId = "9182919"; // plugd website id
      const sid = cuid;
      return `https://www.anrdoezrs.net/links/${propertyId}/type/dlg/sid/${sid}/${url}`; // cj doesn't encode the url, so not using encodeURIComponent here
    }
    case "ebay": {
      const urlObj = new URL(url);
      const updatedParams = new URLSearchParams(urlObj.searchParams);
      for (const dataKey in affiliateData) {
        updatedParams.set(dataKey, affiliateData[dataKey]);
      }
      updatedParams.set("customid", cuid);
      urlObj.search = `?${updatedParams.toString()}`;
      return urlObj.toString();
    }
    case "impact": {
      const qs = new URLSearchParams({
        u: url,
        /* About SubIds: https://help.impactradius.com/hc/en-us/articles/202938699-SubID-and-SharedID?flash_digest=512746b6b1ffbfa7003303c605f263f99cf4d7d0#faqs-0-3 */
        subId1: cuid,
      }).toString();
      return `${affiliateData.base_url}?${qs}`;
    }
    case "flexoffers": {
      const qs = new URLSearchParams({
        foc: affiliateData.foc,
        foid: affiliateData.foid,
        fos: affiliateData.fos,
        fot: affiliateData.fot,
        trid: affiliateData.trid,
        url: url,
        fobs: cuid,
      }).toString();
      return `https://track.flexlinkspro.com/g.ashx?${qs}`;
    }
    case "partnerize": {
      const camref = affiliateData.camref;
      const destination = encodeURIComponent(url);
      const pubref = encodeURIComponent(cuid);
      // URL Generation: https://console.partnerize.com/v2/creative/tracking
      return `https://prf.hn/click/camref:${camref}/pubref:${pubref}/destination:${destination}`;
    }
    case "pepperjam": {
      const mid = affiliateData.mid;
      const qs = new URLSearchParams({
        sid: cuid,
        url,
      }).toString();
      return `https://www.pjtra.com/t/${mid}?${qs}`;
    }
    case "rakuten": {
      const id = "tzKQwO7KHpw"; // I also assume this is the pubisher id (plugd). Need further investigation
      const qs = new URLSearchParams({
        id,
        mid: affiliateData.mid, // I assume this is merchant id (stadium goods). Need further investigation
        murl: url,
        /* u1:
         * https://pubhelp.rakutenmarketing.com/hc/en-us/articles/207222086-Quick-Start-Guide-How-to-Get-Your-Affiliate-Marketing-Program-Going-Fast
         */
        u1: cuid, // for tracking unique users. Will need this in the future. Leaving here for future reference
      }).toString();
      return `https://click.linksynergy.com/deeplink?${qs}`;
    }
    // todo: implement this (right now only have klekt programs which aren't implemented)
    // case "webgains": {
    //   const qs = new URLSearchParams({
    //     wgcampaignid: "1443265",
    //     wgprogramid: 281405, // need this
    //     clickref: cuid,
    //     wgtarget: url,
    //   }).toString();
    //   return `https://track.webgains.com/click.html?${qs}`;
    // }
    default: {
      // return viglink url as default
      const qs = new URLSearchParams({
        cuid,
        key: config.VIGLINK_KEY,
        u: url,
      }).toString();
      return `http://redirect.viglink.com?${qs}`;
    }
  }
};
