import React, { useContext } from "react";
import { Spacer, Text } from "../../../UI";
import ResetPassword from "../../Auth/ResetPassword";
import AuthScreenContentHeader from "../AuthScreenContentHeader";
import AuthScreensContext from "../AuthScreensContext";

export interface ResetPasswordScreenProps {}

const ResetPasswordScreen: React.FC<ResetPasswordScreenProps> = () => {
  const { setAuthScreen } = useContext(AuthScreensContext);

  return (
    <>
      <AuthScreenContentHeader
        title={"Enter your new password"}
        description={
          <>
            <Text>{"Try again? "}</Text>
            <Text color="blue" clickable onClick={() => setAuthScreen("emailLogin")}>
              {"Log in"}
            </Text>
          </>
        }
      />
      <Spacer mt={3} />
      <ResetPassword />
    </>
  );
};

export default ResetPasswordScreen;
