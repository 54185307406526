import React, { useMemo, useState } from "react";
import {
  ToastProvider as RTNToastProvider,
  ToastProviderProps as RTNToastProviderProps,
} from "react-toast-notifications";
import useScreenSize from "../../UI/utils/useScreenSize";
import Toast from "./Toast";
import ToastContainer from "./ToastContainer";

export interface ToastProviderProps {}

export const ToastContext = React.createContext<{
  bottomOffset: number;
  setBottomOffset: (offset: number) => void;
}>({
  bottomOffset: 0,
  setBottomOffset: () => {},
});

const ToastProvider: React.FC<React.PropsWithChildren<ToastProviderProps>> = ({ children }) => {
  const [bottomOffset, setBottomOffset] = useState<number>(0);
  const screenSize = useScreenSize();

  const providerValue = useMemo(
    () => ({
      bottomOffset,
      setBottomOffset,
    }),
    [bottomOffset],
  );

  const toastComponents = useMemo<RTNToastProviderProps["components"]>(
    () => ({
      Toast,
      ToastContainer,
    }),
    [],
  );

  return (
    <ToastContext.Provider value={providerValue}>
      <RTNToastProvider
        autoDismissTimeout={4000}
        placement={screenSize === "large" ? "top-right" : "bottom-center"}
        components={toastComponents}
      >
        {children}
      </RTNToastProvider>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
